'use strict';

import _ from 'underscore';
import React from 'react';
import Ajax from '../../jskit/general/Ajax';

import Utils from '../../jskit/general/Utils';
import Paginator from '../../jskit/react/Paginator';
import Incident from './Incident.jsx';
import IncidentForm from './IncidentForm.jsx';
import MessageBox from '../../js/global/messagebox';

export default class IncidentsTab extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.itemName = props.forMaintenance ? 'Scheduled Maintenance' : 'Incident';
    this.itemNamePlural = props.forMaintenance ? 'maintenance event(s)' : 'incident(s)';
    this.showIncidentFormInterval = null;
    this.state = {
      isLoading: true,
    };
  }

  getComponentChoices() {
    const components = [];
    const addFn = (l) => {
      l.forEach((c) => {
        if (!c.is_group) {
          components.push([c.id, c.name]);
        } else {
          addFn(c.subcomponents);
        }
      });
    };

    addFn(this.props.statuspage.components);
    return components;
  }

  onNewData(presentData) {
    this.setState({isLoading: true});
    const paginator = this.refs.paginator;
    const query = _.extend({}, paginator ? paginator.getQueryOffsetLimit() : 0);
    new Ajax().post({
      url: this.props.listPastIncidentsURL,
      data: query,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          let newStatusPage = presentData || Object.assign({}, this.props.statuspage);
          newStatusPage = Object.assign(newStatusPage, data.data);
          this.props.onNewData(newStatusPage);
        }
      }.bind(this),
      complete: function () {
        this.setState({isLoading: false});
      }.bind(this),
    });
  }

  handlePageChange() {
    this.onNewData(null);
  }

  showIncidentFormModal(incident) {
    // This is a dirty hack to deal with a race condition where changing
    // tabs causes this parent component to completely re-render
    // (due to handleNewData() calling back to the parent & replacing its state)
    this.showIncidentFormInterval = setInterval(() => {
      if (!this.state.isLoading) {
        clearInterval(this.showIncidentFormInterval);
        this.showIncidentFormInterval = null;
        this.refs.incidentForm.showModal(incident);
      }
    }, 50);
  }

  handleAdd(e) {
    if (e) {
      e.preventDefault();
    }
    this.showIncidentFormModal(null);
  }

  handleEdit(incident, e) {
    e.preventDefault();
    this.showIncidentFormModal(incident);
  }

  handleDelete(incident, e) {
    e.preventDefault();
    const msg = 'Are you sure you want to delete "' + incident.name + '"?';

    MessageBox.confirmBox(msg, 'Delete ' + this.itemName, () => {
      new Ajax().post({
        url: incident.delete_url,
        data: {},
        encoder: 'json',
        decoder: 'json',
        success: function (data) {
          if (data.success) {
            this.onNewData(data.data);
          }
        }.bind(this),
      });
    });
  }

  getActiveIncidents(statuspage, forMaintenance) {
    if (forMaintenance) {
      return statuspage.upcoming_maintenance;
    } else {
      return statuspage.active_incidents;
    }
  }

  getPastIncidents(statuspage, forMaintenance) {
    if (forMaintenance) {
      return statuspage.past_maintenance || [];
    } else {
      return statuspage.past_incidents || [];
    }
  }

  getTotalPastRecords(statuspage, forMaintenance) {
    if (forMaintenance) {
      return statuspage.past_maintenance_count || 0;
    } else {
      return statuspage.past_incidents_count || 0;
    }
  }

  render() {
    const statuspage = this.props.statuspage;
    const activeIncidents = this.getActiveIncidents(statuspage, this.props.forMaintenance);
    const pastIncidents = this.getPastIncidents(statuspage, this.props.forMaintenance);
    const totalPastRecords = this.getTotalPastRecords(this.props.statuspage, this.props.forMaintenance);
    const totalRecords = totalPastRecords + activeIncidents.length;
    if (!totalRecords) {
      return this.renderEmptyState();
    }
    return (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="text-muted mr-3">
            {activeIncidents.length} active {this.itemNamePlural}
          </div>
          {this.renderAddButton()}
        </div>
        {this.renderIncidents(activeIncidents)}
        <div className="text-muted mt-5 mb-3">
          {pastIncidents ? pastIncidents.length : 0} resolved {this.itemNamePlural}
        </div>
        {this.renderIncidents(pastIncidents)}
        <div className="mt-4">
          <Paginator ref="paginator" totalRecords={totalPastRecords} onPageChange={this.handlePageChange} />
        </div>
        {this.renderIncidentForm()}
      </React.Fragment>
    );
  }

  renderIncidentForm() {
    const choices = Object.assign({}, this.props.choices, {components: this.getComponentChoices()});
    return (
      <React.Fragment>
        <IncidentForm
          ref="incidentForm"
          updateURL={this.props.updateURL}
          previewURL={this.props.updatePreviewURL}
          forMaintenance={this.props.forMaintenance}
          choices={choices}
          onNewData={this.onNewData}
        />
      </React.Fragment>
    );
  }

  renderIncidents(incidents) {
    if (!incidents || !incidents.length) {
      return null;
    }

    const renderedIncidents = incidents.map((incident, i) => (
      <Incident
        key={i}
        incident={incident}
        componentStatusRank={this.props.componentStatusRank}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
      />
    ));

    return (
      <div className="incident-list white-block white-block-border">
        {renderedIncidents}
        <div className="incident-end"></div>
      </div>
    );
  }

  renderEmptyState() {
    // eslint-disable-next-line max-len
    const incidentsOverview =
      'Add an incident to communicate the state of downtime events to your userbase via real-time updates to your page.';
    // eslint-disable-next-line max-len
    const scheduledMaintenanceOverview =
      'Schedule a maintenance window to show a planned downtime event, and optionally issue a notification to alert your users.';
    return (
      <React.Fragment>
        <div className="text-center p-lg-5 white-block white-block-border">
          <div className="font-weight-bold font-24 mb-4">
            You have not yet added any {this.props.forMaintenance ? 'Maintenance Windows' : 'Incidents'}...
          </div>
          <div className="font-weight-normal font-14 mb-5">
            {this.props.forMaintenance ? scheduledMaintenanceOverview : incidentsOverview}
          </div>
          <div>{this.renderAddButton()}</div>
        </div>
        {this.renderIncidentForm()}
      </React.Fragment>
    );
  }

  renderAddButton() {
    const buttonTitle = this.props.forMaintenance ? 'Schedule Maintenance' : 'Add an Incident';
    return (
      <div>
        <button onClick={this.handleAdd} className="btn btn-sm btn-outline-primary">
          <i className="fas fa-plus mr-2" />
          {buttonTitle}
        </button>
      </div>
    );
  }
}

IncidentsTab.defaultProps = {
  forMaintenance: false,
  componentStatusRank: null,
  updateURL: null,
  listPastIncidentsURL: null,
  statuspage: null,
  onNewData: null,
};
