import React from 'react';
import Ajax from '../../jskit/general/Ajax';
import Utils from '../../jskit/general/Utils';
import Cookie from '../../jskit/general/Cookie';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';

import SubscriptionCheckoutPage from './SubscriptionCheckoutPage.jsx';

export default class SwitchToAnnualController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      paymentProcessorErrors: null,
      formData: {annual_billing: true},
    };
    Utils.autoBindClass(this);
  }

  handleSubscriptionUpdate(paymentData, paymentFieldsErrorFn, finalizeSubmitFn) {
    this.setState({checkoutErrors: null});
    const data = {
      payment_data: paymentData,
      annual_billing: true,
      added_values: {},
    };
    new Ajax().post({
      url: this.props.updateURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          Cookie.clearCookie('subscriptionPage');
          window.location = this.props.doneURL + '?action=subscription';
        } else {
          finalizeSubmitFn();
          if (data.fields.payment_fields) {
            paymentFieldsErrorFn(data.fields.payment_fields);
          }
          if (data.fields.__all__) {
            this.setState({checkoutErrors: data.fields.__all__});
          }
        }
      }.bind(this),
    });
  }

  handleCancel(e) {
    e.preventDefault();
    window.location = this.props.cancelURL;
  }

  render() {
    const formLink = prepareFormLink(this, 'formData');
    return (
      <SubscriptionCheckoutPage
        withTitle={true}
        unitsPrices={{}}
        displayFullQuote={false}
        displayTotalCost={false}
        displayProrationNotice={false}
        addedValues={{annual_subscription: -1}}
        calculatedValues={{discounts: this.props.discounts, added_cost: this.props.price_monthly}}
        formLink={formLink}
        braintreeToken={this.props.braintreeToken}
        isCustomer={true}
        isUpgrade={false}
        companyData={this.props.companyData}
        checkoutErrors={this.state.checkoutErrors}
        paymentMethod={this.props.paymentMethod}
        billingInfo={this.props.billingInfo}
        onCancel={this.handleCancel}
        onSubmit={this.handleSubscriptionUpdate}
        updateURL={this.props.updateURL}
        alertNotificationMessage={this.state.checkoutAlertNotificationMessage}
        canPurchase={true}
        isPrimary={this.props.isPrimary}
        allCountries={this.props.allCountries}
        excludeStateCountries={this.props.excludeStateCountries}
        showStateDropdownForCountries={this.props.showStateDropdownForCountries}
      />
    );
  }
}
