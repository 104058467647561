'use strict';

import _ from 'underscore';
import React from 'react';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import TextInput from '../../jskit/react/forms/TextInput';
import CheckBox from '../../jskit/react/forms/CheckBox';
import SelectBox from '../../jskit/react/forms/SelectBox';
import Select2 from '../../jskit/react/forms/Select2';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import {prepareFormLink, FormErrors, Label} from '../../jskit/react/forms/FormHelpers';
import {ServiceSelectorDialog, ServiceSelectorInput} from '../../devices/common/DevicesCommon';

const Pages = {
  PAGE_GLOBAL: 'page_global',
  PAGE_METRICS: 'page_metrics',
  PAGE_CHECKS: 'page_checks',
  PAGE_ALERTS: 'page_alerts',
};

export default class DashboardForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {
        services_selected: [],
        dashboard_position: null,
      },
      dashboardPositionChoices: [],
      currentPage: Pages.PAGE_GLOBAL,
      checksSelectorIsActive: false,
    };

    this.ALL_PAGES = [
      [Pages.PAGE_GLOBAL, 'Global'],
      [Pages.PAGE_METRICS, 'Metrics Summary'],
      [Pages.PAGE_CHECKS, 'Checks'],
      [Pages.PAGE_ALERTS, 'Alerts'],
    ];
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialFormData) {
    if (initialFormData) {
      const dashboardPositionChoices = _.zip(
        _.range(0, this.props.allDashboards.length).concat([this.props.allDashboards.length]),
        _.range(1, this.props.allDashboards.length + 1).concat(['Last'])
      );

      const dashboardPosition = initialFormData.id
        ? this.props.allDashboards.findIndex((x) => x.id === initialFormData.id)
        : this.props.allDashboards.length;

      this.setState({
        formData: Object.assign({}, initialFormData, {dashboard_position: dashboardPosition}),
        dashboardPositionChoices: dashboardPositionChoices,
        currentPage: Pages.PAGE_GLOBAL,
        checksSelectorIsActive: false,
      });
    }

    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  handlePageChange(page) {
    this.setState({currentPage: page});
  }

  handleChecksSelectionShowDialog(e) {
    e.preventDefault();
    this.setState({checksSelectorIsActive: true});
  }

  handleChecksSelectionDialogDismissed(e) {
    e.preventDefault();
    this.setState({checksSelectorIsActive: false});
  }

  handlePreview(e) {
    e.persist();
    e.preventDefault();
    this.props.onApply(e);
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this.getFormData();

    return (
      <Modal
        ref="modal"
        title="Customize Dashboard"
        size="lg"
        previewButton={this.handlePreview}
        cancelButton={this.props.onCancel}
        subModalActive={this.state.checksSelectorIsActive ? 'Select Checks' : null}
        onSubModalDismissed={this.handleChecksSelectionDialogDismissed}
      >
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <input type="hidden" name="id" value={formData.id || ''} />
          {this.state.checksSelectorIsActive
            ? this.renderChecksSelectorSubModal(formLink, formData)
            : this.renderFormSections(formLink, formData)}
        </form>
      </Modal>
    );
  }

  renderFormSections(formLink, formData) {
    return (
      <SectionNav
        sectionIds={this.ALL_PAGES}
        currentSectionId={this.state.currentPage}
        errors={this.props.errors}
        navClass="nav nav-pills nav-pills-buttons nav-justified mb-4"
        onSectionNav={this.handlePageChange}
      >
        <SectionNavContent sectionId={Pages.PAGE_GLOBAL} currentSectionId={this.state.currentPage}>
          <FormErrors errors={this.props.errors.__all__} />
          <div className="row">
            <div className="col-lg-6">
              <TextInput
                fieldName="name"
                labelText="Name"
                isRequired={true}
                formLink={formLink}
                titleText="Displayed name for this dashboard."
              />
            </div>
            <div className="col-lg-6">
              <SelectBox
                fieldName="dashboard_position"
                labelText="Menu Position"
                isRequired={true}
                formLink={formLink}
                choices={this.state.dashboardPositionChoices}
                titleText="Where to position this dashboard in the dashboards list."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <CheckBox
                fieldName="is_pinned"
                labelText="Pin this dashboard to the sidebar for quick access."
                isRequired={false}
                formLink={formLink}
              />
            </div>
          </div>
          <hr className="my-4" />
          <div className="row">
            <div className="col-lg-6">
              <ServiceSelectorInput
                numChecks={formData.services_selected.length}
                fieldName="services_selected"
                formErrors={this.props.errors}
                labelText="Manually select checks"
                isRequired={false}
                titleText="Individually select checks."
                onShowDialog={this.handleChecksSelectionShowDialog}
              />
            </div>
            <div className="col-lg-6">
              <Select2
                fieldName="services_tags"
                labelText="Auto-select checks by tag"
                isRequired={false}
                formLink={formLink}
                choices={this.props.choices.services_tags}
                allowEmpty={true}
                multiple={true}
                closeOnSelect={false}
                placeholder="Select tags..."
                titleText="Include all present or future checks with any of the selected tags."
              />
            </div>
          </div>
          <div className="mb-2">
            <Label isRequired={false} labelText="Include the following checks:" />
          </div>
          <div className="row">
            <div className="col-lg-12 mb-2">
              <CheckBox
                fieldName="services_include_up"
                labelText="Up"
                isRequired={false}
                formLink={formLink}
                titleText="Include/exclude checks which are currently up."
              />
            </div>
            <div className="col-lg-12 mb-2">
              <CheckBox
                fieldName="services_include_down"
                labelText="Down"
                isRequired={false}
                formLink={formLink}
                titleText="Include/exclude checks which are currently down."
              />
            </div>
            <div className="col-lg-12 mb-2">
              <CheckBox
                fieldName="services_include_paused"
                labelText="Paused"
                isRequired={false}
                formLink={formLink}
                titleText="Include/exclude checks which are currently paused."
              />
            </div>
            <div className="col-lg-12 mb-2">
              <CheckBox
                fieldName="services_include_maintenance"
                labelText="Under maintenance"
                isRequired={false}
                formLink={formLink}
                titleText="Include/exclude checks which are manually marked for maintenance."
              />
            </div>
          </div>
        </SectionNavContent>
        <SectionNavContent sectionId={Pages.PAGE_METRICS} currentSectionId={this.state.currentPage}>
          <FormErrors errors={this.props.errors.__all__} />
          <div className="row mb-2">
            <div className="col-lg">
              <CheckBox
                fieldName="metrics_show_section"
                labelText="Show Metric Summary section"
                isRequired={true}
                formLink={formLink}
                titleText="Show/hide the Metric Summary section at the top of this dashboard."
              />
            </div>
          </div>
          {formData.metrics_show_section ? (
            <React.Fragment>
              <hr className="my-4" />
              <div className="row mb-2">
                <div className="col-lg">
                  <CheckBox
                    fieldName="metrics_for_all_checks"
                    labelText="Include all checks"
                    isRequired={false}
                    formLink={formLink}
                    titleText="Include metrics from all checks, not just the selected checks."
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </SectionNavContent>
        <SectionNavContent sectionId={Pages.PAGE_CHECKS} currentSectionId={this.state.currentPage}>
          <FormErrors errors={this.props.errors.__all__} />
          <div className="row mb-2">
            <div className="col-lg">
              <CheckBox
                fieldName="services_show_section"
                labelText="Show check cards"
                isRequired={true}
                formLink={formLink}
                titleText="Show/hide the check cards from this dashboard."
              />
            </div>
          </div>
          {formData.services_show_section ? (
            <React.Fragment>
              <hr className="my-4" />
              <div className="row">
                <div className="col-lg-6">
                  <SelectBox
                    fieldName="services_num_to_show"
                    labelText="Number of check cards"
                    isRequired={true}
                    formLink={formLink}
                    choices={this.props.choices.services_num_to_show}
                    titleText="Number of check cards to show in the Checks section."
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <SelectBox
                    fieldName="services_primary_sort"
                    labelText="Primary sort order"
                    isRequired={true}
                    formLink={formLink}
                    choices={this.props.choices.services_sort}
                    titleText="The primary value for ordering the checks."
                  />
                </div>
                <div className="col-lg-6">
                  <SelectBox
                    fieldName="services_secondary_sort"
                    labelText="Secondary sort order"
                    isRequired={true}
                    formLink={formLink}
                    choices={this.props.choices.services_sort}
                    /* eslint-disable-next-line max-len */
                    titleText="The secondary value for ordering the checks, if the primary value is the same."
                  />
                </div>
              </div>
              <div className="mb-2">
                <Label isRequired={false} labelText="Display on check cards:" />
              </div>
              <div className="row">
                <div className="col-lg-12 mb-2">
                  <CheckBox
                    fieldName="services_show_uptime"
                    labelText="Uptime (24h)"
                    isRequired={false}
                    formLink={formLink}
                    titleText="Display each check's 24-hour uptime percentage on the check cards."
                  />
                </div>
                <div className="col-lg-12 mb-2">
                  <CheckBox
                    fieldName="services_show_response_time"
                    labelText="Response Time"
                    isRequired={false}
                    formLink={formLink}
                    titleText="Display each check's response time graph or metric on the check cards."
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </SectionNavContent>
        <SectionNavContent sectionId={Pages.PAGE_ALERTS} currentSectionId={this.state.currentPage}>
          <FormErrors errors={this.props.errors.__all__} />
          <div className="row mb-2">
            <div className="col-lg">
              <CheckBox
                fieldName="alerts_show_section"
                labelText="Show Latest Alerts"
                isRequired={true}
                formLink={formLink}
                titleText="Show/hide the Latest Alerts section from this dashboard."
              />
            </div>
          </div>
          {formData.alerts_show_section ? (
            <React.Fragment>
              <hr className="my-4" />
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <CheckBox
                    fieldName="alerts_for_all_checks"
                    labelText="Show alerts for all checks"
                    divCSSClass="mb-2"
                    isRequired={false}
                    formLink={formLink}
                    titleText="Include alerts from all checks, not just the selected checks."
                  />
                  <CheckBox
                    fieldName="alerts_include_ignored"
                    labelText="Include ignored alerts"
                    divCSSClass="mb-2"
                    isRequired={false}
                    formLink={formLink}
                    /* eslint-disable-next-line max-len */
                    titleText='Include/exclude alerts marked as "ignored" or under maintenance in the alerts listing.'
                  />
                  <CheckBox
                    fieldName="alerts_include_resolved"
                    labelText="Include resolved alerts"
                    divCSSClass="mb-2"
                    isRequired={false}
                    formLink={formLink}
                    titleText="Include/exclude alerts that are no longer down in the alerts listing."
                  />
                </div>
                <div className="col-lg-6">
                  <SelectBox
                    fieldName="alerts_num_to_show"
                    labelText="Number of alerts to show"
                    isRequired={true}
                    formLink={formLink}
                    choices={this.props.choices.alerts_num_to_show}
                    titleText="Number of alerts to show in the Latest Alerts section."
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </SectionNavContent>
      </SectionNav>
    );
  }

  renderChecksSelectorSubModal(formLink) {
    return (
      <ServiceSelectorDialog
        servicesCheckListURL={this.props.servicesCheckListURL}
        fieldName="services_selected"
        formLink={formLink}
      />
    );
  }
}
