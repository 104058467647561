'use strict';

import React from 'react';
import ZendeskInfoIcon from './ZendeskInfoIcon.jsx';

export default class FeatureSection extends React.Component {
  renderAccessory(item) {
    if (this.props.isReadOnly) {
      return false;
    }
    return <div className="accessory">{item.accessory}</div>;
  }

  renderFeatureItem(item) {
    return (
      <div className="col-12 col-sm-6 col-lg-4 mb-4" key={item.title}>
        <div className="feature-block">
          {item.icon}
          <h5>{item.title}</h5>&nbsp;&nbsp;
          <ZendeskInfoIcon supportURL={item.supportURL} />
          <div>
            <small className="text-muted">{item.subtitle}</small>
          </div>
          {this.renderAccessory(item)}
        </div>
      </div>
    );
  }

  render() {
    const additionalFeaturesDef = this.props.items;
    const additionalFeatures = additionalFeaturesDef.map((item) => this.renderFeatureItem(item));
    return (
      <div className="bg-white rounded-8 mb-3" id="launchpad-additional-features">
        <div className="container mw-100">
          <h4 className="pb-4">{this.props.title}</h4>
          <div className="row">{additionalFeatures}</div>
        </div>
      </div>
    );
  }
}
