'use strict';

/**
 * Shared check data info.
 * Required keys:
 *      - key: Feature name as returned from AJAX call.
 *          This matches the column name in the database or otherwise returned by the API.
 *      - name: Human-readable name for this feature.
 * Optional keys:
 *      - units: Units of the data.
 *      - stateless: Set to true if passing/failing/better/worse is not relevant.
 *      - inverse: Set to true if a larger number means good for this feature (default smaller number is good).
 *      - stateThresholdPct: If abs(delta) is less than this (in percent), treat the delta as neutral.
 *      - shortName: Short name to use for legend, axis labels, etc.
 *      - isDataFeature: Whether or not this is a data feature (e.g. set false for a Country feature).
 *      - ajaxOnly: True if this feature applies only to AJAX data (no main pages).
 *      - mainOnly: True if this feature applies to main page views only (no ajax).
 *      - tooltip: Quick info about what this feature is (used for tooltips).
 *      - tooltipName: Feature name to use for tooltips. There are some cases where we want to display a more detailed name for the tooltip.
 */
export const checkFeature = (
  key,
  name,
  {
    units,
    stateless,
    inverse,
    stateThresholdPct,
    shortName,
    isDataFeature,
    ajaxOnly,
    mainOnly,
    tooltip,
    tooltipName,
  } = {}
) => {
  return {
    key,
    name,

    units,
    stateless,
    inverse,
    stateThresholdPct,
    tooltip,
    ajaxOnly,
    mainOnly,
    shortName: shortName || name,
    tooltipName: tooltipName || name,
    isDataFeature: isDataFeature === undefined ? true : !!isDataFeature,

    baseline: `${key}_B`,
    delta: `${key}_D`,
    deltaPct: `${key}_D_PCT`,
  };
};

// For brevity when defining features.
const inverse = true;
const stateless = true;
const ajaxOnly = true;
const mainOnly = true;

export const checkFeatures = {
  pageLoad: checkFeature('PageLoad', 'Page load', {
    units: 'ms',
    shortName: 'Load time',
    mainOnly,
    tooltip: 'Full load time for main pages (non-AJAX)',
  }),

  tti: checkFeature('TTI', 'Time to interactive', {
    units: 'ms',
    shortName: 'TTI',
    mainOnly,
    tooltip: 'Time elapsed before a page becomes fully interactive',
  }),

  pageViews: checkFeature('PageViews', 'Page views', {
    stateless,
    mainOnly,
    tooltip: 'Number of page views, excluding AJAX views',
  }),

  ajaxViews: checkFeature('AjaxViews', 'Ajax calls', {stateless, ajaxOnly, tooltip: 'Total number of AJAX events'}),

  hits: checkFeature('Hits', 'Views', {stateless, tooltip: 'Combined number of main page views and AJAX calls'}),

  sessions: checkFeature('Sessions', 'Sessions', {
    stateless,
    tooltip: 'Number of unique user sessions that started at this URL',
  }),

  bounceRate: checkFeature('BounceRate', 'Bounce rate', {
    units: '%',
    mainOnly,
    tooltip: '(Number of bounced sessions * 100) / TotalSessions',
  }),

  ajaxLoad: checkFeature('AjaxLoad', 'Ajax load', {
    units: 'ms',
    ajaxOnly,
    tooltip: 'Time taken to make and process AJAX calls',
  }),

  apdex: checkFeature(
    'APDEX',
    'APDEX',
    // eslint-disable-next-line max-len
    {
      inverse,
      mainOnly,
      tooltip: 'Overall performance, calculated as (SatisfiedCount + (0.5 * ToleratingCount)) / TotalCount',
    }
  ),

  apdexThreshold: checkFeature('apdex_threshold', 'APDEX Threshold', {
    mainOnly,
    tooltip: 'Time threshold that separates satisfied and tolerating page load',
  }),

  satisfied: checkFeature('SatisfiedCount', 'Satisfied', {
    inverse,
    mainOnly,
    tooltip: 'Number of events where time to interactive <= APDEX threshold',
  }),

  tolerating: checkFeature('ToleratingCount', 'Tolerating', {
    mainOnly,
    tooltip: 'Number of events where time to interactive is 1-4x the APDEX threshold',
  }),

  frustrated: checkFeature('FrustratedCount', 'Frustrated', {
    mainOnly,
    tooltip: 'Number of events where time to interactive is >4x the APDEX threshold',
  }),

  httpErrorRate: checkFeature('HTTPErrorRate', 'Error rate', {
    units: '%',
    tooltip: 'Percent of HTTP method calls that result in an error',
  }),

  httpErrorCode: checkFeature('HTTPErrorCode', 'Error Code', {tooltip: 'Number code for HTTP errors'}),

  httpErrors: checkFeature('HTTPErrors', 'Error count', {tooltip: 'Total count of HTTP errors'}),

  errorRate4xx: checkFeature('ErrorRate4xx', '4xx error rate', {
    units: '%',
    shortName: '4xx',
    tooltip: 'Percent of page views and AJAX calls with 4xx errors',
  }),

  errorRate5xx: checkFeature('ErrorRate5xx', '5xx error rate', {
    units: '%',
    shortName: '5xx',
    tooltip: 'Percent of page views and AJAX calls with 5xx errors',
  }),

  errorRateJS: checkFeature('ErrorRateJS', 'JS error rate', {
    units: '%',
    shortName: 'JS',
    tooltip: 'Percent of page views and AJAX calls with JS errors',
  }),

  // TODO: can this be removed?
  dnsEnd: checkFeature('DNSEnd', 'DNS end', {units: 'ms'}),

  connectEnd: checkFeature('ConnectEnd', 'Connection end', {
    units: 'ms',
    tooltip: 'Time when server connection is established and browser is ready to receive content',
  }),

  responseStart: checkFeature(
    'ResponseStart',
    'Response start',
    // eslint-disable-next-line max-len
    {
      units: 'ms',
      tooltip: 'Time when browser receives the first byte of the response (from server, cache, or local resource)',
    }
  ),

  responseEnd: checkFeature(
    'ResponseEnd',
    'Response end',
    // eslint-disable-next-line max-len
    {
      units: 'ms',
      tooltip: 'Time when browser receives the last byte of the response (from server, cache, or local resource)',
    }
  ),

  domInteractive: checkFeature('DOMInteractive', 'DOM interactive', {
    units: 'ms',
    tooltip: 'Time when resource parsing is finished, before running first JavaScript',
  }),

  domLoaded: checkFeature('DOMContentLoaded', 'DOM Content loaded', {
    units: 'ms',
    tooltip: 'Time when initial JavaScript scripts have been run',
  }),

  assets: checkFeature('DOMComplete', 'DOM complete', {
    units: 'ms',
    tooltip: 'Time when all scripts are finished and all blocking resources are loaded',
  }),

  loadEventEnd: checkFeature('LoadEventEnd', 'Load event end', {
    units: 'ms',
    tooltip: 'Time when the load event of the current document is completed',
  }),

  firstByte: checkFeature(
    'FirstByte',
    'First byte',
    // eslint-disable-next-line max-len
    {
      units: 'ms',
      mainOnly,
      tooltip:
        'Elapsed time between the browser making and receiving the first byte of the ' +
        'response for main page views only. This is a good indication of server processing time',
    }
  ),

  ajaxFirstByte: checkFeature(
    'AjaxFirstByte',
    'First byte',
    // eslint-disable-next-line max-len
    {
      units: 'ms',
      ajaxOnly,
      tooltip: 'Elapsed time between the browser making and receiving the first byte of the response (AJAX calls only)',
    }
  ),

  firstPaint: checkFeature(
    'FirstPaint',
    'First paint',
    // eslint-disable-next-line max-len
    {
      units: 'ms',
      mainOnly,
      tooltip: 'Time elapsed before the browser first renders significant content on screen (excluding AJAX calls)',
    }
  ),

  ajaxOnly: checkFeature('IsAjax', 'AJAX only?', {
    isDataFeature: false,
    tooltip: 'True if this feature applies only to AJAX calls',
  }),

  mainOnly: checkFeature('IsMain', 'Main pages only?', {
    isDataFeature: false,
    tooltip: 'True if this feature applies only to main page views',
  }),

  urlGroup: checkFeature('URLGroup', 'URL / Group', {
    isDataFeature: false,
    tooltip: 'Individual URL or pattern-matched URL group monitored by this check',
  }),

  startURL: checkFeature('StartURL', 'Session start URL', {isDataFeature: false}),

  device: checkFeature('Device', 'Device', {isDataFeature: false}),

  platform: checkFeature('Platform', 'Platform', {isDataFeature: false}),

  browser: checkFeature('Browser', 'Browser', {isDataFeature: false}),

  browserVersion: checkFeature('BrowserVersion', 'Browser Version', {isDataFeature: false}),

  country: checkFeature('Country', 'Country', {isDataFeature: false}),

  region: checkFeature('Region', 'Country Region', {isDataFeature: false}),
};
