/**
 * Shared color palettes for RUM pages.
 */

export const Colors = {
  black: '#000000',

  // https://coolors.co/17252c-0e0e78-1512b9-115d9d-66a1df-bedbfb-e2ecf8
  veryDarkBlue: '#17252c',
  darkBlue: '#0e0e78',
  blue: '#1512b9',
  mediumLightBlue: '#115d9d',
  lightBlue: '#66a1df',
  paleBlue: '#bedbfb',
  ghostBlue: '#e2ecf8',

  // https://coolors.co/1ec2cd-7662f0-f69335-fe86c7
  turquoise: '#1ec2cd',
  purple: '#7662f0',
  saffron: '#f69335',
  pink: '#fe86c7',

  // https://coolors.co/499d4b-c9d859-f9c752-f69335-eb5757
  green: '#499d4b',
  lightGreen: '#c9d859',
  yellowOrange: '#f9c752',
  orange: '#f69335',
  red: '#ce4242',
};
