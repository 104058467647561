export interface LegacyLogoProps {
  name: string;
  logoUrl?: string;
  companyWebsiteUrl?: string;
}

export const LegacyLogo = ({logoUrl, name, companyWebsiteUrl}: LegacyLogoProps) => {
  if (!logoUrl) {
    return null;
  }
  const logoImage = <img alt={name} src={logoUrl} className="img-responsive" />;
  if (companyWebsiteUrl) {
    return (
      <a href={companyWebsiteUrl} target="_blank">
        {logoImage}
      </a>
    );
  }
  return logoImage;
};
