'use strict';

import _ from 'underscore';
import React from 'react';

export default class ProgressBar extends React.PureComponent {
  render() {
    var total = this.props.results.length;
    var counts = _.countBy(this.props.results, 'state');
    var ok = counts['OK'] || 0;
    var warning = counts['WARNING'] || 0;
    var error = (counts['CRITICAL'] || 0) + (counts['UNKNOWN'] || 0);
    var max = this.props.isComplete ? total : Math.max(22, total + 1);

    var okWidth = (ok / max) * 100;
    var warningWidth = (warning / max) * 100;
    var errorWidth = (error / max) * 100;

    if (okWidth + warningWidth + errorWidth === 0) {
      okWidth = 1.0;
    }

    return (
      <div className="mt-5">
        <div className="white-block p-4">
          <p>The scan may take 1-2 minutes, thank you for your patience.</p>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-success"
              role="progressbar"
              aria-valuenow={Math.round(okWidth)}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{width: okWidth + '%'}}
            >
              <span className="sr-only">20% Complete (Passed)</span>
            </div>
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
              role="progressbar"
              aria-valuenow={Math.round(warningWidth)}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{width: warningWidth + '%'}}
            >
              <span className="sr-only">60% Complete (Warning)</span>
            </div>
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-danger"
              role="progressbar"
              aria-valuenow={Math.round(errorWidth)}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{width: errorWidth + '%'}}
            >
              <span className="sr-only">20% Complete (Error)</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
