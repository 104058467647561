import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import RadioGroup from '@/jskit/react/forms/RadioGroup';

export default class PauseConfirmationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {formData: {send_resolved_notifications: 't'}};
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal(itemIndexes, hasDownChecksToPause) {
    const sendResolvedNotifications = hasDownChecksToPause ? 't' : 'f';
    this.setState({
      itemIndexes: itemIndexes,
      hasDownChecksToPause,
      formData: {send_resolved_notifications: sendResolvedNotifications},
    });
    this.refs.modal_pause.showModal();
  }

  hideModal() {
    this.refs.modal_pause.hideModal();
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    let title = 'Confirm Pause and Resolve Notification?';
    if (this.props.isBulk) {
      if (this.state.hasDownChecksToPause) {
        title = 'Confirm Bulk Pause and Resolve Notification?';
      } else {
        title = 'Confirm Bulk Pause?';
      }
    }
    return (
      <Modal
        ref="modal_pause"
        title={title}
        size="lg"
        buttons={[
          <button
            key="btn-cancel"
            onClick={() => this.hideModal()}
            type="button"
            className="btn btn-outline-primary modal-btn-cancel"
          >
            Cancel
          </button>,
          <button
            key="btn-pause"
            onClick={() =>
              this.props.onSave(
                this.state.itemIndexes,
                'pause',
                this.state.formData.send_resolved_notifications === 't'
              )
            }
            type="button"
            className="btn btn-primary"
          >
            {this.props.isBulk ? 'Confirm' : 'Pause Check'}
          </button>,
        ]}
      >
        <form onSubmit={ReactUtils.doNotSubmit}>
          {!this.props.isBulk && (
            <p>
              <i className="fa-regular fa-triangle-exclamation mr-2"></i> An active alert exists for this check.
            </p>
          )}
          <p>
            Pausing the {this.props.isBulk ? 'selected checks' : 'check'} will stop monitoring and no further updates
            from {this.props.isBulk ? 'these checks' : 'the check'} will be sent.
          </p>
          {this.state.hasDownChecksToPause && (
            <>
              <p>
                Would you like to send a resolve notification for the active {this.props.isBulk ? 'alerts' : 'alert'}?
              </p>
              <p className="px-4">
                <RadioGroup
                  fieldName="send_resolved_notifications"
                  formLink={formLink}
                  divCSSClass="mb-2"
                  choices={[
                    ['t', 'Yes, send a resolve notification'],
                    ['f', 'No, do not send a resolve notification'],
                  ]}
                />
              </p>
            </>
          )}
        </form>
      </Modal>
    );
  }
}

PauseConfirmationForm.defaultProps = {
  isBulk: false,
};
