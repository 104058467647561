'use strict';

import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import Select2 from '../../jskit/react/forms/Select2';
import TextInput from '../../jskit/react/forms/TextInput';

// TODO: move constants to backend if they will be used anywhere else
const defaultBulkSensitivity = 2;
const defaultBulkNumberOfRetries = 2;

export default class BulkRetryAndSensitivityForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        msp_sensitivity: defaultBulkSensitivity,
        msp_num_retries: defaultBulkNumberOfRetries,
        msp_threshold: '',
      },
    };
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal() {
    this.refs.modal_contact.showModal();
    this.setState({
      formData: {
        msp_sensitivity: defaultBulkSensitivity,
        msp_num_retries: defaultBulkNumberOfRetries,
        msp_threshold: '',
      },
    });
  }

  hideModal() {
    this.refs.modal_contact.hideModal();
  }

  render() {
    if (!(this.props.choices.msp_sensitivity && this.props.choices.msp_num_retries)) {
      return null;
    }
    // extract sensitivity and retries field defs from HTTP form definition
    const httpFormChoices = this.props.choices.service_defs.HTTP;
    const mspSensitivityDef = _.findWhere(httpFormChoices.fields, {field: 'msp_sensitivity'});
    const mspNumRetriesDef = _.findWhere(httpFormChoices.fields, {field: 'msp_num_retries'});
    const mspThresholdDef = _.findWhere(httpFormChoices.fields, {field: 'msp_threshold'});
    if (!(mspSensitivityDef && mspNumRetriesDef && mspThresholdDef)) {
      return null;
    }
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    return (
      <Modal
        ref="modal_contact"
        title="Set sensitivity, number of retries and timeout"
        cancelButton={true}
        saveButton={this.props.onSave}
      >
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>
              Note: The current sensitivity, retry and timeout settings (if specified) on selected checks will be
              replaced with the new settings chosen below.
            </em>
          </p>
          <Select2
            choices={this.props.choices.msp_sensitivity}
            fieldName="msp_sensitivity"
            formLink={formLink}
            labelText={mspSensitivityDef.verbose_name}
            titleText={mspSensitivityDef.title}
          />
          <Select2
            choices={this.props.choices.msp_num_retries}
            fieldName="msp_num_retries"
            formLink={formLink}
            labelText={mspNumRetriesDef.verbose_name}
            titleText={mspNumRetriesDef.title}
          />
          <TextInput
            fieldName="msp_threshold"
            formLink={formLink}
            labelText={mspThresholdDef.verbose_name}
            titleText={mspThresholdDef.title}
            placeholder="no change"
          />
        </form>
      </Modal>
    );
  }
}
