import {SelectArrayOptions} from '../SelectArrayOptions';
import React from 'react';

//This component receives the select options via props from the parent because the options
// come from a single endpoint that returns other options that are used in the parent component
// this reduces the number of requests to the backend
export const ServiceSelectorFilters = ({
  setSearch,
  setType,
  setTag,
  typeOptions,
  tagOptions,
  search,
  type,
  tag,
}: {
  search: string;
  setSearch: (e) => void;
  setType: (value) => void;
  type: [string, string];
  typeOptions: any[];
  setTag: (value) => void;
  tag: [string, string];
  tagOptions: any[];
}) => {
  return (
    <div className="row mb-3">
      <div className="col-lg-6 col-sm-12 mb-2">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fas fa-search" />
            </span>
          </div>
          <input
            type="text"
            className={'form-control'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="col-lg-6 col-sm-12 mb-2">
        <div className={'row'}>
          <div className={'col-sm-6'}>
            <SelectArrayOptions
              labelIndex={1}
              onChange={setType}
              value={type}
              options={typeOptions}
              getOptionLabel={(option) => option[1]}
              isClearable
              placeholder="-- Type --"
            />
          </div>
          <div className={'col-sm-6'}>
            <SelectArrayOptions
              labelIndex={1}
              onChange={setTag}
              value={tag}
              options={tagOptions}
              getOptionLabel={(option) => option[1]}
              getOptionValue={(option) => option[1]}
              isClearable
              placeholder="-- Tag --"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
