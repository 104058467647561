'use strict';

import React from 'react';
import './UIABanner.css';

const UIABanner = ({url, csrftoken, value}) => (
  <div className="rounded-8 banner-border justify-start mb-3 p-4 bg-white">
    <div className="row">
      <div className="col-8">
        <h4 className="text-left mb-2">Uptime Intelligent Analyzer</h4>
        <p className="text-left text-muted font-14">
          {/* eslint-disable-next-line max-len */}
          The Uptime Intelligent Analyzer (also known as Monitor Entire Site) can generate recommended checks quickly
          and easily. It uses industry best practices to provide intelligent recommendations that ensure your
          monitoring, availability, reporting and alerts staying ahead of potential downtime.
        </p>
        <form method="POST" action={url}>
          <div className="row pt-3">
            <div className="col-sm col-md-5">
              <div className="form-group">
                <input
                  type="text"
                  defaultValue={value}
                  placeholder="Enter your domain URL here"
                  name="url"
                  className="form-control form-control-success mr-3"
                />
              </div>
            </div>
            <div className="col-sm col-md-2">
              <div className="form-group text-center text-sm-left mb-0 mb-sm-2 mt-1 mt-sm-0">
                <input type="submit" className="btn btn-success btn-primary" value="Analyze Now" />
              </div>
            </div>
          </div>
          <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
        </form>
      </div>
      <div className="col-4">
        <img className="float-right" src="/static/img/launchpad/banner-mes.svg" />
      </div>
    </div>
  </div>
);

export default UIABanner;
