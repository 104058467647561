import React from 'react';
import SubscriptionDurationPicker from './SubscriptionDurationPicker';
import CtaComparisonButtonGroup from './CtaComparisonButtonGroup';

const CtaBlurbComparisonButtonGroup = ({
  text_success,
  title_cont,
  copy,
  tooltip,
  freeTrial_or_paid,
  isAnnual,
  onChange,
}) => (
  <div className="Blurb-container shadow-sm">
    <h4>
      <span className="text-success">{text_success}</span> {title_cont}
    </h4>
    <p className="Blurb">{copy}</p>
    <div className="Subscription-Cta-btn-container mt-4">
      {freeTrial_or_paid === 'paid' ? (
        <SubscriptionDurationPicker tooltip={tooltip} isAnnual={isAnnual} disabled={true} />
      ) : (
        <SubscriptionDurationPicker className="" isAnnual={isAnnual} onChange={onChange} />
      )}
      <CtaComparisonButtonGroup className="CtaComparisonButtonGroup" />
    </div>
  </div>
);

export default CtaBlurbComparisonButtonGroup;
