'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import Chart from 'react-apexcharts';
import {defaultGraphOptions} from './GraphFormatter.js';
import {defaultFormatter} from '../RumUtils.jsx';

export default class WorldMapLegend extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    const defaultOptions = defaultGraphOptions();
    this.state = {
      series: [],
      options: {
        chart: Object.assign(defaultOptions.chart, {
          toolbar: {
            show: false,
          },
        }),
        title: {
          text: this.props.feature.name,
          align: 'center',
          offsetY: 30,
        },
        colors: [],
        legend: Object.assign(defaultOptions.legend, {
          position: 'bottom',
          horizontalAlign: 'center',
          onItemClick: {
            toggleDataSeries: false,
          },
        }),
        tooltip: {
          enabled: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          show: false,
        },
      },
    };
    const parsedData = this._parseData(this.props.bins);
    this._setData(parsedData);
  }

  componentDidUpdate(previousProps) {
    if (this.props.bins !== previousProps.bins) {
      const parsedData = this._parseData(this.props.bins);
      this._setData(parsedData);
      this.forceUpdate();
    }
  }

  _parseData(bins) {
    const series = [];
    for (const [ix, bin] of (bins || []).entries()) {
      const lowerVal = ix === 0 ? 0 : bins[ix - 1].upper;
      const lower = defaultFormatter(lowerVal, 2, this.props.feature);
      let name;
      if (bin.upper === Infinity) {
        name = `${lower}+`;
      } else {
        name = `${lower} - ${defaultFormatter(bin.upper, this.props.precision || 2, this.props.feature)}`;
      }
      const data = [[0, lowerVal]];
      series.push({name, data});
    }
    return series;
  }

  /**
   * Set data state directly (not via setState) so that it can be parsed in the constructor.
   * This is necessary for the graph to appear correctly on its initial render.
   */
  _setData(parsedData) {
    this.state.series = parsedData || [];
    this.state.options.colors = (this.props.bins || []).map((bin) => bin.color);
  }

  render() {
    const containerStyle = {display: 'flex', flexDirection: 'row', alignItems: 'center'};
    return (
      <div className="world-map-legend-container" style={containerStyle}>
        {this.state.header}
        <Chart options={this.state.options} series={this.state.series} width="500px" height="80px" />
      </div>
    );
  }
}
