'use strict';

import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import URLHistory from '../../jskit/general/URLHistory';
import Ajax from '../../jskit/general/Ajax';
import Paginator from '../../jskit/react/Paginator';
import {AlertHistoryList} from '../common/AlertHistoryList';
import {AlertDetailsModal} from '../common/AlertDetailsModal';
import MessageBox from '../../js/global/messagebox';

export default class AlertHistoryController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      hasLoaded: false,
      items: [],
      total: 0,
      itemDetailsIndex: null,
      filters: URLHistory.queryStringToObject(URLHistory.currentURL()),
    };
  }

  componentDidMount() {
    this.loadAllData(true);
  }

  loadAllData(shouldShowAlert) {
    let query = {};
    query = _.extend(query, {filters: _.pick(this.state.filters, 'device_id', 'service_id')});
    query = _.extend(query, this.refs.paginator.getQueryOffsetLimit());

    new Ajax().post({
      url: this.props.listURL,
      data: query,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({hasLoaded: true, items: data.items, total: data.total});

        if (shouldShowAlert && this.props.showId) {
          const index = _.findIndex(
            data.items,
            function (item) {
              return item.id.toString() === this.props.showId;
            }.bind(this)
          );

          if (index >= 0) {
            this.handleDetailsClick(index);
          }
        }
      }.bind(this),
    });
  }

  showModalWithMessage(title, msg) {
    MessageBox.alertBox(msg, title);
  }

  handlePageChange() {
    this.loadAllData();
  }

  handleIgnoreClick(itemIndex, e) {
    e.preventDefault();
    const item = this.state.items[itemIndex];
    new Ajax().post({
      url: this.props.ignoreURL,
      data: {id: item.id, service_id: item.service_id},
      encoder: 'url',
      decoder: 'json',
      success: function (data) {
        if (!data.success) {
          this.showModalWithMessage('System Error', 'Unable to ignore alert, please contact support.');
        } else {
          this.loadAllData();
        }
      }.bind(this),
    });
  }

  handleDetailsClick(itemIndex, e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({itemDetailsIndex: itemIndex, alertExtData: null});
    this.refs.modal.showModal();
  }

  render() {
    return (
      <React.Fragment>
        <AlertHistoryList
          hasWriteAccess={this.props.hasWriteAccess}
          hasLoaded={this.state.hasLoaded}
          editCheckURL={this.props.editCheckURL}
          analysisURL={this.props.analysisURL}
          onDetailsClick={this.handleDetailsClick}
          onIgnoreClick={this.handleIgnoreClick}
          items={this.state.items}
        />
        <AlertDetailsModal
          ref="modal"
          alert={this.state.items[this.state.itemDetailsIndex] || {}}
          alertExtDataURL={this.props.alertExtDataURL}
          editCheckURL={this.props.editCheckURL}
          analysisURL={this.props.analysisURL}
          hasWriteAccess={this.props.hasWriteAccess}
          stepDefs={this.props.stepDefs}
        />
        <Paginator
          ref="paginator"
          numRecordsOnCurrentPage={this.state.items.length}
          onPageChange={this.handlePageChange}
        />
      </React.Fragment>
    );
  }
}
