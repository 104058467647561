'use strict';

import React from 'react';
import ZendeskSupportLink from '../jskit/react/forms/ZendeskSupportLink';

export default class ZendeskInfoIcon extends React.Component {
  renderInfoIcon() {
    // the only reason to keep this svg here rather than in a separate file is to be able
    // to take advantage of `currentColor` to be able to recolor paths via .css (won't work
    // for externally linked svg
    // TODO: replace with FA icon when we migrate to FA6.x
    return (
      <svg width="5" height="12" viewBox="0 0 5 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* eslint-disable-next-line max-len */}
        <path
          d="M4.81601 10.415C4.60556 10.0315 4.12307 9.89117 3.73957 10.1021C3.73957 10.1021 3.10127 10.4397 3.12565 9.99018C3.13548 9.91143 3.14021 9.87724 3.17387 9.72733L3.40083 8.72966L4.30888 4.73926L4.34013 4.6014C4.38718 4.39685 4.37504 4.17729 4.29236 3.96757C4.07477 3.41498 3.44978 3.14293 2.89719 3.36078L0.573529 4.27569C0.283517 4.3906 0.0797689 4.67667 0.087106 5.00702C0.0962132 5.43068 0.447197 5.76693 0.87138 5.75756L1.77826 5.73783L1.44496 8.38491L1.31692 9.39982C1.31692 9.39982 1.14933 10.2281 1.58549 10.9993C1.73987 11.2722 1.9803 11.5189 2.26513 11.6617C3.55004 12.3063 4.56913 11.4515 4.56913 11.4515C4.89895 11.2236 5.01386 10.7748 4.81601 10.4149L4.81601 10.415Z"
          fill="currentColor"
        />
        {/* eslint-disable-next-line max-len */}
        <path
          d="M4.70283 1.29429C4.70283 2.0091 4.12335 2.58857 3.40854 2.58857C2.69373 2.58857 2.11426 2.0091 2.11426 1.29429C2.11426 0.579474 2.69373 0 3.40854 0C4.12335 0 4.70283 0.579474 4.70283 1.29429Z"
          fill="currentColor"
        />
      </svg>
    );
  }

  render() {
    if (!this.props.supportURL) {
      return false;
    }
    return (
      <ZendeskSupportLink href={this.props.supportURL}>
        <span className="check-info d-inline-block">{this.renderInfoIcon()}</span>
      </ZendeskSupportLink>
    );
  }
}

ZendeskInfoIcon.defaultProps = {
  supportURL: null,
};
