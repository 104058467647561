import ReactUtils from '@/jskit/react/ReactUtils';
import css from './ThemeSwitchButton.module.css';

export interface ThemeSwitchButtonProps {
  onClick: () => void;
}

export const ThemeSwitchButton = ({onClick}: ThemeSwitchButtonProps) => {
  return (
    <>
      <button
        type="button"
        title="Switch to dark theme"
        onClick={onClick}
        className={ReactUtils.cssClass(css.ThemeSwitchButton, css.SwitchToDark, 'btn btn-link')}
      >
        <i className="fas fa-moon"></i>
      </button>
      <button
        type="button"
        title="Switch to light theme"
        onClick={onClick}
        className={ReactUtils.cssClass(css.ThemeSwitchButton, css.SwitchToLight, 'btn btn-link')}
      >
        <i className="fas fa-sun-bright"></i>
      </button>
    </>
  );
};
