'use strict';

import React from 'react';

class IntegrationRow extends React.PureComponent {
  render() {
    var buttons = null,
      error_mark = null;
    if (this.props.hasWriteAccess) {
      buttons = (
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-xs dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></button>
          <div className="dropdown-menu dropdown-menu-right">
            <a href="#" className="dropdown-item" onClick={this.props.onEdit}>
              Edit
            </a>
            <a href="#" className="dropdown-item" onClick={this.props.onShowTestForm}>
              Test
            </a>
            <a href="#" className="dropdown-item" onClick={this.props.onDelete}>
              Delete
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item" onClick={this.props.onShowErrors}>
              Error Log
            </a>
          </div>
        </div>
      );
    }
    if (this.props.is_errored) {
      error_mark = (
        <i
          className="fa fa-exclamation-circle text-danger"
          /* eslint-disable-next-line max-len */
          title="This integration profile has been disabled due to errors. Please check the “Error Log” under “Actions”."
        ></i>
      );
    }

    return (
      <tr>
        <td>
          {error_mark} {this.props.name}
        </td>
        <td className="text-muted">{this.props.module_verbose_name}</td>
        <td className="actions-right">{buttons}</td>
      </tr>
    );
  }
}

export default class IntegrationList extends React.PureComponent {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return <div>No profiles found.</div>;
    }

    var nodes = this.props.items.map(
      function (item, index) {
        return (
          <IntegrationRow
            {...item}
            key={item.id}
            hasWriteAccess={this.props.hasWriteAccess}
            onEdit={this.props.onEdit.bind(null, index)}
            onDelete={this.props.onDelete.bind(null, index)}
            onShowErrors={this.props.onShowErrors.bind(null, index)}
            onShowTestForm={this.props.onShowTestForm.bind(null, index)}
          />
        );
      }.bind(this)
    );

    return (
      <div className="white-block white-block-border mb-4">
        <table className="table table-responsive-sm data-table mb-0" role="grid">
          <thead>
            <tr>
              <th>Name</th>
              <th>Provider</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }
}
