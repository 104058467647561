'use strict';

import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import RadioGroup from '../../jskit/react/forms/RadioGroup';
import CheckGroup from '../../jskit/react/forms/CheckGroup';
import TimeInput from '../../jskit/react/forms/TimeInput';
import TextInput from '../../jskit/react/forms/TextInput';
import Formatter from '../../jskit/general/Formatter';
import DatePicker from '../../components/DatePicker/DatePicker';

import $ from 'jquery';

var preventDropdownClose = function (e) {
  e.stopPropagation();
};

export default class Schedule extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {
        schedule_state: null,
        schedule_entries: [],
      },
    };
  }

  componentDidMount() {
    $(document).on('click', '.weekly-schedule .dropdown', preventDropdownClose);
  }

  componentWillUnmount() {
    $(document).off('click', '.weekly-schedule .dropdown', preventDropdownClose);
  }

  componentDidUpdate(prevProps, prevState) {
    // if component's form data changed, notify parent component
    const {formData} = this.state;
    const oldFormData = prevState.formData;
    if (formData !== oldFormData && _.isFunction(this.props.onChange)) {
      this.props.onChange(formData);
    }
  }

  _getFormData() {
    return this.state.formData;
  }

  _setFormData(obj) {
    const newFormData = Object.assign({}, this.state.formData, obj);
    this.setState({formData: newFormData});
  }

  shouldWarn() {
    return this.state.formData.schedule_state === 'SCHEDULED' && this.state.formData.schedule_entries.length === 0;
  }

  serialize() {
    const v = Object.assign({}, this._getFormData());
    v.schedule_entries = _.map(v.schedule_entries, (e) => {
      delete e.singleDay;
      return e;
    });
    return v;
  }

  deserialize(schedule) {
    if (schedule.schedule_entries) {
      // adding singleDay property to differentiate between different kind of monthly entries
      var schedule_entries = _.map(schedule.schedule_entries, (e) => {
        e.singleDay = e.type === 'MONTHLY' && e.monthday_from === e.monthday_to;
        return e;
      });
    } else {
      schedule_entries = [];
    }
    this.setState({
      formData: {
        schedule_state: schedule.schedule_state,
        schedule_entries: schedule_entries,
      },
    });
  }

  handleScheduleDeleteClick(index, e) {
    e.preventDefault();

    const newEntries = this._getFormData().schedule_entries.slice();
    newEntries.splice(index, 1);

    this._setFormData({schedule_entries: newEntries});
  }

  handleAddOneOffEntry(e) {
    e.preventDefault();
    e.stopPropagation();
    const blank_entry = {
      type: 'ONCE',
      weekdays: [],
      from_time: '00:00:00',
      to_time: '00:00:00',
      once_start_date: moment(),
      once_end_date: moment().add(2, 'hour'),
      monthday_from: 0,
      monthday_to: 0,
    };
    var formData = this._getFormData();
    formData.schedule_entries.push(blank_entry);
    this._setFormData(formData);
  }

  handleAddWeeklyEntry(e) {
    e.preventDefault();
    e.stopPropagation();

    const blank_entry = {
      type: 'WEEKLY',
      weekdays: [],
      from_time: '00:00:00',
      to_time: '24:00:00',
      monthday_from: 0,
      monthday_to: 0,
    };
    var formData = this._getFormData();
    formData.schedule_entries.push(blank_entry);
    this._setFormData(formData);
  }

  handleAddMonthlyEntry(e, singleDay) {
    e.preventDefault();

    const blank_entry = {
      type: 'MONTHLY',
      weekdays: [],
      from_time: '00:00:00',
      to_time: '24:00:00',
      monthday_from: 1,
      monthday_to: singleDay ? 1 : 31,
      singleDay: singleDay !== undefined,
    };
    var formData = this._getFormData();
    formData.schedule_entries.push(blank_entry);
    this._setFormData(formData);
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this._getFormData();

    return (
      <div className="schedule">
        {this.renderAvailableStates(formLink, formData)}
        {this.renderErrors()}
        <div className={ReactUtils.cssClass({['d-none']: formData.schedule_state !== 'SCHEDULED'})}>
          {this.renderScheduleSection(formLink, formData)}
        </div>
      </div>
    );
  }

  renderErrors() {
    if (!this.props.errors) {
      return null;
    }

    const objErrors = _.pick(this.props.errors, 'schedule_state', 'schedule_entries');
    const newList = [];
    const listErrors = newList.concat.apply(newList, _.values(objErrors));

    return <FormErrors errors={listErrors} />;
  }

  renderAvailableStates(formLink) {
    const schedule_states = this.props.choices.schedule_states || [];

    const states = schedule_states.map((v) => [v[0], this.props.availableStates[v[0]]]).filter((v) => v[1]);

    return (
      <div className="form-group">
        <RadioGroup
          fieldName="schedule_state"
          isRequired={true}
          choices={states}
          formLink={formLink}
          divCSSClass="mb-1"
        />
      </div>
    );
  }

  renderScheduleSection(formLink) {
    const short_timezone = Formatter.timezoneAbbr();

    return (
      <React.Fragment>
        <div className="section one-off-schedule">
          <h5>One Off Schedule ({short_timezone})</h5>
          {this.renderOneOffTable({formLink})}
          <a className="add-rule" href="#" onClick={this.handleAddOneOffEntry}>
            + Add Schedule
          </a>
        </div>
        <div className="section weekly-schedule">
          <h5>Weekly Schedule ({short_timezone})</h5>
          {this.renderWeeklyTable()}
          <a className="add-rule" href="#" onClick={this.handleAddWeeklyEntry}>
            + Add Schedule
          </a>
        </div>

        <div className="section monthly-schedule">
          <h5>Monthly Schedule ({short_timezone})</h5>
          {this.renderMonthlyTable()}
          <div className="dropdown">
            <a
              href="#"
              className="add-rule"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              + Add Schedule
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="#" onClick={(e) => this.handleAddMonthlyEntry(e, true)}>
                Single day
              </a>
              <a className="dropdown-item" href="#" onClick={this.handleAddMonthlyEntry}>
                Date range
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  updateEntry(num, entry) {
    var formData = this._getFormData();
    formData.schedule_entries[num] = entry;
    this._setFormData(formData);
  }

  renderOneOffTable() {
    var entries = this._getFormData().schedule_entries.map((entry, index) => {
      if (entry.type !== 'ONCE') {
        return null;
      }

      return (
        <tr key={'of-' + index}>
          <td className="w-50">
            <DatePicker
              className=""
              isRequired={true}
              value={moment(entry.once_start_date)}
              timezone={window.TIMEZONE}
              onChange={(value) => {
                value = moment(value);
                entry.once_start_date = value.format();
                if (value.isSameOrAfter(moment(entry.once_end_date))) {
                  entry.once_end_date = moment(entry.once_start_date).add(1, 'hour').format();
                }
                this.updateEntry(index, entry);
              }}
            />
          </td>
          <td className="w-50">
            <DatePicker
              className=""
              isRequired={true}
              value={moment(entry.once_end_date)}
              timezone={window.TIMEZONE}
              onChange={(value) => {
                value = moment(value);
                entry.once_end_date = value.format();
                if (moment(entry.once_start_date).isSameOrAfter(moment(entry.once_end_date))) {
                  entry.once_end_date = moment(entry.once_start_date).add(1, 'hour').format();
                }
                this.updateEntry(index, entry);
              }}
            />
          </td>
          <td>
            <a
              href="#"
              onClick={this.handleScheduleDeleteClick.bind(null, index)}
              title="Delete entry"
              className="text-muted"
            >
              <i className="far fa-trash" />
            </a>
          </td>
        </tr>
      );
    });

    if (entries.filter((e) => e !== null).length > 0) {
      return (
        <div className="table-responsive-sm">
          <table className="schedule-entries">
            <tbody>
              <tr>
                <td>Start Date</td>
                <td>End Date</td>
                <td></td>
              </tr>
              {entries}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  }

  renderWeeklyTable() {
    var entries = this._getFormData().schedule_entries.map((entry, index) => {
      if (entry.type !== 'WEEKLY') {
        return null;
      }

      return (
        <tr key={'wr-' + index}>
          <td>
            <div className="dropdown weekday-selector">
              <button
                className={ReactUtils.cssClass('btn btn-link dropdown-toggle', {empty: entry.weekdays.length === 0})}
                type="button"
                id={'w-' + index}
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {this.formatWeekdays(entry.weekdays)}
              </button>
              <div className="dropdown-menu" aria-labelledby={'w-' + index}>
                <CheckGroup
                  fieldName="weekdays"
                  isRequired={false}
                  choices={this.props.choices.schedule_weekdays}
                  onChange={(e) => {
                    entry.weekdays = e.target.value;
                    this.updateEntry(index, entry);
                  }}
                  value={entry.weekdays}
                />
              </div>
            </div>
          </td>
          <td>
            <TimeInput
              onChange={(e) => {
                entry.from_time = e.target.value;
                this.updateEntry(index, entry);
              }}
              value={entry.from_time}
            />
          </td>
          <td>
            <TimeInput
              onChange={(e) => {
                entry.to_time = e.target.value;
                this.updateEntry(index, entry);
              }}
              value={entry.to_time}
            />
          </td>
          <td>
            <a
              href="#"
              onClick={this.handleScheduleDeleteClick.bind(null, index)}
              title="Delete entry"
              className="text-muted"
            >
              <i className="far fa-trash" />
            </a>
          </td>
        </tr>
      );
    });

    if (entries.filter((e) => e !== null).length > 0) {
      return (
        <div className="table-responsive-sm">
          <table className="schedule-entries">
            <tbody>
              <tr>
                <td>Day</td>
                <td>Start</td>
                <td>End</td>
                <td></td>
              </tr>
              {entries}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }

  validateDay(d) {
    if (d !== '') {
      return d > 0 && d <= 31;
    }
    return true;
  }

  renderMonthlyTable() {
    var entries = this._getFormData().schedule_entries.map((entry, index) => {
      if (entry.type !== 'MONTHLY') {
        return null;
      }
      if (entry.singleDay) {
        var days = (
          <TextInput
            addonPrefix="Day"
            inputGroupClass="input-group-sm"
            value={entry.monthday_from}
            onChange={(e) => {
              var v = e.target.value;
              if (this.validateDay(v)) {
                entry.monthday_from = v;
                entry.monthday_to = v;
                this.updateEntry(index, entry);
              }
            }}
          />
        );
      } else {
        days = (
          <React.Fragment>
            <TextInput
              addonPrefix="Start Date"
              inputGroupClass="input-group-sm"
              value={entry.monthday_from}
              onChange={(e) => {
                var v = e.target.value;
                if (this.validateDay(v)) {
                  entry.monthday_from = v;
                  this.updateEntry(index, entry);
                }
              }}
            />
            <TextInput
              addonPrefix="End Date"
              inputGroupClass="input-group-sm"
              value={entry.monthday_to}
              onChange={(e) => {
                var v = e.target.value;
                if (this.validateDay(v)) {
                  entry.monthday_to = v;
                  this.updateEntry(index, entry);
                }
              }}
            />
          </React.Fragment>
        );
      }

      return (
        <tr key={'mr-' + index}>
          <td className="monthday-selector">{days}</td>
          <td>
            <TimeInput
              onChange={(e) => {
                entry.from_time = e.target.value;
                this.updateEntry(index, entry);
              }}
              value={entry.from_time}
            />
          </td>
          <td>
            <TimeInput
              onChange={(e) => {
                entry.to_time = e.target.value;
                this.updateEntry(index, entry);
              }}
              value={entry.to_time}
            />
          </td>
          <td>
            <a
              href="#"
              onClick={this.handleScheduleDeleteClick.bind(null, index)}
              title="Delete entry"
              className="text-muted"
            >
              <i className="far fa-trash" />
            </a>
          </td>
        </tr>
      );
    });

    if (entries.filter((e) => e !== null).length > 0) {
      return (
        <div className="table-responsive-sm">
          <table className="schedule-entries">
            <tbody>
              <tr>
                <td>Date</td>
                <td>Start</td>
                <td>End</td>
                <td></td>
              </tr>
              {entries}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }

  formatWeekdays(weekdays) {
    var names = _.filter(this.props.choices.schedule_weekdays, function (d) {
      return _.contains(weekdays, d[0].toString()) || _.contains(weekdays, parseInt(d[0]));
    });
    if (names.length > 0) {
      names = _.map(_.pluck(names, 1), (day) => day.substr(0, 3));
      return names.join(', ');
    }
    return 'Select day';
  }
}

Schedule.defaultProps = {
  availableStates: {
    ACTIVE: <span>Active</span>,
    SUPPRESSED: <span>Suppressed</span>,
    SCHEDULED: <span>Use the following schedule:</span>,
  },
  choices: {},
  errors: {},
  onChange: null,
};
