'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export default class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  focus() {
    this.refs.textInput.focus();
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className="form-group">
        <Label fieldId={this.htmlId} {...this.props} wizard={undefined} />
        {this.props.addonLabel || this.props.addonPrefix ? this.renderInputGroup(form) : this.renderInput(form)}
        {this.renderErrors(form)}
        <FieldHelpText {...this.props} />
      </div>
    );
  }

  renderErrors(form) {
    return <FieldErrors errors={form.errors} />;
  }

  renderInputGroup(form) {
    const addonClass = 'btn ' + this.props.addonClass;
    const inputGroupClass = 'input-group ' + this.props.inputGroupClass;
    return (
      <div className={inputGroupClass}>
        {this.props.addonPrefix ? (
          <div className="input-group-prepend">
            <span className="input-group-text">{this.props.addonPrefix}</span>
          </div>
        ) : null}

        {this.renderInput(form)}

        {this.props.addonLabel ? (
          <div className="input-group-append">
            <button className={addonClass} type="button" onClick={this.props.onAddonClick}>
              {this.props.addonLabel}
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  renderInput(form) {
    return (
      <input
        ref="textInput"
        type={this.props.inputType}
        data-wizard={this.props.wizard}
        id={this.htmlId}
        name={form.fieldName}
        className={ReactUtils.cssClass('form-control', this.props.extraInputCSS, {['is-invalid']: form.errors})}
        disabled={this.props.disabled}
        maxLength={this.props.maxLength}
        readOnly={this.props.readOnly}
        placeholder={this.props.placeholder}
        value={form.value}
        onChange={form.onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
      />
    );
  }
}

TextInput.defaultProps = {
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  placeholder: null, // Placeholder text
  isRequired: false, // Field is required?
  readOnly: false, // Field is read-only?
  disabled: false, // Control is disabled?
  inputType: 'text', // Type of input
  maxLength: null, // HTML maxlength for this field
  addonPrefix: null, // Text/HTML to place in the addon on the left of the field
  addonLabel: null, // Label for an addon button at the end of the field
  addonClass: 'btn-secondary', // Classes to apply for addon Button
  inputGroupClass: '', //Additional class to append to input group
  wizard: undefined, // Set a data-wizard attribute on the text field

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
  onAddonClick: null, // Handler when addon button is clicked
  onFocus: null, // Focus handler
  onBlur: null, // Blur handler

  extraInputCSS: null, // Extra CSS class to pass to <input>
};
