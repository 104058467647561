'use strict';

import React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Utils from '../../jskit/general/Utils';

const TOOLTIP_DELAY = 300;

export type Action = {
  onClick?: React.MouseEventHandler;
  href?: string;
  target?: string;
  rel?: string;
  className?: string;
  text: string; // tooltip or display text
  endsBlock?: boolean;
};

type QuickActionsProps = {
  actions: Action[];
  moreActionsText?: string;
  dropdownMenuClassName?: string;
  buttonsDisplayed?: number;
};

const DropdownAction = ({item}) => {
  if (typeof item.onClick === 'function') {
    return (
      <button className="dropdown-item btn-sm btn-link btn" onClick={item.onClick}>
        {item.text}
      </button>
    );
  }

  return (
    <a href={item.href} className="dropdown-item">
      {item.text}
    </a>
  );
};

const IconAction = ({onClick, className, text, endsBlock, target, rel, href}: Action) => {
  const elID = Utils.generateRandomUUID();
  if (onClick) {
    return (
      <button
        className={classnames('btn btn-sm btn-outline-light border-0 text-dark', {'mr-2': endsBlock})}
        onClick={onClick}
        data-for={elID}
        data-tip={text}
      >
        <i className={`fa-lg fa fa-regular ${className || 'fa-square-info'}`} />
        <ReactTooltip delayShow={TOOLTIP_DELAY} id={elID} />
      </button>
    );
  }

  return (
    <a
      target={target}
      rel={rel}
      role="button"
      href={href}
      data-for={elID}
      data-tip={text}
      className={classnames('btn btn-sm btn-outline-light border-0 text-dark', {'mr-2': endsBlock})}
    >
      <i className={`fa-lg fa fa-regular ${className || 'fa-square-info'}`} />
      <ReactTooltip delayShow={TOOLTIP_DELAY} id={elID} />
    </a>
  );
};

const QuickActions = ({actions, moreActionsText, buttonsDisplayed, dropdownMenuClassName}: QuickActionsProps) => {
  if (!actions || actions.length < 1) {
    return null;
  }

  let actionsDisplayed = 3;
  if (buttonsDisplayed >= 3 && buttonsDisplayed < actions.length) {
    actionsDisplayed = buttonsDisplayed;
  }

  const links = actions.slice(actionsDisplayed);
  const displayMoreID = Utils.generateRandomUUID();

  return (
    <span id="check-actions" className="d-flex justify-content-end text-dark">
      {actions.slice(0, actionsDisplayed).map((a) => (
        <IconAction key={a.text} {...a} />
      ))}
      {links && links.length > 0 && (
        <span className="dropdown d-flex">
          <button
            type="button"
            id="dropdownMenuButton"
            className="btn border-0 text-dark btn-outline-light btn-sm"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-for={displayMoreID}
            data-tip={moreActionsText || 'More Actions'}
          >
            <i className="fa fa-lg fa-regular fa-ellipsis-v" />
            <ReactTooltip delayShow={TOOLTIP_DELAY} id={displayMoreID} />
          </button>
          <ul className={`dropdown-menu font-14 ${dropdownMenuClassName}`} aria-labelledby="dropdownMenuButton">
            {links.map((i) => (
              <li key={i.text}>
                <DropdownAction item={i} />
                {i.endsBlock && <div className="dropdown-divider"></div>}
              </li>
            ))}
          </ul>
        </span>
      )}
    </span>
  );
};

export default QuickActions;
