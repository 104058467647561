'use strict';

import React from 'react';
import moment from 'moment';
import Ajax from '../../jskit/general/Ajax';
import AutoRefresh from '../../jskit/react/AutoRefresh';
import DateRangePicker from '../../jskit/react/forms/DateRangePicker';
import {Select2Widget} from '../../jskit/react/forms/Select2';
import Utils from '../../jskit/general/Utils';
import URLHistory from '../../jskit/general/URLHistory';

import {checkFeatures} from './CheckFeatures.js';
import {ReportDefs} from './ReportDefs.js';
import RumCheckDetailHeader from './RumCheckDetailHeader.jsx';
import RumCheckGraphs from './RumCheckGraphs.jsx';

export default class RumCheckDetailController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      aggregation: '',
      startDate: null,
      endDate: null,
      urlList: null,
      url: '',
      urlHash: '',
      headerData: {},
      tabData: {},
      activeTab: 'pageLoad',
      isAjax: false, // True if active URL filter is Ajax-only.
    };

    this.datePickerRef = React.createRef();
    this.graphRef = React.createRef();
    this.refreshRef = React.createRef();
  }

  componentDidMount() {
    URLHistory.addStateChangeListener(() => {
      this.readParamsFromURLAndLoad();
    });
    this.readParamsFromURLAndLoad();
  }

  readParamsFromURLAndLoad() {
    const queryString = URLHistory.queryStringToObject();
    this.setState(
      {
        aggregation: queryString.agg || this.props.aggregationType,
        startDate: queryString.start ? moment(queryString.start) : null,
        endDate: queryString.end ? moment(queryString.end) : null,
        urlHash: queryString.url || '',
      },
      this.loadAllData.bind(this)
    );
  }

  writeParamsToURLAndLoad() {
    const options = {};
    if (this.state.aggregation) {
      options.agg = this.state.aggregation;
    }
    if (this.state.startDate) {
      options.start = this.state.startDate.toISOString();
    }
    if (this.state.endDate) {
      options.end = this.state.endDate.toISOString();
    }
    if (this.state.urlHash) {
      options.url = this.state.urlHash;
    }

    URLHistory.pushState(options);

    this.loadAllData();
  }

  getLoadDataAjaxFilters() {
    const curDateRange = this.datePickerRef.current.getCurrentDateRange();
    return {
      agg: this.state.aggregation || null,
      start: curDateRange[0] && curDateRange[0].toISOString(),
      end: curDateRange[1] && curDateRange[1].toISOString(),
      url: this.state.urlHash || null,
    };
  }

  loadAllData() {
    const filters = this.getLoadDataAjaxFilters();
    new Ajax().post({
      url: this.props.headerURL,
      data: filters,
      encoder: 'json',
      decoder: 'json',
      success: function (response) {
        this.setState({headerData: Object.assign({}, response.data)});
      }.bind(this),
    });

    if (this.state.urlHash && !this.state.url) {
      // Load filter URL name when cold loading report by direct share URL with URL hash
      new Ajax().get({
        url: this.props.urlListURL,
        data: {url_hash: this.state.urlHash, start: filters.start, end: filters.end},
        decoder: 'json',
        success: function (response) {
          if (response.data.length) {
            this.setState({url: response.data[0].URLGroup});
          }
        }.bind(this),
      });
    }

    this.loadActiveTabData(true);
  }

  loadActiveTabData(clearAllTabs) {
    const activeTab = ReportDefs[this.state.activeTab];

    new Ajax().post({
      url: this.props.tabDataURL + '?tab=' + activeTab.key,
      data: this.getLoadDataAjaxFilters(),
      encoder: 'json',
      decoder: 'json',
      success: function (response) {
        const baseObj = clearAllTabs ? {} : this.state.tabData;
        const newState = {
          tabData: Object.assign({}, baseObj, {[activeTab.key]: response.data}),
        };
        if (this.state.activeTab === 'pageLoad' && response.data.donut) {
          const donutData = {};
          for (const feature of [checkFeatures.apdex, checkFeatures.satisfied]) {
            for (const key of [feature.key, feature.baseline, feature.deltaPct]) {
              donutData[key] = response.data.donut[key];
            }
          }
          newState.headerData = Object.assign({}, this.state.headerData, donutData);
        }
        this.setState(newState);
      }.bind(this),
    });

    this.graphRef.current.forceDataRefresh();
  }

  _onSegmentDataLoad(data) {
    // Check if active URL filter is Ajax-only.
    let isAjax = false;
    if (this.state.url) {
      const rows = (data || {}).table || [];
      const rowItem = rows.filter((row) => row.URLGroup === this.state.url)[0];
      isAjax = !!(rowItem && rowItem.isAjax);
    }
    this.setState({isAjax});
  }

  _onAggregationChange(e) {
    this.setState({aggregation: e.target.value}, this.writeParamsToURLAndLoad.bind(this));
  }

  _onDateRangeChange(start, end) {
    this.setState({startDate: start, endDate: end}, this.writeParamsToURLAndLoad.bind(this));
  }

  _onURLChange(e) {
    this.handleURLChange(e.target.value);
  }

  handleURLChange(urlHash, url) {
    if (url === undefined) {
      // when this is called from select2 widget, url is not passed, setting to true
      // to avoid loading url from server since it is already in the select2 widget
      url = true;
    }
    this.setState({urlHash, url}, this.writeParamsToURLAndLoad.bind(this));
  }

  _onClearURL() {
    this.handleURLChange('', '');
  }

  _onSwitchTab(tab) {
    // TODO: Disable caching tab data for now
    // const activeTabInfo = ReportDefs[tab];
    // const loadFn = this.state.tabData[activeTabInfo.key] ? null : this.loadActiveTabData.bind(this);
    // this.setState({ activeTab: tab }, loadFn);
    this.setState({activeTab: tab}, this.loadActiveTabData.bind(this));
  }

  _ajaxResultFn(data) {
    return data.data.map((group) => [group.URLGroupHash, group.URLGroup]);
  }

  _ajaxParamsFn(term) {
    const filters = this.getLoadDataAjaxFilters();
    return {
      q: term,
      start: filters.start,
      end: filters.end,
    };
  }

  render() {
    // Wait until the URL has been resolved from the hash to display in the select2 widget.
    const ajaxInitialChoices = this.state.url ? [[this.state.urlHash, this.state.url]] : [];

    return (
      <React.Fragment>
        <div className="heading-container d-block d-lg-flex">
          <h1 className="heading">
            {this.props.checkName}
            <small className="d-block text-muted font-18 font-weight-normal mt-n1">{this.props.mspAddress}</small>
          </h1>
          <div id="heading-controls">
            <div className="mr-2 mb-2 mb-sm-0" id="url-filter-container" title="Filter RUM results by URL">
              {!!this.state.urlHash && (
                <button
                  id="url-clear-button"
                  type="button"
                  title="Clear URL filter"
                  onClick={this._onClearURL}
                  className="btn"
                >
                  <i className="far fa-times-circle" />
                </button>
              )}
              <Select2Widget
                fieldName="url"
                placeholder="All URLs"
                value={this.state.urlHash}
                ajaxParamsFn={this._ajaxParamsFn}
                ajaxURL={this.props.urlListURL}
                ajaxInitialChoices={ajaxInitialChoices}
                onChange={this._onURLChange}
                minimumResultsForSearch={0}
                ajaxResultFn={this._ajaxResultFn}
                ajaxDelay={200}
                ajaxMinimumInputLength={2}
                dropdownAutoWidth={true}
              />
            </div>
            <div className="mr-2 mb-2 mb-sm-0" title="Time range to use in reports">
              <DateRangePicker
                ref={this.datePickerRef}
                timezone={window.TIMEZONE}
                openDirection="left"
                nullDateLabel="Last 30 Mins"
                enabledDateRanges="live,1h,3h,12h,24h,day0,day1,7d,30d,mon0,mon1,yr0,yr1"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this._onDateRangeChange}
              />
            </div>
            <div className="mr-2 mb-2 mb-sm-0">
              <Select2Widget
                fieldName="aggregation"
                onChange={this._onAggregationChange}
                value={this.state.aggregation}
                choices={this.props.choices.aggregation}
                titleText="Default aggregation type to use in reports"
              />
            </div>
            <div className="mr-2">
              <AutoRefresh ref={this.refreshRef} refreshFunction={this.loadAllData} />
            </div>
            <div>
              <button
                type="button"
                title="Toggle Full Screen"
                onClick={Utils.toggleFullScreenMode}
                className="btn btn-white d-none d-sm-inline-block"
              >
                <i className="fas fa-fw fa-expand-arrows-alt" />
              </button>
            </div>
          </div>
        </div>
        <RumCheckDetailHeader data={this.state.headerData} isAjax={this.state.isAjax} />
        <RumCheckGraphs
          ref={this.graphRef}
          checkName={this.props.checkName}
          data={this.state.tabData}
          headerData={this.state.headerData}
          activeTab={this.state.activeTab}
          onSwitchTab={this._onSwitchTab.bind(this)}
          onURLFilter={this.handleURLChange.bind(this)}
          segmentDataURL={this.props.segmentDataURL}
          getLoadDataAjaxFilters={this.getLoadDataAjaxFilters.bind(this)}
          isURLFilterActive={!!this.state.url}
          onSegmentDataLoad={this._onSegmentDataLoad.bind(this)}
          isAjax={this.state.isAjax}
        />
      </React.Fragment>
    );
  }
}
