'use strict';

import React from 'react';
import Formatter from '../../jskit/general/Formatter';
import {getBaselineComparison, renderBaselineComparison, isUnset, getTooltip} from './RumUtils.jsx';
import CheckRowSparkline from './graphs/CheckRowSparkline.jsx';
import {checkFeatures} from './CheckFeatures.js';

/** Overall check state enumeration, in the order they will be sorted for the table. */
const OVERALL_STATE = {
  BAD: 0,
  WARNING: 1,
  GOOD: 2,
  NO_DATA: 3,
  PAUSED: 4,
};

const getCheckState = (item) => {
  if (item.is_paused) {
    return OVERALL_STATE.PAUSED;
  }

  const apdexThreshold = item.apdex_threshold;
  const pageLoad = (item.rowData || {})[checkFeatures.pageLoad.key];
  if (isUnset(apdexThreshold) || isUnset(pageLoad)) {
    return OVERALL_STATE.NO_DATA;
  }

  if (pageLoad <= apdexThreshold) {
    return OVERALL_STATE.GOOD;
  } else if (pageLoad <= 4 * apdexThreshold) {
    return OVERALL_STATE.WARNING;
  }
  return OVERALL_STATE.BAD;
};

class RumCheckRow extends React.PureComponent {
  render() {
    const {graphData, overallStatus: status} = this.props.item;
    const rowData = this.props.item.rowData || {};
    const {pageViews, pageLoad, ajaxLoad, errorRate4xx, errorRate5xx, errorRateJS} = checkFeatures;

    const overallStatus = this.getOverallStatus(this.props.item);
    const pageViewsComparison = getBaselineComparison(pageViews, rowData);
    const pageLoadComparison = getBaselineComparison(pageLoad, rowData);
    const ajaxLoadComparison = getBaselineComparison(ajaxLoad, rowData);

    // Only display the worst error rate on this page.
    let errorRateFeature, errorRateData;
    for (const feature of [errorRate4xx, errorRate5xx, errorRateJS]) {
      const thisData = rowData ? rowData[feature.deltaPct] : undefined;
      if (!errorRateData || thisData > errorRateData) {
        errorRateData = thisData;
        errorRateFeature = feature;
      }
    }
    const errorRateComparison = getBaselineComparison(errorRateFeature, rowData);
    if (errorRateComparison) {
      errorRateComparison.subLabel = ` (${errorRateFeature.shortName})`;
    }

    const comparisonElements = [pageViewsComparison, pageLoadComparison, ajaxLoadComparison, errorRateComparison].map(
      (comparison, index) => {
        const comparisonFragment = comparison
          ? renderBaselineComparison(comparison, {subLabel: comparison.subLabel})
          : undefined;
        return (
          <td title={status.title} key={index}>
            {comparisonFragment}
          </td>
        );
      }
    );

    const timePoints = graphData ? graphData.map((datum) => datum['Time']) : [];
    const pageLoadPoints = graphData ? graphData.map((datum) => datum[pageLoad.key]) : [];
    const pageLoadBaseline = rowData[pageLoad.baseline];

    return (
      <React.Fragment>
        <tr style={{cursor: 'pointer'}}>
          <td>
            <i className={overallStatus.className} title={overallStatus.title} />
            {this.renderCheckName(this.props.item)}
          </td>
          {comparisonElements}
          <td>
            <CheckRowSparkline
              time={timePoints}
              feature={pageLoad}
              points={pageLoadPoints}
              baseline={pageLoadBaseline}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }

  renderCheckName(item) {
    const nameAndAddress = Formatter.serviceNameAndAddress(item);
    const link = this.props.extraListProps.rum2ReportURL + item.id;

    return (
      <React.Fragment>
        <a href={link} className="d-block text-dark font-weight-semibold">
          {nameAndAddress.name}
        </a>
        <a href={link} title={nameAndAddress.address} className="d-block unstyled">
          <span className="text-light">{nameAndAddress.addressShort}</span>
        </a>
      </React.Fragment>
    );
  }

  getOverallStatus(item) {
    let className = 'status';
    let title;
    const state = getCheckState(item);
    switch (state) {
      case OVERALL_STATE.BAD:
        className += ' status-danger';
        title = 'The average user experience is in the "frustrated" range.';
        break;
      case OVERALL_STATE.WARNING:
        className += ' status-warning';
        title = 'The average user experience is in the "tolerating" range.';
        break;
      case OVERALL_STATE.GOOD:
        className += ' status-success';
        title = 'The average user experience is in the "satisfied" range.';
        break;
      case OVERALL_STATE.PAUSED:
        className += ' status-paused';
        title = 'This check is paused.';
        break;
      case OVERALL_STATE.NO_DATA:
      default:
        className += ' status-muted';
        title = 'No data recorded yet. Check back later or try a different time range.';
        break;
    }
    return {className, title};
  }
}

export default class RumChecksList extends React.PureComponent {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return <div>No checks found</div>;
    }

    // Sort items by status so failing checks are prominent (on top).
    const itemsSorted = [...this.props.items].sort((a, b) => getCheckState(a) - getCheckState(b));

    const nodes = itemsSorted.map((item) => {
      return <RumCheckRow key={item.name} item={item} extraListProps={this.props.extraListProps} />;
    });

    return (
      <div className="rum-checks-list">
        <table className="rum-checks-list-table" role="grid">
          <thead>
            <tr>
              <th className="checks-list-header" title="RUM check name and URL.">
                RUM Check
              </th>
              <th className="checks-list-header" title={getTooltip(checkFeatures.pageViews)}>
                Page Views
              </th>
              <th className="checks-list-header" title={getTooltip(checkFeatures.tti)}>
                TTI
              </th>
              <th className="checks-list-header" title={getTooltip(checkFeatures.ajaxLoad)}>
                AJAX
              </th>
              <th className="checks-list-header" title="Error rate (showing highest delta among 4xx, 5xx, and JS).">
                Error Rate
              </th>
              <th
                className="checks-list-header"
                title="TTI Over the last 30 minutes, compared to previous 1-week baseline."
              >
                TTI over time
              </th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }
}
