import {SectionNavContent} from '@/jskit/react/SectionNav';
import {HistoryTabs} from '@/status_pages/display/components/tabs/History/History';
import ComponentHistory from '@/status_pages/display/ComponentHistory';
import {ComponentStatusValue, StatusPage} from '@/status_pages/types';
import {EmptySectionBan} from '@/status_pages/display/EmptySectionBan';

export interface ComponentHistorySectionProps {
  currentSectionId: HistoryTabs;
  statuspage: StatusPage;
  componentStatusRank: Record<ComponentStatusValue, number>;
  componentStatusChoices: Record<ComponentStatusValue, string>;
  historyStartDate: object;
  historyEndDate: object;
}

export const ComponentHistorySection = ({
  currentSectionId,
  statuspage,
  componentStatusRank,
  componentStatusChoices,
  historyStartDate,
  historyEndDate,
}: ComponentHistorySectionProps) => (
  <SectionNavContent sectionId={HistoryTabs.COMPONENT_HISTORY} currentSectionId={currentSectionId}>
    {statuspage.components?.length > 0 ? (
      <ComponentHistory
        statuspage={statuspage}
        historyStartDate={historyStartDate}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
        historyEndDate={historyEndDate}
        historyTypeDisplay={statuspage.uptime_calculation_type}
      />
    ) : (
      <EmptySectionBan>
        There is no <strong>Component History</strong>
      </EmptySectionBan>
    )}
  </SectionNavContent>
);
