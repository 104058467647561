import React from 'react';
import Utils from '../../jskit/general/Utils';
import Select2 from '../../jskit/react/forms/Select2';

import $ from 'jquery';

export default class SingleServiceSelectBox extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    if (!this.props.servicesCheckListURL) {
      throw 'SingleServiceSelectBox must be provided the "servicesCheckListURL" prop.';
    }
  }

  getSelectionData() {
    const data = this.refs.select2.getSelectionData();
    if (!data) {
      return data;
    }

    if (data.text.indexOf('~!~') !== -1) {
      const [name, addr, type] = data.text.split('~!~');
      return {
        id: data.id,
        name: name,
        addr: addr,
        type: type,
      };
    } else {
      return {
        id: data.id,
        name: data.text,
        addr: '',
        type: '',
      };
    }
  }

  formatServiceResult(val) {
    if (val.text.indexOf('~!~') !== -1) {
      const [name, addr, type] = val.text.split('~!~');
      return $(`
              <div class="font-14">
                <div class="font-12 text-muted float-right">${type}</div>
                <div>${name}</div>
                <div class="small text-muted">${addr}</div>
              </div>
            `);
    } else {
      return val.text;
    }
  }

  formatServiceSelection(val) {
    if (val.text.indexOf('~!~') !== -1) {
      const [name, , type] = val.text.split('~!~');
      return $(`<span>${name} <small class="font-12 text-muted ml-2">${type}</small></span>`);
    } else {
      return val.text;
    }
  }

  handleSearchRequest(searchTerms) {
    return {search: searchTerms, limit: this.props.ajaxLimit};
  }

  handleSearchResult(data) {
    const choices = data.choices.services.map((s) => [s.id, `${s.name}~!~${s.address}~!~${s.type}`]);
    if (this.props.withRemoveServiceOption) {
      return [[' ', '--- No Check --- ']].concat(choices);
    } else {
      return choices;
    }
  }

  render() {
    return (
      <Select2
        {...this.props}
        ref="select2"
        multiple={false}
        ajaxURL={this.props.servicesCheckListURL}
        ajaxParamsFn={this.handleSearchRequest}
        ajaxResultFn={this.handleSearchResult}
        templateResult={this.formatServiceResult}
        templateSelection={this.formatServiceSelection}
      />
    );
  }
}

SingleServiceSelectBox.defaultProps = {
  servicesCheckListURL: null, // URL for retrieving check choices
  ajaxInitialChoices: [], // Array of key-value pairs (id, name) for the initially selected items in the ajax box. These should match the form value.
  placeholder: 'Select a check...',
  ajaxLimit: null,
  withRemoveServiceOption: false,
  // ... other props per Select2
};
