import {ReactNode} from 'react';
import Formatter from '../../jskit/general/Formatter';
import classnames from 'classnames';
import _ from 'underscore';

const renderNumericValue = (value: number, units?: string) => {
  if (value < 1) {
    return;
  }

  if (units) {
    return `${Formatter.renderFormattedQuantity(value)} ${units}`;
  }
  return Formatter.renderFormattedQuantity(value);
};

interface Props {
  className?: string;
  value?: number;
  units?: string;
  title: string;
  tooltip: string;
  infoIcon?: ReactNode;
  actionButton?: ReactNode;
}

export const FeatureRow = ({value, units, title, tooltip, className, infoIcon, actionButton}: Props) => (
  <div className="position-relative">
    {infoIcon && <span className="position-absolute float-left infoIcon">{infoIcon}</span>}
    <div
      className={classnames(className)}
      key={`feature-row-${title}`}
      data-placement="top"
      data-toggle="tooltip"
      title={tooltip}
    >
      {value !== undefined && (
        <div className="feature-title d-flex align-items-center w-100">
          {_.isString(value) && <h2 className="mx-auto">{value}</h2>}
          {_.isNumber(value) && value > 0 && <h2 className=" mx-auto">{renderNumericValue(value, units)} </h2>}
          {_.isNumber(value) && value === 0 && (
            <i className="my-3 text-brand-gray fa-lg mx-auto fa-solid fa-circle-xmark"></i>
          )}
        </div>
      )}
      <div className={classnames('feature-content')}>
        <span className={classnames('text-center', {'font-weight-semibold font-20': value === undefined})}>
          {title}
        </span>
      </div>
    </div>
    {actionButton && <div>{actionButton}</div>}
  </div>
);

export const CustomFeatureRow = ({value, title, tooltip, className, infoIcon, actionButton}: Props) => {
  return (
    <div
      className={classnames(className, `tooltip-trigger-${title}`)}
      key={`feature-row-${title}`}
      data-original-title={tooltip}
      data-toggle="tooltip"
      data-placement="top"
      title={tooltip}
    >
      {value !== undefined && (
        <div className="feature-title d-flex align-items-center w-100">
          {infoIcon && <span className="position-absolute float-left">{infoIcon}</span>}
          {_.isString(value) && <h2 className="mx-auto">{value}</h2>}
          {_.isNumber(value) && value > 0 && <h2 className=" mx-auto">{renderNumericValue(value)}</h2>}
          {_.isNumber(value) && value === 0 && (
            <i className="my-3 text-brand-gray fa-lg mx-auto fa-solid fa-circle-xmark"></i>
          )}
        </div>
      )}
      <div className={classnames('feature-content')}>
        <span className={classnames('text-center', {'font-weight-semibold font-20': value === undefined})}>
          {title}
        </span>
      </div>
      {actionButton && <div>{actionButton}</div>}
    </div>
  );
};
