'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import Select2 from '../../jskit/react/forms/Select2';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';
import {SingleServiceSelectBox} from '../../devices/common/DevicesCommon';

import $ from 'jquery';

const DEFAULT_AUTO_STATUS = {
  auto_status_down: 'major-outage',
  auto_status_up: 'operational',
};

export default class ComponentForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {},
      validationErrors: {},
      serviceInitialChoices: [],
    };
  }

  getFormData() {
    const {service, auto_status_up, auto_status_down} = this.state.formData;
    return {
      ...this.state.formData,
      auto_status_up: service ? auto_status_up : '',
      auto_status_down: service ? auto_status_down : '',
    };
  }

  showModal(initialData) {
    if (initialData) {
      this.setState({
        formData: Object.assign({}, initialData),
        serviceInitialChoices: [
          [
            initialData.service || '',
            `${initialData.service_name}~!~${initialData.service_address}~!~${initialData.service_type}`,
          ],
        ],
      });
    } else {
      this.setState({
        formData: {
          ...DEFAULT_AUTO_STATUS,
          is_visible: true,
        },
        serviceInitialChoices: [],
      });
    }
    this.clearValidationErrors();
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleServiceChanged() {
    const service = this.refs.serviceSelectBox.getSelectionData();
    // Prefill the component name if it's empty
    if (!this.getFormData().name) {
      if (service) {
        this.setState({
          formData: {
            ...this.getFormData(),
            name: service.name,
          },
        });
      }
    }
    // if service is removed - reset auto set status fields
    if (!service.id.trim()) {
      this.setState({
        formData: {
          ...this.getFormData(),
          auto_status_down: '',
          auto_status_up: '',
        },
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.clearValidationErrors();

    new Ajax().post({
      url: this.props.updateURL,
      data: this.getFormData(),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.props.onNewData(data.data);
          this.hideModal();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  formatComponentStatus(val) {
    return $(`<span class="status-icon ${val.id ? val.id : 'no-change'}">${val.text}</span>`);
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    const formData = this.getFormData();
    const title = formData.id ? 'Edit Component' : 'Add Component';

    return (
      <Modal ref="modal" title={title} size="lg" saveButton={this.handleSubmit} cancelButton={true}>
        <form ref="form" className="form-spaced" onSubmit={this.handleSubmit}>
          {this.renderForm(formLink, formData)}
        </form>
      </Modal>
    );
  }

  renderForm(formLink) {
    const selectedService = this.getFormData().service;
    const isServiceSelected = !!selectedService && (selectedService + '').trim() !== '';

    const downStateOptions = [['', 'No Change']].concat(this.props.choices.componentFailureStatus);
    const upStateOptions = [['', 'No Change']].concat(this.props.choices.componentStatus);

    return (
      <React.Fragment>
        <FormErrors errors={this.state.validationErrors.__all__} />
        <div className="row mb-4">
          <div className="col-lg-12 font-14">
            {/* eslint-disable-next-line max-len */}
            Components represent the functional parts of your infrastructure, such as websites, API endpoints, or
            databases.
            {/* eslint-disable-next-line max-len */}
            &nbsp;
            <ZendeskSupportLink href="https://support.uptime.com/hc/en-us/articles/360016256840-Status-Page-Forms-and-Fields#components">
              View&nbsp;Documentation&nbsp;&rarr;
            </ZendeskSupportLink>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <TextInput
              fieldName="name"
              labelText="Component Name"
              placeholder="e.g. Website, Database, API"
              titleText="Name of components/system, e.g. Website, Database, API."
              isRequired={true}
              formLink={formLink}
            />
          </div>
          <div className="col-lg-6">
            <Select2
              fieldName="group"
              labelText="Component Group"
              /* eslint-disable-next-line max-len */
              titleText="Select an existing component group for this component, or &ldquo;Add Group&rdquo; on the prior screen."
              isRequired={false}
              choices={this.props.choices.componentGroups}
              allowEmpty={true}
              formLink={formLink}
            />
          </div>
        </div>
        <TextInput
          fieldName="description"
          labelText="Component Description"
          isRequired={false}
          titleText="OPTIONAL: Provide a description of this component for Page visitors."
          formLink={formLink}
        />
        <div className="row">
          <div className="col-lg-6">
            <SingleServiceSelectBox
              ref="serviceSelectBox"
              servicesCheckListURL={this.props.servicesCheckListURL}
              ajaxPreload={true}
              minimumResultsForSearch={0}
              ajaxLimit={50}
              fieldName="service"
              labelText="Associated Check"
              /* eslint-disable-next-line max-len */
              titleText="OPTIONAL: Link this component to a check to display response time. Checks can also affect component status."
              isRequired={false}
              formLink={formLink}
              withRemoveServiceOption={true}
              ajaxInitialChoices={this.state.serviceInitialChoices}
              onChange={this.handleServiceChanged}
            />
          </div>
          <div className="col-lg-6">
            <Select2
              fieldName="auto_status_down"
              labelText="If check fails, set component status to:"
              disabled={!isServiceSelected}
              titleText="Automatically change component status based on check state."
              isRequired={false}
              choices={downStateOptions}
              templateResult={this.formatComponentStatus}
              templateSelection={this.formatComponentStatus}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6" />
          <div className="col-lg-6">
            <Select2
              fieldName="auto_status_up"
              labelText="When check recovers, always set the component status to:"
              disabled={!isServiceSelected}
              /* eslint-disable-next-line max-len */
              titleText="Automatically change component status to the following state or select No Change for the state to never change until manually adjusted."
              isRequired={false}
              choices={upStateOptions}
              templateResult={this.formatComponentStatus}
              templateSelection={this.formatComponentStatus}
              formLink={formLink}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ComponentForm.defaultProps = {
  updateURL: null,
  servicesCheckListURL: null,
  onNewData: null,
};
