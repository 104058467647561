'use strict';

import $ from 'jquery';
import _ from 'underscore';
import React from 'react';
import Formatter from '../../../jskit/general/Formatter';
import {uniqueHtmlId, unpackFormLinkOrProps, CustomEvent, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export class SliderWidget extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = props.htmlId || uniqueHtmlId(props.fieldName);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const config = Object.assign({}, SliderWidget.defaultProps.sliderConfig, this.props.sliderConfig);

    $(this.refs.input).slider(config);
    $(this.refs.input).on('change', _.throttle(this.handleChange, 50));

    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps) {
      const prevConf = prevProps.sliderConfig;
      const newConf = this.props.sliderConfig;

      if (
        prevConf.min !== newConf.min ||
        prevConf.max !== newConf.max ||
        prevConf.step !== newConf.step ||
        prevConf.precision !== newConf.precision
      ) {
        $(this.refs.input).slider('setAttribute', 'min', newConf.min);
        $(this.refs.input).slider('setAttribute', 'max', newConf.max);
        $(this.refs.input).slider('setAttribute', 'step', newConf.step);
        $(this.refs.input).slider('setAttribute', 'precision', newConf.precision);
      }
    }

    const form = unpackFormLinkOrProps(this.props);
    const formVal = form.value;
    const sliderVal = this.props.formValueToSliderValue(formVal);
    $(this.refs.input).slider('setValue', sliderVal);
  }

  componentWillUnmount() {
    $(this.refs.input).slider('destroy');
  }

  handleChange(e) {
    const form = unpackFormLinkOrProps(this.props);

    const sliderVal = $(this.refs.input).slider('getValue');
    const formVal = this.props.sliderValueToFormValue(sliderVal);

    form.onChange(new CustomEvent({type: 'change', target: this, value: formVal}));
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className="slider-container">
        <input
          ref="input"
          type="text"
          id={this.htmlId}
          name={this.props.fieldName}
          className={ReactUtils.cssClass('form-control', {['is-invalid']: form.errors})}
        />
      </div>
    );
  }
}

SliderWidget.defaultProps = {
  fieldName: '', // Field name returned in form data

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler

  sliderConfig: {
    // Config passed to bootstrap-slider
    min: 0,
    max: 100,
    step: 1,
    precision: 0,
    handle: 'round',
  },

  sliderValueToFormValue: (x) => x, // Formats the bootstrap-slider value to the value saved in the form
  formValueToSliderValue: (x) => x, // Formats the form value to the value used by bootstrap-slider
};

export class NumericSliderWidget extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const sliderConfig = {
      min: this.props.min,
      max: this.props.max,
      step: this.props.step,
      precision: this.props.precision,
      tooltip: 'hide',
    };

    return <SliderWidget sliderConfig={sliderConfig} {...this.props} />;
  }
}

NumericSliderWidget.defaultProps = {
  // ...SliderWidget.defaultProps +
  min: 0,
  max: 100,
  step: 1,
  precision: 0,
};

export class TimeRangeSliderWidget extends React.Component {
  constructor(props) {
    super(props);

    this.sliderConfig = {
      range: true,
      min: 0.0,
      max: 24.0,
      step: 0.25,
      precision: 2,
      value: [0.0, 24.0],
      tooltip: 'always',
      tooltip_split: true,
      formatter: (v) => (_.isArray(v) ? this.formatTime(v[0]) + ' to ' + this.formatTime(v[1]) : this.formatTime(v)),
    };

    this.sliderValueToFormValue = (val) => [this.formatTime(val[0], true), this.formatTime(val[1], true)];
    this.formValueToSliderValue = (val) => [this.parseTime(val[0]), this.parseTime(val[1])];
  }

  formatTime(v, withSeconds) {
    var h = parseInt(v);
    var m = Math.round((v - h) * 60);
    var t = Formatter.pad(h, 2) + ':' + Formatter.pad(m, 2);
    t = withSeconds ? t + ':00' : t;
    return t;
  }

  parseTime(t) {
    t = t.split(':');
    var h = parseInt(t[0]);
    var m = parseInt(t[1]) / 60.0;
    return h + m;
  }

  render() {
    return (
      <SliderWidget
        sliderConfig={this.sliderConfig}
        sliderValueToFormValue={this.sliderValueToFormValue}
        formValueToSliderValue={this.formValueToSliderValue}
        {...this.props}
      />
    );
  }
}

export default class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);
    const childProps = Object.assign({}, this.props, {htmlId: this.htmlId});

    return (
      <div className="form-group">
        <Label fieldId={this.htmlId} {...this.props} />
        {this.props.widget === TimeRangeSliderWidget ? <div style={{marginBottom: '33px'}} /> : null}
        {React.createElement(this.props.widget, childProps)}
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

Slider.defaultProps = {
  // ...SliderWidget.defaultProps +
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?

  widget: NumericSliderWidget, // What kind of slider?
};
