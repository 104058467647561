'use strict';

import React from 'react';
import QuickActions from '../../devices/services/QuickActions';
import EmptyState from '../../website/common/EmptyState';

class ScheduledReportRow extends React.PureComponent {
  render() {
    const hasReport = !!this.props.report;
    const hasRecipients = this.props.recipient_users.length || this.props.recipient_emails.length;

    let report;
    if (!hasReport) {
      report = (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" /> No report selected
        </span>
      );
    } else if (!hasRecipients) {
      report = (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" /> No recipients selected
        </span>
      );
    } else {
      report = this.props.report.name;
    }

    return (
      <tr>
        <td>{this.props.name}</td>
        <td>
          {this.props.schedule_display} {this.props.timezoneName}
        </td>
        <td>{report}</td>
        <td className="text-center">
          {this.props.is_enabled && hasReport && hasRecipients ? (
            <i className="text-success fas fa-check" />
          ) : (
            <i className="text-danger fas fa-times" />
          )}
        </td>
        <td className="actions-right">
          {this.props.hasWriteAccess && (
            <QuickActions
              actions={[
                {
                  text: 'Edit Report',
                  onClick: this.props.onEdit,
                  className: 'fa-pen-to-square',
                },
                {
                  text: 'Duplicate',
                  onClick: this.props.onDuplicate,
                  className: 'fa-clone',
                },
                {
                  text: 'Delete',
                  onClick: this.props.onDelete,
                  className: 'fa-trash',
                },
              ]}
            />
          )}
        </td>
      </tr>
    );
  }
}

export default class ScheduledReportList extends React.PureComponent {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return this.renderEmptyState();
    }

    const nodes = this.props.items.map((item, index) => (
      <ScheduledReportRow
        {...item}
        key={item.id}
        hasWriteAccess={this.props.hasWriteAccess}
        siteURL={this.props.siteURL}
        timezoneName={this.props.timezoneName}
        onEdit={this.props.onEdit.bind(null, index)}
        onDuplicate={this.props.onDuplicate.bind(null, index)}
        onDelete={this.props.onDelete.bind(null, index)}
      />
    ));

    return (
      <div className="white-block">
        <table className="table table-responsive-sm data-table" role="grid">
          <thead>
            <tr>
              <th>Name</th>
              <th>Schedule</th>
              <th>Report</th>
              <th className="text-center">Enabled</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }

  renderEmptyState() {
    return (
      <EmptyState
        title="You have not yet added any scheduled reports... "
        subtitle="Add a scheduled report and configure contacts to receive it on regular basis."
        btnText="Create Scheduled report"
        onClick={this.props.handleAddItemClick}
        helpImage="/static/img/screenshots/scheduled_report-sm.png"
        helpTitle="What is a scheduled SLA report?"
        helpSubtitle="Scheduled SLA Report allows your organization to deliver periodic reports on specific checks or systems to users or specific email addresses"
        helpLinks={[
          {
            title: 'Overview of SLA Reports',
            link: 'https://support.uptime.com/hc/en-us/articles/360017118860-Creating-an-Uptime-com-SLA-Report-',
          },
        ]}
      />
    );
  }
}
