import React from 'react';
import SystemMetric from './SystemMetric';

export default class SystemMetrics extends React.PureComponent {
  render() {
    const allMetrics = this.props.metrics.map((metric, i) => (
      <SystemMetric metric={metric} key={i} datapointsURL={this.props.datapointsURL} />
    ));
    return <div>{allMetrics}</div>;
  }
}
