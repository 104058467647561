'use strict';

import React from 'react';
import $ from 'jquery';
import Utils from '../../jskit/general/Utils';
import Validators from '../../jskit/general/Validators';
import Modal from '../../jskit/react/Modal';
import ReactUtils from '../../jskit/react/ReactUtils';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import CheckBox from '../../jskit/react/forms/CheckBox';
import Select2 from '../../jskit/react/forms/Select2';
import SelectBox from '../../jskit/react/forms/SelectBox';
import ListInput from '../../jskit/react/forms/ListInput';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';

export default class ScheduledReportForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {
        recipient_users: [],
        recipient_emails: [],
      },
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialFormData) {
    let formData = {recipient_users: [], recipient_emails: []};

    if (initialFormData) {
      formData = Object.assign(formData, initialFormData);
    }

    this.setState({
      formData: formData,
    });
    this.refs.emailList.reset();
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  formatUserResult(val) {
    if (val.text.indexOf('~!~') !== -1) {
      const [name, email] = val.text.split('~!~');
      const a = $(`
              <div class="font-14">
                <div>${name}</div>
                <div class="small text-muted mt-n1">${email}</div>
              </div>
          `);
      return a;
    } else {
      return val.text;
    }
  }

  formatServiceSelection(val) {
    if (val.text.indexOf('~!~') !== -1) {
      const [name, email] = val.text.split('~!~');
      return name;
    } else {
      return val.text;
    }
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this.getFormData();
    const title = formData.id ? 'Edit Scheduled Report' : 'New Scheduled Report';
    return (
      <Modal ref="modal" title={title} size="lg" saveButton={this.props.onSave} cancelButton={true}>
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <input type="hidden" name="id" value={formData.id || ''} />
          {this.renderForm(formLink, formData)}
        </form>
      </Modal>
    );
  }

  renderForm(formLink, formData) {
    return (
      <React.Fragment>
        <FormErrors errors={this.props.errors.__all__} />
        <div className="font-14 mb-4">
          Scheduling the SLA Report allows your organization to deliver periodic reports on specific checks or systems
          to users or specific email addresses. &nbsp;
          <ZendeskSupportLink href="https://support.uptime.com/hc/en-us/articles/360006291539-Uptime-com-Reporting#scheduling-reports">
            View&nbsp;Documentation&nbsp;&rarr;
          </ZendeskSupportLink>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <TextInput fieldName="name" labelText="Schedule Name" isRequired={true} formLink={formLink} />
          </div>
          <div className="col-lg-6">
            <Select2
              fieldName="report"
              groups={this.props.choices.report}
              labelText="Report to Send"
              placeholder="Select a report"
              isRequired={true}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <SelectBox
              fieldName="file_type"
              labelText="File Type"
              isRequired={true}
              allowEmpty={false}
              formLink={formLink}
              choices={this.props.choices.file_type}
            />
          </div>
          <div className="col-lg-6">
            <SelectBox
              fieldName="recurrence"
              labelText="Recurrence"
              isRequired={true}
              allowEmpty={false}
              formLink={formLink}
              choices={this.props.choices.recurrence}
              /* eslint-disable-next-line max-len */
              titleText="How often to deliver this report. Monthly, Quarterly and Yearly reports are sent on the 1st of the appropriate month(s)."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <SelectBox
              fieldName="at_time"
              labelText={`At Time (${this.props.timezoneName})`}
              isRequired={true}
              allowEmpty={false}
              formLink={formLink}
              choices={this.props.choices.at_time}
              titleText="Deliver the report at this time (local time)."
            />
          </div>
          <div className="col-lg-6">
            <SelectBox
              formGroupClass={ReactUtils.cssClass('form-group', {['d-none']: formData.recurrence !== 'WEEKLY'})}
              fieldName="on_weekday"
              labelText="On Day"
              isRequired={true}
              allowEmpty={false}
              formLink={formLink}
              choices={this.props.choices.on_weekday}
              titleText="Weekly reports will be sent on this day, and cover the preceding 7 full days."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Select2
              fieldName="recipient_users"
              labelText="Send to Users"
              isRequired={false}
              allowEmpty={true}
              multiple={true}
              closeOnSelect={false}
              placeholder="Select users"
              formLink={formLink}
              templateResult={this.formatUserResult}
              templateSelection={this.formatServiceSelection}
              choices={this.props.choices.recipient_users}
              titleText="Users in your account that will receive these emailed reports."
            />
          </div>
          <div className="col-lg-6">
            <ListInput
              ref="emailList"
              fieldName="recipient_emails"
              labelText="Send to Emails"
              isRequired={false}
              inputType="email"
              placeholder="Enter email address"
              validationFn={Validators.isValidEmail}
              validationError="Please enter a valid email."
              titleText="Additional email addresses that will receive this report."
              formLink={formLink}
              alphabetical={true}
            />
          </div>
        </div>
        <div className="form-group">
          <CheckBox
            fieldName="is_enabled"
            formLink={formLink}
            divCSSClass="mb-0"
            isRequired={false}
            labelText="Scheduled enabled"
          />
        </div>
      </React.Fragment>
    );
  }
}
