'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import FileInput from '../../jskit/react/forms/FileInput';

export default class ImportSubscribersForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      numSubscribers: null,
      formData: {},
      validationErrors: {},
      importSuccessful: null,
      importSkipped: null,
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal() {
    new Ajax().get({
      url: this.props.importSubscribersURL,
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.setState({
            numSubscribers: data.data.subscribers_count,
            formData: {},
          });
          this.clearValidationErrors();
          this.refs.modal.showModal();
        }
      }.bind(this),
    });
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}, importSuccessful: null, importSkipped: null});
  }

  handleImport(e) {
    e.preventDefault();

    this.clearValidationErrors();

    new Ajax().post({
      url: this.props.importSubscribersURL,
      data: this.getFormData(),
      encoder: 'formdata',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.setState({
            numSubscribers: data.data.num_subscribers,
            importSkipped: data.data.num_skipped,
            importSuccessful: data.data.num_imported,
          });
          this.props.importSuccessHandler();
        } else {
          this.setState({
            importSkipped: null,
            importSuccessful: null,
            validationErrors: data.fields,
          });
        }
      }.bind(this),
    });
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    const formData = this.getFormData();

    return (
      <Modal ref="modal" title="Import Subscribers" size="lg" closeButton={true}>
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <p>Using this form you may upload subscribers in bulk into the system.</p>
          <p>
            Please download our{' '}
            <a download href={this.props.importSubscribersTemplateURL}>
              Bulk Upload Excel Template
            </a>
            , fill it out and submit it using the form below.
          </p>
          <p>
            We also have a{' '}
            <a download href={this.props.importSubscribersSampleURL}>
              sample
            </a>{' '}
            available for your reference. The Bulk Import feature accepts both XLSX and CSV files. It is limited to
            importing 1000 subscribers at a time.
          </p>
          {this.state.importSuccessful || this.state.importSkipped ? (
            <div className="alert alert-success">
              {this.state.importSuccessful} records imported.{' '}
              {this.state.importSkipped ? this.state.importSkipped + ' duplicative records skipped.' : false}
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : null}
          {this.renderForm(formLink, formData)}
        </form>
      </Modal>
    );
  }

  renderForm(formLink, formData) {
    return (
      <React.Fragment>
        <FormErrors errors={this.state.validationErrors.__all__} />
        <FileInput
          fieldName="file"
          labelText="Import Subscribers XLSX/CSV"
          acceptFormat=".csv,.xls,.xlsx"
          isRequired={true}
          formLink={formLink}
        />
        <button className="btn btn-secondary" onClick={this.handleImport}>
          Import
        </button>
      </React.Fragment>
    );
  }
}

ImportSubscribersForm.defaultProps = {
  importSubscribersURL: null,
  importSubscribersTemplateURL: null,
  importSubscribersSampleURL: null,
  exportSubscribersURL: null,
};
