'use strict';

import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import ServiceMaintenance from './ServiceMaintenance.jsx';

export default class BulkMaintenanceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  serialize() {
    return this.refs.maintenance.serialize();
  }

  showModal() {
    this.refs.modal.showModal();
    this.refs.maintenance.deserialize({
      schedule_state: 'ACTIVE',
      schedule_timezone: this.props.choices.default_timezone,
    });
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  render() {
    return (
      <Modal
        ref="modal"
        title="Edit multiple maintenance windows"
        size="lg"
        cancelButton={true}
        saveButton={this.props.onSave}
      >
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>Note: Existing maintenance windows will be replaced with whatever you set below.</em>
          </p>
          <ServiceMaintenance ref="maintenance" choices={this.props.choices} />
        </form>
      </Modal>
    );
  }
}
