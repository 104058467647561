'use strict';

import React from 'react';
import Ajax from '../../jskit/general/Ajax';
import Utils from '../../jskit/general/Utils';
import SystemMetric from './SystemMetric.jsx';
import SystemMetricsForm from './SystemMetricsForm.jsx';
import * as ManageUtils from './ManageUtils.jsx';
import MessageBox from '../../js/global/messagebox';

export default class SystemMetricsTab extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  handleAdd(e) {
    e.preventDefault();
    this.refs.systemMetricsForm.showModal();
  }

  handleEdit(metric, e) {
    e.preventDefault();
    this.refs.systemMetricsForm.showModal(metric);
  }

  handleDelete(metric, e) {
    e.preventDefault();

    const msg = 'Are you sure you want to delete "' + metric.name + '"?';

    MessageBox.confirmBox(msg, 'Delete System Metric', () => {
      new Ajax().post({
        url: metric.delete_url,
        data: {},
        encoder: 'json',
        decoder: 'json',
        success: function (data) {
          if (data.success) {
            this.props.onNewData(data.data);
          }
        }.bind(this),
      });
    });
  }

  handleDrag(srcEl, dstEl) {
    const statuspage = this.props.statuspage;
    ManageUtils.swapMetricsWithIds(statuspage.metrics, srcEl.id, dstEl.id);
    const sortOrder = [];
    statuspage.metrics.forEach((metric, idx) => {
      sortOrder.push({
        metricId: metric.id,
        sortingWeight: idx,
      });
    });
    new Ajax().post({
      url: this.props.reorderURL,
      data: sortOrder,
      encoder: 'json',
      decoder: 'json',
      success: (data) => {
        if (data.success) {
          this.props.onNewData(data.data);
        }
      },
    });
  }

  render() {
    const statuspage = this.props.statuspage;

    return (
      <React.Fragment>
        {statuspage.metrics.length ? this.renderMetrics(statuspage) : this.renderEmptyState()}

        <SystemMetricsForm
          ref="systemMetricsForm"
          updateURL={this.props.updateURL}
          servicesCheckListURL={this.props.servicesCheckListURL}
          onNewData={this.props.onNewData}
        />
      </React.Fragment>
    );
  }

  renderMetrics(statuspage) {
    const metrics = statuspage.metrics.map((metric, i) => (
      <SystemMetric
        key={i}
        metric={metric}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        onDrag={this.handleDrag}
      />
    ));

    return (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="text-muted mr-3">{statuspage.metrics.length} system metric(s)</div>
          {this.renderAddButton()}
        </div>
        <div className="component-list white-block white-block-border">
          <div className="component-header">
            <div className="row align-items-end">
              <div className="col-sm-4">Name</div>
              <div className="col-sm-3">Response Time</div>
              <div className="col-sm-3">Source</div>
            </div>
          </div>
          {metrics}
          <div className="component-end"></div>
        </div>
      </React.Fragment>
    );
  }

  renderEmptyState() {
    return (
      <div className="text-center p-lg-5 white-block white-block-border">
        <div className="font-weight-bold font-24 mb-4">You have not yet added any System Metrics...</div>
        <div className="font-weight-normal font-14 mb-5">
          {/* eslint-disable-next-line max-len */}
          Add system metrics, such as response time, for added visibility into critical system performance on your page.
        </div>
        <div>{this.renderAddButton()}</div>
      </div>
    );
  }

  renderAddButton() {
    return (
      <div>
        <button
          data-wizard="statuspages-manage-metrics-add-metric"
          onClick={this.handleAdd}
          className="btn btn-sm btn-outline-primary"
        >
          <i className="fas fa-plus mr-2" />
          Add System Metrics
        </button>
      </div>
    );
  }
}

SystemMetricsTab.defaultProps = {
  updateURL: null,
  reorderURL: null,
  servicesCheckListURL: null,
  statuspage: null,
  onNewData: null,
};
