import _ from 'underscore';
import React from 'react';
import URLHistory from '../../jskit/general/URLHistory';
import Utils from '../../jskit/general/Utils';
import Cookie from '../../jskit/general/Cookie';

const MAX_PAGES = 10000;

export default class Paginator extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    if (this.props.totalRecords === null && this.props.numRecordsOnCurrentPage === null) {
      throw 'Paginator: `numRecordsOnCurrentPage` prop must be provided if `totalRecords` is null.';
    }

    this.state = {
      page: 0,

      // Number of items to display per page.
      pageSize: this.readPageSizeCookie() || 10,
    };
  }

  getCurrentPage() {
    return this.state.page;
  }

  getQueryOffsetLimit() {
    var query = {
      offset: this.state.page * this.state.pageSize,
      limit: this.state.pageSize,
    };

    return query;
  }

  reset(page) {
    // Need this change to be instantaneous, as parent may call
    // getQueryOffsetLimit() next
    this.state.page = !page || parseInt(page) != page || page < 0 ? 0 : page;
    this.forceUpdate();
  }

  numEntries() {
    if (this.props.totalRecords === null) {
      // If we don't have a count of all records, fake the total
      // by using a big number, or 0 if there is no data at all.
      if (this.props.numRecordsOnCurrentPage === 0 && this.state.page === 0) {
        return 0;
      } else {
        return this.state.pageSize * MAX_PAGES;
      }
    } else {
      return this.props.totalRecords;
    }
  }

  numPages() {
    if (this.props.totalRecords === null) {
      // If we don't have a count of all records, fake the number of pages
      // with a large number. However, if we have less than pageSize records
      // on the current page, we know this is the last page.
      if (this.props.numRecordsOnCurrentPage < this.state.pageSize) {
        return this.state.page + 1;
      } else {
        return MAX_PAGES;
      }
    } else {
      return Math.ceil(this.props.totalRecords / this.state.pageSize);
    }
  }

  currentPage() {
    var numPages = this.numPages();

    // Check if we just lost our last page...
    if (this.state.page !== 0 && this.state.page > numPages - 1) {
      _.defer(this.handlePageClick, Math.max(0, numPages - 1));
    }

    return this.state.page;
  }

  getCurrentUrl() {
    return URLHistory.currentURL().split(/[?#]/)[0];
  }

  decodePageSizeCookie() {
    var cookieVal = Cookie.getCookie('uptime_paginator');

    if (!cookieVal) {
      return null;
    }

    try {
      cookieVal = JSON.parse(cookieVal);
    } catch (e) {
      return null;
    }

    return cookieVal;
  }

  readPageSizeCookie() {
    var cookieVal = this.decodePageSizeCookie();
    if (!cookieVal) {
      return null;
    }

    var url = this.getCurrentUrl();
    return cookieVal[url] || null;
  }

  writePageSizeCookie(pageSize) {
    var url = this.getCurrentUrl();
    var cookieObj = this.decodePageSizeCookie() || {};
    cookieObj[url] = pageSize;
    Cookie.setCookie('uptime_paginator', JSON.stringify(cookieObj), 90);
  }

  handlePageClick(page, e) {
    if (e) {
      e.preventDefault();
    }

    var p = this.currentPage();

    if (page === 'prev') {
      p = Math.max(p - 1, 0);
    } else if (page === 'next') {
      p = Math.min(p + 1, this.numPages() - 1);
    } else {
      p = Math.min(Math.max(page, 0), this.numPages() - 1);
    }

    this.setState({page: p}, function () {
      this.props.onPageChange(p);
    });
  }

  handlePerPageChange(event) {
    var pageSize = parseInt(event.target.value);
    if (pageSize) {
      this.writePageSizeCookie(pageSize);

      this.setState({page: 0, pageSize: pageSize}, function () {
        this.props.onPageChange(0);
      });
    }
  }

  render() {
    if (typeof this.state.pageSize === 'string') {
      throw new Error('Paginator: Must assign the pageSize prop a numeric value ' + '(eg. <Paginator pageSize={10}/>)');
    }

    var numEntries = this.numEntries();
    if (this.numEntries() <= 0) {
      return null;
    }

    var page = this.currentPage();
    var numPages = this.numPages();
    var startEntry = page * this.state.pageSize + 1;
    var endEntry = Math.min(startEntry + this.state.pageSize - 1, numEntries);

    return (
      <div className="row clearfix mx-0">
        <div className="col-lg-5 mb-3">
          <div className="form-inline justify-content-center justify-content-lg-start">
            <select
              className="form-control mr-3 mb-2 mb-sm-0"
              value={this.state.pageSize}
              onChange={this.handlePerPageChange}
            >
              <option value="10">10 Per Page</option>
              <option value="25">25 Per Page</option>
              <option value="50">50 Per Page</option>
              <option value="100">100 Per Page</option>
              <option value="250">250 Per Page</option>
            </select>
            <small>
              Showing {startEntry} to {endEntry}&nbsp;
            </small>
            {this.props.totalRecords !== null ? <small>of {this.props.totalRecords} entries</small> : null}
          </div>
        </div>
        <div className="col-lg-7 mb-3">{this.renderPageNavButtons(page, numPages)}</div>
      </div>
    );
  }

  renderPageNavButtons(page, numPages) {
    if (numPages > 1) {
      return (
        <nav aria-label="Page number">
          <ul className="pagination justify-content-center justify-content-lg-end">
            <li className={'page-item ' + (page === 0 ? 'disabled' : '')}>
              <a key="prev" onClick={this.handlePageClick.bind(null, 'prev')} className="page-link text-nowrap">
                <i className="fas fa-angle-double-left" /> Prev
              </a>
            </li>
            {this.renderPageNumberButtons(page, numPages)}
            <li className={'page-item ' + (page === numPages - 1 ? 'disabled' : '')}>
              <a key="next" onClick={this.handlePageClick.bind(null, 'next')} className="page-link text-nowrap">
                Next
                <i className="fas fa-angle-double-right" />
              </a>
            </li>
          </ul>
        </nav>
      );
    } else {
      return null;
    }
  }

  renderPageNumberButtons(page, numPages) {
    if (this.props.totalRecords === null) {
      return null;
    }

    var pageClassName = function (num) {
      return 'page-item ' + (page == num ? 'active' : '');
    };

    var windowSize = Math.min(10, numPages);
    var startPage = Math.max(0, page - 4);
    var endPage = Math.min(numPages, page + 6);

    if (endPage - startPage < windowSize) {
      if (startPage == 0) {
        endPage = startPage + windowSize;
      } else {
        startPage = endPage - windowSize;
      }
    }

    return _.map(
      _.range(startPage, endPage),
      function (i) {
        return (
          <li key={i} className={pageClassName(i)}>
            <a onClick={this.handlePageClick.bind(null, i)} className="page-link">
              {i + 1}
            </a>
          </li>
        );
      }.bind(this)
    );
  }
}

Paginator.defaultProps = {
  // Total number of records, after searching/filtering is done.
  // Leave null to show only next/previous buttons without totals & pages,
  // for efficency when you want to avoid counting all rows.
  totalRecords: null,

  // Number of records on this page. Only needed if totalRecords is null,
  // in order to determine whether to disable the Next button when we don't
  // have a full page of results.
  numRecordsOnCurrentPage: null,

  // Event called when the page has been changed by the user.
  onPageChange: function () {
    return undefined;
  },
};
