import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import PercentageInput from '../../jskit/react/forms/PercentageInput';

// TODO: move constants to backend if they will be used anywhere else
const defaultBulkUptimeSLA = '0.99';
const defaultBulkResponseTimeSLA = '';

export default class BulkSLAForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        msp_uptime_sla: defaultBulkUptimeSLA,
        msp_response_time_sla: defaultBulkResponseTimeSLA,
      },
    };
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal() {
    this.refs.modal_contact.showModal();
    this.setState({
      formData: {msp_uptime_sla: defaultBulkUptimeSLA, msp_response_time_sla: defaultBulkResponseTimeSLA},
    });
  }

  hideModal() {
    this.refs.modal_contact.hideModal();
  }

  render() {
    if (!this.props.choices.service_defs) {
      return null;
    }

    // extract SLA field defs from HTTP form definition
    const httpFormChoices = this.props.choices.service_defs.HTTP;
    const mspUptimeSLADef = _.findWhere(httpFormChoices.fields, {field: 'msp_uptime_sla'});
    const mspResponseTimeSLADef = _.findWhere(httpFormChoices.fields, {field: 'msp_response_time_sla'});
    if (!(mspUptimeSLADef && mspResponseTimeSLADef)) {
      return null;
    }
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    return (
      <Modal
        ref="modal_contact"
        title="Set uptime and response time SLA's"
        cancelButton={true}
        saveButton={this.props.onSave}
      >
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>
              Note: The current SLA values on selected checks will be replaced with the new settings chosen below.
            </em>
          </p>
          <PercentageInput
            fieldName="msp_uptime_sla"
            formLink={formLink}
            labelText={mspUptimeSLADef.verbose_name}
            titleText={mspUptimeSLADef.title}
          />
          <TextInput
            fieldName="msp_response_time_sla"
            formLink={formLink}
            labelText={mspResponseTimeSLADef.verbose_name}
            titleText={mspResponseTimeSLADef.title}
            placeholder="no change"
          />
        </form>
      </Modal>
    );
  }
}
