'use strict';

import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import Select2 from '../../jskit/react/forms/Select2';

export default class BulkContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        contact_groups: [],
      },
    };
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal() {
    this.refs.modal_contact.showModal();
    this.setState({formData: {contact_groups: []}});
  }

  hideModal() {
    this.refs.modal_contact.hideModal();
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);

    return (
      <Modal ref="modal_contact" title="Edit multiple contacts" cancelButton={true} saveButton={this.props.onSave}>
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>Note: Existing contacts will be replaced with whatever you select below.</em>
          </p>
          <Select2
            fieldName="contact_groups"
            choices={this.props.choices.contact_groups}
            labelText="Contacts"
            isRequired={true}
            multiple={true}
            closeOnSelect={false}
            formLink={formLink}
          />
        </form>
      </Modal>
    );
  }
}
