'use strict';

import React from 'react';
import Formatter from '../../../jskit/general/Formatter';
import Utils from '../../../jskit/general/Utils';
import Chart from 'react-apexcharts';
import {millisecondFormatter, datetimeFormatter, defaultFormatter, renderNoData} from '../RumUtils.jsx';
import {defaultGraphOptions, toolbarExportOptions, manualFormat} from './GraphFormatter.js';
import {Colors} from '../RumColors.js';

export default class SegmentsTrendGraph extends React.PureComponent {
  // TODO: shared tooltip does not work with irregular/different-length arrays.
  // Workaround here: https://github.com/apexcharts/apexcharts.js/issues/420

  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    const defaultOptions = defaultGraphOptions();
    this.state = {
      series: [],
      options: {
        chart: Object.assign(defaultOptions.chart, {
          markers: {
            size: 20,
          },
          toolbar: Object.assign(defaultOptions.chart.toolbar, toolbarExportOptions(this.props.checkName, 'segments')),
        }),
        title: defaultOptions.title,
        grid: {
          padding: {
            top: 3,
            bottom: 3,
          },
        },
        colors: this.props.colors || [
          Colors.ghostBlue,
          Colors.green,
          Colors.blue,
          Colors.yellowOrange,
          Colors.red,
          Colors.turquoise,
          Colors.red,
        ],
        stroke: {
          width: [0, 5, 5, 5, 5, 5, 5],
        },
        plotOptions: {
          bar: {
            columnWidth: '97%',
            dataLabels: {
              position: 'bottom',
            },
            stroke: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          formatter: this._xlabelFormatter.bind(this),
          background: {
            enabled: false,
          },
          offsetY: 30,
          style: {
            colors: [Colors.black],
            fontWeight: 100,
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            seriesName: this.props.def.summarize.name,
            title: {
              text: this.props.def.summarize.name,
              style: {
                color: Colors.lightBlue,
                fontSize: '14px',
                fontWeight: 400,
              },
            },
            labels: {
              formatter: Formatter.humanFriendlyInt,
              style: {
                colors: [Colors.lightBlue],
              },
            },
            tickAmount: 3,
          },
        ],
        legend: defaultOptions.legend,
        tooltip: {
          x: {
            formatter: (value) => datetimeFormatter(value),
          },
          y: {
            formatter: this._tooltipFormatter.bind(this),
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
        },
      },
      noData: false,
    };
    this.chartRef = React.createRef();
    const parsedData = this._parseData(this.props.data);
    this._setData(parsedData);
  }

  componentDidMount() {
    manualFormat((this.chartRef.current || {}).chart);
  }

  componentDidUpdate(previousProps) {
    if (this.props.data !== previousProps.data) {
      const parsedData = this._parseData(this.props.data);
      this._setData(parsedData);
      const chart = (this.chartRef.current || {}).chart;
      if (chart) {
        chart.updateOptions(this.state.options, true, false, false);
        chart.updateSeries(this.state.series);
      }
      this.forceUpdate();
    }
  }

  _parseData(data) {
    data = data || [];

    const timeKey = this.props.def.timeKey;

    const summary = {};
    const detailSeries = {};

    // Optionally limit the number of series we show.
    // We will take the top N series by the sum of their summary feature values.
    const sumBySeries = {};

    // Store the unique time values so we can properly space the x-labels.
    const timeValues = new Set();

    // Organize combined array of data points into their respective series.
    const summaryKey = this.props.def.summarize.key; // The key used for the bars.
    const seriesKey = this.props.def.autoSeries.key; // The key used to put each datum in the correct series.
    const valueKey = this.props.def.value.key; // The key used to get the value for each datum.
    for (const datum of data) {
      const seriesName = datum[seriesKey];
      let time = datum[timeKey];
      if (typeof time === 'string' && !time.endsWith('Z')) {
        time += 'Z';
      }
      timeValues.add(time);
      summary[time] = (summary[time] || 0) + datum[summaryKey];
      detailSeries[seriesName] = detailSeries[seriesName] || [];
      detailSeries[seriesName].push([new Date(time), datum[valueKey]]);
      sumBySeries[seriesName] = (sumBySeries[seriesName] || 0) + (datum[summaryKey] || 0);
    }
    const times = Array.from(timeValues).sort();

    const summaryData = [];
    for (const [time, val] of Object.entries(summary)) {
      summaryData.push([new Date(time), val]);
    }

    const series = [];
    series.push({
      name: this.props.def.summarize.name,
      data: summaryData,
      type: 'column',
    });

    const seriesNames = Object.keys(detailSeries);
    if (this.props.maxSeries) {
      // If specified, limit the number of series we show.
      seriesNames.sort((a, b) => (sumBySeries[b] || 0) - (sumBySeries[a] || 0));
      seriesNames.splice(this.props.maxSeries);
    }
    seriesNames.sort(); // Sort the keys alphabetically to be consistent with other graphs.

    // Construct yaxis array with pageViews on left and metrics on right.
    const yaxis = [this.state.options.yaxis[0]];
    let firstFeature;
    const rightSeries = [];
    for (const seriesName of seriesNames) {
      const thisData = detailSeries[seriesName];
      if (!thisData || !thisData.length) {
        continue;
      }
      const thisSeries = {
        name: seriesName,
        data: thisData,
        type: 'line',
      };
      series.push(thisSeries);
      rightSeries.push(thisSeries);

      if (!firstFeature) {
        yaxis.push({
          seriesName,
          opposite: true,
          labels: {
            formatter: this._ylabelFormatter.bind(this),
          },
          tickAmount: 3,
        });
        firstFeature = seriesName;
      } else {
        yaxis.push({
          seriesName: firstFeature,
          show: false,
          opposite: true,
          labels: {
            formatter: this._ylabelFormatter.bind(this),
          },
        });
      }
    }

    // Apexcharts seems to handle rounded axis limits on shared axes incorrectly, so we'll set them manually based on the data.
    // The issue is with niceMax; the first opposite axis is correct, but the others are set to a different niceMax and it causes scaling issues.
    if (rightSeries.length) {
      let rightMax = Number.MIN_VALUE;
      for (const rSeries of rightSeries) {
        for (const value of rSeries.data) {
          rightMax = Math.max(rightMax, value[1]);
        }
      }
      if (rightMax !== Number.MIN_VALUE) {
        for (const axis of yaxis.filter((ax) => ax.opposite)) {
          axis.min = 0;
          axis.max = rightMax;
        }
      }
    }

    // If a dual-axis chart is rendered with no data, the axes will render incorrectly if the time range is shifted and data shows up.
    // Insert some placeholder data to work around this.
    const noData = !firstFeature;
    if (!firstFeature) {
      yaxis.push({
        seriesName: 'null',
        opposite: true,
        labels: {
          formatter: this._ylabelFormatter.bind(this),
        },
      });
      series.push({
        name: '(No data found)',
        data: [],
        type: 'line',
      });
    }

    return {series, yaxis, times, noData};
  }

  _setData(parsedData) {
    // Set directly (instead of setState) so data can be parsed in the constructor instead of on mount.
    // Apexcharts will otherwise not set some chart options correctly.
    const {series, yaxis, times, noData} = parsedData;
    this.state.noData = noData;
    this.state.options.title.text = this.props.title || '';
    this.state.options.yaxis = yaxis || this.state.options.yaxis[0];
    this.state.series = series || [];
    this.state.times = times || [];
  }

  _tooltipFormatter(value, options) {
    const feature = options.seriesIndex === 0 ? this.props.def.summarize : this.props.def.value;
    return defaultFormatter(value, 3, feature);
  }

  _ylabelFormatter(value) {
    return millisecondFormatter(value, 3).string;
  }

  _xlabelFormatter(_, options) {
    const data = this.state.series[0].data;
    const ix = options.dataPointIndex;
    const thisPoint = data[ix];
    if (!thisPoint) {
      return '';
    }

    // Allow a max of 8 labels so they don't get cluttered (start, end, 6 in the middle).
    let everyNth = 1;
    if (data.length > 8) {
      everyNth = parseInt(data.length / 8) + 1;
      if (ix !== 0 && ix !== data.length - 1 && (ix % everyNth || ix > data.length - everyNth)) {
        return '';
      }
    }

    const times = this.state.times;
    return datetimeFormatter(thisPoint[0], times[0], times[times.length - 1]);
  }

  render() {
    return (
      <div className="detail-trend-graph">
        {!!this.state.noData && renderNoData([], this.props.noDataMessage)}
        <Chart
          ref={this.chartRef}
          type="line"
          options={this.state.options}
          series={this.state.series}
          width="100%"
          height="300px"
        />
      </div>
    );
  }
}
