'use strict';

import React from 'react';
import Formatter from '../jskit/general/Formatter';
import Modal from '../jskit/react/Modal';
import ReactUtils from '../jskit/react/ReactUtils';

export class AuditLogDetailsModal extends React.Component {
  showModal() {
    this.refs.modal.showModal();
  }

  render() {
    const item = this.props.item;
    const tableCss = (item.changes_list || []).some((x) => x.name === 'msp_script')
      ? 'table-responsive'
      : 'table-responsive-lg';

    return (
      <Modal
        ref="modal"
        title={'Audit Log Detail: ' + Formatter.truncate(item.object_str, 30)}
        size="lg"
        closeButton={true}
      >
        <table className={ReactUtils.cssClass('table listing-table', tableCss)}>
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Old Value</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>{item.changes_list ? item.changes_list.map(this.renderChangesRow) : null}</tbody>
        </table>
      </Modal>
    );
  }

  renderChangesRow(fld, i) {
    let changes = null;

    if (!fld.to_many) {
      changes = (
        <React.Fragment>
          <td className="text-word-wrap">{fld.class === 'DateTimeField' ? fld.old.value : fld.old.verbose}</td>
          <td className="text-word-wrap">{fld.class === 'DateTimeField' ? fld.new.value : fld.new.verbose}</td>
        </React.Fragment>
      );
    } else {
      changes = (
        <React.Fragment>
          <td></td>
          <td key={i}>
            {fld.added && fld.added.length ? (
              <p className="mb-1">
                <strong>Added:</strong> {fld.added.map((x) => x.verbose).join(', ')}
              </p>
            ) : null}
            {fld.removed && fld.removed.length ? (
              <p className="mb-1">
                <strong>Removed:</strong> {fld.removed.map((x) => x.verbose).join(', ')}
              </p>
            ) : null}
          </td>
        </React.Fragment>
      );
    }

    return (
      <tr key={i}>
        <td>{Formatter.capitalizeFirstLetter(fld.verbose_name)}</td>
        {changes}
      </tr>
    );
  }
}

class AuditLogEntry extends React.PureComponent {
  render() {
    return (
      <tr>
        <td>{Formatter.longDateTime(this.props.created_at)}</td>
        <td>{this.props.user_display}</td>
        <td>{this.props.model_display}</td>
        <td>{this.props.object_str}</td>
        <td>{this.props.object_id}</td>
        <td>{this.props.comment}</td>
        <td className="actions-right">
          <button className="btn btn-outline-primary" type="button" onClick={this.props.onDetailsClick}>
            View
          </button>
        </td>
      </tr>
    );
  }
}

export class AuditLogList extends React.PureComponent {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length == 0) {
      return <div>No items found.</div>;
    }

    const nodes = this.props.items.map(
      function (item, index) {
        return <AuditLogEntry {...item} key={item.id} onDetailsClick={this.props.onDetailsClick.bind(null, index)} />;
      }.bind(this)
    );

    return (
      <div className="white-block white-block-border mb-4">
        <table className="table table-responsive-lg data-table mb-0" role="grid">
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Object Type</th>
              <th>Object</th>
              <th>Object ID</th>
              <th>Comment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }
}
