'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import Chart from 'react-apexcharts';
import {defaultFormatter, datetimeFormatter, renderNoData} from '../RumUtils.jsx';
import {defaultGraphOptions, toolbarExportOptions, manualFormat} from './GraphFormatter.js';
import {Colors} from '../RumColors.js';

export default class DetailTrendGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    const defaultOptions = defaultGraphOptions();
    this.state = {
      series: [],
      options: {
        chart: Object.assign(defaultOptions.chart, {
          markers: {
            size: 20,
          },
          toolbar: Object.assign(defaultOptions.chart.toolbar, toolbarExportOptions(this.props.checkName, 'trend')),
        }),
        title: defaultOptions.title,
        grid: {
          padding: {
            top: 3,
            bottom: 3,
          },
        },
        colors:
          this.props.colors ||
          (this.props.bar ? [Colors.ghostBlue] : []).concat([
            Colors.lightBlue,
            Colors.blue,
            Colors.turquoise,
            Colors.saffron,
            Colors.mediumLightBlue,
          ]),
        stroke: {
          width: [this.props.bar ? 0 : 5, 5, 5, 5, 5],
        },
        plotOptions: {
          bar: {
            columnWidth: '97%',
            dataLabels: {
              position: 'bottom',
            },
            stroke: {
              show: false,
            },
          },
        },
        dataLabels: this.props.bar
          ? {
              enabled: true,
              enabledOnSeries: [0],
              formatter: this._barLabelFormatter.bind(this),
              background: {
                enabled: false,
              },
              offsetY: 30,
              style: {
                colors: [Colors.black],
                fontWeight: 100,
              },
            }
          : {},
        xaxis: {
          type: 'datetime',
          labels: {
            show: !this.props.bar,
            formatter: this._xlabelFormatter.bind(this),
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            seriesName: (this.props.left || {}).name,
            title: {
              text: (this.props.left || {}).name,
              style: {
                color: Colors.lightBlue,
                fontSize: '14px',
                fontWeight: 400,
              },
            },
            labels: {
              formatter: (value) => defaultFormatter(value, 2, this.props.left),
              style: {
                colors: [Colors.lightBlue],
              },
            },
            tickAmount: 3,
          },
        ],
        legend: Object.assign(defaultOptions.legend, {
          offsetY: 10,
        }),
        tooltip: {
          x: {
            formatter: (value) => datetimeFormatter(value),
          },
          y: {
            formatter: this._tooltipFormatter.bind(this),
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
        },
      },
      noData: false,
    };
    this.chartRef = React.createRef();
    const parsedData = this._parseData(this.props.data);
    this._setData(parsedData);
  }

  componentDidMount() {
    manualFormat((this.chartRef.current || {}).chart);
  }

  componentDidUpdate(previousProps) {
    if (this.props.data !== previousProps.data) {
      const parsedData = this._parseData(this.props.data);
      this._setData(parsedData);
      const chart = (this.chartRef.current || {}).chart;
      if (chart) {
        chart.updateOptions(this.state.options, true, false, false);
        chart.updateSeries(this.state.series);
      }
      this.forceUpdate();
    }
  }

  _parseData(data) {
    data = data || [];
    const dashArray = []; // Show features as solid lines and baselines as dotted lines.
    const series = []; // Data arrays.
    const timeKey = 'EventTime';

    const timePoints = data.map((datum) => {
      let timePoint = datum[timeKey];
      if (typeof timePoint === 'string' && !timePoint.endsWith('Z')) {
        timePoint += 'Z';
      }
      return new Date(timePoint);
    });

    let yaxis;
    if (this.props.left) {
      const leftData = [];
      for (const [ix, datum] of data.entries()) {
        leftData.push([timePoints[ix], datum[this.props.left.key]]);
      }
      yaxis = [this.state.options.yaxis[0]];
      series.push({
        name: this.props.left.name,
        data: leftData,
        type: this.props.bar ? 'column' : 'line',
      });
      dashArray.push(0);
    } else {
      yaxis = [];
    }

    const rightLines = (this.props.right || []).concat(this.props.baselines || []);
    const rightSeries = [];
    let firstFeature;
    for (const [ix, feature] of rightLines.entries()) {
      if (!data.length || !data[0][feature.key]) {
        continue;
      }

      let key, name, dash;
      if (ix < (this.props.right || []).length) {
        key = feature.key;
        name = feature.name;
        dash = 0;
      } else {
        // Hide the baselines if the user has selected a time range for which baselines are not applicable.
        if (this.props.headerData[feature.baseline] == null) {
          continue;
        }
        key = feature.baseline;
        name = `${feature.name} (baseline)`;
        dash = 2;
      }
      dashArray.push(dash);

      const featureData = [];
      for (const [ix, datum] of data.entries()) {
        // First, look for the data in the indexed data point.
        // Second, look for it in the header data (for baseline values).
        let value = datum[key];
        if (value === undefined && this.props.headerData) {
          value = this.props.headerData[key];
        }
        featureData.push([timePoints[ix], value]);
      }
      const thisSeries = {
        name,
        data: featureData,
        type: 'line',
      };
      series.push(thisSeries);

      const opposite = !!this.props.left; // True if on right axis.
      if (opposite) {
        rightSeries.push(thisSeries);
      }

      // https://apexcharts.com/docs/chart-types/multiple-yaxis-scales/#3-series-2-scales
      if (!firstFeature) {
        yaxis.push({
          seriesName: name,
          opposite,
          labels: {
            formatter: this._ylabelFormatterRight.bind(this),
          },
          tickAmount: 3,
        });
        firstFeature = name;
      } else {
        yaxis.push({
          seriesName: firstFeature,
          show: false,
          opposite,
        });
      }
    }

    // Apexcharts seems to handle rounded axis limits on shared axes incorrectly, so we'll set them manually based on the data.
    // The issue is with niceMax; the first opposite axis is correct, but the others are set to a different niceMax and it causes scaling issues.
    if (rightSeries.length) {
      let rightMax = Number.MIN_VALUE;
      for (const rSeries of rightSeries) {
        for (const value of rSeries.data) {
          rightMax = Math.max(rightMax, value[1]);
        }
      }
      if (rightMax !== Number.MIN_VALUE) {
        for (const axis of yaxis.filter((ax) => ax.opposite)) {
          axis.min = 0;
          axis.max = rightMax;
        }
      }
    }

    // If a dual-axis chart is rendered with no data, the axes will render incorrectly if the time range is shifted and data shows up.
    // Insert some placeholder data to work around this.
    const noData = !firstFeature;
    if (!firstFeature) {
      yaxis.push({
        seriesName: 'null',
        opposite: !!this.props.left,
        labels: {
          formatter: this._ylabelFormatterRight.bind(this),
        },
      });
      series.push({
        name: '(No data found)',
        data: [],
        type: 'line',
      });
    }

    return {series, yaxis, dashArray, noData};
  }

  /**
   * Set data state directly (not via setState) so that it can be parsed in the constructor.
   * This is necessary for the graph to appear correctly on its initial render.
   */
  _setData(parsedData) {
    const {series, yaxis, dashArray, noData} = parsedData;
    this.state.noData = noData;
    this.state.options.title.text = this.props.title || '';
    this.state.options.yaxis = yaxis || [this.state.options.yaxis[0]];
    this.state.options.stroke.dashArray = dashArray;
    this.state.options.xaxis.labels.show = !this.props.bar;
    this.state.series = series || [];
  }

  _tooltipFormatter(value, options) {
    const allFeatures = (this.props.left ? [this.props.left] : [])
      .concat(this.props.right || [])
      .concat(this.props.baselines || []);
    const feature = allFeatures[options.seriesIndex];
    return defaultFormatter(value, 3, feature);
  }

  _ylabelFormatterRight(value) {
    const allUnits = new Set();
    for (const feature of this.props.right || []) {
      if (feature.units) {
        allUnits.add(feature.units);
      }
    }
    let firstFeature;
    if (allUnits.size === 1) {
      firstFeature = this.props.right[0];
    }
    return defaultFormatter(value, 3, firstFeature);
  }

  /** x-label formatter without bars */
  _xlabelFormatter(value) {
    const data = this.props.data;
    if (value == null || !data || !data.length) {
      return '';
    }
    const timeKey = 'EventTime';
    return datetimeFormatter(value, data[0][timeKey], data[data.length - 1][timeKey]);
  }

  /** x-label formatter with bars */
  _barLabelFormatter(_, options) {
    const data = this.props.data;
    const timeKey = 'EventTime';
    const ix = options.dataPointIndex;

    // Allow a max of 8 labels so they don't get cluttered (start, end, 6 in the middle).
    let everyNth = 1;
    if (data.length > 8) {
      everyNth = parseInt(data.length / 8) + 1;
      if (ix !== 0 && ix !== data.length - 1 && (ix % everyNth || ix > data.length - everyNth)) {
        return '';
      }
    }

    const thisPoint = data[ix];
    if (!thisPoint) {
      return '';
    }

    return datetimeFormatter(thisPoint[timeKey], data[0][timeKey], data[data.length - 1][timeKey]);
  }

  render() {
    return (
      <div className="detail-trend-graph">
        {!!this.state.noData && renderNoData([], this.props.noDataMessage)}
        <Chart
          ref={this.chartRef}
          type="line"
          options={this.state.options}
          series={this.state.series}
          width="100%"
          height="300px"
        />
      </div>
    );
  }
}
