/* eslint-disable */
'use strict';

import React from 'react';

import TextInput from '../../../jskit/react/forms/TextInput';
import {FieldErrors} from './FormHelpers';

export default class URLInput extends TextInput {
  constructor(props) {
    super(props);
    this.url_re = /^(https?:\/\/)((?:[a-zA-Z0-9_\-\.]+)|(?:\[[0-9a-f:]+\]))(:\d{2,5})?([\/?#]\S*)?$/i;
    this.has_variable_re = /\$.*?\$/;
  }

  renderErrors(form) {
    let url = form.value;
    let errors = null;
    if (!(this.has_variable_re.test(url) && this.props.allowVariables)) {
      if (url.indexOf('://') == -1) {
        url = 'https://' + url;
      }

      if (this.props.httpsOnly && !url.startsWith('https://')) {
        errors = ['Only https URLs are supported.'];
      } else if (url != '' && !this.url_re.test(url)) {
        errors = ['Please enter a valid URL.'];
      }

      if (errors) {
        if (form.errors) {
          errors = errors.concat(form.errors);
        }
      } else {
        errors = form.errors;
      }
    }
    return <FieldErrors errors={errors} />;
  }
}

URLInput.defaultProps = {
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  placeholder: null, // Placeholder text
  isRequired: false, // Field is required?
  readOnly: false, // Field is read-only?
  disabled: false, // Control is disabled?
  inputType: 'text', // Type of input
  maxLength: null, // HTML maxlength for this field
  addonPrefix: null, // Text/HTML to place in the addon on the left of the field
  addonLabel: null, // Label for an addon button at the end of the field
  addonClass: 'btn-secondary', // Classes to apply for addon Button

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
  onAddonClick: null, // Handler when addon button is clicked
  onFocus: null, // Focus handler
  onBlur: null, // Blur handler

  httpsOnly: false, // Accept only HTTPS urls.
  allowVariables: false, //Allow to use $VAR$ variables in URL, validation for such URLs is disabled
};
