import React from 'react';
import _ from 'underscore';
import Ajax from '../../jskit/general/Ajax';
import Utils from '../../jskit/general/Utils';
import Formatter from '../../jskit/general/Formatter';
import {unpackFormLinkOrProps, CustomEvent} from '../../jskit/react/forms/FormHelpers';
import SelectBox from '../../jskit/react/forms/SelectBox';
import ServiceListPanel from './ServiceListPanel';

import $ from 'jquery';

const DEFAULT_MAX_SERVICES = 50;

export default class ServiceSelectorDialog extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      search: '',
      monitoring_service_type: '',
      tag: '',

      choices: {
        services: [],
        tags: [],
        monitoring_service_type: [],
      },
    };

    this.throttledLoadAvailableChecks = _.throttle(this.loadAvailableChecks, 500);
    this.debouncedLoadAvailableChecks = _.debounce(this.throttledLoadAvailableChecks, 750);
  }

  componentDidMount() {
    this.loadAvailableChecks(true);
  }

  loadAvailableChecks(withChoices) {
    new Ajax().get({
      url: this.props.servicesCheckListURL,
      data: {
        choices: withChoices ? 'true' : '',
        search: this.state.search,
        monitoring_service_type: this.state.monitoring_service_type,
        tag: this.state.tag,
        limit: this.props.maxServices,
      },
      encoder: 'url',
      decoder: 'json',
      success: function (data) {
        this.setState({
          choices: Object.assign({}, this.state.choices, data.choices),
        });
      }.bind(this),
    });
  }

  _unselectedChoices(form) {
    const selectedIds = form.value.map((x) => x.id);
    const selectedLookup = _.object(selectedIds, selectedIds);
    return this.state.choices.services.filter((x) => !selectedLookup[x.id]);
  }

  handleSearchChange(e) {
    this.setState({search: e.target.value}, this.debouncedLoadAvailableChecks);
  }

  handleMonitoringServiceTypeChange(e) {
    this.setState({monitoring_service_type: e.target.value}, this.throttledLoadAvailableChecks);
  }

  handleTagChange(e) {
    this.setState({tag: e.target.value}, this.throttledLoadAvailableChecks);
  }

  handleSelectService(svc, e) {
    e.preventDefault();

    const form = unpackFormLinkOrProps(this.props);
    if (form.value.length >= this.props.maxServices) {
      return;
    }

    const val = form.value.concat([svc]);
    const event = new CustomEvent({type: 'change', target: this, value: val});
    form.onChange(event);
  }

  handleRemoveService(svc, e) {
    e.preventDefault();

    const form = unpackFormLinkOrProps(this.props);
    const val = form.value.filter((x) => x.id !== svc.id);
    const event = new CustomEvent({type: 'change', target: this, value: val});
    form.onChange(event);
  }

  handleSelectAll(e) {
    e.preventDefault();

    const form = unpackFormLinkOrProps(this.props);
    const remaining = this.props.maxServices - form.value.length;
    const unselectedChoices = this._unselectedChoices(form);
    const choicesToAdd = unselectedChoices.slice(0, remaining);
    const val = form.value.concat(choicesToAdd);
    const event = new CustomEvent({type: 'change', target: this, value: val});
    form.onChange(event);
  }

  handleRemoveAll(e) {
    e.preventDefault();

    const form = unpackFormLinkOrProps(this.props);
    const val = [];
    const event = new CustomEvent({type: 'change', target: this, value: val});
    form.onChange(event);
  }

  render() {
    // Fade effect for the dialog
    setTimeout(() => $(this.refs.pane).addClass('show'), 0);

    const form = unpackFormLinkOrProps(this.props);
    const unselectedChoices = this._unselectedChoices(form);

    return (
      <div ref="pane" className="service-selector-dialog tab-pane fade active">
        <div className="row">
          <div className="col-lg-6 col-sm-12 mb-2">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-search" />
                </span>
              </div>
              <input
                type="text"
                value={this.state.search}
                onChange={this.handleSearchChange}
                className="form-control"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 mb-2">
            <SelectBox
              fieldName="monitoring_service_type"
              groups={this.state.choices.monitoring_service_type}
              allowEmpty={true}
              value={this.state.monitoring_service_type}
              onChange={this.handleMonitoringServiceTypeChange}
              emptyLabel="--- Type ---"
            />
          </div>
          <div className="col-lg-3 col-sm-6 mb-2">
            <SelectBox
              fieldName="service_tag"
              choices={this.state.choices.tags}
              allowEmpty={true}
              emptyLabel="--- Tag ---"
              value={this.state.tag}
              onChange={this.handleTagChange}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 mb-3">
            <ServiceListPanel
              labelText="Checks Available"
              titleText="Click on checks to add"
              services={unselectedChoices}
              hasMoreServices={this.state.choices.services.length >= this.props.maxServices}
              onServiceClick={this.handleSelectService}
            />
            <a href="" role="button" onClick={this.handleSelectAll} className="d-block mt-n3">
              Select All
            </a>
          </div>
          <div className="col-lg-6 mb-3">
            <ServiceListPanel
              labelText={form.value.length + Formatter.pluralize(form.value.length, ' Check', ' Checks') + ' Selected'}
              titleText="Click on checks to remove"
              services={form.value}
              onServiceClick={this.handleRemoveService}
            />
            <div>
              <a href="" role="button" onClick={this.handleRemoveAll} className="d-block float-left mt-n3 mr-3">
                Remove All
              </a>
              <span className="d-block float-right text-muted small mt-n3">
                <em>Max {this.props.maxServices} checks</em>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ServiceSelectorDialog.defaultProps = {
  servicesCheckListURL: null, // URL for retrieving check choices
  maxServices: DEFAULT_MAX_SERVICES,
  fieldName: null, // Field name returned in form data
  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName
  onChange: null, // Change handler
};
