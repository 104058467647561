'use strict';

import React from 'react';
import _ from 'underscore';
import {uniqueHtmlId, unpackFormLinkOrProps, CustomEvent, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import './IntlTelInput.css';

export class IntlTelInputWidget extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = props.htmlId || uniqueHtmlId(props.fieldName);
    this.handleChange = this.handleChange.bind(this);
    this.state = {};
  }

  componentDidMount() {
    const countryAvailable = _.any(this.props.countryCodes, (code) => code === window.COUNTRY);
    const telInput = intlTelInput(document.getElementById(this.refs.input.id), {
      utilsScript: '/static/js/intl-tel-input/utils.js',
      initialCountry: countryAvailable ? window.COUNTRY : 'us',
      nationalMode: true,
      numberType: 'MOBILE',
      preferredCountries: this.props.countryCodes ? [] : ['us', 'gb'],
      onlyCountries: this.props.countryCodes || [],
      excludeCountries: [],
    });
    this.setState({
      telInput: telInput.telInput,
      intlTelInput: telInput,
    });
    telInput.telInput.addEventListener('countrychange', this.handleChange);
    const form = unpackFormLinkOrProps(this.props);
    telInput.setNumber(form.value);
  }

  componentWillReceiveProps(nextProps) {
    const oldForm = unpackFormLinkOrProps(this.props);
    const newForm = unpackFormLinkOrProps(nextProps);

    if (oldForm.value === newForm.value) {
      return;
    }

    if (newForm.value === this._getPhoneNumber()) {
      return;
    }

    this.state.intlTelInput.setNumber(newForm.value);
  }

  componentWillUnmount() {
    if (this.state && this.state.telInput) {
      this.state.telInput.removeEventListener('countrychange', this.handleChange);
      this.state.intlTelInput.destroy();
    }
  }

  focus() {
    this.state.telInput.focus();
  }

  handleChange() {
    const form = unpackFormLinkOrProps(this.props);
    const value = this._getPhoneNumber();
    const event = new CustomEvent({
      type: 'change',
      target: this,
      value: value,
    });
    form.onChange(event);
  }

  _getPhoneNumber() {
    let num = null;

    if (window.intlTelInputUtils) {
      num = this.state.intlTelInput.getNumber(window.intlTelInputUtils.numberFormat.INTERNATIONAL);
    }

    if (!num && this.state.telInput) {
      num = this.state.telInput.value;
    }

    return num;
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <input
        ref="input"
        type="tel"
        id={this.htmlId}
        name={this.props.fieldName}
        className={ReactUtils.cssClass('form-control', {['is-invalid']: form.errors})}
        maxLength="25"
        disabled={this.props.disabled}
        onChange={this.handleChange}
        onKeyPress={this.props.onKeyPress}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
      />
    );
  }
}

IntlTelInputWidget.defaultProps = {
  countryCodes: null, // An optional list of iso2 countries to enable in the widget.
  fieldName: '', // Field name returned in form data
  disabled: false, // Control is disabled?

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
  onKeyPress: null, // Keypress handler
  onFocus: null, // Focus handler
  onBlur: null, // Blur handler
};

export default class IntlTelInput extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  focus() {
    this.refs.widget.focus();
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);
    return (
      <div className="form-group">
        <Label fieldId={this.htmlId} {...this.props} />
        <IntlTelInputWidget ref="widget" htmlId={this.htmlId} {...this.props} />
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

IntlTelInput.defaultProps = {
  // ...IntlTelInputWidget.defaultProps +
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
};
