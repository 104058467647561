'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import Chart from 'react-apexcharts';
import {millisecondFormatter, datetimeFormatter} from '../RumUtils.jsx';
import {Colors} from '../RumColors.js';

export default class CheckRowSparkline extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      series: this._parseData(this.props.time, this.props.points),
      options: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: false,
          },
        },
        colors: [Colors.mediumLightBlue, Colors.green],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: [3, 1],
          dashArray: [0, 2],
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: (value) => millisecondFormatter(value).string,
          },
          x: {
            formatter: this._tooltipTitle.bind(this),
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: -140,
            offsetY: -40,
          },
        },
        grid: {
          show: false,
          padding: {
            top: 4,
            right: 8,
            bottom: 5,
            left: 8,
          },
        },
        legend: {
          show: false,
        },
      },
    };
  }

  componentDidUpdate() {
    this.state.series = this._parseData(this.props.time, this.props.points);
  }

  _parseData(time, points) {
    if (!time || !points) {
      return [];
    }
    if (points.length === 1) {
      points.push(points[0]);
    }
    if (time.length === 1) {
      time.push(time[0]);
    }
    const baselinePoints = Array(points.length).fill(this.props.baseline);

    return [
      {
        name: this.props.feature ? this.props.feature.name : 'value',
        data: points,
      },
      {
        name: '1-Week Baseline',
        data: baselinePoints,
      },
    ];
  }

  _tooltipTitle(_, options) {
    const time = this.props.time[options.dataPointIndex];
    return datetimeFormatter(time, this.props.time[0], this.props.time[this.props.time.length - 1], true);
  }

  render() {
    return (
      <div>
        <Chart options={this.state.options} series={this.state.series} width="120px" height="20px" />
      </div>
    );
  }
}
