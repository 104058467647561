import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import TextArea from '../../jskit/react/forms/TextArea';

export default class CustomRequestForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: this.props.initialValues || {},
      validationErrors: {},
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({validationErrors: {}});
    const data = this.state.formData;
    new Ajax().post({
      url: this.props.requestURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      disable: '#custom-quote-request button',
      success: function (data) {
        if (data.success) {
          if (this.props.onSuccess) {
            this.props.onSuccess(data);
          }
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    return (
      <React.Fragment>
        <h3 className="mb-1 text-center">Submit for Custom Quote Request</h3>
        <span className="d-block text-center mb-4">Scheduled within 24 hours</span>
        <form id="custom-quote-request" onSubmit={this.handleSubmit}>
          <div className="row form-row">
            <div className="col-12 col-md-6">
              <TextInput fieldName="first_name" labelText="First Name" isRequired={true} formLink={formLink} />
            </div>
            <div className="col-12 col-md-6">
              <TextInput fieldName="last_name" labelText="Last Name" isRequired={true} formLink={formLink} />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-12">
              <TextInput fieldName="email" labelText="Company Email" isRequired={true} formLink={formLink} />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-12">
              <TextInput fieldName="company_name" labelText="Company Name" isRequired={true} formLink={formLink} />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-12">
              <TextArea
                fieldName="custom_monitoring_needs"
                labelText="Custom Monitoring Needs"
                isRequired={true}
                formLink={formLink}
              />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-12">
              <TextArea
                fieldName="additional_comments"
                labelText="Additional Comments"
                isRequired={false}
                formLink={formLink}
              />
            </div>
          </div>
          <div className="row form-row mb-2">
            <div className="col-12 d-flex">
              <button className="ml-auto btn" onClick={this.props.onCancel}>
                Go Back
              </button>
              <button className="ml-4 btn btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
