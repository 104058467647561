'use strict';

import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import ServiceEscalations from './ServiceEscalations.jsx';

export default class BulkEscalationsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  serialize() {
    return this.refs.escalations.serialize();
  }

  showModal() {
    this.refs.modal.showModal();
    this.refs.escalations.deserialize(null);
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  render() {
    return (
      <Modal ref="modal" title="Edit multiple escalations" size="lg" cancelButton={true} saveButton={this.props.onSave}>
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>Note: Existing escalations will be replaced with whatever you set below.</em>
          </p>
          <ServiceEscalations ref="escalations" choices={this.props.choices} />
        </form>
      </Modal>
    );
  }
}
