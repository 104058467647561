'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';

export default class ComponentGroupForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {},
      validationErrors: {},
    };
  }

  getFormData() {
    const {service, auto_status_up, auto_status_down} = this.state.formData;
    return {
      ...this.state.formData,
      auto_status_up: service ? auto_status_up : '',
      auto_status_down: service ? auto_status_down : '',
    };
  }

  showModal(initialData) {
    if (initialData) {
      this.setState({formData: Object.assign({}, initialData || {})});
    } else {
      this.setState({formData: {}});
    }
    this.clearValidationErrors();
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleSubmit(e) {
    e.preventDefault();

    this.clearValidationErrors();
    new Ajax().post({
      url: this.props.updateURL,
      data: Object.assign({is_group: true}, this.getFormData()),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.props.onNewData(data.data);
          this.hideModal();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    const formData = this.getFormData();
    const title = formData.id ? 'Edit Component Group' : 'Add Component Group';

    return (
      <Modal ref="modal" title={title} saveButton={this.handleSubmit} cancelButton={true}>
        <form ref="form" className="form-spaced" onSubmit={this.handleSubmit}>
          {this.renderForm(formLink, formData)}
        </form>
      </Modal>
    );
  }

  renderForm(formLink) {
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="col-lg-12 font-14">
            A Component Group organizes related components to make it easy for your visitors to inspect. &nbsp;
            <ZendeskSupportLink
              /* eslint-disable-next-line max-len */
              href="https://support.uptime.com/hc/en-us/articles/360016256840-Status-Page-Forms-and-Fields#component_group_fields"
            >
              View&nbsp;Documentation&nbsp;&rarr;
            </ZendeskSupportLink>
          </div>
        </div>
        <FormErrors errors={this.state.validationErrors.__all__} />
        <TextInput
          fieldName="name"
          labelText="Group Name"
          titleText="Group related components under this heading."
          isRequired={true}
          formLink={formLink}
        />
        <TextInput
          fieldName="description"
          labelText="Group Description"
          isRequired={false}
          /* eslint-disable-next-line max-len */
          titleText="OPTIONAL: Add a description visible to Page visitors beneath the name of the component group."
          formLink={formLink}
        />
      </React.Fragment>
    );
  }
}

ComponentGroupForm.defaultProps = {
  updateURL: null,
  onNewData: null,
};
