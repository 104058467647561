import Schedule from './Schedule.jsx';
import ServiceSelectorInput from './ServiceSelectorInput.jsx';
import ServiceSelectorDialog from './ServiceSelectorDialog.jsx';
import SingleServiceSelectBox from './SingleServiceSelectBox.jsx';
import TestRunner from './TestRunner.jsx';
import {TaskStatus} from './TaskStatus.jsx';

const GroupUptimePercentCalculation = {
  AVERAGE: 'AVERAGE',
  UP_DOWN_STATES: 'UP_DOWN_STATES',
};

// true if the check supports alerting / downtimes
function contactGroupsAllowed(formData) {
  if (!formData) {
    return false;
  }

  // here go all cases when check does not support alerting
  return !(
    formData.monitoring_service_type === 'GROUP' &&
    formData.group_uptime_percent_calculation === GroupUptimePercentCalculation.AVERAGE
  );
}

export {
  Schedule,
  ServiceSelectorInput,
  ServiceSelectorDialog,
  SingleServiceSelectBox,
  TaskStatus,
  TestRunner,
  contactGroupsAllowed,
  GroupUptimePercentCalculation,
};
