'use strict';

import React from 'react';
import moment from 'moment';
import _ from 'underscore';
import Utils from '../jskit/general/Utils';
import Ajax from '../jskit/general/Ajax';
import Formatter from '../jskit/general/Formatter';
import Chart from 'chart.js';
import SpeedTestUtils from './SpeedTestUtils';

import $ from 'jquery';

const Status = {
  NEW: 0,
  RUNNING: 10,
  COMPLETED: 100,
  ERROR: 999,
};

export default class SpeedTestResults extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      status: null,
      data: null,
    };
    this.loadTimer = 0;
  }

  componentDidMount() {
    this.loadAllData();
  }

  componentDidUpdate() {
    if (this.state.data !== null) {
      this.updateAllAnalysisCharts(this.state.data);
    }
  }

  loadAllData() {
    if (this.loadTimer >= 120000) {
      this.setState({status: 999}); // error
      return;
    }
    new Ajax().get({
      url: this.props.statusURL.replace('000', this.props.test.id),
      decoder: 'json',
      success: function (data) {
        this.setState({
          status: data.status,
          data: data.data || null,
          error_text: data.error_text || 'There was an error running this test. Please try again later.',
        });

        if (this.shouldShowProgress()) {
          this.loadTimer += 2500;
          setTimeout(this.loadAllData.bind(this), 2500);
        }
      }.bind(this),
    });
  }

  shouldShowProgress() {
    return this.props.showProgress && this.state.status !== null && this.state.status < Status.COMPLETED;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderHeader()}
        <div className="white-block p-4 mt-4 no-bottom-radius">
          <nav id="speed-test-tabs" className="nav nav-tabs nav-tabs-plain" role="tablist">
            <a
              className="nav-item nav-link active"
              data-toggle="tab"
              href="#speed-test-recommendations"
              role="tab"
              aria-controls="speed-test-recommendations"
              aria-selected="true"
            >
              Recommendations
            </a>
            <a
              className="nav-item nav-link"
              data-toggle="tab"
              href="#speed-test-analysis"
              role="tab"
              aria-controls="speed-test-analysis"
              aria-selected="false"
            >
              Analysis
            </a>
            <a
              className="nav-item nav-link"
              data-toggle="tab"
              href="#speed-test-waterfall"
              role="tab"
              aria-controls="speed-test-waterfall"
              aria-selected="false"
            >
              Waterfall
            </a>
            {this.props.history ? (
              <a
                className="nav-item nav-link"
                data-toggle="tab"
                href="#speed-test-history"
                role="tab"
                aria-controls="speed-test-history"
                aria-selected="false"
              >
                History
              </a>
            ) : null}
          </nav>
        </div>
        {this.renderErrorOrProgress()}
        <div className="tab-content">
          <div
            className="white-block p-4 no-top-radius tab-pane fade active show"
            id="speed-test-recommendations"
            role="tabpanel"
          >
            {this.state.data ? this.renderRecommendations(this.state.data) : null}
          </div>
          <div className="white-block p-4 no-top-radius tab-pane fade" id="speed-test-analysis" role="tabpanel">
            {this.state.data ? this.renderAnalysis(this.state.data) : null}
          </div>
          <div className="tab-pane fade" id="speed-test-waterfall" role="tabpanel">
            {this.state.data ? this.renderWaterfall(this.state.data) : null}
          </div>
          {this.props.history ? (
            <div className="tab-pane fade" id="speed-test-history" role="tabpanel">
              {this.renderHistoryTable(this.props.test, this.props.history)}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }

  renderHeader() {
    const screenshot = this.state.data ? this.state.data.screenshot : null;
    const test = this.state.data && this.state.data.test ? this.state.data.test : {};
    const pagespeed = this.state.data && this.state.data.pagespeed ? this.state.data.pagespeed : null;
    const space = <span>&nbsp;</span>;

    return (
      <div id="speed-test-header" className="row mt-4">
        <div className="col-xl-3 mb-3">
          <img
            alt=""
            src={screenshot}
            className="mx-auto mb-4 mb-lg-0"
            width="300"
            height="168"
            style={{maxWidth: '100%'}}
          />
        </div>
        <div className="col-xl-9">
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className={SpeedTestUtils.loadTimeCSSClass(test.load_ms) + ' header-block d-flex'}>
                <i className="far fa-history text-primary bg-primary-fade" />
                <div>
                  <span className="label">Loading Time</span>
                  <span className="value">{test.load_ms ? test.load_ms + ' ms' : space}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="header-block d-flex">
                <i className="far fa-exchange text-primary bg-primary-fade" />
                <div>
                  <span className="label">Requests</span>
                  <span className="value">{test.request_count || space}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="header-block d-flex">
                <i className="far fa-folder-download text-primary bg-primary-fade" />
                <div>
                  <span className="label">Total Size</span>
                  <span className="value">{test.fmt_size || space}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div
                className={
                  SpeedTestUtils.ratingCSSClass(pagespeed && pagespeed.performance_score) + ' header-block d-flex'
                }
              >
                <i className="far fa-tachometer-alt-fast text-primary bg-primary-fade" />
                <div>
                  <span className="label">Performance Grade</span>
                  <span className="value">{pagespeed ? pagespeed.performance_score + '/100' : space}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div
                className={
                  SpeedTestUtils.ratingCSSClass(pagespeed && pagespeed.usability_score) + ' header-block d-flex'
                }
              >
                <i className="far fa-mobile-alt text-primary bg-primary-fade" />
                <div>
                  <span className="label">Mobile Usability</span>
                  <span className="value">{pagespeed ? pagespeed.usability_score + '/100' : space}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="header-block d-block">
                <div className="d-flex align-items-center mb-2">
                  <i className="far fa-globe text-primary bg-primary-fade small" />
                  <span className="value small">{test.location || space}</span>
                </div>
                <div className="d-flex align-items-center">
                  <i className="far fa-calendar text-primary bg-primary-fade small" />
                  <span className="value small">{Formatter.longDateTime(test.created_on) || space}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderErrorOrProgress() {
    if (this.state.status === Status.ERROR) {
      return (
        <div className="white-block white-block-divider p-4">
          <div className="alert alert-danger mb-0" role="alert">
            {this.state.error_text}
          </div>
        </div>
      );
    } else if (this.shouldShowProgress()) {
      const isInQueue = this.state.status === Status.NEW;
      const max = this.props.maxTestTime;
      const start = moment.utc(this.props.test.created_on);
      const elapsed = moment.utc().diff(start, 'seconds');
      const progress = Math.min((elapsed / max) * 100.0, 98);

      return (
        <div className="white-block white-block-divider p-4">
          <p className="mb-1">
            Please wait...
            <strong>{isInQueue ? 'Test is waiting in queue.' : 'Test is in progress.'}</strong>
          </p>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow={isInQueue ? 0 : progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{width: (isInQueue ? 0 : progress) + '%'}}
            />
          </div>
        </div>
      );
    }
  }

  renderRecommendations(data) {
    if (!data.pagespeed) {
      return <p>Sorry, we were unable to determine recommendations for this domain.</p>;
    } else {
      return (
        <React.Fragment>
          <h4 className="mb-4">Performance</h4>
          <div className="row mb-4">{this.renderRecommendationsBlocks('performance', data.pagespeed.performance)}</div>
          <h4 className="mb-4">Usability</h4>
          <div className="row mb-4">{this.renderRecommendationsBlocks('usability', data.pagespeed.usability)}</div>
        </React.Fragment>
      );
    }
  }

  renderRecommendationsBlocks(type, blocks) {
    const successIcon = <i className="rec-icon fas fa-sm fa-check bg-secondary mr-4"></i>;
    const warningIcon = <i className="rec-icon fas fa-sm fa-exclamation bg-warning mr-4"></i>;

    return blocks.map((block, i) => {
      const key = type + '-recommendation-' + i;
      const selector = '#' + key;

      const urlBlocks = (block.urlBlocks || []).map((b, i) => {
        const urls = (b.urls || []).map((u, j) => <li key={j} dangerouslySetInnerHTML={{__html: u.result.format}} />);

        return (
          <React.Fragment key={i}>
            <p dangerouslySetInnerHTML={{__html: b.header.format}} />
            <ul className="text-force-wrap">{urls}</ul>
          </React.Fragment>
        );
      });

      return (
        <div className="col-lg-6 mb-3" key={key}>
          <div
            className="rec-block p-4 d-flex align-items-center"
            data-toggle="collapse"
            data-target={selector}
            aria-controls={key}
          >
            {block.ruleImpact ? warningIcon : successIcon}
            <p className="mb-0">{block.localizedRuleName}</p>
          </div>
          <div className="rec-block-content p-4 collapse" id={key} data-parent="#speed-test-recommendations">
            {block.summary ? <p dangerouslySetInnerHTML={{__html: block.summary.format}} /> : null}
            {urlBlocks}
          </div>
        </div>
      );
    });
  }

  renderAnalysis(data) {
    return (
      <div className="row">
        {this.renderAnalysisChart('loading-per-content-type-chart', 'Loading Time per Content Type')}
        {this.renderAnalysisChart('loading-per-domain-chart', 'Loading Time per Domain')}
        {this.renderAnalysisChart('size-per-content-type-chart', 'Size per Content Type')}
        {this.renderAnalysisChart('size-per-domain-chart', 'Size per Domain')}
        {this.renderAnalysisChart('requests-per-content-type-chart', 'Requests per Content Type')}
        {this.renderAnalysisChart('requests-per-domain-chart', 'Requests per Domain')}
      </div>
    );
  }

  renderAnalysisChart(chartId, title) {
    return (
      <div className="col-lg-6 mb-5" key={chartId}>
        <h4 className="mb-4">{title}</h4>
        <div className="white-block p-4">
          <div className="position-relative">
            <canvas id={chartId} width="0" height="200" style={{width: '100%', height: '200px'}}></canvas>
          </div>
        </div>
      </div>
    );
  }

  updateAllAnalysisCharts(data) {
    const pane = $('#speed-test-analysis');
    const isVisible = pane.hasClass('show');
    const analysis = data.analysis;

    const formatTime = (x) => x.toString() + ' ms';
    const formatBytes = (x) => Math.round(x / 1024.0).toString() + ' KB';
    const formatRequests = (x) => x.toString() + ' requests';

    if (!isVisible) {
      pane.addClass('active show');
    }

    this.updateAnalysisChart(
      'loading-per-content-type-chart',
      Object.keys(analysis.type),
      Object.values(analysis.type).map((x) => Math.round(x.time)),
      formatTime
    );

    this.updateAnalysisChart(
      'loading-per-domain-chart',
      Object.keys(analysis.domain),
      Object.values(analysis.domain).map((x) => Math.round(x.time)),
      formatTime
    );

    this.updateAnalysisChart(
      'size-per-content-type-chart',
      Object.keys(analysis.type),
      Object.values(analysis.type).map((x) => x.size),
      formatBytes
    );

    this.updateAnalysisChart(
      'size-per-domain-chart',
      Object.keys(analysis.domain),
      Object.values(analysis.domain).map((x) => x.size),
      formatBytes
    );

    this.updateAnalysisChart(
      'requests-per-content-type-chart',
      Object.keys(analysis.type),
      Object.values(analysis.type).map((x) => x.count),
      formatRequests
    );

    this.updateAnalysisChart(
      'requests-per-domain-chart',
      Object.keys(analysis.domain),
      Object.values(analysis.domain).map((x) => x.count),
      formatRequests
    );

    if (!isVisible) {
      pane.removeClass('active show');
    }
  }

  updateAnalysisChart(chartId, labels, dataPoints, tooltipFn) {
    const sortedData = _.sortBy(_.zip(_.range(dataPoints.length), dataPoints), (x) => x[1]);
    const largeValueIndexes = _.object(sortedData.slice(-7));

    tooltipFn = tooltipFn || ((x) => x);

    new Chart(document.getElementById(chartId), {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: dataPoints,
            backgroundColor: [
              '#289DF5',
              '#72C4B9',
              '#F1B497',
              '#3373B4',
              '#FFD600',
              '#C08DE3',
              '#40557D',
              '#2BAF00',
              '#FF4861',
              '#F5A623',

              '#289DF5',
              '#72C4B9',
              '#F1B497',
              '#3373B4',
              '#FFD600',
              '#C08DE3',
              '#40557D',
              '#2BAF00',
              '#FF4861',
              '#F5A623',
            ],
            borderColor: 'rgba(0,0,0,0)',
            borderWidth: 0,
          },
        ],
        labels: labels,
      },
      options: {
        legend: {
          display: true,
          position: 'right',
          fullWidth: false,
          labels: {
            boxWidth: 12,
            fontSize: 14,
            fontColor: '#202A2E',
            fontFamily: '"barlow", "sans-serif"',

            filter: function (legendItem) {
              return largeValueIndexes[legendItem.index] !== undefined;
            },
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const index = tooltipItem.index;
              const label = data.labels[index];
              const value = tooltipFn(data.datasets[tooltipItem.datasetIndex].data[index]);
              return label + ': ' + value.toString();
            },
          },
        },
      },
    });
  }

  renderWaterfall(data) {
    const ticks = data.wf_ticks.map((t, i) => (
      <span key={i} className="wf-tick" style={{left: t.left + '%'}}>
        {t.val}
      </span>
    ));

    return (
      <div className="white-block p-4 no-top-radius">
        <table className="table listing-table table-valign-middle table-responsive-lg mb-0">
          <thead>
            <tr>
              <th>Type</th>
              <th>URL</th>
              <th>Size</th>
              <th className="wf-tick-cell">{ticks}</th>
            </tr>
          </thead>
          <tbody>{data.results.map(this.renderWaterfallRow.bind(this))}</tbody>
          <tfoot>
            <tr className="font-weight-semibold">
              <td colSpan="2">Total requests: {data.test.request_count}</td>
              <td className="nowrap">{data.test.fmt_size}</td>
              <td className="text-right">Load end time: {data.test.load_secs} s </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }

  renderWaterfallRow(result, i) {
    const wf = result.waterfall;
    const tooltip = `Start: ${Math.round(wf.start)}ms.  Waiting for response: ${Math.round(
      wf.wait
    )}ms.  Downloading data: ${Math.round(wf.send)}ms.`;

    return (
      <tr key={i}>
        <td title={result.mime_type}>{result.content_type}</td>
        <td>
          <span className="d-block text-dark" title={result.path}>
            {Formatter.truncate(result.path, 45)}
          </span>
          {result.domain}
        </td>
        <td>{result.fmt_size}</td>
        <td className="position-relative" title={tooltip}>
          <span className="wf-wait" style={{left: wf.px.start + '%', width: wf.px.wait + '%'}}></span>
          <span className="wf-send" style={{left: wf.px.send_start + '%', width: wf.px.send + '%'}}></span>
        </td>
      </tr>
    );
  }

  renderHistoryTable(test, history) {
    return (
      <div className="white-block p-4 no-top-radius">
        <table className="table listing-table table-responsive-md mb-0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Load Speed</th>
              <th>Requests</th>
              <th>Page Size</th>
            </tr>
          </thead>
          <tbody>{history.map(this.renderHistoryTableRow.bind(null, test))}</tbody>
        </table>
      </div>
    );
  }

  renderHistoryTableRow(test, row) {
    const createdOn = Formatter.longDateTime(row.created_on);
    let link;

    if (row.id === test.id) {
      link = <span>{createdOn + ' (current result)'}</span>;
    } else {
      link = <a href={this.props.resultURL + '/' + row.id + '/' + row.domain}>{createdOn}</a>;
    }

    return (
      <tr key={row.id}>
        <td>{link}</td>
        <td>{row.load_secs ? row.load_secs.toFixed(2) + ' s' : ''}</td>
        <td>{row.request_count}</td>
        <td>{row.fmt_size}</td>
      </tr>
    );
  }
}
