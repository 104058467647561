const EmptyState = ({title, subtitle, btnText, onClick, helpImage, helpTitle, helpSubtitle, helpLinks, children}) => {
  return (
    <div className="white-block white-block-border mb-3">
      <div className="container mw-100">
        <div className="row">
          <div className="col-lg-8 col-xl-9">
            <div className="row">
              <div className="offset-lg-1 col-lg-10 offset-xl-2 col-xl-8 p-5 text-center mb-lg-5">
                <h3 className="mb-5 font-32 font-weight-bold pt-lg-5">
                  {title}
                  <small className="d-block mt-2 text-muted font-16 font-weight-light">{subtitle}</small>
                </h3>
                <p>
                  <a onClick={onClick} className="btn btn-primary">
                    {btnText}
                  </a>
                </p>
                {children}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-3 bg-light-2 px-5 py-4">
            <img className="img-fluid mb-4 shadow" src={helpImage} />
            <h5 className="font-14">{helpTitle}</h5>
            <p className="d-block mt-2 text-muted font-14 font-weight-light">{helpSubtitle}</p>
            <p className="font-14">
              {(helpLinks || []).map((item, idx) => (
                <a key={idx} className="d-inline-block mb-2" href={item.link} target="_blank">
                  {item.title}
                </a>
              ))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
