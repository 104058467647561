import Popper from 'popper.js';
import React from 'react';
import _ from 'underscore';

import Utils from '../jskit/general/Utils';
import ReactUtils from '../jskit/react/ReactUtils';
import {FieldErrors} from '../jskit/react/forms/FormHelpers';

import TransactionUtils from './TransactionUtils.jsx';
import StepCommandSelector from './transaction_script_editor/StepCommandSelector.jsx';
import StepsList from './transaction_script_editor/StepsList.jsx';
import Validators from './transaction_script_editor/Validators';

import $ from 'jquery';

export default class TransactionScriptEditor extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      transactionStepDefs: [],
      transactionStepDefsMap: _.object([]),

      monitoringServiceType: null,

      transactionScript: [],
      consoleOutput: null,

      selectedIndex: -1,
      isAddingNewStep: false,
      addIndex: null,
      isRunningTest: false,
      validation_errors: null,
      ext_data_ref: null,
    };
  }

  runTestStart() {
    window.DEBUG && console.log('runTestStart');
    if (this.currentStepContainsErrors() || this.state.isRunningTest) {
      return;
    }

    if (this.props.onRunTestStart) {
      this.props.onRunTestStart();
    }
    this.setState({
      consoleOutput: null,
      selectedIndex: -1,
      isAddingNewStep: false,
      isRunningTest: true,
      validation_errors: null,
      ext_data_ref: null,
    });
  }

  runTestComplete(test_result, validation_errors) {
    window.DEBUG && console.log('runTestCompleted', test_result);
    let test_data = null;
    try {
      test_data = test_result !== null ? JSON.parse(test_result.result) : null;
    } catch (e) {
      this.setState({
        isRunningTest: false,
        validation_errors: 'Invalid response from test server: ' + test_result.result,
      });
      return;
    }
    if (!test_data || !test_data.results) {
      this.setState({
        isRunningTest: false,
        validation_errors: validation_errors,
      });
      return;
    }
    window.DEBUG && console.log('Test Data', test_data);
    const version = test_data.version || '2.0.0';
    const firstStepIndex = Utils.isVersionGreaterOrEqual(version, '3.0.0') ? 0 : 1;
    var newScript = _.map(
      this.state.transactionScript,
      function (step, index) {
        if (test_data.results[index]) {
          // determine 1st step index, v2 results have network data starting from 1 vs v3 version results starts from 0
          var requests = _.sortBy(
            _.filter(test_data.network, (item) => item.step === index + firstStepIndex),
            (item) => item.timing.requestWillBeSent
          );
          var newStep = _.extend({}, step, {
            results: test_data.results[index],
            requests: requests,
          });
          if (test_data.results[index].html) {
            newStep.results.html_elements = this._extractHTMLElementsFromRawHTML(test_data.results[index].html);
          }
          return newStep;
        } else {
          return step;
        }
      }.bind(this)
    );
    this.setState({
      transactionScript: newScript,
      consoleOutput: test_data.console,
      ext_data_ref: {
        task_id: test_result.task_id,
        file: test_result.file,
      },
      isRunningTest: false,
    });
    if (this.props.onRunTestComplete) {
      this.props.onRunTestComplete();
    }
  }

  _extractHTMLElementsFromRawHTML(html) {
    html = html.replace(/src=["'].*?["']/g, '');
    var parsed = $(html);
    var parsed1 = parsed.filter('*[id], *[name], *[class]');
    var parsed2 = parsed.find('*[id], *[name], *[class]');
    parsed = parsed2.add(parsed1);

    var tags_order = ['button', 'input', 'select', 'textarea', 'a'];
    var sorted = _.sortBy(parsed, function (e) {
      const order_idx = tags_order.indexOf(e.tagName.toLowerCase());
      if (order_idx > -1) {
        return order_idx;
      }
      return 100;
    });

    return $.map(sorted, function (e) {
      var ret = '<' + e.tagName.toLowerCase();
      if (e.id) {
        ret += ' id="' + e.id + '"';
      }
      if (e.name) {
        ret += ' name="' + e.name + '"';
      }
      if (e.className) {
        ret += ' class="' + e.className + '"';
      }
      ret += '/>';
      return ret;
    });
  }

  getScript() {
    return this.state.transactionScript;
  }

  serialize() {
    window.DEBUG && console.log('serialize');
    var scriptToSerialize = _.map(this.state.transactionScript, function (step) {
      var stepData = {
        step_def: step.step_def,
        values: _.clone(step.values),
      };

      _.each(step.fields, function (field) {
        stepData.values[field.name] = Validators.validateField(field, stepData.values[field.name]).serializedValue;
      });

      return stepData;
    });

    return JSON.stringify(scriptToSerialize);
  }

  deserialize(monitoringServiceType, serializedScript) {
    window.DEBUG && console.log('deserialize');
    let stepDefsKey = monitoringServiceType;
    var stepDefs = this.props.stepDefs[stepDefsKey] || [];
    var stepDefsMap = _.object(stepDefs);

    var script = null;
    try {
      if (!serializedScript) {
        script = [];
      } else {
        script = JSON.parse(serializedScript);
      }
    } catch (exc) {
      window.DEBUG && console.log(exc);
    }

    if (!_.isArray(script)) {
      return;
    }

    script = _.filter(
      script,
      function (step) {
        return stepDefsMap[step.step_def];
      }.bind(this)
    );

    script = TransactionUtils.mergeStepDefsIntoScript(script, stepDefsMap);

    script = _.map(
      script,
      function (step) {
        step = _.extend(TransactionUtils.createEmptyStep(), step);
        _.each(step.fields, function (field) {
          step.values[field.name] = Validators.deserializeField(field.type, step.values[field.name]);
        });

        return step;
      }.bind(this)
    );

    let selectedIndex = -1;

    if (
      script.length === 1 &&
      script[0].step_def === 'C_OPEN_URL' &&
      script[0].values.url === stepDefsMap['C_OPEN_URL'].fields[0].default
    ) {
      // open Go to URL step form for new check
      selectedIndex = 0;
    }

    this.setState({
      transactionStepDefs: stepDefs,
      transactionStepDefsMap: stepDefsMap,
      monitoringServiceType: monitoringServiceType,
      transactionScript: script,
      consoleOutput: null,
      selectedIndex: selectedIndex,
      isAddingNewStep: false,
      isRunningTest: false,
      validation_errors: null,
      ext_data_ref: null,
    });

    return null;
  }

  currentStepContainsErrors() {
    if (this.state.selectedIndex < 0) {
      return false;
    }

    var currentStep = this.state.transactionScript[this.state.selectedIndex];
    return !_.isEmpty(currentStep.errors);
  }

  removeStep(index) {
    window.DEBUG && console.log('removeStep', index);
    if (index === undefined) {
      index = this.state.transactionScript.length - 1;
    }
    if ((this.state.selectedIndex !== index && this.currentStepContainsErrors()) || this.state.isRunningTest) {
      return;
    }
    var newScript = this.state.transactionScript.concat();
    newScript.splice(index, 1);
    this.setState({
      selectedIndex: -1,
      isAddingNewStep: false,
      transactionScript: newScript,
    });
  }

  _makeStep(step_key, serialized_step) {
    const step = _.extend(
      TransactionUtils.createEmptyStep(step_key),
      serialized_step,
      this.state.transactionStepDefsMap[step_key]
    );

    // fill in default values
    var fieldsAndValues = _.object(
      _.map(step.fields, function (f) {
        var value = null;
        if (step.values && step.values[f.name] !== undefined) {
          value = step.values[f.name];
        }
        if (step.type === 'BOOLEAN') {
          return [f.name, value || f.default];
        } else {
          return [f.name, value || f.default || ''];
        }
      })
    );

    step.values = fieldsAndValues;
    return step;
  }

  replaceStep(index, new_step_key, serialized_step) {
    window.DEBUG && console.log('replaceStep', index, new_step_key, serialized_step);
    var currentStep = _.omit(
      _.extend(this.state.transactionScript[index], serialized_step),
      'step_def',
      'is_deprecated',
      'replace_with'
    );
    var newStep = this._makeStep(new_step_key, currentStep);
    var newScript = this.state.transactionScript.concat();
    newScript.splice(index, 1, newStep);
    this.setState({
      selectedIndex: index,
      isAddingNewStep: false,
      transactionScript: newScript,
    });
  }

  reorderStep(fromIndex, toIndex) {
    window.DEBUG && console.log('reorderStep', fromIndex, toIndex);
    if (this.currentStepContainsErrors() || this.state.isRunningTest) {
      return;
    }

    if (fromIndex === toIndex) {
      return;
    }
    var newScript = this.state.transactionScript.concat();
    var stepToMove = newScript.splice(fromIndex, 1);
    newScript.splice(toIndex, 0, stepToMove[0]);
    this.setState({
      selectedIndex: -1,
      isAddingNewStep: false,
      transactionScript: newScript,
    });
  }

  updateSelectedStepField(field, value) {
    var newScript = this.state.transactionScript.concat(),
      index = this.state.selectedIndex;
    newScript = _.map(newScript, function (step, i) {
      if (i === index) {
        var fieldInfo = _.findWhere(step.fields, {name: field});
        var validation = Validators.validateField(fieldInfo, value);

        var newObj = {};
        newObj[field] = value;
        var values = _.extend({}, step.values, newObj);

        var newErr = {};
        newErr[field] = validation.error;
        var errors = validation.error ? _.extend({}, step.errors, newErr) : _.omit(step.errors, field);

        return _.extend({}, step, {
          values: values,
          errors: errors,
          results: {response_time: -1, error: null},
        });
      } else {
        return step;
      }
    });
    this.setState({transactionScript: newScript});
  }

  canAddStep(step_key) {
    if (step_key === 'C_AUTH_AND_SETTINGS') {
      // Do not allow to add Settings step if it already exists
      for (let i = 0; i < this.state.transactionScript.length; i++) {
        if (this.state.transactionScript[i].step_def === 'C_AUTH_AND_SETTINGS') {
          return false;
        }
      }
    } else if (this.state.transactionStepDefsMap[step_key].is_deprecated) {
      return false;
    }
    return true;
  }

  /* creates a new step of `step_key` type and inserts it in `index` position of ths script.
   * if a `serialized_step` is provided its values are used for step field values
   */
  addStep(step_key, index, serialized_step) {
    window.DEBUG && console.log('addStep', index, serialized_step);
    if (this.currentStepContainsErrors() || this.state.isRunningTest) {
      return;
    }

    if (index === undefined || index == null) {
      index = this.state.transactionScript.length;
    }
    if (serialized_step === undefined) {
      serialized_step = {};
    }

    const step = this._makeStep(step_key, serialized_step);
    if (step_key === 'C_AUTH_AND_SETTINGS') {
      index = 0;
    }

    var newScript = this.state.transactionScript.concat();
    newScript.splice(index, 0, step);
    this.setState(
      {
        transactionScript: newScript,
      },
      () => {
        this.selectStep(index);
      }
    );
  }

  selectStep(index) {
    window.DEBUG && console.log('selectStep', index);
    if (this.currentStepContainsErrors() || this.state.isRunningTest) {
      return;
    }

    let htmlElements = null;
    for (var i = index; i >= 0; i--) {
      if (this.state.transactionScript[i].results.html_elements) {
        htmlElements = this.state.transactionScript[i].results.html_elements;
      }
    }

    var newIndex = index === this.state.selectedIndex ? -1 : index;

    this.setState(
      {
        selectedIndex: newIndex,
        htmlElementList: htmlElements,
        isAddingNewStep: false,
      },
      () => {
        if (this.props.onSelectElementReset) {
          this.props.onSelectElementReset();
        }
      }
    );
  }

  handleAddStart(e, addButton, index) {
    if (this.currentStepContainsErrors() || this.state.isRunningTest) {
      return;
    }

    if (index === undefined) {
      index = this.state.transactionScript.length;
    }

    this.setState({
      selectedIndex: -1,
      isAddingNewStep: true,
      addIndex: index,
    });

    var options = {
      placement: 'bottom-end',
    };
    if (addButton === undefined) {
      addButton = document.querySelector('#mainAddButton');
      options = {
        placement: this.props.shortSelectors ? 'top' : 'top-start', // in recorder mode we use 'top' placement because top-start works bad on narrow screens (popup is placed outside of viewport)
        modifiers: {
          offset: {
            offset: '0,10',
          },
        },
      };
    }
    const tooltip = document.querySelector('#stepSelect');
    new Popper(addButton, tooltip, options);
  }

  addStepComplete(step_key) {
    window.DEBUG && console.log('addStepComplete');
    if (this.currentStepContainsErrors()) {
      return;
    }

    if (step_key == null) {
      this.setState({
        isAddingNewStep: false,
      });
      return;
    }

    this.addStep(step_key, this.state.addIndex);

    if (this.props.isRecording) {
      const step = this.state.transactionScript[this.state.addIndex];
      for (let i = 0; i < step.fields.length; i++) {
        const field = step.fields[i];
        if (field.type === 'SELECTOR') {
          this.props.onSelectElement(step, field, this.handleSelectElementCompleted);
          break;
        }
      }
    }
  }

  handleRunTest(e) {
    e.preventDefault();
    e.stopPropagation();
    this.runTestStart();
  }

  selectElementCompleted(step, field, selector, text) {
    this.updateSelectedStepField(field.name, selector);
    if (
      ['C_WAIT_FOR_ELEMENT_TEXT', 'V_ELEMENT_CONTAINS_TEXT', 'V_ELEMENT_DOES_NOT_CONTAIN_TEXT'].indexOf(
        step.step_def
      ) !== -1
    ) {
      this.updateSelectedStepField('text', text.substr(0, 50));
    }
  }

  render() {
    if (!this.props.isVisible) {
      return null;
    }

    var openRecorderBtn = null;
    if (this.props.onOpenRecorder) {
      openRecorderBtn = (
        <button className="btn btn-primary btn-sm" onClick={this.props.onOpenRecorder}>
          Edit in Recorder
        </button>
      );
    }

    var errors = this.state.validation_errors || this.props.validationErrors;

    return (
      <div id="transaction-editor" className="mt-2">
        <div className="steps-table-title mb-2">
          <h5>Check Steps</h5>
          {openRecorderBtn}
        </div>
        <StepsList
          shortSelectors={this.props.shortSelectors}
          allowReorder={!this.state.isRunningTest}
          onSelectElement={this.props.onSelectElement}
          onOpenURL={this.props.onOpenURL}
          transactionScript={this.state.transactionScript}
          selectedIndex={this.state.selectedIndex}
          htmlElementList={this.state.htmlElementList}
          handleAddStart={this.handleAddStart}
          onSelectStep={this.selectStep}
          onReorderStep={this.reorderStep}
          onReplaceStep={this.replaceStep}
          onDeleteStep={this.removeStep}
          onUpdateSelectedStepField={this.updateSelectedStepField}
          onSelectElementCompleted={this.selectElementCompleted}
        />
        <FieldErrors errors={errors} />
        <div className="operations mt-2 mb-3">
          <div>{this.renderAddStepButton()}</div>
        </div>
        {this.renderPagespeedReport()}
        {this.state.consoleOutput && this.state.consoleOutput.length ? this.renderConsoleOutput() : null}
      </div>
    );
  }

  renderAddStepButton() {
    return (
      <React.Fragment>
        <StepCommandSelector
          visible={this.state.isAddingNewStep}
          transactionStepDefs={this.state.transactionStepDefs}
          onCheckStepCanBeAdded={this.canAddStep}
          onAddStepComplete={this.addStepComplete}
        />
        <button
          type="button"
          id="mainAddButton"
          className="btn btn-primary btn-add-step mr-2"
          onClick={this.handleAddStart}
        >
          <i className="far fa-plus"></i> Add Step
        </button>
      </React.Fragment>
    );
  }

  renderConsoleOutput() {
    var output_items = this.state.consoleOutput.map(function (e) {
      if (e.file) {
        e.file = e.file.split('/');
        e.file = e.file[e.file.length - 1];
      }

      return `
              <li class="${e.type}">
                ${e.file ? `<div class="file-and-line float-right">${e.file} (line ${e.line})</div>` : ''}
                <i class="fas fa-fw"></i>
                <span>${ReactUtils.escapeHTML(e.message)}</span>
              </li>`;
    });
    var output = '<ul class="browser-console-output">';
    for (let i = 0; i < output_items.length; i++) {
      output += output_items[i];
    }
    output += '</ul>';

    return (
      <div className="browser-console-output btn-group dropup">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            TransactionUtils.createInfoWindow(output, 'Console Output');
          }}
        >
          View Console
        </button>
      </div>
    );
  }

  renderPagespeedReport() {
    if (this.state.monitoringServiceType === 'PAGESPEED' && this.state.ext_data_ref) {
      const url = this.props.pagespeedReportURL;
      const params = [];
      if (this.state.ext_data_ref.task_id) {
        params.push(`task_id=${this.state.ext_data_ref.task_id}`);
      }
      if (this.state.ext_data_ref.file) {
        params.push(`file=${this.state.ext_data_ref.file}`);
      }

      return (
        <React.Fragment>
          <a className="btn btn-link" href={url + '?' + params.join('&')} target="_blank">
            View pagespeed report
          </a>
        </React.Fragment>
      );
    }
    return null;
  }
}

TransactionScriptEditor.defaultProps = {
  isVisible: false,
  stepDefs: {}, // stepDefs,
};
