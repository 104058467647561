import React, {useEffect, useState} from 'react';
import $ from 'jquery';

export const FormModal = ({
  show,
  name,
  onCancel,
  formId,
  children,
}: {
  show: boolean;
  name: string;
  onCancel: () => void;
  formId: string;
  children: React.ReactNode;
}) => {
  const [renderForm, setRenderForm] = useState(false);
  const modalRef = React.useRef<any>(null);

  useEffect(() => {
    if (show) {
      setRenderForm(true);
      $(modalRef.current).modal('show');
    } else {
      $(modalRef.current).modal('hide');
    }
  }, [show, setRenderForm]);

  // this will unmount the form when the modal is closed to reset the form
  useEffect(() => {
    $(modalRef.current).on('hidden.bs.modal', function () {
      setRenderForm(false);
      onCancel();
    });
  }, [setRenderForm, onCancel]);

  return (
    <div ref={modalRef} className={`modal fade`} tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="light modal-title">{name}</h3>
            <button type="button" onClick={onCancel} className="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {renderForm ? <div className={'modal-body'}>{children}</div> : null}
          <div className={'modal-footer'}>
            <button className="btn btn-primary" type="submit" form={formId}>
              Save
            </button>
            <button className="btn btn-outline-primary" type="button" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
