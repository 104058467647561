'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import ReactUtils from '../../../jskit/react/ReactUtils';
import {uniqueHtmlId, unpackFormLinkOrProps, CustomEvent, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import {IntlTelInputWidget} from './IntlTelInput';

export default class ListInput extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
    Utils.autoBindClass(this);

    this.state = this._initialState();
  }

  _initialState() {
    return {
      enteredText: '',
      validationError: '',
    };
  }

  isTelInput() {
    return this.props.inputType === 'tel';
  }

  focus() {
    this.refs.textInput.focus();
  }

  reset() {
    this.setState(this._initialState());
  }

  handleKeyPress(e) {
    if (e.which === 13 && this.state.enteredText) {
      this.handleOnBlur(e, true);
    }
  }

  handleChange(e) {
    if (this.state.validationError) {
      this.setState({validationError: ''});
    }

    this.setState({enteredText: e.target.value.trim()});
  }

  handleOnBlur(e, noBlurEvent) {
    e.preventDefault();

    const {onBlur} = this.props;

    if (this.state.enteredText) {
      const form = unpackFormLinkOrProps(this.props);
      const finalText = this.state.enteredText;

      if (this.props.validationFn) {
        if (!this.props.validationFn(finalText)) {
          this.setState({validationError: this.props.validationError});
          return;
        }
      }

      const lastVal = form.value || [];
      const newVal = lastVal.concat([finalText]);

      this.setState({enteredText: ''});
      this._sendEvent(form, newVal);
    }

    if (onBlur && !noBlurEvent) {
      onBlur(e);
    }
  }

  handleAddClick(e) {
    // Merely to prevent event propagation when clicking the 'Add' button.
    // Actual 'add' event is handled by handleOnBlur method.
    e.preventDefault();
  }

  handleItemDelete(index, e) {
    e.preventDefault();

    const form = unpackFormLinkOrProps(this.props);

    const val = (form.value || []).slice();
    val.splice(index, 1);

    this._sendEvent(form, val);
  }

  _sendEvent(form, value) {
    const event = new CustomEvent({type: 'change', target: this, value: value});
    form.onChange(event);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className="form-group">
        <Label fieldId={this.htmlId} {...this.props} />
        <div className="input-group mb-1">
          {this.renderTextEntryWidget(form)}
          <div className="input-group-append">
            <button onClick={this.handleAddClick} className="btn btn-outline-secondary">
              Add
            </button>
          </div>
          <FieldErrors errors={this.state.validationError} />
        </div>
        {this.renderList(form)}
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }

  renderTextEntryWidget(form) {
    if (this.isTelInput()) {
      return (
        <IntlTelInputWidget
          ref="textInput"
          htmlId={this.htmlId}
          fieldName={this.props.fieldName}
          countryCodes={this.props.countryCodes}
          value={this.state.enteredText}
          formErrors={{[this.props.fieldName]: form.errors || this.state.validationError}}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          onBlur={this.handleOnBlur}
        />
      );
    } else {
      return (
        <input
          ref="textInput"
          type={this.props.inputType}
          id={this.htmlId}
          name={form.fieldName}
          placeholder={this.props.placeholder}
          className={ReactUtils.cssClass('form-control', {
            ['is-invalid']: form.errors || this.state.validationError,
          })}
          value={this.state.enteredText}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          onFocus={this.props.onFocus}
          onBlur={this.handleOnBlur}
        />
      );
    }
  }

  renderList(form) {
    const listValues = form.value;
    if (!listValues || listValues.length === 0) {
      return null;
    }

    let listValuesOrdered = listValues;
    if (this.props.alphabetical) {
      listValuesOrdered = listValues.sort((a, b) => (a > b ? 1 : -1));
    }

    const listItems = listValuesOrdered.map(
      function (v, index) {
        return (
          <li key={v} className="d-flex">
            <a role="button" href="#" onClick={this.handleItemDelete.bind(null, index)}>
              <i className="far fa-fw fa-trash-alt"></i>
            </a>{' '}
            {v}
          </li>
        );
      }.bind(this)
    );

    return <ul className="overflow-list form-control list-input">{listItems}</ul>;
  }
}

ListInput.defaultProps = {
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
  inputType: 'text', // Type of input: 'text', 'email', 'tel'
  alphabetical: false, // Whether or not to sort alphabetically

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
  onFocus: null, // Focus handler
  onBlur: null, // Blur handler
};
