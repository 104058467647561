'use strict';

import React from 'react';
import _ from 'underscore';
import Utils from '../jskit/general/Utils';
import URLHistory from '../jskit/general/URLHistory';
import Ajax from '../jskit/general/Ajax';
import Paginator from '../jskit/react/Paginator';
import AuditLogButtonBar from './AuditLogButtonBar.jsx';
import {AuditLogList, AuditLogDetailsModal} from './AuditLogList.jsx';
import MessageBox from '../js/global/messagebox';

export default class AuditLogController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      hasLoaded: false,
      items: [],
      total: 0,
      itemDetailsIndex: null,
    };
  }

  componentDidMount() {
    this.loadStateFromURL();
  }

  loadStateFromURL() {
    var qs = URLHistory.queryStringToObject();
    this.refs.buttonBar.setFiltersAndSearch(qs, qs.s);
    this.refs.paginator.reset(qs.p);

    this.setState({}, () => {
      this.loadAllData();
    });
  }

  updateStateToURL() {
    const buttonBarQuery = this.refs.buttonBar.getQueryFilter();
    const paginatorPage = this.refs.paginator.getCurrentPage();

    let qs = {};

    qs = _.extend(qs, buttonBarQuery.filters);
    qs = _.extend(qs, {s: buttonBarQuery.search});
    qs = _.extend(qs, {p: paginatorPage});
    qs = _.pick(qs, (v) => !!v && v.length !== 0);

    URLHistory.replaceState(qs);
  }

  loadAllData() {
    this.updateStateToURL();

    let query = {};
    query = _.extend(query, this.refs.buttonBar.getQueryFilter());
    query = _.extend(query, this.refs.paginator.getQueryOffsetLimit());

    new Ajax().post({
      url: this.props.listURL,
      data: query,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({hasLoaded: true, items: data.items, total: data.total});
      }.bind(this),
    });
  }

  showModalWithMessage(title, msg) {
    MessageBox.alertBox(msg, title);
  }

  handlePageChange() {
    this.loadAllData();
  }

  handleFilterChange() {
    this.refs.paginator.reset();
    this.loadAllData();
  }

  handleDetailsClick(itemIndex, e) {
    e.preventDefault();
    this.setState({itemDetailsIndex: itemIndex});
    this.refs.modal.showModal();
  }

  render() {
    return (
      <React.Fragment>
        <AuditLogButtonBar ref="buttonBar" choices={this.props.choices} onFilterChange={this.handleFilterChange} />
        <AuditLogList
          hasLoaded={this.state.hasLoaded}
          onDetailsClick={this.handleDetailsClick}
          items={this.state.items}
        />
        <AuditLogDetailsModal ref="modal" item={this.state.items[this.state.itemDetailsIndex] || {}} />
        <Paginator ref="paginator" totalRecords={this.state.total} onPageChange={this.handlePageChange} />
      </React.Fragment>
    );
  }
}
