import ServiceListPanel from '../../../devices/common/ServiceListPanel';
import React from 'react';

export const ServiceSelectorListPanels = ({
  maxServices,
  onSelectAll,
  onRemoveAll,
  onSelectService,
  onUnselectService,
  serviceOptions,
  unselectedServices,
  selectedServices,
}: {
  unselectedServices: any[];
  serviceOptions: any[];
  maxServices: number;
  onSelectService: (service) => void;
  onSelectAll: (e) => void;
  selectedServices: any[];
  onUnselectService: (service) => void;
  onRemoveAll: (e) => void;
}) => (
  <div className={'row'}>
    <div className="col-lg-6 mb-3">
      <div className="service-selector-dialog">
        <ServiceListPanel
          labelText="Checks Available"
          titleText="Click on checks to add"
          services={unselectedServices}
          hasMoreServices={serviceOptions.length >= maxServices}
          onServiceClick={onSelectService}
        />
        <a href="" role="button" onClick={onSelectAll} className="d-block mt-n3">
          Select All
        </a>
      </div>
    </div>
    <div className="col-lg-6 mb-3">
      <div className="service-selector-dialog">
        <ServiceListPanel
          labelText="Checks Selected"
          titleText="Click on checks to remove"
          services={selectedServices}
          onServiceClick={onUnselectService}
        />
        <div>
          <a href="" role="button" onClick={onRemoveAll} className="d-block float-left mt-n3 mr-3">
            Remove All
          </a>
          <span className="d-block float-right text-muted small mt-n3">
            <em>Max {maxServices} checks</em>
          </span>
        </div>
      </div>
    </div>
  </div>
);
