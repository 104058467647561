'use strict';

import $ from 'jquery';
import moment from 'moment-timezone';
import React from 'react';
import Ajax from '../../jskit/general/Ajax';
import _ from 'underscore';
import Formatter from '../../jskit/general/Formatter';
import Utils from '../../jskit/general/Utils';
import Modal from '../../jskit/react/Modal';
import {PrivateLocation, STATUS_MAP} from './PrivateLocationsTypes';
import MessageBox from '../../js/global/messagebox';

interface RenameFormProps {
  serverName: string;
  serverID: number;
  saveURL: string;
}

interface RenameFormState {
  updatedServerName: string;
}
class PrivateServerRenameForm extends React.Component<RenameFormProps, RenameFormState> {
  constructor(props: RenameFormProps) {
    super(props);

    this.state = {
      updatedServerName: this.props.serverName || '',
    };
  }

  showModal() {
    this.refs.modal.showModal();
  }

  updatePrivateLocation(serverID, newPLName) {
    new Ajax().post({
      url: this.props.saveURL,
      data: {name: newPLName, id: serverID},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.status && data.status === 'ok') {
          window.location.reload();
        }
      }.bind(this),
    });
  }
  render() {
    return (
      <Modal
        ref="modal"
        size="lg"
        title="Rename Private Location:"
        cancelButton={true}
        saveButton={() => {
          this.updatePrivateLocation(this.props.serverID, this.state.updatedServerName);
        }}
      >
        <form ref="form" className="form-spaced">
          Private:{' '}
          <input
            type="text"
            value={this.state.updatedServerName}
            style={{width: '90%'}}
            onChange={(e) => {
              this.setState({updatedServerName: e.target.value});
            }}
          ></input>
        </form>
      </Modal>
    );
  }
}

interface Props {
  hasLoaded: boolean;
  isEditable: boolean;
  privateLocations: PrivateLocation[];
  contactURL: string;
  saveURL: string;
}

class PrivateServer extends React.Component<PrivateLocation> {
  constructor(props: PrivateLocation) {
    super(props);
    Utils.autoBindClass(this, {});
  }

  getLoad(status): string[] {
    if (!status || !status.details) {
      return ['muted', 'unknown'];
    }

    const load = status.details.check_load;
    const loadStatus = Formatter.alertStateCSSClass(load.status);
    const loadAvgMatch = load.description.match(/load average: ([\d., ]+)\|/i);
    const loadAvg = loadAvgMatch ? loadAvgMatch[1] : '-';
    return [loadStatus, loadAvg];
  }

  getLastStatusReportSecsAgo(status) {
    return moment.utc().diff(moment.utc(status.sent_at), 'seconds');
  }

  getGlobalStatus(status, lastStatusSecsAgo) {
    if (!status || !status.details || lastStatusSecsAgo > 30 * 60) {
      return {
        isRunning: false,
        statusClass: 'status-paused',
        statusTitle: 'This private location server is not communicating with Uptime.com.',
        warningCount: 0,
        errorCount: 0,
        warnings: {},
        errors: {},
      };
    }

    const checkKeys = Object.keys(STATUS_MAP);
    const checksKeysInWarningState = checkKeys.filter(
      (k) => STATUS_MAP[k].severity === 'WARNING' && status.details[k] && status.details[k].status === 'ERROR'
    );
    const checkKeysInErrorState = checkKeys.filter(
      (k) => STATUS_MAP[k].severity === 'ERROR' && status.details[k] && status.details[k].status === 'ERROR'
    );
    const warnings = _.pick(STATUS_MAP, checksKeysInWarningState);
    const errors = _.pick(STATUS_MAP, checkKeysInErrorState);

    const result = {
      isRunning: true,
      statusClass: '',
      statusTitle: '',
      warningCount: checksKeysInWarningState.length,
      errorCount: checkKeysInErrorState.length,
      warnings: warnings,
      errors: errors,
    };

    if (result.errorCount > 0) {
      result.statusClass = 'status-danger';
      result.statusTitle = 'One or more errors were detected with this private location server.';
    } else if (result.warningCount > 0) {
      result.statusClass = 'status-warning';
      result.statusTitle = 'One or more warnings were detected with this private location server.';
    } else {
      result.statusClass = 'status-success';
      result.statusTitle = 'This private location server is operating correctly.';
    }

    return result;
  }

  handleShowExtendedStatus(e) {
    e.preventDefault();

    const status = this.props.status;
    const lastStatusSecsAgo = this.getLastStatusReportSecsAgo(status);
    const globalStatus = this.getGlobalStatus(status, lastStatusSecsAgo);
    let message = `
      <div>
        <strong>API Token: </strong>
        <code>${this.props.api_token || '&lt;requires admin access to view&gt;'}</code>
      </div>
      <div class="mt-3">${globalStatus.statusTitle}</div>
    `;

    if (globalStatus.errorCount) {
      const errs = Object.values(globalStatus.errors).map(
        (err: Record<string, string>) => `<li>${err.description}</li>`
      );
      message += `
        <h4 class="text-danger mt-3">Errors:</h3>
        <ul class="list-spacer-1">
          ${errs.join('\n')}
        </ul>
      `;
    }

    if (globalStatus.warningCount) {
      const warnings = Object.values(globalStatus.warnings).map(
        (warn: Record<string, string>) => `<li>${warn.description}</li>`
      );
      message += `
        <h4 class="text-warning mt-3">Warnings:</h3>
        <ul class="list-spacer-1">
          ${warnings.join('\n')}
        </ul>
      `;
    }

    MessageBox.messageBox({
      largeModal: true,
      title: `Extended Status: ${this.props.name}`,
      htmlMessage: message,
    });
  }

  render() {
    const server = this.props;
    const status = server.status;

    const [loadStatus, loadAvg] = this.getLoad(status);
    const lastStatusSecsAgo = this.getLastStatusReportSecsAgo(status);
    const globalStatus = this.getGlobalStatus(status, lastStatusSecsAgo);

    const statusHTML = [];
    if (!globalStatus.isRunning) {
      statusHTML.push(
        <span key="paused" className="d-block text-muted">
          NOT REPORTING
        </span>
      );
    } else if (globalStatus.errorCount > 0 || globalStatus.warningCount > 0) {
      if (globalStatus.errorCount > 0) {
        statusHTML.push(
          <span key="error" className="d-block text-danger">
            {globalStatus.errorCount} {Formatter.pluralize(globalStatus.errorCount, 'ERROR', 'ERRORS')}
          </span>
        );
      }
      if (globalStatus.warningCount > 0) {
        statusHTML.push(
          <span key="warning" className="d-block text-warning">
            {globalStatus.warningCount} {Formatter.pluralize(globalStatus.warningCount, 'WARNING', 'WARNINGS')}
          </span>
        );
      }
    } else {
      statusHTML.push(
        <span key="success" className="d-block text-success">
          OK
        </span>
      );
    }

    return (
      <tr>
        <td>
          <i className={'status ' + globalStatus.statusClass} title={globalStatus.statusTitle} />
          <a href="#" onClick={this.handleShowExtendedStatus} className="d-inline-block unstyled font-weight-semibold">
            Private: {server.name}
          </a>
          <br />
          <a href="#" onClick={this.handleShowExtendedStatus} className="d-inline-block unstyled text-muted mb-0">
            {server.location}
          </a>
        </td>
        <td>{server.version}</td>
        <td>{server.num_services}</td>
        <td className={'text-' + loadStatus}>{loadAvg}</td>
        <td>
          {lastStatusSecsAgo ? (
            <span data-toggle="tooltip" className="tooltip-indicator" title={Formatter.shortDateTime(status.sent_at)}>
              {Formatter.shortDuration(lastStatusSecsAgo)} ago
            </span>
          ) : (
            <span className="text-muted">none recorded</span>
          )}
        </td>
        <td>
          <a href="#" className="d-inline-block" onClick={this.handleShowExtendedStatus}>
            {statusHTML}
          </a>
        </td>
        {this.props.editable ? (
          <td
            data-toggle="tooltip"
            data-html="false"
            tabIndex={0}
            className="tooltip-indicator"
            title={'Rename Private Location'}
          >
            <PrivateServerRenameForm
              ref="renameModal"
              saveURL={this.props.saveURL}
              serverName={server.name}
              serverID={server.id}
            />
            <a href="#">
              <i
                className="icon fas fa-edit text-dark"
                style={{fontSize: '14px'}}
                onClick={(e) => {
                  e.preventDefault();
                  this.refs.renameModal.showModal();
                }}
              ></i>
            </a>
          </td>
        ) : (
          ''
        )}
      </tr>
    );
  }
}

export default class PrivateLocationsList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    Utils.autoBindClass(this, {});
  }

  componentDidUpdate() {
    $('#private-locations-list [data-toggle=tooltip]').tooltip('enable');
  }

  renderEmptyState() {
    return (
      <div className="mb-4" style={{borderRadius: '4px', border: '2px solid #CED4DA'}}>
        <div className="d-flex justify-content-between px-5 py-3" style={{borderBottom: '2px solid #CED4DA'}}>
          <a href="" className="text-brand-black" style={{fontWeight: '700', fontSize: '18px'}}>
            Server & Location
          </a>
          <ul className="list-inline mb-0">
            <li className="list-inline-item" style={{marginRight: '4rem'}}>
              <a href="" className="text-brand-black" style={{fontWeight: '700', fontSize: '18px'}}>
                Version
              </a>
            </li>
            <li className="list-inline-item" style={{marginRight: '4rem'}}>
              <a href="" className="text-brand-black" style={{fontWeight: '700', fontSize: '18px'}}>
                Checks
              </a>
            </li>
            <li className="list-inline-item" style={{marginRight: '4rem'}}>
              <a href="" className="text-brand-black" style={{fontWeight: '700', fontSize: '18px'}}>
                Load Avg
              </a>
            </li>
            <li className="list-inline-item">
              <a href="" className="text-brand-black" style={{fontWeight: '700', fontSize: '18px'}}>
                Last Status Report
              </a>
            </li>
          </ul>
          <a href="" className="text-brand-black" style={{fontWeight: '700', fontSize: '18px'}}>
            Status
          </a>
        </div>
        <div>
          <div className="text-center p-4">
            <img src="/static/img/global/map.png" alt="map" className="mb-3" />
            <p style={{fontWeight: '600', fontSize: '20px'}}>
              You have not deployed Private Location servers in your account
              <span className="d-block">or you are not subscribed to this feature.</span>
            </p>
            <p>
              Please contact <a href="mailto:support@uptime.com">support@uptime.com</a> to inquire about subscribing to
              Private Locations.
            </p>
            <button
              className="btn btn-success"
              onClick={() => {
                window.location.href = `${this.props.contactURL}?subject=User request for Private Locations`;
              }}
            >
              Contact Support
            </button>{' '}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.privateLocations.length === 0) {
      return this.renderEmptyState();
    }

    const nodes = this.props.privateLocations.map((server) => {
      return (
        <PrivateServer {...server} key={server.id} editable={this.props.isEditable} saveURL={this.props.saveURL} />
      );
    });

    return (
      <div id="private-locations-list" className="white-block white-block-border mb-4">
        <table className="table table-responsive-lg data-table data-table-with-status mb-0" role="grid">
          <thead>
            <tr>
              <th>Server &amp; Location</th>
              <th>Version</th>
              <th>Checks</th>
              <th>Load Avg</th>
              <th>Last Status Report</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }
}
