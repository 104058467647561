'use strict';

import React from 'react';
import TextInput from '../../../jskit/react/forms/TextInput';
import Select2 from '../../../jskit/react/forms/Select2';
import {unpackFormLinkOrProps, CustomEvent} from './FormHelpers';
import {Label} from './FormHelpers';
import Formatter from '../../../jskit/general/Formatter';

const HOUR = 60;
const DAY = 24 * 60;

const CHOICES_MULTIPLIERS = [
  ['MINUTES', 'Minutes', 1],
  ['HOURS', 'Hours', HOUR],
  ['DAYS', 'Days', DAY],
];

export default class SplitInterval extends React.Component {
  constructor(props) {
    super(props);
    const [interval, intervalType] = this.getIntervalValueAndType();
    this.state = {
      interval,
      intervalType,
      formErrors: null,
    };
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // we set zero timeout here to break an async chain that results in a race condition
    setTimeout(() => {
      const fieldProps = unpackFormLinkOrProps(this.props);
      const totalMinutes = this.calculateTotalMinutes(this.state.interval, this.state.intervalType);
      if (fieldProps.value !== totalMinutes) {
        const [interval, intervalType] = this.getIntervalValueAndType();
        this.setState({interval, intervalType});
      }
    }, 0);
  }

  getIntervalValueAndType() {
    const fieldProps = unpackFormLinkOrProps(this.props);
    // Return displayable interval and interval type based on raw form value from props
    const totalMinutes = parseInt(fieldProps.value) || this.props.defaultValue;
    if (totalMinutes >= DAY && totalMinutes % DAY === 0) {
      return [totalMinutes / DAY, 'DAYS'];
    }
    if (totalMinutes >= HOUR && totalMinutes % HOUR === 0) {
      return [totalMinutes / HOUR, 'HOURS'];
    }
    return [totalMinutes, 'MINUTES'];
  }

  calculateTotalMinutes(interval, intervalType) {
    const choiceMultiplierOption = this.props.choicesMultipliers.find(function (el) {
      return el[0] === intervalType;
    });
    const choiceMultiplier = choiceMultiplierOption[2];
    // if totalMinutes resolves to NaN - pass interval to backend verbatim to validate
    const totalMinutes = interval * choiceMultiplier || interval;
    return totalMinutes;
  }

  updateTotalMinutes(interval, intervalType) {
    const form = unpackFormLinkOrProps(this.props);
    const totalMinutes = this.calculateTotalMinutes(interval, intervalType);
    form.onChange(new CustomEvent({type: 'change', target: this, value: totalMinutes}));
    return totalMinutes;
  }

  handleTypeChange(e) {
    const intervalType = e.target.value;
    const {interval} = this.state;
    this.setState({intervalType});
    const totalMinutes = this.updateTotalMinutes(interval, intervalType);
    this.validateInput(totalMinutes);
  }

  handleValueChange(e) {
    const interval = e.target.value;
    const {intervalType} = this.state;
    this.setState({interval});
    const totalMinutes = this.updateTotalMinutes(interval, intervalType);
    this.validateInput(totalMinutes);
  }

  validateInput(val) {
    if (val >= this.props.minValue) {
      this.setState({formErrors: null});
    } else {
      this.setState({formErrors: 'Interval must be greater than ' + Formatter.humanInterval(this.props.minValue)});
    }
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);
    // we can't use ES6 property assignment, e.g. `{fieldName, formLink, ...extraProps} = this.props`
    // here, so just delete unneeded props before passing this through to TextInput
    const extraProps = Object.assign({}, this.props);
    delete extraProps.formLink;
    delete extraProps.labelText;
    extraProps.formErrors = {[this.props.fieldName]: form.errors || this.state.formErrors};

    return (
      <React.Fragment>
        <div className="col-sm-6 form-group">
          <Label labelText={this.props.labelText} isRequired={true} />
          <div className="row">
            <div className="col-sm-8 pr-1">
              <TextInput value={this.state.interval} onChange={this.handleValueChange} {...extraProps} />
            </div>
            <div className="col-sm-4 pl-1">
              <Select2
                choices={this.props.choicesMultipliers}
                value={this.state.intervalType}
                onChange={this.handleTypeChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SplitInterval.defaultProps = {
  choicesMultipliers: CHOICES_MULTIPLIERS, // List of [choiceValue, choiceName, choiceMultiplier]
  defaultValue: null, // Default value
  minValue: null, // Minimum value
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  helpText: null, // Help text beneath the input field
  placeholder: null, // Text input placeholder text
  isRequired: false, // Field is required?
  readOnly: false, // Field is read-only?
  disabled: false, // Control is disabled?
  inputType: 'text', // Type of input
  maxLength: null, // HTML maxlength for this field
  addonLabel: null, // Label for an addon button at the end of the field
  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
};
