'use strict';

import $ from 'jquery';
import Ajax from '../../jskit/general/Ajax';
import MessageBox from './messagebox';
import intlTelInput from 'intl-tel-input';

/**
 * Setup bootstrap tooltips
 *
 * Selector: <div data-toggle="tooltip"/>
 */
function setupTooltips() {
  $(document).ready(function () {
    const tooltips = $('[data-toggle="tooltip"]');
    // ignored on admin
    if (tooltips.tooltip === undefined) {
      console.warn('tooltip undefined');
      return;
    }

    tooltips.tooltip();
    $('[data-toggle-secondary="tooltip"]').tooltip();
  });
}

/**
 * Ajax busy cursor
 */
function setupAjax() {
  Ajax.setDefaultSettings({
    useAutomaticWaitCursor: true,
    globalErrorHandler: function (errorThrown, textStatus, jqXHR) {
      if (jqXHR.status === 429) {
        MessageBox.alertBox(
          'The rate limit for this feature has been exceeded, please try again later.',
          'Rate Limit Exceeded'
        );
      } else {
        var alertTitle = errorThrown;
        if (jqXHR.statusText === '' || jqXHR.statusText === 'error') {
          if (jqXHR.status === 400) {
            alertTitle = 'Bad Request';
          } else if (jqXHR.status === 404) {
            alertTitle = 'Not Found';
          } else if (jqXHR.status === 500) {
            alertTitle = 'Server Error';
          } else {
            alertTitle = 'Unknown Error';
          }
        }
        MessageBox.alertBox('An error occurred - please reload the page and try again.', alertTitle);
      }
    },
  });
}

/**
 * Select2 select boxes.
 *
 * Selector: <select data-use-select2/>
 */
function setupSelect2() {
  // not plugged in on admin page
  if ($.fn.select2 === undefined) {
    console.warn('select2 undefined');
    return;
  }
  // Patch Select2 to completely remove current selection on backspace
  const Search = $.fn.select2.amd.require('select2/selection/search');
  const oldRemoveChoice = Search.prototype.searchRemoveChoice;
  Search.prototype.searchRemoveChoice = function () {
    oldRemoveChoice.apply(this, arguments);
    this.$search.val('');
  };

  // Configure Select2 defaults
  $('[data-use-select2]').select2({
    minimumResultsForSearch: 10,
  });
}

/**
 * IntlTelInput text fields for phone numbers.
 *
 * Selector: <input data-intl-tel/>
 */
export function setupIntlTelInput() {
  $('[data-intl-tel]').each(function () {
    var elem = $(this);
    var name = elem.attr('name');
    elem.attr('type', 'tel');
    elem.attr('name', null);

    intlTelInput(elem[0], {
      utilsScript: '/static/js/intl-tel-input/utils.js',
      geoIpLookup: function (callback) {
        $.get(
          'https://ipinfo.io',
          function () {
            return null;
          },
          'jsonp'
        ).always(function (resp) {
          var countryCode = resp && resp.country ? resp.country : 'us';
          callback(countryCode);
        });
      },
      initialCountry: 'auto',
      nationalMode: true,
      numberType: 'MOBILE',
      preferredCountries: ['us'],
      onlyCountries: [],
      excludeCountries: [],
      hiddenInput: name,
    });
  });
}

/**
 * Setups up animated scroll to the specified element.
 *
 * Selector: <a data-scroll="<selector>"/>
 */
function setupAnimatedScroll() {
  $('[data-scroll]').on('click', function (e) {
    e.preventDefault();
    var target = $($(this).data('scroll'));
    $('html, body').animate({scrollTop: target.offset().top});
  });
}

/**
 * Fixes behaviour when there are 2 modals (e.g. parent modal and a message box),
 * and after closing the child modal parent modal is no longer scrollable. See
 * https://stackoverflow.com/questions/28077066/bootstrap-modal-issue-scrolling-gets-disabled
 *
 */
function setupModalInModal() {
  $('body').on('hidden.bs.modal', function () {
    if ($('.modal.show').length > 0) {
      $('body').addClass('modal-open');
    }
  });
}

function mobileNavToggle() {
  $('.menu-toggle').click(function () {
    const chevron = $(this).children('i.chevron-toggle');
    if (chevron.hasClass('fa-chevron-down')) {
      chevron.removeClass('fa-chevron-down');
      chevron.addClass('fa-chevron-up');
    } else {
      chevron.removeClass('fa-chevron-up');
      chevron.addClass('fa-chevron-down');
    }
    var toggleableId = $(this).attr('data-toggle');
    if ($(toggleableId).hasClass('d-none')) {
      $(toggleableId).removeClass('d-none');
    } else {
      $(toggleableId).addClass('d-none');
    }
  });
}

/**
 * Initialize everything
 */
const initAll = () => {
  setupTooltips();
  setupAjax();
  setupSelect2();
  setupIntlTelInput();
  setupAnimatedScroll();
  setupModalInModal();
  mobileNavToggle();
};

export default initAll;
