'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText, CustomEvent} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';
import Utils from '../../../jskit/general/Utils';

export default class PercentageInput extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
    Utils.autoBindClass(this);

    const form = unpackFormLinkOrProps(this.props);
    this.state = {
      userValue: this._calculateUserValue(form.value),
    };
  }

  _calculateUserValue(formValue) {
    const userValue = formValue * 100.0;
    if (isNaN(userValue) || userValue === null) {
      return formValue;
    } else {
      return userValue.toFixed(2);
    }
  }

  _calculateFormValue(userValue) {
    if (userValue === '') {
      return '';
    }

    const formValue = userValue / 100.0;
    if (isNaN(formValue) || formValue === null) {
      return userValue;
    } else {
      return formValue.toFixed(4);
    }
  }

  _formValuesAreEqual(a, b) {
    a = parseFloat(a);
    b = parseFloat(b);
    return (isNaN(a) && isNaN(b)) || a == b;
  }

  componentDidUpdate(prevProps, prevState) {
    // we set zero timeout here to break an async chain that results in a race condition
    setTimeout(() => {
      const form = unpackFormLinkOrProps(this.props);
      const formValue = this._calculateFormValue(this.state.userValue);
      if (!this._formValuesAreEqual(form.value, formValue)) {
        const userValue = this._calculateUserValue(form.value);
        this.setState({userValue: userValue});
      }
    }, 0);
  }

  handleChange(e) {
    const form = unpackFormLinkOrProps(this.props);

    this.setState({userValue: e.target.value});

    const formValue = this._calculateFormValue(e.target.value);
    form.onChange(new CustomEvent({type: 'change', target: this, value: formValue}));
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className="form-group">
        <Label fieldId={this.htmlId} {...this.props} wizard={undefined} />
        <input
          ref="textInput"
          type="text"
          data-wizard={this.props.wizard}
          id={this.htmlId}
          name={form.fieldName}
          className={ReactUtils.cssClass('form-control', {['is-invalid']: form.errors})}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          placeholder={this.props.placeholder}
          value={this.state.userValue}
          onChange={this.handleChange}
        />
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

PercentageInput.defaultProps = {
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  placeholder: null, // Placeholder text
  isRequired: false, // Field is required?
  readOnly: false, // Field is read-only?
  disabled: false, // Control is disabled?
  wizard: undefined, // Set a data-wizard attribute on the text field

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
};
