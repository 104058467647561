'use strict';

import React from 'react';
import ReactUtils from '../../../jskit/react/ReactUtils';
import classnames from 'classnames';

import $ from 'jquery';

/**
 * Returns a unique HTML ID.
 * Call and store this in the component constructor.
 */
let uniqueHtmlIdCounter = 0;
export function uniqueHtmlId(prefix) {
  uniqueHtmlIdCounter++;
  prefix = prefix || 'react-id';
  return prefix + '--' + uniqueHtmlIdCounter + '--';
}

/**
 * Creates an object with get() and set() methods used
 * to link form state from a child component to a parent.
 *
 * @param formComponent A reference to the React component that form state is
 *     saved on.
 * @param stateKey The string key in the component state where form data is stored.
 * @param formErrors Optional prop containing a dict with form errors.
 */
export function prepareFormLink(formComponent, stateKey, formErrors) {
  const get = (fieldKey) => formComponent.state[stateKey][fieldKey];
  const set = (fieldKey, nextHandler, e) => {
    if (!e) {
      e = nextHandler;
      nextHandler = null;
    }

    let newValue;
    switch (e.target.type) {
      case 'checkbox':
        newValue = e.target.checked;
        break;

      case 'file':
        newValue = e.target.files.length > 0 ? e.target.files[0] : null;
        break;

      default:
        newValue = e.target.value;
        break;
    }

    const oldState = formComponent.state[stateKey];
    const newState = Object.assign({}, oldState, {[fieldKey]: newValue});
    formComponent.setState({[stateKey]: newState});

    if (nextHandler) {
      nextHandler(e, newValue);
    }
  };

  const errors = (fieldKey) => (formErrors ? formErrors[fieldKey] : null);

  const forField = (fieldKey) => ({
    get: get.bind(null, fieldKey),
    set: set.bind(null, fieldKey),
    errors: errors.bind(null, fieldKey),
  });
  return {
    get: get,
    set: set,
    errors: errors,
    forField: forField,
  };
}

/**
 * Assuming standard prop names fieldName, formLink, formErrors, value, onChange, returns
 * an object containing those keys after deciding whether to use
 * the formLink or standalone values.
 */
export function unpackFormLinkOrProps(props) {
  const fieldName = props.fieldName;
  const formLink = props.formLink ? props.formLink.forField(fieldName) : null;

  let value = formLink ? formLink.get() : props.value;
  if (value === undefined || value === null) {
    value = '';
  }

  return {
    fieldName: fieldName,
    formLink: formLink,
    value: value,
    onChange: formLink ? formLink.set.bind(null, props.onChange) : props.onChange,
    errors: (formLink && formLink.errors()) || (props.formErrors && props.formErrors[fieldName]) || null,
  };
}

/**
 * Substitute for React's SyntheticEvent for custom form components.
 */
export class CustomEvent {
  constructor({type = 'custom', target = null, value = undefined}) {
    this.bubbles = false;
    this.cancelable = false;
    this.currentTarget = target;
    this.defaultPrevented = true;
    this.eventPhase = 0;
    this.nativeEvent = null;
    this.preventDefault = () => null;
    this.isDefaultPrevented = () => true;
    this.stopPropagation = () => null;
    this.isPropagationStopped = () => true;
    this.target = target;
    this.timeStamp = Date.now();
    this.type = type;

    if (value !== undefined) {
      this.target.value = value;
    }
  }
}

/**
 * Checks if a value contains HTML code.
 */
function valueContainsHTML(v) {
  return v.indexOf('</a>') !== -1 || v.indexOf('</strong>') !== -1 || v.indexOf('</em>') !== -1;
}

/**
 * Create a bootstrap global form errors component.
 * Accepts `errors` prop.
 */
export function FormErrors(props) {
  if (!props.errors || $.isEmptyObject(props.errors)) {
    return null;
  }

  const errs = props.errors.map((v) => {
    if (valueContainsHTML(v)) {
      return <li key={v} dangerouslySetInnerHTML={{__html: v}} />;
    } else {
      return <li key={v}>{v}</li>;
    }
  });

  return (
    <div className="alert alert-block alert-danger">
      <ul className="list-unstyled list-spacer-1 mb-0">{errs}</ul>
    </div>
  );
}

/**
 * Tooltip beside form label. Accepts `titleText` prop.
 */
export class HelpIcon extends React.PureComponent {
  componentDidMount() {
    $(this.refs.tooltip).tooltip();
  }

  componentWillUnmount() {
    $(this.refs.tooltip).tooltip('dispose');
  }

  componentDidUpdate(prevProps) {
    if (this.props.titleText !== prevProps.titleText) {
      $(this.refs.tooltip).tooltip('dispose');
      $(this.refs.tooltip).tooltip();
    }
  }

  render() {
    if (!this.props.titleText) {
      return null;
    }

    return (
      <i
        ref="tooltip"
        title={this.props.titleText}
        className={classnames('fas fa-info-circle ml-2', this.props.className)}
      />
    );
  }
}

/**
 * Error text beneath form element. Accepts `errors` prop.
 */
export function FieldErrors(props) {
  if (!props.errors || $.isEmptyObject(props.errors)) {
    return null;
  }

  const errors = props.errors.join ? props.errors.join(' ') : props.errors;

  if (valueContainsHTML(errors)) {
    return (
      <span className="invalid-feedback d-block">
        <strong dangerouslySetInnerHTML={{__html: errors}} />
      </span>
    );
  } else {
    return (
      <span className="invalid-feedback d-block">
        <strong>{errors}</strong>
      </span>
    );
  }
}

/**
 * Error text beneath form element. Accepts `helpText` prop.
 */
export function FieldHelpText(props) {
  if (!props.helpText) {
    return null;
  }

  if (!React.isValidElement(props.helpText) && valueContainsHTML(props.helpText)) {
    return <small className="form-text text-muted" dangerouslySetInnerHTML={{__html: props.helpText}} />;
  } else {
    return <small className="form-text text-muted">{props.helpText}</small>;
  }
}

/**
 * Combined HTML label & tooltip if relevant.
 * Accepts `baseCSSClass`, `fieldId`, `isRequired`, labelText`, `titleText` and `wizard` props.
 */
export function Label(props) {
  const baseCSSClass = props.baseCSSClass || 'form-control-label';

  if (!props.labelText && baseCSSClass.indexOf('custom-control') === -1) {
    return null;
  }

  return (
    <label
      data-wizard={props.wizard}
      htmlFor={props.fieldId}
      className={ReactUtils.cssClass(baseCSSClass, {requiredField: props.isRequired})}
    >
      {props.labelText}
      {props.isRequired ? <span className="ml-2 text-danger">*</span> : null}
      <HelpIcon titleText={props.titleText} />
    </label>
  );
}
