'use strict';

import _ from 'underscore';
import Formatter from '../jskit/general/Formatter';
import ReactUtils from '../jskit/react/ReactUtils';

const TransactionUtils = {};
export default TransactionUtils;

TransactionUtils.formatStepDescription = function (stepDef, short) {
  return _.reduce(
    stepDef.fields,
    function (string, field) {
      var regex = new RegExp('\\{' + field.name + '\\}', 'g');
      var val = (stepDef.values[field.name] || field.name).toString();

      if (val !== field.name && TransactionUtils.isSecretTextField(stepDef, field)) {
        val = '*'.repeat(val.length);
      }
      if (short && field.type === 'SELECTOR') {
        //try to shorten value to single tag name
        try {
          const last = val.split(' ').slice(-1)[0].trim();
          if (last) {
            const parts = last.split('.');
            const tag = parts[0];
            let cssClass = '';
            if (parts.length > 1) {
              cssClass = parts[1].split(':')[0];
            }
            if (tag.indexOf('#') > -1) {
              val = '… ' + tag;
            } else if (cssClass !== '') {
              val = '… ' + tag + '.' + cssClass;
            }
          }
        } catch (e) {
          return undefined;
        }
      }

      return string.replace(
        regex,
        '<span class="user-value">' + ReactUtils.escapeHTML(val).replace(/\$/g, '$$$$') + '</span>'
      );
    },
    ReactUtils.escapeHTML(stepDef.template)
  );
};

TransactionUtils.formatStepDetails = function (stepDetails) {
  if (!stepDetails) {
    return null;
  }

  const details = _.mapObject(stepDetails, (val, key) => {
    if (_.isObject(val)) {
      val = _.map(val, function (v, k) {
        return k + ': ' + v;
      });
    }

    if (_.isArray(val)) {
      if (val.length) {
        val = _.reduce(
          val,
          function (memo, v) {
            return (memo += `<span>${v}</span><br/>`);
          },
          ''
        );
      } else {
        val = null;
      }
    }

    if (key === 'response_body') {
      //escape html and special chars in response body
      val = val.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }

    return `<dt><strong>${TransactionUtils.formatDetailsHeading(key)}</strong></dt><dd>${val || '<none>'}</dd>`;
  });

  let mergedDetails = '';
  for (const key in details) {
    mergedDetails += details[key];
  }
  return mergedDetails;
};

TransactionUtils.formatDetailsHeading = function (keyName) {
  keyName = keyName.replace(/_/g, ' ');
  return keyName;
};

TransactionUtils.getFirstSentence = function (text) {
  return text.split('.')[0] + '.';
};

TransactionUtils._SECRET_REGEX = /API|TOKEN|KEY|SECRET|PASS|SIGNATURE|PW/i;
TransactionUtils.isSecretTextField = function (step, field) {
  if (
    step.step_def === 'C_FILL_FIELD' &&
    field.name === 'text' &&
    TransactionUtils._SECRET_REGEX.test(step.values.element)
  ) {
    return true;
  }

  return false;
};

TransactionUtils.createInfoWindow = function (html, title) {
  var win = window.open('/transaction/info.html', '_blank');
  win.addEventListener(
    'load',
    () => {
      if (title !== undefined) {
        var heading = win.document.getElementById('title');
        heading.innerHTML = title;
      }
      var container = win.document.getElementById('container');
      container.innerHTML = html;
      const script = win.document.createElement('script');
      script.innerHTML = '$(\'[data-toggle="popover"]\').popover();';
      const body = win.document.getElementsByTagName('body')[0];
      body.appendChild(script);
    },
    false
  );
};

TransactionUtils.createEmptyStep = function (step_key) {
  return {
    step_def: step_key || '',
    values: {},
    errors: {},
    results: {
      response_time: -1,
      error: null,
    },
  };
};

TransactionUtils.mergeStepDefsIntoScript = function (transactionScript, stepDefsMap) {
  return _.map(transactionScript, function (step) {
    return _.extend(TransactionUtils.createEmptyStep(), step, stepDefsMap[step.step_def]);
  });
};

TransactionUtils.renderWaterfallHTML = function (requests) {
  if (!requests || requests.length === 0) {
    return null;
  }
  const T0 = requests[0].timing.requestWillBeSent,
    request_count = requests.length;

  // calculate waterfall timings adjusting 0 time to first request and converting everything to milliseconds
  var waterfall = requests.map((r) => {
    if (r.timing.loadingFinished !== null) {
      const start = r.timing.requestWillBeSent - T0,
        requestEnd = start + r.timing.sendEnd,
        responseStart = start + r.timing.receiveHeadersEnd,
        responseEnd = r.fromCache ? responseStart : r.timing.loadingFinished - T0;
      return {
        //escape html and special chars in url
        url: r.url.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;'),
        status: r.status,
        resourceType: r.resourceType,
        size: r.size,
        // absolute values
        requestStart: start,
        requestEnd: requestEnd,
        responseStart: responseStart,
        responseEnd: responseEnd,
        // durations
        sendingRequest: r.timing.sendEnd,
        dns: r.timing.dnsEnd - r.timing.dnsStart,
        connect: r.timing.connectEnd - r.timing.connectStart,
        connectSecureConnection: r.timing.sslEnd - r.timing.sslStart,
        requestSending: r.timing.sendEnd - r.timing.sendStart,
        waitingResponse: responseStart - requestEnd,
        downloadingData: responseEnd - responseStart,
        duration: responseEnd - start,
      };
    } else {
      return {
        url: r.url.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;'),
        status: null,
        resourceType: null,
        size: null,
        requestStart: r.timing.requestWillBeSent - T0,
        requestEnd: null,
        responseStart: null,
        responseEnd: null,
        sendingRequest: null,
        dns: null,
        connect: null,
        connectSecureConnection: null,
        requestSending: null,
        waitingResponse: null,
        downloadingData: null,
        duration: null,
      };
    }
  });

  //get latest requests
  var latest = _.max(waterfall, (x) => x.responseEnd);
  const total_duration = latest.responseEnd || 1000, // if not any request finished, set total duration to 1s
    ms_pct = 100 / total_duration;
  const total_size = _.reduce(
    waterfall,
    function (memo, x) {
      return memo + x.size;
    },
    0
  );

  //calculate pixel percent data for display
  waterfall = waterfall.map((wf) => {
    return {
      ...wf,
      pct: {
        requestStart: wf.requestStart * ms_pct,
        // for not finished requests, set
        // duration to 100ms so that they are visible in waterfall
        requestDuration: wf.requestEnd !== null ? (wf.requestEnd - wf.requestStart) * ms_pct : 100 * ms_pct,
        waitStart: wf.requestEnd * ms_pct,
        waitDuration: (wf.responseStart - wf.requestEnd) * ms_pct,
        receiveStart: wf.responseStart * ms_pct,
        receiveDuration: (wf.responseEnd - wf.responseStart) * ms_pct,
      },
    };
  });

  //ticks
  var ticks_num = 7;
  var wf_ticks = [];
  for (let i = 0; i < ticks_num; i++) {
    wf_ticks.push({
      left: (100 / ticks_num) * i,
      val: Formatter.autoDuration((total_duration / 1000 / ticks_num) * i),
    });
  }

  const ticks = wf_ticks.map((t) => `<span class="wf-tick" style="left: ${t.left}%">${t.val}</span>`).join('');

  const url =
    'https://support.uptime.com/hc/en-us/articles/360000984785-Synthetic' +
    '-Monitoring-With-the-Uptime-com-Transaction-Check#data-on-check-failure';
  return `<p><a href="${url}" target="_blank">View Documentation</a></p>
              <div class="requestsWaterfall table-responsive">
                <table class="table listing-table table-valign-middle">
                  <thead>
                    <tr>
                      <th>URL</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Size</th>
                      <th>Duration</th>
                      <th class="wf-tick-cell">${ticks}</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${waterfall.map((item, i) => this.renderWaterfallRow(item, i)).join('\n')}
                  </tbody>
                  <tfoot>
                    <tr class="font-weight-semibold">
                      <td>Total requests: ${request_count}</td>
                      <td></td><td></td>
                      <td>${Formatter.humanBytes(total_size || 0)}</td>
                      <td>${Formatter.ms(total_duration)}</td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
        `;
};

TransactionUtils.renderWaterfallRow = function (wf) {
  const _ms = Formatter.ms;
  const tooltip =
    `<strong>Requesting: ${_ms(wf.sendingRequest)}</strong> (` +
    `DNS: ${_ms(wf.dns)}, ` +
    `Connect: ${_ms(wf.connect)} (SSL: ${_ms(wf.connectSecureConnection)}), ` +
    `Sending request: ${_ms(wf.requestSending)})<br/>` +
    `<strong>Server processing: ${_ms(wf.waitingResponse)}</strong><br/>` +
    `<strong>Downloading response: ${_ms(wf.downloadingData)}</strong>`;

  return `
            <tr>
              <td><span title="${wf.url}">${Formatter.truncate(wf.url, 45)}</span></td>
              <td>${wf.status || 'not completed'}</td>
              <td>${wf.resourceType || 'n/a'}</td>
              <td>${Formatter.humanBytes(wf.size) || 'n/a'}</td>
              <td data-html="true" data-toggle="popover"
              data-trigger="hover" title="Request Timings"
              data-content="${tooltip}">${_ms(wf.duration) || 'n/a'}</td>
              <td class="position-relative">
                <span
                class="wf-request"
                title="Requesting ${_ms(wf.sendingRequest)}"
                data-toggle="tooltip" style="left: ${wf.pct.requestStart}%; width: ${wf.pct.requestDuration}%"></span>
                <span
                class="wf-wait"
                title="Server processing ${_ms(wf.waitingResponse)}"
                data-toggle="tooltip"
                style="left: ${wf.pct.waitStart}%; width: ${wf.pct.waitDuration}%"></span>
                <span
                class="wf-receive"
                title="Downloading response ${_ms(wf.downloadingData)}"
                data-toggle="tooltip"
                style="left: ${wf.pct.receiveStart}%; width: ${wf.pct.receiveDuration}%"></span>
                &nbsp;
              </td>
            </tr>`;
};
