import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import SingleServiceSelectBox from './../common/SingleServiceSelectBox';

export default class BulkGroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        group_id: 'new',
      },
    };
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal() {
    this.refs.modal_group.showModal();
    this.setState({formData: {group_id: 'new'}});
  }

  hideModal() {
    this.refs.modal_group.hideModal();
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const initialChoices = [['new', 'Create New Group']];
    return (
      <Modal ref="modal_group" title="Add checks to group" cancelButton={true} saveButton={this.props.onSave}>
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>
              Note: The selected checks, excluding group checks, will be added to whichever group you create or select
              below.
            </em>
          </p>
          <SingleServiceSelectBox
            ref="serviceSelectBox"
            servicesCheckListURL={this.props.groupChecksListURL}
            ajaxPreload={true}
            minimumResultsForSearch={0}
            ajaxLimit={50}
            fieldName="group_id"
            labelText="Add to Group Check"
            isRequired={true}
            formLink={formLink}
            ajaxInitialChoices={initialChoices}
          />
        </form>
      </Modal>
    );
  }
}
