import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Formatter from '../jskit/general/Formatter';
import Utils from '../jskit/general/Utils';

class LineChart extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      options: {
        chart: {
          animations: {
            enabled: false,
          },
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          redrawOnParentResize: true,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const metric = props.labels[0];
              let tickValue = value;

              if (metric.datatype == 'FLOAT') {
                tickValue = value.toFixed(2);
              }

              if (metric.unit == 'MILLISECOND') {
                tickValue = value.toFixed(0) + ' ms';
              } else if (metric.unit == 'BYTE') {
                tickValue = Formatter.humanBytes(value);
              } else if (metric.unit == 'SCORE') {
                tickValue = (value * 100).toFixed(0) + '%';
              }
              return tickValue;
            },
          },
        },
        tooltip: {
          x: {
            formatter: function (value, timestamp) {
              return Formatter.shortDateTime(new Date(value));
            },
          },
          y: {
            formatter: function (value, info) {
              const metric = props.labels[info.seriesIndex];
              if (!metric) {
                return value.toFixed(2);
              }
              if (metric.datatype == 'FLOAT') {
                value = value.toFixed(2);
              }
              if (metric.unit == 'MILLISECOND') {
                value = value + ' ms';
              } else if (metric.unit == 'BYTE') {
                value = Formatter.humanBytes(value);
              } else if (metric.unit == 'SCORE') {
                value = (value * 100).toFixed(0) + '%';
              }
              return value;
            },
          },
        },
      },
      series: [],
    };
  }

  componentDidMount() {
    this.setState({
      series: this.getChartData(),
    });
  }

  getChartData() {
    const colors = ['#00aa66', '#66a1df', '#0074ce', '#FF6384', '#36A2EB', '#FFCE56'];
    const chartData = Object.keys(this.props.series).map((name, index) => {
      return {
        name: this.props.labels ? this.props.labels[index].label : name,
        data: this.props.series[name].map((data) => ({x: new Date(data[0]), y: data[1]})),
        color: colors[index],
      };
    });
    return chartData;
  }

  render() {
    return (
      <div>
        <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
      </div>
    );
  }
}

export default LineChart;
