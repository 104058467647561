import {PropsWithChildren} from 'react';

export interface PdfReportDownloadButtonProps extends PropsWithChildren {
  historyStartFmt: string;
  historyEndFmt: string;
  pdfUrl: string;
}

export const PdfReportDownloadButton = ({
  historyStartFmt,
  historyEndFmt,
  pdfUrl,
  children,
}: PdfReportDownloadButtonProps) => (
  <div className="dropdown">
    <button
      className="btn btn-primary dropdown-toggle"
      type="button"
      id="history-download-btn"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {children}
    </button>
    <div className="dropdown-menu" aria-labelledby="history-download-btn">
      <a
        className="dropdown-item"
        target="_blank"
        href={pdfUrl + '?start=' + historyStartFmt + '&end=' + historyEndFmt}
      >
        Download PDF
      </a>
    </div>
  </div>
);
