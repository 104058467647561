import FormRow from './FormRow';
import React from 'react';
import Formatter from '../../../jskit/general/Formatter';

const getWarnMessage = (currentPlan, nextPlan) => {
  if (currentPlan === nextPlan) {
    return null;
  }

  return `Unfortunately, you have reached the maximum purchase limit on \
your current ${Formatter.capitalizeFirstLetter(currentPlan)} \
subscription plan. <br/>To continue to expand the limits on your account, please upgrade to your \
${nextPlan} plan, which offers even higher limits and greater features & functionality.`;
};

const ManageAddOnItem = ({
  formLink,
  usedValues,
  includedValues,
  unitsPrices,
  bundleSizes,
  onValuesChange,
  item,
  limit,
  currentPlan,
  nextPlan,
}) => {
  const used = usedValues[item.field] || 0;
  const included = includedValues[item.field];
  if (item.field !== 'limit_private_locations' && (limit === 0 || included === 0 || !item.field)) {
    return null;
  }
  if (item.field === 'limit_private_locations' && ['STARTER', ''].includes(currentPlan)) {
    item.readOnly = true;
    item.subtitle = item.subtitle_alt;
  }
  const minValue = item.soft_consumption_limit ? 0 : Math.max(0, used - included);
  const bundleSize = bundleSizes[item.field];

  return (
    <FormRow
      key={`row-${item.title}`}
      warnMessage={getWarnMessage(currentPlan, nextPlan)}
      item={{
        title: item.title,
        subtitle: item.subtitle,
        field: item.field,
        readOnly: item.readOnly,
        bundleSize: bundleSize,
        used: used,
        included: included,
        min: minValue,
        max: limit - included,
        value: formLink.get(item.field) || 0,
        unitPrice: parseFloat(unitsPrices[item.field]),
      }}
      formLink={formLink}
      onValuesChange={onValuesChange}
    />
  );
};

export default ManageAddOnItem;
