export const STARTER = 'STARTER';
export const ESSENTIAL = 'ESSENTIAL';
export const PREMIUM = 'PREMIUM';
export const CUSTOM = 'CUSTOM';
export const PAGESPEEDCHECKS = 'limit_pagespeed_checks';

export enum MonitoringGroupLevel {
  LEVEL_0 = 'LEVEL_0',
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
}

export const MonitoringLevelPriority: Record<string, number> = {
  [MonitoringGroupLevel.LEVEL_0]: 0,
  [MonitoringGroupLevel.LEVEL_1]: 1,
  [MonitoringGroupLevel.LEVEL_2]: 2,
  [MonitoringGroupLevel.LEVEL_3]: 3,
};

export enum SubscriptionFeature {
  LimitBasicChecks = 'limit_basic_checks',
  LimitTransactionChecks = 'limit_transaction_checks',
  LimitApiChecks = 'limit_api_checks',
  LimitPagespeedChecks = 'limit_pagespeed_checks',
  LimitCloudStatusChecks = 'limit_cloudstatus_checks',
  LimitGroupChecks = 'limit_group_checks',
  LimitStatusPages = 'limit_statuspages',
  LimitMultiUsers = 'limit_multi_users',
  LimitMonitoringGroupLevel = 'limit_monitoring_group_level',
  LimitMonitoringGroups = 'limit_monitoring_groups',
  LimitCheckFrequency = 'limit_check_frequency',
  LimitRumChecksRequestsPerMonth = 'limit_rum_checks_requests_per_month',

  LimitPhoneCalls = 'limit_phonecalls',
  LimitSMS = 'limit_sms',
  LimitReports = 'limit_reports',
  LimitCustomChecks = 'limit_custom_checks',
  LimitDashboards = 'limit_dashboards',
  LimitStatusPageSubscriptions = 'limit_statuspage_subscriptions',
  LimitStatusPagesExternalUser = 'limit_statuspages_external_user',
  LimitPrivateLocations = 'limit_private_locations',
}

export const PLAN_SPEC_ORDER = [
  SubscriptionFeature.LimitBasicChecks,
  SubscriptionFeature.LimitTransactionChecks,
  SubscriptionFeature.LimitApiChecks,
  SubscriptionFeature.LimitPagespeedChecks,
  SubscriptionFeature.LimitCloudStatusChecks,
  SubscriptionFeature.LimitGroupChecks,
  SubscriptionFeature.LimitStatusPages,
  SubscriptionFeature.LimitMultiUsers,
  SubscriptionFeature.LimitSMS,
  SubscriptionFeature.LimitPhoneCalls,
  SubscriptionFeature.LimitMonitoringGroupLevel,
  SubscriptionFeature.LimitMonitoringGroups,
  SubscriptionFeature.LimitCheckFrequency,
  SubscriptionFeature.LimitPrivateLocations,
  SubscriptionFeature.LimitRumChecksRequestsPerMonth,
];

export const PLAN_FIELD_TOOLTIPS = {
  [SubscriptionFeature.LimitBasicChecks]: 'Continuously test website, network, and email server performance.',
  [SubscriptionFeature.LimitTransactionChecks]: 'Mimic user actions to test forms and flows like login and checkout.',
  [SubscriptionFeature.LimitTransactionExtChecks]:
    'Facilitates advanced, extended-duration transaction tests for complex applications or interfaces, accommodating ' +
    'slower execution times compared to standard transaction tests.',
  [SubscriptionFeature.LimitApiChecks]: 'Test APIs with multiple HTTP(S) requests, custom headers and tokens.',
  [SubscriptionFeature.LimitPagespeedChecks]: {
    [STARTER]:
      'Test website performance on scheduled intervals. Starter plan limits frequency and number ' +
      'of locations. Upgrade to Essential for additional PageSpeed tests.',
    [ESSENTIAL]: 'Test website performance on scheduled intervals.',
    [PREMIUM]: 'Test website performance on scheduled intervals.',
    [CUSTOM]: 'Test website performance on scheduled intervals.',
  },
  [SubscriptionFeature.LimitCloudStatusChecks]: 'Monitor the status of common cloud services.',
  [SubscriptionFeature.LimitGroupChecks]: 'Organize, monitor, alert, and report on the overall performance ',
  [SubscriptionFeature.LimitStatusPages]: 'Customizable reporting for downtime, alerts, checks, and more.',
  [SubscriptionFeature.LimitMultiUsers]: 'The total number of available platform login seats.',
  [SubscriptionFeature.LimitMonitoringGroupLevel]:
    'To see the whole list of available locations please visit our Probe Servers page inside the product UI.',
  [SubscriptionFeature.LimitMonitoringGroups]: 'The number of probe servers locations configurable per check.',
  [SubscriptionFeature.LimitCheckFrequency]:
    'The minimum check frequency available for checks. Some advanced checks have higher frequency. ' +
    'See our full Feature Comparison below.',
  [SubscriptionFeature.LimitRumChecksRequestsPerMonth]:
    'Monitor site load time, errors, and performance with real user sessions.',
  [SubscriptionFeature.LimitPhoneCalls]: 'Get automated call alert notifications',
  [SubscriptionFeature.LimitSMS]: 'Get text message alert notifications',
  [SubscriptionFeature.LimitReports]:
    'Effortlessly visualize uptime, downtime, outages, and more using our comprehensive Scheduled and SLA Reports',
  [SubscriptionFeature.LimitCustomChecks]: 'Perform complex API checks against REST API endpoints.',
  [SubscriptionFeature.LimitDashboards]: 'Customize for downtime, alerts, and check reporting.',
  [SubscriptionFeature.LimitStatusPageSubscriptions]: 'Number of Email & SMS Subscribers available on StatusPage.',
  [SubscriptionFeature.LimitPrivateLocations]: 'Number of Private Locations available for monitoring.',
};

export const PLAN_FIELD_PLURAL = {
  [SubscriptionFeature.LimitBasicChecks]: 'Basic Checks',
  [SubscriptionFeature.LimitTransactionChecks]: 'Transaction Checks',
  [SubscriptionFeature.LimitApiChecks]: 'API Checks',
  [SubscriptionFeature.LimitPagespeedChecks]: 'Page Speed Checks',
  [SubscriptionFeature.LimitCloudStatusChecks]: 'Cloud Status Checks',
  [SubscriptionFeature.LimitGroupChecks]: 'Group Checks',
  [SubscriptionFeature.LimitStatusPages]: 'Status Pages',
  [SubscriptionFeature.LimitMultiUsers]: 'User Accounts',
  [SubscriptionFeature.LimitMonitoringGroupLevel]: 'Check Locations',
  [SubscriptionFeature.LimitMonitoringGroups]: 'Locations per Check',
  [SubscriptionFeature.LimitCheckFrequency]: 'Minimum Check Frequency',
  [SubscriptionFeature.LimitRumChecksRequestsPerMonth]: 'Real User Monitoring',
  [SubscriptionFeature.LimitPhoneCalls]: 'Monthly Phone Credits',
  [SubscriptionFeature.LimitSMS]: 'Monthly SMS Credits',
  [SubscriptionFeature.LimitReports]: 'Reports',
  [SubscriptionFeature.LimitCustomChecks]: 'Custom Checks',
  [SubscriptionFeature.LimitDashboards]: 'Dashboards',
  [SubscriptionFeature.LimitStatusPageSubscriptions]: 'Status Page Subscribers',
  [SubscriptionFeature.LimitStatusPagesExternalUser]: 'Status Page External Users',
  [SubscriptionFeature.LimitPrivateLocations]: 'Private Locations',
};

export const PLAN_FIELD_SINGULAR = {
  [SubscriptionFeature.LimitBasicChecks]: 'Basic Check',
  [SubscriptionFeature.LimitTransactionChecks]: 'Transaction Check',
  [SubscriptionFeature.LimitApiChecks]: 'API Check',
  [SubscriptionFeature.LimitPagespeedChecks]: 'Page Speed Check',
  [SubscriptionFeature.LimitCloudStatusChecks]: 'Cloud Status Checks',
  [SubscriptionFeature.LimitGroupChecks]: 'Group Check',
  [SubscriptionFeature.LimitStatusPages]: 'Status Page',
  [SubscriptionFeature.LimitMultiUsers]: 'User Account',
  [SubscriptionFeature.LimitMonitoringGroupLevel]: 'Check Location',
  [SubscriptionFeature.LimitMonitoringGroups]: 'Location per Check',
  [SubscriptionFeature.LimitCheckFrequency]: 'Minimum Check Frequency',
  [SubscriptionFeature.LimitRumChecksRequestsPerMonth]: 'Real User Monitoring',
  [SubscriptionFeature.LimitPhoneCalls]: 'Monthly Phone Credit',
  [SubscriptionFeature.LimitSMS]: 'Monthly SMS Credit',
  [SubscriptionFeature.LimitReports]: 'Report',
  [SubscriptionFeature.LimitCustomChecks]: 'Custom Check',
  [SubscriptionFeature.LimitDashboards]: 'Dashboard',
  [SubscriptionFeature.LimitStatusPageSubscriptions]: 'Status Page Subscriber',
  [SubscriptionFeature.LimitStatusPagesExternalUser]: 'Status Page External User',
  [SubscriptionFeature.LimitPrivateLocations]: 'Private Location',
};

export const MKT_PLAN_FIELD_DISPLAY = {
  limit_rum_checks_requests_per_month: 'RUM data points/month',
};

export const PLAN_INTERVAL_CHECKS = [
  'limit_basic_checks',
  'limit_transaction_checks',
  'limit_api_checks',
  'limit_pagespeed_checks',
];

export const CHECKOUT_FIELDS = {
  ...PLAN_FIELD_PLURAL,
  annual_subscription: 'Move to Annual Billing',
};

export const PLAN_FIELD_VALUE = {
  limit_monitoring_group_level: {
    LEVEL_1: '4',
    LEVEL_2: 'Global',
    LEVEL_3: 'Unlimited Global+',
  },
  limit_check_frequency: {
    '10-LOW': '5 Mins',
    '20-MEDIUM': '1 Min',
    '30-HIGH': '1 Min',
  },
  limit_multi_users: {
    1: '1+',
    10000: 'Unlimited',
  },
  limit_phonecalls: {
    500: 'Unlimited',
  },
  limit_sms: {
    1000: 'Unlimited',
  },
};

export const PLAN_FIELD_UNITS = {
  limit_rum_checks_requests_per_month: 'Page-Views',
};

export const ON_DEMAND_FEATURES = [
  'limit_phonecalls',
  'limit_sms',
  'limit_basic_checks',
  'limit_api_checks',
  'limit_transaction_checks',
  'limit_pagespeed_checks',
  'limit_custom_checks',
  'limit_group_checks',
  'limit_rum_checks_requests_per_month',
  'limit_multi_users',
  'limit_statuspages',
  'limit_statuspage_subscriptions',
];
