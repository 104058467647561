import React, {useEffect} from 'react';

export const FormRecaptchaField = ({onChange, sitekey}) => {
  window.initRecaptcha = () => {
    window.grecaptcha.render('g-recaptcha', {
      sitekey: sitekey,
      callback: onChange,
      'expired-callback': () => {
        onChange(null);
      },
      theme: 'light',
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=initRecaptcha';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [sitekey]);

  return (
    <div>
      <div id="g-recaptcha"></div>
    </div>
  );
};
