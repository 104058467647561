'use strict';

import React from 'react';
import Utils from '../jskit/general/Utils';
import CheckBox from '../jskit/react/forms/CheckBox';

export default class ResultsTable extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      openResultId: null,
    };
  }

  toggleOpenResult(resultId, e) {
    e.preventDefault();

    if (this.state.openResultId === resultId) {
      this.setState({openResultId: null});
    } else {
      this.setState({openResultId: resultId});
    }
  }

  render() {
    var checkboxCol = null;
    if (this.props.isAddingChecks) {
      checkboxCol = <th style={{width: '40px'}} />;
    }

    return (
      <div className="results-table mb-5" data-wizard={'domainhealth-results-' + this.props.group.key.toLowerCase()}>
        <div className="d-flex align-items-center mb-4">
          <div className="icon text-primary bg-primary-fade">
            <i className={this.props.group.icon} />
          </div>
          <h4 className="mb-0">{this.props.group.name}</h4>
        </div>
        <div className="white-block p-4">
          <table className="table listing-table table-valign-middle table-responsive-lg">
            <thead>
              <tr>
                {checkboxCol}
                <th style={{width: '25px'}}></th>
                <th>Type</th>
                <th>Test</th>
                <th>Result</th>
                <th style={{width: '115px'}}></th>
              </tr>
            </thead>
            <tbody>{this.props.results.map(this.renderTableRow.bind(this))}</tbody>
          </table>
        </div>
      </div>
    );
  }

  renderStatusPagePromo(result, statusPagesURL) {
    if (result && result.name === 'Status page check' && result.short_output === 'The status page was not found.') {
      return (
        <span className="ml-1">
          We strongly recommend setting up one using our easy-to-use and reliable&nbsp;
          <a href={statusPagesURL}>Public Status Page</a>.
        </span>
      );
    }
  }

  renderTableRow(result) {
    var checkboxCol = null;
    if (this.props.isAddingChecks) {
      if (this.props.selectedChecks[result.id] !== null) {
        checkboxCol = (
          <td className="nowrap text-center">
            <CheckBox
              fieldName={'check-' + result.id}
              divCSSClass="p-0"
              value={this.props.selectedChecks[result.id]}
              onChange={this.props.onSelectedCheckToggle.bind(null, result.id)}
            />
          </td>
        );
      } else {
        checkboxCol = <td className="nowrap text-center">n/a</td>;
      }
    }

    var resultRow = (
      <tr key={result.id}>
        {checkboxCol}
        <td>
          <i className={result.state + ' fas fa-fw'}></i>
        </td>
        <td>{result.type_display}</td>
        <td>{result.name}</td>
        <td>
          <span>{result.short_output}</span>
          {this.renderStatusPagePromo(result, this.props.statusPagesURL)}
        </td>
        <td className="text-right">
          <a href="#" onClick={this.toggleOpenResult.bind(null, result.id)} className="text-primary">
            <i className={'fas ' + (this.state.openResultId === result.id ? 'fa-times' : 'fa-info-circle')} />
            &nbsp;
            {this.state.openResultId === result.id ? 'Close' : 'More Info'}
          </a>
        </td>
      </tr>
    );

    var moreInfoRow;

    if (this.state.openResultId === result.id) {
      moreInfoRow = (
        <tr key={result.id + '-info'}>
          <td colSpan="6" className="py-0">
            <div className="card card-light-2 card-body mt-1 mb-3">
              <pre className="mb-0 text-force-wrap">{result.verbose_output.trim()}</pre>
            </div>
          </td>
        </tr>
      );
    } else {
      moreInfoRow = <tr key={result.id + '-info'} style={{display: 'none'}} />;
    }

    return [resultRow, moreInfoRow];
  }
}

ResultsTable.defaultProps = {
  group: null,
  results: [],
  selectedChecks: {},
  isAddingChecks: false,

  onSelectedCheckToggle: null,
};
