'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import {Label, uniqueHtmlId, unpackFormLinkOrProps, FieldErrors, FieldHelpText} from './FormHelpers';

export default class ImageInput extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.htmlId = uniqueHtmlId(props.fieldName);
    this.state = {
      uploadedImage: this.props.currentImageURL,
      currentImageURL: this.props.currentImageURL,
    };
  }

  handleFileUploadChange(e) {
    // Read the uploaded file
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        // Check it's an image
        if (event.target.result && event.target.result.split(';')[0].indexOf('image') !== -1) {
          this.setState({uploadedImage: event.target.result});
        }
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  handleChangeClick(e) {
    e.preventDefault();
    this.refs.imgInput.click();
  }

  handleRemove(e) {
    const form = unpackFormLinkOrProps(this.props);
    this.setState({uploadedImage: null, currentImageURL: null});
    this.refs.imgInput.value = '';
    const evt = Object.assign({}, e);
    form.onChange(evt);
    if (this.props.onRemove) {
      this.props.onRemove(this.props.fieldName);
    }
  }

  render() {
    const props = Object.assign({}, this.props);
    props.onChange = this.handleFileUploadChange;
    const form = unpackFormLinkOrProps(props);
    const imgSrc = this.state.uploadedImage;
    return (
      <div className={this.props.formGroupClass}>
        <div className="form-control" style={{height: 'auto'}}>
          <div className="row">
            <div className="col-lg-9">
              <Label fieldId={this.htmlId} {...this.props} />
              <input
                className="d-none"
                ref="imgInput"
                type="file"
                id={this.htmlId}
                name={this.props.fieldName}
                disabled={this.props.disabled}
                onChange={form.onChange}
              />
              <div className="row d-flex align-content-center">
                <div className="col-12">
                  <small>
                    <a href="#" onClick={this.handleChangeClick}>
                      {imgSrc ? 'change' : 'upload'}
                    </a>
                    &nbsp;&nbsp;&nbsp;
                    {imgSrc ? (
                      <a href="#" onClick={this.handleRemove}>
                        remove
                      </a>
                    ) : (
                      false
                    )}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text-right">
              {imgSrc ? <img src={imgSrc} className="img-fluid" style={{height: this.props.maxHeight}} /> : false}
            </div>
          </div>
          <FieldHelpText {...this.props} />
        </div>
        <FieldErrors errors={form.errors} />
      </div>
    );
  }
}

ImageInput.defaultProps = {
  currentImageURL: null, // URL to the currently uploaded image, if any
  maxHeight: 50, // Max height of the image preview

  fieldName: '', // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
  disabled: false, // Control is disabled?

  formGroupClass: 'form-group', // CSS classes for the form-group div

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  onChange: null, // Change handler
  onRemove: null,
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName
};
