'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import URLHistory from '../../jskit/general/URLHistory';
import ComponentsTab from './ComponentsTab.jsx';
import SystemMetricsTab from './SystemMetricsTab.jsx';
import IncidentsTab from './IncidentsTab.jsx';
import SSOTab from './SSOTab.jsx';
import ExternalAccountUsersTab from './ExternalAccountUsersTab.jsx';
import SubscribersTab from './SubscribersTab';
import SettingsForm from './SettingsForm.jsx';
import CustomizeForm from './CustomizeForm.jsx';
import {postAlertMessage} from '../../js/global/alerts';

const Tabs = {
  TAB_COMPONENTS: 'tab-components',
  TAB_METRICS: 'tab-metrics',
  TAB_INCIDENTS: 'tab-incidents',
  TAB_MAINTENANCE: 'tab-maintenance',
  TAB_SSO: 'tab-sso',
  TAB_EXTERNAL_USERS: 'tab-external-users',
  TAB_SUBSCRIBERS: 'tab-sp-subscribers',
};

export default class StatusPageManageController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.ALL_TABS = [
      [Tabs.TAB_COMPONENTS, 'Components'],
      [Tabs.TAB_METRICS, 'System Metrics'],
      [Tabs.TAB_INCIDENTS, 'Incidents'],
      [Tabs.TAB_MAINTENANCE, 'Maintenance'],
      [Tabs.TAB_SUBSCRIBERS, 'Subscribers'],
    ];

    if (props.saml_settings.enabled && props.statuspage.visibility_level === 'EXTERNAL_USERS') {
      this.ALL_TABS.push([Tabs.TAB_SSO, 'SSO']);
    }

    if (props.statuspage.visibility_level === 'EXTERNAL_USERS') {
      this.ALL_TABS.push([Tabs.TAB_EXTERNAL_USERS, 'External Users']);
    }

    this.state = {
      hasLoaded: false,
      currentTab: Tabs.TAB_COMPONENTS,
      statuspage: props.statuspage,
      sp_uuid: props.sp_uuid,
      sp_acs_url: props.sp_acs_url,
      sp_wayfless_url: props.sp_wayfless_url,
      sp_attributes: props.sp_attributes,
      test_idps: props.test_idps,
      saml_settings: props.saml_settings,
    };
  }

  componentDidMount() {
    const queryString = URLHistory.queryStringToObject();
    if (queryString.success !== undefined) {
      URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['success']));
      const pageTypeDisplay = this.props.statuspage.page_type_display;
      const isPublic = this.props.statuspage.is_public;
      postAlertMessage(
        '<strong>New ' +
          pageTypeDisplay +
          ' successfully created. Click <i class="fas fa-fw fa-eye">' +
          '</i> icon at top right to preview ' +
          (isPublic ? 'public ' : '') +
          'page.</strong>',
        'success',
        true
      );
    } else if (queryString.settings !== undefined) {
      URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['settings']));
      this.refs.settingsForm.showModal();
    } else if (queryString.new_incident !== undefined) {
      URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['new_incident']));
      this.setState({}, this.handleAddIncidentClick);
    } else if (queryString.new_maintenance !== undefined) {
      URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['new_maintenance']));
      this.setState({}, this.handleAddMaintenanceClick);
    }
  }

  handleTabChange(tab) {
    if (tab !== this.state.currentTab && [Tabs.TAB_INCIDENTS, Tabs.TAB_MAINTENANCE].includes(tab)) {
      const ref = tab === Tabs.TAB_INCIDENTS ? this.refs.incidentsTab : this.refs.maintenanceTab;
      ref.onNewData(this.state.statuspage);
    }
    this.setState({currentTab: tab});
  }

  handleNewData(statuspage) {
    this.setState({statuspage: statuspage});
  }

  handleSettingsClick(e) {
    e.preventDefault();
    this.refs.settingsForm.showModal();
  }

  handleCustomizeClick(e) {
    e.preventDefault();
    this.refs.customizeForm.showModal();
  }

  handleAddIncidentClick(e) {
    if (e) {
      e.preventDefault();
    }
    this.handleTabChange(Tabs.TAB_INCIDENTS);
    this.setState({}, this.refs.incidentsTab.handleAdd);
  }

  handleAddMaintenanceClick(e) {
    if (e) {
      e.preventDefault();
    }
    this.handleTabChange(Tabs.TAB_MAINTENANCE);
    this.setState({}, this.refs.maintenanceTab.handleAdd);
  }

  render() {
    const statuspage = this.state.statuspage;
    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">{'Manage: ' + statuspage.name}</h1>
          <div>
            <div className="dropdown d-inline-block mr-2">
              <button
                data-wizard="statuspages-manage-btn-settings"
                data-toggle="dropdown"
                data-toggle-secondary="tooltip"
                data-original-title="Settings"
                data-placement="bottom"
                aria-haspopup="true"
                aria-expanded="false"
                className="btn btn-white text-muted"
              >
                <i className="fas fa-fw fa-cog" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="#" onClick={this.handleSettingsClick}>
                  Settings
                </a>
                <a className="dropdown-item" href="#" onClick={this.handleCustomizeClick}>
                  Customize Look &amp; Feel
                </a>
              </div>
            </div>
            <a
              data-wizard="statuspages-manage-btn-preview"
              data-toggle="tooltip"
              data-placement="bottom"
              data-original-title="Preview"
              target="_blank"
              href={statuspage.cname_url || statuspage.public_url || statuspage.private_url}
              className="btn btn-white text-muted mr-2"
            >
              <i className="fas fa-fw fa-eye" />
            </a>
            <div className="dropdown d-inline-block mr-2">
              <button
                data-wizard="statuspages-manage-btn-add"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="btn btn-primary"
              >
                <i className="fas fa-plus mr-2" />
                Add
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="#" onClick={this.handleAddIncidentClick}>
                  New Incident
                </a>
                <a className="dropdown-item" href="#" onClick={this.handleAddMaintenanceClick}>
                  New Scheduled Maintenance
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <SectionNav
            sectionIds={this.ALL_TABS}
            currentSectionId={this.state.currentTab}
            navClass="nav nav-tabs-plain mb-4"
            onSectionNav={this.handleTabChange}
          >
            <SectionNavContent sectionId={Tabs.TAB_COMPONENTS} currentSectionId={this.state.currentTab}>
              <ComponentsTab
                updateComponentURL={this.props.updateComponentURL}
                updateComponentGroupURL={this.props.updateComponentGroupURL}
                servicesCheckListURL={this.props.servicesCheckListURL}
                reorderURL={this.props.reorderComponentsURL}
                statuspage={statuspage}
                choices={this.props.choices}
                componentStatusRank={this.props.componentStatusRank}
                onNewData={this.handleNewData}
              />
            </SectionNavContent>
            <SectionNavContent sectionId={Tabs.TAB_METRICS} currentSectionId={this.state.currentTab}>
              <SystemMetricsTab
                updateURL={this.props.updateMetricURL}
                reorderURL={this.props.reorderMetricsURL}
                servicesCheckListURL={this.props.servicesCheckListURL}
                statuspage={statuspage}
                onNewData={this.handleNewData}
              />
            </SectionNavContent>
            <SectionNavContent sectionId={Tabs.TAB_INCIDENTS} currentSectionId={this.state.currentTab}>
              <IncidentsTab
                ref="incidentsTab"
                updateURL={this.props.updateIncidentURL}
                updatePreviewURL={this.props.incidentUpdatePreviewURL}
                forMaintenance={false}
                statuspage={statuspage}
                componentStatusRank={this.props.componentStatusRank}
                listPastIncidentsURL={this.props.listPastIncidentsURL}
                choices={this.props.choices}
                onNewData={this.handleNewData}
              />
            </SectionNavContent>
            <SectionNavContent sectionId={Tabs.TAB_MAINTENANCE} currentSectionId={this.state.currentTab}>
              <IncidentsTab
                ref="maintenanceTab"
                updateURL={this.props.updateIncidentURL}
                updatePreviewURL={this.props.incidentUpdatePreviewURL}
                listPastIncidentsURL={this.props.listPastIncidentsURL}
                forMaintenance={true}
                statuspage={statuspage}
                componentStatusRank={this.props.componentStatusRank}
                choices={this.props.choices}
                onNewData={this.handleNewData}
              />
            </SectionNavContent>
            <SectionNavContent sectionId={Tabs.TAB_SUBSCRIBERS} currentSectionId={this.state.currentTab}>
              <SubscribersTab
                statuspage={statuspage}
                hasAdminAccess={this.props.hasAdminAccess}
                exportSubscribersURL={this.props.exportSubscribersURL}
                subscribersURL={this.props.subscribers.subscribersURL}
                importSubscribersURL={this.props.importSubscribersURL}
                importSubscribersTemplateURL={this.props.importSubscribersTemplateURL}
                subscribersSearchURL={this.props.subscribers.subscribersSearchURL}
                subscribersDeleteURL={this.props.subscribers.subscribersDeleteURL}
                importSubscribersSampleURL={this.props.importSubscribersSampleURL}
                subscribersCount={this.state.statuspage.subscribers_count}
                onReactivateClicked={this.handleSettingsClick}
              ></SubscribersTab>
            </SectionNavContent>
          </SectionNav>
          {this.props.saml_settings.enabled &&
          this.props.statuspage.visibility_level === 'EXTERNAL_USERS' &&
          this.state.currentTab === 'tab-sso' ? (
            <SectionNavContent sectionId={Tabs.TAB_SSO} currentSectionId={this.state.currentTab}>
              <SSOTab
                sp_uuid={this.state.sp_uuid}
                sp_acs_url={this.state.sp_acs_url}
                sp_wayfless_url={this.state.sp_wayfless_url}
                sp_attributes={this.state.sp_attributes}
                test_idps={this.state.test_idps}
                saml_settings={this.state.saml_settings}
                metadata_url={this.props.saml_settings.metadata_url}
                cname_url={this.state.statuspage.cname_url}
              />
            </SectionNavContent>
          ) : (
            ''
          )}
          {(this.props.saml_settings.enabled || statuspage.visibility_level === 'EXTERNAL_USERS') &&
          this.state.currentTab === 'tab-external-users' ? (
            <SectionNavContent sectionId={Tabs.TAB_EXTERNAL_USERS} currentSectionId={this.state.currentTab}>
              <ExternalAccountUsersTab
                listURL={this.props.external_users.listURL}
                saveURL={this.props.external_users.saveURL}
                toggleURL={this.props.external_users.toggleURL}
                deleteURL={this.props.external_users.deleteURL}
                passwordResetURL={this.props.external_users.passwordResetURL}
                exportUsersURL={this.props.external_users.exportUsersURL}
              />
            </SectionNavContent>
          ) : (
            ''
          )}
        </div>
        <SettingsForm
          ref="settingsForm"
          siteURL={this.props.siteURL}
          cnameURL={this.props.cnameURL}
          updateURL={this.props.updateStatuspageURL}
          choices={this.props.choices}
          statuspage={statuspage}
          saml_settings={this.state.saml_settings}
          currentVisibilityLevel={this.props.current_visibility_level}
          visibilityLevels={this.props.visibility_levels}
          onNewData={this.handleNewData}
          accountTimezone={this.props.account_timezone}
        />
        <CustomizeForm
          ref="customizeForm"
          updateURL={this.props.updateStatuspageURL}
          choices={this.props.choices}
          statuspage={statuspage}
          advancedFeaturesEnabled={this.props.advancedFeaturesEnabled}
          onNewData={this.handleNewData}
        />
      </React.Fragment>
    );
  }
}
