/**
 * React.js Utility functions.
 */

'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';

import $ from 'jquery';
const ReactUtils = {};

/**
 * Renders the default component from `moduleName`, with the given `props`,
 * into the specified DOM element.
 */
ReactUtils.renderComponent = function (domElementId, moduleName, props, componentName) {
  componentName = componentName || 'default';

  // Use doc ready handler to be sure all other doc ready handlers have already run
  $(document).ready(function () {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const componentClass = require(moduleName)[componentName];
    const component = React.createElement(componentClass, props);
    ReactDOM.render(component, document.getElementById(domElementId));
  });
};

/**
 * Escapes a string to allow use in HTML.
 */
ReactUtils.escapeHTML = function (unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

/**
 * Default event handle to prevent form submission when a form
 * consists of a single text input. Usage:
 *
 * <form onSubmit={ReactUtils.doNotSubmit()}/>
 */
ReactUtils.doNotSubmit = function (e) {
  e.preventDefault();
};

/**
 * Creates an css 'class' string from several parts,
 * either strings or objects with key=classname, value=whether to include.
 */
ReactUtils.cssClass = function () {
  const css = [];

  _.each(arguments, function (arg) {
    if (_.isObject(arg)) {
      _.each(arg, function (v, k) {
        if (v) {
          css.push(k.toString().trim());
        }
      });
    } else if (arg) {
      css.push(arg.toString().trim());
    }
  });

  return css.join(' ');
};

/**
 * Creates an array of <option> elements based on the given
 * object mapping or array of key value pairs.
 *
 * If emptyLabel is provided (not undefined), an additional option is created
 * with an empty key and the emptyLabel string.
 */
ReactUtils.createSelectOptionList = function (keyValuePairs, emptyLabel) {
  if (!_.isArray(keyValuePairs)) {
    keyValuePairs = _.pairs(keyValuePairs);
  }

  const opts = _.map(keyValuePairs, function (v) {
    return (
      <option key={v[0]} value={v[0]}>
        {v[1]}
      </option>
    );
  });

  if (emptyLabel !== undefined) {
    opts.unshift(
      <option key="" value="">
        {emptyLabel}
      </option>
    );
  }

  return opts;
};

/* generate unique key for each invocation and prefix */
ReactUtils.Key = {
  counters: {},
  get: function (prefix) {
    if (this.counters[prefix] === undefined) {
      this.counters[prefix] = 0;
    }
    this.counters[prefix]++;
    return prefix + '-' + this.counters[prefix];
  },
};
//****************************
//* EXPORTS
//****************************

export default ReactUtils;
