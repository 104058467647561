import React from 'react';

const SubscriptionHeader = ({title, subtitle}) => (
  <React.Fragment>
    <div className="row">
      <div className="col-md-9">
        <h2>
          {title}
          {subtitle && (
            <React.Fragment>
              <b> &gt; </b>
              {subtitle}
            </React.Fragment>
          )}
        </h2>
      </div>
      <div className="col-md-3 text-right">
        Current Plan Type: <b>Free Trial</b>
      </div>
    </div>
    <hr />
  </React.Fragment>
);

export default SubscriptionHeader;
