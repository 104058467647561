'use strict';

import {datetimeFormatter, defaultFormatter} from '../RumUtils.jsx';

/** Get safely formatted filename for download */
const getDownloadFilename = (checkName, identifier) => {
  if (!checkName && !identifier) {
    return undefined; // Use default apexcharts behavior.
  }
  let filename = (checkName || '').replace(/[^a-z0-9]/gi, '_').toLowerCase();
  if (identifier) {
    filename = `${filename}_${identifier}`;
  }
  return filename;
};

/** Options for graph download filenames */
export const toolbarExportOptions = (checkName, identifier) => {
  const filename = getDownloadFilename(checkName, identifier);
  return {
    export: {
      csv: {
        filename,
        dateFormatter: (value) =>
          datetimeFormatter(value, undefined, undefined, {
            forceYear: true,
            forceDate: true,
            forceTime: true,
          }),
        headerCategory: 'time',
      },
      svg: {filename},
      png: {filename},
    },
  };
};

export const defaultGraphOptions = () => {
  return {
    chart: {
      fontFamily: 'Proxima Nova, proxima-nova, Helvetica Neue, Helvetica, Arial, sans-serif',
      zoom: {
        enabled: false,
      },
      toolbar: {
        offsetY: -20,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      animations: {
        enabled: false,
      },
    },

    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      itemMargin: {
        horizontal: 10,
      },
      markers: {
        width: 10,
        height: 10,
        radius: 2,
      },
    },

    tooltip: {
      y: {
        formatter: defaultFormatter,
      },
      fixed: {
        enabled: true,
        position: 'topLeft',
        offsetY: -175,
      },
    },

    title: {
      offsetY: 30,
      offsetX: 50,
    },
  };
};

/** Custom formatting of graphs that cannot be done via apexcharts options or CSS. Pass the chart ref from graph components. */
export const manualFormat = (chart) => {
  if (!chart) {
    return;
  }
  const toolbar = chart.toolbar;
  if (toolbar && toolbar.elMenuIcon && toolbar.localeValues) {
    toolbar.elMenuIcon.title = toolbar.localeValues.menu = 'Download this graph';
  }
};
