import {TooltipIcon} from '../../components/TooltipIcon';
import {PLAN_FIELD_PLURAL, PLAN_FIELD_SINGULAR, SubscriptionFeature} from './PLAN_DEFS';
import Formatter from '../../jskit/general/Formatter';

interface Props {
  plan: string;
  feature: string;
  used: number;
  limit: number;
}

export const FeatureRowWarning = ({plan, feature, used, limit}: Props) => {
  if (feature === SubscriptionFeature.LimitMonitoringGroupLevel) {
    const tooltip =
      'The selected plan is unavailable due to locations ' +
      'configured that are not available in this plan. ' +
      'Please update the locations or click the button below ' +
      "to reset all tests to this plan's default locations.";
    return (
      <TooltipIcon
        tooltip={tooltip}
        placement="top"
        className="w-20"
        iconClass="fa-lg far fa-solid fa-gauge-circle-minus text-brand-red"
      />
    );
  }

  if (used <= limit) {
    return null;
  }

  const plural = PLAN_FIELD_PLURAL[feature];
  const singular = PLAN_FIELD_SINGULAR[feature];
  const originalName = Formatter.pluralize(used, singular, plural);
  const toRemoveName = Formatter.pluralize(used - limit, singular, plural);
  const title = `You currently have ${used} ${originalName} configured and the ${plan} plan supports ${
    limit > 0 ? limit : 'none'
  }. Please delete ${used - limit} ${toRemoveName} to subscribe to ${plan}.`;
  return (
    <TooltipIcon
      tooltip={title}
      placement="top"
      className="w-20"
      iconClass="fa-lg far fa-solid fa-gauge-circle-minus text-brand-red"
    />
  );
};
