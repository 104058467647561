/**
 * Provides functions to read, write and erase cookies.
 *
 * Dependencies: None.
 *
 */

'use strict';

var Cookie = {};

/**
 * Set a cookie.
 *
 * @param {string} name - Name of the cookie.
 * @param {string} value - Value of the cookie.
 * @param {int} expiryDays - In how many days the cookie should be erased.
 *      If not provided, creates a session cookie.
 */
Cookie.setCookie = function (name, value, expiryDays) {
  var expires = '';

  if (expiryDays) {
    var date = new Date();
    date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  }

  document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + expires + '; path=/; SameSite=Lax';
};

/**
 * Get a cookie.
 *
 * @param {string} name - Name of the cookie.
 * @param {string} decode - URLDecode the cookie value. Defaults to true.
 */
Cookie.getCookie = function (name, decode) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) == 0) {
      var value = c.substring(nameEQ.length, c.length);
      if (decode || decode === undefined) {
        value = decodeURIComponent(value);
      }
      return value;
    }
  }

  return null;
};

/**
 * Deletes a cookie, if it exists.
 *
 * @param {string} name - Name of the cookie.
 */
Cookie.clearCookie = function (name) {
  Cookie.setCookie(name, '', -1);
};

/**
 * Set a JSON-encoded cookie.
 *
 * @param {string} name - Name of the cookie.
 * @param {object} value - Javascript object with key-value pairs for cookie.
 * @param {int} expiryDays - In how many days the cookie should be erased.
 *      If not provided, creates a session cookie.
 */
Cookie.setJSONCookie = function (name, value, expiryDays) {
  var encodedValue = JSON.stringify(value);
  Cookie.setCookie(name, encodedValue, expiryDays);
};

/**
 * Get a JSON-encoded cookie value, returning the Javascript object it contains.
 *
 * @param {string} name - Name of the cookie.
 */
Cookie.getJSONCookie = function (name) {
  var encodedValue = Cookie.getCookie(name);
  if (encodedValue) {
    return JSON.parse(encodedValue);
  } else {
    return undefined;
  }
};

//****************************
//* EXPORTS
//****************************

export default Cookie;
