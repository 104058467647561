'use strict';

import React from 'react';
import moment from 'moment';
import Formatter from '../../jskit/general/Formatter';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';

export const COMPARISON_STATE = {
  BETTER: 'BETTER',
  WORSE: 'WORSE',
  NEUTRAL: 'NEUTRAL',
  NO_DATA: 'NO_DATA',
};

/** Helper function to check if value is unset, explicitly or not */
export const isUnset = (value) => {
  return value === undefined || value === null || isNaN(value);
};

/** Shared formatter for RUM check values */
export const baseFormatter = (value, precision, {intThreshold} = {}) => {
  if (isUnset(value)) {
    return '';
  }
  precision = Number.isInteger(precision) ? precision : 2;
  const absVal = Math.abs(value);
  if (absVal < 1) {
    return parseFloat(value.toFixed(precision));
  }
  if (absVal >= 1000) {
    return Formatter.humanFriendlyInt(value, {precision, threshold: intThreshold});
  }
  if (Number.isInteger(value)) {
    return value;
  }
  return parseFloat(value.toPrecision(precision));
};

/**
 * Millisecond feature value formatter.
 * Convert to seconds if any of these conditions are met:
 *  - value is >= 1000 ms
 *  - value is part of a group where the max value is >= 1000 ms
 */
export const millisecondFormatter = (value, precision, {maxValue} = {}) => {
  let units = 'ms';
  if (value >= 1000 || (maxValue || 0) >= 1000) {
    value /= 1000;
    units = 's';
  }
  let string;
  if (isUnset(value)) {
    string = '--';
  } else if (value === 0) {
    string = '0';
  } else {
    value = baseFormatter(value, precision, {intThreshold: Infinity});
    string = `${value}${units}`;
  }
  return {value, units, string};
};

/** Default formatter for RUM check values */
export const defaultFormatter = (value, precision, feature, {intThreshold, maxValue} = {}) => {
  if (isUnset(value)) {
    return '--';
  }
  if (feature && feature.units === 'ms') {
    return millisecondFormatter(value, precision, {maxValue}).string;
  }
  return `${baseFormatter(value, precision, {intThreshold})}${feature ? feature.units || '' : ''}`;
};

/** Datetime formatter conditional on (max - min) time range (e.g. show higher resolution over smaller periods) */
export const datetimeFormatter = (value, rangeStart, rangeEnd, {forceYear, forceDate, forceTime} = {}) => {
  // Remove timezone from date string and assume it is UTC. With the timezone offset, moment parses the string incorrectly.
  if (typeof value === 'string') {
    if (!value.endsWith('Z')) {
      value += 'Z';
    }
  }
  const date = Formatter.localizeDate(value);
  if (date == null) {
    return '';
  }

  let rangeSec;
  if (rangeStart && rangeEnd) {
    rangeSec = Math.abs((new Date(rangeStart) - new Date(rangeEnd)) / 1000);
  }

  const month = date.format('MMM');
  const tz = date.zoneAbbr();
  const mm = ('0' + date.minutes()).substr(-2);

  // If the range spans more than 364 days, include the year.
  // If the range end is not in the current year, include the year.
  let includeYear = forceYear || rangeSec >= 31449600;
  if (rangeEnd) {
    const rangeEndYear = Formatter.localizeDate(rangeEnd).year();
    const currentYear = Formatter.localizeDate(new moment()).year();
    includeYear |= rangeEndYear < currentYear;
  }

  let dateString = `${month} ${date.date()}`;
  if (includeYear) {
    dateString += ` ${date.year()}`;
  }
  const timeString = `${date.hours()}:${mm} ${tz}`;
  const datetimeString = `${dateString} ${timeString}`;

  // Default to date+time if the range is not provided.
  if (!rangeSec) {
    return datetimeString;
  }

  // If the date range spans at least 5 days, include the date and exclude the time.
  if (rangeSec >= 5 * 86400) {
    return forceTime ? datetimeString : dateString;
  }

  // If the date range is between 1 and 5 days, include the date and time.
  if (rangeSec >= 86400) {
    return datetimeString;
  }

  // If less than a day, just include the time.
  return forceDate ? datetimeString : timeString;
};

/** Helper function to check if feature is not applicable with the current data (if main page or ajax only) */
export const applicable = (feature, data, isAjax) => {
  if (!data || !feature) {
    return false;
  }
  return !(feature.mainOnly && isAjax);
};

/**
 *
 * @param {Object} feature checkFeature object.
 * @param {Number} current current value.
 * @param {Number} baseline baseline value.
 * @param {Number} thresholdPct if abs(delta) < thresholdPct, treat as neutral
 * @returns {String} Current state.
 */
export const getState = (feature, deltaPct, thresholdPct) => {
  thresholdPct = thresholdPct === undefined ? 2 : thresholdPct;

  let state;
  if (feature.stateless) {
    state = COMPARISON_STATE.NEUTRAL;
  } else if (isUnset(deltaPct)) {
    state = COMPARISON_STATE.NO_DATA;
  } else if (Math.abs(deltaPct) <= thresholdPct) {
    state = COMPARISON_STATE.NEUTRAL;
  } else if (feature.inverse) {
    state = deltaPct > thresholdPct ? COMPARISON_STATE.BETTER : COMPARISON_STATE.WORSE;
  } else {
    state = deltaPct < thresholdPct ? COMPARISON_STATE.BETTER : COMPARISON_STATE.WORSE;
  }

  return state;
};

/**
 * Generate standard tooltip for feature
 * If `data` is passed, the tooltip will also include the change % (if available)
 */
export const getTooltip = (feature, data, {pctPrecision} = {}) => {
  let title = feature.tooltip ? `${feature.name}: ${feature.tooltip}.` : '';
  const tooltipName = feature.tooltip ? 'This' : feature.tooltipName; // Don't display the feature name twice.

  if (data) {
    if (title) {
      title += '\n';
    }
    const deltaPct = (data || {})[feature.deltaPct];
    if (isUnset(deltaPct)) {
      title += 'No baseline data yet. Check back later or try a different time range.';
    } else {
      const absPct = Math.abs(deltaPct);
      const displayPct = `${defaultFormatter(absPct, pctPrecision || 2)}%`;
      if (deltaPct < 0) {
        title += `${tooltipName} decreased by ${displayPct}`;
      } else if (deltaPct > 0) {
        title += `${tooltipName} increased by ${displayPct}`;
      } else {
        title += `${tooltipName} has not changed`;
      }
      title += " compared to the past week's baseline.";
    }
  }

  return title;
};

/**
 * Generate baseline comparison object.
 * @param {Object} feature check feature info from checkFeatures.
 * @param {Object} data data containing feature key and deltaPct key at the top level.
 * @param {Number} valuePrecision display precision for raw value.
 * @param {Number} pctPrecision display precision for deltaPct.
 * @param {Number} intThreshold threshold for applying abbreviations to integers.
 * @param {Number} maxValue maximum value of group (if applicable), used to keep consistent units.
 * @param {Boolean} isAjax true if the data is Ajax-only.
 *
 */
export const getBaselineComparison = (
  feature,
  data,
  {valuePrecision, pctPrecision, intThreshold, maxValue, isAjax} = {}
) => {
  if (!feature || !data) {
    return;
  }

  const isApplicable = applicable(feature, data, isAjax);
  const value = data[feature.key];
  const deltaPct = data[feature.deltaPct];
  const state = isApplicable ? getState(feature, deltaPct) : COMPARISON_STATE.NO_DATA;
  const title = isApplicable
    ? getTooltip(feature, data)
    : `${feature.tooltipName} is not applicable for the selected data.`;
  valuePrecision = valuePrecision || 3;
  pctPrecision = pctPrecision || 2;

  let displayValue;
  let displayPct;
  let arrowClassName;
  let statusClassName = 'change';

  if (!isApplicable) {
    displayValue = 'n/a';
  } else if (isUnset(value)) {
    displayValue = '--';
  } else {
    displayValue = defaultFormatter(value, valuePrecision, feature, {intThreshold, maxValue});
  }

  if (!isApplicable || isUnset(deltaPct)) {
    displayPct = '';
    statusClassName += ' change-neutral';
  } else {
    const absPct = Math.abs(deltaPct);
    displayPct = `${defaultFormatter(absPct, pctPrecision)}%`;

    arrowClassName = 'arrow';
    switch (state) {
      case COMPARISON_STATE.BETTER:
        arrowClassName += ' change-success';
        statusClassName += ' change-success';
        break;
      case COMPARISON_STATE.WORSE:
        arrowClassName += ' change-danger';
        statusClassName += ' change-danger';
        break;
      default:
        arrowClassName += ' change-neutral';
        statusClassName += ' change-neutral';
        break;
    }
    arrowClassName += deltaPct >= 0 ? ' up-arrow' : ' down-arrow';
  }

  return {feature, isApplicable, displayValue, displayPct, arrowClassName, statusClassName, title};
};

/**
 * Generate baseline comparison fragment.
 * @param {Object} comparison generated from getBaselineComparison.
 * @param {String} subLabel optional label to place next to the baseline comparison.
 * @param {Object} divStyle optional formatting for the container div.
 * @param {Object} headerStyle optional formatting for the value and deltaPct div.
 * @param {Object} arrowStyle optional formatting for the deltaPct arrow (e.g. bold if bad).
 */
export const renderBaselineComparison = (comparison, {subLabel, divStyle, headerStyle, arrowStyle} = {}) => {
  if (!comparison) {
    return;
  }
  return (
    <React.Fragment key={comparison.feature.key}>
      <div className="baseline-comparison" style={divStyle} title={comparison.title}>
        <p className="baseline-comparison-value" style={headerStyle}>
          {comparison.displayValue}
        </p>
        <i className={comparison.arrowClassName} style={arrowStyle} />
        <p className={comparison.statusClassName} style={arrowStyle}>
          {comparison.displayPct}
        </p>
        {!!subLabel && comparison.isApplicable && comparison.displayValue !== '--' && (
          <p className="change change-neutral">{subLabel}</p>
        )}
      </div>
    </React.Fragment>
  );
};

/**
 * Lighte a hex color by converting to rgba and setting opacity.
 * Ref: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 * @param {String} color Hex color string (with or without leading #).
 * @param {Number} opacity 0 for fully transparent, 1 for fully opaque.
 * @returns {String} Modified rbga color.
 */
export const hexToRgba = (color, opacity) => {
  const [r, g, b] = color
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
  opacity = Math.max(0, opacity || 0);
  opacity = Math.min(1, opacity);
  return `rgba(${r},${g},${b},${opacity})`;
};

/** Truncate string and add ... */
export const truncate = (string, length) => {
  string = '' + string;
  if (!length || string.length <= length) {
    return string;
  }
  const substring = string.substring(0, length);
  return substring + '...';
};

/** Render "Need help?" div */
export const renderNeedHelp = (link, {containerStyle, linkOnly, dismissible} = {}) => {
  // eslint-disable-next-line max-len
  link = link || 'https://support.uptime.com/hc/en-us/articles/4415874052242-RUM-Advanced-Data-and-Reports#check_page';
  containerStyle = Object.assign({paddingBottom: dismissible ? '0' : '0.8em'}, containerStyle || {});
  const content = (
    <div className="rum-help-inner-container" style={containerStyle}>
      {!linkOnly && <span style={{fontWeight: 'bold'}}>Need help?</span>}
      {!linkOnly && (
        <span className="rum-help-label">Hover over items for on-screen tooltips or view documentation</span>
      )}
      <ZendeskSupportLink href={link}>
        <i className="fal fa-file-search" style={{fontWeight: '500'}} title="View support article"></i>
      </ZendeskSupportLink>
    </div>
  );

  if (dismissible) {
    return (
      <div className="alert alert-info alert-dismissible fade show" role="alert">
        {content}
        <button type="button" className="close rum-help-close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
  return content;
};

/** Render hover div when no data is present */
export const renderNoData = (series, message) => {
  if (series && series.length) {
    return undefined;
  }
  message = message || 'No data found. Try adjusting time range.';
  return (
    <div className="no-data-container">
      <div className="no-data-text">{message}</div>
    </div>
  );
};
