'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import URLHistory from '../../jskit/general/URLHistory';
import AutoRefresh from '../../jskit/react/AutoRefresh';
import ReactUtils from '../../jskit/react/ReactUtils';
import MessageBox from '../../js/global/messagebox';
import DashboardDisplay from './DashboardDisplay.jsx';
import DashboardForm from './DashboardForm.jsx';

export default class DashboardController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      hasLoaded: false,
      allServicesLoaded: false,
      autoRefreshIsSuppressed: false,
      hasBeenModified: false,

      dashboardDef: Utils.deepCopy(this.props.dashboardDef),
      validationErrors: {},

      globalMetrics: {},
      serviceInfo: [],
      servicePerformanceMetrics: {},
      latestOutages: [],
      alertForDetails: null,

      goBackOnCancel: false,
    };
  }

  componentDidMount() {
    this.loadAllData();

    if (URLHistory.queryStringToObject().new !== undefined) {
      this.setState(
        {
          dashboardDef: Object.assign({}, this.state.dashboardDef, {id: null, name: 'New Dashboard'}),
          hasBeenModified: true,
        },
        () => {
          this.handleCustomizeClick();
        }
      );
    } else {
      const query = URLHistory.queryStringToObject();
      const pop = this.props.hasWriteAccess ? query.pop : null;
      const goBackOnCancel = query.gobackoncancel ? query.gobackoncancel === 'true' : false;
      this.setState({goBackOnCancel});
      URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['pop', 'gobackoncancel']));
      if (pop === 'initial') {
        this.handleCustomizeClick();
      }
    }
  }

  loadAllData() {
    this.setState({autoRefreshIsSuppressed: false});

    if (!this.state.dashboardDef.metrics_show_section && !this.state.dashboardDef.alerts_show_section) {
      this.loadCheckInfo();
      return;
    }

    new Ajax().post({
      url: this.props.metricsURL,
      data: {
        dashboard_def: this.state.dashboardDef,
      },
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({
          globalMetrics: data.global_metrics || {},
          latestOutages: data.latest_outages || [],
        });

        this.loadCheckInfo();
      }.bind(this),
    });
  }

  loadCheckInfo(next) {
    if (!this.state.dashboardDef.services_show_section) {
      this.setState({hasLoaded: true});
      return;
    }

    const offset = next ? this.state.serviceInfo.length : 0;

    new Ajax().post({
      url: this.props.serviceInfoURL,
      data: {
        dashboard_def: this.state.dashboardDef,
        offset: offset,
      },
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({
          hasLoaded: true,
          allServicesLoaded:
            (!next && data.service_info.length < this.state.dashboardDef.services_num_to_show) ||
            (next && data.service_info.length < 4),
          serviceInfo: next ? this.state.serviceInfo.concat(data.service_info) : data.service_info,
          servicePerformanceMetrics: next
            ? Object.assign(this.state.servicePerformanceMetrics, data.service_performance_metrics)
            : data.service_performance_metrics,
        });
      }.bind(this),
    });
  }

  handleShowMoreChecks(e) {
    e.preventDefault();

    if (this.refs.autoRefresh.isAutoRefreshActive()) {
      this.refs.autoRefresh.setRefreshInterval(0, true);
      this.setState({autoRefreshIsSuppressed: true});
      setTimeout(() => this.loadCheckInfo(true), 1500);
    } else {
      this.loadCheckInfo(true);
    }
  }

  handleAutoRefreshChanged() {
    this.setState({
      allServicesLoaded: this.state.serviceInfo.length < this.state.dashboardDef.services_num_to_show,
      autoRefreshIsSuppressed: false,
      serviceInfo: this.state.serviceInfo.slice(0, this.state.dashboardDef.services_num_to_show),
    });
  }

  handleAlertDetailsClick(index, e) {
    e.preventDefault();
    this.setState({alertForDetails: this.state.latestOutages[index]});
    this.refs.dashboardDisplay.showAlertModal();
  }

  handleCreateNewDashboardClick(e) {
    e.preventDefault();
    window.location = this.props.dashboardURL + '?new';
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleCustomizeClick(e) {
    if (e) {
      e.preventDefault();
    }

    this.clearValidationErrors();
    this.refs.dashboardForm.showModal(Utils.deepCopy(this.state.dashboardDef));
  }

  handleCustomizeApply(e) {
    e.preventDefault();

    const newDef = Object.assign({}, this.state.dashboardDef, Utils.deepCopy(this.refs.dashboardForm.getFormData()));
    newDef.services_num_to_show = parseInt(newDef.services_num_to_show);
    newDef.services_tags = newDef.services_tags.map((x) => parseInt(x));

    this.clearValidationErrors();

    new Ajax().post({
      url: this.props.saveURL,
      data: {
        validate_only: true,
        dashboard_def: newDef,
      },
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (!data.success) {
          this.setState({validationErrors: data.fields});
        } else {
          this.refs.dashboardForm.hideModal();
          Utils.enableWindowUnloadPopup(true);

          this.setState(
            {
              dashboardDef: newDef,
              hasLoaded: false,
              hasBeenModified: true,
            },
            this.loadAllData.bind(this)
          );
        }
      }.bind(this),
    });
  }

  handleSaveClick(e) {
    e.preventDefault();

    new Ajax().post({
      url: this.props.saveURL,
      data: {
        validate_only: false,
        dashboard_def: this.state.dashboardDef,
        dashboard_position: this.state.dashboardDef.dashboard_position,
      },
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (!data.success) {
          MessageBox.alertBox(data.error, 'Save Dashboard Failed');
        } else {
          Utils.enableWindowUnloadPopup(false);

          const newDef = Object.assign({}, this.state.dashboardDef, {id: data.data.id});
          this.setState({
            hasBeenModified: false,
            dashboardDef: newDef,
          });

          window.location = data.data.redirect_url;
        }
      }.bind(this),
    });
  }

  handleDeleteClick(e) {
    e.preventDefault();

    const msg = 'Are you sure you want to delete "' + this.state.dashboardDef.name + '"?';

    MessageBox.confirmBox(
      msg,
      'Delete Dashboard',
      function () {
        new Ajax().post({
          url: this.props.deleteURL,
          data: {dashboard_id: this.state.dashboardDef.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (!data.success) {
              MessageBox.alertBox('Unable to delete dashboard.', 'Delete Dashboard');
            } else {
              Utils.enableWindowUnloadPopup(false);
              window.location = data.data.redirect_url;
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleCancelClick() {
    if (this.state.goBackOnCancel) {
      setTimeout(() => history.back(), 300);
    }
  }

  render() {
    let saveButtons = [];
    if (this.props.hasWriteAccess) {
      saveButtons = (
        <React.Fragment>
          <div className="dropdown-divider"></div>
          <button onClick={this.handleCreateNewDashboardClick} className="dropdown-item" type="button">
            Create New Dashboard
          </button>
          <button
            onClick={this.handleSaveClick}
            className="dropdown-item"
            disabled={!this.state.hasBeenModified || !this.state.dashboardDef.id}
            type="button"
          >
            Save Changes
          </button>
          <button
            onClick={this.handleDeleteClick}
            className="dropdown-item"
            disabled={!this.state.dashboardDef.id}
            type="button"
          >
            Delete
          </button>
        </React.Fragment>
      );
    }

    const allDashboards = this.props.allDashboards
      .map((x) => Object.assign({}, x, {url: this.props.dashboardURL.replace('0', x.id)}))
      .map((x) => (
        <a
          key={x.id}
          href={x.url}
          className={ReactUtils.cssClass('dropdown-item', {
            pinned: x.is_pinned,
            selected: x.id === this.state.dashboardDef.id,
          })}
        >
          {x.name}
        </a>
      ));

    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">
            <span className="mr-3">{this.state.dashboardDef.name}</span>
            {this.state.hasBeenModified && this.props.hasWriteAccess ? (
              <small className="text-warning">
                <em>(unsaved)</em>
              </small>
            ) : null}
          </h1>
          <div className="flex-sm-shrink-0">
            {this.state.hasBeenModified && this.props.hasWriteAccess ? (
              <button
                type="button"
                title="Save changes to this dashboard"
                onClick={this.handleSaveClick}
                className="btn btn-primary mr-2 mb-1"
              >
                <i className="fas fa-fw fa-save" />
              </button>
            ) : null}
            <AutoRefresh
              ref="autoRefresh"
              refreshFunction={this.loadAllData}
              onAutoRefreshChanged={this.handleAutoRefreshChanged}
              extraClass="mr-2 mb-1"
            />
            <button
              type="button"
              title="Toggle Full Screen"
              onClick={Utils.toggleFullScreenMode}
              className="btn btn-white mr-2 mb-1 d-none d-sm-inline-block"
            >
              <i className="fas fa-fw fa-expand-arrows-alt" />
            </button>
            <div id="dashboard-menu" className="btn-group">
              <button
                type="button"
                className="btn btn-white mb-1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-fw fa-bars" />
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-with-selections">
                <button onClick={this.handleCustomizeClick} className="dropdown-item" type="button">
                  Customize...
                </button>
                {saveButtons}
                {allDashboards.length ? (
                  <React.Fragment>
                    <div className="dropdown-divider"></div>
                    <h6 className="dropdown-header">All Dashboards</h6>
                    {allDashboards}
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <DashboardForm
          ref="dashboardForm"
          choices={this.props.choices}
          allDashboards={this.props.allDashboards}
          servicesCheckListURL={this.props.servicesCheckListURL}
          errors={this.state.validationErrors}
          onCancel={this.handleCancelClick}
          onApply={this.handleCustomizeApply}
        />
        <DashboardDisplay
          ref="dashboardDisplay"
          {...this.props}
          hasLoaded={this.state.hasLoaded}
          allServicesLoaded={this.state.allServicesLoaded}
          autoRefreshIsSuppressed={this.state.autoRefreshIsSuppressed}
          dashboardDef={this.state.dashboardDef}
          globalMetrics={this.state.globalMetrics}
          serviceInfo={this.state.serviceInfo}
          servicePerformanceMetrics={this.state.servicePerformanceMetrics}
          latestOutages={this.state.latestOutages}
          alertForDetails={this.state.alertForDetails}
          onShowMoreChecks={this.handleShowMoreChecks}
          onAlertDetailsClick={this.handleAlertDetailsClick}
        />
      </React.Fragment>
    );
  }
}
