import _ from 'underscore';
import React from 'react';
import 'highlight.js/styles/github.css';
import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('html', xml);
hljs.highlightAll();

import Utils from '../../jskit/general/Utils';
import TextInput from '../../jskit/react/forms/TextInput';

import $ from 'jquery';

export default class HTMLElementListInput extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      htmlElementListOpen: false,
    };
  }

  componentDidMount() {
    $('body').on('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    $('body').off('click', this.handleBodyClick);
  }

  filteredHTMLElements() {
    if (!this.props.htmlElementList) {
      return null;
    }

    var text = this.props.value || '';
    text = text
      .toLowerCase()
      .replace(/#/g, '')
      .replace(/\./g, ' ')
      .replace(/^\w+\[name="(.*?)"\]/, '$1')
      .trim();

    if (!text) {
      return this.props.htmlElementList;
    }

    return this.props.htmlElementList.filter(function (v) {
      return v.toLowerCase().indexOf(text) !== -1;
    });
  }

  findSelectorForElement(element) {
    var idMatch = element.match(/\sid="(.*?)"/i);
    if (idMatch) {
      return '#' + idMatch[1].trim();
    }

    var nameMatch = element.match(/<(\w+)\s(.*?\s)?name="(.*?)"/i);
    if (nameMatch) {
      return nameMatch[1] + '[name="' + nameMatch[3] + '"]';
    }

    var classMatch = element.match(/\sclass="(.*?)"/i);
    if (classMatch) {
      var classes = classMatch[1].trim().split(/ +/g);
      return _.map(classes, function (c) {
        return '.' + c;
      }).join('');
    }

    return null;
  }

  handleBodyClick(e) {
    if (this.state.htmlElementListOpen) {
      if (!$.contains(this.refs.topNode, e.target)) {
        this.setState({htmlElementListOpen: false});
      }
    }
  }

  handleFocus() {
    this.setState({htmlElementListOpen: true});
  }

  handleSelection(element) {
    var selector = this.findSelectorForElement(element);
    this.setState({htmlElementListOpen: false});

    if (selector && this.props.onChange) {
      this.props.onChange({target: {name: this.props.fieldName, value: selector}});
    }
  }

  render() {
    return (
      <div ref="topNode" className="position-relative">
        <TextInput
          fieldName={this.props.fieldName}
          labelText={this.props.labelText}
          isRequired={this.props.isRequired}
          maxLength={this.props.maxLength}
          value={this.props.value}
          onChange={this.props.onChange}
          onFocus={this.handleFocus}
        />
        {this.state.htmlElementListOpen && this.props.htmlElementList
          ? this.renderHTMLElementList(this.filteredHTMLElements())
          : null}
      </div>
    );
  }

  renderHTMLElementList(htmlElementList) {
    if (!htmlElementList || !htmlElementList.length) {
      return null;
    }

    return <ul className="element-list">{this.renderHTMLElementListItems(htmlElementList)}</ul>;
  }

  renderHTMLElementListItems(htmlElementList) {
    return _.map(
      htmlElementList,
      function (e, i) {
        return (
          <li key={i}>
            <a onClick={this.handleSelection.bind(null, e)} dangerouslySetInnerHTML={{__html: this.formatElement(e)}} />
          </li>
        );
      }.bind(this)
    );
  }

  formatElement(element) {
    return hljs.highlight('html', element, true).value;
  }
}

HTMLElementListInput.defaultProps = {
  htmlElementList: null,
  fieldName: null,
  labelText: null,
  isRequired: true,
  value: null,
  onChange: null,
};
