'use strict';

import _ from 'underscore';
import React, {createRef} from 'react';
import Formatter from '../../jskit/general/Formatter';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Modal from '../../jskit/react/Modal';
import ReactUtils from '../../jskit/react/ReactUtils';
import TransactionUtils from '../../transactions/TransactionUtils.jsx';
import MessageBox from '../../js/global/messagebox';

function _getEditCheckURL(editCheckURL, alert) {
  return alert ? editCheckURL.replace('0000', alert.service_id) : '#';
}

function _getAnalysisURL(analysisURL, alert) {
  return alert ? analysisURL.replace('0000', alert.service_id) : '#';
}

export class AlertDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.modalRef = createRef();

    this.state = {
      alert: null,
      alertExtData: null,
    };
  }

  _getAlert() {
    return this.props.alert || this.state.alert;
  }

  showModal() {
    this.setState(
      {
        alert: null,
        alertExtData: null,
      },
      () => {
        this.modalRef.current.showModal();

        if (!this._getAlert()) {
          this.loadAlert();
        }
      }
    );
  }

  loadAlert() {
    new Ajax().get({
      url: this.props.alertURL + '?alert_id=' + this.props.alertId,
      decoder: 'json',
      success: function (data) {
        if (!data.success) {
          this.modalRef.current.hideModal();
          MessageBox.alertBox('Alert data was not found.', 'System Error');
        } else {
          this.setState({alert: data.data});
        }
      }.bind(this),
    });
  }

  loadAlertExtData(alertId) {
    this.setState({alertExtData: true});

    new Ajax().get({
      url: this.props.alertExtDataURL + '?alert_id=' + alertId,
      decoder: 'json',
      success: function (data) {
        if (!data.success) {
          MessageBox.alertBox('Root cause data was not found.', 'System Error');
          this.handleHideAlertExtData();
        } else {
          this.setState({alertExtData: data.data});
        }
      }.bind(this),
    });
  }

  handleClose(e) {
    e.preventDefault();
    this.modalRef.current.hideModal();
  }

  handleShowAlertExtData(alertId, e) {
    e.preventDefault();
    this.loadAlertExtData(alertId);
  }

  handleHideAlertExtData(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({alertExtData: null});
  }

  render() {
    const alert = this._getAlert();
    const withAlertExtData = alert && (alert.service_type === 'TRANSACTION' || alert.service_type === 'API');
    let contents = null;

    if (!alert || this.state.alertExtData === true) {
      contents = this.renderLoading();
    } else if (this.state.alertExtData) {
      contents = this.renderAlertExtData(this.state.alertExtData.alert, this.state.alertExtData.alert_data);
    } else if (alert && alert.down_alerts) {
      contents = alert.down_alerts.map(this.renderAlert.bind(this, withAlertExtData));
    }

    const buttons = (
      <div className="d-sm-flex justify-content-between align-items-center w-100">
        <div>
          {alert && !alert.service_is_deleted && this.props.hasWriteAccess ? (
            <a className="btn btn-outline-light-2" href={_getEditCheckURL(this.props.editCheckURL, alert)}>
              Edit Check
            </a>
          ) : null}
        </div>
        <div className="mt-2 mt-sm-0">
          {this.props.analysisURL && alert && !alert.service_is_deleted ? (
            <a href={_getAnalysisURL(this.props.analysisURL, alert)} className="btn btn-outline-secondary">
              Real-Time Analysis
            </a>
          ) : null}
          <button type="button" className="btn btn-primary ml-2" onClick={this.handleClose}>
            Close
          </button>
        </div>
      </div>
    );

    return (
      <Modal ref={this.modalRef} title="Alert Details" size="lg" extraClass="alert-details-modal" buttons={buttons}>
        {contents}
      </Modal>
    );
  }

  renderLoading() {
    return <i className="loading-spinner pad100" />;
  }

  renderAlert(withAlertExtData, alert) {
    const monSrv = alert.monitoring_server && alert.monitoring_server.location ? alert.monitoring_server : null;
    const locationName = monSrv ? monSrv.location : 'Check Result';

    return (
      <div key={alert.id} className="mb-5">
        <h4 className="mb-2">
          <strong>{locationName}</strong>
        </h4>
        <p className="font-14 text-muted mb-1">{Formatter.longDateTime(alert.created_at)}</p>
        {this.renderMonitoringServerInfo(monSrv)}
        <pre className="card card-light-2 card-body card-body-slim text-prewrap">{alert.output}</pre>
        {withAlertExtData ? (
          <a href="#" onClick={this.handleShowAlertExtData.bind(null, alert.id)}>
            <i className="fas fa-chevron-circle-right" /> Root Cause Analysis
          </a>
        ) : null}
      </div>
    );
  }

  handleStepWaterfallClick(requests) {
    TransactionUtils.createInfoWindow(TransactionUtils.renderWaterfallHTML(requests), 'Requests Waterfall');
  }

  renderAlertExtData(alert, alertData) {
    if (!alertData) {
      return (
        <p>
          No root cause analysis is available for this alert.&nbsp;
          <a href="#" onClick={this.handleHideAlertExtData}>
            Go Back
          </a>
          .
        </p>
      );
    }

    var STEP_DEFS;
    var service_type = this._getAlert().service_type;
    if (service_type === 'TRANSACTION') {
      STEP_DEFS = _.object(this.props.stepDefs['TRANSACTION']);
    } else if (service_type === 'API') {
      STEP_DEFS = _.object(this.props.stepDefs['API']);
    }
    const monSrv = alert.monitoring_server || {};
    let screenshot = null;

    if (alertData.picture) {
      screenshot = (
        <React.Fragment>
          <h5>Screenshot</h5>
          <a target="_blank" className="d-block border rounded mb-4" href={alertData.picture}>
            <img className="img-fluid" src={alertData.picture} alt="" />
          </a>
        </React.Fragment>
      );
    }
    if (alertData.network) {
      const version = alertData.version || '2.0.0';
      const firstStepIndex = Utils.isVersionGreaterOrEqual(version, '3.0.1') ? 0 : 1;
      alertData.results = _.map(alertData.results, function (step, index) {
        var requests = _.sortBy(
          _.filter(alertData.network, (item) => item.step === index + firstStepIndex),
          (item) => item.timing.requestWillBeSent
        );
        return _.extend({}, alertData.results[index], {
          requests: requests,
        });
      });
      alertData.network = null;
    }

    const stepResults = alertData.results.map((s, i) => {
      const stepDef = STEP_DEFS[s.step_def];
      const desc = this.formatStepDescription(stepDef, s);

      const id = 'step-results-details-' + i;
      let detailsIcon = null;
      let detailsPanel = null;
      let waterfallIcon = null;

      if (s.details) {
        detailsIcon = (
          <a
            className="details"
            data-toggle="collapse"
            href={'#' + id}
            role="button"
            aria-expanded="false"
            aria-controls={id}
          >
            <i className="fas fa-info-circle" />
          </a>
        );
        detailsPanel = (
          <div className="collapse" id={id}>
            <div className="details-panel card card-body-slim bg-light-2 mx-4 my-3">
              <dl
                dangerouslySetInnerHTML={{
                  __html: TransactionUtils.formatStepDetails(s.details),
                }}
              />
            </div>
          </div>
        );
      }

      if (s.requests && s.requests.length > 0) {
        waterfallIcon = (
          <a
            className="details"
            href="#"
            title="Show requests waterfall"
            onClick={() => {
              TransactionUtils.createInfoWindow(
                TransactionUtils.renderWaterfallHTML(s.requests),
                'Requests Waterfall: Step ' + (i + 1)
              );
            }}
          >
            <i className="fal fa-align-left" />
          </a>
        );
      }

      return (
        <li key={i} className={ReactUtils.cssClass(stepDef.step_type, {error: s.error})}>
          <span className="num">{i + 1}.</span>
          <span className="step" dangerouslySetInnerHTML={{__html: desc}} />
          <span className="response">{s.response_time}ms</span>
          {waterfallIcon}
          {detailsIcon}
          {detailsPanel}
        </li>
      );
    });

    let browserConsole = null;
    if (alertData.console !== undefined) {
      const browserConsoleItems = alertData.console.map((e, i) => (
        <li key={i} className={e.type}>
          <i className="fas fa-fw" />
          <span>{e.message}</span>
        </li>
      ));
      browserConsole = (
        <div>
          <h5>Browser Console Log</h5>
          <ul
            className="list-unstyled border rounded browser-console-output mb-4"
            style={{maxHeight: '250px', overflowY: 'auto'}}
          >
            {browserConsoleItems}
          </ul>
        </div>
      );
    }
    return (
      <div>
        <a className="float-right" href="#" onClick={this.handleHideAlertExtData}>
          <i className="far fa-arrow-alt-circle-left" />
          Go Back
        </a>
        <h4 className="mb-2">
          <strong>Root Cause Analysis: {monSrv.location || ''}</strong>
        </h4>
        <p className="font-14 text-muted mb-1">{Formatter.longDateTime(alert.created_at)}</p>
        {this.renderMonitoringServerInfo(monSrv)}
        <pre className="card card-light-2 card-body card-body-slim text-prewrap mb-4">{alert.output}</pre>
        {screenshot}
        <h5>Check Results</h5>
        <ol className="transaction-step-results list-unstyled border rounded mb-4">{stepResults}</ol>
        {browserConsole}
      </div>
    );
  }

  renderMonitoringServerInfo(monSrv) {
    if (!monSrv || !monSrv.name) {
      return null;
    }

    return (
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="font-14 text-muted mr-3 mb-0">
          <em>{monSrv.name || ''}</em>
        </p>
        <p className="d-none d-md-block font-13 font-monospace text-muted text-prewrap mb-0">
          [{monSrv.address_ipv4}]&nbsp; [{monSrv.address_ipv6}]
        </p>
      </div>
    );
  }

  formatStepDescription(stepDef, data) {
    const combinedStepDef = Object.assign({}, stepDef, {step_def: data.step_def, values: data.values || {}});
    let desc = TransactionUtils.formatStepDescription(combinedStepDef);

    if (data.error) {
      desc += '<br/><span class="small text-danger">' + ReactUtils.escapeHTML(data.error) + '</span>';
    }

    return desc;
  }
}

AlertDetailsModal.defaultProps = {
  hasWriteAccess: false, // Whether this user can edit checks
  alertURL: null, // URL for loading alert details from ID, or null if `alert` is provided.
  alertExtDataURL: null, // URL for loading alert ext data (root cause analysis).
  analysisURL: null, // Base URL to the check's Real-Time Analysis page (null to hide button).
  alertId: null, // AlertId to display details for, or null if `alert` is provided.
  alert: null, // Full alert object, or null to load from ID.
  stepDefs: {}, // stepDefs,
};
