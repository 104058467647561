/* eslint-disable */
'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import {unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText, CustomEvent} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export default class TimeInput extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.lastVal = '';
  }

  isValid(val) {
    // validation prevents a user from typing incorrect time values hours > 24 and minutes > 59
    // the only exception is hours == 24 and minutes == 00 that is used to denote time.max
    const regexp = /^\d{0,2}?\:?\d{0,2}(\:[0-5]?[0-9]?)?(\.[0-9]{0,6}?)?$/; // HH:MM[:SS[.uuuuuu]]
    var parts = val.split(':');
    const hoursStr = parts[0],
      minutesStr = parts[1];

    if (!regexp.test(val)) {
      return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = Number.isInteger(hours) && hours >= 0 && hours <= 24;
    const isValidMinutes = (Number.isInteger(minutes) && (hours < 24 || minutes == 0)) || Number.isNaN(minutes); // allow empty minutes (user hasn't entered yet), allow only 00 if hours == 24, or any number wich is additionally validated later to be <= 59

    if (!isValidHour || !isValidMinutes) {
      return false;
    }

    // do not allow to enter minutes with fist digit greater than 5
    if (minutes < 10 && Number(minutesStr[0]) > 5) {
      return false;
    }

    return true;
  }

  handleChange(e) {
    const form = unpackFormLinkOrProps(this.props);
    var val = e.target.value;

    if (this.isValid(val)) {
      //automatically add or remove ':' to separate HH and MM as user types
      // add ':' after two digits entered
      if (val.length == 2 && this.lastVal.length != 3 && val.indexOf(':') == -1) {
        val = val + ':';
      }
      // add ':' immediatelly after hours starts from digit > 2
      if (val.length == 1 && Number(val) > 2 && this.lastVal.length != 2 && val.indexOf(':') == -1) {
        val = val + ':';
      }
      // automatically remove ':' from 'xx:' value when deleting a char
      if (val.length == 2 && this.lastVal.length == 3) {
        val = val.slice(0, 1);
      }
      // automatically remove ':' from 'x:' valu when deleting a char
      if (val.length == 1 && this.lastVal.length == 2 && this.lastVal[1] == ':') {
        val = '';
      }
      this.lastVal = val;

      form.onChange(new CustomEvent({type: 'change', target: this, value: val}));
    }
    return false;
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className="form-group time-input">
        <Label fieldId={this.htmlId} {...this.props} />
        {this.renderInput(form)}
        {this.renderErrors(form)}
        <FieldHelpText {...this.props} />
      </div>
    );
  }

  renderErrors(form) {
    return <FieldErrors errors={form.errors} />;
  }

  renderInput(form) {
    let val = form.value;
    if (val.substr(5) == ':00') {
      val = val.substr(0, 5);
    }
    return (
      <input
        ref="textInput"
        type="text"
        id={this.htmlId}
        name={form.fieldName}
        className={ReactUtils.cssClass('form-control', {['is-invalid']: form.errors})}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        placeholder={this.props.placeholder}
        value={val}
        onChange={this.handleChange}
      />
    );
  }
}

TimeInput.defaultProps = {
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  placeholder: null, // Placeholder text
  isRequired: false, // Field is required?
  readOnly: false, // Field is read-only?
  disabled: false, // Control is disabled?

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
};
