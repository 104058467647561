import ManageSubscription from './ManageSubscription';
import React, {useEffect, useState} from 'react';
import SubscriptionCheckoutPage from './SubscriptionCheckoutPage';
import Ajax from '../../jskit/general/Ajax';
import _ from 'underscore';
import Header from '../../website/common/Header';
import ContactUs from './ContactUs';

const MANAGE_SUBSCRIPTION_PAGE = 'manage_subscription_page';
const MANAGE_SUBSCRIPTION_CHECKOUT = 'manage_subscription_checkout';
const MANAGE_SUBSCRIPTION_CONTACT_US = 'manage_subscription_contact_us';

const ManageSubscriptionController = ({
  plan,
  plans,
  checkFrequencies,
  starterLimits,
  bundleSizes,
  unitsPrices,
  premiumDiscount,
  isAnnual,
  isPrimary,
  braintreeToken,
  paymentMethod,
  billingInfo,
  companyData,
  uptimeBrandInfo,
  doneURL,
  updateURL,
  calculateURL,
  customRequestURL,
  includedValues,
  allCountries,
  resetLocationsURL,
  excludeStateCountries,
  showStateDropdownForCountries,
}) => {
  const [promoCodeError, setPromoCodeError] = useState();
  const [appliedPromoCode, applyPromoCode] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(plan);
  const [checkoutErrors, setCheckoutErrors] = useState(null);
  const [currentPage, setCurrentPage] = useState(MANAGE_SUBSCRIPTION_PAGE);
  // checkout page dependency, remove with calculate call when checkout is extracted
  const [calculatedValues, setCalculatedValues] = useState({});
  useEffect(() => {
    // clean bt session for clean prices calculation
    // sessionStorage.removeItem('bt_subscription');
    fetchCalculatedValues({plan});
  }, []);
  const fetchCalculatedValues = ({plan, promoCode}, onSuccess) => {
    new Ajax().post({
      url: calculateURL,
      data: {
        preset: plan,
        added_values: {},
        extra_data: {
          annual_billing: isAnnual,
          promo_code: promoCode,
        },
      },
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (!_.isEmpty(data.data)) {
          setCalculatedValues(data.data);
          if (onSuccess instanceof Function) {
            onSuccess();
          }
          if (data.error && data.fields['promo_code']) {
            setPromoCodeError(data.fields['promo_code']);
          }
        }
      }.bind(this),
    });
    if (promoCode !== appliedPromoCode) {
      applyPromoCode(promoCode);
    }
  };
  const handleSubmit = (paymentData, paymentFieldsErrorFn, finalizeSubmitFn) => {
    const data = {
      payment_data: paymentData,
      annual_billing: isAnnual,
      promo_code: appliedPromoCode,
      plan: selectedPlan,
    };
    new Ajax().post({
      url: updateURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          window.location = doneURL + '?action=subscription';
        } else {
          console.log(JSON.stringify(data.fields.__all__));
          if (data.fields.payment_fields) {
            paymentFieldsErrorFn(data.fields.payment_fields);
          }
          if (data.fields.__all__) {
            setCheckoutErrors(data.fields.__all__);
          }
        }
      },
    });
  };

  return (
    <>
      {currentPage === MANAGE_SUBSCRIPTION_PAGE && <Header title="Manage Subscription" />}
      {currentPage === MANAGE_SUBSCRIPTION_PAGE && (
        <ManageSubscription
          plan={plan}
          plans={plans}
          checkFrequencies={checkFrequencies}
          starterLimits={starterLimits}
          bundleSizes={bundleSizes}
          unitsPrices={unitsPrices}
          premiumDiscount={premiumDiscount}
          isAnnual={isAnnual}
          onPlanChange={(plan) => {
            setSelectedPlan(plan);
            if (plan === 'CUSTOM') {
              setCurrentPage(MANAGE_SUBSCRIPTION_CONTACT_US);
              return;
            }

            fetchCalculatedValues({plan}, () => {
              setCurrentPage(MANAGE_SUBSCRIPTION_CHECKOUT);
            });
          }}
          resetLocationsURL={resetLocationsURL}
        />
      )}
      {currentPage === MANAGE_SUBSCRIPTION_CONTACT_US && (
        <ContactUs
          initialValues={companyData}
          requestURL={customRequestURL}
          onCancel={() => setCurrentPage(MANAGE_SUBSCRIPTION_PAGE)}
          onSuccess={() => (window.location = doneURL + '?action=custom-request')}
        />
      )}
      {currentPage === MANAGE_SUBSCRIPTION_CHECKOUT && (
        <SubscriptionCheckoutPage
          withTitle={true}
          bareUnitesPrices={unitsPrices}
          braintreeToken={braintreeToken}
          isCustomer={true}
          allowPromocode={true}
          promoCodeError={promoCodeError}
          onPromocodeRefresh={({code}) => {
            setPromoCodeError(null);
            fetchCalculatedValues({plan: selectedPlan, promoCode: code});
          }}
          isPrimary={isPrimary}
          formLink={{get: () => isAnnual}}
          onCancel={() => {
            setPromoCodeError();
            setCurrentPage(MANAGE_SUBSCRIPTION_PAGE);
          }}
          onSubmit={handleSubmit}
          paymentMethod={paymentMethod}
          billingInfo={billingInfo}
          companyData={companyData}
          uptimeBrandInfo={uptimeBrandInfo}
          canPurchase={true}
          isUpgrade={true}
          includedValues={includedValues}
          unitsPrices={calculatedValues.units_prices}
          calculatedValues={calculatedValues}
          checkoutErrors={checkoutErrors}
          addedValues={{}}
          allCountries={allCountries}
          excludeStateCountries={excludeStateCountries}
          showStateDropdownForCountries={showStateDropdownForCountries}
        />
      )}
    </>
  );
};

export default ManageSubscriptionController;
