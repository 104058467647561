import classnames from 'classnames';
import {Interval} from 'date-fns';
import moment from 'moment';
import {pairs} from 'underscore';

import {BarData, BarEvent} from '@uptime-com/uptimebar';

import Formatter from '@/jskit/general/Formatter';
import {Incident, UptimeCalculationType} from '@/status_pages/types';
import css from './InspireDateRangeSnakePopup.module.css';

export interface BarEventWithMetadata extends BarEvent {
  eventType: UptimeCalculationType;
  incidents: Incident[];
  components: Record<string, string>;
}

export interface InspireDateRangeSnakePopupProps {
  data: BarData;
}

const MAX_LIST_LENGTH = 3;

const dateTimeLabel = (interval: Interval): string => {
  const normalizedIntervalEnd = moment(interval.end).subtract(1, 'seconds');
  if (moment(interval.start).dayOfYear() !== moment(normalizedIntervalEnd).dayOfYear()) {
    return `${Formatter.shortDate(interval.start)} ${Formatter.shortTime(interval.start)} - ${Formatter.shortDate(
      normalizedIntervalEnd
    )} ${Formatter.shortTime(normalizedIntervalEnd)}`;
  }
  return `${Formatter.shortDate(interval.start)}, ${Formatter.shortTime(interval.start)} - ${Formatter.shortTime(
    normalizedIntervalEnd
  )}`;
};

export const InspireDateRangeSnakePopup = ({data}: InspireDateRangeSnakePopupProps) => {
  const {event, interval} = data;
  if (!event) {
    return (
      <div className={css.popupContainer}>
        <div className={classnames(css.dateTimeLabel, 'mb-2 font-14')}>{dateTimeLabel(interval)}</div>
        <div className={classnames(css.popupTitle, css.positive, 'mb-3 font-24 font-weight-normal')}>100% Uptime</div>
      </div>
    );
  }
  const eventStartWithinInterval = moment(event.start).isAfter(moment(interval.start))
    ? moment(event.start)
    : moment(interval.start);
  const eventEndWithinInterval = moment(event.end).isBefore(moment(interval.end))
    ? moment(event.end)
    : moment(interval.end);

  const eventDurationWithinInterval = moment
    .duration(eventEndWithinInterval.diff(eventStartWithinInterval))
    .asSeconds();

  const components = pairs(event.components);
  return (
    <div className={css.popupContainer}>
      <div className="mb-2 font-14">{dateTimeLabel(interval)}</div>
      <div className={classnames(css.negative, 'mb-0 pb-0 font-24 font-weight-normal')}>
        {Formatter.shortDuration(eventDurationWithinInterval)}
      </div>
      <div className="d-block font-16 font-weight-normal mb-0 text-muted">
        {event.eventType === UptimeCalculationType.BY_INCIDENTS ? 'Incident' : 'Downtime'} Duration
      </div>
      {components.length > 0 && (
        <>
          <hr />
          <div>
            <div className="font-18 font-weight-bold mb-2">Affected Components</div>
            <ul className="list-unstyled list-spacer-1 font-16">
              {components.slice(0, MAX_LIST_LENGTH).map((component) => (
                <li key={`cmp-${component[0]}`}>
                  <span className={'status-icon ' + component[1]}>{component[0]}</span>
                </li>
              ))}
              {components.length > MAX_LIST_LENGTH && (
                <li key="cmp-more" className="text-muted">
                  + {components.length - MAX_LIST_LENGTH} more
                </li>
              )}
            </ul>
          </div>
        </>
      )}
      {event.incidents.length > 0 && (
        <>
          <hr />
          <div className="font-weight-bold mb-2 font-18">Incidents</div>
          <div>
            <ul className="list-unstyled list-spacer-1 font-16">
              {event.incidents.slice(0, MAX_LIST_LENGTH).map((incident) => (
                <li key={`inc-${incident.id}`}>
                  {incident.name} ({Formatter.shortDuration(incident.duration)})
                </li>
              ))}
              {event.incidents.length > MAX_LIST_LENGTH && (
                <li key="inc-more" className="text-muted">
                  + {event.incidents.length - MAX_LIST_LENGTH} more
                </li>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
