import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import AccountUserButtonBar from './AccountUsersButtonBar.jsx';
import AccountUserList from './AccountUsersList.jsx';
import AccountUserForm from './AccountUsersForm.jsx';
import MessageBox from '../../js/global/messagebox';

export default class AccountUsersController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      items: [],
      current_user_id: null,
      timezone_choices: [],
      access_level_choices: [],
      contact_choices: [],
      subaccount_choices: null,
      two_factor_override_choices: [],
      validationErrors: {},
      modalTitle: '',
      modalMessage: '',
    };
  }

  componentDidMount() {
    this.loadUsersData();
    this.loadContactsData();
  }

  loadUsersData() {
    const query = this.refs.buttonBar.getQueryFilter();

    new Ajax().post({
      url: this.props.listURL,
      data: query,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({
          items: data.items,
          current_user_id: data.current_user_id,
          timezone_choices: data.timezones,
          access_level_choices: data.access_levels,
          subaccount_choices: data.subaccounts && data.subaccounts.length ? data.subaccounts : null,
          two_factor_override_choices: data.two_factor_override,
        });
      }.bind(this),
    });
  }

  loadContactsData() {
    new Ajax().post({
      url: this.props.contactsGetURL,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({contact_choices: data.choices.contact_groups});
      }.bind(this),
    });
  }

  showModalWithMessage(title, msg) {
    msg = msg || 'An error occurred, please try again later.';
    MessageBox.alertBox(msg, title);
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleFilterChange() {
    this.loadUsersData();
  }

  handleAddItemClick(e) {
    e.preventDefault();
    this.showEditForm(null);
  }

  handleEditItemClick(itemIndex, e) {
    e.preventDefault();
    this.showEditForm(itemIndex);
  }

  showEditForm(itemIndex) {
    let state;

    this.clearValidationErrors();

    if (null === itemIndex) {
      state = {
        access_level: '10-READ',
        timezone: 'GMT',
        is_api_enabled: true,
        is_current_user: false,
      };
    } else {
      state = _.clone(this.state.items[itemIndex]);
      state.is_current_user = state.id === this.state.current_user_id;
      state.assigned_subaccounts = _.pluck(state.assigned_subaccounts, 'id');
    }

    this.refs.form.showModal(state);
  }

  handleActivateItemClick(itemIndex, activate, e) {
    e.preventDefault();
    const lowerCaseTerm = activate ? 're-activate' : 'de-activate';
    const titleCaseTerm = activate ? 'Re-activate' : 'De-activate';
    const item = this.state.items[itemIndex];
    const msg = 'Are you sure you want to ' + lowerCaseTerm + ' user "' + item.email + '"?';

    MessageBox.confirmBox(
      msg,
      titleCaseTerm + ' User',
      function () {
        new Ajax().post({
          url: this.props.activateURL,
          data: {
            id: item.id,
            activate: activate,
          },
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (!data.success) {
              this.showModalWithMessage('Unable to ' + lowerCaseTerm + ' user');
            } else {
              this.loadUsersData();
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleDeleteItemClick(itemIndex, e) {
    e.preventDefault();
    const item = this.state.items[itemIndex];
    const msg = 'Are you sure you want to DELETE user "' + item.email + '"?';

    MessageBox.confirmBox(
      msg,
      'Delete User',
      function () {
        new Ajax().post({
          url: this.props.deleteURL,
          data: {
            id: item.id,
          },
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (!data.success) {
              this.showModalWithMessage('Unable to delete user', data.error);
            } else {
              this.loadUsersData();
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleResetTwoFactorClick(itemIndex, e) {
    e.preventDefault();
    const item = this.state.items[itemIndex];
    new Ajax().post({
      url: this.props.reset2FAURL,
      data: {id: item.id},
      encoder: 'json',
      decoder: 'json',
      success: function (response) {
        if (!response.success) {
          this.showModalWithMessage('Unable to reset 2FA', response.error);
        } else {
          this.showModalWithMessage('Success', 'User’s 2FA access has been reset successfully.');
          this.loadUsersData();
        }
      }.bind(this),
    });
  }

  handleResendInviteItemClick(itemIndex, e) {
    e.preventDefault();
    const item = this.state.items[itemIndex];
    const msg = 'Are you sure you want to resend the invitation email to "' + item.email + '"?';

    MessageBox.confirmBox(
      msg,
      'Resend email invite',
      function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        new Ajax().post({
          url: _this.props.resendInviteURL,
          data: {
            id: item.id,
          },
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (data.success) {
              _this.showModalWithMessage(
                'Invitation successfully sent',
                'We sent a new email invite to: ' + item.email
              );
            } else {
              _this.showModalWithMessage('Unable to resend the email invite');
            }
          },
        });
      }.bind(this)
    );
  }

  handleFormSaveClick(e) {
    e.preventDefault();
    this.clearValidationErrors();

    new Ajax().post({
      url: this.props.saveURL,
      data: this.refs.form.getFormData(),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.loadUsersData();
          this.refs.form.hideModal();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">User Management</h1>
          <div className="btn-group">
            <button
              data-wizard="users-add-new"
              type="button"
              onClick={this.handleAddItemClick}
              className="btn btn-primary dropdown-toggle-split-left"
            >
              New User
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle dropdown-toggle-split dropdown-toggle-split-right"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle Dropdown</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <a href="#" onClick={this.handleAddItemClick} className="dropdown-item">
                Add New User
              </a>
              <a target="_blank" href={this.props.exportUsersURL} className="dropdown-item">
                Export to CSV
              </a>
            </div>
          </div>
        </div>
        <AccountUserButtonBar
          ref="buttonBar"
          subaccountsFilter={this.state.subaccount_choices}
          onFilterChange={this.handleFilterChange}
        />
        <AccountUserList
          hasLoaded={!_.isEmpty(this.state.timezone_choices)}
          items={this.state.items}
          includeSubaccounts={!!this.state.subaccount_choices}
          onEdit={this.handleEditItemClick}
          onActivate={this.handleActivateItemClick}
          onDelete={this.handleDeleteItemClick}
          onResendInvite={this.handleResendInviteItemClick}
          onResetTwoFactor={this.handleResetTwoFactorClick}
          hasCanPurchaseFeature={this.props.hasCanPurchaseFeature}
        />
        <AccountUserForm
          ref="form"
          timezoneChoices={this.state.timezone_choices}
          accessLevelChoices={this.state.access_level_choices}
          contactChoices={this.state.contact_choices}
          subaccountChoices={this.state.subaccount_choices}
          twoFactorOverrideChoices={this.state.two_factor_override_choices}
          isPrimaryUser={this.props.isPrimaryUser}
          errors={this.state.validationErrors}
          onSave={this.handleFormSaveClick}
          hasCanPurchaseFeature={this.props.hasCanPurchaseFeature}
          mobileAccessDisabledAtAccount={this.props.mobileAccessDisabledAtAccount}
        />
      </React.Fragment>
    );
  }
}
