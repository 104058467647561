'use strict';

import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import URLHistory from '../../jskit/general/URLHistory';
import ScheduledReportList from './ScheduledReportList.jsx';
import ScheduledReportForm from './ScheduledReportForm.jsx';
import MessageBox from '../../js/global/messagebox';
import {postAlertMessage} from '../../js/global/alerts';

export default class ScheduledReportController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      hasLoaded: false,
      items: [],
      validationErrors: {},
    };
  }

  componentDidMount() {
    const pop = this.props.hasWriteAccess ? URLHistory.queryStringToObject().pop : null;
    URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['pop']));
    this.loadAllData(pop);
  }

  loadAllData(pop) {
    new Ajax().post({
      url: this.props.listURL,
      data: {},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({hasLoaded: true, items: data.items});

        if (pop === 'initial') {
          this.handleAddItemClick();
        } else if (parseInt(pop)) {
          const popIndex = data.items.findIndex((x) => x.id === parseInt(pop));
          if (popIndex >= 0) {
            this.showEditForm(popIndex);
          }
        }
      }.bind(this),
    });
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleAddItemClick(e) {
    if (e) {
      e.preventDefault();
    }

    this.showEditForm(null);
  }

  handleEditItemClick(itemIndex, e) {
    if (e) {
      e.preventDefault();
    }

    this.showEditForm(itemIndex);
  }

  handleDuplicateItemClick(itemIndex, e) {
    if (e) {
      e.preventDefault();
    }

    this.showEditForm(itemIndex, true);
  }

  showEditForm(itemIndex, duplicate) {
    let state;
    this.clearValidationErrors();

    if (null === itemIndex) {
      if (_.isEmpty(this.props.choices.report)) {
        // TODO: Handle wording for multiple report types when available
        MessageBox.alertBox(
          'Please create an SLA Report to send prior to creating a schedule.',
          'New Scheduled Report'
        );
        return;
      }
      state = _.clone(this.props.choices.defaults);
    } else {
      state = _.clone(this.state.items[itemIndex]);
      state.report = state.report ? state.report.key : null;

      if (duplicate) {
        state.id = null;
      }
    }
    this.refs.form.showModal(state);
  }

  handleDeleteItemClick(itemIndex, e) {
    e.preventDefault();

    const item = this.state.items[itemIndex];
    const msg = 'Are you sure you want to delete "' + item.name + '"?';

    MessageBox.confirmBox(
      msg,
      'Delete Scheduled Report',
      function () {
        new Ajax().post({
          url: this.props.deleteURL,
          data: {id: item.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (!data.success) {
              MessageBox.alertBox('Unable to delete Scheduled Report.', 'Delete Scheduled Report');
            } else {
              this.refs.form.hideModal();
              this.loadAllData();
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleFormSaveClick(e) {
    e.preventDefault();
    this.clearValidationErrors();

    const formData = this.refs.form.getFormData();
    const reportIsBeingAdded = !formData.id;
    const reportName = formData.name;

    new Ajax().post({
      url: this.props.saveURL,
      data: this.refs.form.getFormData(),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.refs.form.hideModal();
          this.loadAllData();

          if (reportIsBeingAdded) {
            postAlertMessage(
              `Your new Scheduled Report &quot;${_.escape(reportName)}&quot; has been added.`,
              'success',
              true
            );
          }
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">Scheduled Reports</h1>
          {this.props.hasWriteAccess ? (
            <button onClick={this.handleAddItemClick} className="btn btn-primary">
              New Scheduled Report
            </button>
          ) : null}
        </div>

        <ScheduledReportList
          handleAddItemClick={this.handleAddItemClick}
          hasWriteAccess={this.props.hasWriteAccess}
          hasLoaded={this.state.hasLoaded}
          siteURL={this.props.siteURL}
          timezoneName={this.props.timezoneName}
          items={this.state.items}
          onEdit={this.handleEditItemClick}
          onDuplicate={this.handleDuplicateItemClick}
          onDelete={this.handleDeleteItemClick}
        />
        <ScheduledReportForm
          ref="form"
          siteURL={this.props.siteURL}
          timezoneName={this.props.timezoneName}
          choices={this.props.choices}
          errors={this.state.validationErrors}
          onSave={this.handleFormSaveClick}
        />
      </React.Fragment>
    );
  }
}
