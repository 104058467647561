'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';

export default class SystemMetric extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      isDragging: false,
    };
  }

  handleDragHandleMouseDown() {
    // Enable dragging only from the handle
    this.setState({isDragging: true});
  }

  handleDragHandleMouseUp() {
    // Disable dragging again
    this.setState({isDragging: false});
  }

  handleDragStart(e) {
    // Save this component ID as the component being moved
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('srcEl', JSON.stringify(this.props.metric));
  }

  handleDragOver(e) {
    // Prevent default to allow dropping
    e.preventDefault();
  }

  handleDragEnd() {
    // Disable dragging again
    this.setState({isDragging: false});
  }

  handleDrop(e) {
    e.preventDefault();
    // Trigger event handler with both source being dragged and drop target
    const srcEl = JSON.parse(e.dataTransfer.getData('srcEl'));
    const dstEl = this.props.metric;
    this.props.onDrag(srcEl, dstEl);
  }

  render() {
    const metric = this.props.metric;

    return (
      <React.Fragment>
        <div
          className="component open"
          draggable={this.state.isDragging}
          onDragStart={this.handleDragStart}
          onDragOver={this.handleDragOver}
          onDragEnd={this.handleDragEnd}
          onDrop={this.handleDrop}
        >
          <div className="row align-items-center">
            <div className="col-sm-4 font-weight-semibold">{metric.name}</div>
            <div className="col-sm-3">{this.renderResponseTime(metric)}</div>
            <div className="col-sm-3">{metric.source}</div>
            <div className="col-sm-2 text-right text-muted">
              <div className="dropdown d-inline-block mr-3">
                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="unstyled">
                  <i className="fas fa-fw fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={this.props.onEdit.bind(null, metric)}>
                    Edit
                  </a>
                  <a className="dropdown-item" href="#" onClick={this.props.onDelete.bind(null, metric)}>
                    Delete
                  </a>
                </div>
              </div>
              <i
                onMouseDown={this.handleDragHandleMouseDown}
                onClick={this.handleDragHandleClick}
                onMouseUp={this.handleDragHandleMouseUp}
                className="fas fa-grip-lines drag-handle"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderResponseTime() {
    // disable graph while dragging; ResponseTimeChart behaves really strange when being dragged
    if (this.state.isDragging) {
      return null;
    }
    return (
      <i
        className="fas fa-chart-bar fa-lg tooltip-cursor"
        data-toggle="tooltip"
        title="Response time metrics will be displayed."
      />
    );
  }
}

SystemMetric.defaultProps = {
  metric: null,
  onEdit: null,
  onDelete: null,
  onDrag: null,
};
