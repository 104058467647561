import {useState} from 'react';

const PromoCode = ({handleApply, error}) => {
  const [isEditing, setEditing] = useState(false);
  const [code, setCode] = useState('');
  if (error && !isEditing) {
    return null;
  }
  if (!isEditing) {
    return (
      <div className="PromoCode" onClick={() => setEditing(!isEditing)}>
        <i className="far fa-tags"></i> Use Promo Code
      </div>
    );
  }

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleApply({code});
      }}
    >
      <div className="form-group">
        <div className="input-group ">
          <input
            type="text"
            name="promo_code"
            className="form-control"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <div className="input-group-append">
            <button className="btn btn-secondary" type="submit">
              Apply
            </button>
          </div>
        </div>
        <span className="text-danger font-14">{error}</span>
      </div>
    </form>
  );
};

export default PromoCode;
