'use strict';

import React from 'react';
import Component from './Component.jsx';
import {CalculationType} from './Enums.jsx';

export default class ComponentHistory extends React.PureComponent {
  render() {
    const sp = this.props.statuspage;

    if (!sp.show_component_history || !sp.components.length) {
      return null;
    }

    const components = sp.components.map((component, i) => (
      <Component
        key={i}
        component={component}
        allowDrillDown={sp.allow_drill_down}
        showHistory={true}
        componentStatusRank={this.props.componentStatusRank}
        componentStatusChoices={this.props.componentStatusChoices}
        historyTypeDisplay={this.props.historyTypeDisplay}
        historyStartDate={this.props.historyStartDate}
        historyEndDate={this.props.historyEndDate}
      />
    ));

    return (
      <React.Fragment>
        <div className="component-list component-history overflow-x-auto">
          <div className="component-list-scroller">
            <div className="component-header">
              <div className="row align-items-end">
                {this.props.historyTypeDisplay === CalculationType.BY_CHECKS
                  ? this.renderCheckBasedHeaders()
                  : this.renderIncidentBasedHeaders()}
              </div>
            </div>
            {components}
            <div className="component-end"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCheckBasedHeaders() {
    return (
      <React.Fragment>
        <div className="col-4">Name</div>
        <div className="col-2 text-center">Uptime</div>
        <div className="col-2 text-center">Outages</div>
        <div className="col-2 text-center">Downtime</div>
        <div className="col-2 text-center">Avg Response Time</div>
      </React.Fragment>
    );
  }

  renderIncidentBasedHeaders() {
    return (
      <React.Fragment>
        <div className="col-4">Name</div>
        <div className="col-2 text-center">Major Outage</div>
        <div className="col-2 text-center">Partial Outage</div>
        <div className="col-2 text-center">Degraded Performance</div>
        <div className="col-2 text-center">Avg Response Time</div>
      </React.Fragment>
    );
  }
}

ComponentHistory.defaultProps = {
  statuspage: null,
  componentStatusRank: null,
  historyTypeDisplay: null,
  historyStartDate: null,
  historyEndDate: null,
};
