import _ from 'underscore';

/**
 * Validator functions for the different field types.
 */
var Validators = {
  /**
   * Creates a successful validation result.
   */
  _validationSuccessful: function (serializedValue) {
    return {
      error: null,
      serializedValue: serializedValue,
    };
  },

  /**
   * Creates a failed validation result.
   */
  _validationFailed: function (error, serializedValue) {
    return {
      error: error,
      serializedValue: serializedValue || '',
    };
  },

  /**
   * Validates an arbitrary field type.
   */
  validateField: function (field, value) {
    switch (field.type) {
      case 'KEY_VALUE':
        return this.validateKeyValueField(value, field.name);

      case 'REGEX':
        return this.validateRegexField(value);

      case 'VARIABLE':
        return this.validateVariableField(value);

      default:
        return this._validationSuccessful(value);
    }
  },

  /**
   * Deserializes a field value to text format if necessary.
   */
  deserializeField: function (fieldType, value) {
    switch (fieldType) {
      case 'KEY_VALUE':
        return this.deserializeKeyValueField(value);

      default:
        return value;
    }
  },

  /**
   * Validates a key-value field (eg HTTP headers) input
   * as a text area in Key: Value format, one per line.
   */
  _KEY_VALUE_SPLIT_RE: /\s*\n/,
  _KEY_VALUE_VALID_RE: /^\s*([\w-]+)\s*:\s*(.*)$/,
  _KEY_VALUE_ERROR_DICT: {
    form: 'Please enter one field per line in Name: Value format.',
    files: 'Please enter one file per line in Name: Value format.',
    headers: 'Please enter one header per line in Name: Value format.',
  },

  validateKeyValueField: function (value, key_value_field_name) {
    if (!value || !value.trim()) {
      return this._validationSuccessful({});
    }

    var headers = value.split(this._KEY_VALUE_SPLIT_RE);
    headers = _.filter(headers, function (s) {
      return !!s.trim();
    });
    var matches = _.map(
      headers,
      function (s) {
        return this._KEY_VALUE_VALID_RE.exec(s);
      }.bind(this)
    );
    matches = _.filter(matches, function (s) {
      return !!s;
    });

    if (matches.length !== headers.length) {
      return this._validationFailed(
        this._KEY_VALUE_ERROR_DICT[key_value_field_name],
        _.object(
          _.map(matches, function (m) {
            return [m[1], m[2]];
          })
        )
      );
    }

    return this._validationSuccessful(
      _.object(
        _.map(matches, function (m) {
          return [m[1], m[2]];
        })
      )
    );
  },

  /**
   * Deserialize a key-value field from object form to Name: Value form.
   */
  deserializeKeyValueField: function (obj) {
    return _.map(obj, function (v, k) {
      return k + ': ' + v;
    }).join('\n');
  },

  /**
   * Validates a regex field.
   */
  validateRegexField: function (value) {
    if (!value) {
      return '';
    }

    try {
      new RegExp(value);
    } catch (e) {
      return this._validationFailed(e.toString());
    }

    return this._validationSuccessful(value);
  },

  /**
   * Validates a variable name
   */
  validateVariableField: function (name) {
    if (/^\w+$/.test(name)) {
      return this._validationSuccessful(name);
    } else {
      name = name.replace(/[^\w]/g, '');
      return this._validationFailed('A variable name can only include letters, numbers or underscores.', name);
    }
  },
};

export default Validators;
