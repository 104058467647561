'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import {uniqueHtmlId, unpackFormLinkOrProps, CustomEvent, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export class DurationInputWidget extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = props.htmlId || uniqueHtmlId(props.fieldName);
    Utils.autoBindClass(this);

    this.state = {
      isEditingMins: false,
    };
  }

  _clamp(n, min, max) {
    n = parseInt(n) || 0;
    return Math.min(Math.max(n, min), max);
  }

  _splitMinsToText(totalMins) {
    if (!totalMins && !this.state.isEditingMins) {
      return {hours: '', mins: ''};
    }

    totalMins = Math.floor(totalMins);
    const mins = totalMins % 60;

    return {
      hours: Math.floor(totalMins / 60).toString(),
      mins: this.state.isEditingMins ? mins.toString() : mins.toString().padStart(2, '0'),
    };
  }

  _joinHoursMins(hours, mins) {
    return (parseInt(hours) || 0) * 60 + (parseInt(mins) || 0);
  }

  handleBlur() {
    this.setState({isEditingMins: false});
  }

  handleChange(e) {
    if (e.target === this.refs.mins) {
      this.setState({isEditingMins: true});
    }

    let hours = this.refs.hours.value;
    let mins = this.refs.mins.value;

    if (hours !== '' || mins !== '') {
      hours = this._clamp(hours, 0, this.props.upperLimit);
      mins = this._clamp(mins, 0, 59);
    }

    const form = unpackFormLinkOrProps(this.props);
    const val = this._joinHoursMins(hours, mins);
    const event = new CustomEvent({type: 'change', target: this, value: val});
    form.onChange(event);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);
    const hoursMins = this._splitMinsToText(form.value);

    return (
      <div className="d-flex justify-content-between align-items-top">
        <div>
          <input
            ref="hours"
            type="text"
            id={this.htmlId + '-hours'}
            name={form.fieldName + '-hours'}
            className={ReactUtils.cssClass('form-control text-right', {['is-invalid']: form.errors})}
            placeholder={this.props.maxLength ? 'hhh' : 'hh'}
            disabled={this.props.disabled}
            maxLength={this.props.maxLength ? this.props.maxLength : '2'}
            value={hoursMins.hours}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
          <small className="form-text text-right text-muted mr-3">hours</small>
        </div>
        <span className="mt-2">&nbsp;:&nbsp;</span>
        <div>
          <input
            ref="mins"
            type="text"
            id={this.htmlId + '-mins'}
            name={form.fieldName + '-mins'}
            className={ReactUtils.cssClass('form-control text-right', {['is-invalid']: form.errors})}
            placeholder="mm"
            disabled={this.props.disabled}
            maxLength="2"
            value={hoursMins.mins}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
          <small className="form-text text-right text-muted mr-3">mins</small>
        </div>
      </div>
    );
  }
}

DurationInputWidget.defaultProps = {
  fieldName: '', // Field name returned in form data
  disabled: false, // Control is disabled?

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
};

export default class DurationInput extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className="form-group">
        <Label fieldId={this.htmlId + '-hours'} {...this.props} />
        <DurationInputWidget ref="widget" htmlId={this.htmlId} {...this.props} />
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

DurationInput.defaultProps = {
  // ...DurationInputWidget.defaultProps +
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
};
