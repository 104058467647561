import React from 'react';
import Modal from '../../jskit/react/Modal';
import ReactUtils from '../../jskit/react/ReactUtils';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import Select2 from '../../jskit/react/forms/Select2';
import CheckBox from '../../jskit/react/forms/CheckBox';
import Utils from '../../jskit/general/Utils';

const ADMIN = '50-ADMIN';
const WRITE = '30-WRITE';
const READ = '10-READ';

export default class AccountUsersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
    Utils.autoBindClass(this);
  }

  _allowPurchasePermissionToggle() {
    const formData = this.getFormData();
    return (
      formData && [ADMIN, WRITE].includes(formData.access_level) && !formData.is_primary && this.props.isPrimaryUser
    );
  }

  handleChangeAccessLevel() {
    const formData = this.getFormData();
    if (!formData) {
      return;
    }
    if (!this.props.isPrimaryUser) {
      return;
    }
    if (!this._allowPurchasePermissionToggle()) {
      this.setFormData({has_purchase_permission: false});
    }
  }

  getFormData() {
    return this.state.formData;
  }

  setFormData(state, callback) {
    const newFormData = Object.assign({}, this.getFormData(), state);
    this.state.formData = newFormData;
    this.setState({formData: newFormData}, callback);
  }

  showModal(initialFormData) {
    if (initialFormData) {
      this.setState({formData: initialFormData});
    }

    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this.getFormData();

    return (
      <Modal
        ref="modal"
        title={formData.id ? 'Edit Account User' : 'Add Account User'}
        size="lg"
        saveButton={this.props.onSave}
        cancelButton={true}
      >
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <FormErrors errors={this.props.errors.__all__} />
          <input type="hidden" name="id" value={formData.id || ''} />
          <div className="row">
            <div className="col-sm">
              <TextInput fieldName="first_name" labelText="First Name" isRequired={true} formLink={formLink} />
            </div>
            <div className="col-sm">
              <TextInput fieldName="last_name" labelText="Last Name" isRequired={true} formLink={formLink} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <TextInput fieldName="email" labelText="Email Address" isRequired={true} formLink={formLink} />
            </div>
            <div className="col-sm">
              <Select2
                fieldName="access_level"
                disabled={formData.is_primary}
                choices={this.props.accessLevelChoices}
                labelText="Access Level"
                isRequired={true}
                onChange={this.handleChangeAccessLevel}
                formLink={formLink}
              />
            </div>
          </div>
          <div className="row mb-2">
            {this.renderAssignedSubaccounts(formLink, formData)}
            {this.renderAddToContactGroups(formLink, formData)}
            <div className="col-sm-6">
              <Select2
                fieldName="require_two_factor"
                labelText="2FA Override"
                choices={this.props.twoFactorOverrideChoices}
                formLink={formLink}
                divCSSClass="mb-2"
                titleText="Assign settings to user which override account 2FA requirements."
              />
            </div>
          </div>

          <CheckBox
            fieldName="is_api_enabled"
            disabled={formData.is_primary}
            labelText="Enable API access"
            isRequired={false}
            formLink={formLink}
            divCSSClass="mb-2"
          />

          <CheckBox
            fieldName="is_mobile_enabled"
            disabled={this.props.mobileAccessDisabledAtAccount}
            labelText="Enable Mobile App access"
            isRequired={false}
            formLink={this.props.mobileAccessDisabledAtAccount ? null : formLink}
            value={this.props.mobileAccessDisabledAtAccount ? false : null}
            divCSSClass="mb-2"
          />

          <CheckBox
            fieldName="notify_paid_invoices"
            labelText="Send new invoices via email"
            isRequired={false}
            formLink={formLink}
            divCSSClass="mb-2"
          />

          <CheckBox
            fieldName="notify_important_updates"
            labelText="Notify me of important updates"
            isRequired={false}
            formLink={formLink}
            divCSSClass="mb-2"
          />
          {this.props.hasCanPurchaseFeature && (
            <CheckBox
              fieldName="has_purchase_permission"
              labelText="Allow add-on purchases"
              isRequired={false}
              formLink={formLink}
              divCSSClass="mb-2"
              disabled={!this._allowPurchasePermissionToggle()}
            />
          )}
          <div className="row">
            <div className="col-sm">{AccountUsersForm.renderPasswordHelpText(formLink, formData)}</div>
          </div>
        </form>
      </Modal>
    );
  }

  static renderPasswordHelpText(formLink, formData) {
    if (formData.id) {
      if (formData.is_current_user) {
        return (
          <div className="help-text" style={{padding: '10px'}}>
            Please <a href="/accounts/change-password">click here</a> if you need to change your password.
          </div>
        );
      } else {
        return '';
      }
    } else {
      return (
        <div className="help-text">
          An email invite will be sent to this address <br />
          with a link for creating a password.
        </div>
      );
    }
  }

  renderAssignedSubaccounts(formLink, formData) {
    if (!this.props.subaccountChoices) {
      return null;
    }

    const allSubaccounts = formData.is_primary || formData.access_level === ADMIN;

    return (
      <div className="col-sm-6">
        <Select2
          fieldName="assigned_subaccounts"
          choices={this.props.subaccountChoices}
          labelText="Subaccounts"
          multiple={true}
          titleText="Which subaccounts this user may access. Admins can always access all subaccounts."
          helpText={allSubaccounts ? 'This user will have access to all subaccounts.' : null}
          disabled={allSubaccounts}
          isRequired={false}
          formLink={formLink}
          placeholder="All Subaccounts"
        />
      </div>
    );
  }

  renderAddToContactGroups(formLink, formData) {
    if (formData.id) {
      return null;
    }

    return (
      <div className="col-sm-6">
        <Select2
          fieldName="contact_groups"
          choices={this.props.contactChoices}
          labelText="Add To Contacts"
          placeholder="Select Contacts"
          isRequired={false}
          multiple={true}
          formLink={formLink}
        />
      </div>
    );
  }
}
