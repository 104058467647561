import React from 'react';
import QuickActions from '../../devices/services/QuickActions';
import {PageType} from '../../status_pages/manage/Enums';

class StatusPageRow extends React.PureComponent {
  render() {
    const actions = [];
    let pageName = '';
    if (this.props.page_type === PageType.PUBLIC) {
      pageName = 'Public';
    }
    if (this.props.page_type === PageType.PUBLIC_SLA) {
      pageName = 'SLA';
    }
    if (this.props.hasWriteAccess) {
      actions.push({
        className: 'fa-pen-to-square',
        text: `Edit ${pageName} Page`,
        href: this.props.manage_url,
      });
      actions.push({
        className: 'fa-file-exclamation',
        text: 'Add incident',
        href: this.props.manage_url + '?new_incident',
      });
      actions.push({
        className: 'fa-calendar-plus',
        text: 'Schedule Maintenance',
        href: this.props.manage_url + '?new_maintenance',
      });
      actions.push({
        onClick: this.props.onDuplicate,
        className: 'fa-copy',
        text: 'Duplicate',
      });
      if (this.props.hasAdminAccess) {
        actions.push({
          text: 'Delete',
          className: 'fa-trash',
          onClick: this.props.onDelete,
        });
      }
    }

    var publicLinks = null;
    if (this.props.public_url) {
      var url = this.props.siteURL + this.props.public_url;
      publicLinks = [
        <a key="publink" target="_blank" href={url}>
          {url}
        </a>,
      ];

      if (this.props.cname_url) {
        publicLinks.push(<br key="linebreak" />);
        publicLinks.push(
          <a key="cnamelink" target="_blank" href={this.props.cname_url}>
            {this.props.cname_url}
          </a>
        );
      }
    } else {
      publicLinks = <em className="text-muted">Not published</em>;
    }

    return (
      <tr>
        <td>
          <a href={this.props.private_url}>{this.props.name}</a>
        </td>
        <td>{publicLinks}</td>
        <td>{this.props.subscribers_count}</td>
        <td className="actions-right">
          <QuickActions actions={actions} />
        </td>
      </tr>
    );
  }
}

export default class StatusPageList extends React.PureComponent {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return this.renderEmptyState();
    }

    const nodes = this.props.items.map((item, index) => (
      <StatusPageRow
        {...item}
        key={item.id}
        hasWriteAccess={this.props.hasWriteAccess}
        hasAdminAccess={this.props.hasAdminAccess}
        siteURL={this.props.siteURL}
        onDuplicate={this.props.onDuplicate.bind(null, index)}
        onDelete={this.props.onDelete.bind(null, index)}
      />
    ));

    return (
      <div className="white-block">
        <table className="table table-responsive-sm data-table" role="grid">
          <thead>
            <tr>
              <th>Name</th>
              <th>Public URL</th>
              <th>Subscribers</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }

  renderEmptyState() {
    return <div>No status pages of this type created.</div>;
  }
}
