'use strict';

import _ from 'underscore';
import React from 'react';
import Utils from '../jskit/general/Utils';
import Ajax from '../jskit/general/Ajax';

export default class PushNotificationsController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      debugLog: [],
      isOneSignalInitialized: false,
      isSubscribed: false,
      oneSignalUserId: null,
      contacts: null,
      contactsSubscribed: null,
    };

    this.debouncedUpdateSubscriptionContacts = _.debounce(this.updateSubscriptionContacts, 2000).bind(this);
  }

  componentDidMount() {
    this.loadAllContacts();
    window.OneSignal.isPushNotificationsEnabled(this.handleOneSignalInitialized);
    window.OneSignal.on('subscriptionChange', this.handleOneSignalSubscriptionChanged);
  }

  componentWillUnmount() {
    window.OneSignal.off('subscriptionChange');
  }

  writeLog(msg) {
    this.setState({debugLog: this.state.debugLog.concat([msg])});
  }

  isInitialized() {
    return this.state.isOneSignalInitialized && this.state.contacts !== null && this.state.contactsSubscribed !== null;
  }

  loadAllContacts() {
    new Ajax().get({
      url: this.props.urls.listContacts + '?page_size=250',
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({contacts: data.results.map((x) => x.name)});
        this.writeLog(`API: Loaded ${data.results.length} contacts.`);
      }.bind(this),
    });
  }

  loadSubscribedContacts(uuid) {
    if (uuid === null) {
      this.setState({contactsSubscribed: {}});
      return;
    }

    new Ajax().get({
      url: this.props.urls.getOrUpdatePushNotification.replace('0000', uuid),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({contactsSubscribed: _.object(data.contact_groups, data.contact_groups)});
        this.writeLog(`API: Loaded ${data.contact_groups.length} subscribed contacts.`);
      }.bind(this),
      error: function () {
        this.writeLog('API: ERROR: Failed to find subscription, reverting to unsubscribed.');
        this.setState({contactsSubscribed: {}, isSubscribed: false});
        window.OneSignal.setSubscription(false);
      }.bind(this),
    });
  }

  registerNewSubscription(uuid, deviceName, contacts) {
    this.setState({contactsSubscribed: _.object(contacts, contacts)});

    new Ajax().post({
      url: this.props.urls.registerPushNotification,
      encoder: 'json',
      decoder: 'json',
      data: {
        app_key: this.props.appKey,
        uuid: uuid,
        device_name: deviceName,
        contact_groups: contacts,
      },
      success: function (data) {
        this.setState({contactsSubscribed: _.object(data.results.contact_groups, data.results.contact_groups)});
        this.writeLog(
          `API: Registered new subscription with ${data.results.contact_groups.length} subscribed contacts.`
        );
      }.bind(this),
      error: function () {
        this.writeLog(`API: ERROR: Failed registering new subscription ${uuid}.`);
      }.bind(this),
    });
  }

  deleteSubscription(uuid) {
    new Ajax().sendRequest({
      method: 'DELETE',
      url: this.props.urls.getOrUpdatePushNotification.replace('0000', uuid),
      encoder: 'json',
      decoder: 'json',
      success: function () {
        this.setState({contactsSubscribed: {}});
        this.writeLog(`API: Deleted subscription ${uuid}`);
      }.bind(this),
      error: function () {
        this.writeLog(`API: ERROR: Failed to delete subscription ${uuid}.`);
      }.bind(this),
    });
  }

  updateSubscriptionContacts() {
    const uuid = this.state.oneSignalUserId;
    const contacts = _.keys(this.state.contactsSubscribed);

    new Ajax().sendRequest({
      method: 'PATCH',
      url: this.props.urls.getOrUpdatePushNotification.replace('0000', uuid),
      encoder: 'json',
      decoder: 'json',
      data: {
        contact_groups: contacts,
      },
      success: function (data) {
        this.setState({contactsSubscribed: _.object(data.results.contact_groups, data.results.contact_groups)});
        this.writeLog(`API: Updated subscription ${uuid} with ${data.results.contact_groups.length} contacts.`);
      }.bind(this),
      error: function () {
        this.writeLog(`API: ERROR: Failed to update subscription ${uuid}.`);
      }.bind(this),
    });
  }

  handleOneSignalInitialized(isSubscribed) {
    window.OneSignal.getUserId(
      function (oneSignalUserId) {
        this.setState({
          isOneSignalInitialized: true,
          isSubscribed: isSubscribed,
          oneSignalUserId: oneSignalUserId,
        });

        this.writeLog(`OneSignal Init: Subscribed: ${isSubscribed} / UserId: ${oneSignalUserId}`);
        this.loadSubscribedContacts(isSubscribed ? oneSignalUserId : null);
      }.bind(this)
    );
  }

  handleOneSignalSubscriptionChanged(isSubscribed) {
    if (isSubscribed) {
      window.OneSignal.getUserId(
        function (oneSignalUserId) {
          let browser = window.navigator.userAgent.match(/Edge|OPR|Firefox|Chrome|Safari/);
          browser = 'Desktop ' + (browser || 'Browser');
          window.OneSignal.sendTags({
            userId: this.props.userId,
            device: browser,
          });

          this.setState({isSubscribed: true, oneSignalUserId: oneSignalUserId});
          this.writeLog('OneSignal: Subscribed: ' + oneSignalUserId + ' / ' + browser);
          this.registerNewSubscription(oneSignalUserId, browser, this.state.contacts);
        }.bind(this)
      );
    } else {
      window.OneSignal.setSubscription(false);

      this.setState({isSubscribed: false});
      this.writeLog('OneSignal: Unsubscribed');
      this.deleteSubscription(this.state.oneSignalUserId);
    }
  }

  handleSubscribeCheckboxChange(e) {
    if (e.target.checked) {
      window.OneSignal.showNativePrompt();
      window.OneSignal.setSubscription(true);
    } else {
      window.OneSignal.setSubscription(false);
    }
  }

  handleContactCheckboxChange(e) {
    const contact = e.target.value;
    const newState = e.target.checked;
    const newSub = newState
      ? _.extend({}, this.state.contactsSubscribed, {[contact]: contact})
      : _.omit(this.state.contactsSubscribed, contact);

    this.setState({contactsSubscribed: newSub}, this.debouncedUpdateSubscriptionContacts);
  }

  handleSelectAllContacts(e) {
    e.preventDefault();
    this.setState(
      {contactsSubscribed: _.object(this.state.contacts, this.state.contacts)},
      this.debouncedUpdateSubscriptionContacts
    );
  }

  handleClearAllContacts(e) {
    e.preventDefault();
    this.setState({contactsSubscribed: []}, this.debouncedUpdateSubscriptionContacts);
  }

  render() {
    return (
      <React.Fragment>
        <div style={{float: 'left', width: '500px', marginRight: '40px'}}>
          {this.isInitialized() ? this.renderPushNotificationsPanel() : <span>&nbsp;</span>}
        </div>
        <div style={{float: 'left', width: '500px'}}>{this.renderDebugLog()}</div>
      </React.Fragment>
    );
  }

  renderPushNotificationsPanel() {
    return (
      <React.Fragment>
        <div style={{border: '1px solid #ccc', borderRadius: '3px', padding: '15px'}}>
          <p>
            Turn on push notifications and select which contacts to subcribe to. You will then receive browser desktop
            notifications for any downtime alerts sent to those contacts,
            <strong>
              <em> as long as this page is open</em>
            </strong>
            .
          </p>
          <div>
            <label style={{fontSize: '15px', fontWeight: 'bold'}}>
              Enable Push Notifications:&nbsp;&nbsp;
              <input type="checkbox" checked={this.state.isSubscribed} onChange={this.handleSubscribeCheckboxChange} />
            </label>
          </div>
          {this.state.isSubscribed ? this.renderContacts() : null}
        </div>
        <div>
          <small>OneSignal UserId: {this.state.oneSignalUserId || '<none>'}</small>
        </div>
      </React.Fragment>
    );
  }

  renderContacts() {
    return (
      <div className="spacer-medium">
        <h3>Subscribe to Contacts:</h3>
        <div>
          <a href="" onClick={this.handleSelectAllContacts}>
            <small>all</small>
          </a>
          &nbsp;|&nbsp;
          <a href="" onClick={this.handleClearAllContacts}>
            <small>none</small>
          </a>
        </div>
        <ul className="list-unstyled spacer-tiny">{this.state.contacts.map(this.renderContact)}</ul>
      </div>
    );
  }

  renderContact(contact) {
    return (
      <li key={contact}>
        <label>
          <input
            type="checkbox"
            value={contact}
            checked={!!this.state.contactsSubscribed[contact]}
            onChange={this.handleContactCheckboxChange}
          />{' '}
          {contact}
        </label>
      </li>
    );
  }

  renderDebugLog() {
    return (
      <div style={{border: '1px solid #ccc', borderRadius: '3px', padding: '15px'}}>
        <h3 className="spacer-none">Debug Log</h3>
        <ul className="list-unstyled">
          {this.state.debugLog.map((x, i) => (
            <li key={i}>
              <small>{x}</small>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
