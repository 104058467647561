'use strict';

import React from 'react';
import ResponseTimeChart from '../../jskit/react/ResponseTimeChart';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';

export default class SystemMetric extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      datapoints_time_start_ts: 0,
      datapoints_time_end_ts: 0,
      datapoints: [],
      timerange: this.props.metricTimeRange,
    };
  }

  componentDidMount() {
    this.handleTimeRangeChange(this.props.metric.id, this.state.timerange);
  }

  handleTimeRangeChange(metricId, timerange) {
    new Ajax().get({
      url: this.props.datapointsURL + '/' + metricId,
      data: timerange ? {timerange: timerange} : null,
      decoder: 'json',
      success: (data) => {
        if (data.success) {
          this.setState({
            ...data.data,
            timerange,
          });
        }
      },
    });
  }

  render() {
    const metric = this.props.metric;
    const timerange = this.state.timerange;
    const makeCss = (t) => 'px-4 btn btn-sm ' + (t === timerange ? 'btn-primary' : 'btn-white');
    const makeHandler = (t) => this.handleTimeRangeChange.bind(null, metric.id, t);
    return (
      <div className="pb-3">
        <div className="d-md-flex align-items-baseline justify-content-between">
          <h5 className="mt-4">{metric.name}</h5>
          <div className="btn-group mb-2" role="group" aria-label="Period Selection">
            <button type="button" className={makeCss(null)} onClick={makeHandler(null)}>
              Day
            </button>
            <button type="button" className={makeCss('week')} onClick={makeHandler('week')}>
              Week
            </button>
            <button type="button" className={makeCss('month')} onClick={makeHandler('month')}>
              Month
            </button>
          </div>
        </div>
        <ResponseTimeChart
          height={225}
          data={this.state.datapoints}
          min={this.state.datapoints_time_start_ts * 1000}
          max={this.state.datapoints_time_end_ts * 1000}
          isLarge
        />
      </div>
    );
  }
}
