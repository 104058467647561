'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import CheckBox from '../../jskit/react/forms/CheckBox';
import SelectBox from '../../jskit/react/forms/SelectBox';
import Select2 from '../../jskit/react/forms/Select2';
import ShowPassword from '../../jskit/react/forms/ShowPassword';
import TextInput from '../../jskit/react/forms/TextInput';
import TextArea from '../../jskit/react/forms/TextArea';
import {markdownGuideURL} from './ManageUtils.jsx';
import {PageType} from './Enums.jsx';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';

const Pages = {
  PAGE_GLOBAL: 'page_global',
  PAGE_STATUS: 'page_status',
  PAGE_HISTORY: 'page_history',
};

const FormDocumentation = {
  [PageType.PUBLIC]: {
    description:
      'A Public Status Page conveys information on scheduled maintenance, ' +
      'downtime events, and general system uptime to your site or service users.',
    supportLink: 'https://support.uptime.com/hc/en-us/articles/360016373219-Public-Status-Page',
  },
  [PageType.PUBLIC_SLA]: {
    description:
      'The Public SLA Page allows users to share historical uptime percentage for direct ' +
      'visibility into the state of your Uptime.com checks and services.',
    supportLink: 'https://support.uptime.com/hc/en-us/articles/360016346700-Public-SLA',
  },
  [PageType.INTERNAL]: {
    description:
      'An Internal Status Page allows devops and your designated Uptime.com account users to ' +
      'share outages, incidents, maintenance schedules, and uptime statistics internally.',
    supportLink: 'https://support.uptime.com/hc/en-us/articles/360016373679-Internal-Status-Page',
  },
};

export default class SettingsForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.ALL_PAGES = [
      [Pages.PAGE_GLOBAL, 'Global'],
      [Pages.PAGE_STATUS, 'Current Status'],
      [Pages.PAGE_HISTORY, 'History & Incidents'],
    ];

    this.state = {
      currentPage: null,
      formData: {},
      validationErrors: {},
      currentSelectedVisibilityLevel: this.props.currentVisibilityLevel,
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal() {
    // setting the default timezone to the account timezone
    const formTimezone = {
      timezone: this.props.statuspage.timezone ? this.props.statuspage.timezone : this.props.accountTimezone,
    };
    const formData = {...this.props.statuspage, ...formTimezone};
    this.setState({
      currentPage: Pages.PAGE_GLOBAL,
      formData: formData,
    });
    this.clearValidationErrors();
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handlePageChange(page) {
    this.setState({currentPage: page});
  }

  handleSubmit(e) {
    e.preventDefault();

    this.clearValidationErrors();
    new Ajax().post({
      url: this.props.updateURL,
      data: this.getFormData(),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.props.onNewData(data.data);
          this.hideModal();
          window.location.reload();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    const formData = this.getFormData();
    return (
      <Modal ref="modal" title="Settings" size="lg" saveButton={this.handleSubmit} cancelButton={true}>
        <form ref="form" className="form-spaced" onSubmit={this.handleSubmit}>
          <SectionNav
            sectionIds={this.ALL_PAGES}
            currentSectionId={this.state.currentPage}
            errors={this.state.validationErrors}
            gridClass="col-lg-12"
            navClass="nav nav-pills nav-pills-buttons nav-justified mb-4"
            onSectionNav={this.handlePageChange}
          >
            <SectionNavContent sectionId={Pages.PAGE_GLOBAL} currentSectionId={this.state.currentPage}>
              {this.renderGlobalForm(formLink, formData)}
            </SectionNavContent>
            <SectionNavContent sectionId={Pages.PAGE_STATUS} currentSectionId={this.state.currentPage}>
              {this.renderStatusForm(formLink, formData)}
            </SectionNavContent>
            <SectionNavContent sectionId={Pages.PAGE_HISTORY} currentSectionId={this.state.currentPage}>
              {this.renderHistoryForm(formLink, formData)}
            </SectionNavContent>
          </SectionNav>
        </form>
      </Modal>
    );
  }

  renderGlobalForm(formLink, formData) {
    const sp = this.props.statuspage;
    const documentation = FormDocumentation[sp.page_type];
    return (
      <React.Fragment>
        <FormErrors errors={this.state.validationErrors.__all__} />
        <div className="row mb-4">
          <div className="col-lg-12 font-14">
            {documentation.description}&nbsp;
            <ZendeskSupportLink href={documentation.supportLink}>
              View&nbsp;our&nbsp;documentation&nbsp;here&nbsp;&rarr;
            </ZendeskSupportLink>
          </div>
        </div>
        <TextInput fieldName="name" labelText="Status Page Name" isRequired={true} formLink={formLink} />
        <TextArea
          fieldName="description"
          labelText="Status Page Description"
          titleText="This text is displayed at the top of the page for your visitors."
          isRequired={false}
          helpText={'<a href="' + markdownGuideURL + '" target="_blank">Markdown</a> supported.'}
          formLink={formLink}
        />
        <div className="form-group mb-0">
          <div className="row">
            {this.props.saml_settings.enabled || this.props.visibilityLevels.length > 0 ? (
              <div className="col-md col-lg-6">
                <Select2
                  fieldName="visibility_level"
                  choices={this.props.visibilityLevels}
                  labelText="Visibility Level"
                  isRequired={false}
                  formLink={formLink}
                  onChange={(e) => {
                    this.setState({currentSelectedVisibilityLevel: e.target.value});
                  }}
                  defaultValue={this.props.currentVisibilityLevel}
                />
              </div>
            ) : (
              ''
            )}
            <div className="col-md col-lg-4">
              <Select2
                fieldName="timezone"
                choices={this.props.choices.timezones}
                labelText="Timezone"
                isRequired={false}
                formLink={formLink}
                titleText="Excludes DST"
                onChange={(e) => {
                  this.setState({currentSelectedTimeZone: e.target.value});
                }}
              />
            </div>
            <div className="col-md col-lg-2 px-0">
              <Select2
                fieldName="time_format"
                choices={this.props.choices.timeFormat}
                labelText="Time Format"
                titleText="Select the time format that will be shown for events created on Status Page."
                isRequired={false}
                formLink={formLink}
              />
            </div>
          </div>
        </div>
        <div className="form-group mb-0">
          <CheckBox
            fieldName="allow_subscriptions"
            labelText="Allow users to subscribe to this page"
            titleText="Enabling the below subscription options will enable each subscription method on your Status Page to your users."
            isRequired={false}
            divCSSClass="mb-2 non-bold-errors"
            suppressErrorClass={true}
            formLink={formLink}
          />
          <div className="row mx-0">
            <div className="pl-4">
              <CheckBox
                fieldName="allow_subscriptions_email"
                labelText="Email"
                isRequired={false}
                divCSSClass="mb-2"
                formLink={formLink}
                disabled={!formData.allow_subscriptions}
              />
            </div>
            <div className="pl-4">
              <CheckBox
                fieldName="allow_subscriptions_rss"
                labelText="RSS"
                isRequired={false}
                divCSSClass="mb-2"
                formLink={formLink}
                disabled={!formData.allow_subscriptions}
              />
            </div>
            <div className="pl-4">
              <CheckBox
                fieldName="allow_subscriptions_slack"
                labelText="Slack"
                isRequired={false}
                divCSSClass="mb-2"
                formLink={formLink}
                disabled={!formData.allow_subscriptions}
              />
            </div>
            <div className="pl-4">
              <CheckBox
                fieldName="allow_subscriptions_sms"
                labelText="SMS"
                isRequired={false}
                divCSSClass="mb-2"
                formLink={formLink}
                disabled={!formData.allow_subscriptions}
              />
            </div>
            <div className="pl-4">
              <CheckBox
                fieldName="allow_subscriptions_webhook"
                labelText="Webhook"
                isRequired={false}
                divCSSClass="mb-2"
                formLink={formLink}
                disabled={!formData.allow_subscriptions}
              />
            </div>
          </div>
        </div>

        {this.state.currentSelectedVisibilityLevel === 'UPTIME_USERS' ? null : (
          <React.Fragment>
            <hr className="my-4" />
            <div className="row">
              <div className="col-lg-6">
                <TextInput
                  fieldName="slug"
                  labelText="URL Slug"
                  isRequired={true}
                  titleText="This is the URL path/foldername that you can customize
                              (letters, numbers and hyphens only)."
                  formLink={formLink}
                />
              </div>
              <div className="col-lg-6">
                <TextInput
                  fieldName="cname"
                  labelText="CNAME"
                  isRequired={false}
                  titleText={
                    'OPTIONAL: Create a CNAME record for your subdomain pointing to "' +
                    this.props.cnameURL.split('//')[1] +
                    '" and it will display this status page.'
                  }
                  formLink={formLink}
                />
              </div>
              <div className="col-lg-12">
                <TextInput
                  fieldName="public_url"
                  labelText="Public URL"
                  isRequired={true}
                  disabled={true}
                  value={this.props.siteURL + '/statuspage/' + (formData.slug || '')}
                />
              </div>
              {this.state.currentSelectedVisibilityLevel === 'PUBLIC' ? (
                <React.Fragment>
                  <div className="col-lg-6">
                    <TextInput
                      fieldName="auth_username"
                      labelText="Username"
                      isRequired={false}
                      formLink={formLink}
                      titleText="OPTIONAL: Secure this status page using HTTP Basic Auth with this username."
                    />
                  </div>
                  <div className="col-lg-6">
                    <ShowPassword
                      fieldName="auth_password"
                      labelText="Password"
                      isRequired={false}
                      formLink={formLink}
                      titleText="OPTIONAL: Secure this status page using HTTP Basic Auth with this password."
                    />
                  </div>
                </React.Fragment>
              ) : null}
            </div>
            <div className="form-group">
              <CheckBox
                fieldName="allow_search_indexing"
                labelText="Allow search engine indexing"
                titleText="Turn off to add a HTML header requesting search engines to not index your page."
                isRequired={false}
                divCSSClass="mb-2"
                formLink={formLink}
              />
              <CheckBox
                fieldName="allow_drill_down"
                labelText="Allow visitor drill down into individual checks"
                titleText="Allow visitors to view performance graphs and reports of check-based components."
                isRequired={false}
                formLink={formLink}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderStatusForm(formLink, formData) {
    return (
      <React.Fragment>
        <div className="form-group">
          <CheckBox
            fieldName="show_status_tab"
            labelText='Display "Current Status" tab on your status page'
            titleText="Page visitors can view component status, response time graphs, active incidents & system metrics."
            isRequired={true}
            formLink={formLink}
          />
        </div>
        {formData.show_status_tab ? this.renderShowStatusSettings(formLink, formData) : null}
      </React.Fragment>
    );
  }

  renderShowStatusSettings(formLink) {
    return (
      <React.Fragment>
        <hr className="my-4" />
        <div className="form-group">
          <CheckBox
            fieldName="show_active_incidents"
            labelText="Display active incidents and scheduled maintenance"
            isRequired={false}
            divCSSClass="mb-2"
            formLink={formLink}
          />
          <CheckBox
            fieldName="show_component_response_time"
            labelText='Display components&apos; "Response Time" graph in the "Current Status" table'
            isRequired={false}
            formLink={formLink}
          />
        </div>
      </React.Fragment>
    );
  }

  renderHistoryForm(formLink, formData) {
    return (
      <React.Fragment>
        <div className="form-group">
          <CheckBox
            fieldName="show_history_tab"
            labelText='Display "History &amp; Incidents" tab on your status page'
            titleText="Anyone can view historic graphs, metrics, component history, and past incidents."
            isRequired={true}
            formLink={formLink}
          />
        </div>
        {formData.show_history_tab ? this.renderShowHistorySettings(formLink, formData) : null}
      </React.Fragment>
    );
  }

  renderShowHistorySettings(formLink) {
    return (
      <React.Fragment>
        <hr className="my-4" />
        <div className="row">
          <div className="col-lg-6">
            <TextInput
              fieldName="default_history_date_range"
              labelText="Default date range (days)"
              isRequired={true}
              formLink={formLink}
            />
          </div>
          <div className="col-lg-6">
            <SelectBox
              fieldName="uptime_calculation_type"
              labelText="Uptime calculated by"
              titleText="Select the value that calculates global uptime;
                                incident durations or the uptime percent of check-based components."
              isRequired={true}
              choices={this.props.choices.uptimeCalculationType}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="form-group">
          <p className="mb-2">
            <strong>Display Options:</strong>
          </p>
          <CheckBox
            fieldName="allow_pdf_report"
            labelText="Enable PDF report download"
            titleText="Enable PDF download of History & Incidents report."
            isRequired={false}
            divCSSClass="mb-2"
            formLink={formLink}
          />
          <CheckBox
            fieldName="show_history_snake"
            labelText='Graph: "History - all components"'
            titleText="Enable an interactive visualization of your components’ availability history."
            isRequired={false}
            divCSSClass="mb-2"
            formLink={formLink}
          />
          <CheckBox
            fieldName="show_summary_metrics"
            labelText="Summary metrics (Global Uptime, Outages..)"
            titleText="Allow page visitors to see Global Uptime, Outages and Total Downtime."
            isRequired={false}
            divCSSClass="mb-2"
            formLink={formLink}
          />
          <CheckBox
            fieldName="show_component_history"
            labelText='"Component History" table'
            titleText="Display a table of components’ historical metrics for the selected period."
            isRequired={false}
            divCSSClass="mb-2"
            formLink={formLink}
          />
          <CheckBox
            fieldName="show_past_incidents"
            labelText="Past Incidents"
            titleText="Display past incidents for the selected period."
            isRequired={false}
            formLink={formLink}
          />
        </div>
      </React.Fragment>
    );
  }
}

SettingsForm.defaultProps = {
  updateURL: null,
  cnameURL: null,
  choices: {},
  statuspage: null,
  onNewData: null,
};
