/**
 * Utility functions.
 */

'use strict';

var Utils = {};

/**
 * Escape any <script> tags in the given string so they are not processed
 * if the string is rendered as HTML.
 */
Utils.escapeScripts = function (str) {
  return str.replaceAll(/<(\/?)(script)/gi, '\\x3C$1$2');
};

/**
 * TODO: Move this into a proper unit testing lib
 */
Utils.testEscapeScripts = function () {
  const assertEqual = (x, y) => (x === y ? null : console.error(`${x} !== ${y}`));

  assertEqual(Utils.escapeScripts('<div>'), '<div>');
  assertEqual(Utils.escapeScripts('</div>'), '</div>');
  assertEqual(Utils.escapeScripts('script'), 'script');
  assertEqual(Utils.escapeScripts('<script>'), '\\x3Cscript>');
  assertEqual(Utils.escapeScripts('</script>'), '\\x3C/script>');
  assertEqual(Utils.escapeScripts('<script  >'), '\\x3Cscript  >');
  assertEqual(Utils.escapeScripts('</script  >'), '\\x3C/script  >');
  assertEqual(Utils.escapeScripts('<script a="1">'), '\\x3Cscript a="1">');
  assertEqual(Utils.escapeScripts('</script a="1">'), '\\x3C/script a="1">');
  assertEqual(Utils.escapeScripts('</script><script></script>'), '\\x3C/script>\\x3Cscript>\\x3C/script>');
  assertEqual(Utils.escapeScripts('</SCRIPT><SCRIPT></SCRIPT>'), '\\x3C/SCRIPT>\\x3CSCRIPT>\\x3C/SCRIPT>');
  assertEqual(Utils.escapeScripts('</ScRiPT><ScRiPT></ScRiPT>'), '\\x3C/ScRiPT>\\x3CScRiPT>\\x3C/ScRiPT>');
};

/**
 * Prompt box with text that can be copied.
 */
Utils.copyToClipboard = function (text, prompt) {
  prompt = prompt || 'Copy to clipboard: Ctrl+C, Enter';
  window.prompt(prompt, text);
  return false;
};

/**
 * Copy an arbitrary text to clipboard
 * @param text - text to copy
 */
Utils.setClipboard = function (text) {
  if (!navigator.clipboard) {
    // old browser
    // isn't compatible with bootstrap modal because modal steals focus by default with JS handler
    const el = document.createElement('textarea');
    // eslint-disable-next-line no-undef
    el.textContent = text;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Could not copy text: ', err);
    }
    document.body.removeChild(el);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      return undefined;
    },
    function (err) {
      console.error('Could not copy text: ', err);
    }
  );
};

/**
 * Get and JSON-decode a value from local storage
 */
Utils.loadFromLocalStorage = function (key) {
  return window.localStorage ? JSON.parse(window.localStorage.getItem(key)) : null;
};

/**
 * Get and JSON-decode a value from session storage
 */
Utils.loadFromSessionStorage = function (key) {
  return window.sessionStorage ? JSON.parse(window.sessionStorage.getItem(key)) : null;
};

/**
 * Set a value to local storage, after JSON-encoding it
 */
Utils.saveToLocalStorage = function (key, value) {
  if (window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

/**
 * Set a value to session storage, after JSON-encoding it
 */
Utils.saveToSessionStorage = function (key, value) {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

/**
 * Set a value to local storage, after JSON-encoding it.
 * If `value` is `undefined`, the key is cleared.
 */
Utils.saveToLocalStorage = function (key, value) {
  if (window.localStorage) {
    if (value !== undefined) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(key);
    }
  }
};

/**
 * Set a value to session storage, after JSON-encoding it
 * If `value` is `undefined`, the key is cleared.
 */
Utils.saveToSessionStorage = function (key, value) {
  if (window.sessionStorage) {
    if (value !== undefined) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      window.sessionStorage.removeItem(key);
    }
  }
};

/**
 * Returns the index of the last array element passing the predicate test.
 * Predicate receives (elem, index, arr)
 * Context is the optional value of `this` passed to the predicate function
 */
Utils.findLastIndex = function (arr, predicate, context) {
  if (context) {
    predicate = predicate.bind(context);
  }

  for (var i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i], i, arr)) {
      return i;
    }
  }

  return -1;
};

/**
 * Lists all methods names in the given class instance by looking
 * up the prototype chain. ES6 only.
 */
Utils.listClassMethodNames = function (instance, followPrototype) {
  let props = [];
  let obj = Object.getPrototypeOf(instance);
  const endPrototype = followPrototype ? Object.prototype : Object.getPrototypeOf(obj);

  while (obj !== endPrototype) {
    props = props.concat(Object.getOwnPropertyNames(obj));
    obj = Object.getPrototypeOf(obj);
  }

  return props.sort().filter((e, i, arr) => e != arr[i + 1] && typeof instance[e] == 'function');
};

/**
 * Automatically binds all methods of the given class instance to the instance.
 * Should be called from the constructor of an ES6 class.
 *
 * @param instance The class instance to bind to itself.
 * @param prefixes (array) If provided, only binds method whose names start with the given prefixes.
 * @param followPrototype If true, binds all methods up the prototype chain not just this class.
 */
Utils.autoBindClass = function (instance, options) {
  options = options || {};
  const followPrototype = options.followPrototype || false;
  const prefixes = options.prefixes || null;

  const methods = Utils.listClassMethodNames(instance, followPrototype);
  const filteredMethods = methods.filter(
    (methodName) =>
      methodName !== 'constructor' && (!prefixes || prefixes.some((prefix) => methodName.indexOf(prefix) === 0))
  );

  filteredMethods.forEach((methodName) => {
    instance[methodName] = instance[methodName].bind(instance);
  });

  return filteredMethods;
};

/**
 * Uses JSON conversion to deep-copy an object containing simple types.
 *
 * @param obj The object to clone.
 */
Utils.deepCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Sets the javascript window onbeforeunload handler to show a popup
 * before the user browses off the page.
 *
 * @param enabled True to enable the prompt, false to disable it.
 */
Utils.enableWindowUnloadPopup = function (enabled) {
  if (enabled) {
    window.onbeforeunload = function (e) {
      e.preventDefault();
      e.returnValue = true;
      return true;
    };
  } else {
    window.onbeforeunload = null;
  }
};

/**
 * Starts download of textual content as a file
 *
 * @param filename name of the file
 * @param text textual content
 */
Utils.downloadTextAsFile = function (filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

/**
 * Opens base64 encoded image in a new browser tab
 *
 * @param base64data base64 encoded image
 */
Utils.openBase64Image = function (base64data) {
  var imageData = 'data:image/png;base64,' + base64data;
  var win = window.open();
  win.document.write('<html><body><img src="' + imageData + '"></img></body></html>');
  win.document.close();
  return false;
};

/**
 * Toggle full-screen mode for the entire document.
 */
Utils.toggleFullScreenMode = function () {
  document.onfullscreenchange = () => {
    document.body.classList.toggle('fullscreen', !!document.fullscreenElement);
  };

  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else {
    document.documentElement.requestFullscreen();
  }
};

/**
 * Get a random UUID using window.crypto.randomUUID(), unless it doesn't
 * exist in which case it's simulated.
 */
Utils.generateRandomUUID = function () {
  if (window.crypto && window.crypto.randomUUID) {
    return window.crypto.randomUUID();
  }
  // Polyfill
  return parseInt(Math.random() * 2 ** 63).toString(16);
};

/**
 * Compare two version strings in the format x.y.z
 * Returns true if v1 is greater or equal to v2
 */
Utils.isVersionGreaterOrEqual = function (v1, v2) {
  const v1Parts = v1.split('.').map(Number);
  const v2Parts = v2.split('.').map(Number);

  for (let i = 0; i < v1Parts.length && i < v2Parts.length; i++) {
    if (v1Parts[i] > v2Parts[i]) {
      return true;
    } else if (v1Parts[i] < v2Parts[i]) {
      return false;
    }
  }

  // If all parts are equal, then v1 is equal to v2
  return true;
};

//****************************
//* EXPORTS
//****************************

export default Utils;
