'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import TransactionUtils from '../TransactionUtils';
import EditStepForm from './EditStepForm';

// a workaround for a broken dropdown
function useOutsideAlerter(ref, onOutsideClick) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const StepOptions = ({
  hasHtml,
  hasDetails,
  hasRawHtml,
  hasScreenshot,
  getFirstSelector,
  handleCopySelector,
  handleViewScreenshot,
  handleStepDetailsClick,
  handleHtmlDownload,
  handleRawHtmlDownload,
  handleDelete,
  handleAddStepBelow,
}) => {
  const [visible, setVisible] = React.useState(false);
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setVisible(false);
  });
  let buttons = [];
  let addDivider = false;
  if (hasDetails) {
    buttons.push(
      <a key="step-details" href="#" className="dropdown-item" onClick={handleStepDetailsClick}>
        <i className="fal fa-info-circle" />
        Show Details
      </a>
    );
    addDivider = true;
  }

  if (hasRawHtml) {
    buttons.push(
      <a key="download-html" href="#" className="dropdown-item" onClick={handleRawHtmlDownload}>
        <i className="fal fa-file-code" />
        Download Raw HTML
      </a>
    );
    addDivider = true;
  }

  if (hasHtml) {
    buttons.push(
      <a key="donwload-dom" href="#" className="dropdown-item" onClick={handleHtmlDownload}>
        <i className="fal fa-code" />
        Download HTML DOM
      </a>
    );
    addDivider = true;
  }

  if (hasScreenshot) {
    buttons.push(
      <a key="view-screenshot" href="#" className="dropdown-item" onClick={handleViewScreenshot}>
        <i className="fal fa-camera" />
        View Screenshot
      </a>
    );
    addDivider = true;
  }

  if (addDivider) {
    buttons.push(<div key="divider" className="dropdown-divider"></div>);
  }

  if (getFirstSelector !== null) {
    buttons.push(
      <a key="copy-selector" href="#" className="dropdown-item" onClick={handleCopySelector}>
        <i className="fal fa-copy" />
        Copy element selector
      </a>
    );
  }

  buttons = buttons.concat([
    <a key="delete" href="#" className="dropdown-item" onClick={handleDelete}>
      <i className="fal fa-trash" />
      Delete step
    </a>,
    <div key="divider2" className="dropdown-divider"></div>,
    <a key="addnew" href="#" className="dropdown-item" onClick={handleAddStepBelow}>
      <i className="fal fa-plus" />
      Add step below
    </a>,
  ]);

  return (
    <div ref={wrapperRef} className="dropdown">
      <a
        href="#"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={(e) => {
          e.stopPropagation();
          setVisible(!visible);
        }}
      >
        <i className="fa fa-ellipsis-v" />
      </a>
      <div
        className={ReactUtils.cssClass('dropdown-menu dropdown-menu-right', {
          show: visible,
        })}
      >
        {buttons}
      </div>
    </div>
  );
};

export default class StepsListItem extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      showHelp: false,
    };
  }

  componentDidMount() {
    var el = ReactDOM.findDOMNode(this);
    el.scrollIntoView();
  }

  handleSelect(e) {
    if (e.target.className === 'download-link' || e.target.parentElement.className === 'download-link') {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelectStep(this.props.index);
  }

  handleDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDeleteStep(this.props.index);
  }

  handleMenuClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragStart(index, e) {
    index = index.toString();
    e.dataTransfer.effectAllowed = 'move';

    try {
      e.dataTransfer.setData('application/json', index);
    } catch (exc) {
      e.dataTransfer.setData('text', index);
    }
  }

  handleDragOver(e) {
    e.preventDefault();
  }

  handleDrop(index, e) {
    e.preventDefault();
    var sourceIndex = null;

    try {
      sourceIndex = e.dataTransfer.getData('application/json');
    } catch (exc) {
      sourceIndex = e.dataTransfer.getData('text');
    }

    this.props.onReorderStep(parseInt(sourceIndex), index);
  }

  handleShowMoreClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({showHelp: !this.state.showHelp});
  }

  handleUpgradeDeprecatedClick(e) {
    e.preventDefault();
    e.stopPropagation();
    var step = this.props.step;
    this.props.onReplaceStep(this.props.index, step.replace_with);
  }

  handleAddStepBelow(e) {
    e.preventDefault();
    e.stopPropagation();
    var el = e.currentTarget;
    var button = el.parentElement.parentElement.parentElement;
    this.props.handleAddStart(e, button, this.props.index + 1);
  }

  getFirstSelector() {
    const step = this.props.step;
    for (let i = 0; i < step.fields.length; i++) {
      const field = step.fields[i];
      if (field.type === 'SELECTOR') {
        return step.values[field.name];
      }
    }
    return null;
  }

  handleCopySelector(e) {
    e.preventDefault();
    e.stopPropagation();
    const selector = this.getFirstSelector();
    Utils.setClipboard(selector);
  }

  render() {
    var step = this.props.step;

    return (
      <li
        className={ReactUtils.cssClass(step.step_type, {
          selected: this.props.selected,
          error: step.results.error,
          success: !step.results.error && step.results.response_time >= 0,
        })}
      >
        <div
          className="step-row"
          onClick={this.handleSelect}
          draggable={this.props.allowMove}
          onDragStart={this.handleDragStart.bind(null, this.props.index)}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop.bind(null, this.props.index)}
        >
          <div className="num">{this.props.index + 1}</div>
          <div className={ReactUtils.cssClass('description', step.step_type)}>
            <a
              dangerouslySetInnerHTML={{
                __html: TransactionUtils.formatStepDescription(step, this.props.shortSelectors),
              }}
            />
            {step.results.error ? <div className="error">{step.results.error}</div> : ''}
          </div>
          <div className="step-details-icons">
            {this.renderStepDetailsIcon()}
            {this.renderStepWaterfallIcon()}
            {this.renderStepHtmlIcon()}
            {this.renderStepRawHtmlIcon()}
            {this.renderScreenshotIcon()}
            {step.results.error ? (
              <div className="error">
                <i className="fal fa-exclamation-triangle" />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="response">
            {!step.results.error && step.results.response_time >= 0 ? (
              <span className="badge badge-success">{step.results.response_time + 'ms'}</span>
            ) : null}
          </div>
          <div className="handle">
            {this.renderMenu()}
            <i className="fa fa-grip-lines" />
          </div>
        </div>
        {this.props.selected ? this.renderForm() : null}
      </li>
    );
  }

  renderMenu() {
    return (
      <StepOptions
        hasHtml={this.hasHtml()}
        hasDetails={this.hasDetails()}
        hasRawHtml={this.hasRawHtml()}
        hasScreenshot={this.hasScreenshot()}
        getFirstSelector={this.getFirstSelector()}
        handleCopySelector={this.handleCopySelector}
        handleViewScreenshot={this.handleViewScreenshot}
        handleStepDetailsClick={this.handleStepDetailsClick}
        handleHtmlDownload={this.handleHtmlDownload}
        handleRawHtmlDownload={this.handleRawHtmlDownload}
        handleDelete={this.handleDelete}
        handleAddStepBelow={this.handleAddStepBelow}
      />
    );
  }

  renderUpgradeDeprecated() {
    var step = this.props.step;
    if (step.is_deprecated && step.replace_with) {
      return (
        <p className="mb-3">
          <strong>This step type has been deprecated.</strong>
          <br />
          <span className="text-muted">
            <a onClick={this.handleUpgradeDeprecatedClick} href="#">
              Click here
            </a>{' '}
            to automatically upgrade this step to its newer version.
          </span>
        </p>
      );
    }
    return null;
  }

  renderForm() {
    if (this.props.helpText.length > 110) {
      var helpSection = (
        <div className={ReactUtils.cssClass('help', {collapsed: !this.state.showHelp})}>
          <div className="text" dangerouslySetInnerHTML={{__html: this.props.helpText}} />
          <a className="more" href="#" onClick={this.handleShowMoreClick}>
            {this.state.showHelp ? 'less' : 'more'}
          </a>
        </div>
      );
    } else {
      helpSection = (
        <div className="help">
          <div className="text" dangerouslySetInnerHTML={{__html: this.props.helpText}} />
        </div>
      );
    }
    return (
      <div className="step-form">
        {helpSection}
        {this.renderUpgradeDeprecated()}
        <EditStepForm
          step={this.props.step}
          htmlElementList={this.props.htmlElementList}
          onSelectElement={this.props.onSelectElement}
          onSelectElementCompleted={this.props.onSelectElementCompleted}
          onUpdateSelectedStepField={this.props.onUpdateSelectedStepField}
          onOpenURL={this.props.onOpenURL}
        />
      </div>
    );
  }

  hasDetails() {
    return !!this.props.step.results.details;
  }

  renderStepDetailsIcon() {
    if (this.hasDetails()) {
      return (
        <div className="step-details">
          <a href="#" title="Show details" onClick={this.handleStepDetailsClick}>
            <i className="fal fa-info-circle" />
          </a>
        </div>
      );
    }
    return null;
  }

  hasWaterfall() {
    return this.props.step.requests && this.props.step.requests.length > 0;
  }

  handleStepDetailsClick() {
    var step = this.props.step;
    const mergedDetails = TransactionUtils.formatStepDetails(step.results.details);
    TransactionUtils.createInfoWindow(mergedDetails, 'Request Details');
  }

  handleStepWaterfallClick() {
    TransactionUtils.createInfoWindow(
      TransactionUtils.renderWaterfallHTML(this.props.step.requests),
      'Requests Waterfall: Step ' + (this.props.index + 1)
    );
  }

  renderStepWaterfallIcon() {
    if (this.hasWaterfall()) {
      return (
        <div className="step-waterfall">
          <a href="#" title="Show requests waterfall" onClick={this.handleStepWaterfallClick}>
            <i className="fal fa-align-left" />
          </a>
        </div>
      );
    }
    return null;
  }

  hasHtml() {
    return !!this.props.step.results.raw_html;
  }

  handleHtmlDownload(e) {
    e.preventDefault();
    e.stopPropagation();
    var step = this.props.step;
    var html = step.results.html;
    Utils.downloadTextAsFile('transaction-dom.html', html);
  }

  renderStepHtmlIcon() {
    if (this.hasHtml()) {
      return (
        <div className="step-html">
          <a
            className="download-link"
            href="#"
            onClick={this.handleHtmlDownload}
            title="Download HTML DOM (after browser rendering)"
          >
            <i className="fal fa-code" />
          </a>
        </div>
      );
    }
    return null;
  }

  hasRawHtml() {
    return !!this.props.step.results.raw_html;
  }

  handleRawHtmlDownload(e) {
    e.preventDefault();
    e.stopPropagation();
    var step = this.props.step;
    var html = step.results.raw_html;
    Utils.downloadTextAsFile('transaction-raw.html', html);
  }

  renderStepRawHtmlIcon() {
    if (this.hasRawHtml()) {
      return (
        <div className="step-html">
          <a
            className="download-link"
            href="#"
            onClick={this.handleRawHtmlDownload}
            title="Download Raw HTML (before browser rendering)"
          >
            <i className="fal fa-file-code" />
          </a>
        </div>
      );
    }
    return null;
  }

  hasScreenshot() {
    return !!this.props.step.results.error_screenshot;
  }

  handleViewScreenshot(e) {
    e.preventDefault();
    e.stopPropagation();
    var data = this.props.step.results.error_screenshot;
    Utils.openBase64Image(data);
  }

  renderScreenshotIcon() {
    if (this.hasScreenshot()) {
      return (
        <div className="step-screenshot">
          <a href="#" title="View Screenshot" onClick={this.handleViewScreenshot}>
            <i className="fal fa-camera" />
          </a>
        </div>
      );
    }
    return null;
  }
}

StepsListItem.defaultProps = {
  index: 0,
  step: {},
  selected: false,
};
