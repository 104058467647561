'use strict';

import _ from 'underscore';
import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, CustomEvent, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export default class CheckGroup extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const form = unpackFormLinkOrProps(this.props);

    const key = e.target.value;
    const isChecked = e.target.checked;
    let state = form.value || [];

    state = _.difference(state, [key.toString(), parseInt(key)]);
    if (isChecked) {
      state = _.union(state, [key]);
    }

    form.onChange(new CustomEvent({type: 'change', target: this, value: state}));
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className="form-group">
        {this.renderOptions(form)}
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }

  renderOptions(form) {
    const value = form.value || [];
    return this.props.choices.map((v, i) => {
      const [key, label] = v;
      const fieldId = this.htmlId + '-' + i;

      return (
        <div key={i} className={ReactUtils.cssClass('custom-control custom-checkbox', this.props.divCSSClass)}>
          <input
            type="checkbox"
            id={fieldId}
            name={form.fieldName}
            className={ReactUtils.cssClass('custom-control-input', {['is-invalid']: form.errors})}
            disabled={this.props.disabled}
            value={key}
            checked={value.find((v) => v.toString() === key.toString()) !== undefined}
            onChange={this.handleChange}
          />

          <Label
            baseCSSClass="custom-control-label"
            fieldId={fieldId}
            isRequired={this.props.isRequired}
            labelText={label}
          />
        </div>
      );
    });
  }
}

CheckGroup.defaultProps = {
  fieldName: null, // Field name returned in form data
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
  disabled: false, // Control is disabled?
  choices: [], // Array of 2-tuples: [key, value] select box choices.

  divCSSClass: null, // Extra CSS classes to add to the div.form-check

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
};
