'use strict';

import $ from 'jquery';
import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import Select2 from '../../jskit/react/forms/Select2';
import Utils from '../../jskit/general/Utils';

export default class BulkLocationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        monitoring_groups: [],
      },
    };
    Utils.autoBindClass(this);
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal() {
    this.refs.modal_contact.showModal();
    this.setState({formData: {monitoring_groups: []}});
  }

  hideModal() {
    this.refs.modal_contact.hideModal();
  }

  formatMonitoringGroup(val) {
    if (this.props.choices.monitoring_group_to_country[val.id]) {
      return $(
        '<span><span class="flag-icon flag-icon-' +
          this.props.choices.monitoring_group_to_country[val.id] +
          '"></span> ' +
          val.text +
          '</span>'
      );
    }
    return val.text;
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const monitoringGroupsChoices = (this.props.choices.monitoring_groups || []).map((mg) => [mg.id, mg.location]);

    return (
      <Modal
        ref="modal_contact"
        title="Set multiple check locations"
        cancelButton={true}
        saveButton={this.props.onSave}
      >
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>Note: Existing locations on selected checks will be replaced with whatever you select below.</em>
          </p>
          <Select2
            fieldName="monitoring_groups"
            disabled={this.props.choices.monitoring_groups_unselectable}
            choices={monitoringGroupsChoices}
            templateResult={this.formatMonitoringGroup}
            templateSelection={this.formatMonitoringGroup}
            labelText="Locations"
            isRequired={true}
            multiple={true}
            closeOnSelect={false}
            formLink={formLink}
          />
        </form>
      </Modal>
    );
  }
}
