'use strict';

import _ from 'underscore';
import React from 'react';
import Utils from '../jskit/general/Utils';
import Ajax from '../jskit/general/Ajax';
import URLHistory from '../jskit/general/URLHistory';
import SelectBox from '../jskit/react/forms/SelectBox';
import ReactUtils from '../jskit/react/ReactUtils';
import TestRunner from '../devices/common/TestRunner';
import TransactionScriptEditor from './TransactionScriptEditor';

const CHROME_STORE_URL =
  'https://chrome.google.com/webstore/detail/uptimecom-transaction-rec/jiiejololhdhdhfefdaokjkgfpmdbiio';

function isChromeOrChromium() {
  return !!(window.chrome && navigator.vendor === 'Google Inc.');
}

export default class RecorderController extends React.Component {
  constructor(props) {
    super(props);
    this.transactionScriptEditorRef = React.createRef();
    this.extensionId = props.extensionId;

    Utils.autoBindClass(this);
    const isChrome = isChromeOrChromium();
    const initalURL = URLHistory.queryStringToObject().url;
    if (isChrome) {
      this.connectExtension(this.props.startURL || initalURL || 'about:blank');
      window.addEventListener('beforeunload', (event) => {
        if (this.exitWarning) {
          event.preventDefault();
          event.returnValue = 'Are you sure you want to leave this page?';
        }
      });
    }

    this.lastStep = null;
    this.lastStepData = null;
    this.frameNavigated = false;
    this.exitWarning = false;

    //get viewport size choices
    this.viewport_size_choices = [];
    const stepDefs = _.object(props.stepDefs['TRANSACTION']);
    const step = stepDefs['C_AUTH_AND_SETTINGS'];
    for (let i = 0; i < step.fields.length; i++) {
      if (step.fields[i].name === 'viewport_size') {
        this.viewport_size_choices = step.fields[i].choices.slice();
        break;
      }
    }

    this.viewport_size_choices.unshift(['responsive', 'Responsive']);

    this.state = {
      isChrome: isChrome,
      isExtensionInstalled: false,
      upgradeRequired: false,
      isLoading: false,
      isRecording: false,
      isSelecting: false,
      isTestRunning: false,
      select: null,
      validationErrors: {},
      currentURL: '',
      viewportSize: 'responsive',
      iframeWidth: '100%',
      iframeHeight: '99%',
      error: null,
      newWindow: null,
    };
  }

  createNavigationStep(url) {
    return {step_def: 'C_OPEN_URL', values: {url: url}};
  }

  createAuthAndSettingsStep(viewport_size) {
    return {
      step_def: 'C_AUTH_AND_SETTINGS',
      values: {
        headers: '',
        is_mobile: '',
        password: '',
        username: '',
        viewport_size: viewport_size,
      },
    };
  }

  createSteps(data) {
    var steps = [];
    if (data.action === 'load' || data.action === 'unload') {
      this.frameNavigated = true;
    } else if (data.action === 'click') {
      steps = [
        {
          step_def: 'C_MOUSE_CLICK',
          values: {element: data.selector},
        },
      ];
    } else if (data.action === 'dblclick') {
      steps = [
        {
          step_def: 'C_MOUSE_CLICK',
          values: {element: data.selector, click_count: '2'},
        },
      ];
    }
    // code below could be useful when we decide to implement handling of special keys like arrows, PgUp/PgDown, etc.
    // else if(data.action == 'keydown') {
    //     let text = (data.value ? data.value : "") + ((data.key && data.key.length == 1)? data.key : "");
    //     steps = [{
    //         step_def: 'C_FILL_FIELD',
    //         values: {element: data.selector, text: text}
    //     }];
    // }
    else if (data.action === 'input') {
      steps = [
        {
          step_def: 'C_FILL_FIELD',
          values: {element: data.selector, text: data.value},
        },
      ];
    } else if (data.action === 'change') {
      if (data.tagName === 'select') {
        steps = [
          {
            step_def: 'C_FILL_FIELD',
            values: {element: data.selector, text: data.value},
          },
        ];
      } else if (data.type === 'checkbox' || data.type === 'radio') {
        if (data.checked) {
          steps = [
            {
              step_def: 'C_CHECK_BOX',
              values: {element: data.selector},
            },
          ];
        } else {
          steps = [
            {
              step_def: 'C_UNCHECK_BOX',
              values: {element: data.selector},
            },
          ];
        }
      }
    }
    if (steps.length > 0 && this.frameNavigated) {
      this.frameNavigated = false;
      if (this.lastStep && this.lastStep.step_def !== 'C_OPEN_URL') {
        steps.unshift({step_def: 'C_WAIT_FOR_ELEMENT', values: {element: data.selector}});
      }
    }

    return steps;
  }

  createReplaceStep(step, data) {
    if (this.lastStep == null) return null;

    if (
      step.step_def === 'C_MOUSE_CLICK' &&
      step.step_def === this.lastStep.step_def &&
      step.values.element === this.lastStep.values.element
    ) {
      return step;
    } else if (
      step.step_def === 'C_MOUSE_CLICK' &&
      this.lastStep.step_def === 'C_FILL_FIELD' &&
      (data.tagName === 'select' || data.tagName === 'option')
    ) {
      return this.lastStep;
    } else if (
      ['C_FILL_FIELD', 'C_CHECK_BOX', 'C_UNCHECK_BOX'].indexOf(step.step_def) > -1 &&
      step.values.element === this.lastStep.values.element &&
      (this.lastStep.step_def === 'C_FILL_FIELD' || this.lastStep.step_def === 'C_MOUSE_CLICK')
    ) {
      return step;
    } else if (
      (step.step_def === 'C_CHECK_BOX' || step.step_def === 'C_UNCHECK_BOX') &&
      this.lastStep.step_def === 'C_MOUSE_CLICK' &&
      this.lastStepData.tagName === 'label'
    ) {
      return step;
    }

    return null;
  }

  connectExtension(initial_url) {
    //setup persistent connection
    // eslint-disable-next-line no-undef
    if (!this.extensionId || !chrome.runtime) {
      return;
    }

    // eslint-disable-next-line no-undef

    this.port = window.chrome.runtime.connect(this.extensionId);
    this.port.onMessage.addListener((msg) => {
      window.DEBUG && console.debug('Message from extension received:', msg);
      if (msg.action == 'INIT') {
        const response = msg;
        // eslint-disable-next-line no-undef
        if (response === undefined && chrome.runtime.lastError) {
          this.setState({isExtensionInstalled: false});
          return;
        }
        this.setState({isExtensionInstalled: true});
        if (response.version < this.props.extensionVersion) {
          this.setState({upgradeRequired: true});
          return;
        }
        if (!this.exitWarning) {
          // only run on initial load
          if (this.props.script) {
            this.transactionScriptEditorRef.current.deserialize('TRANSACTION', this.props.script);
            this.checkScriptViewportSize();
          } else {
            const step = this.createNavigationStep(initial_url);
            this.transactionScriptEditorRef.current.deserialize('TRANSACTION', JSON.stringify([step]));
            this.lastStep = step;
          }
        }
        this.exitWarning = true;
        this.handleOpenURL(initial_url);
      } else if (msg.action === 'NAVIGATION' && msg.url !== 'about:blank') {
        if (msg.parentFrameId === (this.state.newWindow === null ? 0 : -1)) {
          this.setState({currentURL: msg.url});
        }
      } else if (msg.action === 'UNLOAD') {
        if (msg.parentFrameId === (this.state.newWindow === null ? 0 : -1)) {
          this.setState({isLoading: true});
          console.debug('Loading');
        }
      } else if (msg.action === 'LOAD') {
        if (msg.parentFrameId === (this.state.newWindow === null ? 0 : -1)) {
          this.setState({isLoading: false});
          console.debug('Stop');
        }
      } else if (this.state.isSelecting && msg.action === 'SELECT') {
        if (msg.data.action === 'click') {
          if (this.state.select != null && this.state.select.callback) {
            this.state.select.callback(
              this.state.select.step,
              this.state.select.field,
              msg.data.selector,
              msg.data.text
            );
          }
          this.handleSelectElementStop();
        }
      } else if (this.state.isRecording && msg.action === 'RECORD') {
        const steps = this.createSteps(msg.data);
        if (steps.length > 0) {
          const step = steps[steps.length - 1];
          const replaceStep = this.createReplaceStep(step, msg.data);
          if (replaceStep != null) {
            this.transactionScriptEditorRef.current.removeStep();
            steps[steps.length - 1] = replaceStep;
          }
          for (let i = 0; i < steps.length; i++) {
            this.transactionScriptEditorRef.current.addStep(steps[i].step_def, undefined, steps[i]);
            this.lastStep = steps[i];
          }
          this.lastStepData = msg.data;
        }
      } else if (msg.action === 'TAB_CLOSED') {
        if (this.state.newWindow != null) {
          this.setState({newWindow: null});
        }
        this.port.postMessage({action: 'INIT'});
      }
    });

    this.port.postMessage({action: 'INIT'});
  }

  handleSelectElementStart(step, field, callback) {
    this.port.postMessage({action: 'SELECT_START'});
    this.setState({
      isSelecting: true,
      select: {
        callback: callback,
        step: step,
        field: field,
      },
    });
  }

  handleSelectElementStop() {
    if (this.state.isSelecting) {
      this.port.postMessage({action: 'SELECT_STOP'});
      this.setState({isSelecting: false, select: null});
    }
  }

  handleToggleRecording() {
    const toggle = !this.state.isRecording;
    this.setState({isRecording: toggle});
  }

  handleRunTestStart() {
    this.setState({isTestRunning: true});
    this.transactionScriptEditorRef.current.runTestStart();
  }

  handleRunTestComplete(data) {
    if (!data.result) {
      this.handleRunTestError({fields: ['No response received from probe server.']});
      return;
    }
    this.setState({isTestRunning: false});
    this.transactionScriptEditorRef.current.runTestComplete(data);
  }

  handleRunTestError(data) {
    const fields = data.fields;
    this.setState({isTestRunning: false});
    if (fields !== undefined) {
      this.setState({validationErrors: fields});
      this.transactionScriptEditorRef.current.runTestComplete(null, fields);
    }
  }

  handleSave() {
    this.exitWarning = false;
    new Ajax().post({
      url: this.props.saveURL,
      disable: '.btnSave',
      data: {
        id: this.props.serviceId,
        msp_script: this.transactionScriptEditorRef.current.serialize(),
      },
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          window.location = this.props.listURL + '?pop=' + this.props.serviceId;
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleCancel() {
    window.location = this.props.listURL + '?pop=' + this.props.serviceId;
  }

  handleOpenURL(url) {
    if (url.startsWith('http://')) {
      this.setState({currentURL: url, error: 'Sorry, only https URLs are supported by the recorder.'});
      return;
    }
    if (url.indexOf('://') === -1) {
      url = 'https://' + url;
    }
    this.setState({url: url, currentURL: url, error: null});
  }

  getAuthAndSettings() {
    const script = this.transactionScriptEditorRef.current.getScript();
    for (let i = 0; i < script.length; i++) {
      const step = script[i];
      if (step.step_def === 'C_AUTH_AND_SETTINGS') {
        return {step: step, position: i};
      }
    }
    return {step: null, position: null};
  }

  checkScriptViewportSize() {
    const {step} = this.getAuthAndSettings();
    if (step !== null && step.values.viewport_size !== '') {
      this.setViewportSize(step.values.viewport_size);
    }
  }

  setViewportSize(width_x_height) {
    let size = width_x_height,
      width = '100%',
      height = '99%';

    // when Default selected we want to adjust viewport size in recorder
    // but set script settings value to empty string i.e. - no selection use default
    if (size === '') size = '1024x768';

    if (size && size !== 'responsive') {
      const parts = size.split('x');
      width = parts[0] + 'px';
      height = parts[1] + 'px';
    }

    if (size === 'responsive' || size === '1024x768') {
      this.ensureViewportSettings('');
    } else {
      this.ensureViewportSettings(size);
    }

    this.setState({
      viewportSize: width_x_height,
      iframeWidth: width,
      iframeHeight: height,
    });
  }

  ensureViewportSettings(size) {
    const editor = this.transactionScriptEditorRef.current;
    if (size == null) {
      return;
    }
    const {step, position} = this.getAuthAndSettings();
    if (step === null) {
      const settings = this.createAuthAndSettingsStep(size);
      editor.addStep(settings.step_def, 0, settings);
    } else if (step.values.viewport_size !== size) {
      step.values.viewport_size = size;
      editor.replaceStep(position, step.step_def, position, step);
    }
    return;
  }

  handleViewportSizeChange(e) {
    const selectedOption = e.currentTarget.value;
    this.setViewportSize(selectedOption);
  }

  handleOpenInNewWindow() {
    this.port.postMessage({action: 'NEW_TAB', url: this.state.currentURL});
    this.setState({
      newWindow: true,
      error: 'Editing in new tab. Close the tab to use this frame.',
    });
  }

  render() {
    if (!this.state.isChrome) return this.renderNotChrome();
    if (!this.state.isExtensionInstalled) return this.renderExtensionNotInstalled();
    if (this.state.upgradeRequired) return this.renderUpgradeWarning();
    const serviceData = {
      monitoring_service_type: 'TRANSACTION',
      id: this.props.serviceId,
      test_location: this.props.testServers[0][0],
    };
    const rec = {
      classes: 'mt-3 mb-3 mr-2 btn btn-recording',
      disabled: false,
      value: (
        <React.Fragment>
          <span className="far fa-dot-circle mr-1"></span>Start Recording
        </React.Fragment>
      ),
    };
    if (this.state.isRecording) {
      rec.value = (
        <React.Fragment>
          <span className="fa fa-circle-notch fa-spin mr-1"></span>Stop Recording
        </React.Fragment>
      );
    }
    if (this.state.isSelecting) {
      rec.disabled = true;
      rec.value = 'Selecting element...';
    }

    const validation_errors = this.state.validationErrors.msp_script;

    return (
      <React.Fragment>
        <div className="fw-header">
          <div className="logo-col">
            <a href={this.props.websiteHomepage}>
              <img src={this.props.brandInfoLogo} alt={this.props.brandInfoName} />
            </a>
          </div>
          <div className="title-col">
            <h1>{this.props.checkName}</h1>
          </div>
          <div className="header-controls-col"></div>
        </div>
        <div className="container-fluid fw-fh-wrapper h-100">
          <div className="row h-100">
            <div key="col-1" className="col-lg-3 col-md-5 p-0 editor-form">
              <div className="script-editor-wrapper">
                <div
                  className={ReactUtils.cssClass({
                    'selection-overlay': true,
                    active: this.state.isSelecting,
                  })}
                >
                  <h3>Element Selector</h3>
                  <div>
                    Highlight and select a specific element for this step to interact with from the current URL.
                  </div>
                  <div>
                    <button className="btn btn-outline-primary" onClick={this.handleSelectElementStop}>
                      Exit Element Selector
                    </button>
                  </div>
                </div>
                <button className={rec.classes} disabled={rec.disabled} onClick={this.handleToggleRecording}>
                  {rec.value}
                </button>
                {!this.state.newWindow && (
                  <button className="btn btn-outline-secondary mr-2" onClick={this.handleOpenInNewWindow}>
                    Edit in New Tab
                  </button>
                )}

                <TransactionScriptEditor
                  ref={this.transactionScriptEditorRef}
                  isVisible={true}
                  onSelectElement={this.handleSelectElementStart}
                  onSelectElementReset={this.handleSelectElementStop}
                  isSelectingElement={this.state.isSelecting}
                  isRecording={this.state.isRecording}
                  onOpenURL={this.handleOpenURL}
                  validationErrors={validation_errors}
                  shortSelectors={true}
                  stepDefs={this.props.stepDefs}
                />
              </div>
              <div className="form-footer p-4">
                <TestRunner
                  onStart={this.handleRunTestStart}
                  onSuccess={this.handleRunTestComplete}
                  onError={this.handleRunTestError}
                  scriptGetter={() => this.transactionScriptEditorRef.current.serialize()}
                  data={serviceData}
                  helpText="This test is running from a dedicated Uptime.com testing server or private location."
                  locations={this.props.testServers}
                  runTestURL={this.props.runTestURL}
                  selectCSSClass="col-sm-7"
                  buttonCSSClass="col-auto"
                />
              </div>
              <div className="form-footer p-4">
                <button className="btnSave btn btn-primary mx-1" onClick={this.handleSave}>
                  Save
                </button>
                <button onClick={this.handleCancel} className="btn btn-outline-primary mx-1">
                  Cancel
                </button>
              </div>
            </div>
            <div key="col-2" className="col-lg-9 col-md-7 p-0 h-100 browser">
              <div
                className={ReactUtils.cssClass({
                  'runtest-overlay': true,
                  active: this.state.isTestRunning,
                })}
              >
                <h3>Running a Test</h3>
                <div>
                  Your script is being executed on our check servers &{' '}
                  <strong>may take up to 60 seconds to complete</strong>.
                </div>
                <div>Test results will be available in "Check Steps" list to the left.</div>
                <div>You can continue adding steps after the test completes.</div>
              </div>

              <div key="controls" className="form-group row m-0 controls">
                <div className="col col-sm-9">
                  <div>
                    URL {this.state.isLoading ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null}
                  </div>
                  <div>
                    <input type="text" readOnly={true} className="form-control" value={this.state.currentURL} />
                  </div>
                </div>
                <div className="col col-sm-3">
                  <div>Viewport</div>
                  <div>
                    <SelectBox
                      label="Viewport"
                      choices={this.viewport_size_choices}
                      value={this.state.viewportSize}
                      onChange={this.handleViewportSizeChange}
                    />
                  </div>
                </div>
              </div>
              <div key="viewport" className="viewport">
                {this.state.error == null ? (
                  <iframe
                    /* eslint-disable-next-line max-len */
                    sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-presentation allow-same-origin allow-scripts"
                    style={{
                      width: this.state.iframeWidth,
                      height: this.state.iframeHeight,
                    }}
                    src={this.state.url}
                  ></iframe>
                ) : (
                  <div className="navigation-error">{this.state.error}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  errorHeader() {
    return (
      <div className="fw-header">
        <div className="logo-col">
          <a href={this.props.websiteHomepage}>
            <img src={this.props.brandInfoLogo} alt={this.props.brandInfoName} />
          </a>
        </div>
        <div className="title-col">
          <h1>{this.props.checkName}</h1>
        </div>
        <div className="header-controls-col"></div>
      </div>
    );
  }

  renderNotChrome() {
    return (
      <React.Fragment>
        {this.errorHeader()}
        <div className="critical-error">
          <div className="chrome title-pic"></div>
          <h1>Unsupported Browser</h1>
          <p className="text-muted text-center">
            The Uptime.com Transaction Recorder is available only in Google Chrome (or compatible browsers).
          </p>
          <div>
            <a className="btn btn-primary" href="https://www.google.com/chrome/" target="_blank">
              Download Google Chrome
            </a>
            <button onClick={this.handleCancel} className="btn btn-outline-primary mx-1">
              Use default editor
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderExtensionNotInstalled() {
    if (this.state.isExtensionInstalled === undefined) {
      return (
        <React.Fragment>
          {this.errorHeader()}
          <div className="critical-error">
            <div className="extension title-pic"></div>
            <p className="text-muted text-center">Checking Extension...</p>
            <div>
              <a className="btn btn-primary" href={CHROME_STORE_URL} target="_blank">
                Get Extension
              </a>
              <button onClick={this.handleCancel} className="btn btn-outline-primary mx-1">
                Use default editor
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {this.errorHeader()}
        <div className="critical-error">
          <div className="extension title-pic"></div>
          <h1>Install the Transaction Recorder Extension</h1>
          <p className="text-muted text-center">
            Please install or upgrade the required Uptime.com Transaction Recorder Extension from the Google Chrome
            Store.
          </p>
          <p className="text-muted text-center">
            After installing the extension, please{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                document.location.reload();
              }}
            >
              reload this page
            </a>
            .
          </p>
          <div>
            <a className="btn btn-primary" href={CHROME_STORE_URL} target="_blank">
              Get Extension
            </a>
            <button onClick={this.handleCancel} className="btn btn-outline-primary mx-1">
              Use default editor
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderUpgradeWarning() {
    return (
      <React.Fragment>
        {this.errorHeader()}
        <div className="critical-error">
          <div className="extension-upgrade title-pic"></div>
          <h1>Update Required</h1>
          <p className="text-muted text-center">
            A new version of the Uptime.com Transaction Recorder is available. Please update.
          </p>
          <div>
            <a className="btn btn-primary" href={CHROME_STORE_URL} target="_blank">
              Get Extension
            </a>
            <button onClick={this.handleCancel} className="btn btn-outline-primary mx-1">
              Use default editor
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
