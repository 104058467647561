'use strict';

const SpeedTestUtils = {
  loadTimeCSSClass: function (ms) {
    if (!ms) {
      return '';
    } else if (ms <= 3000) {
      return 'success';
    } else if (ms <= 6000) {
      return 'warning';
    } else {
      return 'danger';
    }
  },

  ratingCSSClass: function (rating) {
    if (!rating) {
      return '';
    } else if (rating >= 80) {
      return 'success';
    } else if (rating >= 60) {
      return 'warning';
    } else {
      return 'danger';
    }
  },
};

export default SpeedTestUtils;
