'use strict';

import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import Select2 from '../../jskit/react/forms/Select2';

export default class BulkTagsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        tags: [],
      },
    };
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal() {
    this.refs.modal_contact.showModal();
    this.setState({formData: {tags: []}});
  }

  hideModal() {
    this.refs.modal_contact.hideModal();
  }

  render() {
    if (!this.props.choices.tags || this.props.choices.tags.length === 0) {
      return this.renderCreateTags();
    }

    const formLink = prepareFormLink(this, 'formData', this.props.errors);

    const buttons = (
      <React.Fragment>
        <button onClick={this.props.onCreateTags} className="btn btn-secondary mr-auto">
          Manage Tags
        </button>
        <button onClick={this.props.onSave} className="btn btn-primary" type="button">
          Save
        </button>
        <button data-dismiss="modal" className="btn btn-outline-primary" type="button">
          Cancel
        </button>
      </React.Fragment>
    );

    const choices = this.props.choices.tags.map((tag) => [tag.id, tag.tag]);

    return (
      <Modal ref="modal_contact" title="Assign tags" buttons={buttons}>
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>Note: Existing tags on selected checks will be replaced with whatever you select below.</em>
          </p>
          <Select2
            fieldName="tags"
            choices={choices}
            labelText="Tags"
            isRequired={true}
            multiple={true}
            closeOnSelect={false}
            formLink={formLink}
          />
        </form>
      </Modal>
    );
  }

  renderCreateTags() {
    return (
      <Modal ref="modal_contact" title="Assign tags" cancelButton={true}>
        <span>You have not created any tags. </span>
        <a href="#" onClick={this.props.onCreateTags}>
          Create some tags now
        </a>
        .
      </Modal>
    );
  }
}
