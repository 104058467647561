'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import DetailTrendGraph from './graphs/DetailTrendGraph.jsx';
import PerformanceDistributionGraph from './graphs/PerformanceDistributionGraph.jsx';
import Donut from './graphs/DonutGraph.jsx';
import LoadBreakdownGraph from './graphs/LoadBreakdownGraph.jsx';
import SegmentDataSection from './SegmentsData.jsx';
import {ReportDefs} from './ReportDefs.js';
import {renderNeedHelp} from './RumUtils.jsx';

export default class RumCheckGraphs extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.segmentsRef = React.createRef('segmentsData');
  }

  forceDataRefresh() {
    const segmentsData = this.segmentsRef.current;
    if (segmentsData) {
      segmentsData.forceDataRefresh();
    }
  }

  _onSwitchTab(tab) {
    this.props.onSwitchTab(tab);
  }

  render() {
    const tabIds = Object.keys(ReportDefs);

    return (
      <div className="rum-check-graphs p-4">
        <SectionNav
          sectionIds={tabIds.map((t) => [t, ReportDefs[t].name])}
          currentSectionId={this.props.activeTab}
          navClass="nav nav-tabs-plain mb-4"
          onSectionNav={this._onSwitchTab}
        >
          {tabIds.map((t) => this.renderTab(t))}
        </SectionNav>
      </div>
    );
  }

  renderTab(tabId) {
    const tabInfo = ReportDefs[tabId];
    return (
      <SectionNavContent key={tabId} sectionId={tabId} currentSectionId={this.props.activeTab}>
        {tabId === this.props.activeTab && this.props.data[tabInfo.key]
          ? this.renderTabData(tabInfo, this.props.data[tabInfo.key])
          : null}
      </SectionNavContent>
    );
  }

  renderTabData(tabInfo, tabData) {
    const possibleSections = [
      {key: 'trend', fn: this.renderTrendSection},
      {key: 'hist', fn: this.renderDistributionSection},
      {key: 'breakdown', fn: this.renderBreakdownSection},
      {key: 'segments', fn: this.renderSegmentsSection},
    ];

    let dividerKey = 1;
    const renderedGraphs = [];
    possibleSections.forEach((section) => {
      if (!tabInfo[section.key]) {
        return;
      }

      // Render documentation link on first section only.
      let docLink = renderedGraphs.length ? undefined : tabInfo.doc;

      if (section.key === 'trend') {
        // support for multiple trend graphs
        var defs = tabInfo[section.key],
          data = tabData[section.key];

        for (let i = 0; i < defs.length; i++) {
          const noDataMessage = defs[i].noDataMessage || tabInfo.noDataMessage;
          if (renderedGraphs.length) {
            renderedGraphs.push(
              <div key={'divider-' + dividerKey++} className="white-block-inner-divider mx-n4 my-4" />
            );
          }
          docLink = renderedGraphs.length ? undefined : tabInfo.doc;
          renderedGraphs.push(section.fn(defs[i], data[i], {index: i, docLink, noDataMessage}));
        }
      } else {
        if (renderedGraphs.length) {
          renderedGraphs.push(<div key={'divider-' + dividerKey++} className="white-block-inner-divider mx-n4 my-4" />);
        }
        const noDataMessage = tabInfo[section.key].noDataMessage || tabInfo.noDataMessage;
        renderedGraphs.push(section.fn(tabInfo, tabData, {docLink, noDataMessage}));
      }
    });

    return renderedGraphs;
  }

  renderHeader(title, docLink) {
    return (
      <div style={{display: 'flex'}}>
        <h4 className="mb-n4">{title}</h4>
        {!!docLink &&
          renderNeedHelp(docLink, {
            linkOnly: true,
            containerStyle: {paddingTop: '0.3em', paddingBottom: '0', fontSize: '14px'},
            dismissible: false,
          })}
      </div>
    );
  }

  renderTrendSection(def, data, {index, docLink, noDataMessage} = {}) {
    return (
      <div key={`trend-${index || 0}`}>
        {this.renderHeader(def.title, docLink)}
        <DetailTrendGraph
          checkName={this.props.checkName}
          data={data}
          headerData={this.props.headerData}
          bar={def.bar}
          left={def.left}
          right={def.right}
          baselines={def.baselines}
          noDataMessage={noDataMessage}
        />
      </div>
    );
  }

  renderDistributionSection(tabInfo, tabData, {docLink, noDataMessage} = {}) {
    const distributionClass = tabInfo.donut ? 'col-lg-9' : 'col';
    return (
      <div key="hist">
        {this.renderHeader(`${tabInfo.name} distribution`, docLink)}
        <div className="row">
          <div className={distributionClass}>
            <PerformanceDistributionGraph
              checkName={this.props.checkName}
              data={tabData.hist}
              feature={tabInfo.hist.feature}
              ranges={tabInfo.hist.ranges}
              noDataMessage={noDataMessage}
            />
          </div>
          {!!tabInfo.donut && (
            <div className="col-lg-3">
              <div className="border rounded p-4 donut-outer-container">
                <Donut
                  checkName={this.props.checkName}
                  data={tabData.donut}
                  header={tabInfo.donut.header}
                  featureColors={tabInfo.donut.featureColors}
                  title={tabInfo.name}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderBreakdownSection(tabInfo, tabData, {docLink, noDataMessage} = {}) {
    return (
      <div key="breakdown">
        {this.renderHeader(`${tabInfo.name} breakdown`, docLink)}
        <LoadBreakdownGraph
          checkName={this.props.checkName}
          data={tabData.breakdown}
          sections={tabInfo.breakdown.sections}
          markers={tabInfo.breakdown.markers}
          noDataMessage={noDataMessage}
        />
      </div>
    );
  }

  renderSegmentsSection(tabInfo, tabData, {docLink} = {}) {
    return (
      <div key="segments">
        {this.renderHeader(`${tabInfo.name} by segment`, docLink)}
        <SegmentDataSection
          checkName={this.props.checkName}
          ref={this.segmentsRef}
          segments={tabInfo.segments}
          dataURL={this.props.segmentDataURL}
          getLoadDataAjaxFilters={this.props.getLoadDataAjaxFilters}
          onURLFilter={this.props.onURLFilter}
          isURLFilterActive={this.props.isURLFilterActive}
          onLoad={this.props.onSegmentDataLoad}
          isAjax={this.props.isAjax}
        />
      </div>
    );
  }
}
