'use strict';

import _ from 'underscore';
import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, CustomEvent, Label, FieldErrors, FieldHelpText} from './FormHelpers';

import $ from 'jquery';

export class Select2Widget extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = props.htmlId || uniqueHtmlId(props.fieldName);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const select = $(this.refs.selectbox);
    const modal = select.closest('.modal-content');
    const minimumResultsForSearch =
      this.props.minimumResultsForSearch !== null ? this.props.minimumResultsForSearch : 10;
    var opts = {
      dropdownParent: modal && modal.length ? modal : null,
      minimumResultsForSearch: this.props.preventSearch ? 999999 : minimumResultsForSearch,
      closeOnSelect: this.props.closeOnSelect,
      tags: this.props.tags,
      createTag: this.props.createTag,
      insertTag: this.props.insertTag,
      dropdownAutoWidth: this.props.dropdownAutoWidth,
    };

    if (this.props.templateSelection !== null) {
      opts.templateSelection = this.props.templateSelection;
    }
    if (this.props.templateResult !== null) {
      opts.templateResult = this.props.templateResult;
    }

    if (this.props.ajaxURL) {
      const paramsFn = this.props.ajaxParamsFn || ((s) => ({q: s}));
      const resultFn = this.props.ajaxResultFn || ((r) => r);

      opts.ajax = {
        minimumResultsForSearch: this.props.ajaxMinimumResults || 0,
        cache: this.props.ajaxCache !== undefined ? this.props.ajaxCache : true,
        delay: this.props.ajaxDelay !== undefined ? this.props.ajaxDelay : 250,
        url: this.props.ajaxURL,
        dataType: 'json',
        data: (params) => paramsFn(params.term),
        processResults: (data) => ({results: resultFn(data).map((x) => ({id: x[0], text: x[1]}))}),
      };

      opts.minimumInputLength = this.props.ajaxMinimumInputLength || (this.props.ajaxPreload === true ? 0 : 1);
    }

    select.select2(opts);
    select.nextAll('.select2-container').first().attr('data-wizard', this.props.wizard);
    select.on('select2:select', this.handleChange);
    select.on('select2:unselect', this.handleChange);
    select.on('select2:clear', this.handleChange);
    if (this.props.titleText) {
      this.overrideTitle();
    }
  }

  componentDidUpdate() {
    const form = unpackFormLinkOrProps(this.props);
    $(this.refs.selectbox).val(this.props.multiple ? form.value || [] : form.value);
    $(this.refs.selectbox).trigger('change.select2');
    if (this.props.titleText) {
      this.overrideTitle();
    }
  }

  componentWillUnmount() {
    $(this.refs.selectbox).off('select2:select', this.handleChange);
    $(this.refs.selectbox).off('select2:unselect', this.handleChange);
    $(this.refs.selectbox).off('select2:clear', this.handleChange);
    $(this.refs.selectbox).off('change', this.handleChange);
    $(this.refs.selectbox).select2('destroy');
  }

  /** Manually set tooltip text, if defined */
  overrideTitle() {
    const container = $(this.refs.selectbox)[0].parentElement;
    const rendered = container ? container.querySelector('.select2-selection__rendered') : undefined;
    if (rendered) {
      rendered.title = this.props.titleText;
    }
  }

  focus() {
    this.refs.selectBox.focus();
  }

  getSelectionData() {
    const data = $(this.refs.selectbox).select2('data');
    const mappedData = data.map((v) => _.pick(v, 'id', 'text'));

    if (this.props.multiple) {
      return mappedData;
    } else {
      return mappedData.length > 0 ? mappedData[0] : null;
    }
  }

  handleChange(e) {
    const form = unpackFormLinkOrProps(this.props);
    const val = $(this.refs.selectbox).val();
    const event = new CustomEvent({type: 'change', target: this, value: val});

    form.onChange(event);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);
    const choices = (this.props.ajaxURL ? this.props.ajaxInitialChoices : this.props.choices) || [];
    const groups = this.props.groups || {};
    const selectOptions = [];

    if (this.props.allowEmpty) {
      selectOptions.push(
        <option key="" value="">
          ------------
        </option>
      );
    }

    for (const group in groups) {
      const options = [];
      groups[group].forEach((choice) => {
        options.push(
          <option key={choice[0]} value={choice[0]}>
            {choice[1]}
          </option>
        );
      });
      selectOptions.push(
        <optgroup key={group} label={group}>
          {options}
        </optgroup>
      );
    }

    choices.forEach((choice) => {
      selectOptions.push(
        <option key={choice[0]} value={choice[0]}>
          {choice[1]}
        </option>
      );
    });

    // this workaround is generally needed because our onChange handlers that are bound to Select2 elements
    // are executed after state is actually changed and form and other controls that depend on state can change their select2
    // `multiple` attributes, while formData bound to this control is yet to change
    // inside prepareFormLink magic and we do not manage to
    // correctly prepare data inside handlers
    let value = form.value;
    if (this.props.multiple && !Array.isArray(value)) {
      value = [value];
    } else if (!this.props.multiple && Array.isArray(value)) {
      value = value[0];
    }

    return (
      <select
        ref="selectbox"
        id={this.htmlId}
        name={this.props.fieldName}
        className="form-control"
        data-is-invalid={!!form.errors || null}
        data-placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        multiple={this.props.multiple}
        value={this.props.multiple ? form.value || [] : form.value}
        onChange={this.handleChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
      >
        {selectOptions}
      </select>
    );
  }
}

Select2Widget.defaultProps = {
  fieldName: '', // Field name returned in form data
  placeholder: null, // Placeholder text
  multiple: false, // Multi-select?
  disabled: false, // Control is disabled?
  preventSearch: false, // Disable search function
  minimumResultsForSearch: null, // Minimum number of results required to display search field
  closeOnSelect: true, // Close the dropdown after a selection
  choices: [], // Array of 2-tuples: [key, value] select box choices.
  groups: {}, // Object containg grouped [key, value] choices. Group keys are used as choices separators
  allowEmpty: false, // Whether to add an empty value to the list.
  wizard: undefined, // Set a data-wizard attribute on the <select> element (hidden)
  titleText: undefined, // Tooltip
  dropdownAutoWidth: false, // True to enable auto-width on dropdown, false for default width.

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
  onFocus: null, // Focus handler
  onBlur: null, // Blur handler
  templateResult: null, // Select2 templateResult option
  templateSelection: null, // Select2 tempalteSelection option
  tags: false, //Select2 tags - allow dynamic option creations
  createTag: null, //Select2 createTag hook
  insertTag: null, //Select2 insertTag hook

  // Ajax options reference: https://select2.org/configuration/options-api
  ajaxURL: null, // URL for an ajax GET call to populate options based on search term
  ajaxParamsFn: null, // Function that receives the search term & returns the ajax GET params dict
  ajaxResultFn: null, // Function that receives the ajax result (JSON decoded) & returns an array of key-value 2-tuples
  ajaxInitialChoices: [], // Array of key-value pairs (id, name) for the initially selected items in the ajax box
  // These should match the form value.
  ajaxPreload: false, // Auto-load ajax results before input starts
  ajaxCache: null, // Whether or not the select2 element should cache results
  ajaxDelay: null, // Number of milliseconds after user stops typing to trigger ajax call
  ajaxMinimumInputLength: null, // Minimum number of input chars to trigger ajax search
};

export default class Select2 extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  focus() {
    this.refs.select2widget.focus();
  }

  getSelectionData() {
    return this.refs.select2widget.getSelectionData();
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className={this.props.formGroupClass}>
        <Label fieldId={this.htmlId} {...this.props} wizard={undefined} />
        <Select2Widget ref="select2widget" htmlId={this.htmlId} {...this.props} />
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

Select2.defaultProps = {
  // ...Select2Widget.defaultProps +
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?

  formGroupClass: 'form-group', // CSS classes for the form-group div
};
