import React from 'react';

const CtaComparisonButtonGroup = ({className}) => (
  <div className={className}>
    <a href="/contact" target="_blank" className="mr-1 btn btn-outline-light-3">
      Contact Us
      <i class="ml-2 fa-solid fa-arrow-up-right-from-square"></i>
    </a>
    <a href="/request-demo" target="_blank" className="mr-1 btn btn-outline-light-3">
      Book a Demo
      <i class="ml-2 fa-solid fa-arrow-up-right-from-square"></i>
    </a>
    <a href="/pricing#comparison-table" target="_blank" className="btn btn-outline-dark">
      Compare All Features
      <i class="ml-2 fa-solid fa-arrow-up-right-from-square"></i>
    </a>
  </div>
);

export default CtaComparisonButtonGroup;
