'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText} from './FormHelpers';

export default class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    let acceptedFileFormats = '*';
    if (this.props.acceptFormat) {
      acceptedFileFormats = this.props.acceptFormat;
    }

    return (
      <div className={this.props.formGroupClass}>
        <Label fieldId={this.htmlId} {...this.props} />
        <div className="custom-file">
          <input
            type="file"
            accept={acceptedFileFormats}
            className="custom-file-input"
            id={this.htmlId}
            name={this.props.fieldName}
            disabled={this.props.disabled}
            onChange={form.onChange}
          />
          <label className="custom-file-label" htmlFor={this.htmlId}>
            {form.value ? form.value.name : <em>Select file...</em>}
          </label>
        </div>
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

FileInput.defaultProps = {
  fieldName: '', // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
  disabled: false, // Control is disabled?

  formGroupClass: 'form-group', // CSS classes for the form-group div

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  onChange: null, // Change handler
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName
};
