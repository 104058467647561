'use strict';

import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import URLHistory from '../../jskit/general/URLHistory';
import MessageBox from '../../js/global/messagebox';
import {postAlertMessage} from '../../js/global/alerts';
import SLAReportList from './SLAReportList.jsx';
import SLAReportForm from './SLAReportForm.jsx';

export default class SLAReportController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      hasLoaded: false,
      items: [],
      validationErrors: {},
      goBackOnCancel: false,
    };
  }

  componentDidMount() {
    const query = URLHistory.queryStringToObject();
    const pop = this.props.hasWriteAccess ? query.pop : null;
    const goBackOnCancel = query.gobackoncancel ? query.gobackoncancel === 'true' : false;
    this.setState({goBackOnCancel});
    URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['pop', 'gobackoncancel']));
    this.loadAllData(pop);
  }

  loadAllData(pop) {
    new Ajax().post({
      url: this.props.listURL,
      data: {},
      encoder: 'json',
      decoder: 'json',
      success: function (data, textStatus, jqXHR, options) {
        this.setState({hasLoaded: true, items: data.items});

        if (pop === 'initial') {
          this.handleAddItemClick();
        } else if (parseInt(pop)) {
          const popIndex = data.items.findIndex((x) => x.id === parseInt(pop));
          if (popIndex >= 0) {
            this.showEditForm(popIndex);
          }
        }
      }.bind(this),
    });
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleAddItemClick(e) {
    if (e) {
      e.preventDefault();
    }

    this.showEditForm(null);
  }

  handleEditItemClick(itemIndex, e) {
    if (e) {
      e.preventDefault();
    }

    this.showEditForm(itemIndex);
  }

  showEditForm(itemIndex) {
    let state;
    this.clearValidationErrors();

    if (null === itemIndex) {
      state = _.clone(this.props.choices.defaults);
    } else {
      state = _.clone(this.state.items[itemIndex]);
    }
    this.refs.form.showModal(state);
  }

  handleDeleteItemClick(itemIndex, e) {
    e.preventDefault();

    const item = this.state.items[itemIndex];
    const msg = 'Are you sure you want to delete "' + item.name + '"?';

    MessageBox.confirmBox(
      msg,
      'Delete SLA Report',
      function () {
        new Ajax().post({
          url: this.props.deleteURL,
          data: {id: item.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data, textStatus, jqXHR, options) {
            if (!data.success) {
              MessageBox.alertBox('Unable to delete SLA report.', 'Delete SLA Report');
            } else {
              this.refs.form.hideModal();
              this.loadAllData();
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleFormSaveClick(e) {
    e.preventDefault();
    this.clearValidationErrors();

    const formData = this.refs.form.getFormData();
    const reportIsBeingAdded = !formData.id;
    const reportName = formData.name;
    let msg = '';
    let title = '';

    if (formData.group_name || formData.group_services_selected.length > 0) {
      msg = 'You have unsaved groups. Are you sure you want to save the form. Group data might be lost';
      title = 'Save SLA Report';
    }

    if (formData.delete_groups.length && formData.new_groups.length === 0) {
      msg =
        'Are you sure you want to delete ' +
        formData.delete_groups.length +
        ' group' +
        (formData.delete_groups.length > 1 ? 's' : '') +
        '?';
      title = 'Delete SLA Report Group';
    }

    if (msg && title) {
      MessageBox.confirmBox(
        msg,
        title,
        function () {
          this.saveForm(reportIsBeingAdded, reportName);
        }.bind(this)
      );
    } else {
      this.saveForm(reportIsBeingAdded, reportName);
    }
  }

  saveForm(reportIsBeingAdded, reportName) {
    new Ajax().post({
      url: this.props.saveURL,
      data: this.refs.form.getFormData(),
      encoder: 'json',
      decoder: 'json',
      success: function (data, textStatus, jqXHR, options) {
        if (data.success) {
          this.refs.form.hideModal();
          this.loadAllData();

          if (reportIsBeingAdded) {
            postAlertMessage(
              `Your New SLA Report &quot;${_.escape(reportName)}&quot; has been added.
                             <a href="${this.props.viewURL.replace('0000', data.data.id)}">View Report</a>.`,
              'success',
              true
            );
          }
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleCancelClick(e) {
    if (this.state.goBackOnCancel) {
      setTimeout(() => history.back(), 300);
    }
    this.refs.form.hideModal();
  }

  handleError(field, error) {
    this.state.validationErrors[field] = error;
    this.setState({validationErrors: this.state.validationErrors});
  }

  render() {
    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">SLA Reports</h1>
          {this.props.hasWriteAccess ? (
            <button onClick={this.handleAddItemClick} className="btn btn-primary">
              New SLA Report
            </button>
          ) : null}
        </div>

        <div className="row">
          <div className="col-lg-8 col-xl-9">
            <SLAReportList
              hasWriteAccess={this.props.hasWriteAccess}
              hasLoaded={this.state.hasLoaded}
              siteURL={this.props.siteURL}
              viewURL={this.props.viewURL}
              viewPDFURL={this.props.viewPDFURL}
              viewXLSURL={this.props.viewXLSURL}
              items={this.state.items}
              onEdit={this.handleEditItemClick}
              onDelete={this.handleDeleteItemClick}
            />
          </div>
          <div className="col-lg-4 col-xl-3 d-none d-lg-block">
            <div className="bg-white px-5 py-4 border" dangerouslySetInnerHTML={{__html: this.props.emptyStateHTML}} />
          </div>
        </div>

        <SLAReportForm
          ref="form"
          siteURL={this.props.siteURL}
          servicesCheckListURL={this.props.servicesCheckListURL}
          reportingGroupsListURL={this.props.reportingGroupsListURL}
          reportingGroupsIsValidURL={this.props.reportingGroupsIsValidURL}
          countReportingGroupsURL={this.props.countReportingGroupsURL}
          choices={this.props.choices}
          errors={this.state.validationErrors}
          onError={this.handleError}
          onCancel={this.handleCancelClick}
          onSave={this.handleFormSaveClick}
        />
      </React.Fragment>
    );
  }
}
