'use strict';

import React from 'react';
import _ from 'underscore';
import Ajax from '../../jskit/general/Ajax';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import Paginator from '../../jskit/react/Paginator';
import ImportSubscribersForm from './ImportSubscribersForm.jsx';

const Tabs = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
  SMS: 'SMS',
  WEBHOOK: 'WEBHOOK',
};

const TabNames = {
  [Tabs.EMAIL]: 'Email',
  [Tabs.SLACK]: 'Slack',
  [Tabs.SMS]: 'SMS',
  [Tabs.WEBHOOK]: 'Webhook',
};

const targetColumnNameMap = {
  [Tabs.EMAIL]: 'Subscriber Email',
  [Tabs.SLACK]: 'Slack Workspace',
  [Tabs.SMS]: 'Subscriber Phone Number',
  [Tabs.WEBHOOK]: 'Subscriber Webhook URL',
};

export default class SubscribersTab extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.state = {
      hideSearchIcon: false,
      items: [],
      total: 0,
      allSelected: false,
      showDeleteModal: false,
      searchResultsLoading: false,
      searchYieldedNoResults: false,
      selectedSubscribers: [],
      selectedTab: Tabs.EMAIL,
    };
    this.loadSubscribersDebounced = _.debounce(this.loadSubscribers, 750);
  }

  loadSubscribers = (q) => {
    let finalURL;
    if (q === undefined || q.target.value.length === 0) {
      finalURL = this.props.subscribersURL;
    } else {
      finalURL = `${this.props.subscribersSearchURL}?q=${encodeURIComponent(q.target.value)}`;
    }
    let sortColumns = ['target'];
    if (this.state.selectedTab === Tabs.WEBHOOK) {
      sortColumns = ['-created_at'];
    } else if (this.state.selectedTab === Tabs.SLACK) {
      sortColumns = ['slack_integration__workspace_name', 'slack_integration__channel_name', '-created_at'];
    }
    let query = {sort: sortColumns, type: this.state.selectedTab};

    if (this.state.items.length > 0) {
      query = _.extend(query, this.refs.paginator.getQueryOffsetLimit());
    }

    this.setState({searchResultsLoading: true}, () => {
      new Ajax().post({
        url: finalURL,
        encoder: 'json',
        data: query,
        decoder: 'json',
        success: function (data) {
          var results = [];
          if (data.items.length > 0) {
            this.setState(
              {items: data.items, total: data.total, searchYieldedNoResults: false, searchResultsLoading: false},
              this.resetSelection
            );
          } else {
            let resultsNotFound = false;
            if (q !== undefined && q.target.value.length > 0) {
              resultsNotFound = true;
            }
            this.setState(
              {items: results, searchYieldedNoResults: resultsNotFound, searchResultsLoading: false},
              this.resetSelection
            );
          }
        }.bind(this),
      });
    });
  };

  toggleSelectAll = () => {
    const currentState = this.state.allSelected;
    if (currentState === true) {
      this.setState({allSelected: false, selectedSubscribers: []});
    } else {
      const allSubIDs = this.state.items.map((item) => {
        return item.id;
      });
      this.setState({allSelected: true, selectedSubscribers: allSubIDs});
    }
  };

  toggleSelection = (subscriberID) => {
    let currentSubs = this.state.selectedSubscribers;
    if (currentSubs.indexOf(subscriberID) !== -1) {
      currentSubs = currentSubs.filter((item) => item !== subscriberID);
    } else {
      currentSubs.push(subscriberID);
    }
    const allSelected = this.state.items.length == currentSubs.length;
    this.setState({selectedSubscribers: currentSubs, allSelected});
  };

  resetSelection() {
    this.setState({allSelected: false, selectedSubscribers: []});
  }

  deleteSubscriber = (subscriberID) => {
    this.setState({showDeleteModal: true, selectedSubscribers: subscriberID});
  };

  confirmDeletion = () => {
    new Ajax().post({
      url: this.props.subscribersDeleteURL,
      data: {id: this.state.selectedSubscribers},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.setState({showDeleteModal: false, selectedSubscribers: [], allSelected: false}, this.loadSubscribers());
        }
      }.bind(this),
    });
  };

  handleManageSubscribersClick(e) {
    e.preventDefault();
    this.refs.importSubscribersForm.showModal();
  }

  handlePageChange = () => {
    this.loadSubscribers();
  };

  componentDidMount() {
    this.loadSubscribers();
  }

  handleTabChange = (newTab, e) => {
    e.preventDefault();
    if (this.refs.paginator) {
      this.refs.paginator.reset();
    }
    this.resetSelection();
    this.refs.searchInput.value = null;
    this.setState({selectedTab: newTab, items: [], total: 0}, this.loadSubscribers);
  };

  disabledNotificationsWarning = () => {
    const {statuspage} = this.props;
    const {selectedTab} = this.state;

    if (
      !statuspage.allow_subscriptions ||
      (selectedTab === Tabs.EMAIL && !statuspage.allow_subscriptions_email) ||
      (selectedTab === Tabs.SMS && !statuspage.allow_subscriptions_sms) ||
      (selectedTab === Tabs.WEBHOOK && !statuspage.allow_subscriptions_webhook) ||
      (selectedTab === Tabs.SLACK && !statuspage.allow_subscriptions_slack)
    ) {
      const disabledNotificationsType = statuspage.allow_subscriptions ? TabNames[selectedTab] : 'Status Page';
      return (
        <div className="border p-4 w-100 border-bottom-0 white-block white-block-border border-radius-bottom-0 border-radius-top-0">
          <div className="warning-panel px-4 py-2">
            <i className="fa fa-exclamation-triangle pr-2" />
            {disabledNotificationsType} Notifications are disabled. Please reactivate notifications. <br />
            <a href="" onClick={this.props.onReactivateClicked}>
              Reactivate Now
            </a>
          </div>
        </div>
      );
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.statuspage.components.length === 0 ? (
          <div className="alert alert-warning" role="alert">
            You need to add components on your status page to import subscribers
          </div>
        ) : null}
        <div className="manage-subscribers-container row mx-0">
          <div className="d-flex justify-content-end pb-3 align-items-center w-100" style={{columnGap: '2rem'}}>
            {this.props.statuspage.components.length > 0 && this.props.hasAdminAccess && (
              <a className="font-weight-bold btn px-1 mr-3" onClick={this.handleManageSubscribersClick}>
                <i className="fa-solid fa-download mr-2"></i>
                Import
              </a>
            )}
            {this.props.hasAdminAccess && (
              <a className="font-weight-bold btn px-1 mr-3" target="_blank" href={this.props.exportSubscribersURL}>
                <i className="fa-solid fa-upload mr-2"></i>
                Export
              </a>
            )}
            <div className="position-relative" style={{width: '20%'}}>
              {!this.state.hideSearchIcon ? (
                <i
                  className="fa-solid fa-magnifying-glass mr-2 position-absolute text-black-50"
                  style={{top: '10px', right: '5px'}}
                ></i>
              ) : null}
              <input
                className="w-100"
                style={{padding: '5px 10px', borderRadius: '4px', border: '1px solid #CED4DA'}}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length > 0) {
                    this.setState({hideSearchIcon: true});
                  } else {
                    this.setState({hideSearchIcon: false});
                  }
                  this.loadSubscribersDebounced(e);
                }}
                placeholder="Search..."
                type="search"
                ref="searchInput"
              />
            </div>
          </div>
          <div>
            <ImportSubscribersForm
              ref="importSubscribersForm"
              importSubscribersURL={this.props.importSubscribersURL}
              importSubscribersTemplateURL={this.props.importSubscribersTemplateURL}
              importSubscribersSampleURL={this.props.importSubscribersSampleURL}
              importSuccessHandler={this.loadSubscribers}
              exportSubscribersURL={this.props.exportSubscribersURL}
              subscribersCount={this.state.total}
            />
          </div>
          <div className="border w-100 border-bottom-0 white-block white-block-border border-radius-bottom-0">
            <ul className="list-inline mb-0 pb-3">
              <li key="tab-email" className="subscribers-tab list-inline-item px-3 pt-3">
                <a
                  href="#"
                  onClick={this.handleTabChange.bind(null, Tabs.EMAIL)}
                  className={ReactUtils.cssClass('tab-title font-16 font-weight-semibold pb-3', {
                    selected: this.state.selectedTab === Tabs.EMAIL,
                  })}
                >
                  {TabNames[Tabs.EMAIL]}
                </a>
              </li>
              <li key="tab-slack" className="subscribers-tab  list-inline-item px-3 pt-3">
                <a
                  href="#"
                  onClick={this.handleTabChange.bind(null, Tabs.SLACK)}
                  className={ReactUtils.cssClass('tab-title font-16 font-weight-semibold pb-3', {
                    selected: this.state.selectedTab === Tabs.SLACK,
                  })}
                >
                  {TabNames[Tabs.SLACK]}
                </a>
              </li>
              <li key="tab-sms" className="subscribers-tab  list-inline-item px-3 pt-3">
                <a
                  href="#"
                  onClick={this.handleTabChange.bind(null, Tabs.SMS)}
                  className={ReactUtils.cssClass('tab-title font-16 font-weight-semibold pb-3', {
                    selected: this.state.selectedTab === Tabs.SMS,
                  })}
                >
                  {TabNames[Tabs.SMS]}
                </a>
              </li>
              <li key="tab-webhook" className="subscribers-tab  list-inline-item px-3 pt-3">
                <a
                  href="#"
                  onClick={this.handleTabChange.bind(null, Tabs.WEBHOOK)}
                  className={ReactUtils.cssClass('tab-title font-16 font-weight-semibold pb-3', {
                    selected: this.state.selectedTab === Tabs.WEBHOOK,
                  })}
                >
                  {TabNames[Tabs.WEBHOOK]}
                </a>
              </li>
            </ul>
          </div>
          {this.disabledNotificationsWarning()}
          <table
            className={ReactUtils.cssClass('table table-bordered mb-0 white-block white-block-border', {
              'table-hover': this.state.items.length > 0,
            })}
          >
            <thead>
              {this.state.items.length === 0 && !this.state.searchResultsLoading ? (
                false
              ) : (
                <tr style={{borderBottom: '2px solid #dbe1e5', width: '100%'}}>
                  <th className="border-0 py-3" style={{width: '25%'}}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        style={{width: '16px', height: '16px'}}
                        onChange={this.toggleSelectAll}
                        checked={this.state.allSelected}
                      />
                      <label className="form-check-label font-16 font-weight-bold ml-2" htmlFor="subscriberCheck">
                        {targetColumnNameMap[this.state.selectedTab]}
                      </label>
                    </div>
                  </th>
                  {this.state.selectedTab === Tabs.SMS && (
                    <th className="border-0 py-3" style={{width: '25%'}}>
                      <h2 className="font-weight-bold font-16 mb-0">Subscription Status</h2>
                    </th>
                  )}
                  {this.state.selectedTab === Tabs.SLACK && (
                    <th className="border-0 py-3" style={{width: '25%'}}>
                      <h2 className="font-weight-bold font-16 mb-0">Channel</h2>
                    </th>
                  )}
                  <th className="border-0 py-3" style={{width: '25%'}}>
                    <h2 className="font-weight-bold font-16 mb-0">Date Added</h2>
                  </th>
                  {this.state.selectedSubscribers.length >= 1 ? (
                    <th className="border-0 font-weight-bold font-16 text-right px-3" style={{width: '25%'}}>
                      <button
                        className="font-16 text-dark"
                        style={{
                          background: '#F8F9FA',
                          border: 'none',
                          paddingInline: '5px',
                          borderRadius: '2px',
                          paddingBlock: '3px',
                        }}
                        onClick={() => this.deleteSubscriber(this.state.selectedSubscribers)}
                        data-toggle="modal"
                        data-target="#staticBackdrop"
                      >
                        Delete Selected
                        <i className="fa-regular fa-trash font-18 ml-2" style={{color: '#131C22'}}></i>
                      </button>
                    </th>
                  ) : (
                    <th className="border-0 font-weight-bold font-16 text-right px-3" style={{width: '25%'}}></th>
                  )}
                </tr>
              )}
            </thead>
            <tbody>
              {this.state.items.length > 0 ? (
                <>
                  {this.state.items.map((item, index) => (
                    <tr key={index} className="border-bottom" style={{width: '100%'}}>
                      <td className="border-0" style={{width: '25%'}}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            style={{width: '16px', height: '16px'}}
                            onChange={() => {
                              this.toggleSelection(item.id);
                            }}
                            checked={this.state.selectedSubscribers.indexOf(item.id) !== -1}
                          />
                          <label className="form-check-label font-16 ml-2 font-weight-medium" htmlFor="subscriberCheck">
                            {this.state.selectedTab === Tabs.SLACK
                              ? item.slack_integration?.workspace_name
                              : item.target}
                          </label>
                        </div>
                      </td>
                      {this.state.selectedTab === Tabs.SMS && (
                        <td className="border-0" style={{width: '25%'}}>
                          <span className="font-14 d-inline-block" style={{marginTop: '2px'}}>
                            {item.target_verified_on ? 'Active' : 'Unconfirmed'}
                          </span>
                        </td>
                      )}
                      {this.state.selectedTab === Tabs.SLACK && (
                        <td className="border-0" style={{width: '25%'}}>
                          <span className="font-14 d-inline-block" style={{marginTop: '2px'}}>
                            {item.slack_integration?.channel_name}
                          </span>
                        </td>
                      )}
                      <td className="border-0" style={{width: '25%'}}>
                        <span className="font-14 d-inline-block" style={{marginTop: '2px'}}>
                          {item.created_at}
                        </span>
                      </td>
                      <td className="border-0 text-right" style={{width: '25%'}}>
                        <button className="btn px-2 p-0" onClick={() => this.deleteSubscriber([item.id])}>
                          <i
                            className="fa-regular fa-trash font-18"
                            style={{color: '#131C22'}}
                            data-toggle="modal"
                            data-target="#staticBackdrop"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={12}>
                    {!this.state.searchResultsLoading && (
                      <div className="d-flex justify-content-center align-items-center flex-column p-5">
                        <img
                          src="/static/img/statuspage/subscribers-placeholder.svg"
                          alt="search-subscribers"
                          style={{width: '150px'}}
                        />
                        {this.state.searchYieldedNoResults === false ? (
                          <React.Fragment>
                            <p className="mt-3 font-18 font-weight-500">
                              You don't have any subscribers to this Status Page yet.
                            </p>
                            {this.props.hasAdminAccess &&
                            this.props.statuspage.components.length > 0 &&
                            this.state.selectedTab === Tabs.EMAIL ? (
                              <button
                                className="btn btn-outline-success font-weight-semibold"
                                onClick={this.handleManageSubscribersClick}
                              >
                                Import Subscribers
                              </button>
                            ) : null}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <p className="mt-3 font-18 font-weight-500">
                              No subscribers found matching your search criteria.
                            </p>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {this.state.showDeleteModal ? (
            <div
              className="modal fade"
              id="staticBackdrop"
              data-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Delete Subscriber(s)
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p className="font-weight-medium font-16">Are you sure you want to delete the Subscriber(s)?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-primary"
                      onClick={() => {
                        this.confirmDeletion();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {this.state.items.length > 0 ? (
            <div className="mt-2 w-100 py-3">
              <Paginator ref="paginator" totalRecords={this.state.total || 0} onPageChange={this.handlePageChange} />
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

SubscribersTab.defaultProps = {
  subscribersURL: null,
  statuspage: null,
};
