'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export default class ShowPassword extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);

    this.state = {
      passwordShown: false,
    };
    this.handleShowPasswordBtnClick = this.handleShowPasswordBtnClick.bind(this);
  }

  focus() {
    const input = this.refs.showPassword;
    const length = input.value.length;
    input.focus();

    // place cursor at the end of the string
    input.setSelectionRange(length, length);
  }

  handleShowPasswordBtnClick() {
    this.setState({passwordShown: !this.state.passwordShown});
    this.focus();
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className="form-group">
        <Label fieldId={this.htmlId} {...this.props} />
        <div className="input-group">
          <input
            ref="showPassword"
            type={this.state.passwordShown ? 'text' : 'password'}
            id={this.htmlId}
            name={form.fieldName}
            className={ReactUtils.cssClass('form-control', {['is-invalid']: form.errors})}
            autoComplete="new-password"
            disabled={this.props.disabled}
            maxLength={this.props.maxLength}
            value={form.value}
            onChange={form.onChange}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
          <div className="input-group-append">
            <button className="btn btn-default" type="button" onClick={this.handleShowPasswordBtnClick}>
              <i className={this.state.passwordShown ? 'fa fa-eye-slash' : 'fa fa-eye'} aria-hidden="true" />
            </button>
          </div>
          <FieldErrors errors={form.errors} />
          <FieldHelpText {...this.props} />
        </div>
      </div>
    );
  }
}

ShowPassword.defaultProps = {
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
  disabled: false, // Control is disabled?
  maxLength: null, // HTML maxlength for this field

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
  onFocus: null, // Focus handler
  onBlur: null, // Blur handler
};
