'use strict';

import React from 'react';

class URLPatternsRow extends React.PureComponent {
  render() {
    const groupLabel = this.props.group ? 'Change/Remove Group' : 'Set Group';

    const buttons = (
      <div className="dropdown">
        <button
          type="button"
          className="btn btn-xs dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        ></button>
        <div className="dropdown-menu dropdown-menu-right">
          <a href="#" className="dropdown-item" onClick={this.props.onEditPattern}>
            Edit URL Prefix
          </a>
          <a href="#" className="dropdown-item" onClick={this.props.onDeletePattern}>
            Delete URL Prefix
          </a>
          <a href="#" className="dropdown-item" onClick={this.props.onChangeGroup}>
            {groupLabel}
          </a>
          {this.props.group ? (
            <React.Fragment>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item" onClick={this.props.onDeleteGroup}>
                Delete Group &quot;{this.props.group.name}&quot;
              </a>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );

    return (
      <tr>
        <td>{this.props.pattern}</td>
        <td>{this.props.group ? <span className="badge badge-info font-12">{this.props.group.name}</span> : null}</td>
        <td className="actions-right">{buttons}</td>
      </tr>
    );
  }
}

export default class URLPatternsList extends React.PureComponent {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return <div>No patterns found.</div>;
    }

    const nodes = this.props.items.map((item, index) => (
      <URLPatternsRow
        key={item.id}
        onChangeGroup={this.props.onChangeGroup.bind(null, index)}
        onDeleteGroup={this.props.onDeleteGroup.bind(null, index)}
        onEditPattern={this.props.onEditPattern.bind(null, index)}
        onDeletePattern={this.props.onDeletePattern.bind(null, index)}
        {...item}
      />
    ));

    return (
      <div className="white-block">
        <table className="table table-responsive-sm data-table" role="grid">
          <thead>
            <tr>
              <th>URL Prefix</th>
              <th>Group</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>/</td>
              <td>
                <span style={{opacity: 0.35}} className="badge badge-info font-12">
                  Homepage
                </span>
              </td>
              <td></td>
            </tr>
            {nodes}
          </tbody>
        </table>
      </div>
    );
  }
}
