import 'vite/modulepreload-polyfill';
import {createElement} from 'react';
import ReactDOM from 'react-dom';
import './main.css';
import 'react-toastify/dist/ReactToastify.css';

import Cookie from './jskit/general/Cookie';
import Ajax from './jskit/general/Ajax';
import Utils from './jskit/general/Utils';
import MapProjections from './jskit/general/MapProjections';
import MessageBox from './js/global/messagebox';
import URLHistory from './jskit/general/URLHistory';
import initializeAppcues from './js/global/appcues';
import {initializeJQueryPlugins, initializeAdmin} from './legacy/uptime';

import AlertHistoryController from './reports/alert_history/AlertHistoryController';
import SLAReportController from './reports/sla_report/SLAReportController';
import ScheduledReportController from './reports/scheduled_report/ScheduledReportController';
import StatusPageController from './status_pages/public/StatusPageController';
import StatusPageManageController from './status_pages/manage/StatusPageManageController';
import StatusPageDisplayController from './status_pages/display/StatusPageDisplayController';
import ServicesController from './devices/services/ServicesController';
import AuditLogController from './audit/AuditLogController';
import ContactGroupsController from './notifications/contact_groups/ContactGroupsController';
import IntegrationController from './notifications/integrations/IntegrationController';
import AccountUsersController from './accounts/account-users/AccountUsersController';
import SubscriptionController from './billing/subscription/SubscriptionController';
import SubscriptionFreeTrialController from './billing/subscription/SubscriptionFreeTrialController';
import PaymentMethodController from './billing/subscription/PaymentMethodController';
import SwitchToAnnualController from './billing/subscription/SwitchToAnnualController';
import ManageSubscriptionController from './billing/subscription/ManageSubscriptionController';
import UptimeTestResults from './freetools/UptimeTestResults';
import SpeedTestResults from './freetools/SpeedTestResults';
import PagespeedTestController from './freetools/PagespeedTestController';
import DomainHealthController from './domaintools/DomainHealthController';
import DashboardController from './website/dashboard/DashboardController';
import LaunchpadController from './launchpad/LaunchpadController';
import PushNotificationsController from './adminx/PushNotificationsController';
import RecorderController from './transactions/RecorderController';
import RealTimeAnalysisController from './devices/realtime/RealTimeAnalysisController';
import PagespeedReport from './pagespeed/PagespeedReport';
import URLPatternsController from './rum/v1/URLPatternsController';
import RumChecksListController from './rum/v2/RumChecksListController';
import RumCheckDetailController from './rum/v2/RumCheckDetailController';
import PrivateLocationsController from './monitoring/private_locations/PrivateLocationsController';

// Global initialization
initializeJQueryPlugins();

// Expose React components
window['mainModule'] = {
  // devices pages
  ServicesController,
  RealTimeAnalysisController,

  // launchpad
  LaunchpadController,

  // reports
  AlertHistoryController,
  SLAReportController,
  ScheduledReportController,
  RumChecksListController,

  // notifications
  ContactGroupsController,
  IntegrationController,

  // status pages
  StatusPageController,
  StatusPageManageController,
  StatusPageDisplayController,

  // settings
  AccountUsersController,
  AuditLogController,

  // billing
  SubscriptionController,
  SubscriptionFreeTrialController,
  PaymentMethodController,
  SwitchToAnnualController,
  ManageSubscriptionController,

  // freetools
  UptimeTestResults,
  SpeedTestResults,
  DomainHealthController,
  PagespeedTestController,

  // website
  DashboardController,

  // admin
  PushNotificationsController,
  initializeAdmin,

  // recorder
  RecorderController,

  // pagespeed
  PagespeedReport,

  // rum
  URLPatternsController,
  RumCheckDetailController,

  // monitoring
  PrivateLocationsController,

  // react
  createElement,
  ReactDOM,

  // global
  Ajax,
  Cookie,
  MapProjections,
  MessageBox,
  URLHistory,
  Utils,
  initializeAppcues,
};
