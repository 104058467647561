import React from 'react';
import {DeepMap, FieldError, useController, useFormContext} from 'react-hook-form';
import {HelpIcon} from '../../../components/HelpIcon';
import {FormLabel} from '../../../components/form/FormLabel';
import {FormCheckBox} from '../../../components/form/FormCheckBox';
import {VisibilityLevel} from './StatusPageEnums';

export function FormPublicUrlSlugInput({
  checkBoxName,
  errors,
  publicViewUrl,
  siteUrl,
  slugName,
}: {
  checkBoxName: string;
  siteUrl: string;
  publicViewUrl: string;
  slugName: string;
  errors: DeepMap<any, FieldError>;
}) {
  const {watch, control} = useFormContext();
  const {field: slug} = useController({
    name: slugName,
    control,
    defaultValue: '',
  });
  const publicCheckBox = watch(checkBoxName);
  return (
    <div className="mb-4">
      <div className={'form-group d-flex'}>
        <FormCheckBox
          control={control}
          label="Make this page available publicly"
          stringValues={{true: VisibilityLevel.PUBLIC, false: VisibilityLevel.UPTIME_USERS}}
          name={checkBoxName}
        />
        <HelpIcon helpText="Make this Page publicly accessible at the URL below." />
      </div>
      {publicCheckBox === VisibilityLevel.PUBLIC && (
        <div className="form-group">
          <FormLabel
            formId={slugName}
            textStyle={{fontWeight: 700}}
            required
            helpText="This is the URL path/foldername that you can customize (letters, numbers and hyphens only)."
          >
            Public URL
          </FormLabel>
          <div className={'input-group'}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                {siteUrl + publicViewUrl}
              </span>
            </div>
            <input
              id={slugName}
              type="text"
              name={slugName}
              placeholder="URL of the Status Page"
              {...slug}
              className={`form-control ${errors[slugName] ? 'is-invalid' : ''}`}
            />
            {errors[slugName] && (
              <div className="invalid-feedback d-block font-weight-bold">{errors[slugName].message}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
