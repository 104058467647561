'use strict';

import moment from 'moment-timezone';
import URLHistory from '../../jskit/general/URLHistory';
import Formatter from '../../jskit/general/Formatter';

import $ from 'jquery';

/*
 * Enables a date range picker on the text input boxes.
 *
 * Specifier: <input
 *                data-daterangepicker
 *                data-daterangepicker-start="YYYYMMDD"
 *                data-daterangepicker-end="YYYYMMDD"
 *                data-daterangepicker-last-24h="true|false"
 *                />
 *
 * Markup:
 *   <form method="get" class="form-inline justify-content-md-end">
 *     <input type="hidden" name="start"/>
 *     <input type="hidden" name="end"/>
 *     <input data-daterangepicker aria-label="Date Range" type="text"
 *         class="form-control light-shadow daterangepicker-textinput"/>
 *     <span class="caret"></span>
 *   </form>
 */
export function initializeDateRangePicker() {
  $('[data-daterangepicker]').each(function () {
    const elem = $(this);
    const form = elem.closest('form');
    const timezone = window.TIMEZONE || 'utc';
    const startHidden = form.find('input[name=start]');
    const endHidden = form.find('input[name=end]');
    const caret = form.find('span.caret');
    const initStart = moment.tz(elem.data('daterangepicker-start'), timezone);
    const initEnd = moment.tz(elem.data('daterangepicker-end'), timezone);
    const minDate = elem.data('daterangepicker-mindate')
      ? moment(elem.data('daterangepicker-mindate'), 'YYYY-MM-DD').tz(timezone)
      : false;
    const useLast24h = elem.data('daterangepicker-last-24h');
    const range24h = {'Last 24 Hours': [null, null]};
    const now = moment.tz(timezone);

    const otherRanges = {
      Today: [moment(now), moment(now)],
      Yesterday: [moment(now).subtract(1, 'days'), moment(now).subtract(1, 'days')],
      'Last 7 Days': [moment(now).subtract(6, 'days'), moment(now)],
      'Last 30 Days': [moment(now).subtract(29, 'days'), moment(now)],
      'This Week': [moment(now).subtract(now.isoWeekday() - 1, 'days'), moment(now)],
      // eslint-disable-next-line max-len
      'Last Week': [
        moment(now).subtract(now.isoWeekday() - 1 + 7, 'days'),
        moment(now).subtract(now.isoWeekday() - 1 + 1, 'days'),
      ],
      'This Month': [moment(now).startOf('month'), moment(now)],
      // eslint-disable-next-line max-len
      'Last Month': [
        moment(now).subtract(1, 'month').startOf('month'),
        moment(now).subtract(1, 'month').endOf('month'),
      ],
      'This Year': [moment(now).startOf('year'), moment(now).endOf('year')],
      // eslint-disable-next-line max-len
      'Last Year': [moment(now).subtract(1, 'year').startOf('year'), moment(now).subtract(1, 'year').endOf('year')],
    };

    const ranges = Object.assign({}, useLast24h ? range24h : {}, otherRanges);
    const maxDate = moment.tz(timezone).endOf('day');

    if (minDate && maxDate) {
      Object.keys(ranges).forEach((key) => {
        const start = ranges[key][0];
        const end = ranges[key][1];
        if (minDate && start && end) {
          if (start < minDate || end > maxDate) delete ranges[key];
        }
      });
    }

    function daterangeCallback(start, end) {
      startHidden.val(start.isValid() ? start.format('YYYYMMDD') : '');
      endHidden.val(end.isValid() ? end.format('YYYYMMDD') : '');
    }

    elem.daterangepicker(
      {
        drops: 'down',
        opens: 'left',
        autoUpdateInput: true,
        autoApply: true,
        startDate: initStart ? initStart : now,
        endDate: initEnd ? initEnd : now,
        minDate: minDate,
        maxDate: maxDate,
        alwaysShowCalendars: true,
        showCustomRangeLabel: true,
        ranges: ranges,
        locale: {
          format: Formatter.getCurrentDateFormattedString('D MMM YYYY'),
          separator: ' to ',
        },
      },
      daterangeCallback
    );

    var picker = elem.data('daterangepicker');
    daterangeCallback(picker.startDate, picker.endDate);

    elem.on('apply.daterangepicker', function () {
      form.submit();
    });
    caret.on('click', function () {
      elem.focus();
    });

    form.on('submit', function (e) {
      e.preventDefault();
      window.location = URLHistory.updateQueryString(null, {
        [startHidden.attr('name')]: startHidden.val(),
        [endHidden.attr('name')]: endHidden.val(),
      });
    });
  });
}
