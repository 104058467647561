import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';

export default class AccountUserButtonBar extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      // NOTE: Currently this class has support for multiple filters & search,
      // similar to the Services page, of which we currently only use the
      // subaccount filter. We intentionally leave that additional code in place
      // for ease of extensibility in future.
      filters: this._clearFilters(),
      search: '',
    };

    this.debouncedTriggerFilterChanged = _.debounce(this.triggerFilterChanged, 750);
  }

  _clearFilters() {
    return {
      subaccount: '',
    };
  }

  getQueryFilter() {
    const query = _.clone(this.state);

    // Remove empty filters so we don't filter on blanks
    query.filters = _.omit(query.filters, function (v) {
      return !v || v.length === 0;
    });
    return query;
  }

  handleSubaccountsFilterChange(key, e) {
    e.preventDefault();
    const newValue = this.state.filters.subaccount === key ? '' : key;
    const filters = Object.assign({}, this.state.filters, {subaccount: newValue});
    this.setState({filters: filters}, this.triggerFilterChanged);
  }

  handleSearchChange(e) {
    this.setState({search: e.target.value}, this.debouncedTriggerFilterChanged);
  }

  handleFiltersClear(e) {
    this.setState({filters: this._clearFilters(), search: ''}, this.triggerFilterChanged);
  }

  triggerFilterChanged() {
    if (this.props.onFilterChange) {
      this.props.onFilterChange();
    }
  }

  isFilterSet() {
    const f = this.state.filters;
    return this.state.search || f.subaccount;
  }

  render() {
    if (!this.props.subaccountsFilter) {
      return null;
    }

    return (
      <React.Fragment>
        <div id="buttonBar" className="d-flex flex-wrap align-items-center mb-3">
          <div className="mr-3 mb-2">
            <span className="d-inline-block mr-3 mb-1">Filter</span>
            {this.renderSubaccountsFilter(this.props.subaccountsFilter, this.state.filters.subaccount)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSubaccountsFilter(choices, selected) {
    const filterIsActive = !!selected;
    const selectedOption = choices.find((x) => x[0] === selected);
    const renderedOptions = choices.map((x) => {
      const [key, label] = x;
      return (
        <a
          key={key}
          href="#"
          onClick={this.handleSubaccountsFilterChange.bind(null, key)}
          className={ReactUtils.cssClass('dropdown-item', {active: key === selected})}
        >
          {label}
        </a>
      );
    });

    return (
      <div className="dropdown d-inline-block">
        <button
          id="button-bar-subaccount"
          type="button"
          className={ReactUtils.cssClass('btn btn-sm btn-toggle dropdown-toggle', {active: filterIsActive})}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedOption ? selectedOption[1] : 'All Subaccounts'}
        </button>
        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right" aria-labelledby="button-bar-check-type">
          <a href="#" onClick={this.handleSubaccountsFilterChange.bind(null, null)} className="dropdown-item">
            All Subaccounts
          </a>
          <div key="at" className="dropdown-divider"></div>
          {renderedOptions}
        </div>
      </div>
    );
  }
}
