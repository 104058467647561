'use strict';

import $ from 'jquery';

function _clickBackButton() {
  try {
    const loopSentinelThreshold = 50;
    var operationCount = 0;
    const iframeContent = $('#webWidget').contents();
    var iconButtons = [];
    // if the user was sequentially opening multiple support articles, they
    // all will be in the navigation stack of the widget, so we'll need to 'click'
    // the 'back' button as many times as how many articles were open. We'll add
    // a reasonable max iterations sentinel to avoid dead loops here
    while (operationCount < loopSentinelThreshold) {
      operationCount++;
      iconButtons = iframeContent.find(
        'div[data-embed=helpCenterForm] div header div button[data-garden-id="buttons.icon_button"]'
      );
      if (iconButtons.length !== 2) {
        break;
      }
      iconButtons[0].click();
    }
  } catch (e) {
    return undefined;
  }
}

export function openZendeskArticle(articleId) {
  if (!articleId) {
    return;
  }
  _clickBackButton();
  if (window.zE) {
    // suppress chat and answer bots to open instantly
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        answerBot: {
          suppress: true,
        },
      },
    });
    window.zE.activate();
    window.zE.setHelpCenterSuggestions({search: articleId});
  } else {
    const url = buildZendeskUrlFromArticleId(articleId);
    window.open(url, '_blank');
  }
}

export function initialize() {
  if (window.zE) {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        chat: {
          title: 'Chat with Uptime',
          concierge: {
            name: 'Uptime',
            title: 'Have a question?',
          },
        },
      },
    });
    window.zE('webWidget:on', 'open', function () {
      window.zE('webWidget', 'reset');
    });
    window.zE('webWidget:on', 'close', function () {
      // if previously suppressed - restore to enable normal functionality
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          answerBot: {
            suppress: false,
          },
        },
      });
    });
  }
  $('a[data-zd-article-id]').on('click', function (e) {
    var articleId = $(this).attr('data-zd-article-id') || extractZendeskArticleIdFromUrl($(this).attr('href'));
    if (articleId) {
      e.preventDefault();
      openZendeskArticle(articleId);
    }
  });
}

export function extractZendeskArticleIdFromUrl(url) {
  const urlParts = url.split('/');
  if (urlParts.length <= 0) {
    return null;
  }
  return urlParts[urlParts.length - 1];
}

export function buildZendeskUrlFromArticleId(articleId) {
  return 'https://support.uptime.com/hc/en-us/articles/' + articleId;
}
