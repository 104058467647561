'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import CheckBox from '../../jskit/react/forms/CheckBox';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';
import {SingleServiceSelectBox} from '../../devices/common/DevicesCommon';

export default class SystemMetricsForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {},
      validationErrors: {},
      serviceInitialChoices: [],
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialData) {
    if (initialData) {
      this.setState({
        formData: Object.assign({}, initialData || {}),
        serviceInitialChoices: [[initialData.service, initialData.service_name]],
      });
    } else {
      this.setState({
        formData: {is_visible: true},
        serviceInitialChoices: [],
      });
    }
    this.clearValidationErrors();
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleServiceChanged() {
    // Prefill the metric name if it's empty
    if (!this.getFormData().name) {
      const service = this.refs.serviceSelectBox.getSelectionData();
      if (service) {
        this.setState({formData: Object.assign({}, this.getFormData(), {name: service.name})});
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.clearValidationErrors();

    new Ajax().post({
      url: this.props.updateURL,
      data: this.getFormData(),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.props.onNewData(data.data);
          this.hideModal();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    const formData = this.getFormData();
    const title = formData.id ? 'Edit System Metric' : 'Add System Metric';

    return (
      <Modal ref="modal" title={title} saveButton={this.handleSubmit} cancelButton={true}>
        <form ref="form" className="form-spaced" onSubmit={this.handleSubmit}>
          {this.renderForm(formLink, formData)}
        </form>
      </Modal>
    );
  }

  renderForm(formLink) {
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="col-lg-12 font-14">
            {/* eslint-disable-next-line max-len */}
            System metric, such as response time graphs, add visibility into critical system performance on your public
            status page.
            {/* eslint-disable-next-line max-len */}
            &nbsp;
            <ZendeskSupportLink href="https://support.uptime.com/hc/en-us/articles/360016256840-Status-Page-Forms-and-Fields#metrics">
              View&nbsp;Documentation&nbsp;&rarr;
            </ZendeskSupportLink>
          </div>
        </div>
        <FormErrors errors={this.state.validationErrors.__all__} />
        <SingleServiceSelectBox
          ref="serviceSelectBox"
          servicesCheckListURL={this.props.servicesCheckListURL}
          ajaxPreload={true}
          minimumResultsForSearch={0}
          ajaxLimit={50}
          fieldName="service"
          labelText="Associated Check"
          titleText="Link this System Metric to a check to display response time graph."
          isRequired={true}
          formLink={formLink}
          ajaxInitialChoices={this.state.serviceInitialChoices}
          onChange={this.handleServiceChanged}
        />
        <TextInput fieldName="name" labelText="Metric Display Name" isRequired={true} formLink={formLink} />
        <div className="form-group">
          <CheckBox
            fieldName="is_visible"
            labelText="Display this metric in the System Metrics section of the page"
            isRequired={false}
            formLink={formLink}
          />
        </div>
      </React.Fragment>
    );
  }
}

SystemMetricsForm.defaultProps = {
  updateURL: null,
  servicesCheckListURL: null,
  onNewData: null,
};
