import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import PaymentMethodForm from './PaymentMethodForm.jsx';

export default class PaymentMethodController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      paymentProcessorErrors: null,
      expanded: !this.props.paymentMethod.pm_type,
      recaptchaRequired: false,
      recaptchaResponseToken: null,
    };
    Utils.autoBindClass(this);
  }

  handlePaymentMethodReceived(data) {
    this.setState({checkoutErrors: null, recaptchaRequired: false});
    data['recaptcha_response_token'] = this.state.recaptchaResponseToken;
    new Ajax().post({
      url: this.props.saveURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          window.location = this.props.successURL + '?action=payment-method';
        } else {
          this.setState({submitting: false});
          if (data.fields.payment_fields) {
            this.setPaymentValidationErrors(data.fields.payment_fields);
          }
          if (data.fields.__all__) {
            this.setPaymentProcessorErrors(data.fields.__all__);
            this.setState({
              recaptchaRequired: data.fields.recaptcha_required || false,
              recaptchaResponseToken: null,
            });
          }
        }
      }.bind(this),
      error: function () {
        this.setState({submitting: false});
      },
    });
  }

  handlePaymentMethodError(event) {
    this.setState({submitting: false});
  }

  handleExpand() {
    this.setState({expanded: true});
  }

  handleSave(e) {
    e.preventDefault();
    this.refs.paymentForm.clearValidationErrors();
    if (this.refs.paymentForm.handleSubscribeSubmit()) {
      this.setState({submitting: true, paymentProcessorErrors: null});
    }
  }

  setPaymentValidationErrors(fields) {
    this.refs.paymentForm.setValidationErrors(fields);
  }

  setPaymentProcessorErrors(fields) {
    this.setState({paymentProcessorErrors: fields}, () => {
      // bring it to the top, otherwise it is not visible
      document.getElementById('payment-processor-errors').scrollIntoView({block: 'end'});
    });
  }

  clearPaymentValidationErrors() {
    this.refs.paymentForm.clearValidationErrors();
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-lg-8">
          {this.renderPaymentProcessorErrors()}
          <PaymentMethodForm
            ref="paymentForm"
            paymentMethod={this.props.paymentMethod}
            braintreeToken={this.props.braintreeToken}
            initialData={this.props.billingInfo}
            readOnly={this.props.readOnly}
            forceOpen={false}
            onSuccess={this.handlePaymentMethodReceived}
            onError={this.handlePaymentMethodError}
            onExpand={this.handleExpand}
            allCountries={this.props.allCountries}
            excludeStateCountries={this.props.excludeStateCountries}
            showStateDropdownForCountries={this.props.showStateDropdownForCountries}
            recaptchaSiteKey={this.props.recaptchaSiteKey}
            recaptchaRequired={this.state.recaptchaRequired}
            recaptchaSolved={this.state.recaptchaResponseToken !== null}
            recaptchaCallback={(token) => {
              this.setState({recaptchaResponseToken: token});
            }}
            {...this.props}
          />

          {this.renderSubmitButton()}
        </div>
      </div>
    );
  }

  renderSubmitButton() {
    if (!this.state.expanded) {
      return false;
    }
    return (
      <div className="row form-row">
        <div className="ml-auto">
          <button className="btn btn-primary" onClick={this.handleSave} disabled={this.state.submitting}>
            {this.state.submitting ? 'Working...' : 'Save Payment Information'}
          </button>
        </div>
      </div>
    );
  }

  renderPaymentProcessorErrors() {
    if (!this.state.paymentProcessorErrors || !this.state.paymentProcessorErrors.length) {
      return false;
    }
    const errors = this.state.paymentProcessorErrors.reduce((a, el) => a + el, '');
    return (
      <div id="payment-processor-errors" className="alert alert-danger">
        {errors}
      </div>
    );
  }
}

PaymentMethodController.defaultProps = {
  saveURL: null,
};
