/**
 * Date range selection component.
 *
 * This component does not keep internal state, so the current start & end date
 * selection (as `moment` objects or `null`) are passed in as props.
 *
 * It uses a few different formats for start & end dates to handle a couple of use cases.
 *
 * - If both startDate & endDate are `null`, it displays `props.nullDateLabel` and the
 *   actual date range is defined by the parent component.
 *
 * - If both startDate & endDate are `moment` objects, they represent the current date range
 *   (of full days - 00:00:00 to 23:59:59)
 *
 * - If start is `null` and endDate is a `moment` object, the date range it represents
 *   is "last XXX mins/hours", defined by the difference between
 *   `endDate.startOf('day')` and `endDate` (with each second representing 1 minute).
 *   For this case you should call `getCurrentDateRange()` whenever using the date range
 *   (eg in Ajax loads) to recalculate an updated date range back from `now`.
 *
 *   NOTE: The weird calculation of 1 second representing 1 minute is required to ensure
 *   the actual date object itself always represents "today", and the correct days are
 *   shown on the calendars.
 */
'use strict';

import $ from 'jquery';

import moment from 'moment-timezone';
import React from 'react';
import Utils from '../../../jskit/general/Utils';

export default class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.dateRanges = this.createDateRanges(props);

    // bootstrap-daterangepicker goes insane if we set it to a null date
    // so, this sentinel value that is unused by any date range will represent
    // null inside the daterangepicker component, while still showing the date
    // of "today" for the calendars
    this.nullDateSentinel = moment.tz(props.timezone).startOf('day').add(1, 'seconds');
  }

  createDateRanges(props) {
    const now = moment.tz(props.timezone);
    const todayStart = moment(now).startOf('day');
    const todayEnd = moment(now).endOf('day');

    return [
      {key: 'live', label: props.nullDateLabel, start: null, end: null},
      {key: '30m', label: 'Last 30 Minutes', start: null, end: moment(todayStart).add(30, 'seconds')},
      {key: '1h', label: 'Last 1 Hour', start: null, end: moment(todayStart).add(60, 'seconds')},
      {key: '3h', label: 'Last 3 Hours', start: null, end: moment(todayStart).add(180, 'seconds')},
      {key: '12h', label: 'Last 12 Hours', start: null, end: moment(todayStart).add(720, 'seconds')},
      {key: '24h', label: 'Last 24 Hours', start: null, end: moment(todayStart).add(1440, 'seconds')},
      {key: 'day0', label: 'Today', start: todayStart, end: todayEnd},
      {
        key: 'day1',
        label: 'Yesterday',
        start: moment(todayStart).subtract(1, 'days'),
        end: moment(todayEnd).subtract(1, 'days'),
      },
      {key: '7d', label: 'Last 7 Days', start: moment(todayStart).subtract(6, 'days'), end: todayEnd},
      {key: '30d', label: 'Last 30 Days', start: moment(todayStart).subtract(29, 'days'), end: todayEnd},
      {key: 'mon0', label: 'This Month', start: moment(now).startOf('month'), end: todayEnd},
      {
        key: 'mon1',
        label: 'Last Month',
        start: moment(now).subtract(1, 'month').startOf('month'),
        end: moment(now).subtract(1, 'month').endOf('month'),
      },
      {key: 'yr0', label: 'This Year', start: moment(now).startOf('year'), end: todayEnd},
      {
        key: 'yr1',
        label: 'Last Year',
        start: moment(now).subtract(1, 'year').startOf('year'),
        end: moment(now).subtract(1, 'year').endOf('year'),
      },
    ];
  }

  componentDidMount() {
    const minDate = moment(this.props.minDate).local().tz('GMT', true);
    const maxDate = moment.tz(this.props.timezone).endOf('day');

    const enabledDateRanges = this.props.enabledDateRanges.split(',');
    const enabledDateRangesForPicker = this.dateRanges.filter((r) => {
      if (minDate && r.start && r.end) {
        if (r.start < minDate || r.end > maxDate) return false;
      }
      return enabledDateRanges.includes(r.key);
    });

    if (minDate.isValid()) {
      enabledDateRangesForPicker.push({
        key: 'available',
        label: 'Available Range',
        start: minDate,
        end: maxDate,
      });
    }

    const pickerRanges = enabledDateRangesForPicker.reduce((acc, cur) => {
      acc[cur.label] = [cur.start || this.nullDateSentinel, cur.end || this.nullDateSentinel];
      return acc;
    }, {});

    const $picker = $(this.refs.picker);

    $picker.daterangepicker(
      {
        drops: 'down',
        opens: this.props.openDirection,
        autoUpdateInput: false,
        autoApply: true,
        alwaysShowCalendars: true,
        showCustomRangeLabel: true,
        ranges: pickerRanges,
        minDate: this.props.minDate,
        maxDate: moment(),

        // Note: this will be hidden by CSS but is required for the moment
        // objects to include times
        timePicker: true,

        locale: {
          format: this.props.dateFormat,
          separator: ' to ',
        },
      },
      this.handleChange
    );

    // Currently using the constructor change handler
    // $picker.on('apply.daterangepicker', this.handleApply);

    // Set initial date range
    this.componentDidUpdate({}, {});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
      const pickerStartDate = this.props.startDate ? moment.tz(this.props.startDate, this.props.timezone) : null;
      const pickerEndDate = this.props.endDate ? moment.tz(this.props.endDate, this.props.timezone) : null;
      $(this.refs.picker)
        .data('daterangepicker')
        .setStartDate(pickerStartDate || this.nullDateSentinel);
      $(this.refs.picker)
        .data('daterangepicker')
        .setEndDate(pickerEndDate || this.nullDateSentinel);
      $(this.refs.picker).val(this.formatLabelForDateRange(pickerStartDate, pickerEndDate));
    }
  }

  componentWillUnmount() {
    // $(this.refs.picker).off('apply.daterangepicker');
    if (this.refs.picker) {
      const picker = $(this.refs.picker).data('daterangepicker');
      if (picker) {
        picker.remove();
      }
    }
  }

  formatLabelForDateRange(startDate, endDate) {
    for (const range of this.dateRanges) {
      if (
        (range.start === startDate || (range.start !== null && range.start.isSame(startDate))) &&
        (range.end === endDate || (range.end !== null && range.end.isSame(endDate)))
      ) {
        return range.label;
      }
    }

    return (
      (startDate ? startDate.format(this.props.dateFormat) : '') +
      ' to ' +
      (endDate ? endDate.format(this.props.dateFormat) : '')
    );
  }

  getCurrentDateRange() {
    if (!this.props.endDate) {
      return [null, null];
    } else if (!this.props.startDate) {
      const now = moment.tz(this.props.timezone);
      const end = moment.tz(this.props.endDate, this.props.timezone);
      const duration = end.diff(moment(end).startOf('day'), 'seconds');
      const durationStart = moment(now).subtract(duration, 'minutes');
      return [durationStart, now];
    } else {
      return [this.props.startDate, this.props.endDate];
    }
  }

  showDatePicker() {
    this.refs.picker.focus();
  }

  handleChange(start, end, label) {
    if (!start.isValid() || start.isSame(this.nullDateSentinel)) {
      start = null;
    }
    if (!end.isValid() || end.isSame(this.nullDateSentinel)) {
      end = null;
    }

    if (!label || label === 'Custom Range') {
      // The date picker returns times in local time, so we need to re-parse the
      // dates in the account timezone
      if (start) {
        start = moment.tz(start.format('YYYY-MM-DD'), this.props.timezone).startOf('day');
      }
      if (end) {
        end = moment.tz(end.format('YYYY-MM-DD'), this.props.timezone).endOf('day');
      }
    }

    if (this.props.onChange) {
      this.props.onChange(start, end);
    }
  }

  handleCaretClick(e) {
    e.preventDefault();
    this.showDatePicker();
  }

  render() {
    return (
      <div className="form-inline">
        <input
          ref="picker"
          aria-label="Date Range"
          type="text"
          readOnly={true}
          disabled={this.props.disabled}
          className="form-control light-shadow daterangepicker-textinput"
        />
        <span className="caret" onClick={this.handleCaretClick}></span>
      </div>
    );
  }
}

DateRangePicker.defaultProps = {
  timezone: 'utc', // Default timezone
  dateFormat: 'D MMM YYYY', // Default date format.
  openDirection: 'right', // Open to left or right?
  disabled: false, // Control is disabled?
  enabledDateRanges: 'live,30m,1h,3h,12h,24h,day0,day1,7d,30d,mon0,mon1,yr0,yr1',
  nullDateLabel: 'Live Data', // What to display when startDate and endDate are null

  minDate: null, // Beginning of visible range according to max visible range setting
  maxDate: null, // End of visible range
  startDate: null, // Start date to show
  endDate: null, // End date to show
  onChange: null, // Change handler
};
