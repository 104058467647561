import moment from 'moment-timezone';

export const extractLastIncidentUpdateTime = (incident) => {
  if (!incident.updates?.length) {
    return moment.utc(incident.created_at);
  }
  let lastUpdatedAt = moment.utc(incident.updates[0].updated_at);
  incident.updates.forEach((update) => {
    const curr = moment.utc(update.updated_at);
    if (curr.isAfter(lastUpdatedAt)) {
      lastUpdatedAt = curr;
    }
  });
  return lastUpdatedAt;
};

export const compareIncidentsByLastUpdateFn = (i1, i2) => {
  /*
   * Compare two incidents by last update
   * */
  if (i1.lastUpdated.isAfter(i2.lastUpdated)) {
    return -1;
  } else if (i1.lastUpdated.isBefore(i2.lastUpdated)) {
    return 1;
  }
  return 0;
};
