'use strict';

import React from 'react';
import Formatter from '../../jskit/general/Formatter';
import ReactUtils from '../../jskit/react/ReactUtils';
import Utils from '../../jskit/general/Utils';

export default class Incident extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      isOpen: false,
    };
  }

  handleIncidentOpenClose(e) {
    e.preventDefault();
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {
    const incident = this.props.incident;

    const keyFn = (x) => this.props.componentStatusRank[x.status];
    const componentSortedBySeverity = incident.affected_components.sort((a, b) => (keyFn(a) < keyFn(b) ? -1 : 1));
    const highRankCmpStatus = componentSortedBySeverity.length ? componentSortedBySeverity[0].status : 'notification';

    const components = incident.affected_components.map((component, i) => {
      return (
        <li key={i} title={component.name + ' - ' + component.status_display}>
          <span className={'status-icon ' + component.status}>{component.name}</span>
        </li>
      );
    });

    const updates = incident.updates.map((update, i) => {
      return (
        <React.Fragment key={i}>
          <div className="update">
            <div className="update-heading">
              <span className={'incident-icon name ' + update.incident_state}>{update.incident_state_display}</span>
              <span className="date d-block d-sm-inline mt-2 mt-sm-0">{Formatter.longDateTime(update.updated_at)}</span>
            </div>
            <div
              className="update-description markdown"
              dangerouslySetInnerHTML={{__html: update.description_html}}
            ></div>
          </div>
        </React.Fragment>
      );
    });

    return (
      <div className={ReactUtils.cssClass('incident-container', {open: this.state.isOpen})} key={incident.id}>
        <div className={ReactUtils.cssClass('incident', {open: this.state.isOpen})}>
          <div className={'indicator ' + highRankCmpStatus} />
          <div className="incident-expander" onClick={this.handleIncidentOpenClose} />
          <div className="incident-heading" onClick={this.handleIncidentOpenClose}>
            <span className="name d-inline-block text-truncate text-wrap">{incident.name}</span>
            <span className="date d-block d-lg-inline">
              {Formatter.shortDateTime(incident.starts_at, `${window.CURRENT_TIME_FORMAT}_NO_SECONDS`)} &mdash;{' '}
              {incident.ends_at
                ? Formatter.shortDateTime(incident.ends_at, `${window.CURRENT_TIME_FORMAT}_NO_SECONDS`)
                : 'ongoing'}{' '}
              <strong>({Formatter.shortDuration(incident.duration, {roundSeconds: true})})</strong>
            </span>
          </div>
        </div>
        <div className="incident-details">
          <div className="row">
            <div className="updates col-lg-8 order-2 order-lg-1">{updates}</div>
            {components.length ? (
              <div className="affected-components offset-lg-1 col-lg-3 order-1 order-lg-2">
                <div className="mb-1">
                  <strong>Affected Components</strong>
                </div>
                <ul className="list-unstyled list-spacer-1">{components}</ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

Incident.defaultProps = {
  incident: null,
};
