'use strict';

import React from 'react';
import {extractZendeskArticleIdFromUrl, openZendeskArticle} from '../../../js/global/zendesk';

export default class ZendeskSupportLink extends React.Component {
  handleLinkClick(e) {
    e.preventDefault();
    const articleId = this.props.articleId || extractZendeskArticleIdFromUrl(this.props.href);
    openZendeskArticle(articleId);
  }

  render() {
    const {href} = this.props;
    if (!href) {
      return false;
    }
    return (
      <a href={href} onClick={this.handleLinkClick.bind(this)}>
        {this.props.children}
      </a>
    );
  }
}

ZendeskSupportLink.defaultProps = {
  href: '#', // URL of a Zendesk support article
  articleId: null,
};
