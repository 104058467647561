'use strict';

import React from 'react';

export default class FormWarning extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {warnings, warningTitle} = this.props;
    if (!warnings || !warnings.length) {
      return false;
    }
    let warning;
    if (warnings.length > 1) {
      const warningItems = warnings.map((v, i) => <li key={i}>{v}</li>);
      warning = <ul className="mb-1 list-noindent">{warningItems}</ul>;
    } else {
      warning = <p className="mb-1">{warnings[0]}</p>;
    }
    return (
      <div className="alert alert-warning">
        <p className="m-0 pb-1">
          <strong>{warningTitle}</strong>
        </p>
        {warning}
      </div>
    );
  }
}

FormWarning.defaultProps = {
  warningTitle: '',
  warnings: [],
};
