'use strict';

import React from 'react';
import MessageBox from '../../js/global/messagebox';

class ExternalAccountUserRow extends React.Component {
  render() {
    return (
      <tr>
        <td>
          {this.props.first_name} {this.props.last_name}
        </td>
        <td className="text-center">
          <i className={this.props.is_active ? 'text-success fas fa-check' : 'text-danger fas fa-times'} />
        </td>
        <td>{this.props.email}</td>
        <td className="actions-right">
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-xs dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            />
            <div className="dropdown-menu dropdown-menu-right">
              <a href="#" className="dropdown-item" onClick={() => this.props.toggleHandler(this.props.id)}>
                {this.props.is_active ? 'Deactivate' : 'Reactivate'}
              </a>
              <a href="#" className="dropdown-item" onClick={() => this.props.confirmDeleteUser(this.props)}>
                Delete User
              </a>
              <a
                href="#"
                className="dropdown-item"
                disabled={this.props.creation_method !== 'EMAIL'}
                onClick={() => this.props.passwordResetHandler(this.props.id, this.props.email)}
              >
                Reset Password
              </a>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default class ExternalAccountUsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  confirmDeleteUser = (user) => {
    const message = `Are you sure you want to DELETE user "${user.email}"?`;
    MessageBox.confirmBox(message, 'Delete User', () => {
      this.props.deleteHandler(user.id);
    });
  };

  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return (
        <div className="p-5 text-center mb-lg-5 white-block white-block-border">
          <div className="font-weight-bold font-24 mb-4">
            You have not yet invited any External Users...
            <div className="font-weight-normal font-14 mb-5">
              Click Add New User to invite a non-account user to securely access this Status Page.
              {/* eslint-disable-next-line max-len */}
              <a
                href="https://support.uptime.com/hc/en-us/articles/6801239386652-Securing-Your-Uptime-com-Status-Page#access_external"
                target="_blank"
              >
                &nbsp;View&nbsp;Documentation&nbsp;→
              </a>
            </div>
          </div>
          <p>
            <button type="button" className={'btn btn-primary'} onClick={this.props.addItemClickHandler}>
              Add New User
            </button>
          </p>
        </div>
      );
    }

    const nodes = this.props.items.map(
      function (item) {
        return (
          <ExternalAccountUserRow
            {...item}
            key={item.id}
            toggleHandler={this.props.toggleHandler}
            confirmDeleteUser={this.confirmDeleteUser}
            passwordResetHandler={this.props.passwordResetHandler}
          />
        );
      }.bind(this)
    );

    return (
      <div className="white-block white-block-border mb-4">
        <table className="table table-responsive-md data-table mb-0" role="grid">
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-center">Active</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }
}
