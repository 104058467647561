import {PropsWithChildren, createContext} from 'react';

export interface StatusPageContextProviderProps extends PropsWithChildren {
  componentStatusRank: Record<number, string>;
  componentStatusChoices: any;
}

export const StatusPageContext = createContext({
  componentStatusRank: {},
  componentStatusChoices: {},
});

export const StatusPageContextProvider = ({
  componentStatusRank,
  componentStatusChoices,
  children,
}: StatusPageContextProviderProps) => {
  return (
    <StatusPageContext.Provider
      value={{
        componentStatusRank,
        componentStatusChoices,
      }}
    >
      {children}
    </StatusPageContext.Provider>
  );
};
