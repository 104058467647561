'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import Chart from 'react-apexcharts';
import {checkFeature} from '../CheckFeatures.js';
import {
  applicable,
  defaultFormatter,
  getBaselineComparison,
  renderBaselineComparison,
  renderNoData,
} from '../RumUtils.jsx';
import {defaultGraphOptions, manualFormat, toolbarExportOptions} from './GraphFormatter.js';
import {Colors} from '../RumColors.js';

// TODO:
// - find load-breakdown-graph element by config.globals.chartID? Otherwise can only have one at a time
// - download images have Annotations in legend. Need to do node cloning method instead of adding fake series?

export default class LoadBreakdownGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this._isMs = true; /** Convenience value for storing if all features are millisecond values */
    this._currentTotalLabel = ''; /** For forcing dataLabels in some cases where they disappear */
    this._baselineTotalLabel = ''; /** For forcing dataLabels in some cases where they disappear */
    this._tooltip = null; /** Tooltip fragment, rendered once per data load because it's expensive */

    const defaultOptions = defaultGraphOptions();
    this.state = {
      series: [],
      options: {
        chart: Object.assign(defaultOptions.chart, {
          events: {
            updated: this._onChartUpdate,
          },
          toolbar: Object.assign(defaultOptions.chart.toolbar, toolbarExportOptions(this.props.checkName, 'breakdown')),
        }),
        plotOptions: {
          bar: {
            horizontal: true,
            rangeBarGroupRows: true,
            dataLabels: {
              position: 'right',
            },
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [],
          textAnchor: 'left',
          formatter: this._dataLabelFormatter.bind(this),
          style: {
            colors: ['#000000'],
          },
        },
        colors: [Colors.darkBlue, Colors.lightBlue, Colors.mediumLightBlue, Colors.turquoise, Colors.purple],
        fill: {
          type: 'solid',
        },
        grid: {
          show: false,
          padding: {
            top: 40,
            right: 100,
          },
        },
        title: Object.assign(defaultOptions.title, {offsetY: 50}),
        xaxis: {
          show: false,
          type: 'datetime',
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: {
            color: Colors.black,
            width: 2,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              fontWeight: 600,
              fontSize: '12px',
            },
          },
        },
        stroke: {
          show: false,
        },
        legend: Object.assign(defaultOptions.legend, {
          offsetY: 10,
        }),
        tooltip: {
          enabled: false,
        },
      },
      parsed: false,
    };
    this.chartRef = React.createRef();
    const parsedData = this._parseData(this.props.data);
    this._setData(parsedData);
  }

  componentDidMount() {
    manualFormat((this.chartRef.current || {}).chart);
  }

  componentDidUpdate(previousProps) {
    if (this.props.data !== previousProps.data) {
      const parsedData = this._parseData(this.props.data);
      this._setData(parsedData);
      const chart = (this.chartRef.current || {}).chart;
      if (chart) {
        chart.updateOptions(this.state.options, true, false, false);
        chart.updateSeries(this.state.series);
      }
      this.forceUpdate();
    }
  }

  _parseData(data) {
    if (!data || !this.props.sections) {
      return {};
    }

    const tooltipData = [];
    const series = [];
    let currentSum = 0,
      baselineSum = 0;
    for (const section of this.props.sections) {
      const {start: startFeature, end: endFeature, name: label} = section;
      if ((startFeature && !applicable(startFeature, data)) || !applicable(endFeature, data)) {
        continue;
      }
      const currentStart = startFeature ? data[startFeature.key] || currentSum : currentSum;
      const currentEnd = endFeature ? data[endFeature.key] || currentSum : currentSum;
      const current = Math.max(currentEnd - currentStart, 0);

      const baselineStart = startFeature ? data[startFeature.baseline] || baselineSum : baselineSum;
      const baselineEnd = endFeature ? data[endFeature.baseline] || baselineSum : baselineSum;
      const baseline = Math.max(baselineEnd - baselineStart, 0);

      series.push({
        name: label,
        data: [
          {
            x: 'Current',
            y: [currentSum, currentSum + current],
          },
          {
            x: 'Baseline',
            y: [baselineSum, baselineSum + baseline],
          },
        ],
      });
      currentSum += current;
      baselineSum += baseline;

      // Store data for rendering tooltip.
      tooltipData.push({
        label,
        current,
        baseline,
        startFeature,
        endFeature,
      });
    }

    if (currentSum === 0 && baselineSum === 0) {
      return {};
    }

    tooltipData.push({
      label: 'Total',
      current: currentSum,
      baseline: baselineSum,
      startFeature: checkFeature('total', 'Total', {units: 'ms'}),
    });
    const hasData = !!(currentSum || baselineSum);

    // Add series data for the markers (thin vertical lines overlaid on the breakdown)
    const markerData = [];
    for (const marker of this.props.markers || []) {
      const {feature} = marker;
      if (!applicable(feature, data)) {
        continue;
      }
      const value = data[feature.key] || 0;
      markerData.push({
        x: 'Current',
        y: [value, value + (hasData ? 1 : 0)],
      });
    }
    series.push({
      name: 'Annotations',
      data: markerData,
    });

    return {series, tooltipData};
  }

  /**
   * Set data state directly (not via setState) so that it can be parsed in the constructor.
   * This is necessary for the graph to appear correctly on its initial render.
   */
  _setData(parsedData) {
    this.state.options.title.text = this.props.title || '';
    const {series, tooltipData} = parsedData;
    this.state.series = series || [];
    const numSeries = this.state.series.length;
    this.state.options.dataLabels.enabledOnSeries = numSeries > 1 ? [numSeries - 2, numSeries - 1] : [];
    this._tooltip = this._renderTooltip(tooltipData);
    this.state.parsed = true;
  }

  _renderTooltip(tooltipData) {
    if (!tooltipData) {
      return <React.Fragment />;
    }

    const dividerStyle = '1px solid #bfbfbf';
    const headerRowStyle = {borderBottom: dividerStyle};
    const totalRowStyle = {borderTop: dividerStyle};
    const cellStyle = {paddingRight: '10px'};

    const rows = [];
    for (const [ix, datum] of tooltipData.entries()) {
      // Mimic the raw data structure with the series features.
      const units = (datum.startFeature || datum.endFeature || {}).units || '';
      let tooltip;
      if (datum.endFeature) {
        tooltip = `${datum.endFeature.name} - ${datum.startFeature ? datum.startFeature.name : 0}`;
      }
      const mockFeature = checkFeature(datum.label, datum.label, {units, tooltip});
      const {current, baseline} = datum;
      const deltaPct = current && baseline ? ((current - baseline) / baseline) * 100 : undefined;
      const mockData = {
        [mockFeature.key]: datum.current,
        [mockFeature.baseline]: datum.baseline,
        [mockFeature.deltaPct]: deltaPct,
      };

      // Render rows.
      const baselineStr = defaultFormatter(datum.baseline, 3, mockFeature);
      const comparison = getBaselineComparison(mockFeature, mockData, {valuePrecision: 3});
      const comparisonFragment = renderBaselineComparison(comparison, {headerStyle: {fontWeight: 'bold'}});

      const isTotal = ix === tooltipData.length - 1;
      const nameCellStyle = Object.assign({}, cellStyle, {fontWeight: isTotal ? 'bold' : 'normal'});
      const rowStyle = isTotal ? totalRowStyle : {};
      rows.push(
        <React.Fragment key={mockFeature.key}>
          <tr style={rowStyle}>
            <td style={nameCellStyle}>{mockFeature.name}</td>
            <td style={cellStyle}>{baselineStr}</td>
            <td style={cellStyle}>{comparisonFragment}</td>
          </tr>
        </React.Fragment>
      );
    }

    const tableStyle = {margin: '20px'};
    return (
      <React.Fragment key="tooltip">
        <table className="breakdown-tooltip" style={tableStyle}>
          <thead>
            <tr style={headerRowStyle}>
              <th style={cellStyle}>Part</th>
              <th style={cellStyle}>Baseline</th>
              <th>Current</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </React.Fragment>
    );
  }

  _dataLabelFormatter(val, options) {
    const idx = options.dataPointIndex;
    let total = 0;
    for (const metric of options.w.config.series) {
      if (metric.data[idx] === undefined) {
        continue;
      }
      const rangeData = metric.data[idx].y;
      total += rangeData[1] - rangeData[0];
    }
    const label = `  ${defaultFormatter(total, 3, {units: 'ms'})}`;

    // dataLabels will fail to show if the final series has zero length.
    // Store the current and baseline totals so we can force them to show.
    if (idx === 0) {
      this._currentTotalLabel = label;
    } else if (idx === 1) {
      this._baselineTotalLabel = label;
    }
    return label;
  }

  /** Make custom data labels above bar chart, as well as other custom formatting */
  _onChartUpdate() {
    const graph = document.querySelector('.load-breakdown-graph');
    if (!graph) {
      return;
    }

    const allSeries = graph.querySelectorAll('.apexcharts-series');
    const allLabels = graph.querySelectorAll('.apexcharts-datalabels');
    if (!allSeries.length || !allLabels.length) {
      return;
    }

    // Add a stroke around the bar paths to make them easier to distinguish.
    // There is no option for this in ApexCharts.
    for (const series of allSeries) {
      const barPaths = series.querySelectorAll('.apexcharts-rangebar-area');
      for (const barPath of barPaths) {
        barPath.setAttribute('stroke', series.getAttribute('seriesName') === 'Annotations' ? '#555555' : 'white');
        barPath.setAttribute('stroke-width', '2px');
      }
    }

    const annotationSeries = allSeries[allSeries.length - 1].children;
    const annotationLabels = allLabels[allLabels.length - 1].children;
    const valueLabels = [];
    const textLabels = [];

    for (const [ix, marker] of (this.props.markers || []).entries()) {
      const {feature} = marker;
      if (!applicable(feature, this.props.data)) {
        continue;
      }
      const series = annotationSeries[ix];
      const label = annotationLabels[ix];
      const value = this.props.data[feature.key] || null;
      const valueString = defaultFormatter(value, 3, feature);

      const textElement = label.querySelector('text');
      if (!textElement) {
        continue;
      }
      textElement.textContent = feature.name;
      textElement.setAttribute('y', -15);
      textElement.setAttribute('fill', '#888888');
      textLabels.push(textElement);

      // Create a value label below the original label.
      const valueTextElement = textElement.cloneNode();
      label.appendChild(valueTextElement);
      valueTextElement.textContent = valueString;
      valueTextElement.setAttribute('y', -2);
      valueTextElement.setAttribute('fill', '#aaaaaa');
      valueLabels.push(valueTextElement);

      for (const element of [textElement, valueTextElement]) {
        const xLoc = parseFloat(series.getAttribute('cx') - element.getBBox().width / 2 - 8);
        element.setAttribute('x', xLoc);
      }
    }

    // If the labels overlap, go back and space them out.
    for (const labels of [textLabels, valueLabels]) {
      for (const [ixLabel, label] of labels.entries()) {
        const prevLabel = labels[ixLabel - 1];
        if (!prevLabel) {
          continue;
        }
        const prevBox = prevLabel.getBBox();
        const thisBox = label.getBBox();
        const overlap = prevBox.x + prevBox.width - thisBox.x;
        if (overlap > -10) {
          const margin = 5;
          prevLabel.setAttribute('x', prevBox.x - overlap / 2 - margin);
          label.setAttribute('x', thisBox.x + overlap / 2 + margin);
        }
      }
    }

    // The dataLabels can get overlaid on the bar chart if the total length is too similar.
    // This seems like an ApexCharts quirk but could be a misconfiguration here as well.
    // Manually move them if they overlap.
    const rightIndex = this.state.series.length - 2;
    let rightSeries = graph.querySelectorAll('.apexcharts-series')[rightIndex];
    rightSeries = rightSeries ? rightSeries.children : [];
    let rightLabels = graph.querySelectorAll('.apexcharts-datalabels')[rightIndex];
    rightLabels = rightLabels ? rightLabels.children : [];
    if (rightSeries.length && rightLabels.length) {
      for (let ix = 0; ix < rightSeries.length; ix++) {
        if (!rightLabels[ix]) {
          continue;
        }
        const seriesBBox = rightSeries[ix].getBBox();
        const labelElement = rightLabels[ix].children[0];

        // If the rightmost series has zero width, Apexcharts will hide the label. Manually add it back in this case.
        if (!labelElement.innerHTML) {
          const label = ix === 0 ? this._currentTotalLabel : ix === 1 ? this._baselineTotalLabel : '';
          labelElement.innerHTML = label.trim();
        }

        const labelBBox = labelElement.getBBox();
        const seriesRight = seriesBBox.x + seriesBBox.width;
        if (labelBBox.x <= seriesRight) {
          labelElement.setAttribute('x', seriesRight + 10);
        }
      }
    }
  }

  render() {
    if (this.state.parsed && this.state.series.length === 0) {
      return <div style={{height: '1.5rem'}}></div>;
    }
    return (
      <div className="load-breakdown-graph">
        {renderNoData(this.state.series, this.props.noDataMessage)}
        <Chart
          ref={this.chartRef}
          type="rangeBar"
          options={this.state.options}
          series={this.state.series}
          width="100%"
          height="180px"
        />
        {this._tooltip}
      </div>
    );
  }
}
