import {SectionNavContent} from '@/jskit/react/SectionNav';
import {CurrentStatusTabs} from '@/status_pages/display/components/tabs/CurrentStatus/CurrentStatus';
import IncidentsList from '@/status_pages/display/IncidentsList';
import {ComponentStatusValue, Incident} from '@/status_pages/types';
import {EmptySectionRocket} from '@/status_pages/display/EmptySectionRocket';

export interface ScheduledMaintenanceSectionProps {
  currentSectionId: CurrentStatusTabs;
  componentStatusRank: Record<ComponentStatusValue, number>;
  incidents: Incident[];
}

export const ScheduledMaintenanceSection = ({
  currentSectionId,
  componentStatusRank,
  incidents,
}: ScheduledMaintenanceSectionProps) => (
  <SectionNavContent sectionId={CurrentStatusTabs.SCHEDULED_MAINTENANCE} currentSectionId={currentSectionId}>
    {incidents?.length > 0 ? (
      <IncidentsList componentStatusRank={componentStatusRank} incidents={incidents} />
    ) : (
      <EmptySectionRocket>
        There is no <strong>Scheduled Maintenance</strong>
      </EmptySectionRocket>
    )}
  </SectionNavContent>
);
