/**
 * Provides functions for parsing/generating URLs and manipulating the browser
 * history via the HTML5 History API.
 */
/* eslint-disable */

import _ from 'underscore';

var URLHistory = {};

/**
 * Returns the URL currently set in the browser.
 */
URLHistory.currentURL = function () {
  return window.location.toString();
};

/**
 * Modifies the query string and/or anchor part of a URL.
 *
 * @param url  The URL to operate on.
 * @param keysToReplace  An object of parameter keys to add/replace and values
 *      to replace with. Should not be URL encoded.
 * @param keysToDelete  An array of keys to delete from the query string.
 * @param newHash  A new value for the hash/anchor part of the URL. If undefined
 *      it leaves it as is.
 *
 */
URLHistory.updateQueryString = function (url, keysToReplace, keysToDelete, newHash) {
  url = url || this.currentURL();

  var parsed = this.parseURL(url);
  var qs = parsed.params;
  var hash = newHash === undefined ? parsed.hash : newHash;

  if (keysToDelete && keysToDelete.length) {
    qs = _.omit(qs, keysToDelete);
  }

  if (keysToReplace) {
    qs = _.extend({}, qs, keysToReplace);
  }

  var result = url.split('?')[0].split('#')[0];

  if (!_.isEmpty(qs)) {
    result = result + '?' + this.objectToQueryString(qs);
  }

  if (hash) {
    result = result + '#' + hash;
  }

  return result;
};

/**
 * Replaces or removes the query string from the given URL.
 * If provided, replacement should not contain the leading '?'.
 *
 */
URLHistory.replaceQueryString = function (url, replacement) {
  var hashSplit = url.split('#');
  var result = url.split('?')[0].split('#')[0];

  if (replacement !== undefined && replacement !== null && replacement !== '') {
    result += '?' + replacement;
  }

  if (hashSplit.length > 1) {
    result += '#' + hashSplit[1];
  }

  return result;
};

/**
 * Parses the specified URL returning an object of URL components.
 */
URLHistory.parseURL = function (url) {
  if (url === undefined) {
    url = this.currentURL();
  }

  var a = document.createElement('a');
  a.href = url;
  return {
    source: url,
    protocol: a.protocol.replace(':', ''),
    host: a.hostname,
    port: a.port,
    query: a.search,
    params: (function () {
      var ret = {},
        seg = a.search.replace(/^\?/, '').split('&'),
        len = seg.length,
        s;
      for (var i = 0; i < len; i++) {
        if (!seg[i]) {
          continue;
        }
        s = seg[i].split('=');
        ret[decodeURIComponent(s[0])] = s[1] !== undefined ? decodeURIComponent(s[1]) : null;
      }
      return ret;
    })(),
    file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
    hash: a.hash.replace('#', ''),
    path: a.pathname.replace(/^([^\/])/, '/$1'),
    relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
    segments: a.pathname.replace(/^\//, '').split('/'),
  };
};

/**
 * Converts the given object into a correctly escaped query string that can
 * be provided to window.location or the HTML5 history API.
 */
URLHistory.objectToQueryString = function (obj) {
  var parts = [];
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      if (obj[i] === null || obj[i] === undefined) {
        parts.push(encodeURIComponent(i));
      } else {
        parts.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]));
      }
    }
  }
  return parts.join('&');
};

/**
 * Converts the given URL or query string into an object of key-value pairs.
 *
 * @param {String} qs If undefined, uses the current page URL.
 */
URLHistory.queryStringToObject = function (qs) {
  if (qs === undefined) {
    qs = this.currentURL();
  }

  return this.parseURL(qs).params;
};

/**
 * Sets a function to be called if the user navigates back/forward
 * between states set with pushState or replaceState.
 *
 * Passes the new URL and the parsed URL to the function.
 */
URLHistory.addStateChangeListener = function (callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this;

  if (window.addEventListener) {
    window.addEventListener('popstate', function () {
      callback(self.currentURL());
    });
  }
};

/**
 * Pushes a new URL to the history stack for HTML5 history enabled browsers.
 *
 * @param {Object} newState Either a URL or URL segment to push, or
 *      an object with key-value pairs to map into a URL query string.
 *
 * @param {String} title An optional title for the page.
 */
URLHistory.pushState = function (newState, title) {
  this._updateBrowserState('pushState', newState, title);
};

/**
 * Replaces the current URL in the history stack for HTML5 history enabled browsers.
 *
 * @param {Object} newState Either a URL or URL segment to push, or
 *      an object with key-value pairs to map into a URL query string.
 *
 * @param {String} title An optional title for the page.
 */
URLHistory.replaceState = function (newState, title) {
  this._updateBrowserState('replaceState', newState, title);
};

/**
 * Does the work of pushState() and replaceState().
 */
URLHistory._updateBrowserState = function (fn, newState, title) {
  title = title || '';

  if (typeof newState === 'object') {
    var qs = this.objectToQueryString(newState);
    newState = this.replaceQueryString(this.currentURL(), qs);
  }

  // Don't push new state if identical to the current state.
  // This breaks browser caching pretty badly.
  var parsed = this.parseURL();
  if (newState === parsed.source || newState === parsed.relative) {
    return;
  }

  if (window.history && window.history[fn]) {
    try {
      window.history[fn](null, title, newState);
    } catch (exc) {
      console.error(exc); // eslint-disable-line no-console
    }
  }
};

//****************************
//* EXPORTS
//****************************

export default URLHistory;
