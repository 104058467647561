/* eslint-disable */
'use strict';

var Validators = {
  EMAIL_RE:
    /^[a-z0-9!#$%&'*+\/=?\^_`{|}~\-]+(?:\.[a-z0-9!#$%&'*+\/=?\^_`{|}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/i,
  STRIP_NON_PHONE_CHAR_RE: /[\(\)\-\. ]/g,
  DIGITS_ONLY_RE: /^\d+$/,
};

Validators.isEmpty = function (val) {
  return val === undefined || val === null || val === '';
};

Validators.isNotEmpty = function (val) {
  return val !== undefined || val !== null || val !== '';
};

Validators.isValidEmail = function (val) {
  return Validators.EMAIL_RE.test(val);
};

Validators.isValidInternationalPhone = function (val) {
  var phoneDigits = val.replace(Validators.STRIP_NON_PHONE_CHAR_RE, '');

  if (phoneDigits.length > 0 && phoneDigits[0] === '+') {
    phoneDigits = phoneDigits.slice(1);
  }

  if (!Validators.DIGITS_ONLY_RE.test(phoneDigits)) {
    return false;
  }

  if (phoneDigits.length < 9) {
    return false;
  }

  return true;
};

// This function validates URL
// query parameters are allowed like https://example.com/?param=value
Validators.isValidURL = function (val) {
  const regex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);
  return regex.test(val);
};

//****************************
//* EXPORTS
//****************************

export default Validators;
