'use strict';

import React from 'react';
import _ from 'underscore';

import ReactUtils from '../../jskit/react/ReactUtils';
import Ajax from '../../jskit/general/Ajax';
import Utils from '../../jskit/general/Utils';
import Paginator from '../../jskit/react/Paginator';

import SegmentsTrendGraph from './graphs/SegmentsTrendGraph';
import Donut from './graphs/DonutGraph';
import DataTable from './graphs/DataTable';
import LoadTimeWorldMap from './graphs/LoadTimeWorldMap';
import {checkFeatures} from './CheckFeatures';
import {Colors} from './RumColors';

export default class SegmentDataSection extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      sectionData: {},
      activeTabIndex: 0,
      hasLoaded: false,
      drillDown: null,
      sort: {
        key: undefined,
        dir: undefined,
      },
    };
    this._barColor = Colors.ghostBlue;
    this._colors = [Colors.green, Colors.blue, Colors.yellowOrange, Colors.red, Colors.turquoise, Colors.red];

    this.paginatorRef = React.createRef();
  }

  componentDidMount() {
    this.loadActiveSectionData();
  }

  forceDataRefresh() {
    this.loadActiveSectionData(true);
  }

  switchSection(idx) {
    this.setState(
      {
        activeTabIndex: idx,
        hasLoaded: false,
        drillDown: null,
        sort: {key: undefined, dir: undefined},
      },
      () => this.loadActiveSectionData(true)
    );
  }

  /** Pass true if loading from drillDown, which resets the paginator to the first page */
  loadActiveSectionData(resetPage) {
    const section = this.props.segments.sections[this.state.activeTabIndex];
    var query = _.extend({}, this.props.getLoadDataAjaxFilters()),
      urlParams = section.dataURLParams;
    if (this.paginatorRef.current) {
      query = _.extend(query, resetPage ? {} : this.paginatorRef.current.getQueryOffsetLimit());
    }
    if (this.state.sort.key) {
      query = _.extend(query, {sort: this.state.sort});
    }
    if (this.state.drillDown) {
      query = _.extend(query, {
        drillDown: {param: this.state.drillDown.col.drillDown.param, value: this.state.drillDown.value},
      });
      urlParams = this.state.drillDown.col.drillDown.dataURLParams;
    }

    new Ajax().post({
      url: this.props.dataURL + '?' + urlParams,
      data: query,
      encoder: 'json',
      decoder: 'json',
      success: function (response) {
        var sectionData = _.clone(this.state.sectionData);
        this.setState({
          sectionData: _.extend(sectionData, response.data),
          hasLoaded: true,
        });
        if (resetPage && this.paginatorRef.current) {
          this.paginatorRef.current.setState({page: 0});
        }
        if (this.props.onLoad) {
          this.props.onLoad(response.data);
        }
      }.bind(this),
    });
  }

  handlePageChange() {
    this.loadActiveSectionData();
  }

  handleTableSort(key, dir) {
    this.setState(
      {
        sort: {key, dir},
      },
      this.loadActiveSectionData
    );
  }

  handleDrillDown(col, data) {
    const value = data[col.key];
    const name = col.key === checkFeatures.country.key ? data['CountryName'] || value : value;
    this.state.drillDown = {col, value, name}; // Re-render is triggered by loadActiveSectionData, once the new data is loaded.

    // Reset the sort column if we are drilling down from a country to a region (otherwise it can break the query).
    if (this.state.sort.key === checkFeatures.country.key) {
      this.state.sort.key = checkFeatures.pageViews.key;
    }

    this.loadActiveSectionData(true);
  }

  handleDrillDownReset(evt) {
    this.setState(
      {
        drillDown: null,
        sort: {key: undefined, dir: undefined},
      },
      () => this.loadActiveSectionData(true)
    );
    evt.preventDefault();
  }

  render() {
    const stateClass = (idx) =>
      ReactUtils.cssClass('btn btn-toggle', {
        ['active']: this.state.activeTabIndex === idx,
      });

    var buttons = [];
    for (let i = 0; i < this.props.segments.sections.length; i++) {
      const section = this.props.segments.sections[i];
      buttons.push(
        <button
          key={`segments-section-${i}`}
          onClick={() => this.switchSection(i)}
          type="button"
          className={stateClass(i)}
        >
          {section.title}
        </button>
      );
    }

    return (
      <div className="segment-data-container">
        <div className="mt-n4 d-flex flex-row-reverse mb-4">
          <div className="btn-group btn-group-sm">{buttons}</div>
        </div>
        {this.renderCurrentSection()}
      </div>
    );
  }

  renderCurrentSection() {
    if (!this.state.hasLoaded) {
      return <div>Loading...</div>;
    }
    const section = this.props.segments.sections[this.state.activeTabIndex];
    const possibleGraphs = [
      {key: 'trend', cond: section.trend, fn: this.renderTrendGraph},
      {key: 'donut', cond: section.donut, fn: this.renderDonutGraph},
      {key: 'map', cond: section.map, fn: this.renderMapGraph},
      {key: 'table', cond: section.table, fn: this.renderTableReport},
      {key: 'breakdownTable', cond: section.breakdownTable, fn: this.renderBreakdownTableReport},
    ];

    const renderedGraphs = {
      trend: null,
      donut: null,
      map: null,
      table: null,
    };
    possibleGraphs.forEach((graph) => {
      if (!graph.cond) {
        return;
      }
      renderedGraphs[graph.key] = graph.fn(section);
    });
    return (
      <React.Fragment>
        {(renderedGraphs['trend'] || renderedGraphs['donut']) && (
          <div className="d-flex mb-3">
            <div className="flex-grow-1">{renderedGraphs['trend']}</div>
            <div>{renderedGraphs['donut']}</div>
          </div>
        )}
        {renderedGraphs['map']}
        {renderedGraphs['breakdownTable']}
        {renderedGraphs['table']}
      </React.Fragment>
    );
  }

  renderBreakdownTableReport(section) {
    if (!this.state.sectionData.breakdown) {
      return null;
    }
    return (
      <div className="mb-2">
        <h5>{section.breakdownTable.title}</h5>
        <DataTable
          key="seg-data-tbl-breakdown"
          def={section.breakdownTable.columns}
          data={this.state.sectionData.breakdown}
          sort={{dir: null, key: null}}
        />
      </div>
    );
  }

  renderTableReport(section) {
    const tableDef = section.table;
    var drillDownSection = null,
      tableFeatures = tableDef.features,
      tableTitle = null;
    if (this.state.drillDown) {
      tableFeatures = this.state.drillDown.col.drillDown.table.features;
      drillDownSection = (
        <div className="mt-4 mb-3">
          <strong>
            <a href="#" onClick={this.handleDrillDownReset}>
              {this.state.drillDown.col.name}
            </a>
            <span className="p-2">&gt;</span>
            {this.state.drillDown.name}
          </strong>
        </div>
      );
    }
    if (this.props.isURLFilterActive && section.tableTitleWithURLFilter) {
      tableTitle = <h5>{section.tableTitleWithURLFilter}</h5>;
    }

    // If the user hasn't selected a sort column, select a default in the following priority:
    //  - report definition (table.sortFeature and table.sortDir)
    //  - page views, descending
    //  - sessions, descending
    //  - first feature in report definition if none of those were available
    const columnKeys = tableFeatures.map((feat) => feat.key);
    let sortKey = this.state.sort.key;
    if (!sortKey) {
      if (tableDef.sortFeature) {
        sortKey = tableDef.sortFeature.key;
      } else {
        if (columnKeys.includes(checkFeatures.pageViews.key)) {
          sortKey = checkFeatures.pageViews.key;
        } else if (columnKeys.includes(checkFeatures.sessions.key)) {
          sortKey = checkFeatures.sessions.key;
        } else {
          sortKey = tableFeatures[0].key;
        }
      }
    }
    const sortDir = this.state.sort.dir || tableDef.sortDir || 'desc';
    const sort = {key: sortKey, dir: sortDir};

    return (
      <React.Fragment>
        {tableTitle}
        {drillDownSection}
        <DataTable
          key="seg-data-tbl"
          def={tableFeatures}
          data={this.state.sectionData.table}
          onDrillDown={this.handleDrillDown}
          onURLFilter={this.props.onURLFilter}
          onSortChange={this.handleTableSort}
          sort={sort}
        />
        <Paginator
          ref={this.paginatorRef}
          pageSize="10"
          totalRecords={this.state.sectionData.table_total_rows || 0}
          onPageChange={this.handlePageChange}
        />
      </React.Fragment>
    );
  }

  renderTrendGraph(section) {
    return (
      <SegmentsTrendGraph
        checkName={this.props.checkName}
        key="seg-map"
        data={this.state.sectionData.trend}
        def={section.trend}
        colors={[this._barColor].concat(this._colors)}
        maxSeries={5}
      />
    );
  }

  renderDonutGraph(section) {
    const data = this.state.sectionData.donut || {};
    const keys = Object.keys(data).sort((a, b) => (data[b] || 0) - (data[a] || 0)); // Sort descending by feature value.
    keys.splice(5); // Allow max 5 results because we like tidy donuts here.
    keys.sort(); // Sort alphabetically to be consistent with trend graph.
    const featureColors = {};
    for (const [ix, key] of Object.entries(keys)) {
      const color = this._colors[ix % (this._colors.length - 1)];
      featureColors[color] = {key, name: key};
    }
    return (
      <Donut
        checkName={this.props.checkName}
        data={this.state.sectionData.donut}
        header={section.donut.feature.name}
        featureColors={featureColors}
        title={section.donut.series.name}
      />
    );
  }

  renderMapGraph(section) {
    return (
      <LoadTimeWorldMap
        checkName={this.props.checkName}
        key="seg-map"
        data={this.state.sectionData.map}
        color={section.map.color}
        shade={section.map.shade}
      />
    );
  }
}
