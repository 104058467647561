'use strict';

import React from 'react';
import Ajax from '../../jskit/general/Ajax';
import Formatter from '../../jskit/general/Formatter';
import Utils from '../../jskit/general/Utils';
import FormWarning from '../../jskit/react/forms/FormWarning';

const DEFAULT_STATE = {
  callInProgress: false,
  callFinished: false,
  callSuccess: false,
};

export default class InstantCheckout extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.state = DEFAULT_STATE;
  }

  getRequestURL() {
    const {usageData} = this.props;
    const hardLimit = usageData.used >= this.props.planLimit;
    if (hardLimit && !this.props.isPrimary) {
      return this.props.purchaseRequestURL;
    }

    return this.props.userCanPurchase ? this.props.purchaseURL : this.props.purchaseRequestURL;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessionID !== this.props.sessionID) {
      // modal has been re-opened, reset state
      this.setState(DEFAULT_STATE);
    }
  }

  handleClick(e) {
    e.preventDefault();
    if (this.state.callInProgress) {
      return;
    }
    const {usageData} = this.props;
    const data = {
      added_values: {
        [usageData.limit_field]: usageData.bundle_size,
      },
    };
    this.setState({callSuccess: null, callFinished: false, callInProgress: true});
    new Ajax().post({
      url: this.getRequestURL(),
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({callSuccess: data.success});
      }.bind(this),
      complete: function () {
        this.setState({callInProgress: false, callFinished: true});
      }.bind(this),
    });
  }

  render() {
    const {usageData, userCanPurchase, accountCanPurchase} = this.props;
    if (!usageData || !usageData.exhausted) {
      return false;
    }

    const hardLimit = usageData.used >= this.props.planLimit;
    if (!accountCanPurchase && userCanPurchase) {
      // Covers the case for primary users of non-regular accounts. Everybody else
      // either can purchase, or can send purchase requests
      return this.renderSimpleWarning();
    }

    if (!this.state.callFinished) {
      return this.renderInitial(usageData, userCanPurchase, hardLimit);
    }

    const isPurchase = userCanPurchase && !hardLimit;
    if (this.state.callSuccess) {
      return this.renderSuccess(usageData, isPurchase);
    }

    return this.renderFailure(usageData, isPurchase);
  }

  renderSimpleWarning() {
    // Generic warning when user is unable to neither purchase new items nor send requests
    const {usageData} = this.props;
    if (!usageData) {
      return false;
    }
    const message = (
      <span>
        Your account has used {Formatter.humanFriendlyInt(usageData.limit)} {usageData.limit_name} check(s) and has
        reached its limit.
        <br />
        <a href={this.props.manageAddOnsURL}>
          <strong>Upgrade</strong>
        </a>{' '}
        your subscription, or{' '}
        <a href={this.props.contactUsURL}>
          <strong>contact us</strong>
        </a>{' '}
        to add more {usageData.limit_name} checks.
      </span>
    );
    return (
      <div className="mb-4">
        <FormWarning warnings={[message]} />
      </div>
    );
  }

  renderContainer(message, cta, cssClass) {
    const ctaCol = <div className="col-3 d-flex flex-column justify-content-between">{cta}</div>;
    return (
      <div className={'mb-4 pr-3 alert instant-checkout-container ' + cssClass}>
        <div className="row">
          <div className={cta ? 'col-9' : 'col'}>{message}</div>
          {cta ? ctaCol : false}
        </div>
      </div>
    );
  }

  renderInitial(usageData, canPurchase, hardLimit) {
    const bundleSize = usageData.bundle_size || 1;
    const bundleCost = usageData.bundle_price;
    const requestSentOn = usageData.request_sent_on;

    if (!usageData.limit) {
      const msgUnavailable = (
        <>
          Sorry, this check type is not available on your current subscription. Please{' '}
          <a target="_blank" href={this.props.contactUsURL}>
            contact us
          </a>{' '}
          or{' '}
          <a target="_blank" href={this.props.manageAddOnsURL}>
            upgrade
          </a>{' '}
          to have your account limit increased.
        </>
      );
      return this.renderContainer(msgUnavailable, null, 'alert-danger');
    }
    let checkoutTerms;
    if (canPurchase) {
      checkoutTerms = <p>This purchase will use your saved billing information that is configured on your account.</p>;
    } else {
      // request has already been sent and not paid yet
      if (requestSentOn) {
        return this.renderSuccess(usageData, canPurchase);
      }
      checkoutTerms = (
        <p>
          The purchase <strong>request</strong> will be sent to the <strong>owner of this account</strong>. Once it is
          purchased, the requested checks will be added to the account.
        </p>
      );
    }
    let message = (
      <React.Fragment>
        <p>
          You have reached the maximum number of <strong>{usageData.limit_name}</strong> checks available in your
          account.
          <br />
          Would you like to {canPurchase ? 'purchase' : 'request purchase'} an additional{' '}
          {bundleSize > 1 ? bundleSize : ''}
          {Formatter.pluralize(bundleSize, 'check', ' checks')} now for {Formatter.shortCurrency(bundleCost, 2)}?
        </p>
        {checkoutTerms}
        <p>
          If you have any questions don’t hesitate to <a href={this.props.contactUsURL}>contact us</a>.
        </p>
      </React.Fragment>
    );
    let cta = (
      <React.Fragment>
        <div className="p-2"></div>
        <button className="btn btn-primary text-nowrap" onClick={this.handleClick} disabled={this.state.callInProgress}>
          {canPurchase ? 'Purchase' : 'Send request'}
        </button>
        <a href={this.props.manageAddOnsURL} target="_blank" className="d-block text-center">
          Manually Upgrade
        </a>
      </React.Fragment>
    );

    if (hardLimit && this.props.isPrimary) {
      message = (
        <>
          <p>
            You have reached the maximum number of <strong>{usageData.limit_name}</strong> checks available in your
            current {Formatter.capitalizeFirstLetter(this.props.plan)} plan.
          </p>
          <p>In order to add more checks proceed to the Manage Subscription page to select a new plan.</p>
          <p>
            If you have any questions don’t hesitate to <a href={this.props.contactUsURL}>contact us</a>.
          </p>
        </>
      );
      cta = (
        <div className="d-flex align-items-center h-100">
          <a href={this.props.manageSubscriptionURL} className="btn btn-primary">
            Manage Subscription
          </a>
        </div>
      );
    }
    if (hardLimit && !this.props.isPrimary) {
      message = (
        <>
          <p>
            You have reached the maximum number of <strong>{usageData.limit_name}</strong> checks available in your
            current {Formatter.capitalizeFirstLetter(this.props.plan)} plan.
          </p>
          <p>
            In order to upgrade your plan and add more checks the owner of this account must be notified. Use the “Send
            Request” button to notify the account owner.
          </p>
          <p>
            If you have any questions don’t hesitate to <a href={this.props.contactUsURL}>contact us</a>.
          </p>
        </>
      );
      cta = (
        <div className="d-flex align-items-center h-100">
          <button
            className="btn btn-primary text-nowrap w-100"
            onClick={this.handleClick}
            disabled={this.state.callInProgress}
          >
            Send request
          </button>
        </div>
      );
    }

    return this.renderContainer(message, cta, 'alert-danger');
  }

  renderSuccess(usageData, canPurchase) {
    const bundleSize = usageData.bundle_size;
    const requestSentOn = usageData.request_sent_on;
    let message;
    if (canPurchase) {
      message = (
        <React.Fragment>
          <p>
            The payment was successful. Invoice details will appear on the{' '}
            <a href={this.props.invoicesURL} target="_blank">
              Invoices page
            </a>{' '}
            within next 2 hours.
          </p>
          <p>
            {bundleSize}{' '}
            <strong>
              {usageData.limit_name} {Formatter.pluralize(bundleSize, 'check', ' checks')}
            </strong>{' '}
            {Formatter.pluralize(bundleSize, 'was', ' were')} added to your account.
          </p>
        </React.Fragment>
      );
    } else {
      const requestSent = requestSentOn ? ' on ' + Formatter.shortDate(requestSentOn) : false;
      message = (
        <React.Fragment>
          <p>
            You have reached the maximum number of <strong>{usageData.limit_name}</strong> checks available in your
            account.
          </p>
          <p>
            A purchase request <strong>was sent</strong> to the owner of this account{requestSent}. Once it is paid, the
            requested checks will be added to the account.
          </p>
          <p>
            If you have any questions don’t hesitate to <a href={this.props.contactUsURL}>contact us</a>.
          </p>
        </React.Fragment>
      );
    }
    return this.renderContainer(message, null, canPurchase ? 'alert-success' : 'alert-danger');
  }

  renderFailure(usageData, canPurchase) {
    let message;
    if (canPurchase) {
      message = (
        <React.Fragment>
          <p>
            The transaction was <strong>unsuccessful</strong>. You can find the reason for transaction failure on the{' '}
            <a href={this.props.invoicesURL} target="_blank">
              Invoice page
            </a>{' '}
            within next 2 hours.
          </p>
          <p>
            You have reached the maximum number of <strong>{usageData.limit_name}</strong> checks available in your
            account.
          </p>
          <p>
            If you have any questions don’t hesitate to <a href={this.props.contactUsURL}>contact us</a>.
          </p>
        </React.Fragment>
      );
    } else {
      message = (
        <React.Fragment>
          <p>
            There was a <strong>problem</strong> sending the purchase request.
          </p>
          <p>
            You have reached the maximum number of <strong>{usageData.limit_name}</strong> checks available in your
            account.
          </p>
          <p>
            If you have any questions don’t hesitate to <a href={this.props.contactUsURL}>contact us</a>.
          </p>
        </React.Fragment>
      );
    }
    return this.renderContainer(message, null, 'alert-danger');
  }
}

InstantCheckout.defaultProps = {
  accountCanPurchase: false,
  userCanPurchase: false,
  usageData: null,
  contactUsURL: null,
  purchaseURL: null,
  purchaseRequestURL: null,
  plan: null,
  planLimit: null,
};
