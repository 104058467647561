'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import Chart from 'react-apexcharts';
import {defaultFormatter, millisecondFormatter, renderNoData} from '../RumUtils.jsx';
import {checkFeatures} from '../CheckFeatures.js';
import {defaultGraphOptions, toolbarExportOptions, manualFormat} from './GraphFormatter.js';
import {Colors} from '../RumColors.js';

export default class PerformanceDistributionGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    const defaultOptions = defaultGraphOptions();
    this.state = {
      series: [],
      options: {
        chart: Object.assign(defaultOptions.chart, {
          toolbar: Object.assign(defaultOptions.chart.toolbar, toolbarExportOptions(this.props.checkName, 'dist')),
        }),
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 3,
            columnWidth: '95%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: [
          {
            title: {
              text: checkFeatures.pageViews.name,
              style: {
                fontSize: '14px',
                fontWeight: 400,
              },
            },
            labels: {
              formatter: defaultFormatter,
            },
            tickAmount: 3,
          },
        ],
        xaxis: {
          labels: {
            formatter: this._xlabelFormatter.bind(this),
          },
        },
        legend: {
          show: false,
        },
        title: defaultOptions.title,
        grid: {
          padding: {
            top: 12,
            bottom: 3,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter: this._tooltipXFormatter.bind(this),
          },
          y: {
            formatter: this._tooltipYFormatter.bind(this),
            title: {
              formatter: () => checkFeatures.pageViews.name,
            },
          },
          marker: {
            show: false,
          },
        },
      },
    };
    this.chartRef = React.createRef();
    const parsedData = this._parseData(this.props.data, this.props.ranges);
    this._setData(parsedData);
  }

  componentDidMount() {
    manualFormat((this.chartRef.current || {}).chart);
  }

  componentDidUpdate(previousProps) {
    if (this.props.data !== previousProps.data) {
      const parsedData = this._parseData(this.props.data, this.props.ranges);
      this._setData(parsedData);
      const chart = (this.chartRef.current || {}).chart;
      if (chart) {
        chart.updateOptions(this.state.options, true, false, false);
        chart.updateSeries(this.state.series);
      }
      this.forceUpdate();
    }
  }

  _parseData(data, ranges) {
    if (!data || !ranges) {
      return {};
    }
    const values = data.values;
    const threshold = data[ranges.multiplier.key];
    if (!values || !values.length || !threshold) {
      return {};
    }

    const getColor = (value) => {
      const relVal = value / threshold;
      for (const [color, upper] of Object.entries(ranges)) {
        if (relVal <= upper) {
          return color;
        }
      }
      return Colors.black;
    };

    const seriesData = [];
    const colors = [];

    // Rendering the graph with all zero-values causes an error with ApexCharts.
    let hasData = false;

    for (const datum of values) {
      const x = datum.lower;
      const y = datum.count;
      seriesData.push({x, y});
      colors.push(getColor(x) || Colors.black);
      hasData |= !!y;
    }
    if (!hasData) {
      return {};
    }

    const series = [
      {
        name: this.props.title,
        data: seriesData,
      },
    ];

    return {series, colors};
  }

  /**
   * Set data state directly (not via setState) so that it can be parsed in the constructor.
   * This is necessary for the graph to appear correctly on its initial render.
   */
  _setData(parsedData) {
    this.state.series = parsedData.series || [];
    this.state.options.xaxis.labels.show = !!parsedData.series;
    this.state.options.colors = parsedData.colors || [Colors.black];
    this.state.options.title.text = this.props.title || '';
  }

  _xlabelFormatter(value) {
    if (value === '') {
      return ''; // Since not all labels are shown.
    }
    if (this.props.feature.units === 'ms') {
      return millisecondFormatter(value).string;
    }
    return defaultFormatter(value);
  }

  _tooltipXFormatter(_, options) {
    const {lower, upper} = this.props.data.values[options.dataPointIndex];
    let lowerString, upperString;
    if (this.props.feature.units === 'ms') {
      lowerString = millisecondFormatter(lower, 3).string;
      upperString = millisecondFormatter(upper, 3).string;
    } else {
      const units = this.props.feature.units || '';
      lowerString = `${defaultFormatter(lower)}${units}`;
      upperString = `${defaultFormatter(upper)}${units}`;
    }
    return `${lowerString} - ${upperString}`;
  }

  _tooltipYFormatter(_, options) {
    let totalCount = 0;
    for (const datum of this.props.data.values) {
      totalCount += datum.count;
    }
    const thisCount = this.props.data.values[options.dataPointIndex].count;
    const thisPct = parseFloat(((thisCount / totalCount) * 100).toPrecision(2));
    return `${defaultFormatter(thisCount, 3)} (${thisPct}%)`;
  }

  render() {
    return (
      <div className="rum-performance-distribution-graph">
        {renderNoData(this.state.series, this.props.noDataMessage)}
        <Chart
          ref={this.chartRef}
          type="bar"
          options={this.state.options}
          series={this.state.series}
          width="100%"
          height="300px"
        />
      </div>
    );
  }
}
