'use strict';

import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import {uniqueHtmlId} from './forms/FormHelpers';

import $ from 'jquery';

const STANDARD_BUTTONS = [
  {key: 'closeButton', label: 'Close', color: 'primary', wizard: 'modal-btn-close'},
  {key: 'previewButton', label: 'Preview', color: 'secondary', wizard: 'modal-btn-preview'},
  {key: 'saveButton', label: 'Save', color: 'primary', wizard: 'modal-btn-save'},
  {key: 'cancelButton', label: 'Cancel', color: 'outline-primary modal-btn-cancel', wizard: 'modal-btn-cancel'},
];

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.modalId = uniqueHtmlId('modal');
    this.modalTitleId = this.modalId + '-title';
  }

  showModal() {
    if (this.props.onShowComplete) {
      $(this.refs.modal).one('shown.bs.modal', this.props.onShowComplete);
    }
    if (this.props.onHideComplete) {
      $(this.refs.modal).one('hidden.bs.modal.bs.modal', this.props.onHideComplete);
    }
    // this will prevent closing the modal completely when closing the submodal with X button
    // but if cancelButton event handler is provided - will call it, too, on parent modal close
    $(this.refs.modal).on('click.dismiss.bs.modal', 'button.close', (e) => {
      if (this.props.subModalActive && this.props.onSubModalDismissed) {
        this.props.onSubModalDismissed(e);
      } else {
        if (this.props.cancelButton && _.isFunction(this.props.cancelButton)) {
          this.props.cancelButton(e);
        }
        this.hideModal();
      }
    });

    $(this.refs.modal).modal('show');
  }

  hideModal() {
    $(this.refs.modal).off('click.dismiss.bs.modal');
    $(this.refs.modal).modal('hide');
  }

  render() {
    const sizeClass = this.props.size ? `modal-${this.props.size}` : '';
    const isLargeModal = ['lg', 'xl'].includes(this.props.size);
    const modalCSS = ReactUtils.cssClass('modal-dialog', sizeClass, this.props.extraClass);
    const headerCSS = ReactUtils.cssClass('modal-header', {['p-lg-4']: isLargeModal});
    const bodyCSS = ReactUtils.cssClass('modal-body', {['px-lg-5 py-lg-4']: isLargeModal});
    const footerCSS = ReactUtils.cssClass('modal-footer', {['px-lg-5 py-lg-4']: isLargeModal});

    return (
      <div
        ref="modal"
        data-wizard={this.props.wizard}
        id={this.modalId}
        className="modal fade"
        data-backdrop="static"
        data-keyboard={this.props.keyboard}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={this.modalTitleId}
        aria-hidden="true"
      >
        <div className={modalCSS} role="document">
          <div className="modal-content">
            <div className={headerCSS}>
              <h3 id={this.modalTitleId} className="light modal-title">
                {this.props.subModalActive || this.props.title}
              </h3>
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              {this.props.headerExtra}
            </div>
            <div className={bodyCSS}>{this.props.children}</div>
            <div className={footerCSS}>
              {this.props.subModalActive
                ? this.renderSubModalButtons()
                : this.props.buttons || this.renderStandardButtons()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStandardButtons() {
    const buttonList = STANDARD_BUTTONS.map((buttonDef, i) => {
      const buttonProp = this.props[buttonDef.key];
      if (!buttonProp) {
        return null;
      }

      const onclick = (e) => {
        if (_.isFunction(buttonProp)) {
          buttonProp(e);
        }

        if (!e.isDefaultPrevented()) {
          this.hideModal();
        }
      };

      return (
        <button
          key={i}
          onClick={onclick}
          type="button"
          data-wizard={buttonDef.wizard}
          className={'btn btn-' + buttonDef.color}
        >
          {buttonDef.label}
        </button>
      );
    });

    return buttonList.filter((x) => !!x);
  }

  renderSubModalButtons() {
    return (
      <div className="w-100">
        <button onClick={this.props.onSubModalDismissed} type="button" className={'btn btn-outline-primary'}>
          {this.props.subModalDismissButtonTitle}
        </button>
      </div>
    );
  }
}

Modal.defaultProps = {
  title: '', // The title of the modal
  size: '', // Override modal size: 'sm', 'lg' or 'xl'
  extraClass: '', // Extra CSS classes for the main modal <div> tag
  headerExtra: null, // Extra content to place in the header section.
  buttons: null, // The footer/button area of the modal. Set subcomponents.
  closeButton: null, // Include a close button & call the given event handler if pressed.
  previewButton: null, // Include a Preview button & call the given event handler if pressed.
  saveButton: null, // Include a save button & call the given event handler if pressed.
  cancelButton: null, // Include a cancel button & call the given event handler if pressed.
  onShowComplete: null, // Callback when the modal is visible
  onHideComplete: null, // Callback when the modal is closed
  keyboard: true, // close modal using keyboard escape button
  wizard: undefined, // Set a data-wizard attribute on the modal

  subModalActive: null, // If we are showing a sub-modal, set this to the desired heading.
  onSubModalDismissed: null, // Callback when the "back" button of the submodal has been clicked
  subModalDismissButtonTitle: '< OK', // default back button title

  // children - the body of the modal.
};
