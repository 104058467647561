/**
 * JavaScript class for additional ajax functionality.
 * Wraps jQuery Ajax functions and supports queuing up ajax
 * requests and processing them sequentially.
 *
 * Depends on jQuery.
 *
 */

'use strict';

import $ from 'jquery';

/**
 * Set up an automatic HTTP request header to be sent with
 * every AJAX request, which contains the CSRF token for
 * Django.
 *
 * NOTE: You may need to use the @ensure_csrf_cookie decorator
 * on your view if this doesn't work.
 */
$(document).ajaxSend(function (event, xhr, settings) {
  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = $.trim(cookies[i]);
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  function sameOrigin(url) {
    // url could be relative or scheme relative or absolute
    var host = document.location.host; // host + port
    var protocol = document.location.protocol;
    var sr_origin = '//' + host;
    var origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (
      url === origin ||
      url.slice(0, origin.length + 1) === origin + '/' ||
      url === sr_origin ||
      url.slice(0, sr_origin.length + 1) === sr_origin + '/' ||
      // or any other URL that isn't scheme relative or absolute i.e relative.
      !/^(\/\/|http:|https:).*/.test(url)
    );
  }

  function safeMethod(method) {
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
  }

  if (!safeMethod(settings.type) && sameOrigin(settings.url)) {
    xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
  }
});

/**
 * Ajax class constructor.
 */
function Ajax() {
  this._numActiveRequests = 0;
  this._requestQueue = [];
  this._customDequeueFunction = null;
  this._cancelQueueOnError = true;
  this._useAutomaticWaitCursor = false;
  this._globalErrorHandler = null;

  if (Ajax._defaultSettings.useAutomaticWaitCursor !== undefined) {
    this.useAutomaticWaitCursor(Ajax._defaultSettings.useAutomaticWaitCursor);
  }

  if (Ajax._defaultSettings.globalErrorHandler !== undefined) {
    this.installGlobalErrorHandler(Ajax._defaultSettings.globalErrorHandler);
  }
}

Ajax._defaultSettings = {
  useAutomaticWaitCursor: true,
  globalErrorHandler: true,
};

/**
 * Globally set default settings for Ajax() instances.
 */
Ajax.setDefaultSettings = function (settings) {
  Ajax._defaultSettings = {
    useAutomaticWaitCursor: settings.useAutomaticWaitCursor,
    globalErrorHandler: settings.globalErrorHandler,
  };
};

/**
 * Function to cause the mouse cursor to change to an hourglass/
 * wait cursor whenever an ajax request is active.
 */
Ajax.prototype.useAutomaticWaitCursor = function (enable) {
  this._useAutomaticWaitCursor = enable ? true : false;
};

/**
 * Shows or hides a global wait cursor.
 */
Ajax.prototype.showWaitCursor = function (show) {
  if (show) {
    $('*').css({cursor: 'wait'});
  } else {
    $('*').css({cursor: ''});
  }
};

/**
 * Function to setup a default AJAX error handler. By default
 * uses the defaultGlobalErrorHandler() function of this class.
 *
 * Function signature is:
 *
 *    function(errorThrown, textStatus, jqXHR, options)
 */
Ajax.prototype.installGlobalErrorHandler = function (fn) {
  this._globalErrorHandler = fn && fn !== true ? fn : this.defaultGlobalErrorHandler;
};

/**
 * The default AJAX error handler.
 * Shows an alert box if the an Ajax request fails.
 */
Ajax.prototype.defaultGlobalErrorHandler = function () {
  alert('There was a problem retrieving the information. Please try again or reload the page.');
};

/**
 * If set to true, indicates that when a queued Ajax
 * request fails any other requests already in the queue
 * should be cancelled. Defaults to 'true' for data integrity
 * purposes.
 */
Ajax.prototype.cancelQueueOnError = function (enable) {
  this._cancelQueueOnError = enable ? true : false;
};

/**
 * Sets a custom function that handles de-queueing of the next
 * queued Ajax request (when using queueRequest/queueGetqueuePost).
 *
 * This function should accept an array of Ajax.queueRequest
 * 'options' objects, and return a single 'options' object to send
 * out as the next Ajax request.
 *
 * The function can combine multiple 'options' objects from the front
 * (0-indexed end) of the array into a single 'options' object
 * for efficiency.
 *
 * It should remove any items returned from array it is given.
 */
Ajax.prototype.setCustomDequeueFunction = function (fn) {
  this._customDequeueFunction = fn;
};

/**
 * Helper function to disable a jQuery list/selector of HTML elements.
 */

Ajax.prototype.disableHTMLElements = function (elements) {
  if (elements) {
    var list = $(elements);
    list.attr('disabled', 'disabled').addClass('disabled');

    for (var i = 0; i < list.length; i++) {
      var elem = list.get(i);
      elem.disabledOnclick = elem.onclick;
      elem.onclick = function (event) {
        event.preventDefault();
        return false;
      };
    }
  }
};

/**
 * Helper function to enables a jQuery list/selector of HTML elements.
 */

Ajax.prototype.enableHTMLElements = function (elements) {
  if (elements) {
    var list = $(elements);
    list.removeAttr('disabled').removeClass('disabled');

    for (var i = 0; i < list.length; i++) {
      var elem = list.get(i);
      if (elem.disabledOnclick) {
        elem.onclick = elem.disabledOnclick;
      } else {
        elem.onclick = null;
      }

      elem.disabledOnclick = undefined;
    }
  }
};

/**
 * Helper function to determine the content type to send
 * & to use for encoding.
 *
 * @param data object. Javascript object to encode.
 * @param encoder string. null, 'text', 'url', 'json' or 'formdata'
 */
Ajax.prototype._determineEncoderAndContentType = function (method, data, encoder) {
  if (encoder === undefined || encoder === null) {
    if (typeof data == 'object') {
      if (method === 'GET') {
        encoder = 'url';
      } else {
        encoder = 'json';
      }
    } else {
      encoder = 'text';
    }
  }

  if (encoder === 'url') {
    return ['url', 'application/x-www-form-urlencoded'];
  } else if (encoder === 'json' || ((encoder === undefined || encoder === null) && typeof data == 'object')) {
    return ['json', 'application/json'];
  } else if (encoder === 'formdata') {
    return ['formdata', false];
  } else {
    return ['text', 'text/plain'];
  }
};

/**
 * Helper function encode a javascript object into
 * URL-encoded or JSON format.
 *
 * @param data object. Javascript object to encode. Note for
 *      url-encoding, data must be key-value pairs only,
 *      except that value may be an array or primitives.
 * @param encoder string. 'text', 'url', 'json' or 'formdata' to use a FormData
 *      object that supports file uploads (provide the `fileInput.files[n]` as
 *      object values)
 */
Ajax.prototype.encode = function (data, encoder) {
  if (data !== undefined && data !== null && data !== '') {
    if (typeof data === 'string') {
      return data;
    }

    if (encoder === 'url') {
      var vars = '';

      for (var k in data) {
        var val = data[k] instanceof Array ? data[k] : [data[k]];

        for (var i = 0; i < val.length; i++) {
          if (vars) vars += '&';

          vars +=
            encodeURIComponent(k) + '=' + encodeURIComponent(val[i] === undefined || val[i] === null ? '' : val[i]);
        }
      }

      return vars;
    } else if (encoder === 'json') {
      return JSON.stringify(data);
    } else if (encoder === 'formdata') {
      var formData = new FormData();
      for (k in data) {
        formData.append(k, data[k]);
      }
      return formData;
    } else {
      return data;
    }
  }

  return '';
};

/**
 * Helper function decode a response into a javascript object.
 *
 * @param data string. Ajax response data.
 * @param decoder string. null, 'url' or 'json'.
 */
Ajax.prototype.decode = function (data, decoder) {
  if (decoder === 'url') {
    var response = decodeURI(data);
    var vars = response.split('&');
    response = {};

    for (var i in vars) {
      var v = vars[i];
      var idx = v.indexOf('=');
      if (idx < 0) continue;

      response[v.substring(0, idx)] = v.substring(idx + 1);
    }

    return response;
  } else if (decoder === 'json') {
    return JSON.parse(data);
  } else {
    return data;
  }
};

/**
 * Returns the number of active requests.
 */
Ajax.prototype.numActiveRequests = function () {
  return this._numActiveRequests;
};

/**
 * Helper function to make a GET Ajax request via jQuery.
 * See Ajax.sendRequest()
 */
Ajax.prototype.get = function (options) {
  options.method = 'GET';
  return this.sendRequest(options);
};

/**
 * Helper function to make a POST Ajax request via jQuery.
 * See Ajax.sendRequest()
 */
Ajax.prototype.post = function (options) {
  options.method = 'POST';
  return this.sendRequest(options);
};

/**
 * Helper function to make a queued GET Ajax request via jQuery.
 * See Ajax.queueRequest()
 */
Ajax.prototype.queueGet = function (options) {
  options.method = 'GET';
  this.queueRequest(options);
};

/**
 * Helper function to make a queued POST Ajax request via jQuery.
 * See Ajax.queueRequest()
 */
Ajax.prototype.queuePost = function (options) {
  options.method = 'POST';
  this.queueRequest(options);
};

/**
 * Helper function to make an arbitrary Ajax request via jQuery.
 *
 * @param options object. Dictionary containing any or all of
 *      below parameters.
 *
 * @param method string. GET or POST.
 * @param url string. The URL to make the request to.
 * @param disable string. A jQuery/CSS selector
 *      that identifies any elements that should be disabled
 *      when the request starts, and re-enabled when it finishes.
 *      Disabling an element is done by:
 *          - Adding a disabled="disabled" attribute to it.
 *          - Adding a CSS class "disabled" to it.
 * @param encoder string. Specifies how to encode the data.
 *      - null/undefined: send string data as is, or JSON encode object data
 *      - 'url': urlencode object data (to x-www-form-urlencoded key-value pairs)
 *      - 'json': encode JS object into a JSON string.
 * @param decoder string. Specifies how to decode the response.
 *      Defaults to same as encoder.
 *      - null/undefined: return string data as is.
 *      - 'url': URLDecode data into a javascript object.
 *      - 'json': Decode JSON data into a javascript object.
 * @param data object/string. Key-value pairs or string data
 *      to be sent with the request.
 * @param form string. A jquery/CSS selector of a form element to encode
 *      (Please ensure the form has "name" attributes and not ID's)
 * @param success(data, textStatus, jqXHR, options). Callback
 *      called if the request succeeds.
 * @param error(errorThrown, textStatus, jqXHR, options). Callback
 *      called if the request fails.
 * @param complete(textStatus, jqXHR, options). Callback called
 *      when the request completes, successfully or due to error.
 */
Ajax.prototype.sendRequest = function (options) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  // Disable any buttons/elements

  self.disableHTMLElements(options.disable);

  // Encode form

  if (options.form) {
    options.data = {};
    $.each($(options.form).serializeArray(), function (k, v) {
      options.data[v.name] = v.value;
    });
  }

  // Determine content type, encoder, decoder

  var enc = self._determineEncoderAndContentType(options.method, options.data, options.encoder);
  options.encoder = enc[0];
  var contentType = enc[1];

  if (options.decoder === undefined) {
    options.decoder = options.encoder;
  }

  // Send request
  var ajaxSettings = {
    type: options.method ? options.method : 'GET',
    url: options.url,
    contentType: contentType,
    data: this.encode(options.data, options.encoder),
    dataType: 'text',
  };

  if (options.encoder === 'formdata') {
    ajaxSettings.processData = false;
  }

  ajaxSettings.errorFn = function (jqXHR, textStatus, errorThrown) {
    if (textStatus === 'abort') {
      // Manually cancelled the request
      return;
    }

    if (!ajaxSettings.hasBeenRetried && jqXHR.status !== 429) {
      ajaxSettings.hasBeenRetried = true;
      ajaxSettings.skipNextComplete = true;

      $.ajax(ajaxSettings).fail(ajaxSettings.errorFn).done(ajaxSettings.successFn).always(ajaxSettings.completeFn);
    } else {
      if (options.error) {
        options.error(errorThrown, textStatus, jqXHR, options);
      }

      if (self._globalErrorHandler) {
        self._globalErrorHandler(errorThrown, textStatus, jqXHR, options);
      }
    }
  };

  ajaxSettings.successFn = function (data, textStatus, jqXHR) {
    data = self.decode(data, options.decoder);

    if (options.success) {
      options.success(data, textStatus, jqXHR, options);
    }
  };

  ajaxSettings.completeFn = function (data_or_jqXHR, textStatus, jqXHR_or_errorThrown) {
    if (ajaxSettings.skipNextComplete) {
      ajaxSettings.skipNextComplete = false;
      return;
    }

    self.enableHTMLElements(options.disable);
    self._numActiveRequests--;

    if (self._useAutomaticWaitCursor && self._numActiveRequests === 0) {
      self.showWaitCursor(false);
    }

    var p2 = null;

    if (typeof jqXHR_or_errorThrown == 'string') {
      // ajax error case
      p2 = data_or_jqXHR;
    } else {
      // ajax success case
      p2 = jqXHR_or_errorThrown;
    }

    if (options.complete) {
      options.complete(textStatus, p2, options);
    }

    self._dispatchNextQueuedRequestIfIdle();
  };

  self._numActiveRequests++;

  if (self._useAutomaticWaitCursor) {
    self.showWaitCursor(true);
  }

  return $.ajax(ajaxSettings).fail(ajaxSettings.errorFn).done(ajaxSettings.successFn).always(ajaxSettings.completeFn);
};

/**
 * Prepares an Ajax request and queues it up to be processed
 * sequentially rather than immediately.
 *
 * @param options object. See Ajax.sendRequest().
 *    Also accepts additional parameters below as part of options.
 *
 * @param cancelQueueOnError bool. If true and an Ajax error occurs,
 *    it empties all items remaining on the queue.
 */
Ajax.prototype.queueRequest = function (options) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this;
  var errorHandler = options.error;

  options.error = function (errorThrown, textStatus, jqXHR, options) {
    if (options.cancelQueueOnError || (options.cancelQueueOnError === undefined && self._cancelQueueOnError)) {
      self.clearAllQueuedRequests();
    }

    if (errorHandler) {
      errorHandler(errorThrown, textStatus, jqXHR, options);
    }
  };

  self._enqueueNewAjaxRequest(options);
};

/**
 * Removes all queued Ajax requests.
 */
Ajax.prototype.clearAllQueuedRequests = function () {
  this._requestQueue = [];
};

/**
 * Adds a new Ajax request to the queue and sends it
 * out if currently idle.
 */
Ajax.prototype._enqueueNewAjaxRequest = function (options) {
  this._requestQueue.push(options);
  this._dispatchNextQueuedRequestIfIdle();
};

/**
 * Checks if no Ajax operations are in progress and if so,
 * sends out the next queued one.
 */
Ajax.prototype._dispatchNextQueuedRequestIfIdle = function () {
  var options = null;

  if (this._numActiveRequests == 0 && this._requestQueue.length > 0) {
    if (this._customDequeueFunction) {
      options = this._customDequeueFunction(this._requestQueue);
    } else {
      options = this._requestQueue.shift();
    }

    this.sendRequest(options);
  }
};

//****************************
//* EXPORTS
//****************************

export default Ajax;
