'use strict';

import React from 'react';
import Modal from '../../jskit/react/Modal';
import ReactUtils from '../../jskit/react/ReactUtils';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';

export default class ExternalAccountUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialFormData) {
    if (initialFormData) {
      this.setState({formData: initialFormData});
    }

    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
    this.setState({formData: {}});
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = {};

    return (
      <Modal
        ref="modal"
        title={formData.id ? 'Edit External Account User' : 'Add External Account User'}
        size="lg"
        saveButton={this.props.onSave}
        cancelButton={true}
      >
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <FormErrors errors={this.props.errors.__all__} />
          <input type="hidden" name="id" value={formData.id || ''} />
          <div className="row">
            <div className="col-sm">
              <TextInput fieldName="first_name" labelText="First Name" isRequired={true} formLink={formLink} />
            </div>
            <div className="col-sm">
              <TextInput fieldName="last_name" labelText="Last Name" isRequired={true} formLink={formLink} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <TextInput fieldName="email" labelText="Email Address" isRequired={true} formLink={formLink} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm">{ExternalAccountUserForm.renderPasswordHelpText(formLink, formData)}</div>
          </div>
        </form>
      </Modal>
    );
  }

  static renderPasswordHelpText(formLink, formData) {
    if (formData.id) {
      if (formData.is_current_user) {
        return (
          <div className="help-text" style={{padding: '10px'}}>
            Please <a href="#">click here</a> if you need to change your password.
          </div>
        );
      } else {
        return '';
      }
    } else {
      return (
        <div className="help-text">
          An email invite will be sent to this address <br />
          with a link for creating a password.
        </div>
      );
    }
  }
}
