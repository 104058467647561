import React, {useState} from 'react';
import ReactTooltip from 'react-tooltip';

export const HelpIcon = ({helpText, ...props}: {helpText: string} & React.HTMLAttributes<HTMLDivElement>) => {
  const [randomID] = useState(String(Math.random()));

  return (
    <>
      <div {...props}>
        <i data-tip={helpText} data-for={randomID} className="fas fa-info-circle ml-2"></i>
      </div>
      <ReactTooltip id={randomID} place="top" effect="solid" />
    </>
  );
};
