'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, HelpIcon} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export class ToggleSwitchWidget extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);
    return (
      <label className={ReactUtils.cssClass('toggle-switch', {disabled: this.props.disabled})} htmlFor={this.htmlId}>
        <input
          type="checkbox"
          id={this.htmlId}
          name={form.fieldName}
          disabled={this.props.disabled}
          checked={form.value}
          onChange={form.onChange}
        />
        <span className="toggle-switch-slider" />
      </label>
    );
  }
}

ToggleSwitchWidget.defaultProps = {
  fieldName: null, // Field name returned in form data
  disabled: false, // Control is disabled?

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value

  onChange: null, // Change handler
};

export class ToggleSwitchPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body px-3 py-2 border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="card-title d-inline-block m-0">{this.props.title}</h4>
              <HelpIcon titleText={this.props.titleText} />
            </div>
            <ToggleSwitchWidget {...this.props} />
          </div>
        </div>
        <div className="card-body px-3 py-3">{this.props.children}</div>
      </div>
    );
  }
}

ToggleSwitchPanel.defaultProps = {
  // ...ToggleSwitchWidget.defaultProps + children +
  title: 'Panel',
  titleText: '',
};
