import React from 'react';
import {Label} from '../../jskit/react/forms/FormHelpers';

export default class ServiceListPanel extends React.Component {
  render() {
    return (
      <div className="service-list-panel form-group">
        <Label labelText={this.props.labelText} titleText={this.props.titleText} isRequired={false} />
        <ul>
          {this.props.services.map(this.renderService.bind(this))}
          {this.props.hasMoreServices ? (
            <li key="more" className="text-warning non-link">
              <strong>
                <em>Refine filter for more checks...</em>
              </strong>
            </li>
          ) : null}
        </ul>
      </div>
    );
  }

  renderService(svc) {
    return (
      <li key={svc.id} data-id={svc.id}>
        <a onClick={this.props.onServiceClick.bind(null, svc)}>
          <span className="type">{svc.type}</span>
          <span className="name" title={svc.name}>
            {svc.name}
          </span>
          <span className="address" title={svc.address}>
            {svc.address}
          </span>
        </a>
      </li>
    );
  }
}
