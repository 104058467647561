'use strict';

import $ from 'jquery';
var ALERT_MESSAGE_TIMEOUT = 15000;

/**
 * Component for auto-hiding alert messages form the django.contrib.messages app,
 * and dynamically adding new alerts.
 *
 * Selector: #alert-messages
 */
export function initializeAlertMessages() {
  setTimeout(function () {
    hideAlertMessages();
  }, ALERT_MESSAGE_TIMEOUT);
}

/**
 * Hide alert messages
 *
 * Selector: #alert-messages
 */
export function hideAlertMessages() {
  const alertContainerIds = ['#alert-messages', '#alert-messages-dynamic'];
  alertContainerIds.forEach(function (containerId) {
    if ($(containerId + ' div').length > 0) {
      $(containerId + ' .alert:not([data-permanent]):not(.permanent)').alert('close');
    }
  });
}

/**
 * Posts a new dynamic message to the screen in fixed position.
 *
 * @param message  The message to show.
 * @param level  Bootstrap message level (info, success, warning, danger)
 * @param permanent  Set to true if message should not be auto-closed.
 */
export function postAlertMessage(message, level, permanent) {
  level = level || 'info';

  if (level === 'error') {
    level = 'danger';
  }

  var alert = $('<div/>')
    .attr('role', 'alert')
    .addClass('start-hidden alert alert-' + level + ' alert-dismissible fade show text-center mb-4');
  alert.append(
    $('<button><span aria-hidden="true">&times;</span></button>').attr({
      type: 'button',
      class: 'close',
      'data-dismiss': 'alert',
      'aria-label': 'Close',
    })
  );

  alert.append(message);
  $('#alert-messages-dynamic').append(alert);

  setTimeout(function () {
    alert.fadeIn();
  }, 0);

  if (!permanent) {
    setTimeout(function () {
      alert.alert('close');
    }, ALERT_MESSAGE_TIMEOUT);
  }
}
