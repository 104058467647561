import Select, {components, GroupBase, Props} from 'react-select';
import React from 'react';

const SingleValue = (props) => {
  const {data} = props;
  return <components.SingleValue {...props}>{data.label}</components.SingleValue>;
};

interface SelectArrayOptionsProps {
  labelIndex: number;
  value: any;
}

export const SelectArrayOptions = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({
  labelIndex,
  value,
  ...props
}: Props<OptionType, IsMulti, GroupType> & SelectArrayOptionsProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Select {...props} value={value ? {label: value[labelIndex]} : null} components={{SingleValue}} />;
};
