'use strict';

import Ajax from '../../jskit/general/Ajax';
import React from 'react';
import Utils from '../../jskit/general/Utils';
import Component from './Component.jsx';
import ComponentForm from './ComponentForm.jsx';
import ComponentGroupForm from './ComponentGroupForm.jsx';
import * as ManageUtils from './ManageUtils.jsx';
import MessageBox from '../../js/global/messagebox';

export default class ComponentsTab extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  getComponentGroupChoices() {
    const groups = this.props.statuspage.components.filter((c) => c.is_group);
    return groups.map((g) => [g.id, g.name]);
  }

  handleStatusChange(component, newStatus, e) {
    e.preventDefault();
    new Ajax().post({
      url: this.props.updateComponentURL,
      data: {id: component.id, status: newStatus},
      encoder: 'json',
      decoder: 'json',
      success: (data) => {
        if (data.success) {
          this.props.onNewData(data.data);
        }
      },
    });
  }

  handleAddGroup(e) {
    e.preventDefault();
    this.refs.componentGroupForm.showModal();
  }

  handleAddComponent(e) {
    e.preventDefault();
    this.refs.componentForm.showModal();
  }

  handleEdit(component, e) {
    e.preventDefault();
    if (component.is_group) {
      this.refs.componentGroupForm.showModal(component);
    } else {
      this.refs.componentForm.showModal(component);
    }
  }

  handleDelete(component, e) {
    e.preventDefault();

    let msg = 'Are you sure you want to delete "' + component.name + '"?';
    msg += ' This will affect the components listed in any related incident.';
    MessageBox.confirmBox(msg, 'Delete Component', () => {
      new Ajax().post({
        url: component.delete_url,
        data: {},
        encoder: 'json',
        decoder: 'json',
        success: (data) => {
          if (data.success) {
            this.props.onNewData(data.data);
          }
        },
      });
    });
  }

  handleDrag(srcEl, dstEl, dstIsExpandedGroup) {
    const statuspage = this.props.statuspage;
    let targetGroupId = dstEl.group;
    let insertBefore = dstEl;

    if (dstEl.is_group && dstIsExpandedGroup && dstEl.id !== srcEl.group && !srcEl.is_group) {
      // a non-group component dropped on an different expanded group
      targetGroupId = dstEl.id;
      insertBefore = null;
    } else if (dstEl.is_group && dstEl.id === srcEl.group) {
      // dropped on it's own expanded group
      insertBefore = dstEl;
    }

    if (srcEl.is_group && targetGroupId !== null) {
      return;
    }

    const newGroupId = srcEl.group !== targetGroupId ? targetGroupId : undefined;

    // move the element to target position and record new sorting order of the affected group group
    ManageUtils.moveComponent(statuspage.components, srcEl, targetGroupId, insertBefore);

    let affectedComponents = statuspage.components;

    if (targetGroupId !== null) {
      const affectedGroupPos = ManageUtils.findComponentIndex(statuspage.components, targetGroupId, true);
      affectedComponents = affectedGroupPos.component.subcomponents;
    }

    const sortOrder = [];
    affectedComponents.forEach((component, idx) => {
      sortOrder.push({
        componentId: component.id,
        isGroup: component.is_group,
        sortingWeight: idx,
      });
    });

    // post to backend new ordering and, if necessary, updates to the component
    const postData = {
      sortOrder,
      updateComponent: newGroupId !== undefined ? {componentId: srcEl.id, newGroupId} : null,
    };
    new Ajax().post({
      url: this.props.reorderURL,
      data: postData,
      encoder: 'json',
      decoder: 'json',
      success: (data) => {
        if (data.success) {
          this.props.onNewData(data.data);
        }
      },
    });
  }

  countComponentsAndGroups(components) {
    const reducer = (acc, comp) => acc + 1 + (comp.subcomponents ? comp.subcomponents.length : 0);
    return components.reduce(reducer, 0);
  }

  render() {
    const statuspage = this.props.statuspage;
    const choices = Object.assign({}, this.props.choices, {componentGroups: this.getComponentGroupChoices()});
    return (
      <React.Fragment>
        {statuspage.components.length ? this.renderComponents(statuspage) : this.renderEmptyState()}
        <ComponentGroupForm
          ref="componentGroupForm"
          updateURL={this.props.updateComponentURL}
          onNewData={this.props.onNewData}
        />
        <ComponentForm
          ref="componentForm"
          updateURL={this.props.updateComponentURL}
          servicesCheckListURL={this.props.servicesCheckListURL}
          choices={choices}
          onNewData={this.props.onNewData}
        />
      </React.Fragment>
    );
  }

  renderComponents(statuspage) {
    const components = statuspage.components.map((component) => (
      <Component
        key={component.id}
        component={component}
        componentStatusChoices={this.props.choices.componentStatus}
        componentStatusRank={this.props.componentStatusRank}
        showResponseTime={statuspage.show_component_response_time}
        onStatusChange={this.handleStatusChange}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        onDrag={this.handleDrag}
      />
    ));

    return (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="text-muted mr-3">
            {this.countComponentsAndGroups(statuspage.components)} component(s) &amp; group(s)
          </div>
          {this.renderAddButtons()}
        </div>
        <div className="component-list white-block white-block-border">
          <div className="component-header py-2">
            <div className="row align-items-end">
              <div className={statuspage.show_component_response_time ? 'col-sm-5' : 'col-sm-7'}>Name</div>
              {statuspage.show_component_response_time ? <div className="col-sm-2">Response Time</div> : null}
              <div className="col-sm-3">Status</div>
            </div>
          </div>
          {components}
          <div className="component-end"></div>
        </div>
      </React.Fragment>
    );
  }

  renderEmptyState() {
    return (
      <div className="text-center p-lg-5 white-block white-block-border">
        <div className="font-weight-bold font-24 mb-4">You have not yet added any Components...</div>
        <div className="font-weight-normal font-14 mb-5">
          {/* eslint-disable-next-line max-len */}
          Add a component or group of components to represent the functional parts of your infrastructure, such as
          websites, API endpoints, or databases.
        </div>
        <div>{this.renderAddButtons()}</div>
      </div>
    );
  }

  renderAddButtons() {
    return (
      <div>
        <button
          data-wizard="statuspages-manage-components-add-component"
          onClick={this.handleAddComponent}
          className="btn btn-sm btn-outline-primary mr-2"
        >
          <i className="fas fa-plus mr-2" />
          Add Component
        </button>
        <button
          data-wizard="statuspages-manage-components-add-group"
          onClick={this.handleAddGroup}
          className="btn btn-sm btn-outline-primary"
        >
          <i className="fas fa-plus mr-2" />
          Add Group
        </button>
      </div>
    );
  }
}

ComponentsTab.defaultProps = {
  updateComponentURL: null,
  updateComponentGroupURL: null,
  reorderURL: null,
  statuspage: null,
  choices: null,
  onNewData: null,
};
