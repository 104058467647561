'use strict';

import React from 'react';
import Incident from './Incident.jsx';

export default class IncidentsList extends React.PureComponent {
  render() {
    const incidents = this.props.incidents.map((incident, i) => {
      return <Incident key={i} incident={incident} componentStatusRank={this.props.componentStatusRank} />;
    });

    return (
      <div className="incident-list">
        {incidents}
        <div className="incident-end"></div>
      </div>
    );
  }
}

IncidentsList.defaultProps = {
  componentStatusRank: null,
  incidents: [],
};
