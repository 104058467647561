'use strict';

import React from 'react';
import './BulkActionsMenu.scss';
import QuickActions from './QuickActions';

type BulkActionsMenuProps = {
  numSelected: number;

  onPauseClick: React.MouseEventHandler;
  onResumeClick: React.MouseEventHandler;
  onBulkIntervalClick: React.MouseEventHandler;
  onBulkLocationClick: React.MouseEventHandler;
  onBulkContactClick: React.MouseEventHandler;
  onRetryAndSensitivityClick: React.MouseEventHandler;
  onBulkSLAClick: React.MouseEventHandler;
  onBulkEscalationsClick: React.MouseEventHandler;
  onBulkMaintenanceClick: React.MouseEventHandler;
  onBulkTagsClick: React.MouseEventHandler;
  onBulkVersionClick: React.MouseEventHandler;
  onBulkCreateGroupClick: React.MouseEventHandler;
  onBulkAddToGroupClick: React.MouseEventHandler;
  onDeleteClick: React.MouseEventHandler;
};

const BulkActionsMenu = ({numSelected, ...props}: BulkActionsMenuProps) => {
  if (!numSelected) {
    return <div className="text-muted font-14">Select checks for bulk actions</div>;
  }

  return (
    <div className="w-30 d-flex">
      <span className="font-14 mr-3 pt-1">{`${numSelected} Selected`}</span>
      <span className="pt-1">
        <i className=" fa fa-regular fa-pipe text-muted mr-3"></i>
      </span>
      <QuickActions
        moreActionsText="More Bulk Actions"
        actions={[
          {
            text: 'Pause checks',
            onClick: props.onPauseClick,
            className: 'fa-pause',
          },
          {
            text: 'Resume checks',
            onClick: props.onResumeClick,
            className: 'fa-play',
            endsBlock: true,
          },
          {
            text: 'Set maintenance',
            onClick: props.onBulkMaintenanceClick,
            className: 'fa-calendar-plus',
          },
          {
            text: 'Set locations',
            onClick: props.onBulkLocationClick,
            className: 'fa-location-dot',
          },
          {
            text: 'Set intervals',
            onClick: props.onBulkIntervalClick,
            className: 'fa-hourglass-half',
          },
          {
            text: 'Set Contacts',
            onClick: props.onBulkContactClick,
          },
          {
            text: 'Set Sensitivity, Retries and Timeout',
            onClick: props.onRetryAndSensitivityClick,
          },
          {
            text: 'Set SLA',
            onClick: props.onBulkSLAClick,
          },
          {
            text: 'Set Escalations',
            onClick: props.onBulkEscalationsClick,
          },
          {
            text: 'Set Tags',
            onClick: props.onBulkTagsClick,
          },
          {
            text: 'Upgrade Checker Versions',
            onClick: props.onBulkVersionClick,
            endsBlock: true,
          },
          {
            text: 'Create Group Check',
            onClick: props.onBulkCreateGroupClick,
          },
          {
            text: 'Add to Group Check',
            onClick: props.onBulkAddToGroupClick,
          },
          {
            text: 'Delete Selected Checks',
            onClick: props.onDeleteClick,
          },
        ]}
        buttonsDisplayed={5}
      />
    </div>
  );
};

export default BulkActionsMenu;
