import React, {PropsWithChildren} from 'react';
import {StatusPage} from '@/status_pages/types';

export interface LegacyGlobalStatusProps {
  statuspage: StatusPage;
}

const LegacyStatusWrapper = ({children}: PropsWithChildren) => <div className="mb-4">{children}</div>;

export const LegacyGlobalStatus = ({statuspage}: LegacyGlobalStatusProps) => {
  if (statuspage.global_is_operational) {
    return (
      <LegacyStatusWrapper>
        <div className="global-status up">All systems operational</div>
        {statuspage.contact_email ? (
          <small className="text-muted font-12">
            Experiencing an issue? <a href={'mailto:' + statuspage.contact_email}>Contact us</a>
          </small>
        ) : null}
      </LegacyStatusWrapper>
    );
  }

  if (statuspage.has_components_under_critical_state) {
    return (
      <LegacyStatusWrapper>
        <div className="global-status down">Some systems are experiencing issues</div>
      </LegacyStatusWrapper>
    );
  }

  if (statuspage.has_components_under_maintenance_state) {
    return (
      <LegacyStatusWrapper>
        <div className="global-status maintenance">Some systems are under maintenance</div>
      </LegacyStatusWrapper>
    );
  }
};
