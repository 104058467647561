'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import WorldMap from './WorldMap';
import {defaultFormatter, hexToRgba} from '../RumUtils';
import WorldMapLegend from './WorldMapLegend';
import {Colors} from '../RumColors';
import {checkFeature} from '../CheckFeatures';

/** Default bin colors if none are passed in */
const _binColors = [
  Colors.paleBlue, // Min value bin.
  Colors.lightBlue,
  Colors.mediumLightBlue,
  Colors.blue,
  Colors.darkBlue, // Max value bin.
];

/** Get user-friendly bins for shade distribution */
const _valueBins = (data) => {
  let max;
  for (const datum of data) {
    max = max === undefined ? datum : Math.max(max, datum);
  }
  max = parseFloat(max.toPrecision(2)); // Round down to 2 significant figures.
  const bins = [];
  for (let ixBin = 4; ixBin > 0; ixBin--) {
    bins.push({
      upper: parseFloat((max / ixBin).toPrecision(1)),
      color: _binColors[_binColors.length - 1 - ixBin],
    });
  }
  bins.push({
    upper: Infinity,
    color: _binColors[_binColors.length - 1],
  });
  return bins;
};

/** Get country color from raw value */
const _valueColor = (colorValue, colorBins, shadeValue, minShadeValue, maxShadeValue) => {
  let baseColor;
  for (const bin of colorBins) {
    if (colorValue <= bin.upper) {
      baseColor = bin.color;
      break;
    }
  }
  const logMin = minShadeValue ? Math.log10(minShadeValue) : undefined;
  const logMax = maxShadeValue ? Math.log10(maxShadeValue) : undefined;
  baseColor = baseColor || colorBins[colorBins.length - 1].color;
  if (shadeValue !== undefined && logMin !== undefined && logMax !== undefined) {
    // Make the color more transparent the smaller the value, with minimum opacity of 0.25.
    const logThis = Math.log10(shadeValue);
    const opacity = 1 - (1 - (logThis - logMin) / (logMax - logMin)) * 0.75;
    return hexToRgba(baseColor, opacity);
  }
  return baseColor;
};

export default class LoadTimeWorldMap extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      featureValues: [],
      valueBins: [],
      markers: [],
      opacityFeature: undefined,
      maxValue: undefined,
    };
  }

  componentDidMount() {
    this._parseData();
  }

  _parseData() {
    const color = this.props.color || {};
    const {feature: colorFeature, ranges: colorRanges} = color;
    const {feature: shadeFeature} = this.props.shade || {};
    const data = (this.props.data || {}).data;
    if (!data || !colorFeature) {
      return;
    }
    const colorData = [];
    for (const datum of data) {
      const colorValue = datum[colorFeature.key];
      if (colorValue === undefined) {
        continue;
      }
      colorData.push(colorValue);
    }
    if (!colorData.length) {
      return;
    }

    if (colorRanges) {
      let multiplier = 1;
      if (colorRanges.multiplier) {
        multiplier = this.props.data[colorRanges.multiplier.key] || 1;
      }
      const valueBins = [];
      for (const [color, upper] of Object.entries(colorRanges)) {
        if (typeof upper !== 'number') {
          continue;
        }
        valueBins.push({
          color,
          upper: upper * multiplier,
        });
      }
      this.state.valueBins = valueBins;
    } else {
      this.state.valueBins = _valueBins(colorData);
    }

    let minShadeValue, maxShadeValue;
    if (shadeFeature) {
      for (const datum of data) {
        const shadeValue = datum[shadeFeature.key];
        if (shadeValue === undefined) {
          continue;
        }
        minShadeValue = minShadeValue === undefined ? shadeValue : Math.min(minShadeValue, shadeValue);
        maxShadeValue = maxShadeValue === undefined ? shadeValue : Math.max(maxShadeValue, shadeValue);
      }
    }
    this.state.maxValue = maxShadeValue; // Don't trigger refresh.

    this.state.featureValues.length = 0;
    for (const datum of data) {
      const colorValue = datum[colorFeature.key];
      const shadeValue = shadeFeature ? datum[shadeFeature.key] : undefined;
      this.state.featureValues.push({
        ISO2: datum.ISO2,
        datum,
        color: _valueColor(colorValue, this.state.valueBins, shadeValue, minShadeValue, maxShadeValue),
      });
    }

    this.state.opacityFeature = checkFeature('opacity', `${this.props.shade.feature.name} (opacity)`);

    this.state.markers.length = 0;
    for (const marker of data.markers || []) {
      this.state.markers.push({
        name: marker.name,
        coordinates: [marker.latLon[1], marker.latLon[0]],
        color: 'green', // This will need to be modified if markers are re-added.
        value: marker.value,
      });
    }
  }

  _valueTooltip(geo, datum) {
    let bottom = '';
    if (!datum || !datum.datum) {
      bottom = 'No data';
    } else {
      const features = [this.props.color.feature, (this.props.shade || {}).feature];
      for (const [ix, feature] of features.entries()) {
        if (!feature) {
          continue;
        }
        const value = datum.datum[feature.key];
        const valueString = value === undefined ? 'No data' : defaultFormatter(value, 3, feature);
        bottom += `${feature.name}: ${valueString}`;
        if (ix < features.length - 1) {
          bottom += '<br />';
        }
      }
    }
    return `${geo.properties.NAME}<br />${bottom}`;
  }

  _markerTooltip(name, value) {
    let bottom;
    if (value === undefined) {
      bottom = 'No data';
    } else {
      const feature = this.props.marker.feature;
      bottom = `${feature.shortName}: ${defaultFormatter(value, 2, feature)}`;
    }
    return `${name}<br />${bottom}`;
  }

  _renderOpacityLegend(feature) {
    return (
      <div className="opacity-legend-container">
        <div className="opacity-legend-title">{feature.name}</div>
        <div className="opacity-legend-lower">
          <p className="opacity-legend-label">0</p>
          <div className="opacity-gradient"></div>
          <p className="opacity-legend-label">{defaultFormatter(this.state.maxValue)}</p>
        </div>
      </div>
    );
  }

  render() {
    this._parseData();
    const containerStyle = {display: 'flex', flexDirection: 'column', alignItems: 'center'};
    const legendContainerStyle = {display: 'flex', flexDirection: 'row', alignItems: 'center'};

    return (
      <div className="load-time-world-map mb-3" style={containerStyle}>
        <WorldMap
          data={this.state.featureValues}
          markers={this.state.markers}
          valueTooltip={this._valueTooltip}
          markerTooltip={this._markerTooltip}
        />
        <div className="world-map-legends" style={legendContainerStyle}>
          <WorldMapLegend feature={this.props.color.feature} bins={this.state.valueBins} />
          {!!this.state.opacityFeature && this._renderOpacityLegend(this.state.opacityFeature)}
        </div>
      </div>
    );
  }
}
