// this is the validation schema for the public status page form
import * as yup from 'yup';
import {VisibilityLevel} from './StatusPageEnums';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormModal} from '../../../components/form/FormModal';
import {FormLabel} from '../../../components/form/FormLabel';
import {FormPublicUrlSlugInput} from './FormPublicUrlSlugInput';
import {FormStatusPagePublicComponents} from './FormStatusPagePublicComponents';
import {ServerErrors} from '../../../types/response.types';
import {FormErrorAlerts} from '../../../components/form/FormErrorAlerts';

const PublicStatusPageSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
  slug: yup.string().when('visibility_level', {
    is: VisibilityLevel.PUBLIC,
    then: yup.string().required('This field is required to publish your status page'),
  }),
  visibility_level: yup.mixed<VisibilityLevel>().oneOf(Object.values(VisibilityLevel)),
  initial_components: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      description: yup.string(),
    })
  ),
});
export const PublicStatusPageFormModal = ({
  show,
  onSubmit,
  onCancel,
  errors,
  siteUrl,
  publicViewUrl,
}: {
  show: boolean;
  onSubmit: (data: any) => void;
  onCancel: () => void;
  errors: ServerErrors;
  siteUrl: string;
  publicViewUrl: string;
}) => {
  return (
    <FormModal onCancel={onCancel} show={show} formId={'PublicStatusPageForm'} name="New Status Page">
      <PublicStatusPageForm
        onSubmit={onSubmit}
        serverErrors={errors}
        formId={'PublicStatusPageForm'}
        siteUrl={siteUrl}
        publicViewUrl={publicViewUrl}
      />
    </FormModal>
  );
};

const PublicStatusPageForm = ({
  onSubmit,
  serverErrors,
  formId = 'PublicStatusPageForm',
  siteUrl,
  publicViewUrl,
}: {
  onSubmit: (data: any) => void;
  serverErrors: ServerErrors;
  formId?: string;
  siteUrl: string;
  publicViewUrl: string;
}) => {
  const methods = useForm({
    resolver: yupResolver(PublicStatusPageSchema),
    defaultValues: {visibility_level: VisibilityLevel.UPTIME_USERS},
  });
  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = methods;

  useEffect(() => {
    if (serverErrors) {
      Object.keys(serverErrors).forEach((key) => {
        setError(key, {message: serverErrors[key][0]});
      });
    }
  }, [serverErrors]);

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <FormErrorAlerts errors={serverErrors.__all__} />
        <div className="form-group">
          <FormLabel formId={'name'} required textStyle={{fontWeight: 700}}>
            Name
          </FormLabel>
          <input
            type="text"
            id={'name'}
            name="name"
            placeholder="Name of the Status Page"
            {...register('name')}
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          />
          {errors.name && <div className="invalid-feedback d-block font-weight-bold">{errors.name.message}</div>}
        </div>
        <FormPublicUrlSlugInput
          publicViewUrl={publicViewUrl}
          siteUrl={siteUrl}
          slugName={'slug'}
          checkBoxName={'visibility_level'}
          errors={errors}
        />
        <hr />
        <FormStatusPagePublicComponents register={register} errors={errors} />
      </form>
    </FormProvider>
  );
};
