/**
 * Appcues initialization & user property send.
 *
 * To debug appcues, add `?hey_appcues` to your URL.
 */
'use strict';

import $ from 'jquery';
import Cookie from '../../jskit/general/Cookie';
import URLHistory from '../../jskit/general/URLHistory';

const ADBLOCK_MODAL = `
    <div id="appcues-adblock-modal" class="modal fade" data-backdrop="static"
         tabindex="-1" role="dialog" aria-labelledby="appcues-adblock-modal-title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="light modal-title" id="appcues-adblock-modal-title">Guided Tours Available</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="mt-3 text-center">
                        <img class="img-fluid" style="width: 220px;" 
                        src="/static/img/global/logo-uptime-dark.svg" width="220" height="49" alt="Uptime"/>
                    </p><p class="mt-4">
                        Ad blockers may be preventing you from viewing our guided product tours.
                        These tours are intended to help inform your use of Uptime.com, and are
                        1-click away when you disable your adblocker with the promise that we will
                        NEVER display ads.
                    </p><p>
                        Thanks and Happy Monitoring!
                    </p>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" type="button" class="btn btn-primary">Continue</button>
                </div>
            </div>
        </div>
    </div>
`;

/*
 * Initializes the appcues script onload & error handlers, and
 * receives the context for user tracking.
 */
function initializeAppcues(scriptSelector, userId, context) {
  const AppcuesFailed = function () {
    if (Cookie.getCookie('appcues_adblock_modal_shown')) {
      return;
    }
    const $modal = $(ADBLOCK_MODAL).insertAfter(scriptSelector);
    $modal
      .one('hide.bs.modal', function () {
        Cookie.setCookie('appcues_adblock_modal_shown', '1', 90);
      })
      .modal('show');
  };

  const AppcuesReady = function (callback) {
    callback && AppcuesReady.q.push(callback);
    if (window.Appcues) {
      while (AppcuesReady.q.length) {
        const cb = AppcuesReady.q.shift();
        if (typeof cb === 'function') cb();
      }
    }
  };

  const AppcuesCleanupQuery = function () {
    // in case there was a permalink parameter, we want to clean it up
    // in order for the flow to not repeat on page reload
    // https://docs.appcues.com/user-experiences-studio/permalinks
    URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['appcue']));
  };
  AppcuesReady.q = [];

  $(scriptSelector).on('load', AppcuesReady);
  $(scriptSelector).on('error', AppcuesFailed);

  AppcuesReady(function () {
    // Identify the user and send user properties if we have them, then set
    // a cookie so they're not generated again until a few hours from now
    window.Appcues.identify(userId, context || {});

    // Call after the flow started - for cleanup mostly
    window.Appcues.on('flow_started', AppcuesCleanupQuery);
    if (context) {
      Cookie.setCookie('appcues_props_sync', '1', 1 / 8); // Expire in 3h
    }
  });
}

export default initializeAppcues;
