/**
 * Classes for calculating projections (lat, long >> coordinates) for world maps.
 */
'use strict';

/**
 * Calculates coordinates from lat/long for a Cylindrical, Mercator or Miller Projection world map.
 */
var LatitudeTransformProjection = function (settings) {
  this.settings = {
    projection: settings.projection || 'cylindrical', // Projection to use, 'cylindrical' (default), 'mercator' or 'miller'
    northLat: settings.northLat || 89.99, // Latitude of the northenmost part of the map
    southLat: settings.southLat || -89.99, // Latitude of the southernmost part of the map
    eastLon: settings.eastLon || 179.99, // Longitude of the easternmost part of the map
    westLon: settings.westLong || -179.99, // Logitude of the westernmost part fo the map
    mapWidth: settings.mapWidth || 1.0, // Width of the map, or 1.0 to scale to the range 0..1
    mapHeight: settings.mapHeight || 1.0, // Height of the map, or 1.0 to scale to the range 0..1
  };

  var calc = {
    rNorth: this.deg2rad(this.settings.northLat),
    rSouth: this.deg2rad(this.settings.southLat),
    rEast: this.deg2rad(this.settings.eastLon),
    rWest: this.deg2rad(this.settings.westLon),
  };

  if (this.settings.projection === 'mercator') {
    calc.projectionFn = this.mercatorY.bind(this);
  } else if (this.settings.projection === 'miller') {
    calc.projectionFn = this.millerY.bind(this);
  } else {
    calc.projectionFn = this.cylindricalY.bind(this);
  }

  calc.xFactor = 1.0 / (calc.rEast - calc.rWest);
  calc.yMin = calc.projectionFn(calc.rSouth);
  calc.yMax = calc.projectionFn(calc.rNorth);
  calc.yFactor = 1.0 / (calc.yMax - calc.yMin);

  this.calculations = calc;
};

LatitudeTransformProjection.prototype.deg2rad = function (degrees) {
  return (degrees * Math.PI) / 180.0;
};

LatitudeTransformProjection.prototype.cylindricalY = function (lat) {
  return lat;
};

LatitudeTransformProjection.prototype.mercatorY = function (lat) {
  return Math.log(Math.tan(lat / 2.0 + Math.PI / 4.0));
};

LatitudeTransformProjection.prototype.millerY = function (lat) {
  return this.mercatorY(lat * 0.8);
};

LatitudeTransformProjection.prototype.projectToRatio = function (lat, lon) {
  var calc = this.calculations;
  var rLat = this.deg2rad(lat);
  var rLon = this.deg2rad(lon);
  var px = (rLon - calc.rWest) * calc.xFactor;
  var py = (calc.yMax - calc.projectionFn(rLat)) * calc.yFactor;

  return {
    x: px,
    y: py,
  };
};

LatitudeTransformProjection.prototype.projectToPercent = function (lat, lon) {
  var point = this.projectToRatio(lat, lon);
  point.x = point.x * 100.0;
  point.y = point.y * 100.0;
  return point;
};

LatitudeTransformProjection.prototype.projectToMap = function (lat, lon) {
  var point = this.projectToRatio(lat, lon);
  point.x = point.x * this.settings.mapWidth;
  point.y = point.y * this.settings.mapHeight;
  return point;
};

/**
 * Calculates coordinates from lat/long for a Robinbson Projection world map.
 */
var RobinsonProjection = function (settings) {
  // map width and height are asked for because they are what the
  // earthRadius value relies upon. You can use either, as long as
  // the image is sized such that width = height*1.97165551906973
  // you can use either to do the calculation, but as of now I
  // require both and only use width. both are used in projectToCSS.
  //
  // If mapWidth & mapHeight are omitted, the coordinates are calculated
  // for the range 0..1, suitable for percentage placements.

  this.settings = {
    mapWidth: settings.mapWidth || 1.0, // Width of the map, or 1.0 to scale to the range 0..1
    mapHeight: settings.mapHeight || 1.0, // Height of the map, or 1.0 to scale to the range 0..1
    translateX: settings.translateX || 0.0, // Translation of points in the east-west direction, negative = west
    translateY: settings.translateY || 0.0, // Translation of points in the north-south direction, negative = south
    scaleX: settings.scaleX || 1.0, // Scale the calculated east-west points by this factor (i.e. for an incomplete map)
    scaleY: settings.scaleY || 1.0, // Scale the calculated north-south points by this factor (i.e. for an incomplete map)
  };

  this.earthRadius = this.settings.mapWidth / 2.666269758 / 2;

  // these tables are created by robinson and are what the projection is based upon
  // eslint-disable-next-line max-len
  this.AA = [
    0.8487, 0.84751182, 0.84479598, 0.840213, 0.83359314, 0.8257851, 0.814752, 0.80006949, 0.78216192, 0.76060494,
    0.73658673, 0.7086645, 0.67777182, 0.64475739, 0.60987582, 0.57134484, 0.52729731, 0.48562614, 0.45167814,
  ];
  // eslint-disable-next-line max-len
  this.BB = [
    0, 0.0838426, 0.1676852, 0.2515278, 0.3353704, 0.419213, 0.5030556, 0.5868982, 0.67182264, 0.75336633, 0.83518048,
    0.91537187, 0.99339958, 1.06872269, 1.14066505, 1.20841528, 1.27035062, 1.31998003, 1.3523,
  ];
};

RobinsonProjection.prototype.project = function (lat, lng) {
  // returns the robinson projected point for a given lat/lng based on
  // the earth radius value determined in the contructor

  var roundToNearest = function (roundTo, value) {
    return Math.floor(value / roundTo) * roundTo; //rounds down
  };

  var getSign = function (value) {
    return value < 0 ? -1 : 1;
  };

  var lngSign = getSign(lng),
    latSign = getSign(lat); //deals with negatives
  lng = Math.abs(lng);
  lat = Math.abs(lat); //all calculations positive
  var radian = 0.017453293; //pi/180
  var low = roundToNearest(5, lat - 0.0000000001); //want exact numbers to round down
  low = lat == 0 ? 0 : low; //except when at 0
  var high = low + 5;

  // indicies used for interpolation
  var lowIndex = low / 5;
  var highIndex = high / 5;
  var ratio = (lat - low) / 5;

  // interpolation in one dimension
  var adjAA = (this.AA[highIndex] - this.AA[lowIndex]) * ratio + this.AA[lowIndex];
  var adjBB = (this.BB[highIndex] - this.BB[lowIndex]) * ratio + this.BB[lowIndex];

  //create point from robinson function
  var point = {
    x: adjAA * lng * radian * lngSign * this.earthRadius,
    y: adjBB * latSign * this.earthRadius,
  };

  point.x = point.x * this.settings.scaleX + this.settings.translateX;
  point.y = point.y * this.settings.scaleY + this.settings.translateY;

  return point;
};

RobinsonProjection.prototype.projectToRatio = function (lat, lng) {
  // changes the coordinate system of a projected point to a 0..1 ratio
  var point = this.projectToMap(lat, lng);
  point.x = point.x / this.settings.mapWidth;
  point.y = point.y / this.settings.mapHeight;
  return point;
};

RobinsonProjection.prototype.projectToPercent = function (lat, lon) {
  var point = this.projectToRatio(lat, lon);
  point.x = point.x * 100.0;
  point.y = point.y * 100.0;
  return point;
};

RobinsonProjection.prototype.projectToMap = function (lat, lng) {
  // changes the coordinate system of a projected point to the map dimensions
  var point = this.project(lat, lng);
  point.x = point.x + this.settings.mapWidth / 2;
  point.y = this.settings.mapHeight / 2 - point.y;
  return point;
};

/**
 * Calculates the latitude & longitude for a given country.
 */
function getCountryLatLon(countryCode) {
  return COUNTRY_LAT_LON[countryCode] || null;
}

var COUNTRY_LAT_LON = {
  AD: {lat: 42.546245, lon: 1.601554},
  AE: {lat: 23.424076, lon: 53.847818},
  AF: {lat: 33.93911, lon: 67.709953},
  AG: {lat: 17.060816, lon: -61.796428},
  AI: {lat: 18.220554, lon: -63.068615},
  AL: {lat: 41.153332, lon: 20.168331},
  AM: {lat: 40.069099, lon: 45.038189},
  AN: {lat: 12.226079, lon: -69.060087},
  AO: {lat: -11.202692, lon: 17.873887},
  AQ: {lat: -75.250973, lon: -0.071389},
  AR: {lat: -38.416097, lon: -63.616672},
  AS: {lat: -14.270972, lon: -170.132217},
  AT: {lat: 47.516231, lon: 14.550072},
  AU: {lat: -25.274398, lon: 133.775136},
  AW: {lat: 12.52111, lon: -69.968338},
  AZ: {lat: 40.143105, lon: 47.576927},
  BA: {lat: 43.915886, lon: 17.679076},
  BB: {lat: 13.193887, lon: -59.543198},
  BD: {lat: 23.684994, lon: 90.356331},
  BE: {lat: 50.503887, lon: 4.469936},
  BF: {lat: 12.238333, lon: -1.561593},
  BG: {lat: 42.733883, lon: 25.48583},
  BH: {lat: 25.930414, lon: 50.637772},
  BI: {lat: -3.373056, lon: 29.918886},
  BJ: {lat: 9.30769, lon: 2.315834},
  BM: {lat: 32.321384, lon: -64.75737},
  BN: {lat: 4.535277, lon: 114.727669},
  BO: {lat: -16.290154, lon: -63.588653},
  BR: {lat: -14.235004, lon: -51.92528},
  BS: {lat: 25.03428, lon: -77.39628},
  BT: {lat: 27.514162, lon: 90.433601},
  BV: {lat: -54.423199, lon: 3.413194},
  BW: {lat: -22.328474, lon: 24.684866},
  BY: {lat: 53.709807, lon: 27.953389},
  BZ: {lat: 17.189877, lon: -88.49765},
  CA: {lat: 56.130366, lon: -106.346771},
  CC: {lat: -12.164165, lon: 96.870956},
  CD: {lat: -4.038333, lon: 21.758664},
  CF: {lat: 6.611111, lon: 20.939444},
  CG: {lat: -0.228021, lon: 15.827659},
  CH: {lat: 46.818188, lon: 8.227512},
  CI: {lat: 7.539989, lon: -5.54708},
  CK: {lat: -21.236736, lon: -159.777671},
  CL: {lat: -35.675147, lon: -71.542969},
  CM: {lat: 7.369722, lon: 12.354722},
  CN: {lat: 35.86166, lon: 104.195397},
  CO: {lat: 4.570868, lon: -74.297333},
  CR: {lat: 9.748917, lon: -83.753428},
  CU: {lat: 21.521757, lon: -77.781167},
  CV: {lat: 16.002082, lon: -24.013197},
  CX: {lat: -10.447525, lon: 105.690449},
  CY: {lat: 35.126413, lon: 33.429859},
  CZ: {lat: 49.817492, lon: 15.472962},
  DE: {lat: 51.165691, lon: 10.451526},
  DJ: {lat: 11.825138, lon: 42.590275},
  DK: {lat: 56.26392, lon: 9.501785},
  DM: {lat: 15.414999, lon: -61.370976},
  DO: {lat: 18.735693, lon: -70.162651},
  DZ: {lat: 28.033886, lon: 1.659626},
  EC: {lat: -1.831239, lon: -78.183406},
  EE: {lat: 58.595272, lon: 25.013607},
  EG: {lat: 26.820553, lon: 30.802498},
  EH: {lat: 24.215527, lon: -12.885834},
  ER: {lat: 15.179384, lon: 39.782334},
  ES: {lat: 40.463667, lon: -3.74922},
  ET: {lat: 9.145, lon: 40.489673},
  FI: {lat: 61.92411, lon: 25.748151},
  FJ: {lat: -16.578193, lon: 179.414413},
  FK: {lat: -51.796253, lon: -59.523613},
  FM: {lat: 7.425554, lon: 150.550812},
  FO: {lat: 61.892635, lon: -6.911806},
  FR: {lat: 46.227638, lon: 2.213749},
  GA: {lat: -0.803689, lon: 11.609444},
  GB: {lat: 55.378051, lon: -3.435973},
  GD: {lat: 12.262776, lon: -61.604171},
  GE: {lat: 42.315407, lon: 43.356892},
  GF: {lat: 3.933889, lon: -53.125782},
  GG: {lat: 49.465691, lon: -2.585278},
  GH: {lat: 7.946527, lon: -1.023194},
  GI: {lat: 36.137741, lon: -5.345374},
  GL: {lat: 71.706936, lon: -42.604303},
  GM: {lat: 13.443182, lon: -15.310139},
  GN: {lat: 9.945587, lon: -9.696645},
  GP: {lat: 16.995971, lon: -62.067641},
  GQ: {lat: 1.650801, lon: 10.267895},
  GR: {lat: 39.074208, lon: 21.824312},
  GS: {lat: -54.429579, lon: -36.587909},
  GT: {lat: 15.783471, lon: -90.230759},
  GU: {lat: 13.444304, lon: 144.793731},
  GW: {lat: 11.803749, lon: -15.180413},
  GY: {lat: 4.860416, lon: -58.93018},
  GZ: {lat: 31.354676, lon: 34.308825},
  HK: {lat: 22.396428, lon: 114.109497},
  HM: {lat: -53.08181, lon: 73.504158},
  HN: {lat: 15.199999, lon: -86.241905},
  HR: {lat: 45.1, lon: 15.2},
  HT: {lat: 18.971187, lon: -72.285215},
  HU: {lat: 47.162494, lon: 19.503304},
  ID: {lat: -0.789275, lon: 113.921327},
  IE: {lat: 53.41291, lon: -8.24389},
  IL: {lat: 31.046051, lon: 34.851612},
  IM: {lat: 54.236107, lon: -4.548056},
  IN: {lat: 20.593684, lon: 78.96288},
  IO: {lat: -6.343194, lon: 71.876519},
  IQ: {lat: 33.223191, lon: 43.679291},
  IR: {lat: 32.427908, lon: 53.688046},
  IS: {lat: 64.963051, lon: -19.020835},
  IT: {lat: 41.87194, lon: 12.56738},
  JE: {lat: 49.214439, lon: -2.13125},
  JM: {lat: 18.109581, lon: -77.297508},
  JO: {lat: 30.585164, lon: 36.238414},
  JP: {lat: 36.204824, lon: 138.252924},
  KE: {lat: -0.023559, lon: 37.906193},
  KG: {lat: 41.20438, lon: 74.766098},
  KH: {lat: 12.565679, lon: 104.990963},
  KI: {lat: -3.370417, lon: -168.734039},
  KM: {lat: -11.875001, lon: 43.872219},
  KN: {lat: 17.357822, lon: -62.782998},
  KP: {lat: 40.339852, lon: 127.510093},
  KR: {lat: 35.907757, lon: 127.766922},
  KW: {lat: 29.31166, lon: 47.481766},
  KY: {lat: 19.513469, lon: -80.566956},
  KZ: {lat: 48.019573, lon: 66.923684},
  LA: {lat: 19.85627, lon: 102.495496},
  LB: {lat: 33.854721, lon: 35.862285},
  LC: {lat: 13.909444, lon: -60.978893},
  LI: {lat: 47.166, lon: 9.555373},
  LK: {lat: 7.873054, lon: 80.771797},
  LR: {lat: 6.428055, lon: -9.429499},
  LS: {lat: -29.609988, lon: 28.233608},
  LT: {lat: 55.169438, lon: 23.881275},
  LU: {lat: 49.815273, lon: 6.129583},
  LV: {lat: 56.879635, lon: 24.603189},
  LY: {lat: 26.3351, lon: 17.228331},
  MA: {lat: 31.791702, lon: -7.09262},
  MC: {lat: 43.750298, lon: 7.412841},
  MD: {lat: 47.411631, lon: 28.369885},
  ME: {lat: 42.708678, lon: 19.37439},
  MG: {lat: -18.766947, lon: 46.869107},
  MH: {lat: 7.131474, lon: 171.184478},
  MK: {lat: 41.608635, lon: 21.745275},
  ML: {lat: 17.570692, lon: -3.996166},
  MM: {lat: 21.913965, lon: 95.956223},
  MN: {lat: 46.862496, lon: 103.846656},
  MO: {lat: 22.198745, lon: 113.543873},
  MP: {lat: 17.33083, lon: 145.38469},
  MQ: {lat: 14.641528, lon: -61.024174},
  MR: {lat: 21.00789, lon: -10.940835},
  MS: {lat: 16.742498, lon: -62.187366},
  MT: {lat: 35.937496, lon: 14.375416},
  MU: {lat: -20.348404, lon: 57.552152},
  MV: {lat: 3.202778, lon: 73.22068},
  MW: {lat: -13.254308, lon: 34.301525},
  MX: {lat: 23.634501, lon: -102.552784},
  MY: {lat: 4.210484, lon: 101.975766},
  MZ: {lat: -18.665695, lon: 35.529562},
  NA: {lat: -22.95764, lon: 18.49041},
  NC: {lat: -20.904305, lon: 165.618042},
  NE: {lat: 17.607789, lon: 8.081666},
  NF: {lat: -29.040835, lon: 167.954712},
  NG: {lat: 9.081999, lon: 8.675277},
  NI: {lat: 12.865416, lon: -85.207229},
  NL: {lat: 52.132633, lon: 5.291266},
  NO: {lat: 60.472024, lon: 8.468946},
  NP: {lat: 28.394857, lon: 84.124008},
  NR: {lat: -0.522778, lon: 166.931503},
  NU: {lat: -19.054445, lon: -169.867233},
  NZ: {lat: -40.900557, lon: 174.885971},
  OM: {lat: 21.512583, lon: 55.923255},
  PA: {lat: 8.537981, lon: -80.782127},
  PE: {lat: -9.189967, lon: -75.015152},
  PF: {lat: -17.679742, lon: -149.406843},
  PG: {lat: -6.314993, lon: 143.95555},
  PH: {lat: 12.879721, lon: 121.774017},
  PK: {lat: 30.375321, lon: 69.345116},
  PL: {lat: 51.919438, lon: 19.145136},
  PM: {lat: 46.941936, lon: -56.27111},
  PN: {lat: -24.703615, lon: -127.439308},
  PR: {lat: 18.220833, lon: -66.590149},
  PS: {lat: 31.952162, lon: 35.233154},
  PT: {lat: 39.399872, lon: -8.224454},
  PW: {lat: 7.51498, lon: 134.58252},
  PY: {lat: -23.442503, lon: -58.443832},
  QA: {lat: 25.354826, lon: 51.183884},
  RE: {lat: -21.115141, lon: 55.536384},
  RO: {lat: 45.943161, lon: 24.96676},
  RS: {lat: 44.016521, lon: 21.005859},
  RU: {lat: 61.52401, lon: 105.318756},
  RW: {lat: -1.940278, lon: 29.873888},
  SA: {lat: 23.885942, lon: 45.079162},
  SB: {lat: -9.64571, lon: 160.156194},
  SC: {lat: -4.679574, lon: 55.491977},
  SD: {lat: 12.862807, lon: 30.217636},
  SE: {lat: 60.128161, lon: 18.643501},
  SG: {lat: 1.352083, lon: 103.819836},
  SH: {lat: -24.143474, lon: -10.030696},
  SI: {lat: 46.151241, lon: 14.995463},
  SJ: {lat: 77.553604, lon: 23.670272},
  SK: {lat: 48.669026, lon: 19.699024},
  SL: {lat: 8.460555, lon: -11.779889},
  SM: {lat: 43.94236, lon: 12.457777},
  SN: {lat: 14.497401, lon: -14.452362},
  SO: {lat: 5.152149, lon: 46.199616},
  SR: {lat: 3.919305, lon: -56.027783},
  ST: {lat: 0.18636, lon: 6.613081},
  SV: {lat: 13.794185, lon: -88.89653},
  SY: {lat: 34.802075, lon: 38.996815},
  SZ: {lat: -26.522503, lon: 31.465866},
  TC: {lat: 21.694025, lon: -71.797928},
  TD: {lat: 15.454166, lon: 18.732207},
  TF: {lat: -49.280366, lon: 69.348557},
  TG: {lat: 8.619543, lon: 0.824782},
  TH: {lat: 15.870032, lon: 100.992541},
  TJ: {lat: 38.861034, lon: 71.276093},
  TK: {lat: -8.967363, lon: -171.855881},
  TL: {lat: -8.874217, lon: 125.727539},
  TM: {lat: 38.969719, lon: 59.556278},
  TN: {lat: 33.886917, lon: 9.537499},
  TO: {lat: -21.178986, lon: -175.198242},
  TR: {lat: 38.963745, lon: 35.243322},
  TT: {lat: 10.691803, lon: -61.222503},
  TV: {lat: -7.109535, lon: 177.64933},
  TW: {lat: 23.69781, lon: 120.960515},
  TZ: {lat: -6.369028, lon: 34.888822},
  UA: {lat: 48.379433, lon: 31.16558},
  UG: {lat: 1.373333, lon: 32.290275},
  US: {lat: 37.09024, lon: -95.712891},
  UY: {lat: -32.522779, lon: -55.765835},
  UZ: {lat: 41.377491, lon: 64.585262},
  VA: {lat: 41.902916, lon: 12.453389},
  VC: {lat: 12.984305, lon: -61.287228},
  VE: {lat: 6.42375, lon: -66.58973},
  VG: {lat: 18.420695, lon: -64.639968},
  VI: {lat: 18.335765, lon: -64.896335},
  VN: {lat: 14.058324, lon: 108.277199},
  VU: {lat: -15.376706, lon: 166.959158},
  WF: {lat: -13.768752, lon: -177.156097},
  WS: {lat: -13.759029, lon: -172.104629},
  XK: {lat: 42.602636, lon: 20.902977},
  YE: {lat: 15.552727, lon: 48.516388},
  YT: {lat: -12.8275, lon: 45.166244},
  ZA: {lat: -30.559482, lon: 22.937506},
  ZM: {lat: -13.133897, lon: 27.849332},
  ZW: {lat: -19.015438, lon: 29.154857},
};

//****************************
//* EXPORTS
//****************************

export default {
  LatitudeTransformProjection: LatitudeTransformProjection,
  RobinsonProjection: RobinsonProjection,
  getCountryLatLon: getCountryLatLon,
};
