'use strict';

import React from 'react';
import Utils from '../jskit/general/Utils';
import ReactUtils from '../jskit/react/ReactUtils';
import Formatter from '../jskit/general/Formatter';
import ZendeskInfoIcon from './ZendeskInfoIcon.jsx';

export default class CheckItem extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  handleAddClick(checkType, checkName) {
    if (!this.props.withAddNew) {
      return;
    }

    if (this.props.onAdd) {
      this.props.onAdd(checkType, checkName);
    }
  }

  render() {
    const {slug, name, count, helpText, supportURL} = this.props;
    const cssClass = ReactUtils.cssClass('check-item d-flex pb-2 pt-2');
    return (
      <div className={cssClass} key={slug}>
        <div className="check-name-container" onClick={this.handleAddClick.bind(null, slug, name)}>
          {this.props.withAddNew ? (
            <a className="ml-1 d-inline-block">
              <i className="fal fa-plus"></i>
            </a>
          ) : null}
          <a className="check-name" data-tip={helpText}>
            {name}
          </a>
        </div>
        <span style={{color: '#71777A', opacity: 0.3}}>&nbsp;&bull;&nbsp;</span>
        <span className="check-count">{Formatter.humanFriendlyInt(count)}</span>
        <span className="check-info">
          <span style={{color: '#71777A', opacity: 0.3}}>&nbsp;&bull;&nbsp;</span>
          <ZendeskInfoIcon supportURL={supportURL} />
        </span>
      </div>
    );
  }
}
