import React, {PropsWithChildren} from 'react';
import styles from './EmptySection.module.css';
import ReactUtils from '@/jskit/react/ReactUtils';

export interface EmptySectionProps extends PropsWithChildren {
  icon: React.ReactNode;
}

export const EmptySection = ({children, icon}: EmptySectionProps) => {
  return (
    <div className="w-100 text-center">
      <div className={styles.EmptySectionIcon}>{icon}</div>
      <p className={ReactUtils.cssClass('mt-3 mb-0', styles.EmptySectionMessage)}>{children}</p>
    </div>
  );
};
