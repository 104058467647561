import React from 'react';
import moment from 'moment-timezone';
import {
  unpackFormLinkOrProps,
  CustomEvent,
  Label,
  FieldErrors,
  FieldHelpText,
} from '../../jskit/react/forms/FormHelpers';
import DatePicker from 'react-datepicker';
import Formatter from '../../jskit/general/Formatter';
import ReactUtils from '../../jskit/react/ReactUtils';
import {FormLink} from '../../jskit/react/forms/FormLinkType';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

interface IDatePickerProps {
  fieldName: string;
  onChange?: (value: string) => void;
  formLink?: FormLink;
  timezone: string;
  showTimezoneInDate?: boolean;
  value?: string;
  className?: string;
  isRequired?: boolean;
  labelText?: string;
  helpText?: string;
  placeholder?: string;
  defaultNow?: boolean;
}

export default ({
  fieldName,
  onChange,
  timezone,
  showTimezoneInDate = false,
  formLink,
  value,
  className,
  isRequired,
  labelText,
  helpText,
  placeholder,
}: IDatePickerProps) => {
  if (!(onChange || formLink)) console.log('Either a formLink or onChange prop is required');

  const datepickerRef = React.useRef(null);
  const form = unpackFormLinkOrProps({
    fieldName,
    formLink,
  });

  const handleChange = (selected) => {
    if (selected === null) selected = '';
    else selected = removeOffsetForDatePicker(selected);

    if (onChange) {
      onChange(selected);
    } else {
      const event = new CustomEvent({
        type: 'change',
        target: {value: selected},
        value: selected,
      });
      form.onChange(event);
    }
  };

  const openDatepicker = () => {
    if (!datepickerRef.current.state.open) datepickerRef.current.setOpen(true);
  };

  const localToAccountTZDifference = () => {
    const now = moment();
    const localOffset = now.utcOffset();
    now.tz(timezone); // your time zone, not necessarily the server's
    const centralOffset = now.utcOffset();
    return localOffset - centralOffset;
  };

  const applyOffsetForDatePicker = (value) => {
    const momentValue = moment.utc(value);
    const offsetValue = momentValue.subtract(localToAccountTZDifference(), 'minutes');
    return offsetValue.toDate();
  };

  const removeOffsetForDatePicker = (value) => {
    const momentValue = moment.utc(value);
    const offsetValue = momentValue.add(localToAccountTZDifference(), 'minutes');
    return offsetValue.toDate();
  };

  const passedValue = value || form.value;
  const initialValue = passedValue ? applyOffsetForDatePicker(passedValue) : passedValue;

  const getDateTimeFormat = () => {
    let format = Formatter.getCurrentDateTimeFormattedString();
    // https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
    format = format.replaceAll('YYYY', 'yyyy');
    format = format.replaceAll('DD', 'dd');
    if (!showTimezoneInDate) {
      format = format.replaceAll('z', '').trim();
    }
    return format;
  };

  const timeFormat = window.CURRENT_TIME_FORMAT === 'TWENTY_FOUR_HOUR' ? 'HH:mm' : 'h:mm aa';

  return (
    <div className={className}>
      <Label labelText={labelText} />
      <div className="input-group date" data-target-input="nearest">
        <DatePicker
          fieldName={fieldName}
          ref={datepickerRef}
          className={ReactUtils.cssClass('form-control', {['is-invalid']: form.errors})}
          //
          selected={initialValue}
          required={isRequired}
          placeholderText={placeholder}
          //
          showYearDropdown
          showMonthDropdown
          dateFormat={getDateTimeFormat()}
          //
          showTimeSelect
          timeIntervals={15}
          timeFormat={timeFormat}
          //
          onChange={handleChange}
        />
        <div className="input-group-append" onClick={openDatepicker}>
          <div className="input-group-text" data-toggle="datetimepicker">
            <i className="far fa-calendar-alt" />
          </div>
        </div>
      </div>
      <FieldErrors errors={form.errors} />
      <FieldHelpText helpText={helpText} />
    </div>
  );
};
