'use strict';

import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import Utils from '../../jskit/general/Utils';
import * as ManageUtils from './ManageUtils.jsx';

export default class Component extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      isOpen: !!this.props.component.just_added,
      isDragging: false,
    };
  }

  handleComponentOpenClose(e) {
    e.preventDefault();
    this.setState({isOpen: !this.state.isOpen});
  }

  handleDragHandleMouseDown(e) {
    // Enable dragging only from the handle
    this.setState({isDragging: true});
  }

  handleDragHandleMouseUp(e) {
    // Disable dragging again
    this.setState({isDragging: false});
  }

  handleDragStart(e) {
    // Save this component ID as the component being moved
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('srcEl', JSON.stringify(this.props.component));
  }

  handleDragOver(e) {
    // Prevent default to allow dropping
    e.preventDefault();
  }

  handleDragEnd(e) {
    // Disable dragging again
    this.setState({isDragging: false});
  }

  handleDrop(e) {
    e.preventDefault();
    // Trigger event handler with both source being dragged and drop target
    const srcEl = JSON.parse(e.dataTransfer.getData('srcEl'));
    const dstEl = this.props.component;
    this.props.onDrag(srcEl, dstEl, this.state.isOpen);
  }

  render() {
    const component = this.props.component;
    let subcomponentGroup = null;

    if (component.is_group) {
      const subcomponents = component.subcomponents
        .sort(ManageUtils.cmpComponentsByWeight)
        .map((subcomponent, j) => (
          <Component
            key={subcomponent.id}
            component={subcomponent}
            componentStatusChoices={this.props.componentStatusChoices}
            componentStatusRank={this.props.componentStatusRank}
            showResponseTime={this.props.showResponseTime}
            onStatusChange={this.props.onStatusChange}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            onDrag={this.props.onDrag}
          />
        ));

      subcomponentGroup = (
        <div key={component.id + '-sub'} className="sub-component-list">
          {subcomponents}
        </div>
      );
    }

    return (
      <React.Fragment>
        <div
          className={ReactUtils.cssClass('component', {
            ['component-group']: component.is_group,
            open: this.state.isOpen,
          })}
          draggable={this.state.isDragging}
          onDragStart={this.handleDragStart}
          onDragOver={this.handleDragOver}
          onDragEnd={this.handleDragEnd}
          onDrop={this.handleDrop}
        >
          {this.props.showHistory ? null : (
            <div
              className={ReactUtils.cssClass(
                'indicator',
                ManageUtils.getComponentStatus(component, this.props.componentStatusRank)
              )}
            />
          )}
          {component.is_group ? <div className="group-expander" onClick={this.handleComponentOpenClose} /> : null}
          <div className="row align-items-center">
            <div
              className={this.props.showResponseTime ? 'col-sm-5' : 'col-sm-7'}
              onClick={component.is_group ? this.handleComponentOpenClose : null}
            >
              {this.renderName(component)}
            </div>
            {this.props.showResponseTime ? <div className="col-sm-2">{this.renderResponseTime(component)}</div> : null}
            <div className="col-sm-3">
              {component.is_group ? this.renderStatusReadOnly(component) : this.renderStatusDropdown(component)}
            </div>
            <div className="col-sm-2 text-right text-muted">
              <div className="dropdown d-inline-block mr-3">
                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="unstyled">
                  <i className="fas fa-fw fa-ellipsis-v" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={this.props.onEdit.bind(null, component)}>
                    Edit
                  </a>
                  <a className="dropdown-item" href="#" onClick={this.props.onDelete.bind(null, component)}>
                    Delete
                  </a>
                </div>
              </div>
              <i
                onMouseDown={this.handleDragHandleMouseDown}
                onClick={this.handleDragHandleClick}
                onMouseUp={this.handleDragHandleMouseUp}
                className="fas fa-grip-lines drag-handle"
              />
            </div>
          </div>
        </div>
        {subcomponentGroup}
      </React.Fragment>
    );
  }

  renderName(component) {
    return (
      <div className="component-name">
        <span>{component.name}</span>
        {component.description ? <small>{component.description}</small> : null}
      </div>
    );
  }

  renderResponseTime(component) {
    if (component.is_group || !this.props.showResponseTime || !component.service) {
      return null;
    }

    // disable graph while dragging; ResponseTimeChart behaves really strange when being dragged
    if (this.state.isDragging) {
      return null;
    }

    if (!component.service) {
      return null;
    }

    return (
      <i
        className="fas fa-chart-bar fa-lg tooltip-cursor"
        data-toggle="tooltip"
        title="Response time metrics will be displayed for this component."
      />
    );
  }

  renderStatusReadOnly(component) {
    return (
      <span
        className={ReactUtils.cssClass(
          'status-icon',
          ManageUtils.getComponentStatus(component, this.props.componentStatusRank)
        )}
      >
        {ManageUtils.getComponentStatusDisplay(
          component,
          this.props.componentStatusChoices,
          this.props.componentStatusRank
        )}
      </span>
    );
  }

  renderStatusDropdown(component) {
    const choices = this.props.componentStatusChoices.map((status, i) => {
      return (
        <a
          key={i}
          href="#"
          className={ReactUtils.cssClass('dropdown-item status-icon', status[0])}
          onClick={this.props.onStatusChange.bind(null, component, status[0])}
        >
          {status[1]}
        </a>
      );
    });

    return (
      <div className="dropdown status-select-box">
        <a
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          className={ReactUtils.cssClass('status-icon', component.status)}
        >
          {component.status_display}
        </a>
        <div className="dropdown-menu">{choices}</div>
      </div>
    );
  }
}

Component.defaultProps = {
  component: null,
  componentStatusChoices: null,
  componentStatusRank: null,
  showResponseTime: null,
  onStatusChange: null,
  onEdit: null,
  onDelete: null,
  onDrag: null,
};
