'use strict';

import React from 'react';
import _ from 'underscore';
import moment from 'moment-timezone';
import Formatter from '../../jskit/general/Formatter';
import Utils from '../../jskit/general/Utils';
import * as ManageUtils from '../manage/ManageUtils.jsx';
import {CalculationType} from './Enums.jsx';

export default class DateRangeSnake extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      snakePopupDate: null,
    };
  }

  handleDateRangeSnakeHover(date) {
    this.setState({snakePopupDate: date});
  }

  handleDateRangeSnakeBlur() {
    this.setState({snakePopupDate: null});
  }

  calculateIncidentsPerDay(statuspage, toDate) {
    const incidentsPerDay = {};
    // past_incidents and active_incidents may have intersection, so deduplication is required
    const pastIncidents = _.uniq(statuspage.past_incidents.concat(statuspage.active_incidents), false, (i) => i.id);
    pastIncidents.forEach((incident) => {
      let incidentDate = moment(incident.starts_at).tz(window.TIMEZONE).startOf('day');
      const incidentEnd = incident.ends_at ? moment(incident.ends_at).tz(window.TIMEZONE).endOf('day') : toDate;
      while (incidentDate < incidentEnd) {
        if (!incidentsPerDay[incidentDate]) {
          incidentsPerDay[incidentDate] = [];
        }
        if (incident.status !== 'notification' && incident.status !== 'under-maintenance') {
          incidentsPerDay[incidentDate].push(incident);
        }
        incidentDate = incidentDate.add(1, 'days');
      }
    });

    return incidentsPerDay;
  }

  getHighestSeverityIncidents(incidentList) {
    if (!incidentList) {
      return null;
    }

    const keyFn = (x) => this.props.componentStatusRank[x.status] + '|' + x.starts_at;
    return incidentList.concat().sort((a, b) => (keyFn(a) < keyFn(b) ? -1 : 1));
  }

  getComponentAndSeverityList(incidentList) {
    if (!incidentList) {
      return null;
    }

    if (incidentList.length === 1) {
      return incidentList[0].affected_components;
    }

    const components = {};
    incidentList.forEach((incident) => {
      incident.affected_components.forEach((component) => {
        if (
          !components[component.name] ||
          this.props.componentStatusRank[component.status] < components[component.name].status
        ) {
          components[component.name] = component;
        }
      });
    });

    const keyFn = (x) => this.props.componentStatusRank[x.status] + '|' + x.name;
    return Object.values(components).sort((a, b) => (keyFn(a) < keyFn(b) ? -1 : 1));
  }

  render() {
    const sp = this.props.statuspage;
    if (!sp.show_history_snake || !this.props.historyStartDate || !this.props.historyEndDate) {
      return null;
    }

    const incidentsPerDay = this.calculateIncidentsPerDay(this.props.statuspage, this.props.historyEndDate);
    const daysInRange = Math.round(
      moment
        .duration(this.props.historyEndDate.tz(window.TIMEZONE).endOf('day').diff(this.props.historyStartDate))
        .asDays()
    );
    if (daysInRange < 7 || daysInRange > 90) {
      return null;
    }

    const dates = _.range(daysInRange).map((i) => {
      return moment(this.props.historyStartDate).tz(window.TIMEZONE).add(i, 'days');
    });

    const fillPct = 90.0 - ((daysInRange - 7) / 83.0) * 30.0;
    const width = fillPct / daysInRange + '%';

    const elements = dates.map((d, i) => {
      const incidentList = incidentsPerDay[d] || [];
      const dateHistory = this.props.statuspage.date_history[d.format('YYYY-MM-DD')];

      if (!dateHistory) {
        return null;
      }

      const totalDuration = dateHistory.downtime_secs;
      const metricValue = Formatter.shortDuration(totalDuration);
      const upOnDate = totalDuration === 0;
      let componentList;

      let metricName;
      if (sp.uptime_calculation_type === CalculationType.BY_CHECKS) {
        metricName = 'Downtime Duration';
        componentList = dateHistory.affected_components.map(
          (cmpId) => ManageUtils.findComponentIndex(sp.components, cmpId, false).component
        );
      } else {
        metricName = 'Incident Duration';
        componentList = this.getComponentAndSeverityList(incidentList);
      }

      const priorityIncidents = this.getHighestSeverityIncidents(incidentList);
      const statusCls = upOnDate ? 'up' : 'major-outage';

      const renderedIncidents = !priorityIncidents
        ? null
        : priorityIncidents.map((incident, i) => {
            const lim = 3;
            if (i < lim) {
              return <li key={i}>{incident.name}</li>;
            } else if (i === lim) {
              return (
                <li key={i} className="text-muted">
                  + {priorityIncidents.length - lim} more
                </li>
              );
            }
          });

      const renderedComponents = !componentList
        ? null
        : componentList.map((component, i) => {
            const lim = 3;
            if (i < lim) {
              return (
                <li key={i} title={component.name + ' - ' + component.status_display}>
                  <span className={'status-icon ' + component.status}>{component.name}</span>
                </li>
              );
            } else if (i === lim) {
              return (
                <li key={i} className="text-muted">
                  + {componentList.length - lim} more
                </li>
              );
            }
          });

      if (this.state.snakePopupDate && this.state.snakePopupDate.isSame(d)) {
        const positionCls = i / dates.length > 0.5 ? 'right' : 'left';
        return (
          <div
            key={i}
            className={'position-relative day ' + statusCls}
            style={{width: width}}
            onClick={this.props.onDateRangeSnakeClick.bind(null, d)}
            onMouseLeave={this.handleDateRangeSnakeBlur}
          >
            <div className={'snake-popup ' + positionCls}>
              <div className="caret"></div>
              <div className="content">
                {upOnDate ? (
                  <div className="white-block white-block-border p-3">
                    <div className="text-muted float-right">{Formatter.shortDate(d.toISOString())}</div>
                    <div className="font-24 up">100%</div>
                    <div>Uptime</div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="white-block white-block-border white-block-divider p-3">
                      <div className="text-muted float-right">{Formatter.shortDate(d.toISOString())}</div>
                      <div className="font-24 down">{metricValue}</div>
                      <div>{metricName}</div>
                    </div>
                    {renderedComponents && renderedComponents.length ? (
                      <div className="white-block white-block-border white-block-divider p-3">
                        <div className="font-weight-semibold mb-2">Affected Components</div>
                        <ul className="list-unstyled list-spacer-1 mb-0">{renderedComponents}</ul>
                      </div>
                    ) : null}
                    {renderedIncidents && renderedIncidents.length ? (
                      <div className="white-block white-block-border p-3">
                        <div className="font-weight-semibold mb-1">Incidents</div>
                        <ul className="list-unstyled list-spacer-1 mb-0">{renderedIncidents}</ul>
                      </div>
                    ) : null}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <a
            key={i}
            href="#"
            className={'day ' + statusCls}
            style={{width: width}}
            onClick={this.props.onDateRangeSnakeClick.bind(null, d)}
            onMouseEnter={this.handleDateRangeSnakeHover.bind(null, d)}
          />
        );
      }
    });

    return (
      <div className="d-none d-lg-block mb-4">
        <div className="history-date-range-snake mb-2">{elements}</div>
        <div className="d-flex justify-content-between align-items-center font-13 text-muted">
          <div>{this.props.historyStartDate.tz(window.TIMEZONE).format('D MMM')}</div>
          <div>{this.props.historyEndDate.tz(window.TIMEZONE).format('D MMM')}</div>
        </div>
      </div>
    );
  }
}

DateRangeSnake.defaultProps = {
  statuspage: null,
  componentStatusRank: null,
  historyStartDate: null,
  historyEndDate: null,
  onDateRangeSnakeClick: null,
};
