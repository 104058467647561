import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';

export default class BulkVersionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        msp_version: '',
      },
    };
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal() {
    this.refs.modal_contact.showModal();
    this.setState({formData: {msp_version: ''}});
  }

  hideModal() {
    this.refs.modal_contact.hideModal();
  }

  render() {
    if (!this.props.choices.service_defs) {
      return null;
    }

    // extract version field def from HTTP form definition
    const httpFormChoices = this.props.choices.service_defs.HTTP;
    const mspVersionDef = _.findWhere(httpFormChoices.fields, {field: 'msp_version'});
    if (!mspVersionDef) {
      return null;
    }
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    return (
      <Modal ref="modal_contact" title="Upgrade checker version" cancelButton={true} saveButton={this.props.onSave}>
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>
              Note: The current checker version on selected checks will be replaced with the new setting chosen below.
            </em>
          </p>
          <TextInput
            fieldName="msp_version"
            formLink={formLink}
            labelText={mspVersionDef.verbose_name}
            titleText={mspVersionDef.title}
            helpText="Specify a numeric version such as 1 or 2, or leave blank to upgrade to the latest version."
            placeholder="latest version"
          />
        </form>
      </Modal>
    );
  }
}
