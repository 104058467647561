'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import Select2 from '../../jskit/react/forms/Select2';

export default class URLPatternsForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {},
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialFormData) {
    if (initialFormData) {
      this.setState({formData: initialFormData});
    }

    this.refs.modal.showModal(this.refs.pattern.focus);
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this.getFormData();

    let groupChoices = [['', '--- None ---']].concat(this.props.groupChoices);
    if (groupChoices.length <= 1) {
      groupChoices = null;
    }

    return (
      <Modal
        ref="modal"
        title={formData.id ? 'Edit URL Prefix' : 'Add URL Prefix'}
        size="lg"
        saveButton={this.props.onSave}
        cancelButton={true}
      >
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <input type="hidden" name="id" value={formData.id || ''} />
          <FormErrors errors={this.props.errors.__all__} />
          <TextInput
            ref="pattern"
            fieldName="pattern"
            labelText="URL Prefix"
            isRequired={true}
            helpText="Track all URLs that start with or match the above."
            formLink={formLink}
          />
          {groupChoices ? (
            <Select2
              fieldName="group_id"
              labelText="Group"
              choices={groupChoices}
              isRequired={false}
              helpText="URLs matching above prefix should be reported on within this group."
              formLink={formLink}
            />
          ) : null}
        </form>
      </Modal>
    );
  }
}
