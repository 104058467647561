'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Modal from '../../jskit/react/Modal';
import ReactUtils from '../../jskit/react/ReactUtils';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import CheckBox from '../../jskit/react/forms/CheckBox';
import Select2 from '../../jskit/react/forms/Select2';
import SelectBox from '../../jskit/react/forms/SelectBox';
import {ToggleSwitchPanel} from '../../jskit/react/forms/ToggleSwitch';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';
import {ServiceSelectorDialog, ServiceSelectorInput} from '../../devices/common/DevicesCommon';
import SLAReportReportingGroups from './SLAReportReportingGroups';

export default class SLAReporForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {
        services_selected: [],
        group_services_selected: [],
        new_groups: [],
        delete_groups: [],
      },
      checksSelectorIsActive: false,
      reportingGroupActive: false,
      modalShown: false,
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialFormData) {
    let formData = {
      services_selected: [],
      group_services_selected: [],
      new_groups: [],
      delete_groups: [],
    };

    if (initialFormData) {
      formData = Object.assign(formData, initialFormData);
    }

    this.setState({
      formData: formData,
      checksSelectorIsActive: false,
      reportingGroupActive: false,
      modalShown: true,
    });
    this.refs.modal.showModal();
  }

  hideModal() {
    this.setState({
      formData: this.state.formData,
      checksSelectorIsActive: false,
      reportingGroupActive: false,
      modalShown: false,
    });
    this.refs.modal.hideModal();
  }

  handleGroupAdd(group) {
    const formData = this.state.formData;
    formData.new_groups = [...this.state.formData.new_groups, group];
    this.setState({formData: formData});
  }

  handleGroupRemove(group) {
    const formData = this.state.formData;
    if (group.id) {
      formData.delete_groups = [...formData.delete_groups, group];
    } else {
      formData.new_groups = formData.new_groups.filter((g) => g.name !== group.name);
    }
    this.setState({formData: formData});
  }

  handleChecksSelectionShowDialogGroup(e) {
    e.preventDefault();
    this.setState({reportingGroupActive: true});
  }

  handleChecksSelectionShowDialog(e) {
    e.preventDefault();
    this.setState({checksSelectorIsActive: true});
  }

  handleChecksSelectionDialogDismissed(e) {
    e.preventDefault();
    if (this.state.checksSelectorIsActive) {
      this.setState({checksSelectorIsActive: false});
    } else {
      this.setState({reportingGroupActive: false});
    }
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this.getFormData();
    const title = formData.id ? 'Edit SLA Report' : 'New SLA Report';
    return (
      <Modal
        ref="modal"
        title={title}
        size="lg"
        saveButton={this.props.onSave}
        cancelButton={this.props.onCancel}
        subModalActive={this.state.checksSelectorIsActive || this.state.reportingGroupActive ? 'Select Checks' : null}
        onSubModalDismissed={this.handleChecksSelectionDialogDismissed}
      >
        {this.state.modalShown && (
          <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
            <input type="hidden" name="id" value={formData.id || ''} />
            {this.state.checksSelectorIsActive || this.state.reportingGroupActive
              ? this.renderChecksSelectorSubModal(formLink, formData, this.state.reportingGroupActive)
              : this.renderForm(formLink, formData)}
          </form>
        )}
      </Modal>
    );
  }

  renderChecksSelectorSubModal(formLink, formData, reportingGroupActive = false) {
    const field_name = reportingGroupActive ? 'group_services_selected' : 'services_selected';
    const maxServices = reportingGroupActive ? 1000 : 200;
    return (
      <ServiceSelectorDialog
        servicesCheckListURL={this.props.servicesCheckListURL}
        fieldName={field_name}
        formLink={formLink}
        maxServices={maxServices}
      />
    );
  }

  renderForm(formLink, formData) {
    return (
      <React.Fragment>
        <FormErrors errors={this.props.errors.__all__} />
        <div className="font-14 mb-4">
          An SLA Report allows your organization to report on specific checks or systems for the purposes of SLA
          accountability.&nbsp;
          <ZendeskSupportLink href="https://support.uptime.com/hc/en-us/articles/360017118860-Creating-an-Uptime-com-SLA-Report-">
            View&nbsp;Documentation&nbsp;&rarr;
          </ZendeskSupportLink>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <TextInput fieldName="name" labelText="Name" isRequired={true} formLink={formLink} />
          </div>
          <div className="col-lg-6">
            <SelectBox
              fieldName="default_date_range"
              choices={this.props.choices.default_date_range}
              labelText="Default Date Range"
              isRequired={true}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <ServiceSelectorInput
              numChecks={formData.services_selected.length}
              fieldName="services_selected"
              placeholder="All checks..."
              isRequired={false}
              formErrors={this.props.errors}
              labelText="Manually select checks"
              titleText="Individually select checks."
              onShowDialog={this.handleChecksSelectionShowDialog}
            />
          </div>
          <div className="col-lg-6">
            <Select2
              fieldName="services_tags"
              labelText="Auto-select checks by tag"
              isRequired={false}
              formLink={formLink}
              choices={this.props.choices.services_tags}
              allowEmpty={true}
              multiple={true}
              closeOnSelect={false}
              placeholder="All tags..."
              titleText="Include all present or future checks with any of the selected tags."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mt-4 mb-3">
            <ToggleSwitchPanel
              title="Uptime Section"
              fieldName="show_uptime_section"
              formLink={formLink}
              titleText="Show a section with Uptime SLA statistics."
            >
              <CheckBox
                fieldName="show_uptime_sla"
                formLink={formLink}
                divCSSClass="mb-2"
                isRequired={false}
                disabled={!formData.show_uptime_section}
                labelText="Show Target SLA values on report"
                titleText="Show the Target SLA values defined on each check."
              />
              <CheckBox
                fieldName="filter_with_downtime"
                formLink={formLink}
                divCSSClass="mb-2"
                isRequired={false}
                disabled={!formData.show_uptime_section}
                labelText="Show only checks with downtime"
                titleText="Exclude checks with no downtime in the selected date range."
              />
              <CheckBox
                fieldName="filter_uptime_sla_violations"
                formLink={formLink}
                divCSSClass="mb-3"
                isRequired={false}
                disabled={!formData.show_uptime_section}
                labelText="Show only checks below SLA"
                titleText="Exclude checks that meet or exceed their defined SLA."
              />
              <SelectBox
                fieldName="uptime_section_sort"
                labelText="Sorting"
                isRequired={false}
                formLink={formLink}
                allowEmpty={false}
                formGroupClass="form-group mb-0"
                disabled={!formData.show_uptime_section}
                choices={this.props.choices.uptime_section_sort}
              />
            </ToggleSwitchPanel>
          </div>
          <div className="col-lg-6 mt-4 mb-3">
            <ToggleSwitchPanel
              title="Response Time Section"
              fieldName="show_response_time_section"
              formLink={formLink}
              titleText="Show a section with Response Time SLA statistics."
            >
              <CheckBox
                fieldName="show_response_time_sla"
                formLink={formLink}
                divCSSClass="mb-2"
                isRequired={false}
                disabled={!formData.show_response_time_section}
                labelText="Show Target SLA values on report"
                titleText="Show the Target SLA % and Target Response Time SLA values defined on each check."
              />
              <CheckBox
                fieldName="filter_slowest"
                formLink={formLink}
                divCSSClass="mb-2"
                isRequired={false}
                disabled={!formData.show_response_time_section}
                labelText="Limit to 5 checks per type"
                titleText="Include only the 5 lowest performing checks of each type (e.g. HTTP, API, etc.) for the selected date range."
              />
              <CheckBox
                fieldName="filter_response_time_sla_violations"
                formLink={formLink}
                divCSSClass="mb-3"
                isRequired={false}
                disabled={!formData.show_response_time_section}
                labelText="Show only checks below SLA"
                titleText="Exclude checks that meet or exceed their defined response time SLA."
              />
              <SelectBox
                fieldName="response_time_section_sort"
                labelText="Sorting"
                isRequired={false}
                formLink={formLink}
                allowEmpty={false}
                formGroupClass="form-group mb-0"
                disabled={!formData.show_response_time_section}
                choices={this.props.choices.response_time_section_sort}
              />
            </ToggleSwitchPanel>
          </div>
          <SLAReportReportingGroups
            handleChecksSelectionShowDialog={this.handleChecksSelectionShowDialogGroup}
            handleGroupAdd={this.handleGroupAdd}
            handleGroupRemove={this.handleGroupRemove}
            reportingGroupsListURL={this.props.reportingGroupsListURL}
            reportingGroupsIsValidURL={this.props.reportingGroupsIsValidURL}
            countReportingGroupsURL={this.props.countReportingGroupsURL}
            onError={this.props.onError}
            errors={this.props.errors}
            formData={formData}
            formLink={formLink}
          />
        </div>
      </React.Fragment>
    );
  }
}
