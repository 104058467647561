import _ from 'underscore';

import {checkFeatures} from './CheckFeatures.js';
import {Colors} from './RumColors.js';

const timeKey = 'EventTime';

export const ReportDefs = {
  pageLoad: {
    name: 'Page load time',
    key: 'pageLoad',
    doc: 'https://support.uptime.com/hc/en-us/articles/4415869645714-RUM-Advanced-Data-Page-Load-Time-Breakdown',
    noDataMessage: 'No data found. Try adjusting the time range to include more page views.',
    trend: [
      {
        title: 'Page load time trend',
        bar: true,
        left: checkFeatures.pageViews,
        right: [checkFeatures.firstByte, checkFeatures.firstPaint, checkFeatures.tti],
        baselines: [checkFeatures.tti],
      },
    ],
    hist: {
      feature: checkFeatures.pageLoad,
      ranges: {
        multiplier: checkFeatures.apdexThreshold,
        [Colors.green]: 1,
        [Colors.yellowOrange]: 4,
        [Colors.red]: Infinity,
      },
    },
    donut: {
      header: checkFeatures.apdex,
      featureColors: {
        [Colors.green]: checkFeatures.satisfied,
        [Colors.yellowOrange]: checkFeatures.tolerating,
        [Colors.red]: checkFeatures.frustrated,
      },
    },
    breakdown: {
      sections: [
        {start: null, end: checkFeatures.connectEnd, name: 'Connection'},
        {start: checkFeatures.connectEnd, end: checkFeatures.responseStart, name: 'Server'},
        {start: checkFeatures.responseStart, end: checkFeatures.responseEnd, name: 'Transfer'},
        {start: checkFeatures.responseEnd, end: checkFeatures.tti, name: 'Client'},
        {start: checkFeatures.tti, end: checkFeatures.pageLoad, name: 'Assets'},
      ],
      markers: [{feature: checkFeatures.firstPaint}, {feature: checkFeatures.tti}],
    },
    segments: {
      sections: [
        {
          title: 'URL',
          dataURLParams: 'tab=pageload&section=url',
          table: {
            features: [
              checkFeatures.urlGroup,
              checkFeatures.pageViews,
              checkFeatures.sessions,
              checkFeatures.firstByte,
              checkFeatures.firstPaint,
              checkFeatures.tti,
            ],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Devices',
          dataURLParams: 'tab=pageload&section=device',
          table: {
            features: [
              checkFeatures.device,
              checkFeatures.pageViews,
              checkFeatures.sessions,
              checkFeatures.firstByte,
              checkFeatures.firstPaint,
              checkFeatures.tti,
            ],
          },
          trend: {
            timeKey: timeKey,
            autoSeries: checkFeatures.device,
            value: checkFeatures.tti,
            summarize: checkFeatures.pageViews,
          },
          donut: {
            feature: checkFeatures.pageViews,
            series: checkFeatures.device,
          },
          map: null,
        },
        {
          title: 'Platforms',
          dataURLParams: 'tab=pageload&section=platform',
          table: {
            features: [
              checkFeatures.platform,
              checkFeatures.pageViews,
              checkFeatures.sessions,
              checkFeatures.firstByte,
              checkFeatures.firstPaint,
              checkFeatures.tti,
            ],
          },
          trend: {
            timeKey: timeKey,
            autoSeries: checkFeatures.platform,
            value: checkFeatures.tti,
            summarize: checkFeatures.pageViews,
          },
          donut: {
            feature: checkFeatures.pageViews,
            series: checkFeatures.platform,
          },
          map: null,
        },
        {
          title: 'Browsers',
          dataURLParams: 'tab=pageload&section=browser',
          table: {
            features: [
              _.extend({}, checkFeatures.browser, {
                drillDown: {
                  dataURLParams: 'tab=pageload&section=browserVersion',
                  param: 'browser',
                  table: {
                    features: [
                      checkFeatures.browserVersion,
                      checkFeatures.pageViews,
                      checkFeatures.sessions,
                      checkFeatures.firstByte,
                      checkFeatures.firstPaint,
                      checkFeatures.tti,
                    ],
                  },
                },
              }),
              checkFeatures.pageViews,
              checkFeatures.sessions,
              checkFeatures.firstByte,
              checkFeatures.firstPaint,
              checkFeatures.tti,
            ],
          },
          trend: {
            timeKey: timeKey,
            autoSeries: checkFeatures.browser,
            value: checkFeatures.tti,
            summarize: checkFeatures.pageViews,
          },
          donut: {
            feature: checkFeatures.pageViews,
            series: checkFeatures.browser,
          },
          map: null,
        },
        {
          title: 'Countries',
          dataURLParams: 'tab=pageload&section=country',
          table: {
            features: [
              _.extend({}, checkFeatures.country, {
                drillDown: {
                  dataURLParams: 'tab=pageload&section=countryRegion',
                  param: 'country',
                  table: {
                    features: [
                      checkFeatures.region,
                      checkFeatures.pageViews,
                      checkFeatures.sessions,
                      checkFeatures.firstByte,
                      checkFeatures.firstPaint,
                      checkFeatures.tti,
                    ],
                  },
                },
              }),
              checkFeatures.pageViews,
              checkFeatures.sessions,
              checkFeatures.firstByte,
              checkFeatures.firstPaint,
              checkFeatures.tti,
            ],
          },
          trend: null,
          donut: null,
          map: {
            color: {
              feature: checkFeatures.tti,
              ranges: {
                multiplier: checkFeatures.apdexThreshold,
                [Colors.green]: 1,
                [Colors.yellowOrange]: 4,
                [Colors.red]: Infinity,
              },
            },
            shade: {
              feature: checkFeatures.pageViews,
            },
          },
        },
      ],
    },
  },

  ajaxLoad: {
    name: 'Ajax load time',
    key: 'ajaxLoad',
    doc: 'https://support.uptime.com/hc/en-us/articles/4415869814674-RUM-Advanced-Data-AJAX-Load-Time',
    noDataMessage: 'No data found. Try adjusting the time range to include more AJAX calls.',
    trend: [
      {
        title: 'Ajax load time trend',
        bar: true,
        left: checkFeatures.ajaxViews,
        right: [checkFeatures.ajaxFirstByte, checkFeatures.ajaxLoad],
        baselines: [checkFeatures.ajaxLoad],
      },
    ],
    hist: {
      feature: checkFeatures.responseEnd,
      ranges: {
        multiplier: checkFeatures.apdexThreshold,
        [Colors.green]: 1,
        [Colors.yellowOrange]: 4,
        [Colors.red]: Infinity,
      },
    },
    breakdown: {
      sections: [
        {start: null, end: checkFeatures.connectEnd, name: 'Connection'},
        {start: checkFeatures.connectEnd, end: checkFeatures.ajaxFirstByte, name: 'Server'},
        {start: checkFeatures.ajaxFirstByte, end: checkFeatures.responseEnd, name: 'Transfer'},
      ],
      markers: [checkFeatures.ajaxFirstByte],
    },
    segments: {
      sections: [
        {
          title: 'URL',
          dataURLParams: 'tab=ajaxload&section=url',
          table: {
            features: [
              checkFeatures.urlGroup,
              checkFeatures.ajaxViews,
              checkFeatures.sessions,
              checkFeatures.ajaxFirstByte,
              checkFeatures.ajaxLoad,
            ],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Devices',
          dataURLParams: 'tab=ajaxload&section=device',
          table: {
            features: [
              checkFeatures.device,
              checkFeatures.ajaxViews,
              checkFeatures.sessions,
              checkFeatures.ajaxFirstByte,
              checkFeatures.ajaxLoad,
            ],
          },
          trend: {
            timeKey: timeKey,
            autoSeries: checkFeatures.device,
            value: checkFeatures.ajaxLoad,
            summarize: checkFeatures.ajaxViews,
          },
          donut: {
            feature: checkFeatures.ajaxViews,
            series: checkFeatures.device,
          },
          map: null,
        },
        {
          title: 'Platforms',
          dataURLParams: 'tab=ajaxload&section=platform',
          table: {
            features: [
              checkFeatures.platform,
              checkFeatures.ajaxViews,
              checkFeatures.sessions,
              checkFeatures.ajaxFirstByte,
              checkFeatures.ajaxLoad,
            ],
          },
          trend: {
            timeKey: timeKey,
            autoSeries: checkFeatures.platform,
            value: checkFeatures.ajaxLoad,
            summarize: checkFeatures.ajaxViews,
          },
          donut: {
            feature: checkFeatures.ajaxViews,
            series: checkFeatures.platform,
          },
          map: null,
        },
        {
          title: 'Browsers',
          dataURLParams: 'tab=ajaxload&section=browser',
          table: {
            features: [
              _.extend({}, checkFeatures.browser, {
                drillDown: {
                  dataURLParams: 'tab=ajaxload&section=browserVersion',
                  param: 'browser',
                  table: {
                    features: [
                      checkFeatures.browserVersion,
                      checkFeatures.ajaxViews,
                      checkFeatures.sessions,
                      checkFeatures.ajaxFirstByte,
                      checkFeatures.ajaxLoad,
                    ],
                  },
                },
              }),
              checkFeatures.ajaxViews,
              checkFeatures.sessions,
              checkFeatures.ajaxFirstByte,
              checkFeatures.ajaxLoad,
            ],
          },
          trend: {
            timeKey: timeKey,
            autoSeries: checkFeatures.browser,
            value: checkFeatures.ajaxLoad,
            summarize: checkFeatures.ajaxViews,
          },
          donut: {
            feature: checkFeatures.ajaxViews,
            series: checkFeatures.browser,
          },
          map: null,
        },
        {
          title: 'Countries',
          dataURLParams: 'tab=ajaxload&section=country',
          table: {
            features: [
              _.extend({}, checkFeatures.country, {
                drillDown: {
                  dataURLParams: 'tab=ajaxload&section=countryRegion',
                  param: 'country',
                  table: {
                    features: [
                      checkFeatures.region,
                      checkFeatures.ajaxViews,
                      checkFeatures.sessions,
                      checkFeatures.ajaxFirstByte,
                      checkFeatures.ajaxLoad,
                    ],
                  },
                },
              }),
              checkFeatures.ajaxViews,
              checkFeatures.sessions,
              checkFeatures.ajaxFirstByte,
              checkFeatures.ajaxLoad,
            ],
          },
          trend: null,
          donut: null,
          map: null,
        },
      ],
    },
  },

  errors: {
    name: 'Errors',
    key: 'errors',
    doc: 'https://support.uptime.com/hc/en-us/articles/4415882219282-RUM-Advanced-Data-Error-Rate-Breakdown ',
    noDataMessage: 'No data found. Try adjusting the time range to include more page views.',
    trend: [
      {
        title: 'Error rate over time',
        bar: true,
        left: checkFeatures.hits,
        right: [checkFeatures.errorRate4xx, checkFeatures.errorRate5xx, checkFeatures.errorRateJS],
      },
    ],
    segments: {
      sections: [
        {
          title: 'URL',
          dataURLParams: 'tab=errors&section=url',
          table: {
            features: [
              checkFeatures.urlGroup,
              checkFeatures.hits,
              checkFeatures.sessions,
              checkFeatures.errorRate4xx,
              checkFeatures.errorRate5xx,
              checkFeatures.errorRateJS,
            ],
          },
          tableTitleWithURLFilter: 'Called URLs',
          breakdownTable: {
            title: 'Errors By Code',
            columns: [checkFeatures.httpErrorCode, checkFeatures.httpErrors, checkFeatures.httpErrorRate],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Devices',
          dataURLParams: 'tab=errors&section=device',
          table: {
            features: [
              checkFeatures.device,
              checkFeatures.hits,
              checkFeatures.sessions,
              checkFeatures.errorRate4xx,
              checkFeatures.errorRate5xx,
              checkFeatures.errorRateJS,
            ],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Platforms',
          dataURLParams: 'tab=errors&section=platform',
          table: {
            features: [
              checkFeatures.platform,
              checkFeatures.hits,
              checkFeatures.sessions,
              checkFeatures.errorRate4xx,
              checkFeatures.errorRate5xx,
              checkFeatures.errorRateJS,
            ],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Browsers',
          dataURLParams: 'tab=errors&section=browser',
          table: {
            features: [
              _.extend({}, checkFeatures.browser, {
                drillDown: {
                  dataURLParams: 'tab=errors&section=browserVersion',
                  param: 'browser',
                  table: {
                    features: [
                      checkFeatures.browserVersion,
                      checkFeatures.hits,
                      checkFeatures.sessions,
                      checkFeatures.errorRate4xx,
                      checkFeatures.errorRate5xx,
                      checkFeatures.errorRateJS,
                    ],
                  },
                },
              }),
              checkFeatures.hits,
              checkFeatures.sessions,
              checkFeatures.errorRate4xx,
              checkFeatures.errorRate5xx,
              checkFeatures.errorRateJS,
            ],
          },
          trend: null,
          donut: null,
          map: null,
        },
      ],
    },
  },

  bounceRate: {
    name: 'Bounce rate',
    key: 'bounceRate',
    doc: 'https://support.uptime.com/hc/en-us/articles/4415882152850-RUM-Advanced-Data-Bounce-Rate',
    // eslint-disable-next-line max-len
    noDataMessage: 'No data found. Try adjusting the time range to include more page views for accurate bounce rates.',
    primary: checkFeatures.bounceRate,
    trend: [
      {
        title: 'Bounce rate vs. sessions',
        bar: true,
        left: checkFeatures.sessions,
        right: [checkFeatures.bounceRate],
      },
      {
        title: 'Bounce rate vs. load time',
        bar: false,
        left: checkFeatures.bounceRate,
        right: [checkFeatures.pageLoad],
      },
      {
        title: 'Bounce rate vs. errors',
        bar: false,
        left: checkFeatures.bounceRate,
        right: [checkFeatures.errorRate4xx, checkFeatures.errorRate5xx, checkFeatures.errorRateJS],
      },
    ],
    segments: {
      sections: [
        {
          title: 'URL',
          dataURLParams: 'tab=bounceRate&section=url',
          table: {
            features: [checkFeatures.startURL, checkFeatures.sessions, checkFeatures.tti, checkFeatures.bounceRate],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Devices',
          dataURLParams: 'tab=bounceRate&section=device',
          table: {
            features: [checkFeatures.device, checkFeatures.sessions, checkFeatures.tti, checkFeatures.bounceRate],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Platforms',
          dataURLParams: 'tab=bounceRate&section=platform',
          table: {
            features: [checkFeatures.platform, checkFeatures.sessions, checkFeatures.tti, checkFeatures.bounceRate],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Browsers',
          dataURLParams: 'tab=bounceRate&section=browser',
          table: {
            features: [
              _.extend({}, checkFeatures.browser, {
                drillDown: {
                  dataURLParams: 'tab=bounceRate&section=browserVersion',
                  param: 'browser',
                  table: {
                    features: [
                      checkFeatures.browserVersion,
                      checkFeatures.sessions,
                      checkFeatures.tti,
                      checkFeatures.bounceRate,
                    ],
                  },
                },
              }),
              checkFeatures.sessions,
              checkFeatures.tti,
              checkFeatures.bounceRate,
            ],
          },
          trend: null,
          donut: null,
          map: null,
        },
        {
          title: 'Countries',
          dataURLParams: 'tab=bounceRate&section=country',
          table: {
            features: [
              _.extend({}, checkFeatures.country, {
                drillDown: {
                  dataURLParams: 'tab=bounceRate&section=countryRegion',
                  param: 'country',
                  table: {
                    features: [
                      checkFeatures.region,
                      checkFeatures.sessions,
                      checkFeatures.tti,
                      checkFeatures.bounceRate,
                    ],
                  },
                },
              }),
              checkFeatures.sessions,
              checkFeatures.tti,
              checkFeatures.bounceRate,
            ],
          },
          trend: null,
          donut: null,
          map: null,
        },
      ],
    },
  },
};
