'use strict';

import React from 'react';
import QuickActions from '../../devices/services/QuickActions';

class SLAReportRow extends React.PureComponent {
  render() {
    let actions = [];
    const defaultActions = [
      {
        text: 'Download PDF',
        href: this.props.viewPDFURL.replace('0000', this.props.id),
        className: 'fa-file-pdf',
        target: '_blank',
        rel: 'no-referrer',
      },
      {
        text: 'Download XLS',
        href: this.props.viewXLSURL.replace('0000', this.props.id),
        className: 'fa-file-spreadsheet',
        target: '_blank',
        rel: 'no-referrer',
      },
    ];

    if (!this.props.hasWriteAccess) {
      actions.push({
        text: 'View Report',
        href: this.props.viewURL.replace('0000', this.props.id),
        className: 'fa-pen-to-square',
      });
      actions = actions.concat(defaultActions);
    } else {
      actions.push({
        text: 'Edit Report',
        className: 'fa-pen-to-square',
        onClick: this.props.onEdit,
      });
      actions = actions.concat(defaultActions);
      // a candidate for removal, will simplify code here.
      actions.push({
        text: 'View Report',
        href: this.props.viewURL.replace('0000', this.props.id),
        className: 'fa-file',
      });
      actions.push({
        text: 'Delete',
        className: 'fa-trash',
        onClick: this.props.onDelete,
      });
    }

    return (
      <tr>
        <td>
          <a href={this.props.viewURL.replace('0000', this.props.id)}>{this.props.name}</a>
        </td>
        <td className="actions-right">
          <QuickActions actions={actions} />
        </td>
      </tr>
    );
  }
}

export default class SLAReportList extends React.PureComponent {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length == 0) {
      return this.renderEmptyState();
    }

    const nodes = this.props.items.map((item, index) => (
      <SLAReportRow
        {...item}
        key={item.id}
        hasWriteAccess={this.props.hasWriteAccess}
        siteURL={this.props.siteURL}
        viewURL={this.props.viewURL}
        viewPDFURL={this.props.viewPDFURL}
        viewXLSURL={this.props.viewXLSURL}
        onEdit={this.props.onEdit.bind(null, index)}
        onDelete={this.props.onDelete.bind(null, index)}
      />
    ));

    return (
      <div className="white-block">
        <table className="table table-responsive-sm data-table" role="grid">
          <thead>
            <tr>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }

  renderEmptyState() {
    return <div>No SLA reports have been created.</div>;
  }
}
