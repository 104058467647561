'use strict';

import React from 'react';
import _ from 'underscore';
import Utils from '../jskit/general/Utils';
import SelectBox from '../jskit/react/forms/SelectBox';

export default class AuditLogButtonBar extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      filters: {
        model: '',
      },
      search: '',
    };

    this.debouncedTriggerFilterChanged = _.debounce(this.triggerFilterChanged, 750);
  }

  setFiltersAndSearch(filters, search) {
    filters = _.pick(filters, 'model');
    search = (search && search.toString()) || '';
    this.setState({filters: filters, search: search});
  }

  getQueryFilter() {
    const query = _.clone(this.state);

    // Remove empty filters so we don't filter on blanks
    query.filters = _.omit(query.filters, (v) => !v || v.length === 0);
    return query;
  }

  handleObjectTypeFilterChange(e) {
    const filters = Object.assign({}, this.state.filters, {model: e.target.value});
    this.setState({filters: filters}, this.triggerFilterChanged);
  }

  handleSearchChange(e) {
    this.setState({search: e.target.value}, this.debouncedTriggerFilterChanged);
  }

  triggerFilterChanged() {
    if (this.props.onFilterChange) {
      this.props.onFilterChange();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="heading-container d-block d-md-flex mb-4">
          <h1 className="heading mr-4 my-2 my-md-0">Audit Log</h1>
          <div className="form-inline">
            <div className="input-group mr-sm-3 mb-1 mb-md-0" style={{minWidth: '270px'}}>
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-search" />
                </span>
              </div>
              <input
                type="text"
                value={this.state.search}
                onChange={this.handleSearchChange}
                className="form-control"
                placeholder="Search by user, object or ID"
              />
            </div>
            <SelectBox
              fieldName="model"
              formGroupClass="form-group d-inline-block mb-1 mb-md-0"
              choices={this.props.choices.model}
              value={this.state.filters.model}
              onChange={this.handleObjectTypeFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
