import React from 'react';
import ReactApexChart from 'react-apexcharts';

function getTopKeysAndValues(obj, N) {
  const entries = Object.entries(obj);

  // sort entries by value in descending order
  entries.sort((a, b) => b[1] - a[1]);

  const top7Keys = [];
  const top7Values = [];
  let restValue = 0;

  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];

    if (i < N) {
      top7Keys.push(key);
      top7Values.push(value);
    } else {
      restValue += value;
    }
  }

  top7Keys.push('*Rest*');
  top7Values.push(restValue);

  return [top7Keys, top7Values];
}

class DonutChart extends React.Component {
  chartRef = React.createRef();
  chartInstance = null;

  constructor(props) {
    super(props);
    this.createChart = this.createChart.bind(this);
    this.createChart();
  }

  createChart() {
    const {data, tooltipFn} = this.props;
    let [labels, dataPoints] = [Object.keys(data), Object.values(data)];
    if (this.props.collapseSeries) {
      [labels, dataPoints] = getTopKeysAndValues(data, this.props.collapseSeries);
    }

    const fmtY = tooltipFn || ((x) => x);

    this.chartInstance = {
      chart: {
        id: this.props.id,
        type: 'donut',
        animations: {
          enabled: false,
        },
      },
      labels: labels,
      legend: {
        position: 'right',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (value, {seriesIndex}) => {
            return fmtY(value);
          },
        },
      },
      series: dataPoints,
      colors: [
        '#289DF5',
        '#72C4B9',
        '#F1B497',
        '#3373B4',
        '#FFD600',
        '#C08DE3',
        '#40557D',
        '#2BAF00',
        '#FF4861',
        '#F5A623',

        '#289DF5',
        '#72C4B9',
        '#F1B497',
        '#3373B4',
        '#FFD600',
        '#C08DE3',
        '#40557D',
        '#2BAF00',
        '#FF4861',
        '#F5A623',
      ],
      plotOptions: {
        pie: {
          donut: {
            size: '50%',
          },
        },
      },
    };
  }

  render() {
    const {id, title} = this.props;
    return (
      <div className="col-6 mb-2 analysis-chart" key={id}>
        <h4 className="mb-4">{title}</h4>
        <div className="position-relative">
          <ReactApexChart
            ref={this.chartRef}
            options={this.chartInstance}
            series={this.chartInstance.series}
            type="donut"
            height={this.props.height || '300px'}
            width={this.props.width || '100%'}
          />
        </div>
      </div>
    );
  }
}

export default DonutChart;
