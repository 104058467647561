'use strict';

import React from 'react';
import Utils from '../../../jskit/general/Utils';
import Chart from 'react-apexcharts';
import {defaultFormatter, getBaselineComparison, renderBaselineComparison} from '../RumUtils.jsx';
import {defaultGraphOptions} from './GraphFormatter.js';
import {Colors} from '../RumColors.js';
import Formatter from '../../../jskit/general/Formatter';

export default class DonutGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    const defaultOptions = defaultGraphOptions();
    this.state = {
      series: [],
      options: {
        chart: Object.assign(defaultOptions.chart, {
          type: 'donut',
          animations: {
            speed: 500,
          },
        }),
        labels: [],
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: this._tooltipFormatter,
          },
        },
        legend: Object.assign(Object.assign(defaultOptions.legend), {
          position: 'bottom',
          horizontalAlign: 'center',
          formatter: this._legendFormatter,
        }),
      },
    };
    this.chartRef = React.createRef();
    const parsedData = this._parseData();
    this._setData(parsedData);
  }

  componentDidUpdate(previousProps) {
    if (this.props.data !== previousProps.data) {
      const parsedData = this._parseData();
      this._setData(parsedData);
      const chart = (this.chartRef.current || {}).chart;
      if (chart) {
        chart.updateOptions(this.state.options, true, false, false);
      }
      this.forceUpdate();
    }
  }

  _parseData() {
    if (!this.props.data) {
      return {};
    }
    const {header, featureColors} = this.props;
    const colors = Object.keys(featureColors || {}).map((c) => c || Colors.black);
    const features = Object.values(featureColors || {});

    const labels = [];
    const series = [];
    let hasData = false;
    for (const feature of features) {
      labels.push(feature.name);
      const value = this.props.data[feature.key];
      series.push(value);
      hasData |= !!value;
    }
    if (!hasData) {
      return {};
    }

    let titleFragment;
    if (header) {
      if (typeof header == 'string') {
        titleFragment = header;
      } else {
        const baselineComparison = getBaselineComparison(header, this.props.data);
        const baselineFragment = renderBaselineComparison(baselineComparison);
        const titleStyle = {display: 'flex', flexDirection: 'row'};
        titleFragment = (
          <React.Fragment>
            <div style={titleStyle} title={baselineComparison.title}>
              <p className="baseline-comparison-value">{header.name}:</p>
              {baselineFragment}
            </div>
          </React.Fragment>
        );
      }
    }

    return {labels, series, colors, header: titleFragment};
  }

  /**
   * Set data state directly (not via setState) so that it can be parsed in the constructor.
   * This is necessary for the graph to appear correctly on its initial render.
   */
  _setData(parsedData) {
    this.state.header = parsedData.header;
    this.state.series = parsedData.series || [];
    this.state.options.colors = parsedData.colors || [Colors.black];
    this.state.options.labels = parsedData.labels || [];
  }

  _tooltipFormatter(value) {
    return `${Formatter.humanFriendlyInt(value)} page views`;
  }

  _legendFormatter(name, options) {
    let sum,
      pct = 0;
    if (this.state.series && this.state.series.length) {
      sum = this.state.series.reduce((a, b) => a + b);
      pct = sum ? defaultFormatter((this.state.series[options.seriesIndex] / sum) * 100) : 0;
    }
    return `${name}: ${pct}%`;
  }

  render() {
    return (
      <div className="donut-container">
        {this.state.header}
        {!!this.state.series && !!this.state.series.length && (
          <Chart
            ref={this.chartRef}
            options={this.state.options}
            series={this.state.series}
            width="300px"
            height="300px"
            type="donut"
          />
        )}
      </div>
    );
  }
}
