import _ from 'underscore';
import React from 'react';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import TransactionUtils from '../TransactionUtils';

const STEP_TYPES = ['command', 'variable', 'validation'];

export default class StepCommandSelector extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      filterText: '',
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.props.visible && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({filterText: ''});
      this.props.onAddStepComplete(null);
    }
  }

  handleStepTypeSelect(e, step_key) {
    this.setState({filterText: ''});
    this.props.onAddStepComplete(step_key);
  }

  handleFilterTextChange(e) {
    this.setState({filterText: e.target.value});
  }

  handleFilterTextEnter(e) {
    if (e.which !== 13) {
      return;
    }

    for (let i = 0; i < STEP_TYPES.length; i++) {
      var entries = this.filterStepDefs(STEP_TYPES[i], this.state.filterText);
      if (entries.length > 0) {
        this.handleStepTypeSelect(entries[0]);
        window.blur();
        return;
      }
    }
  }

  filterStepDefs(stepType, filterText) {
    filterText = filterText.toLowerCase();

    return this.props.transactionStepDefs.filter(
      function (v) {
        const step_key = v[0],
          step_def = v[1];
        return (
          this.props.onCheckStepCanBeAdded(step_key) &&
          step_def.step_type === stepType &&
          (!filterText || step_def.verbose_name.toLowerCase().indexOf(filterText) !== -1)
        );
      }.bind(this)
    );
  }

  render() {
    const pagespeed = this.filterStepDefs('pagespeed', this.state.filterText);
    const commands = this.filterStepDefs('command', this.state.filterText);
    const variables = this.filterStepDefs('variable', this.state.filterText);
    const validations = this.filterStepDefs('validation', this.state.filterText);

    return (
      <div
        ref={this.wrapperRef}
        id="stepSelect"
        className={ReactUtils.cssClass('step-select', {'d-none': !this.props.visible})}
      >
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation" hidden={!pagespeed.length}>
            <a
              id="home-tab"
              data-toggle="tab"
              href="#pagespeed"
              role="tab"
              aria-controls="commands"
              aria-selected="true"
            >
              Pagespeed
            </a>
          </li>
          <li className="nav-item" role="presentation" hidden={!commands.length}>
            <a
              className="active"
              id="home-tab"
              data-toggle="tab"
              href="#commands"
              role="tab"
              aria-controls="commands"
              aria-selected="true"
            >
              Commands
            </a>
          </li>
          <li className="nav-item" role="presentation" hidden={!variables.length}>
            <a
              className=""
              id="profile-tab"
              data-toggle="tab"
              href="#variables"
              role="tab"
              aria-controls="variables"
              aria-selected="false"
            >
              Variables
            </a>
          </li>
          <li className="nav-item" role="presentation" hidden={!validations.length}>
            <a
              className=""
              id="profile-tab"
              data-toggle="tab"
              href="#validations"
              role="tab"
              aria-controls="validations"
              aria-selected="false"
            >
              Validations
            </a>
          </li>
        </ul>
        <div className="form-group has-search">
          <span className="fal fa-search form-control-feedback"></span>
          <input
            ref="filter"
            type="text"
            className="form-control form-control-sm"
            placeholder="Search"
            value={this.state.filterText}
            onChange={this.handleFilterTextChange}
            onKeyDown={this.handleFilterTextEnter}
          />
        </div>
        <div className="tab-content">
          <div className="tab-pane show" id="pagespeed" role="tabpanel" aria-labelledby="home-tab">
            {pagespeed.length ? this.renderSection('pagespeed', 'Pagespeed', pagespeed) : null}
          </div>
          <div className="tab-pane show active" id="commands" role="tabpanel" aria-labelledby="home-tab">
            {commands.length ? this.renderSection('command', 'Commands', commands) : null}
          </div>
          <div className="tab-pane" id="variables" role="tabpanel" aria-labelledby="profile-tab">
            {variables.length ? this.renderSection('variable', 'Variables', variables) : null}
          </div>
          <div className="tab-pane" id="validations" role="tabpanel" aria-labelledby="profile-tab">
            {validations.length ? this.renderSection('validation', 'Validations', validations) : null}
          </div>
        </div>
      </div>
    );
  }

  renderSection(cssClass, name, stepDefs) {
    return (
      <div className={cssClass}>
        <ul className="step-item list-unstyled">{this.renderList(stepDefs)}</ul>
      </div>
    );
  }

  renderList(stepDefs) {
    return _.map(
      stepDefs,
      function (def_pair, i) {
        const step_key = def_pair[0],
          step_def = def_pair[1],
          title = TransactionUtils.getFirstSentence(step_def.help_text);
        return (
          <li key={step_key}>
            <a
              onClick={(e) => {
                this.handleStepTypeSelect(e, step_key);
              }}
              title={title}
              dangerouslySetInnerHTML={{__html: this.formatDescription(step_def.verbose_name)}}
            />
          </li>
        );
      }.bind(this)
    );
  }

  formatDescription(name) {
    return ReactUtils.escapeHTML(name).replace(/``(.*?)``/g, '<span class="user-value">$1</span>');
  }
}

StepCommandSelector.defaultProps = {
  transactionStepDefs: [],
};
