'use strict';

import _ from 'underscore';
import moment from 'moment-timezone';
import memoize from 'memoize-one';
import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors, Label, HelpIcon} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import TextArea from '../../jskit/react/forms/TextArea';
import Select2 from '../../jskit/react/forms/Select2';
import CheckBox from '../../jskit/react/forms/CheckBox';
import Formatter from '../../jskit/general/Formatter';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';
import {markdownGuideURL} from './ManageUtils.jsx';
import {postAlertMessage} from '../../js/global/alerts';
import DatePicker from '../../components/DatePicker/DatePicker';

import $ from 'jquery';

export default class IncidentForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {
        affected_components: [],
        updates: [],
      },
      validationErrors: {},
      editingIndex: null,
    };

    this.calculateComponentChoiceMap = memoize(this.calculateComponentChoiceMap);
    this.calculateAvailableComponents = memoize(this.calculateAvailableComponents);
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialData) {
    const defaults = {
      affected_components: [],
      updates: [],
      notify_subscribers: false,
      include_in_global_metrics: !this.props.forMaintenance,
      resolve_components: false,
      incident_type: this.props.forMaintenance ? 'SCHEDULED_MAINTENANCE' : 'INCIDENT',
    };

    if (initialData) {
      this.setState({formData: Object.assign({}, defaults, initialData), editingIndex: null});
    } else {
      defaults.updates.push(this._blankUpdate());
      this.setState({formData: defaults, editingIndex: 0});
    }

    this.clearValidationErrors();
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleAffectedComponentChange(index, e) {
    const defaultStatus = this.props.choices.componentFailureStatus[0][0];
    const affectedComponents = this.getFormData().affected_components.concat();

    if (index === 'new') {
      affectedComponents.push({
        obj_id: null,
        id: e.target.value,
        status: defaultStatus,
      });
    } else if (e.target.value === '') {
      affectedComponents.splice(index, 1);
    } else {
      affectedComponents[index].id = e.target.value;
      affectedComponents[index].status = affectedComponents[index].status || defaultStatus;
    }

    const newFormData = Object.assign({}, this.getFormData(), {affected_components: affectedComponents});
    this.setState({formData: newFormData});
  }

  handleAffectedComponentStatusChange(index, e) {
    const affectedComponents = this.getFormData().affected_components.concat();
    affectedComponents[index].status = e.target.value;
    const newFormData = Object.assign({}, this.getFormData(), {affected_components: affectedComponents});
    this.setState({formData: newFormData});
  }

  _blankUpdate() {
    return {
      id: '',
      incident_state: this.props.forMaintenance ? 'maintenance' : '',
      incident_state_display: 'Scheduled Maintenance',
      description: '',
    };
  }

  _finishEditingUpdate(deleteUpdate) {
    if (this.state.editingIndex === null) {
      return;
    }

    const formData = this.getFormData();
    const index = this.state.editingIndex;
    const update = formData.updates[index];
    if (!deleteUpdate && update.incident_state === '') {
      return;
    }
    if (deleteUpdate) {
      const newUpdates = formData.updates.concat();
      newUpdates.splice(index, 1);
      const newFormData = Object.assign({}, formData, {updates: newUpdates});
      this.state.formData = newFormData;
      this.setState({formData: newFormData});
    } else if (!update.updated_at) {
      if (formData.updates.length === 1) {
        update.updated_at = formData.starts_at;
      } else {
        update.updated_at = moment.utc().toISOString();
      }
      if (update.incident_state === 'resolved' && !formData.ends_at) {
        formData.ends_at = update.updated_at;
      }
      this.setState({formData: formData});
    }
    this.state.editingIndex = null;
    this.setState({editingIndex: null});
  }

  handleNewUpdateClick(e) {
    e.preventDefault();
    const formData = this.getFormData();
    if (formData.updates.length > 0 && formData.updates[0].id === null) {
      return;
    }

    this._finishEditingUpdate();

    const newUpdates = [this._blankUpdate()].concat(formData.updates);
    const newFormData = Object.assign({}, formData, {updates: newUpdates});
    this.setState({formData: newFormData, editingIndex: 0});
  }

  handleEditUpdateClick(index, e) {
    e.preventDefault();
    this._finishEditingUpdate();
    this.setState({editingIndex: index});
  }

  handleDeleteUpdateClick(e) {
    e.preventDefault();
    this._finishEditingUpdate(true);
  }

  handlePreviewUpdateClick(e) {
    e.preventDefault();
    const formData = this.getFormData();
    const index = this.state.editingIndex;
    const update = formData.updates[index];
    if (update.incident_state === '') {
      this.setState({
        validationErrors: {
          updates: [{incident_state: 'Incident state is required'}],
        },
      });
      return;
    }
    const markdown = update.description;
    new Ajax().post({
      url: this.props.previewURL,
      data: {markdown},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this._updateIncidentUpdate({description_html: data.data.result});
        } else {
          this._updateIncidentUpdate({description_html: markdown});
        }
        this._finishEditingUpdate();
      }.bind(this),
    });
  }

  _updateIncidentUpdate(obj) {
    const formData = this.getFormData();
    const update = formData.updates[this.state.editingIndex];
    formData.updates[this.state.editingIndex] = Object.assign({}, update, obj, {is_modified: true});
    this.setState({formData: formData});
  }

  handleUpdateIncidentStateChange(e) {
    this._updateIncidentUpdate({
      incident_state: e.target.value,
      incident_state_display: e.target.getSelectionData().text,
    });
  }

  handleUpdateDescriptionChange(e) {
    this._updateIncidentUpdate({description: e.target.value});
  }

  handleUpdateDateTimeChange(e) {
    this._updateIncidentUpdate({update_datetime: e.target.value});
  }

  handleSubmit(e) {
    e.preventDefault();

    this.clearValidationErrors();
    const data = this.getFormData();
    const includesUpdate = data.notify_subscribers === true;
    new Ajax().post({
      url: this.props.updateURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.props.onNewData(data.data);
          this.hideModal();
          if (includesUpdate) {
            postAlertMessage(
              '<strong>An update was queued to be sent to the page subscribers in approximately 2 minutes.</strong>',
              'success',
              true
            );
          }
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  formatComponentStatus(val) {
    if (val.id) {
      return $(`<span class="status-icon ${val.id}">${val.text}</span>`);
    } else {
      return val.text;
    }
  }

  formatIncidentState(val) {
    if (val.id) {
      return $(`<span class="incident-icon ${val.id}">${val.text}</span>`);
    } else {
      return val.text;
    }
  }

  calculateComponentChoiceMap(componentChoices) {
    return _.object(componentChoices);
  }

  calculateAvailableComponents(componentChoices, affectedComponents) {
    const affectedComponentsIds = affectedComponents.map((x) => x.id);
    const affectedComponentsMap = _.object(affectedComponentsIds, affectedComponentsIds);
    return componentChoices.filter((x) => !affectedComponentsMap[x[0]]);
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    const formData = this.getFormData();
    const titleType = this.props.forMaintenance ? 'Scheduled Maintenance' : 'Incident';
    const title = (formData.id ? 'Edit ' : 'Add ') + titleType;

    return (
      <Modal
        ref="modal"
        title={title}
        size="lg"
        extraClass="incident-form"
        saveButton={this.state.editingIndex === null ? this.handleSubmit : null}
        cancelButton={true}
      >
        <form ref="form" className="form-spaced" onSubmit={this.handleSubmit}>
          {this.renderForm(formLink, formData)}
        </form>
      </Modal>
    );
  }

  renderForm(formLink, formData) {
    const maintenanceDescription =
      'Schedule maintenance windows and issue notifications to alert your subscribers of planned downtime events.';
    const maintenanceZendeskLink =
      'https://support.uptime.com/hc/en-us/articles/360016256840-Status-Page-Forms-and-Fields#maintenance';
    const incidentDescription = 'Incidents help convey the state of downtime events to your userbase in real-time.';
    const incidentZendeskLink =
      'https://support.uptime.com/hc/en-us/articles/360016256840-Status-Page-Forms-and-Fields#incidents';
    const description = this.props.forMaintenance ? maintenanceDescription : incidentDescription;
    const zendeskLink = this.props.forMaintenance ? maintenanceZendeskLink : incidentZendeskLink;

    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="col-lg-12 font-14">
            {description}&nbsp;
            <ZendeskSupportLink href={zendeskLink}>View&nbsp;Documentation&nbsp;&rarr;</ZendeskSupportLink>
          </div>
        </div>
        <FormErrors errors={this.state.validationErrors.__all__} />
        {this.renderTitleAndDates(formLink, formData)}
        {this.renderAffectedComponents(formLink, formData)}
        <hr className="my-4" />
        {this.renderUpdates(formLink, formData)}
      </React.Fragment>
    );
  }

  renderTitleAndDates(formLink, formData) {
    const titleCls = 'col-lg-12';
    const dateCls = 'col-lg-6';

    return (
      <React.Fragment>
        <div className="row">
          <div className={titleCls}>
            <TextInput fieldName="name" labelText="Title" isRequired={true} formLink={formLink} />
          </div>
          <div className={dateCls}>
            <DatePicker
              fieldName="starts_at"
              labelText="Starts"
              className="form-group"
              placeholder={this.props.forMaintenance ? null : 'now'}
              titleText={
                'Date and Time of incident start. ' + this.props.forMaintenance
                  ? ''
                  : 'Leave blank to start the incident when this form is saved.'
              }
              isRequired={this.props.forMaintenance}
              helpText={'Times are in ' + Formatter.timezoneAbbr() + '.'}
              timezone={window.TIMEZONE}
              formLink={formLink}
            />
          </div>
          <div className={dateCls}>
            <DatePicker
              fieldName="ends_at"
              labelText="Ends"
              className="form-group"
              titleText={
                'Date and Time of incident end. ' + this.props.forMaintenance
                  ? ''
                  : 'Leave blank if the incident is ongoing.'
              }
              placeholder={this.props.forMaintenance ? null : 'ongoing'}
              isRequired={this.props.forMaintenance}
              helpText={'Times are in ' + Formatter.timezoneAbbr() + '.'}
              timezone={window.TIMEZONE}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-lg-12">
            <CheckBox
              fieldName="include_in_global_metrics"
              labelText="This incident should count as downtime"
              titleText="This incident’s duration will contribute to global downtime calculations."
              formLink={formLink}
            />
          </div>
        </div>
        <hr className="my-4" />
      </React.Fragment>
    );
  }

  renderAffectedComponents(formLink, formData) {
    const componentChoiceMap = this.calculateComponentChoiceMap(this.props.choices.components);
    const remainingComponentChoices = this.calculateAvailableComponents(
      this.props.choices.components,
      formData.affected_components
    );

    const affectedComponents = formData.affected_components.map((c, i) => {
      const choices = [
        [formData.affected_components[i].id, componentChoiceMap[formData.affected_components[i].id]],
      ].concat(remainingComponentChoices);

      return (
        <div key={i} className="row">
          <div className="col-lg-6">
            <Select2
              formGroupClass="form-group mb-2"
              fieldName={'component-' + i}
              isRequired={true}
              choices={choices}
              allowEmpty={true}
              value={formData.affected_components[i].id}
              onChange={this.handleAffectedComponentChange.bind(null, i)}
            />
          </div>
          <div className="col-lg-6">
            <Select2
              formGroupClass="form-group mb-2"
              fieldName={'component_status-' + i}
              isRequired={true}
              choices={this.props.choices.componentFailureStatus}
              templateResult={this.formatComponentStatus}
              templateSelection={this.formatComponentStatus}
              value={formData.affected_components[i].status}
              onChange={this.handleAffectedComponentStatusChange.bind(null, i)}
            />
          </div>
        </div>
      );
    });

    let updateComponents = null;
    if (formData.affected_components.length) {
      const updateTitle = this.props.forMaintenance
        ? 'Update all components with the above status(es) when maintenance begins'
        : 'Update the selected component(s) with the above status(es) when this form saved';

      updateComponents = (
        <div className="form-group mt-3">
          <CheckBox
            fieldName="update_component_status"
            labelText={updateTitle}
            isRequired={false}
            formLink={formLink}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Label
          labelText="Components Affected"
          titleText="OPTIONAL: Select the component(s) affected by this incident."
        />
        {affectedComponents}
        {remainingComponentChoices.length ? (
          <div className="row">
            <div className="col-lg-6">
              <Select2
                formGroupClass="form-group mb-2"
                fieldName={'component-' + formData.affected_components.length}
                isRequired={false}
                choices={remainingComponentChoices}
                allowEmpty={true}
                value=""
                onChange={this.handleAffectedComponentChange.bind(null, 'new')}
              />
            </div>
          </div>
        ) : null}
        {updateComponents}
      </React.Fragment>
    );
  }

  renderUpdates(formLink, formData) {
    const hasNewUpdate = formData.updates.length > 0 && !formData.updates[0].id;
    const updates = formData.updates.map((update, i) => {
      if (this.state.editingIndex === i) {
        return <React.Fragment key={i}>{this.renderUpdateForm(formLink, formData, update, i)}</React.Fragment>;
      } else if (update.is_modified) {
        return <React.Fragment key={i}>{this.renderUpdatePreview(formLink, formData, update, i)}</React.Fragment>;
      } else {
        return <React.Fragment key={i}>{this.renderUpdateStatic(formLink, formData, update, i)}</React.Fragment>;
      }
    });

    const updatesTitleText = this.props.forMaintenance
      ? 'Add maintenance updates throughout the scheduled window, mark as resolved to display as a past maintenance event.'
      : 'Add updates throughout the duration of an incident, mark as resolved to display as a past incident.';

    return (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5 className="mb-0">
            Updates
            <HelpIcon titleText={updatesTitleText} />
          </h5>
          {!hasNewUpdate ? (
            <button onClick={this.handleNewUpdateClick} className="btn btn-outline-primary btn-sm">
              <i className="fas fa-plus fa-sm mr-2" />
              New Update
            </button>
          ) : null}
        </div>
        <div className="incident-updates">{updates}</div>
      </React.Fragment>
    );
  }

  renderUpdateForm(formLink, formData, update, index) {
    const title = update.id ? 'Edit Update' : 'New Update';
    const updateErrors = this.state.validationErrors.updates;
    let incidentStateError = null,
      incidentDescriptionError = null;
    if (updateErrors && updateErrors.length && index < updateErrors.length) {
      incidentStateError = updateErrors[index].incident_state;
      incidentDescriptionError = updateErrors[index].description;
    }
    const previewButtonClasses = ReactUtils.cssClass('btn', {
      disabled: update.incident_state === '',
      'btn-light': update.incident_state === '',
      'btn-primary': update.incident_state !== '',
    });
    return (
      <div className="card card-body card-light-2 mb-4 ml-n3">
        <h5 className="mb-3">{title}</h5>
        <Select2
          formGroupClass="form-group mb-2"
          isRequired={true}
          choices={this.props.choices.incidentState}
          templateResult={this.formatIncidentState}
          fieldName="incident_state"
          formErrors={{incident_state: incidentStateError}}
          templateSelection={this.formatIncidentState}
          placeholder="Select incident state..."
          value={update.incident_state}
          onChange={this.handleUpdateIncidentStateChange}
        />
        <TextArea
          isRequired={true}
          helpText={'<a href="' + markdownGuideURL + '" target="_blank">Markdown</a> supported.'}
          fieldName="description"
          formErrors={{description: incidentDescriptionError}}
          value={update.description}
          onChange={this.handleUpdateDescriptionChange}
        />
        <div className="d-flex align-items-center justify-content-between">
          {formData.updates.length > 1 ? (
            <button onClick={this.handleDeleteUpdateClick} className="btn btn-default text-muted">
              <i className="fas fa-trash" />
            </button>
          ) : (
            <div></div>
          )}
          <button onClick={this.handlePreviewUpdateClick} className={previewButtonClasses}>
            Preview
          </button>
        </div>
      </div>
    );
  }

  renderUpdatePreview(formLink, formData, update, index) {
    const hasAffectedComponents = formData.affected_components && formData.affected_components.length;
    return (
      <div className="preview">
        <div className="preview-label">Preview</div>
        {this.renderUpdateStatic(formLink, formData, update, index)}
        {!update.id ? (
          <div className="form-group mb-0 pl-4">
            <CheckBox
              divCSSClass="mb-2"
              fieldName="notify_subscribers"
              labelText="Notify status page subscribers of this update"
              titleText="OPTIONAL: Send an email notification to subscribers about this update."
              isRequired={false}
              formLink={formLink}
            />
            {update.incident_state === 'resolved' && hasAffectedComponents ? (
              <CheckBox
                fieldName="resolve_components"
                labelText='Reset all affected components to "Operational"'
                isRequired={false}
                formLink={formLink}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  renderUpdateStatic(formLink, formData, update, index) {
    return (
      <div className="update">
        <div className="update-heading">
          <a onClick={this.handleEditUpdateClick.bind(null, index)} className="edit" href="#">
            <i className="fas fa-pen" />
          </a>
          <span className={'incident-icon name ' + update.incident_state}>{update.incident_state_display}</span>
          <span className="date">{Formatter.longDateTime(update.update_datetime)}</span>
        </div>
        <div className="update-description markdown" dangerouslySetInnerHTML={{__html: update.description_html}}></div>
      </div>
    );
  }
}

IncidentForm.defaultProps = {
  updateURL: null,
  previewURL: null,
  forMaintenance: false,
  choices: {},
  onNewData: null,
};
