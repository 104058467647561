import css from './InspireGlobalMetrics.module.css';
import ReactUtils from '@/jskit/react/ReactUtils';

export interface InspireGlobalMetricsProps {
  uptimePct: string;
  outageCnt: number | string;
  downtime: string;
}

export const InspireGlobalMetrics = ({uptimePct, outageCnt, downtime}: InspireGlobalMetricsProps) => (
  <div className={ReactUtils.cssClass('row', css.GlobalMetricsContainer)}>
    <div className={ReactUtils.cssClass('text-center text-md-left', css.GlobalMetric)}>
      <h6 className="pb-0 mb-0">{uptimePct}</h6>
      <span>Global Uptime</span>
    </div>
    <div className={css.GlobalMetricSeparator}></div>
    <div className={ReactUtils.cssClass('text-center text-md-left', css.GlobalMetric)}>
      <h6 className="pb-0 mb-0">{downtime}</h6>
      <span>Total Downtime</span>
    </div>
    <div className={css.GlobalMetricSeparator}></div>
    <div className={ReactUtils.cssClass('text-center text-md-left', css.GlobalMetric)}>
      <h6 className="pb-0 mb-0">{outageCnt}</h6>
      <span>Outage (s)</span>
    </div>
  </div>
);
