import React from 'react';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import Ajax from '../../jskit/general/Ajax';

export default class IntegrationTestModal extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      step1: null,
      step2: null,
      step3: null,
    };
  }

  showModal() {
    this.setState({
      step1: null,
      step2: null,
      step3: null,
    });
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  handleSendTestRequest(e) {
    e.preventDefault();

    this.setState({step1: 'pending'});
    new Ajax().post({
      url: this.props.testURL,
      data: this.props.data,
      encode: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({step2: 'completed'});
        if (data.success) {
          this.setState({step3: 'success'});
        } else {
          this.setState({step3: data.error});
        }
      }.bind(this),
    });
    this.setState({step1: 'completed'});
    this.setState({step2: 'pending'});
  }

  renderStep1() {
    if (this.state.step1 === 'pending') {
      return (
        <li>
          Sending request...
          <i className="fas fa-spinner fa-spin fa-lg mr-5 text-muted" />
        </li>
      );
    } else if (this.state.step1 === 'completed') {
      return (
        <li>
          Sending request... <span className="text-success">OK</span>
        </li>
      );
    }
    return null;
  }

  renderStep2() {
    if (this.state.step2 === 'pending') {
      return (
        <li>
          Monitoring for errors... <i className="fas fa-spinner fa-spin fa-lg mr-5 text-muted" />
        </li>
      );
    } else if (this.state.step2 === 'completed') {
      var res = null;
      if (this.state.step3 === 'success') {
        res = <span className="text-success">No errors detected</span>;
      } else {
        res = <span className="text-danger">Errors detected</span>;
      }
      return <li>Monitoring for errors... {res}</li>;
    }
    return null;
  }

  renderStep3() {
    if (this.state.step3 === 'success') {
      return <li>Success! Please check the integration provider to ensure the notification has arrived</li>;
    } else if (this.state.step3 != null) {
      return (
        <li>
          Please review the error below and adjust the integration settings accordingly:
          <br />
          <br />
          <pre className="text-danger">{this.state.step3}</pre>
        </li>
      );
    }
    return null;
  }

  render() {
    var test_log = null,
      name = null;
    if (this.props.data !== undefined) {
      name = this.props.data.name;
      if (!this.props.data.is_test_supported) {
        test_log = <p>Sorry, testing this integration is not supported.</p>;
      } else {
        if (this.state.step1 === null) {
          test_log = (
            <div>
              Click the button below to send a test notification to this integration and verify it was sent
              successfully.
              <br />
              <br />
              <button type="button" className="btn btn-primary" onClick={this.handleSendTestRequest}>
                Start Test
              </button>
            </div>
          );
        } else {
          test_log = (
            <ol>
              {this.renderStep1()}
              {this.renderStep2()}
              {this.renderStep3()}
            </ol>
          );
        }
      }
    }
    return (
      <Modal ref="modal" title={'Test Integration: ' + name} size="lg" closeButton={true}>
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <div className="row">
            <div className="col-lg">{test_log}</div>
          </div>
        </form>
      </Modal>
    );
  }
}
