'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import {getBaselineComparison, renderBaselineComparison, renderNeedHelp} from './RumUtils.jsx';
import {checkFeatures, checkFeature} from './CheckFeatures.js';

class DataDetail extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  render() {
    const {primary, details, title, tooltip} = this.props.data || {};
    const data = this.props.data.data || {};

    // Format large numbers to be more readable in some cases.
    const precisions = {
      [checkFeatures.pageViews.key]: 4,
      [checkFeatures.sessions.key]: 4,
      [checkFeatures.errorRate4xx.key]: 2,
      [checkFeatures.errorRate5xx.key]: 2,
      [checkFeatures.errorRateJS.key]: 2,
    };
    const intThreshold = 10000;

    // Make primary detail large, bold, and without the text to the left.
    let primaryComparison, primaryCompData;
    if (primary && (primary === checkFeatures.pageViews || data[primary.key])) {
      primaryCompData = getBaselineComparison(primary, data, {
        valuePrecision: precisions[primary.key],
        intThreshold,
        isAjax: this.props.isAjax,
      });
      primaryComparison = renderBaselineComparison(primaryCompData, {
        headerStyle: {fontSize: '22px', fontWeight: 600},
        arrowStyle: {fontSize: '14px'},
      });
    }

    // Make secondary detais smaller and with line dividers.
    const detailRows = [];
    if (details) {
      for (const feature of details) {
        const comparisonData = getBaselineComparison(feature, data, {
          valuePrecision: precisions[feature.key] || 3,
          intThreshold,
          isAjax: this.props.isAjax,
        });
        const detailComparison = renderBaselineComparison(comparisonData, {
          divStyle: {paddingLeft: '10px'},
        });

        detailRows.push(
          <div key={feature.key} className="detail-row" title={comparisonData.title}>
            <p className="secondary-header">{feature.name}</p>
            <div className="dotted-row-spacer"></div>
            {detailComparison}
          </div>
        );
      }
    }

    // If no page views, display a note to the user about what to do.
    const pageViewsValue = data ? data[checkFeatures.pageViews.key] || data[checkFeatures.ajaxViews.key] : 0;
    let userNote;
    if (primary && primary === checkFeatures.pageViews && pageViewsValue === 0) {
      userNote = (
        <p className="no-views-note">
          Try expanding time range, or see
          {/* eslint-disable-next-line max-len */}
          <a
            href="https://support.uptime.com/hc/en-us/articles/115002559485-Overview-of-RUM-Checks-Reports"
            target="_blank"
          >
            &nbsp;here&nbsp;
          </a>
          for more ideas.
        </p>
      );
    }

    const detailTitle = title || primary.name;
    return (
      <div
        className={'header-item-container ' + 'header-item-container-' + detailTitle.toLowerCase().replace(' ', '-')}
      >
        <p className="stat-name primary-header" title={tooltip || (primaryCompData || {}).title}>
          {detailTitle}
        </p>
        {primaryComparison}
        {userNote}
        <div className="primary-spacer"></div>
        {detailRows}
      </div>
    );
  }
}

export default class RumCheckDetailHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  render() {
    let data = this.props.data;

    // We want satisfaction as a percentage instead of raw value, so insert that as a new checkFeature.
    // eslint-disable-next-line max-len
    const satisfiedPct = checkFeature('satisfiedPct', 'Satisfied', {
      units: '%',
      tooltipName: '% Satisfied (based on APDEX threshold)',
      inverse: true,
    });
    const satisfiedPctValue = (data[checkFeatures.satisfied.key] / data[checkFeatures.pageViews.key]) * 100;
    // eslint-disable-next-line max-len
    const satisfiedPctBaseline =
      (data[checkFeatures.satisfied.baseline] / data[checkFeatures.pageViews.baseline]) * 100;
    const satisfiedPctDPct = satisfiedPctValue - satisfiedPctBaseline; // Absolute change in %.
    data = Object.assign({}, data, {
      [satisfiedPct.key]: satisfiedPctValue,
      [satisfiedPct.deltaPct]: satisfiedPctDPct,
    });

    const pageViewData = {
      data,
      primary: checkFeatures.pageViews,
      details: [checkFeatures.sessions, checkFeatures.bounceRate],
    };

    const pageLoadData = {
      data,
      primary: checkFeatures.tti,
      details: [checkFeatures.firstPaint, checkFeatures.apdex, satisfiedPct],
    };

    const ajaxData = {
      data,
      primary: checkFeatures.ajaxLoad,
      details: [checkFeatures.ajaxFirstByte],
    };

    const errorData = {
      data,
      primary: checkFeature('errors', 'Errors'),
      tooltip: 'Error rate breakdown',
      details: [checkFeatures.errorRate4xx, checkFeatures.errorRate5xx, checkFeatures.errorRateJS],
    };

    const baselineComparisons = [pageViewData, pageLoadData, ajaxData, errorData].map((item, index) => {
      return <DataDetail key={index} data={item} isAjax={this.props.isAjax} />;
    });

    return (
      <React.Fragment>
        {renderNeedHelp(undefined, {dismissible: true})}
        <div className="rum-check-detail p-4 mb-3">
          <div className="header-item-wrapper">{baselineComparisons}</div>
        </div>
      </React.Fragment>
    );
  }
}
