'use strict';

import React from 'react';
import moment from 'moment';
import Utils from '../jskit/general/Utils';
import Ajax from '../jskit/general/Ajax';
import Formatter from '../jskit/general/Formatter';
import {initializeMapsWithPins} from '../js/global/maps';
import $ from 'jquery';

const Status = {
  NEW: 0,
  RUNNING: 10,
  COMPLETED: 100,
  ERROR: 999,
};

export default class UptimeTestResults extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      status: {
        global: null,
        results: [],
      },
    };

    this.loadTimer = 0;
  }

  componentDidMount() {
    this.loadAllData();
  }

  componentDidUpdate() {
    initializeMapsWithPins('#uptime-test-map');
    $('#uptime-test-map [data-toggle=tooltip]').tooltip('enable');

    const setToShow = $('#uptime-test-map [data-toggle=tooltip][data-country=US]')
      .first()
      .add($('#uptime-test-map [data-toggle=tooltip][data-country=GB]').first())
      .add($('#uptime-test-map [data-toggle=tooltip][data-country=SG]').first());

    const setToHide = $('#uptime-test-map [data-toggle=tooltip]').not(setToShow);

    setToHide.tooltip('hide');
    setToShow.tooltip('show');
  }

  loadAllData() {
    if (this.loadTimer >= 120000) {
      var status = this.state.status;
      status.global = Status.ERROR;
      this.setState({status: status}); // error
      return;
    }
    new Ajax().get({
      url: this.props.statusURL.replace('000', this.props.test.id),
      decoder: 'json',
      success: function (data) {
        this.setState({status: data.status});

        if (this.shouldShowProgress()) {
          this.loadTimer += 2500;
          setTimeout(this.loadAllData.bind(this), 2500);
        }
      }.bind(this),
    });
  }

  shouldShowProgress() {
    return this.props.showProgress && this.state.status.global !== null && this.state.status.global < Status.COMPLETED;
  }

  render() {
    return (
      <React.Fragment>
        <div className="white-block p-4">
          <nav id="uptime-test-tabs" className="nav nav-tabs nav-tabs-plain" role="tablist">
            <a
              className="nav-item nav-link active"
              data-toggle="tab"
              href="#uptime-test-results"
              role="tab"
              aria-controls="uptime-test-results"
              aria-selected="true"
            >
              Results Details
            </a>
            {this.props.history ? (
              <a
                className="nav-item nav-link"
                data-toggle="tab"
                href="#uptime-test-history"
                role="tab"
                aria-controls="uptime-test-history"
                aria-selected="false"
              >
                History
              </a>
            ) : null}
          </nav>
        </div>
        {this.renderErrorOrProgress()}
        <div className="tab-content">
          <div className="tab-pane fade active show" id="uptime-test-results" role="tabpanel">
            {this.renderResultsTable(this.state.status)}
            {this.renderCountryMap(this.state.status)}
          </div>
          {this.props.history ? (
            <div className="tab-pane fade" id="uptime-test-history" role="tabpanel">
              {this.renderHistoryTable(this.state.status, this.props.history)}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }

  renderErrorOrProgress() {
    if (this.state.status.global === Status.ERROR) {
      return (
        <div className="alert alert-danger mb-4" role="alert">
          There was an error running this test.
        </div>
      );
    } else if (this.shouldShowProgress()) {
      const isInQueue = this.state.status.global === Status.NEW;
      const max = this.props.maxTestTime;
      const start = moment.utc(this.props.test.created_on);
      const elapsed = moment.utc().diff(start, 'seconds');
      const progress = Math.min((elapsed / max) * 100.0, 98);

      return (
        <div className="white-block white-block-divider p-4">
          <p className="mb-1">
            Please wait...
            <strong>{isInQueue ? 'Test is waiting in queue.' : 'Test is in progress.'}</strong>
          </p>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow={isInQueue ? 0 : progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{width: (isInQueue ? 0 : progress) + '%'}}
            />
          </div>
        </div>
      );
    }
  }

  renderResultsTable(status) {
    return (
      <div className="white-block p-4">
        <table className="table listing-table table-responsive mb-0">
          <thead>
            <tr>
              <th>Location</th>
              <th>DNS Lookup</th>
              <th>Connect</th>
              <th>Redirects Time</th>
              <th>TLS Connect</th>
              <th>Request Time</th>
              <th>First Byte</th>
              <th>Total Time</th>
              <th>Size</th>
              <th>Download Speed</th>
              <th>Remote IP</th>
            </tr>
          </thead>
          <tbody>{status.results.map(this.renderResultsTableRow)}</tbody>
        </table>
      </div>
    );
  }

  renderResultsTableRow(result) {
    let cells;

    if (result.status === 999) {
      if (result.error) {
        cells = <td colSpan="10">{result.error}</td>;
      } else {
        cells = <td colSpan="10">Error getting results</td>;
      }
    } else if (result.status !== 100) {
      cells = <td colSpan="10">Waiting for results</td>;
    } else {
      cells = (
        <React.Fragment>
          <td>{result.time_namelookup} ms</td>
          <td>{result.time_connect} ms</td>
          <td>{result.time_redirect} ms</td>
          <td>{result.time_appconnect} ms</td>
          <td>{result.time_pretransfer} ms</td>
          <td>{result.time_starttransfer} ms</td>
          <td>{result.time_total} ms</td>
          <td>{result.fmt_size}</td>
          <td>{result.fmt_speed}/s</td>
          <td>{result.remote_ip}</td>
        </React.Fragment>
      );
    }

    return (
      <tr key={result.location}>
        <td>{result.location}</td>
        {cells}
      </tr>
    );
  }

  renderCountryMap(status) {
    const pins = status.results.filter((x) => x.status === 100);

    return (
      <div className="px-lg-6 py-5">
        <div id="uptime-test-map" className="map-with-pins tooltip-primary tooltip-fixed" data-map-with-pins="solid">
          <img alt="" src={this.props.mapImageURL} style={{opacity: 0.4}} />
          {pins.map(this.renderCountryMapPin.bind(null, this.props.locations))}
        </div>
      </div>
    );
  }

  renderCountryMapPin(locations, result) {
    const loc = locations[result.location] || {};
    const title =
      '<span class="d-block font-13">' +
      loc.name +
      '</span> <span class="d-block font-18">' +
      result.time_total +
      ' ms</span>';

    return (
      <span
        key={loc.map_country}
        data-country={loc.map_country}
        data-offset-x={loc.map_offset_x}
        data-offset-y={loc.map_offset_y}
        className="circular-pointer primary"
        data-toggle="tooltip"
        data-html="true"
        data-container="#uptime-test-map"
        title={title}
      ></span>
    );
  }

  renderHistoryTable(status, history) {
    return (
      <div className="white-block p-4">
        <table className="table listing-table table-responsive-md mb-0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Avg speed</th>
            </tr>
          </thead>
          <tbody>{history.map(this.renderHistoryTableRow.bind(null, status))}</tbody>
        </table>
      </div>
    );
  }

  renderHistoryTableRow(status, row) {
    const createdOn = Formatter.longDateTime(row.created_on);
    let link;

    if (row.id === status.id) {
      link = <span>{createdOn + ' (current result)'}</span>;
    } else {
      link = <a href={this.props.resultURL + '/' + row.id + '/' + row.domain}>{createdOn}</a>;
    }

    return (
      <tr key={row.id}>
        <td>{link}</td>
        <td>{row.avg_time ? Math.round(row.avg_time) + ' ms' : ''}</td>
      </tr>
    );
  }
}
