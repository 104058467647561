'use strict';

import _ from 'underscore';
import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import Schedule from './../common/Schedule';

export default class ServiceMaintenance extends React.Component {
  serialize() {
    return this.refs.schedule.serialize();
  }

  deserialize(schedule) {
    this.refs.schedule.deserialize(schedule);
  }

  render() {
    const maintenanceWindowDesc = this.props.choices.maintenance_window_descriptions || {};
    const availableStates = _.object(
      Object.entries(maintenanceWindowDesc).map(([k, [t1, t2, icon]]) => [
        k,
        <span className="d-inline-block mb-1">
          {t1}
          <i className={ReactUtils.cssClass('text-muted ml-2', icon)} />
          <br />
          <small className="text-muted">{t2}</small>
        </span>,
      ])
    );

    return (
      <div className="mb-3">
        <div className="mb-4">
          <i className="fas fa-info-circle"></i>&nbsp; During maintenance any failed checks will be ignored in uptime
          calculations and alerts will not be sent.
        </div>
        <Schedule
          ref="schedule"
          availableStates={availableStates}
          choices={this.props.choices}
          errors={this.props.errors}
        />
      </div>
    );
  }
}
