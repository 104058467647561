import React from 'react';
import CustomRequestForm from './CustomRequestForm';

const ContactUs = ({className, initialValues, requestURL, onCancel, onSuccess}) => (
  <div className={`ContactUsContainer ${className}`}>
    <div className="ContactUs row pt-3">
      <div className="col-md-8 col-lg-8 offset-lg-1 pl-2 pl-md-5 pr-2 pr-md-5 pt-2 pt-md-4 left-column">
        <CustomRequestForm
          initialValues={initialValues}
          requestURL={requestURL}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </div>
      <div className="col-md-4 col-lg-3 d-none d-md-block right-column">
        <h5>Experience Unparalleled Monitoring Tailored to Your Needs!</h5>
        <ul>
          <li>Tailored monitoring solutions designed to meet your unique requirements of any size and scale.</li>
          <li>Robust monitoring infrastructure capable of handling thousands of checks.</li>
          <li>Intelligent alert management system handling hundreds of alerts with precision.</li>
          <li>Flexible subaccount structures facilitating dozens of user subaccounts.</li>
          <li>Long-term engagement benefits through multi-year agreements.</li>
          <li>Significant cost savings through scaled discounting options.</li>
        </ul>
      </div>
    </div>
  </div>
);
export default ContactUs;
