import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Modal from '../../jskit/react/Modal';
import Formatter from '../../jskit/general/Formatter';
import PaymentMethodForm from './PaymentMethodForm.jsx';
import PromoCode from './checkout/PromoCode';
import SubscriptionQuote from './SubscriptionQuote.jsx';
import {AlertNotification} from './AlertNotification';
import $ from 'jquery';
import {CHECKOUT_FIELDS} from './PLAN_DEFS';

export default class SubscriptionCheckoutPage extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.state = {
      submitting: false,
    };
  }

  handleSubscribeSubmit(e) {
    e.preventDefault();
    if (this.state.submitting) {
      return false;
    }
    this.clearPaymentValidationErrors();
    if (this.refs.paymentMethodForm.handleSubscribeSubmit()) {
      this.setState({submitting: true});
    }
  }

  handlePaymentMethodSuccess(paymentData) {
    this.props.onSubmit(paymentData, this.setPaymentValidationErrors, this.handleFinalizeSubmit);
  }

  handleFinalizeSubmit() {
    this.setState({submitting: false});
  }

  setPaymentValidationErrors(fields) {
    this.refs.paymentMethodForm.setValidationErrors(fields);
  }

  clearPaymentValidationErrors() {
    this.refs.paymentMethodForm.clearValidationErrors();
  }

  handlePaymentMethodError() {
    this.setState({submitting: false});
  }

  handleViewQuote(e) {
    e.preventDefault();
    if (this.state.submitting) {
      return false;
    }
    this.refs.modal.showModal();
  }

  handleGoBack(e) {
    if (this.state.submitting) {
      return false;
    }
    if (this.props.onCancel) {
      this.props.onCancel(e);
    }
  }

  handleViewQuoteShow() {
    $('.modal-dialog').addClass('focused');
    $('body').addClass('modalprinter');
  }

  handleViewQuoteHide() {
    $('.modal-dialog').removeClass('focused');
    $('body').removeClass('modalprinter');
  }

  render() {
    return (
      <div className="SubscriptionCheckoutPage">
        <AlertNotification message={this.props.alertNotificationMessage} />
        {this.props.withTitle && <h1 className="heading">Checkout</h1>}
        {this.renderCheckoutErrors()}
        <div className="row">
          <div className="col-md-7 col-12 order-2 order-md-1">
            <PaymentMethodForm
              paymentMethod={this.props.paymentMethod}
              braintreeToken={this.props.braintreeToken}
              initialData={this.props.billingInfo}
              onSuccess={this.handlePaymentMethodSuccess}
              onError={this.handlePaymentMethodError}
              readOnly={
                !this.props.canPurchase || (this.props.isCustomer && !this.props.isPrimary && this.props.canPurchase)
              }
              allCountries={this.props.allCountries}
              excludeStateCountries={this.props.excludeStateCountries}
              showStateDropdownForCountries={this.props.showStateDropdownForCountries}
              recaptchaRequired={this.props.recaptchaRequired}
              recaptchaSiteKey={this.props.recaptchaSiteKey}
              recaptchaCallback={this.props.recaptchaCallback}
              recaptchaSolved={this.props.recaptchaSolved}
              ref="paymentMethodForm"
            />
          </div>
          <div className="col-md-4 offset-md-1 col-12 order-1 order-md-2">{this.renderPurchaseSummary()}</div>
          <div className="d-block d-md-none col-12 order-3">
            {this.props.isCustomer && !this.props.isUpgrade ? this.renderProRataNotice() : false}
            <div className="row mb-2">
              <div className="col-12">
                <button
                  className="btn btn-primary w-100"
                  onClick={this.handleSubscribeSubmit}
                  disabled={this.state.submitting}
                >
                  {this.state.submitting ? 'Working...' : (this.props.isCustomer ? 'Upgrade' : 'Subscribe') + ' Now'}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <a href="#" onClick={this.handleGoBack} disabled={this.state.submitting}>
                  <small>Cancel</small>
                </a>
              </div>
            </div>
          </div>
        </div>
        {this.props.displayFullQuote && this.renderViewQuoteModal()}
      </div>
    );
  }

  renderCheckoutErrors() {
    if (!this.props.checkoutErrors) {
      return false;
    }
    const errors = this.props.checkoutErrors.reduce(
      (a, el) => a + (el.trim().endsWith('.') ? el.trim() + ' ' : el.trim() + '. '),
      ''
    );

    return <div className="alert alert-danger">{errors}</div>;
  }

  renderViewQuoteModal() {
    const isAnnual = !!this.props.formLink.get('annual_billing');
    return (
      <Modal
        ref="modal"
        size="lg"
        extraClass="SubscriptionQuoteModal"
        closeButton={true}
        onShowComplete={this.handleViewQuoteShow}
        onHideComplete={this.handleViewQuoteHide}
      >
        <SubscriptionQuote
          includedValues={this.props.includedValues}
          isCustomer={this.props.isCustomer}
          isUpgrade={this.props.isUpgrade}
          companyData={this.props.companyData}
          isAnnual={isAnnual}
          unitsPrices={this.props.bareUnitesPrices}
          addedValues={this.props.addedValues}
          basePlanPrice={this.props.calculatedValues.base_price_subtotal}
          basePlanName={this.props.calculatedValues.base_plan}
          calculatedValues={this.props.calculatedValues}
          uptimeBrandInfo={this.props.uptimeBrandInfo}
        />
      </Modal>
    );
  }

  renderProRataNotice() {
    return (
      this.props.displayProrationNotice && (
        <div className="row mb-3 mt-1">
          <div className="col-12">
            <small className="text-muted">
              * Additional charges are calculated pro-rata for the remainder of the current billing cycle.
            </small>
          </div>
        </div>
      )
    );
  }

  renderDiscounts(discounts) {
    return discounts.map((el, idx) => {
      if (!(el.value * 1.0)) {
        return false;
      }
      const elCls = ReactUtils.cssClass('row mb-2 discount-row', {highlighted: el.highlight});
      return (
        <div className={elCls} key={'discount-' + idx}>
          <div className="col-8 col-lg-8">
            {el.percent > 0 ? `${Formatter.percentage(el.percent, 0)} ` : ''}
            {el.title}
          </div>
          <div className="col-4 col-lg-4 text-right">
            <strong className="highlighted">{Formatter.shortCurrency(el.value * -1, 2)}</strong>
          </div>
        </div>
      );
    });
  }

  renderPurchaseSummary() {
    const purchasedItems = _.pairs(this.props.addedValues).map((el) => {
      const fieldName = el[0];
      const itemTitle = CHECKOUT_FIELDS[fieldName];
      const itemQty = el[1];
      const itemCost = this.props.unitsPrices[fieldName] * itemQty;
      const itemCostVerbose = itemQty > 0 ? Formatter.shortCurrency(itemCost, 2) : '';
      return itemTitle !== null && itemQty ? (
        <div className="row mb-2" key={'invoice-line-' + fieldName}>
          <div className="col-8 col-lg-8">
            +{itemQty > 0 ? Formatter.humanFriendlyInt(itemQty) : ''} {itemTitle}
          </div>
          <div className="col-4 col-lg-4 text-right">
            <strong>{itemCostVerbose}</strong>
          </div>
        </div>
      ) : null;
    });
    const miniQuoteDiscounts = this.props.calculatedValues.discounts.filter((el) => el.mini_quote === true);
    const commonDiscounts = this.renderDiscounts(miniQuoteDiscounts.filter((el) => !el.is_promo));
    const promoDiscounts = this.renderDiscounts(miniQuoteDiscounts.filter((el) => el.is_promo === true));
    const hasOneTimePromo =
      miniQuoteDiscounts.filter((el) => el.is_promo === true && el.recurring === false).length > 0;
    let totalCostLabel, totalPrice, monthlySavings, totalPricePerAnnum;
    if (this.props.isCustomer) {
      if (this.props.isUpgrade) {
        // upgrade subscription (plan), no addons, no duration change
        totalPrice = this.props.calculatedValues.total_due_today;
        totalCostLabel = 'Total Due Today'; //??
        monthlySavings = 0;
        totalPricePerAnnum = this.props.calculatedValues.price_monthly * 12;
      } else {
        // purchase addons or switch to annual
        totalCostLabel = 'Total Per Month';
        totalPrice = this.props.calculatedValues.added_cost;
        monthlySavings = this.props.calculatedValues.added_cost_full - totalPrice;
        totalPricePerAnnum = this.props.calculatedValues.added_cost * 12;
      }
    } else {
      // initial subscription for an FT to a plan
      if (hasOneTimePromo) {
        totalCostLabel = 'Total Due Today';
        totalPrice = this.props.calculatedValues.total_due_today;
        monthlySavings = this.props.calculatedValues.monthly_subtotal - this.props.calculatedValues.price_monthly;
      } else {
        totalCostLabel = 'Total Per Month';
        totalPrice = this.props.calculatedValues.price_monthly;
        monthlySavings = this.props.calculatedValues.monthly_subtotal - this.props.calculatedValues.price_monthly;
      }
      totalPricePerAnnum = this.props.calculatedValues.price_monthly * 12;
    }
    const isAnnual = !!this.props.formLink.get('annual_billing');
    return (
      <div className="PurchaseSummaryBox p-2 p-lg-3">
        <div className="d-flex justify-content-between">
          <h4>Purchase Summary</h4>
          {this.props.displayFullQuote && (
            <div>
              <small className="d-inline text-info">
                <u>
                  <em>
                    <a tabIndex="2" onClick={this.handleViewQuote} disabled={this.state.submitting}>
                      View Quote
                    </a>
                  </em>
                </u>
              </small>
            </div>
          )}
        </div>
        <hr />
        {this.props.isCustomer && this.props.isUpgrade && (
          <div className="row mb-2">
            <div className="col-8 col-lg-8">+ {this.props.calculatedValues.base_plan}</div>
            <div className="col-4 col-lg-4 text-right">
              <strong>{Formatter.shortCurrency(this.props.calculatedValues.base_price, 2)}</strong>
            </div>
          </div>
        )}
        {!this.props.isCustomer && (
          <div className="row mb-2">
            <div className="col-8 col-lg-8">Base Price: {this.props.calculatedValues.base_plan}</div>
            <div className="col-4 col-lg-4 text-right">
              <strong>{Formatter.shortCurrency(this.props.calculatedValues.base_price, 2)}</strong>
            </div>
          </div>
        )}
        {purchasedItems}
        {miniQuoteDiscounts.length ? <hr /> : false}
        {commonDiscounts}
        {this.props.allowPromocode ? (
          <>
            <div className="row mt-3">
              <div className="col-12">
                <PromoCode handleApply={this.props.onPromocodeRefresh} error={this.props.promoCodeError} />
              </div>
            </div>
            {promoDiscounts}
          </>
        ) : null}
        <hr />
        {monthlySavings ? (
          <div className="row mb-2 discount-row highlighted">
            <div className="col-8 col-lg-8">
              <strong className="font-16 font-weight-normal" style={{color: '#11B559'}}>
                Monthly Savings
              </strong>
            </div>
            <div className="col-4 col-lg-4 text-right">
              <strong>{Formatter.shortCurrency(monthlySavings, 2)}</strong>
            </div>
          </div>
        ) : (
          false
        )}
        {this.props.displayTotalCost && (
          <div className="row mb-2">
            <div className="col-8 col-lg-8">
              <strong>{totalCostLabel}</strong>
            </div>
            <div className="col-4 col-lg-4 text-right">
              <strong>{Formatter.shortCurrency(totalPrice, 2)}</strong>
            </div>
          </div>
        )}
        {isAnnual ? (
          <div className="row mb-4">
            <div className="col-8 col-lg-8">
              <strong>Billed Annually</strong>
            </div>
            <div className="col-4 col-lg-4 text-right">
              <strong>{Formatter.shortCurrency(totalPricePerAnnum, 2)}</strong>
            </div>
          </div>
        ) : (
          (this.props.isUpgrade || !this.props.isCustomer) && (
            <div className="row mb-4">
              <div className="col-8 col-lg-8">
                <strong>Recurring Monthly Cost</strong>
              </div>
              <div className="col-4 col-lg-4 text-right">
                <strong>{Formatter.shortCurrency(this.props.calculatedValues.price_monthly, 2)}</strong>
              </div>
            </div>
          )
        )}
        <div className="d-none d-md-block">
          {this.props.isCustomer ? this.renderProRataNotice() : false}
          <div className="row mb-2">
            <div className="col-12">
              <button
                className="btn btn-success w-100"
                onClick={this.handleSubscribeSubmit}
                disabled={this.state.submitting}
              >
                {this.state.submitting ? 'Working...' : (this.props.isCustomer ? 'Pay' : 'Subscribe') + ' Now'}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <a href="#" onClick={this.handleGoBack} disabled={this.state.submitting}>
                <small>Back</small>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SubscriptionCheckoutPage.defaultProps = {
  braintreeToken: null,
  paymentMethod: null,
  displayFullQuote: true,
  displayTotalCost: true,
  displayProrationNotice: true,
  alertNotificationMessage: null,
  isCustomer: false,
  canPurchase: false,
  checkoutErrors: null,
  includedValues: {},
  calculatedValues: {},
  uptimeBrandInfo: null,
  formLink: null,
  billingInfo: null,
  onSubmit: null,
  onCancel: null,
};
