'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export default class SelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  focus() {
    this.refs.selectBox.focus();
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);
    const choices = this.props.choices || [];
    const groups = this.props.groups || {};
    const selectOptions = [];

    if (this.props.allowEmpty) {
      selectOptions.push(
        <option key="" value="">
          {this.props.emptyLabel}
        </option>
      );
    }

    for (const group in groups) {
      const options = [];
      groups[group].forEach((choice) => {
        options.push(
          <option key={choice[0]} value={choice[0]}>
            {choice[1]}
          </option>
        );
      });
      selectOptions.push(
        <optgroup key={group} label={group}>
          {options}
        </optgroup>
      );
    }

    choices.forEach((choice) => {
      selectOptions.push(
        <option key={choice[0]} value={choice[0]}>
          {choice[1]}
        </option>
      );
    });

    return (
      <div className={this.props.formGroupClass}>
        <Label fieldId={this.htmlId} {...this.props} />
        <select
          ref="selectBox"
          id={this.htmlId}
          name={this.props.fieldName}
          className={ReactUtils.cssClass('form-control', {['is-invalid']: form.errors})}
          disabled={this.props.disabled}
          value={form.value}
          onChange={form.onChange}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        >
          {selectOptions}
        </select>
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

SelectBox.defaultProps = {
  fieldName: '', // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
  disabled: false, // Control is disabled?
  allowEmpty: false, // Add an extra empty option to the select box.
  emptyLabel: '------------', //default label for empty value
  choices: [], // Array of 2-tuples: [key, value] select box choices.
  groups: {}, // Object containg grouped [key, value] choices. Group keys are used as
  // choices separators
  formGroupClass: 'form-group', // CSS classes for the form-group div

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
  onFocus: null, // Focus handler
  onBlur: null, // Blur handler
};
