'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export default class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className={this.props.formGroupClass}>
        <Label fieldId={this.htmlId} {...this.props} wizard={undefined} />
        {this.renderOptions(form)}
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }

  renderOptions(form) {
    return this.props.choices.map((v, i) => {
      const [key, label] = v;
      const fieldId = this.htmlId + '-' + i;
      const disabled =
        this.props.disabled || (this.props.disabledChoices && this.props.disabledChoices.find((v) => key === v));

      return (
        <div key={i} className={ReactUtils.cssClass('custom-control custom-radio', this.props.divCSSClass)}>
          <input
            type="radio"
            id={fieldId}
            name={form.fieldName}
            className={ReactUtils.cssClass('custom-control-input', {['is-invalid']: form.errors})}
            disabled={disabled}
            value={key}
            checked={form.value === key}
            onChange={form.onChange}
          />

          <Label
            baseCSSClass="custom-control-label"
            fieldId={fieldId}
            isRequired={this.props.isRequired}
            labelText={label}
          />
        </div>
      );
    });
  }
}

RadioGroup.defaultProps = {
  fieldName: null, // Field name returned in form data
  formGroupClass: 'form-group', // CSS classes for the form-group div
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
  disabled: false, // Control is disabled?
  choices: [], // Array of 2-tuples: [key, value] select box choices.

  divCSSClass: null, // Extra CSS classes to add to the div.form-check

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
};
