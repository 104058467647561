import {SectionNavContent} from '@/jskit/react/SectionNav';
import {CurrentStatusTabs} from '@/status_pages/display/components/tabs/CurrentStatus/CurrentStatus';
import ComponentStatus from '@/status_pages/display/ComponentStatus';
import {ComponentStatusValue, StatusPage} from '@/status_pages/types';
import {EmptySectionBan} from '@/status_pages/display/EmptySectionBan';

export interface ComponentCurrentStatusSectionProps {
  currentSectionId: CurrentStatusTabs;
  statuspage: StatusPage;
  componentStatusRank: Record<ComponentStatusValue, number>;
  componentStatusChoices: Record<ComponentStatusValue, string>;
}

export const ComponentCurrentStatusSection = ({
  currentSectionId,
  statuspage,
  componentStatusRank,
  componentStatusChoices,
}: ComponentCurrentStatusSectionProps) => (
  <SectionNavContent sectionId={CurrentStatusTabs.COMPONENT_CURRENT_STATUS} currentSectionId={currentSectionId}>
    {statuspage.components?.length > 0 ? (
      <ComponentStatus
        statuspage={statuspage}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
      />
    ) : (
      <EmptySectionBan>
        There are no <strong>Components</strong>
      </EmptySectionBan>
    )}
  </SectionNavContent>
);
