'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import ExternalAccountUsersList from './ExternalAccountUsersList.jsx';
import ExternalAccountUserForm from './ExternalAccountUserForm.jsx';

export default class ExternalAccountUsersTab extends React.Component {
  loadUsersData() {
    new Ajax().post({
      url: this.props.listURL,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({
          items: data.items,
          itemsLoaded: true,
        });
      }.bind(this),
    });
  }

  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.state = {
      items: [],
      validationErrors: {},
      itemsLoaded: false,
      passwordResetSuccess: false,
      passwordResetEmail: '',
    };

    this.loadUsersData();
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleAddItemClick(e) {
    e.preventDefault();
    this.showEditForm(null);
  }

  showEditForm() {
    this.clearValidationErrors();
    this.refs.form.showModal(undefined);
  }

  handleToggle(itemID) {
    new Ajax().post({
      url: this.props.toggleURL,
      data: {external_user_id: itemID},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.loadUsersData();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleDelete(itemID) {
    new Ajax().post({
      url: this.props.deleteURL,
      data: {external_user_id: itemID},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.loadUsersData();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handlePasswordReset(itemID, externalUserEmail) {
    new Ajax().post({
      url: this.props.passwordResetURL,
      data: {external_user_id: itemID},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.setState({
            passwordResetSuccess: true,
            passwordResetEmail: externalUserEmail,
          });
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleFormSaveClick(e) {
    e.preventDefault();
    this.clearValidationErrors();
    new Ajax().post({
      url: this.props.saveURL,
      data: this.refs.form.getFormData(),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.loadUsersData();
          this.refs.form.hideModal();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.passwordResetSuccess ? (
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            An email with password reset instructions has been sent to: {this.state.passwordResetEmail}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => {
                this.setState({passwordResetSuccess: false, passwordResetEmail: ''});
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : (
          ''
        )}
        <div className="heading-container">
          <h4 className="heading">External Users</h4>
          <div className="btn-group">
            <button
              data-wizard="users-add-new"
              type="button"
              onClick={this.handleAddItemClick}
              className="btn btn-primary dropdown-toggle-split-left"
            >
              New User
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle dropdown-toggle-split dropdown-toggle-split-right"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle Dropdown</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <a href="#" onClick={this.handleAddItemClick} className="dropdown-item">
                Add New User
              </a>
              <a target="_blank" href={this.props.exportUsersURL} className="dropdown-item">
                Export to CSV
              </a>
            </div>
          </div>
        </div>
        <ExternalAccountUsersList
          hasLoaded={this.state.itemsLoaded}
          items={this.state.items}
          toggleHandler={this.handleToggle}
          deleteHandler={this.handleDelete}
          passwordResetHandler={this.handlePasswordReset}
          addItemClickHandler={this.handleAddItemClick}
        />
        <ExternalAccountUserForm ref="form" errors={this.state.validationErrors} onSave={this.handleFormSaveClick} />
      </React.Fragment>
    );
  }
}

ExternalAccountUsersTab.defaultProps = {
  updateURL: null,
  reorderURL: null,
  servicesCheckListURL: null,
  statuspage: null,
  onNewData: null,
};
