'use strict';

import $ from 'jquery';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';

var MessageBox = {};

/**
 * Popup a bootstrap modal message box.
 * Only a single popup should be shown at a time.
 *
 * @param title The message box title
 * @param message The text to display.
 * @param htmlMessage The text to display, as valid HTML.
 * @param confirm If true, shows OK/Cancel buttons instead of Close.
 * @param onConfirm Callback called if the user confirms the action or closes the alert.
 * @param largeModal If true, shows a wide modal instead of a normal one.
 * @param extraButtons html to override existing buttons
 */
MessageBox.messageBox = function (opts) {
  opts = _.clone(opts);
  opts.title = opts.title || '';
  opts.message = opts.message || '';
  opts.htmlMessage = opts.htmlMessage ? Utils.escapeScripts(opts.htmlMessage) : '';
  opts.confirm = !!opts.confirm;
  opts.onConfirm = opts.onConfirm || null;
  opts.largeModal = !!opts.largeModal;
  opts.extraButtons = opts.extraButtons || null;

  var popup = $('#uptime-message-box');
  popup.find('.modal-title').html(opts.title);
  popup.find('.modal-dialog').toggleClass('modal-lg', opts.largeModal);

  if (opts.htmlMessage) {
    popup.find('.modal-body').html(opts.htmlMessage);
  } else {
    popup.find('.modal-body').text(opts.message);
  }

  if (opts.extraButtons) {
    popup.find('.modal-footer > button').toggle(false);
    popup.find('.modal-footer > .extra-buttons').html(opts.extraButtons);
  } else {
    popup.find('.modal-footer > .extra-buttons').html('');
    popup.find('.modal-footer > button[data-type=alert]').toggle(!opts.confirm);
    popup.find('.modal-footer > button[data-type=confirm]').toggle(opts.confirm);
    var eventButtons = popup.find('.modal-footer > button[data-event]');
    eventButtons.off('click');
    if (opts.onConfirm) {
      eventButtons.one('click', opts.onConfirm);
    }
  }

  popup.addClass('fade');
  popup.modal('show');
  popup.removeClass('fade');
  return popup;
};

/**
 * Programatically close the open message box.
 */
MessageBox.closeMessageBox = function () {
  $('#uptime-message-box').modal('hide');
};

/**
 * Popup a bootstrap modal alert.
 *
 * @param message The text to display.
 * @param title The message box title
 */
MessageBox.alertBox = function (message, title) {
  return MessageBox.messageBox({
    title: title,
    htmlMessage: message,
  });
};

/**
 * Popup a bootstrap modal confirm box.
 *
 * @param message The text to display.
 * @param title The message box title, may be omitted.
 * @param onConfirm The callback if the popup is confirmed.
 */
MessageBox.confirmBox = function (message, title, onConfirm) {
  if (!onConfirm && _.isFunction(title)) {
    onConfirm = title;
    title = '';
  }

  return MessageBox.messageBox({
    title: title,
    htmlMessage: message,
    confirm: true,
    onConfirm: onConfirm,
  });
};

/**
 * This function is supposed to go in your
 * else statement when checking for response.success from ajax endpoints.
 *
 * It parses responses from the applogic validation
 * (AjaxResult errors).
 *
 * @param response - The data from an AJAX XHR call
 */
MessageBox.validationErrorBox = function (response) {
  var message = '';

  $.each(response.fields, function (k, v) {
    message += '<li>' + v + '</li>';
  });

  message = '<p class="mb-2">' + response.error + '</p><ul class="list-spaced">' + message + '</ul>';

  return MessageBox.messageBox({
    title: 'An error occurred',
    htmlMessage: message,
  });
};

//****************************
//* EXPORTS
//****************************

export default MessageBox;
