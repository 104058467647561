'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import URLHistory from '../../jskit/general/URLHistory';
import MessageBox from '../../js/global/messagebox';
import StatusPageList from './StatusPageList.jsx';
import {InternalStatusPageFormModal} from './status_page_new_page_form/InternalStatusPageForm';
import {PublicStatusPageFormModal} from './status_page_new_page_form/PublicStatusPageForm';

export default class StatusPageController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      hasLoaded: false,
      items: [],
      validationErrors: {},
      goBackOnCancel: false,
      showNewStatusPageForm: false,
    };
  }

  componentDidMount() {
    const query = URLHistory.queryStringToObject();
    const pop = this.props.hasWriteAccess ? query.pop : null;
    const goBackOnCancel = query.gobackoncancel ? query.gobackoncancel === 'true' : false;
    this.setState({goBackOnCancel});
    URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['pop', 'gobackoncancel']));
    this.loadAllData(pop);
  }

  loadAllData(pop) {
    new Ajax().post({
      url: this.props.listURL,
      data: {page_type: this.props.pageType},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({hasLoaded: true, items: data.items});

        if (pop === 'initial') {
          this.handleAddItemClick();
        }
      }.bind(this),
    });
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleAddItemClick(e) {
    if (e) {
      e.preventDefault();
    }

    this.clearValidationErrors();
    this.setState({newStatusPageForm: true});
  }

  handleDuplicateItemClick(itemIndex, e) {
    const item = this.state.items[itemIndex];
    e.preventDefault();
    new Ajax().post({
      url: this.props.duplicateURL,
      data: {id: item.id},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (!data.success) {
          let error = 'Unable to duplicate status page. ';
          if (data.fields && data.fields.__all__) {
            error = error + ' ' + data.fields.__all__.join(' ');
          }
          MessageBox.alertBox(error, 'Duplicate Status Page');
        } else {
          window.location = data.data.manage_url + '?settings';
        }
      }.bind(this),
    });
  }

  handleDeleteItemClick(itemIndex, e) {
    e.preventDefault();

    const item = this.state.items[itemIndex];
    const msg = 'Are you sure you want to delete "' + item.name + '" including any subscribers to this page?';

    MessageBox.confirmBox(
      msg,
      'Delete Status Page',
      function () {
        new Ajax().post({
          url: this.props.deleteURL,
          data: {id: item.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (!data.success) {
              MessageBox.alertBox('Unable to delete status page.', 'Delete Status Page');
            } else {
              this.setState({newStatusPageForm: false});
              this.loadAllData();
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handlePublicStatusPageFormSave(formData) {
    formData.initial_components = formData.initial_components.filter((component) => component.name.trim().length > 0);
    this.statusPageFormSave(formData);
  }

  handleInternalStatusPageFormSave(formData) {
    this.statusPageFormSave(formData);
  }

  statusPageFormSave(formData) {
    formData.pageType = this.props.pageType;
    new Ajax().post({
      url: this.props.newURL,
      data: formData,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          window.location = data.data.manage_url + '?success';
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleCancelClick() {
    this.setState({newStatusPageForm: false});
    this.clearValidationErrors();
    if (this.state.goBackOnCancel) {
      setTimeout(() => history.back(), 300);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">{this.props.pageTypeDisplay}s</h1>
          {this.props.hasAdminAccess && (
            <button
              onClick={() => this.handleAddItemClick()}
              className="btn btn-primary"
              data-wizard="statuspages-add-new"
            >
              New {this.props.pageTypeDisplay}
            </button>
          )}
        </div>

        <StatusPageList
          hasWriteAccess={this.props.hasWriteAccess}
          hasAdminAccess={this.props.hasAdminAccess}
          hasLoaded={this.state.hasLoaded}
          siteURL={this.props.siteURL}
          pageType={this.props.pageType}
          items={this.state.items}
          onDuplicate={this.handleDuplicateItemClick}
          onDelete={this.handleDeleteItemClick}
        />
        {this.props.pageType === 'PUBLIC' ? (
          <PublicStatusPageFormModal
            show={this.state.newStatusPageForm}
            siteUrl={this.props.siteURL}
            publicViewUrl={this.props.publicViewURL}
            servicesCheckListUrl={this.props.servicesCheckListURL}
            onCancel={this.handleCancelClick}
            onSubmit={this.handlePublicStatusPageFormSave}
            errors={this.state.validationErrors}
          />
        ) : (
          <InternalStatusPageFormModal
            show={this.state.newStatusPageForm}
            siteUrl={this.props.siteURL}
            publicViewUrl={this.props.publicViewURL}
            servicesCheckListUrl={this.props.servicesCheckListURL}
            onCancel={this.handleCancelClick}
            onSubmit={this.handleInternalStatusPageFormSave}
            errors={this.state.validationErrors}
          />
        )}
      </React.Fragment>
    );
  }
}
