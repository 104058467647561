import DateRangePicker from '@/jskit/react/forms/DateRangePicker';
import DateRangeSnake from '@/status_pages/display/DateRangeSnake';
import {GlobalMetrics} from '@/status_pages/display/GlobalMetrics';
import moment from 'moment';
import {PdfReportDownloadButton} from '@/status_pages/display/components/PdfReportDownloadButton';
import {ComponentStatusValue, StatusPage} from '@/status_pages/types';

export interface LegacyHistoryHeaderProps {
  statuspage: StatusPage;
  historyStartDate: object;
  historyEndDate: object;
  historyStartFmt: string;
  historyEndFmt: string;
  handleHistoryDateRangeChange: (start, end) => void;
  hasLoaded: boolean;
  pdfUrl: string;
  componentStatusRank: Record<ComponentStatusValue, number>;
  handleDateRangeSnakeClick: (date: any, e: any) => void;
}

export const LegacyHistoryHeader = ({
  statuspage,
  historyStartDate,
  historyEndDate,
  historyStartFmt,
  historyEndFmt,
  handleHistoryDateRangeChange,
  hasLoaded,
  pdfUrl,
  componentStatusRank,
  handleDateRangeSnakeClick,
}: LegacyHistoryHeaderProps) => {
  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <div className="mb-3 mb-sm-0">
          <DateRangePicker
            timezone={window.TIMEZONE}
            enabledDateRanges="day0,day1,7d,30d,mon0,mon1,yr0,yr1"
            nullDateLabel=""
            minDate={
              statuspage.min_visible_date
                ? moment(statuspage.min_visible_date, 'YYYY-MM-DD').tz(statuspage.used_timezone)
                : false
            }
            maxDate={moment(statuspage.max_visible_date, 'YYYY-MM-DD').tz(statuspage.used_timezone)}
            startDate={historyStartDate}
            endDate={historyEndDate}
            onChange={handleHistoryDateRangeChange}
          />
        </div>
        {statuspage.allow_pdf_report && (
          <PdfReportDownloadButton historyStartFmt={historyStartFmt} historyEndFmt={historyEndFmt} pdfUrl={pdfUrl}>
            Download
          </PdfReportDownloadButton>
        )}
      </div>
      {hasLoaded ? (
        <DateRangeSnake
          statuspage={statuspage}
          componentStatusRank={componentStatusRank}
          historyStartDate={historyStartDate}
          historyEndDate={historyEndDate}
          onDateRangeSnakeClick={handleDateRangeSnakeClick}
        />
      ) : null}
      {statuspage.show_summary_metrics && hasLoaded ? <GlobalMetrics statuspage={statuspage} /> : null}
    </>
  );
};
