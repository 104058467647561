'use strict';

interface StatusDetail {
  description: string;
  status: string;
}

interface Status {
  description: string;
  image_major_version: string;
  image_minor_version: string;
  received_at: string;
  sent_at: string;
  status: string;
  details: Record<string, StatusDetail>;
}

export interface PrivateLocation {
  id: number;
  location: string;
  name: string;
  version: string;
  api_token: string | null;
  status: Status;
  num_services: number;
  editable: boolean;
  saveURL?: string;
}

export const STATUS_MAP = {
  check_alert_queue: {
    description: `Several alerts were not sent successfully to Uptime.com. This may indicate
      intermittent network issues. Restarting the container after network issues are resolved
      should resolve this warning.`,
    severity: 'WARNING',
  },
  check_perfdata_queue: {
    description: `Several metrics were not sent successfully to Uptime.com. This may indicate
      intermittent network issues. Restarting the container after network issues are resolved
      should resolve this warning.`,
    severity: 'WARNING',
  },
  check_hda1: {
    description: 'The system indicates the root filesystem may be low on disk space.',
    severity: 'WARNING',
  },
  check_mem: {
    description: `The system indicates available memory is low. This may negatively impact checks,
      particularly transaction and page speed checks.`,
    severity: 'WARNING',
  },
  check_nag: {
    description: 'The check scheduler process is not currently running or failed to start.',
    severity: 'ERROR',
  },
  check_nagios: {
    description: `No checks have been reported as run in the last 15 minutes. Please check
      whether any checks have been assigned to this location, and/or restart this private
      location container. If this error remains, please ensure your container has the
      NET_BIND_SERVICE capability and that the apache service within is running.`,
    severity: 'ERROR',
  },
  check_nagios_all: {
    description: `One or more scheduled checks are not running in a timely fashion.
      Please restart this private location container. If this error persists, please
      ensure your container has the NET_BIND_SERVICE capability and that the apache
      service within is running.`,
    severity: 'ERROR',
  },
  check_perfdata_log: {
    description: 'Response time data sync has failed to run or returned with error.',
    severity: 'ERROR',
  },
  check_reconfig_log: {
    description: `The private location has failed to check for and/or sync new configuration.
      Please check that your <code>UPTIME_API_TOKEN</code> setting is correct.`,
    severity: 'ERROR',
  },
  check_send_alert_errors: {
    description: 'An error occurred attempting to send alert data to Uptime.com.',
    severity: 'ERROR',
  },
  check_stalled_check_detection_log: {
    description: `The process to synchronize check states with the Uptime.com system failed
      to run or returned with error.`,
    severity: 'WARNING',
  },
  check_total_procs: {
    description: `The system is reporting a very high number of processes running concurrently.
      This may indicate a large number of zombie processes or some other resource cleanup issue.`,
    severity: 'WARNING',
  },
  check_txn_manager: {
    description: `The Chrome transaction check system reported errors. If this does not
      resolve shortly, or after a restart, please contact support for further investigation.`,
    severity: 'ERROR',
  },
  check_vmstat: {
    description: `The system is reporting high CPU use, IO wait time or CPU steal. This may
      indicate insufficient resources allocated for the container. You can use the
      <code>vmstat</code> command for further investigation.`,
    severity: 'WARNING',
  },
};
