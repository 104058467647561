'use strict';

import $ from 'jquery';
import React from 'react';
import ReactUtils from '../../../jskit/react/ReactUtils';
import Popper from 'popper.js';
import Utils from '../../../jskit/general/Utils';
import RadioGroup from '../../../jskit/react/forms/RadioGroup';

import {getBaselineComparison, renderBaselineComparison, truncate, getTooltip} from '../RumUtils.jsx';
import {checkFeatures} from '../CheckFeatures.js';

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.state = {
      sortPopupVisible: false,
      sortFeature: null,
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    $('body').on('click', this.handleBodyClick);
    if (this.props.sort && this.props.onSortChange) {
      this.props.onSortChange(this.props.sort.key, this.props.sort.dir);
    }
  }

  componentWillUnmount() {
    $('body').off('click', this.handleBodyClick);
  }

  handleBodyClick(e) {
    if (this.state.sortPopupVisible && !$.contains(this.tableRef.current, e.target)) {
      this.setState({sortPopupVisible: false});
    }
  }

  handleColumnClick(e, col) {
    e.stopPropagation();
    var th = e.currentTarget;
    this.setState({
      sortPopupVisible: true,
      sortFeature: col,
    });
    const sortPopup = document.querySelector('#SegTblSortPopup');
    var options = {
      placement: 'bottom-end',
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var popper = new Popper(th, sortPopup, options);
  }

  handleSortClick(e) {
    const el = e.currentTarget,
      val = el.value;

    this.setState({
      sortPopupVisible: false,
    });
    this.props.onSortChange(val, this.props.sort.dir);
  }

  handleDirClick(e) {
    const el = e.currentTarget,
      val = el.value;

    this.setState({
      sortPopupVisible: false,
    });
    if (this.state.sortFeature.key !== this.props.sort.key && this.state.sortFeature.deltaPct !== this.props.sort.key) {
      this.props.onSortChange(this.state.sortFeature.key, val);
    } else {
      this.props.onSortChange(this.props.sort.key, val);
    }
  }

  handleOpenURL(e) {
    const button = e.target.tagName === 'I' ? e.target.parentElement : e.target;
    const url = button.getAttribute('data-url');
    window.open(url, '_blank');
    e.stopPropagation(); // Don't do table drilldown.
    e.preventDefault(); // Don't refresh the page.
  }

  render() {
    const columns = [],
      rows = [];
    const maxPerCol = {}; // For time columns, ensure that units are consistent (ms vs. s).
    for (const [ix, feature] of Object.entries(this.props.def)) {
      for (const dataRow of this.props.data) {
        const thisMax = maxPerCol[feature.key];
        maxPerCol[feature.key] = thisMax === undefined ? dataRow[ix] : Math.max(dataRow[ix], thisMax);
      }
      let sort = null;
      if ([feature.key, feature.baseline, feature.deltaPct].includes(this.props.sort.key)) {
        const arrowClassName = `ml-2 fa fa-angle-${this.props.sort.dir === 'asc' ? 'up' : 'down'}`;
        sort = <span className={arrowClassName}></span>;
      }
      columns.push(
        <th key={`seg-th-${ix}`} onClick={(e) => this.handleColumnClick(e, feature)} title={getTooltip(feature)}>
          {feature.name}
          {sort}
        </th>
      );
    }
    for (const [ixRow, dataRow] of Object.entries(this.props.data)) {
      const cells = this.props.def.map((feature, ixCol) => {
        let colText, colTitle;
        if (feature.isDataFeature) {
          colText = renderBaselineComparison(
            getBaselineComparison(feature, dataRow, {maxValue: maxPerCol[feature.key]})
          );
        } else {
          if (feature.key === checkFeatures.country.key) {
            // Display country name instead fof ISO2 code.
            colText = dataRow['CountryName'] || dataRow[feature.key];
          } else {
            colTitle = dataRow[feature.key];
            colText = truncate(colTitle, 60);
          }
        }

        if (feature.drillDown) {
          return (
            <td key={`seg-td-${ixCol}`}>
              <a
                href=""
                onClick={(e) => {
                  this.props.onDrillDown(feature, dataRow);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {colText}
              </a>
            </td>
          );
        } else if (feature.key === checkFeatures.urlGroup.key) {
          const isGroup = !dataRow[feature.key].startsWith('http');
          return (
            <td key={`seg-td-${ixCol}`}>
              <a
                className="data-table-url-container"
                href=""
                onClick={(e) => {
                  this.props.onURLFilter(dataRow.URLGroupHash, dataRow.URLGroup);
                  e.stopPropagation();
                  e.preventDefault();
                }}
                title={colTitle}
              >
                <p className={isGroup ? '' : 'data-table-url'}>{colText}</p>
                {!isGroup && (
                  <button
                    type="button"
                    data-url={dataRow[feature.key]}
                    title="Open in new tab"
                    onClick={this.handleOpenURL.bind(this)}
                    className="btn open-url-button"
                  >
                    <i className="fas fa-external-link-alt" />
                  </button>
                )}
              </a>
            </td>
          );
        } else if (feature.key === checkFeatures.startURL.key) {
          return (
            <td key={`seg-td-${ixCol}`}>
              <a
                href=""
                onClick={(e) => {
                  this.props.onURLFilter(dataRow.StartURLHash, dataRow.URLGroup);
                  e.stopPropagation();
                  e.preventDefault();
                }}
                title={colTitle}
              >
                {colText}
              </a>
            </td>
          );
        }
        return <td key={`seg-td-${ixCol}`}>{colText}</td>;
      });
      rows.push(<tr key={`seg-tr-${ixRow}`}>{cells}</tr>);
    }
    return (
      <React.Fragment>
        {this.renderSortPopup()}
        <div key="seg-table" className="table-responsive">
          <table className="table data-table">
            <thead key="seg-thead">
              <tr>{columns}</tr>
            </thead>
            <tbody key="seg-tbody">{rows}</tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  renderSortPopup() {
    var sortChoices = [],
      dirChoices = [
        ['asc', 'Ascending'],
        ['desc', 'Descending'],
      ];
    if (this.state.sortFeature) {
      sortChoices.push([this.state.sortFeature.key, this.state.sortFeature.name]);
      if (this.state.sortFeature.isDataFeature) {
        sortChoices.push([this.state.sortFeature.deltaPct, 'Change Rate']);
      }
    }
    return (
      <div
        ref={this.tableRef}
        id="SegTblSortPopup"
        className={ReactUtils.cssClass('', {'d-none': !this.state.sortPopupVisible})}
      >
        <div>
          <h6>Sort by</h6>
          <RadioGroup choices={sortChoices} onChange={this.handleSortClick} value={this.props.sort.key} />
        </div>
        <div>
          <h6>Order</h6>
          <RadioGroup choices={dirChoices} onChange={this.handleDirClick} value={this.props.sort.dir} />
        </div>
      </div>
    );
  }
}
