import css from './InspireLogo.module.css';
export interface InspireLogoProps {
  name: string;
  logoUrl?: string;
  companyWebsiteUrl?: string;
}

export const InspireLogo = ({name, logoUrl, companyWebsiteUrl}: InspireLogoProps) => {
  let logo = null;
  if (logoUrl) {
    const logoImage = <img alt={name} src={logoUrl} className="img-responsive" />;
    if (companyWebsiteUrl) {
      logo = (
        <a href={companyWebsiteUrl} target="_blank">
          {logoImage}
        </a>
      );
    } else {
      logo = logoImage;
    }
  }
  return <div className={css.InspireLogoContainer}>{logo}</div>;
};
