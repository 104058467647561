import React from 'react';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors, FieldErrors, Label} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import TextArea from '../../jskit/react/forms/TextArea';
import Select2 from '../../jskit/react/forms/Select2';
import CheckBox from '../../jskit/react/forms/CheckBox';

import $ from 'jquery';

import FormWarning from '../../jskit/react/forms/FormWarning';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';

export default class IntegrationForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {},
      _oauth_complete: false,
      _oauth_error: null,
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialFormData) {
    if (initialFormData) {
      this.setState({formData: initialFormData});
    }

    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  switchIntegrationModule(module) {
    const formData = this.getFormData();
    const integrationDef = this.props.integrationDefs[module];
    const newState = {module: module, name: formData.name};
    const defaultContactGroups = this.props.contacts.default_contacts;
    if (integrationDef) {
      integrationDef.fields.forEach((field) => {
        if (field.initial !== undefined) {
          newState[field.name] = field.initial;
        }
      });
      newState['name'] = integrationDef['verbose_name'];
      newState['contact_groups'] = defaultContactGroups;
    }
    this.setState({
      formData: newState,
      _oauth_complete: false,
      _oauth_error: null,
    });
  }

  handleTypeChange(event) {
    const module = event.target.value;
    this.switchIntegrationModule(module);
  }

  handleOAuthAuthorizeClick(e) {
    e.preventDefault();

    const integrationDef = this.props.integrationDefs[this.getFormData().module];
    const url = this.props.oauthURL + '?target=' + integrationDef.oauth_process_url_name;

    window.oauthCompleteCallback = function (data) {
      this.setState({
        formData: Object.assign({}, this.getFormData(), data),
        _oauth_error: null,
        _oauth_complete: true,
      });
    }.bind(this);
    window.oauthErrorCallback = function (error) {
      this.setState({
        _oauth_error: error,
        _oauth_complete: false,
      });
    }.bind(this);

    window.open(
      url,
      'integration_oauth',
      'width=760,height=600,location=no,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,toolbar=no'
    );

    this.setState({_oauth_error: null, _oauth_complete: false});
  }

  shouldWarnAboutMissingContacts() {
    // true if no contacts were added
    const {formData} = this.state;
    const contactGroups = formData.contact_groups;
    return contactGroups && !contactGroups.length;
  }

  generateWarningList() {
    const warnings = [];
    if (this.shouldWarnAboutMissingContacts()) {
      warnings.push(
        'You will need to assign your integration to a Contact to receive ' + 'alerts & metrics from checks.'
      );
    }
    const data = this.getFormData();
    if (data.module && !data.id && !this.props.integrationDefs[data.module].prevent_creation) {
      warnings.push('Metric data (for relevant integrations) may take up to 30 minutes to initially appear.');
    }
    return warnings;
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this.getFormData();
    const integrationDef = formData.module ? this.props.integrationDefs[formData.module] : null;
    const allowEditing = formData.id || !integrationDef || !integrationDef.prevent_creation;
    const saveButton = allowEditing ? this.props.onSave : null;
    return (
      <Modal
        ref="modal"
        title={formData.id ? 'Edit Integration Profile' : 'Add Integration Profile'}
        size="lg"
        saveButton={saveButton}
        cancelButton={this.props.onCancel}
      >
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <input type="hidden" name="id" value={formData.id || ''} />
          <FormErrors errors={this.props.errors.__all__} />
          <div className="row">
            <div className="col-lg">{this.renderIntegrationType(formLink, formData)}</div>
            <div className="col-lg">{allowEditing ? this.renderIntegrationName(formLink, formData) : null}</div>
          </div>
          <div className="row">
            <div className="col-lg">{allowEditing ? this.renderIntegrationContacts(formLink, formData) : null}</div>
          </div>
          {this.renderIntegrationWarning(integrationDef)}
          {allowEditing ? this.renderOAuthButton(formLink, formData, integrationDef) : null}
          {allowEditing ? this.renderIntegrationFields(formLink, formData, integrationDef) : null}
          {this.renderIntegrationDescription(integrationDef)}
        </form>
        {this.renderFooterWarning()}
      </Modal>
    );
  }

  renderIntegrationType(formLink, formData) {
    const integrationTypes = this.props.integrationTypes || [];

    return (
      <Select2
        fieldName="module"
        labelText="Provider Type"
        isRequired={true}
        disabled={!!formData.id}
        choices={integrationTypes}
        formErrors={this.props.errors}
        value={formData.module || ''}
        onChange={this.handleTypeChange}
      />
    );
  }

  renderIntegrationName(formLink, formData) {
    if (!formData.module) {
      return null;
    }

    return (
      <TextInput
        fieldName="name"
        labelText="Name"
        isRequired={true}
        helpText="Your preferred name for this integration."
        formLink={formLink}
      />
    );
  }

  createContactHook(params) {
    const term = $.trim(params.term);

    if (term === '') {
      return null;
    }

    return {
      id: ':' + term,
      text: 'New contact: ' + term,
      newTag: true,
    };
  }

  insertTagHook(data, tag) {
    // Insert the tag at the end of the results
    data.push(tag);
  }

  renderIntegrationContacts(formLink, formData) {
    if (!formData.module) {
      return null;
    }

    const contactChoices = this.props.contacts.choices;

    return (
      <Select2
        fieldName="contact_groups"
        choices={contactChoices}
        labelText="Assign To Contacts"
        placeholder="Select Contacts"
        helpText="Assign your integration to a Contact to receive alerts & metrics from checks."
        isRequired={false}
        multiple={true}
        formLink={formLink}
        tags={true}
        createTag={this.createContactHook}
        insertTag={this.insertTagHook}
      />
    );
  }

  renderIntegrationFields(formLink, formData, integrationDef) {
    if (!integrationDef) {
      return null;
    }
    const fieldsToRender = integrationDef.fields.filter((f) => !f.hidden);

    const renderedFields = fieldsToRender.map((field) =>
      React.createElement(this.elementForFieldType(field), {
        key: field.name,
        fieldName: field.name,
        labelText: field.label,
        helpText: field.help_text,
        isRequired: field.required,
        choices: field.choices,
        formLink: formLink,
        inputType: field.password ? 'password' : undefined,
        disabled: field.disabled,
        readOnly: field.readonly,
        columnClass: field.widget_attrs.fullwidth ? 'col-lg-12' : null,
      })
    );

    const normalRenderedFields = renderedFields.filter((e) => e.type !== CheckBox);
    const checkboxRenderedFields = renderedFields.filter((e) => e.type === CheckBox);
    const defaultColumnClass = normalRenderedFields.length === 1 ? 'col-lg-12' : 'col-lg-6';

    return (
      <React.Fragment>
        <div className="row">
          {normalRenderedFields.map((e) => (
            <div key={e.props.fieldName + '-wrapper'} className={e.props.columnClass || defaultColumnClass}>
              {e}
            </div>
          ))}
        </div>
        {checkboxRenderedFields.length ? <div className="form-group">{checkboxRenderedFields}</div> : null}
      </React.Fragment>
    );
  }

  elementForFieldType(field) {
    switch (field.type) {
      case 'CharField':
        if (field.widget === 'Textarea') {
          return TextArea;
        } else {
          return TextInput;
        }
      case 'EmailField':
        return TextInput;
      case 'URLField':
        return TextInput;
      case 'IntegerField':
        return TextInput;
      case 'ChoiceField':
        return Select2;
      case 'BooleanField':
        return CheckBox;
    }

    throw 'No widget for field type ' + field.type + '.';
  }

  renderSupportLink(supportLink, verboseName) {
    if (!supportLink) {
      return false;
    }
    return (
      <p className="text-muted small">
        <ZendeskSupportLink href={supportLink}>
          <strong>View</strong>&nbsp;{verboseName}&nbsp;Support&nbsp;Article&nbsp;&rarr;
        </ZendeskSupportLink>
      </p>
    );
  }

  renderIntegrationWarning(integrationDef) {
    if (!integrationDef || !integrationDef.warning) {
      return null;
    }

    return <div className="alert alert-warning">{integrationDef.warning}</div>;
  }

  renderIntegrationDescription(integrationDef) {
    if (!integrationDef) {
      return null;
    }
    const verboseName = integrationDef.verbose_name;
    const supportLink = integrationDef.support_article || null;
    return (
      <div className="pt-4">
        <h5>
          <i className="fas fa-info-circle" /> Setup Info
        </h5>
        <p className="text-muted small" dangerouslySetInnerHTML={{__html: integrationDef.description}}></p>
        {this.renderSupportLink(supportLink, verboseName)}
      </div>
    );
  }

  renderOAuthButton(formLink, formData, integrationDef) {
    if (!integrationDef || !integrationDef.oauth_process_url_name) {
      return null;
    }

    const buttonClass = this.state._oauth_complete ? 'btn-success' : 'btn-info';
    const buttonText = this.state._oauth_complete ? 'Authorized!' : 'Authorize Now';

    return (
      <div className="form-group">
        <Label labelText="Authorize" isRequired={true} />
        <button type="button" className={'d-block btn ' + buttonClass} onClick={this.handleOAuthAuthorizeClick}>
          {buttonText}
        </button>
        <FieldErrors errors={this.state._oauth_error} />
      </div>
    );
  }

  renderFooterWarning() {
    const warnings = this.generateWarningList();
    return <FormWarning warnings={warnings} />;
  }
}
