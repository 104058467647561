'use strict';

import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import URLPatternsList from './URLPatternsList.jsx';
import URLPatternsForm from './URLPatternsForm.jsx';
import URLPatternsGroupForm from './URLPatternsGroupForm.jsx';
import MessageBox from '../../js/global/messagebox';

export default class URLPatternsController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      hasLoaded: false,
      groups: [],
      groupChoices: [],
      patterns: [],
      validationErrors: {},
      lastGroupIdSet: null,
    };
  }

  componentDidMount() {
    this.loadAllData();
  }

  loadAllData() {
    new Ajax().post({
      url: this.props.urls.list,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({
          hasLoaded: true,
          patterns: data.patterns,
          groups: data.groups,
          groupChoices: _.map(data.groups, function (g) {
            return [g.id, g.name];
          }),
        });
      }.bind(this),
    });
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleAddItemClick(e) {
    e.preventDefault();
    this.showEditForm(null);
  }

  handleEditItemClick(itemIndex, e) {
    e.preventDefault();
    this.showEditForm(itemIndex);
  }

  showEditForm(itemIndex) {
    var state;
    this.clearValidationErrors();

    if (null === itemIndex) {
      state = {
        group_id: this.state.lastGroupIdSet || '',
      };
    } else {
      state = Object.assign({}, this.state.patterns[itemIndex]);
      state.group_id = state.group_id || '';
    }

    this.refs.form.showModal(state);
  }

  handleDeleteItemClick(itemIndex, e) {
    e.preventDefault();
    const item = this.state.patterns[itemIndex];
    const msg = 'Are you sure you want to delete "' + item.pattern + '"?';

    MessageBox.confirmBox(
      msg,
      'Delete URL Prefix',
      function () {
        new Ajax().post({
          url: this.props.urls.delete,
          data: {id: item.id},
          encoder: 'json',
          decoder: 'json',
          success: function () {
            this.loadAllData();
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleFormSaveClick(e) {
    e.preventDefault();
    this.clearValidationErrors();
    this._saveURLPattern(this.refs.form.getFormData(), this.refs.form);
  }

  handleChangeGroupClick(itemIndex, e) {
    e.preventDefault();
    this.clearValidationErrors();

    var state = {
      id: this.state.patterns[itemIndex].id,
      group_id: this.state.patterns[itemIndex].group_id || this.state.lastGroupIdSet || '',
    };

    this.refs.groupForm.showModal(state);
  }

  handleDeleteGroupClick(itemIndex, e) {
    e.preventDefault();

    const item = this.state.patterns[itemIndex];
    const groupId = item.group.id;
    const groupName = item.group.name;
    let msg = 'Are you sure you want to delete the group "' + groupName + '"? ';
    msg += 'This will also unassociate any other patterns assigned to it.';

    MessageBox.confirmBox(
      msg,
      'Delete Group',
      function () {
        new Ajax().post({
          url: this.props.urls.delete_group,
          data: {id: groupId},
          encoder: 'json',
          decoder: 'json',
          success: function () {
            this.loadAllData();
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleGroupFormSaveClick(e) {
    e.preventDefault();

    this.clearValidationErrors();
    var state = this.refs.groupForm.getGroupIdAndName();

    if (state.name !== null) {
      this._createGroup(state.name, (data) => {
        this._saveURLPattern({id: state.pattern_id, group_id: data.id}, this.refs.groupForm);
      });
    } else {
      this._saveURLPattern({id: state.pattern_id, group_id: state.group_id}, this.refs.groupForm);
    }
  }

  _saveURLPattern(data, formToHide) {
    new Ajax().post({
      url: this.props.urls.save,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          formToHide.hideModal();
          this.setState({lastGroupIdSet: data.group_id || null});
          this.loadAllData();
        } else {
          data.fields.group_id = data.fields.group;
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  _createGroup(name, onSuccess) {
    new Ajax().post({
      url: this.props.urls.create_group,
      data: {name: name},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          onSuccess(data.data);
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">{this.props.title}</h1>
          <button onClick={this.handleAddItemClick} className="btn btn-primary">
            New URL Prefix
          </button>
        </div>
        <URLPatternsList
          hasLoaded={this.state.hasLoaded}
          items={this.state.patterns}
          onEditPattern={this.handleEditItemClick}
          onDeletePattern={this.handleDeleteItemClick}
          onChangeGroup={this.handleChangeGroupClick}
          onDeleteGroup={this.handleDeleteGroupClick}
        />
        <URLPatternsForm
          ref="form"
          groupChoices={this.state.groupChoices}
          errors={this.state.validationErrors}
          onSave={this.handleFormSaveClick}
        />
        <URLPatternsGroupForm
          ref="groupForm"
          groupChoices={this.state.groupChoices}
          errors={this.state.validationErrors}
          onSave={this.handleGroupFormSaveClick}
        />
      </React.Fragment>
    );
  }
}
