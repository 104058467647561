import React from 'react';
import Formatter from '../../jskit/general/Formatter';
import {CalculationType} from './Enums.jsx';
import {StatusPage, StatusPageTheme} from '@/status_pages/types';
import {LegacyGlobalMetrics} from '@/status_pages/display/components/theme-specific/legacy/LegacyGlobalMetrics';
import {InspireGlobalMetrics} from '@/status_pages/display/components/theme-specific/inspire/InspireGlobalMetrics';

export interface GlobalMetricsProps {
  statuspage: StatusPage;
}

export const GlobalMetrics = ({statuspage}: GlobalMetricsProps) => {
  let uptimePct, outageCnt, downtime;

  if (statuspage.global_metrics.uptime_pct === null || statuspage.global_metrics.uptime_pct === undefined) {
    uptimePct = outageCnt = downtime = 'n/a';
  } else {
    uptimePct = Formatter.percentage(statuspage.global_metrics.uptime_pct);
    outageCnt = statuspage.global_metrics.outages || 0;
    downtime = Formatter.shortDuration(statuspage.global_metrics.downtime_secs, {
      roundSeconds: statuspage.uptime_calculation_type === CalculationType.BY_INCIDENTS,
    });
  }

  if (statuspage.theme === StatusPageTheme.INSPIRE) {
    return <InspireGlobalMetrics uptimePct={uptimePct} outageCnt={outageCnt} downtime={downtime} />;
  } else {
    return <LegacyGlobalMetrics uptimePct={uptimePct} outageCnt={outageCnt} downtime={downtime} />;
  }
};
