import {StatusPage, StatusPageTheme} from '@/status_pages/types';
import {LegacyHeader} from '@/status_pages/display/components/theme-specific/legacy/LegacyHeader';
import {InspireHeader} from '@/status_pages/display/components/theme-specific/inspire/InspireHeader';
import {ReactNode} from 'react';

export interface StatusPageHeaderProps {
  statuspage: StatusPage;
  historyStartDate: object;
  toolbar: ReactNode;
}

export const StatusPageHeader = ({statuspage, historyStartDate, toolbar}: StatusPageHeaderProps) => {
  if (statuspage.theme === StatusPageTheme.INSPIRE) {
    return <InspireHeader statuspage={statuspage} historyStartDate={historyStartDate} toolbar={toolbar} />;
  }
  return <LegacyHeader statuspage={statuspage} toolbar={toolbar} />;
};
