import React from 'react';
import {HelpIcon} from '../HelpIcon';

export const FormLabel = ({
  formId,
  helpText,
  required,
  textStyle,
  children,
}: {
  formId?: string;
  helpText?: string;
  required?: boolean;
  textStyle?: React.CSSProperties;
  children: React.ReactNode;
}) => {
  return (
    <label htmlFor={formId} className={'form-control-label d-flex'}>
      <div style={textStyle}>{children}</div>
      {required && <span className="ml-2 text-danger">*</span>}
      {helpText && <HelpIcon helpText={helpText} />}
    </label>
  );
};
