import React from 'react';
import $ from 'jquery';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Select2 from '../../jskit/react/forms/Select2';
import {FieldHelpText, prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import {TaskStatus} from './TaskStatus.jsx';

export default class TestRunner extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {
        test_location: this.props.locations && this.props.locations.length ? this.props.locations[0].id : null,
      },
      isRunningTest: false,
      testStatus: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data === prevProps.data) {
      return;
    }
    this.resetRunTest();
    const formData = this.getFormData();
    const oldCheckType = prevProps.data.monitoring_service_type;
    const newCheckType = this.props.data.monitoring_service_type;
    const oldCheckVersion = prevProps.data.msp_version;
    const newCheckVersion = this.props.data.msp_version;
    if (
      this.props.locations &&
      this.props.locations.length &&
      (oldCheckType !== newCheckType || oldCheckVersion !== newCheckVersion)
    ) {
      formData.test_location = this.props.locations[0].id;
      this.setState({formData});
    }
  }

  getFormData() {
    return this.state.formData;
  }

  formatLocation(val) {
    const [name, addr] = val.text.split('~!~');
    return $(`
      <div class="font-14">
        <div>${name}</div>
        <div class="small text-muted">${addr || ''}</div>
      </div>
    `);
  }

  formatLocationResult(val) {
    return val.text.split('~!~')[0];
  }

  resetRunTest() {
    this.setState({
      isRunningTest: false,
      testStatus: null,
    });
  }

  handleRunTest(e) {
    e.preventDefault();
    this.resetRunTest();
    this.setState({
      isRunningTest: true,
    });
    if (this.props.onStart) {
      this.props.onStart();
    }
    const serviceData = _.clone(this.props.data) || {};
    if (serviceData.monitoring_groups && !Array.isArray(serviceData.monitoring_groups)) {
      serviceData.monitoring_groups = [serviceData.monitoring_groups];
    }
    const formData = this.getFormData();
    serviceData.test_location = formData.test_location;
    if (this.props.scriptGetter) {
      serviceData.msp_script = this.props.scriptGetter();
    }
    new Ajax().post({
      url: this.props.runTestURL,
      data: serviceData,
      encoder: 'json',
      decoder: 'json',
      success: function (result) {
        if (!result.success) {
          this.resetRunTest();
          if (this.props.onError) {
            this.props.onError(result);
          }
        } else if (!result.data.task_id) {
          // results of a test immeditelly available
          this.setState({
            isRunningTest: false,
          });
          if (this.props.onSuccess) {
            this.props.onSuccess(result.data);
          }
        } else {
          this.setState({
            isRunningTest: true,
          });
          this.loadTimer = 0;
          this.pollResults(result.data.task_id);
        }
      }.bind(this),
      error: function () {
        this.resetRunTest();
        if (this.props.onError) {
          this.props.onError({
            result: 'Probe server responded with an error.',
            status: TaskStatus.FAILED,
            serverId: this.getFormData().test_location,
          });
        }
      }.bind(this),
    });
  }

  _isPollingTimedOut() {
    if (this.state.testStatus === TaskStatus.NEW && this.loadTimer >= 10000) {
      return 'The test server queue is currently full, please try again later or try using a different test server.';
    }
    const mst = this.props.data.monitoring_service_type;
    let timeout = 61;
    if (['TRANSACTION', 'API'].includes(mst)) {
      timeout = 91;
    } else if (['PAGESPEED'].includes(mst)) {
      timeout = 181;
    }
    if (this.state.testStatus === TaskStatus.RUNNING) {
      if (this.loadTimer >= timeout * 1000) {
        return 'Probe server timed out.';
      }
    }
    return null;
  }

  pollResults(task_id) {
    if (!this.state.isRunningTest) {
      this.loadTimer = 0;
      return;
    }
    let timeout = this._isPollingTimedOut();
    if (timeout) {
      this.setState({
        testStatus: TaskStatus.FAILED,
        isRunningTest: false,
      });
      if (this.props.onError) {
        this.props.onError({
          result: timeout,
          status: TaskStatus.FAILED,
          serverId: this.getFormData().test_location,
        });
      }
      return;
    }

    new Ajax().get({
      url: this.props.runTestURL,
      data: {
        task_id: task_id,
      },
      decoder: 'json',
      success: function (result) {
        if (result.success) {
          this.setState({
            isRunningTest: result.data.status === TaskStatus.NEW || result.data.status === TaskStatus.RUNNING,
            testStatus: result.data.status,
          });
        } else {
          this.setState({
            testStatus: TaskStatus.FAILED,
            isRunningTest: false,
          });
        }
        if (this.state.isRunningTest) {
          this.loadTimer += 2000;
          setTimeout(this.pollResults.bind(this), 2000, task_id);
        } else {
          if (this.props.onSuccess) {
            this.props.onSuccess(result.data);
          }
        }
      }.bind(this),
      error: function (jqXHR, textStatus) {
        this.resetRunTest();
        if (this.props.onError) {
          this.props.onError({
            result: textStatus,
            status: TaskStatus.FAILED,
            serverId: this.getFormData().test_location,
          });
        }
      }.bind(this),
    });
  }

  render() {
    const formLink = prepareFormLink(this, 'formData');

    // Select2 only support (id, label) 2-tuples for choices, so we chain the server
    // name and location into a single string & break it apart in the template override functions
    const locationChoices = this.props.locations.map((srv) => [
      srv.id,
      `${srv.name}~!~${srv.is_private ? 'Private' : srv.location}`,
    ]);

    return (
      <div className="form-group">
        <div className="form-row">
          <div className={this.props.selectCSSClass}>
            <Select2
              formGroupClass="form-group mb-0"
              fieldName="test_location"
              choices={locationChoices}
              allowEmpty={false}
              templateResult={this.formatLocation}
              templateSelection={this.formatLocationResult}
              formLink={formLink}
            />
          </div>
          <div className={this.props.buttonCSSClass}>
            <button
              type="button"
              onClick={this.handleRunTest}
              disabled={this.state.isRunningTest}
              className="btn btn-outline-secondary"
              data-wizard="devices-testrunner-run-test"
            >
              Run Test
            </button>
            {this.state.isRunningTest && <i className="fas fa-lg fa-spinner fa-spin align-middle ml-3" />}
          </div>
        </div>
        <FieldHelpText helpText={this.props.helpText} />
      </div>
    );
  }
}

TestRunner.defaultProps = {
  locations: [], // list of locations to display in dropdown, **as MonitoringServer dicts**
  onStart: null, // handler to be called when test starts
  onSuccess: null, // handler to be called on successful execution
  onError: null, // handler to be called on error
  runTestURL: '', // URL to post to
  data: {}, // service data to be posted (usually - service form data)
  scriptGetter: null, // method to be called to retrieve API/Transaction script
  buttonCSSClass: 'col-auto', // CSS class on the Run Test button wrapper
  selectCSSClass: 'col-sm-4', // CSS class on the Select wrapper
};
