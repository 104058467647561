import React from 'react';
import Utils from '../../jskit/general/Utils';
import Formatter from '../../jskit/general/Formatter';
import TextInput from '../../jskit/react/forms/TextInput';

export default class ServiceSelectorInput extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {};
  }

  render() {
    const label = Formatter.pluralize(this.props.numChecks, 'check selected', 'checks selected');
    const value = this.props.numChecks > 0 ? `${this.props.numChecks} ${label}` : '';

    return (
      <TextInput
        {...this.props}
        value={value}
        extraInputCSS={this.props.numChecks ? 'font-weight-bold' : null}
        onChange={this.props.onShowDialog}
        onFocus={this.props.onShowDialog}
      />
    );
  }
}

ServiceSelectorInput.defaultProps = {
  numChecks: 0, // The number of checks currently selected
  placeholder: 'Select checks...', // Placeholder for the input box
  onShowDialog: null, // Event handler when input is click (selector dialog to be shown)

  // ... other props per TextInput (no formlink...)
};
