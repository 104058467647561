'use strict';

import React, {Fragment} from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import ResponseTimeChart from '../../jskit/react/ResponseTimeChart';
import Utils from '../../jskit/general/Utils';
import Formatter from '../../jskit/general/Formatter';
import * as ManageUtils from '../manage/ManageUtils.jsx';
import {CalculationType} from './Enums.jsx';

const ResponseTimes = ['RESPONSE_TIME', 'CUSTOM', 'RUM_LOAD_TIME'];

function _shouldDisplayResponseAvg(responseTimeVal, responseTimeType) {
  return responseTimeVal !== undefined && responseTimeVal !== null && ResponseTimes.includes(responseTimeType);
}

function _formatResponseTime(responseTimeVal, responseTimeType) {
  if (!_shouldDisplayResponseAvg(responseTimeVal, responseTimeType)) {
    return 'n/a';
  }
  return Formatter.autoDuration(responseTimeVal);
}

export default class Component extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      isOpen: false,
    };
  }

  handleComponentOpenClose(e) {
    e.preventDefault();
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {
    const component = this.props.component;
    let subcomponentGroup = null;

    if (component.is_group) {
      const subcomponents = component.subcomponents.map((subcomponent, j) => (
        <Component
          key={j}
          component={subcomponent}
          allowDrillDown={this.props.allowDrillDown}
          showHistory={this.props.showHistory}
          componentStatusRank={this.props.componentStatusRank}
          componentStatusChoices={this.props.componentStatusChoices}
          showResponseTime={this.props.showResponseTime}
          historyTypeDisplay={this.props.historyTypeDisplay}
          historyStartDate={this.props.historyStartDate}
          historyEndDate={this.props.historyEndDate}
        />
      ));

      subcomponentGroup = (
        <div key={component.id + '-sub'} className="sub-component-list">
          {subcomponents}
        </div>
      );
    }

    return (
      <React.Fragment>
        <div
          className={ReactUtils.cssClass('component', {
            ['component-group']: component.is_group,
            open: this.state.isOpen,
          })}
        >
          {this.props.showHistory ? null : (
            <div
              className={ReactUtils.cssClass(
                'indicator',
                ManageUtils.getComponentStatus(component, this.props.componentStatusRank)
              )}
            />
          )}
          {component.is_group ? <div className="group-expander" onClick={this.handleComponentOpenClose} /> : null}
          <div className="row align-items-center">
            {this.props.showHistory ? this.renderHistory(component) : this.renderStatus(component)}
          </div>
        </div>
        {subcomponentGroup}
      </React.Fragment>
    );
  }

  renderStatus(component) {
    return (
      <React.Fragment>
        <div
          className={this.props.showResponseTime ? 'col-7' : 'col-9'}
          onClick={component.is_group ? this.handleComponentOpenClose : null}
        >
          {this.renderName(component)}
        </div>
        {this.props.showResponseTime ? (
          <div className="col-2">{component.is_group ? null : this.renderCheckResponse(component)}</div>
        ) : null}
        <div className="col-3">
          <span
            className={ReactUtils.cssClass(
              'status-icon',
              ManageUtils.getComponentStatus(component, this.props.componentStatusRank)
            )}
          >
            {ManageUtils.getComponentStatusDisplay(
              component,
              this.props.componentStatusChoices,
              this.props.componentStatusRank
            )}
          </span>
        </div>
      </React.Fragment>
    );
  }

  renderHistory(component) {
    const {historyTypeDisplay} = this.props;
    if (historyTypeDisplay === CalculationType.BY_CHECKS) {
      return this.renderCheckBasedHistory(component);
    } else {
      return this.renderIncidentBasedHistory(component);
    }
  }

  renderCheckBasedHistory(component) {
    const {history} = component;
    let uptimePct = history.uptime_pct !== undefined ? history.uptime_pct : null,
      outages = history.outages !== undefined ? history.outages : null,
      downtimeSecs = history.downtime_secs !== undefined ? history.downtime_secs : null,
      // eslint-disable-next-line prefer-const
      responseTimeAvg = history.response_time_avg !== undefined ? history.response_time_avg : null,
      // eslint-disable-next-line prefer-const
      responseTimeType = history.performance_data_type;
    if (component.is_group) {
      const subcomponents = component.subcomponents;
      const withUptimePct = subcomponents.filter((el) => el.history.uptime_pct !== undefined);
      uptimePct = withUptimePct.length
        ? withUptimePct.reduce((a, el) => a + el.history.uptime_pct, 0) / withUptimePct.length
        : null;
      const withOutages = subcomponents.filter((el) => el.history.outages !== undefined);
      outages = withOutages.length ? withOutages.reduce((a, el) => a + (el.history.outages || 0), 0) : null;
      const withDowntimeSecs = subcomponents.filter((el) => el.history.downtime_secs !== undefined);
      downtimeSecs = withDowntimeSecs.length
        ? withDowntimeSecs.reduce((a, el) => a + (el.history.downtime_secs || 0), 0)
        : null;
    }
    return (
      <React.Fragment>
        <div className="col-4" onClick={component.is_group ? this.handleComponentOpenClose : null}>
          {this.renderName(component, true)}
        </div>
        <div
          className={
            'col-2 text-center font-weight-bold text-' +
            Formatter.uptimePercentageCSSClass(uptimePct, component.service_uptime_sla)
          }
        >
          {uptimePct !== null ? Formatter.percentage(uptimePct) : null}
        </div>
        <div className="col-2 text-center">{outages !== null ? outages : 'n/a'}</div>
        <div className="col-2 text-center">
          {downtimeSecs !== null ? Formatter.shortDuration(downtimeSecs || 0) : 'n/a'}
        </div>
        <div className="col-2 text-center">
          {component.is_group ? '' : _formatResponseTime(responseTimeAvg, responseTimeType)}
        </div>
      </React.Fragment>
    );
  }

  renderIncidentBasedHistory(component) {
    const {history} = component;
    let majorOutages = history.major_outage || 0,
      partialOutages = history.partial_outage || 0,
      degradedPerformance = history.degraded_performance || 0,
      // eslint-disable-next-line prefer-const
      responseTimeAvg = history.response_time_avg !== undefined ? history.response_time_avg : null,
      // eslint-disable-next-line prefer-const
      responseTimeType = history.performance_data_type;

    if (component.is_group) {
      const subcomponents = component.subcomponents;
      majorOutages = subcomponents.reduce((a, el) => a + (el.history.major_outage || 0), 0);
      partialOutages = subcomponents.reduce((a, el) => a + (el.history.partial_outage || 0), 0);
      degradedPerformance = subcomponents.reduce((a, el) => a + (el.history.degraded_performance || 0), 0);
    }

    return (
      <React.Fragment>
        <div className="col-4" onClick={component.is_group ? this.handleComponentOpenClose : null}>
          {this.renderName(component, true)}
        </div>
        <div className="col-2 text-center">{majorOutages}</div>
        <div className="col-2 text-center">{partialOutages}</div>
        <div className="col-2 text-center">{degradedPerformance}</div>
        <div className="col-2 text-center">
          {component.is_group ? '' : _formatResponseTime(responseTimeAvg, responseTimeType)}
        </div>
      </React.Fragment>
    );
  }

  renderName(component, withDates) {
    let qs = '';
    if (withDates && !component.isGroup && this.props.historyStartDate) {
      qs = `?start=${this.props.historyStartDate.format('YYYYMMDD')}&end=${this.props.historyEndDate.format(
        'YYYYMMDD'
      )}`;
    }

    let name;
    if (!component.is_group && component.service_url && this.props.allowDrillDown) {
      name = <a href={component.service_url + qs}>{component.name}</a>;
    } else {
      name = <span>{component.name}</span>;
    }

    let description = null;
    if (component.description) {
      description = <small>{component.description}</small>;
    }

    return (
      <div className="component-name d-inline-block text-truncate text-wrap">
        {name}
        {description}
      </div>
    );
  }

  renderCheckResponse(component) {
    switch (component.performance_data_type) {
      case 'DAYS_TO_EXPIRY':
        return this.renderDaysRemaining(component);
      default:
        return this.renderResponseTimeGraph(component);
    }
  }

  renderResponseTimeGraph(component) {
    if (component.response_time_data && component.response_time_data.length) {
      return (
        <div className="my-n2">
          <ResponseTimeChart width={95} height={36} data={component.response_time_data} />
        </div>
      );
    }
  }

  renderDaysRemaining(component) {
    if (component.cached_response_time <= 0) return <Fragment />;
    const daysRemaining = component.cached_response_time.toFixed(0).toString();
    return <Fragment>Expires: {daysRemaining} days</Fragment>;
  }
}

Component.defaultProps = {
  component: null,
  componentStatusRank: null,
  historyTypeDisplay: null,
  allowDrillDown: false,
  showResponseTime: false,
  showHistory: false,
  historyStartDate: null,
  historyEndDate: null,
};
