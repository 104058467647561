import React from 'react';
import _ from 'underscore';

class AccountUserRow extends React.Component {
  render() {
    let activateAction = null;

    if (!this.props.is_primary) {
      let resendInviteBtn = null;
      if (!this.props.last_login) {
        resendInviteBtn = (
          <a href="#" className="dropdown-item" onClick={this.props.onResendInvite}>
            Resend Invite
          </a>
        );
      }

      activateAction = (
        <React.Fragment>
          {resendInviteBtn}
          <a href="#" className="dropdown-item" onClick={this.props.onActivate.bind(null, !this.props.is_active)}>
            {this.props.is_active ? 'De-activate' : 'Re-activate'}
          </a>
          <a href="#" className="dropdown-item" onClick={this.props.onDelete}>
            Delete
          </a>
        </React.Fragment>
      );
    }

    const sso_usernames = this.props.sso_user_bindings.map(function (v, i) {
      return (
        <small key={i} className="text-muted">
          {v.type_display}: {v.sso_username}
          <br />
        </small>
      );
    });

    let subaccounts = null;
    if (this.props.includeSubaccounts) {
      if (this.props.access_level >= '50-ADMIN' || this.props.assigned_subaccounts.length === 0) {
        subaccounts = <em>All Subaccounts</em>;
      } else {
        subaccounts = _.pluck(this.props.assigned_subaccounts, 'name');
        subaccounts = _.map(subaccounts, function (v, i) {
          return (
            <span key={i}>
              {v}
              <br />
            </span>
          );
        });
      }
    }

    return (
      <tr>
        <td>
          {this.props.first_name} {this.props.last_name}
        </td>
        <td className="text-center">
          {this.props.is_active ? (
            <i className="text-success fas fa-check" />
          ) : (
            <i className="text-danger fas fa-times" />
          )}
        </td>
        <td>{this.props.is_primary ? 'Owner' : this.props.access_level_display}</td>
        {this.props.hasCanPurchaseFeature && (
          <td className="text-center" style={{opacity: 0.33}}>
            {this.props.has_purchase_permission ? (
              <i className="text-success fas fa-check-circle" />
            ) : (
              <i className="text-danger fas fa-minus-circle" />
            )}
          </td>
        )}
        <td className="text-center" style={{opacity: 0.33}}>
          {this.props.is_api_enabled ? (
            <i className="text-success fas fa-check-circle" />
          ) : (
            <i className="text-danger fas fa-minus-circle" />
          )}
        </td>
        <td className="text-center" style={{opacity: 0.33}}>
          {this.props.has_feature_mobile_api_access ? (
            <i className="text-success fas fa-check-circle" />
          ) : (
            <i className="text-danger fas fa-minus-circle" />
          )}
        </td>
        <td>{this.props.email}</td>
        <td>
          {this.props.username}
          <br />
          {sso_usernames}
        </td>
        <td>
          {this.props.must_two_factor ? 'Yes' : 'No'}{' '}
          {this.props.require_two_factor !== 'ACCOUNT_DEFAULT' ? '(override)' : ''}
        </td>
        {subaccounts ? <td>{subaccounts}</td> : null}
        <td className="actions-right">
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-xs dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></button>
            <div className="dropdown-menu dropdown-menu-right">
              <a href="#" className="dropdown-item" onClick={this.props.onEdit}>
                Edit
              </a>
              {this.props.has_two_factor_device === true || !this.props.is_primary ? (
                <div className="dropdown-divider"></div>
              ) : null}
              {this.props.has_two_factor_device === true && (
                <a href="#" className="dropdown-item" onClick={this.props.onResetTwoFactor}>
                  Reset 2FA
                </a>
              )}
              {activateAction}
            </div>
          </div>
        </td>
      </tr>
    );
  }

  renderItemList(list) {
    const listItems = list.map(function (v) {
      return <li key={v}>{v}</li>;
    });

    return (
      <ul className="list-unstyled" style={{margin: 0}}>
        {listItems}
      </ul>
    );
  }
}

export default class AccountUserList extends React.Component {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return <div>No users found.</div>;
    }

    const nodes = this.props.items.map(
      function (item, index) {
        return (
          <AccountUserRow
            {...item}
            key={item.id}
            includeSubaccounts={this.props.includeSubaccounts}
            onEdit={this.props.onEdit.bind(null, index)}
            onActivate={this.props.onActivate.bind(null, index)}
            onDelete={this.props.onDelete.bind(null, index)}
            onResendInvite={this.props.onResendInvite.bind(null, index)}
            onResetTwoFactor={this.props.onResetTwoFactor.bind(null, index)}
            hasCanPurchaseFeature={this.props.hasCanPurchaseFeature}
          />
        );
      }.bind(this)
    );

    return (
      <div className="white-block white-block-border mb-4">
        <table className="table table-responsive-md data-table mb-0" role="grid">
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-center">Active</th>
              <th>Access Level</th>
              {this.props.hasCanPurchaseFeature && <th>Can Purchase</th>}
              <th className="text-center">API</th>
              <th className="text-center">Mobile App</th>
              <th>Email</th>
              <th>Username</th>
              <th>2FA Required</th>
              {this.props.includeSubaccounts ? <th>Subaccounts</th> : null}
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }
}
