import React from 'react';
import Utils from '../../jskit/general/Utils';
import totp from '../../jskit/general/Totp';
import TextInput from '../../jskit/react/forms/TextInput';
import URLInput from '../../jskit/react/forms/URLInput';
import ShowPassword from '../../jskit/react/forms/ShowPassword';
import TextArea from '../../jskit/react/forms/TextArea';
import CheckBox from '../../jskit/react/forms/CheckBox';
import SelectBox from '../../jskit/react/forms/SelectBox';
import HTMLElementListInput from './HTMLElementListInput';
import TransactionUtils from '../TransactionUtils';

import $ from 'jquery';

const MULTIPART_CONTENT_TYPES = ['multipart/form-data'];
const MULTIPART_FIELD_TYPES = ['form', 'files'];

export default class EditStepForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.selectingField = null;
  }

  componentDidMount() {
    var elem = $(this.refs.formContainer).find('input, select, textarea').first();
    if (elem.length && !elem.val()) {
      elem.focus();
    }
  }

  handleFieldChange(e) {
    var val = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.props.onUpdateSelectedStepField(e.target.name, val);
  }

  render() {
    return (
      <div ref="formContainer" className="tab-content bordered">
        {this.renderFields()}
      </div>
    );
  }

  handleSelectElementClick(e, field) {
    this.selectingField = field;
    this.props.onSelectElement(this.props.step, field, this.props.onSelectElementCompleted);
  }

  handleOpenURLClick(e, field) {
    this.props.onOpenURL(this.props.step.values[field.name]);
  }

  handleGenerateOTPClick(e) {
    e.preventDefault();
    const secret = this.props.step.values.totp_secret,
      digits = parseInt(this.props.step.values.totp_digits) || 6,
      period = parseInt(this.props.step.values.totp_period) || 30;
    try {
      let otp = totp(secret, period, digits);
      //insert space after every 3 digits
      otp.code = otp.code.replace(/(.{3})/g, '$1 ').trim();
      Utils.copyToClipboard(
        otp.code,
        'Copy OTP to clipboard: Ctrl+c, Enter. Expires in ' + otp.expires_in + ' seconds.'
      );
    } catch (e) {
      window.alert('Error generating OTP. Check the secret and try again.');
      console.error('OTP error', e);
    }
  }

  renderFields() {
    return this.props.step.fields.map((field) => {
      // Based on Content type hide or show the data or form elements
      if (MULTIPART_CONTENT_TYPES.includes(this.props.step.values['content_type']) && field.name == 'data') {
        this.props.step.values['data'] = '';
        return;
      } else if (
        !MULTIPART_CONTENT_TYPES.includes(this.props.step.values['content_type']) &&
        MULTIPART_FIELD_TYPES.includes(field.name)
      ) {
        this.props.step.values[field.name] = '';
        return;
      }

      let label = field.label;
      if (this.props.step.step_def == 'C_SET_VARIABLE') {
        const var_type = this.props.step.values.type;
        if (field.name == 'options') {
          if (var_type == 'const') {
            label = 'Value';
          } else if (var_type == 'random') {
            label = 'Specifier';
          } else if (var_type == 'date') {
            label = 'Format';
          } else if (var_type == 'element') {
            label = 'Element Selector';
          } else if (var_type == 'attr') {
            label = 'Element Selector';
          }
        }

        if (field.name == 'options2') {
          if (var_type == 'date') {
            label = 'Adjustment in seconds (optional)';
          } else if (var_type == 'attr') {
            label = 'Attribute Name';
          } else {
            return null;
          }
        }
      }

      let element = React.createElement(this.elementForFieldType(this.props.step, field), {
        key: field.name,
        fieldName: field.name,
        labelText: label,
        titleText: field.title,
        isRequired: !field.optional,
        value: this.props.step.values[field.name],
        formErrors: this.props.step.errors,
        onChange: this.handleFieldChange,
        htmlElementList: this.props.htmlElementList,
        choices: field.choices,
        allowVariables: field.type == 'URL' ? true : null,
      });

      if (element.type === CheckBox) {
        return (
          <div key={field.name + '-wrapper'} className="form-group">
            {element}
          </div>
        );
      } else {
        if (field.name == 'totp_secret') {
          element = React.createElement(TextInput, {
            key: field.name,
            fieldName: field.name,
            labelText: label,
            titleText: field.title,
            isRequired: !field.optional,
            value: this.props.step.values[field.name],
            formErrors: this.props.step.errors,
            onChange: this.handleFieldChange,
            addonLabel: 'Get Code',
            addonClass: 'btn-outline-primary',
            onAddonClick: (e) => this.handleGenerateOTPClick(e, field),
            httpsOnly: true,
            allowVariables: true,
          });
        } else if (this.props.onOpenURL && field.type == 'URL') {
          element = React.createElement(URLInput, {
            key: field.name,
            fieldName: field.name,
            labelText: label,
            titleText: field.title,
            isRequired: !field.optional,
            value: this.props.step.values[field.name],
            formErrors: this.props.step.errors,
            onChange: this.handleFieldChange,
            addonLabel: 'Go',
            addonClass: 'btn-outline-primary',
            onAddonClick: (e) => this.handleOpenURLClick(e, field),
            httpsOnly: true,
            allowVariables: true,
          });
        } else if (this.props.onSelectElement && field.type == 'SELECTOR') {
          element = React.createElement(TextInput, {
            key: field.name,
            fieldName: field.name,
            labelText: label,
            titleText: field.title,
            isRequired: !field.optional,
            value: this.props.step.values[field.name],
            formErrors: this.props.step.errors,
            onChange: this.handleFieldChange,
            addonLabel: (
              <React.Fragment>
                <i className="fal fa-crosshairs" /> Select on page
              </React.Fragment>
            ),
            addonClass: 'btn-outline-primary',
            onAddonClick: (e) => this.handleSelectElementClick(e, field),
          });
        }
        return element;
      }
    });
  }

  elementForFieldType(step, field) {
    if (TransactionUtils.isSecretTextField(step, field)) {
      return ShowPassword;
    }

    switch (field.type) {
      case 'URL':
        return URLInput;
      case 'SELECTOR':
        return HTMLElementListInput;
      case 'KEY_VALUE':
        return TextArea;
      case 'REGEX':
        return TextInput;
      case 'VARIABLE':
        return TextInput;
      case 'TEXTINPUT':
        return TextInput;
      case 'PASSWORD':
        return ShowPassword;
      case 'TEXTAREA':
        return TextArea;
      case 'BOOLEAN':
        return CheckBox;
      case 'CHOICE':
        return SelectBox;
    }
  }
}

EditStepForm.defaultProps = {
  step: {},
  htmlElementList: null,
};
