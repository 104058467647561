'use strict';

import React from 'react';
import _ from 'underscore';
import Utils from '../jskit/general/Utils';
import Ajax from '../jskit/general/Ajax';
import CreateChecksBar from './CreateChecksBar.jsx';
import ProgressBar from './ProgressBar.jsx';
import ResultsTable from './ResultsTable.jsx';

export default class DomainHealthController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      isError: false,
      isComplete: false,
      checkGroups: [],
      results: [],
      resultsByGroup: {},
      headerData: [],
      progressBarVisible: null,

      isCreatingChecks: false,
      selectedChecks: {},
      freeTrialPeriod: 0,
      currentNumServices: 0,
      currentServiceLimit: 0,
    };
    this.loadTimer = 0;
  }

  componentDidMount() {
    this.loadAllData();
  }

  updateResultsWithNewData(data, isPartial) {
    if (isPartial) {
      data.results = this.state.results.concat(data.results);
    }

    const isComplete = data.is_complete;
    const checkGroups = data.check_groups || this.state.checkGroups;
    const resultsByGroup = _.groupBy(data.results, 'group');

    if (this.state.progressBarVisible === null) {
      this.state.progressBarVisible = !isComplete;
    }

    this.setState({
      isError: data.is_error,
      isComplete: isComplete,
      checkGroups: checkGroups,
      results: data.results,
      resultsByGroup: resultsByGroup,
      headerData: this.parseHeaderData(checkGroups, resultsByGroup),
      freeTrialPeriod: data.free_trial_period || this.state.freeTrialPeriod,
      currentNumServices: data.current_num_services || this.state.currentNumServices,
      currentServiceLimit: data.current_service_limit || this.state.currentServiceLimit,
    });

    if (!isComplete) {
      var lastResultId = data.results.length ? data.results[data.results.length - 1].id : -1;
      if (this.loadTimer >= 120000) {
        this.setState({isError: true, isComplete: true}); // error
        return;
      }
      this.loadTimer += 2500;
      setTimeout(this.loadAllData.bind(this, lastResultId), 2500);
    } else {
      setTimeout(
        function () {
          this.setState({progressBarVisible: false});
        }.bind(this),
        1000
      );

      // Determine which checks are selected for Add Checks mode
      var sel = {};
      _.each(data.results, function (res) {
        if (!res.has_monitoring_params) {
          sel[res.id] = null;
        } else {
          sel[res.id] = res.state === 'OK';
        }
      });
      this.setState({selectedChecks: sel});
    }
  }

  parseHeaderData(checkGroups, resultsByGroup) {
    return checkGroups.map((g) => {
      const counts = _.countBy(resultsByGroup[g.key], 'state');
      return Object.assign({}, g, {
        results: {
          CRITICAL: (counts['CRITICAL'] || 0) + (counts['UNKNOWN'] || 0),
          WARNING: counts['WARNING'] || 0,
          OK: counts['OK'] || 0,
        },
      });
    });
  }

  loadAllData(afterId) {
    new Ajax().get({
      url: this.props.resultsURL,
      data: {
        after_id: afterId || '',
      },
      encoder: 'url',
      decoder: 'json',
      success: function (data) {
        this.updateResultsWithNewData(data, !!afterId);
      }.bind(this),
    });
  }

  isAddingChecks() {
    return this.state.isComplete && !this.state.progressBarVisible && this.props.hasWriteAccess;
  }

  numChecksSelected() {
    return _.filter(this.state.selectedChecks, (v) => !!v).length;
  }

  handleSelectAll(e) {
    e.preventDefault();
    this.state.selectedChecks = _.mapObject(this.state.selectedChecks, (v) => (v === null ? null : true));
    this.setState({selectedChecks: this.state.selectedChecks});
  }

  handleSelectNone(e) {
    e.preventDefault();
    this.state.selectedChecks = _.mapObject(this.state.selectedChecks, (v) => (v === null ? null : false));
    this.setState({selectedChecks: this.state.selectedChecks});
  }

  handleSelectedCheckToggle(resultId) {
    this.state.selectedChecks[resultId] = !this.state.selectedChecks[resultId];
    this.setState({selectedChecks: this.state.selectedChecks});
  }

  listSelectedChecks() {
    var selectedChecks = _.pick(this.state.selectedChecks, (v) => !!v);
    selectedChecks = _.keys(selectedChecks);
    selectedChecks = _.map(selectedChecks, (v) => parseInt(v));
    return selectedChecks;
  }

  handleAddChecksSignUp() {
    var selectedChecks = this.listSelectedChecks();

    this.setState({isCreatingChecks: true});

    new Ajax().post({
      url: this.props.signUpURL,
      data: {
        selected_checks: selectedChecks,
      },
      encoder: 'json',
      success: function (data) {
        window.location = data.url;
      }.bind(this),
    });
  }

  handleAddChecksCreateChecks() {
    var selectedChecks = this.listSelectedChecks();

    this.setState({isCreatingChecks: true});

    new Ajax().post({
      url: this.props.createServicesURL,
      data: {
        selected_checks: selectedChecks,
      },
      encoder: 'json',
      success: function (data) {
        window.location = data.url;
      }.bind(this),
    });
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderResults()}
      </div>
    );
  }

  renderHeader() {
    if (this.props.suppressHeadingBoxes) {
      return null;
    }

    return (
      <div className="mb-5">
        <div className="row">{this.state.headerData.map(this.renderHeaderPanel.bind(this))}</div>
      </div>
    );
  }

  renderHeaderPanel(group) {
    return (
      <div className="userpanel-panel-block col-sm-6 col-lg-3 mb-3 mb-lg-0" key={group.key}>
        <div className="white-block border-color-primary">
          <div className="d-flex align-items-center py-3 px-3">
            <i className={group.icon + ' fa-lg text-primary mr-2'} />
            <h5 className="mb-0" style={{marginTop: '1px'}}>
              {group.name}
            </h5>
          </div>
          <div className="pt-2 pb-4 px-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="font-14 font-weight-semibold">Errors</div>
              <div>
                <span className="badge badge-danger">{group.results.CRITICAL}</span>
              </div>
            </div>
            <div className="pt-2 pb-1" />
            <div className="d-flex justify-content-between align-items-center">
              <div className="font-14 font-weight-semibold">Warnings</div>
              <div>
                <span className="badge badge-warning">{group.results.WARNING}</span>
              </div>
            </div>
            <div className="pt-2 pb-1" />
            <div className="d-flex justify-content-between align-items-center">
              <div className="font-14 font-weight-semibold">Passed</div>
              <div>
                <span className="badge badge-success">{group.results.OK}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderResults() {
    var resultsTables = this.state.checkGroups.map(
      function (group) {
        var results = this.state.resultsByGroup[group.key] || [];
        return (
          <ResultsTable
            key={group.key}
            ref={'results-' + group.key}
            group={group}
            results={results}
            selectedChecks={this.state.selectedChecks}
            statusPagesURL={this.props.statusPagesURL}
            isAddingChecks={this.isAddingChecks()}
            onSelectedCheckToggle={this.handleSelectedCheckToggle}
          />
        );
      }.bind(this)
    );

    var progressBar = null;
    if (this.state.progressBarVisible === true) {
      progressBar = <ProgressBar ref="progressBar" results={this.state.results} isComplete={this.state.isComplete} />;
    }
    var showError = null;
    if (this.state.isError) {
      showError = (
        <div className="alert alert-danger mb-5" role="alert">
          There was an error running this test. Please try again later.
        </div>
      );
    }

    return (
      <div>
        {showError}
        {progressBar}

        <CreateChecksBar
          onSignUp={this.handleAddChecksSignUp}
          onCreateChecks={this.handleAddChecksCreateChecks}
          onSelectAll={this.handleSelectAll}
          onSelectNone={this.handleSelectNone}
          isVisible={this.isAddingChecks()}
          isCreatingChecks={this.state.isCreatingChecks}
          numChecksSelected={this.numChecksSelected()}
          currentNumServices={this.state.currentNumServices}
          currentServiceLimit={this.state.currentServiceLimit}
          freeTrialPeriod={this.state.freeTrialPeriod}
        />

        <div ref="resultsTables" className="my-5">
          {resultsTables}
        </div>
      </div>
    );
  }
}
