import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import URLHistory from '../../jskit/general/URLHistory';
import IntegrationList from './IntegrationList.jsx';
import IntegrationForm from './IntegrationForm.jsx';
import IntegrationErrorLogModal from './IntegrationErrorLogModal.jsx';
import IntegrationTestModal from './IntegrationTestModal.jsx';
import MessageBox from '../../js/global/messagebox';

function getIndexBy(key, items, val) {
  let idx = null;
  for (let i = 0; i < items.length; i++) {
    if (items[i][key] === val) {
      idx = i;
      break;
    }
  }
  return idx;
}

export default class IntegrationController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      contacts: {},
      integrationDefs: {},
      integrationTypes: [],
      items: [],
      validationErrors: {},
      selectedIntegrationIndex: null,
      goBackOnCancel: false,
    };
  }

  componentDidMount() {
    const opts = URLHistory.queryStringToObject();
    const goBackOnCancel = opts.gobackoncancel ? opts.gobackoncancel === 'true' : false;
    this.setState({goBackOnCancel});
    const new_url = URLHistory.updateQueryString(null, null, ['err', 'pop', 'gobackoncancel']);
    URLHistory.replaceState(new_url);
    this.loadAllData(opts.err, opts.name, opts.pop);
  }

  loadAllData(err, test_name, pop) {
    new Ajax().post({
      url: this.props.listURL,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        const types = _.map(data.integration_defs, function (d) {
          return [d[0], d[1].verbose_name];
        });
        this.setState({
          integrationDefs: _.object(data.integration_defs),
          integrationTypes: [['', '--- Select an Integration Type ---']].concat(types),
          contacts: data.contacts,
          items: data.items,
        });
        if (err !== undefined) {
          const idx = getIndexBy('id', data.items, err);
          if (idx !== null) {
            this.showErrorsModal(idx);
          }
        } else if (test_name !== undefined) {
          const idx = getIndexBy('name', data.items, test_name);
          if (idx !== null && data.items[idx].is_test_supported) {
            this.showIntegrationTestModal(idx);
          }
        } else if (pop === 'initial') {
          this.showEditForm(null);
        } else if (parseInt(pop, 10) >= 0) {
          this.showEditForm(parseInt(pop, 10));
        }
      }.bind(this),
    });
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleAddItemClick(e) {
    e.preventDefault();
    this.showEditForm(null);
  }

  handleEditItemClick(itemIndex, e) {
    e.preventDefault();
    this.showEditForm(itemIndex);
  }

  handleShowErrorsClick(itemIndex, e) {
    e.preventDefault();
    this.showErrorsModal(itemIndex);
  }

  handleShowTestIntegrationForm(itemIndex, e) {
    e.preventDefault();
    this.showIntegrationTestModal(itemIndex);
  }

  showEditForm(itemIndex) {
    let state;
    this.clearValidationErrors();

    if (null === itemIndex) {
      // just open modal with module selector
      state = {module: null};
    } else if (this.state.integrationDefs[itemIndex] !== undefined) {
      // open modal to add new module of newIntegrationModule kind
      this.refs.form.switchIntegrationModule(itemIndex);
    } else {
      // open modal and load existing item's data referenced by itemIndex
      state = _.clone(this.state.items[itemIndex]);
    }
    this.refs.form.showModal(state);
  }

  showErrorsModal(itemIndex) {
    this.setState({selectedIntegrationIndex: itemIndex});
    this.refs.error_log.showModal();
  }

  showIntegrationTestModal(itemIndex) {
    this.setState({selectedIntegrationIndex: itemIndex});
    this.refs.test_modal.showModal();
  }

  handleDeleteItemClick(itemIndex, e) {
    e.preventDefault();

    var item = this.state.items[itemIndex];
    var msg = 'Are you sure you want to delete "' + item.name + '"?';

    MessageBox.confirmBox(
      msg,
      'Delete Integration Profile',
      function () {
        new Ajax().post({
          url: this.props.deleteURL,
          data: {id: item.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (!data.success) {
              MessageBox.alertBox('Unable to delete profile.', 'Delete Integration Profile');
            } else {
              this.refs.form.hideModal();
              this.loadAllData();
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleFormSaveClick(e) {
    e.preventDefault();
    this.clearValidationErrors();
    const formData = this.refs.form.getFormData();
    new Ajax().post({
      url: this.props.saveURL,
      data: formData,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.refs.form.hideModal();
          if (formData.id === undefined) {
            // new integration added - show test integration modal
            this.loadAllData(undefined, formData.name);
          } else {
            this.loadAllData();
          }
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleCancelClick() {
    if (this.state.goBackOnCancel) {
      setTimeout(() => history.back(), 300);
    }
  }

  render() {
    const integrationData = _.clone(this.state.items[this.state.selectedIntegrationIndex]);
    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">Integrations</h1>
          {this.props.hasWriteAccess ? (
            <button onClick={this.handleAddItemClick} className="btn btn-primary">
              New Profile
            </button>
          ) : null}
        </div>

        <p className="mb-4">
          <strong>
            <i className="far fa-lightbulb"></i> Remember!{' '}
          </strong>
          <em>
            You will need to assign your integration to a Contact to receive alerts &amp; metrics from checks. Metric
            data may take <strong>up to 30 minutes</strong> to initially appear.
          </em>
        </p>

        <IntegrationList
          hasWriteAccess={this.props.hasWriteAccess}
          hasLoaded={!_.isEmpty(this.state.integrationTypes)}
          items={this.state.items}
          onEdit={this.handleEditItemClick}
          onDelete={this.handleDeleteItemClick}
          onShowErrors={this.handleShowErrorsClick}
          onShowTestForm={this.handleShowTestIntegrationForm}
        />
        <IntegrationForm
          ref="form"
          integrationDefs={this.state.integrationDefs}
          integrationTypes={this.state.integrationTypes}
          contacts={this.state.contacts}
          errors={this.state.validationErrors}
          onSave={this.handleFormSaveClick}
          onCancel={this.handleCancelClick}
          oauthURL={this.props.oauthURL}
        />
        <IntegrationErrorLogModal ref="error_log" data={integrationData} />
        <IntegrationTestModal ref="test_modal" testURL={this.props.testURL} data={integrationData} />
      </React.Fragment>
    );
  }
}
