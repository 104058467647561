import './Summary.css';
import Formatter from '../../../jskit/general/Formatter';
import React from 'react';
import classNames from 'classnames';

type SummaryProps = {
  pastDue: boolean;
  price: number;
  priceBase: number;
  priceDiscounted: number;
  isQuote: boolean;
  isInvoiced: boolean;
  disabled?: boolean;
  userCanPurchase: boolean;
  accountCanPurchase: boolean;
  handleGoToCheckout: () => void;
};

const QuoteInfo = ({className, text}) => (
  <div className={className}>
    <small>{text}</small>
  </div>
);

const PriceInfo = ({className, priceBase, price, priceDiscounted}) => (
  <div className={className}>
    {priceDiscounted != price && <del className="text-muted">{Formatter.shortCurrency(price)}</del>}
    <b>{Formatter.shortCurrency(priceDiscounted)}/mo</b>
    <small>
      Current Plan: <b>{Formatter.shortCurrency(priceBase)}</b> + <b>{Formatter.shortCurrency(priceDiscounted)}</b>{' '}
      Added Cost
    </small>
  </div>
);

const Summary = ({
  pastDue,
  price,
  priceBase,
  priceDiscounted,
  isQuote,
  isInvoiced,
  disabled,
  accountCanPurchase,
  userCanPurchase,
  handleGoToCheckout,
}: SummaryProps) => {
  if (!price || price <= 0) {
    return null;
  }

  let quoteHelp = 'Order exceeds self-serve limits and will be sent to your Account Rep for processing.';
  if (!userCanPurchase || !accountCanPurchase) {
    quoteHelp = 'You do not have permissions to add features';
    if (!accountCanPurchase) {
      quoteHelp =
        quoteHelp + ' until a valid payment method and subscription is added to your account by your account owner';
    }
  }
  if (isInvoiced) {
    quoteHelp = '*Invoiced & Custom Plans must contact Uptime.com to upgrade';
  }

  const canEdit = !disabled && !pastDue;

  return (
    <div className="sticky-footer bg-white d-flex align-items-center">
      {pastDue ? (
        <small className="ml-5 font-24">
          <b>Subscription Payment Past Due</b>
        </small>
      ) : null}
      {isQuote && !pastDue && <QuoteInfo className="ml-5 font-24" text={quoteHelp} />}
      {!isQuote && !pastDue && (
        <PriceInfo className="ml-5 font-24" priceBase={priceBase} price={price} priceDiscounted={priceDiscounted} />
      )}
      <button
        type="submit"
        onClick={handleGoToCheckout}
        className={classNames('btn ml-auto mr-5', {'btn-muted': !canEdit, 'btn-success': canEdit})}
        disabled={disabled || pastDue}
      >
        {isQuote ? 'Get Quote' : 'Next'}
      </button>
    </div>
  );
};

export default Summary;
