import Formatter from '../../../jskit/general/Formatter';
import {FormLink} from '../../../jskit/react/forms/FormLinkType';
import StepInput from '../StepInput';
import ReactTooltip from 'react-tooltip';
import React, {useState} from 'react';

export type AddOnProps = {
  title: string;
  subtitle: string;
  field: string;
  bundleSize: number;
  used: number;
  included: number;
  value: number;
  unitPrice: number;
  readOnly?: boolean;
  min: number;
  max: number;
};

type FormRowProps = {
  item: AddOnProps;
  formLink: FormLink;
  warnMessage: string;
  onValuesChange: () => void;
};

const UNLIMITED_VALUE = 10000;

const FormRow = ({item, warnMessage, formLink, onValuesChange}: FormRowProps) => {
  const {field, readOnly, used, included, unitPrice, title, bundleSize, subtitle, min, max} = item;
  const fieldValue = parseInt(formLink.get(field) || 0);
  const [showError, setShowError] = useState(false);
  const includedValue = included === UNLIMITED_VALUE ? 'Unlimited' : Formatter.humanFriendlyInt(included);
  const price = fieldValue * unitPrice;
  return (
    <tr key={`row-${title}`} className="text-center form-group">
      <td className="text-left w-20">{title}</td>
      <td className="text-left">
        <i data-toggle="tooltip" data-placement="top" title={subtitle} className="fa fa-info-circle text-gray-2"></i>
      </td>
      <td>{<b>{`${Formatter.humanFriendlyInt(used)} / ${includedValue}`}</b>}</td>
      <td>
        {!readOnly && (
          <StepInput
            key={field}
            step={bundleSize}
            fieldName={field}
            onChange={() => {
              onValuesChange();
              setShowError(false);
            }}
            onReachedMax={() => {
              setShowError(true);
            }}
            minValue={min}
            maxValue={max}
            /* eslint-disable-next-line max-len */
            minValueMsg="Reducing settings below current usage or base subscription levels is not supported."
            formLink={formLink}
          />
        )}
      </td>
      <td>
        {!readOnly && showError && (
          <>
            <i data-tip data-for="warn-msg" className="fa fa-info-circle text-danger"></i>
            <ReactTooltip className="w-20 font-14" id="warn-msg" html={true} place="bottom">
              {warnMessage}
            </ReactTooltip>
          </>
        )}
      </td>
      <td>
        <b>{!readOnly && (price ? Formatter.shortCurrency(price) : '-')}</b>
      </td>
    </tr>
  );
};

export default FormRow;
