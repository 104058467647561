'use strict';

import React from 'react';

export default class CreateChecksBar extends React.PureComponent {
  render() {
    let bar = null;

    if (!this.props.isVisible) {
      return null;
    }

    if (this.props.isCreatingChecks) {
      bar = this.renderCreatingChecksBar();
    } else {
      bar = this.renderAddingChecksBar();
    }

    return <div className="create-checks-bar text-center sticky-top">{bar}</div>;
  }

  renderAddingChecksBar() {
    const servicesRemaining = this.props.currentServiceLimit - this.props.currentNumServices;
    let content;

    if (this.props.numChecksSelected <= servicesRemaining) {
      if (this.props.numChecksSelected === 0) {
        content = null;
      } else {
        content = (
          <div>
            <button onClick={this.props.onCreateChecks} className="btn btn-success">
              Add Monitoring Checks Now
            </button>
          </div>
        );
      }
    } else if (this.props.currentServiceLimit === 0) {
      content = (
        <div>
          <span className="d-block create-checks-subtitle">
            {/* eslint-disable-next-line max-len */}
            Start a {this.props.freeTrialPeriod}-day free trial (no credit card required) to schedule these checks.
          </span>
          <button onClick={this.props.onSignUp} className="btn btn-success">
            Start Free Trial
          </button>
        </div>
      );
    } else {
      content = (
        <div>
          <span className="d-block create-checks-subtitle">
            Your account has only {servicesRemaining} checks left. Upgrade your subscription to proceed.
          </span>
          <button onClick={this.props.onSignUp} className="btn btn-success">
            Upgrade Now
          </button>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="create-checks-title">
          <span className="d-block d-md-inline">Checks Selected: {this.props.numChecksSelected}.</span>
          <span className="d-block d-md-inline">
            <span>&nbsp;&nbsp;</span>
            Select&nbsp;
            <a className="text-white" href="#" onClick={this.props.onSelectAll}>
              all
            </a>
            , &nbsp;
            <a className="text-white" href="#" onClick={this.props.onSelectNone}>
              none
            </a>
            &nbsp;or individually below.
          </span>
        </div>
        {content}
      </React.Fragment>
    );
  }

  renderCreatingChecksBar() {
    return <p className="mb-0">One moment please...</p>;
  }
}

CreateChecksBar.defaultProps = {
  isVisible: false,
  isAddingChecks: false,
  isCreatingChecks: false,
  numChecksSelected: 0,
  currentNumServices: 0,
  currentServiceLimit: 0,
  freeTrialPeriod: 0,

  onSignUp: null,
  onCreateChecks: null,
  onSelectAll: null,
  onSelectNone: null,
};
