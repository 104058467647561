export const CUSTOM_SECTIONS = [
  {
    title: 'Any Configuration',
    tooltip: 'Support for any configuration and any scale that your business requires.',
  },
  {
    title: 'Flexible Billing',
    tooltip:
      'Flexible billing that meets your business needs, such as invoiced payments and custom payment terms!' +
      ' We support it all!',
  },
  {
    title: 'Volume Discounts',
    tooltip: 'Volume Discounts available with customized pricing  designed for growth and scale!',
  },
  {
    title: 'Custom Terms',
    tooltip: 'Contact us for custom contract terms, multi-year contracts or specific contract requirements.',
  },
  {
    title: 'Customer Success Manager',
    tooltip: 'Get custom support, QBRs, strategy and more.',
  },
];
