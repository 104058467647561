'use strict';

import React from 'react';
import ReactUtils from '../jskit/react/ReactUtils';

export default class FreeTrialProgressBar extends React.Component {
  render() {
    const percent = Math.round(Math.min(this.props.percent || 0, 100)) + '%';
    const className = ReactUtils.cssClass('FreeTrialProgressBar', this.props.className);
    return (
      <div className={className} data-tip={this.props.title}>
        <div className="frame">
          <div className="indicator" style={{width: percent}}></div>
        </div>
      </div>
    );
  }
}

FreeTrialProgressBar.defaultProps = {
  percent: 0,
  className: null,
};
