'use strict';

import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import Slider from '../../jskit/react/forms/Slider';

export default class BulkIntervalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        msp_interval: null,
      },
    };
  }

  serialize() {
    return _.clone(this.state.formData);
  }

  showModal() {
    this.refs.modal_contact.showModal();
    this.setState({formData: {msp_interval: this.props.choices.msp_interval.default}});
  }

  hideModal() {
    this.refs.modal_contact.hideModal();
  }

  render() {
    if (!this.props.choices.msp_interval) {
      return null;
    }

    const formLink = prepareFormLink(this, 'formData', this.props.errors);

    return (
      <Modal
        ref="modal_contact"
        title="Set multiple check intervals"
        cancelButton={true}
        saveButton={this.props.onSave}
      >
        <form onSubmit={ReactUtils.doNotSubmit}>
          <p>
            <em>Note: Existing intervals on selected checks will be replaced with whatever you select below.</em>
          </p>
          <Slider
            fieldName="msp_interval"
            min={this.props.choices.msp_interval.min}
            max={this.props.choices.msp_interval.max}
            labelText={'Check interval: ' + this.state.formData.msp_interval + ' minutes'}
            isRequired={true}
            formLink={formLink}
          />
        </form>
      </Modal>
    );
  }
}
