import React from 'react';
import {Control, useController} from 'react-hook-form';

interface FormCheckBoxProps {
  control: Control;
  name: string;
  stringValues?: {true?: string; false?: string};
  label?: string;
}

export const FormCheckBox = ({control, name, stringValues, label, ...checkProps}: FormCheckBoxProps) => {
  const {field: publicCheckBox} = useController({
    name,
    control,
  });

  const onChangeOverride = (e) => {
    let value: string | boolean;
    if (e.target.checked) {
      value = stringValues.true || true;
    } else {
      value = stringValues.false || false;
    }
    publicCheckBox.onChange(value);
  };

  return (
    <>
      <input
        type={'checkbox'}
        id={name}
        name={name}
        {...publicCheckBox}
        {...checkProps}
        onClick={onChangeOverride}
        onChange={onChangeOverride}
      />
      <label className={'form-check-label'} htmlFor={name}>
        {label}
      </label>
    </>
  );
};
