import css from './InspireNavItems.module.css';
import ReactUtils from '@/jskit/react/ReactUtils';

export interface NavItem {
  name: string;
  url: string;
}
export interface InspireNavItemsProps {
  items: NavItem[];
}

export const InspireNavItems = ({items}: InspireNavItemsProps) => {
  return (
    <ul className="pl-0 pr-0 mb-0 d-flex align-items-center justify-content-around">
      {items.map((item) => (
        <li className={ReactUtils.cssClass('d-inline-block')}>
          <a className={ReactUtils.cssClass('nav-item nav-link', css.InspireNavItem)} target="_blank" href={item.url}>
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  );
};
