'use strict';

import React from 'react';
import Component from './Component.jsx';

export default class ComponentStatus extends React.PureComponent {
  render() {
    const sp = this.props.statuspage;
    if (!sp.components.length) {
      return null;
    }

    const components = sp.components.map((component, i) => (
      <Component
        key={i}
        component={component}
        showResponseTime={sp.show_component_response_time}
        componentStatusRank={this.props.componentStatusRank}
        componentStatusChoices={this.props.componentStatusChoices}
        allowDrillDown={sp.allow_drill_down}
      />
    ));

    return (
      <React.Fragment>
        <div className="component-list component-status overflow-x-auto">
          <div className="component-list-scroller">
            <div className="component-header">
              <div className="row align-items-end">
                <div className={sp.show_component_response_time ? 'col-7' : 'col-9'}>Name</div>
                {sp.show_component_response_time ? <div className="col-2">Response Time</div> : null}
                <div className="col-3">Status</div>
              </div>
            </div>
            {components}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ComponentStatus.defaultProps = {
  statuspage: null,
  componentStatusRank: null,
};
