'use strict';

import React from 'react';
import _ from 'underscore';
import ReactUtils from '../../jskit/react/ReactUtils';
import Utils from '../../jskit/general/Utils';
import CheckBox from '../../jskit/react/forms/CheckBox';

import './SubscriptionComponent.css';

export default class SubscriptionComponent extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      isOpen: true,
    };
  }

  handleComponentOpenClose(e) {
    e.preventDefault();
    this.setState({isOpen: !this.state.isOpen});
  }

  allChildrenSelected(component) {
    const formLink = this.props.formLink;
    return _.reduce(component.subcomponents, (acc, cmp) => acc && formLink.get(cmp.id), true);
  }

  setAllChildren(component, e) {
    const formLink = this.props.formLink;
    const targetEl = {target: {type: 'checkbox', checked: e.target.checked}};
    component.subcomponents.forEach((cmp) => _.defer(() => formLink.set(cmp.id, targetEl)));
  }

  render() {
    const component = this.props.component;
    if (!component || (component.is_group && !component.subcomponents.length)) {
      return false;
    }
    let subcomponentGroup = null;

    if (component.is_group) {
      const subcomponents = component.subcomponents.map((subcomponent, j) => (
        <SubscriptionComponent key={subcomponent.id} component={subcomponent} formLink={this.props.formLink} />
      ));
      subcomponentGroup = (
        <div key={component.id + '-sub'} className="sub-component-list">
          {subcomponents}
        </div>
      );
    }
    return (
      <React.Fragment>
        <div
          className={ReactUtils.cssClass('component row', {
            ['component-group']: component.is_group,
            open: this.state.isOpen,
          })}
        >
          <div>
            <CheckBox
              fieldName={component.id}
              formLink={component.is_group ? undefined : this.props.formLink}
              value={component.is_group ? this.allChildrenSelected(component) : undefined}
              onChange={component.is_group ? this.setAllChildren.bind(null, component) : undefined}
            />
          </div>
          <div>
            {component.is_group ? (
              <div className="group-expander-holder">
                <div className="group-expander" onClick={this.handleComponentOpenClose} />
              </div>
            ) : null}
          </div>
          {this.renderName(component)}
        </div>
        {subcomponentGroup}
      </React.Fragment>
    );
  }

  renderName(component) {
    const name = <span>{component.name}</span>;
    let description = null;
    if (component.description) {
      description = <small>{component.description}</small>;
    }

    return (
      <div className="component-name">
        {name}
        {description}
      </div>
    );
  }
}

SubscriptionComponent.defaultProps = {
  component: null,
  onGroupSelect: null,
  initial: [],
};
