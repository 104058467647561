import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import Utils from '../../jskit/general/Utils';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import IntlTelInput from '../../jskit/react/forms/IntlTelInput';
import TextArea from '../../jskit/react/forms/TextArea';
import Select2 from '../../jskit/react/forms/Select2';
import braintree from 'braintree-web';
import Ajax from '../../jskit/general/Ajax';
import $ from 'jquery';
import MessageBox from '../../js/global/messagebox';

import {FormRecaptchaField} from '../../components/form/FormRecaptchaField';

const MethodPage = {
  CreditCard: 'CREDIT_CARD',
  PayPal: 'PAYPAL',
};

export default class PaymentMethodForm extends React.Component {
  // hack to fix double-call to onPaymentMethodReceived from BrainTree. In PayPal mode,
  // BT calls onPaymentMethodReceived twice, no matter if the .teardown method was called
  // or not (so it will NOT be fixed by calling it explicitly). Seems like the only way to
  // handle it v2 is by using this extra flag to track the call-in-progress and allow invoking
  // onPaymentMethodReceived only once.
  btCallInProgress = false;

  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    const formData = this.props.initialData;
    formData.payment_method = this.props.paymentMethod.pm_type || MethodPage.CreditCard;
    this.state = {
      formData,
      paymentMethodPage: this.props.paymentMethod.pm_type || MethodPage.CreditCard,
      validationErrors: null,
      isChanging: !this.props.paymentMethod.pm_type || this.props.forceOpen,
      stateOptions: [],
      renderTextFieldForState: false,
      hideStateField: false,
    };
  }

  componentDidMount() {
    this.setupFields();
  }

  setupFields() {
    if (this.state.isChanging) {
      this.setupBrainTree();
    }
    if (this.state.formData.country) {
      this.loadStatesFor(this.state.formData.country);
    }
  }

  getFormData() {
    return this.state.formData;
  }

  setValidationErrors(fields) {
    this.setState({validationErrors: fields});
  }

  clearValidationErrors() {
    this.setState({validationErrors: null});
  }

  setupBrainTree() {
    let btOptions = {
      onError: function (event) {
        this.btCallInProgress = false;
        if (this.props.onError) {
          this.props.onError(event);
        }
      }.bind(this),
      onPaymentMethodReceived: function (obj) {
        // Due to a bug in BT lib, will be called twice with PayPal, regardless.
        // So need to call our handler only if we have previously set btCallInProgress,
        // and clean it otherwise
        if (!this.btCallInProgress) {
          return;
        }
        this.handlePaymentTokenReceived(obj);
      }.bind(this),
    };
    let pmSpecificOptions = {};
    if (this.state.paymentMethodPage === MethodPage.CreditCard) {
      pmSpecificOptions = {
        id: 'change-plan-form',
        hostedFields: {
          styles: {
            input: {
              'font-size': '16px',
              color: '#495057',
            },
          },
          number: {
            selector: '#card_number',
          },
          cvv: {
            selector: '#cvv',
          },
          expirationDate: {
            selector: '#expiry',
            placeholder: 'MM/YY',
          },
          onFieldEvent: function (event) {
            if (event.type === 'focus') {
              $(event.target.container).addClass('focused');
            } else if (event.type === 'blur') {
              $(event.target.container).removeClass('focused');
              if (!event.isValid) {
                $(event.target.container).parent().find('.error').removeClass('d-none');
              } else {
                $(event.target.container).parent().find('.error').addClass('d-none');
              }
            } else if (event.type === 'fieldStateChange') {
              // Handle a change in validation or card type
              if (!event.isFocused) {
                if (!event.isValid) {
                  $(event.target.container).parent().find('.error').removeClass('d-none');
                } else {
                  $(event.target.container).parent().find('.error').addClass('d-none');
                }
              }
            }
          },
        },
      };
    } else {
      pmSpecificOptions = {
        paypal: {
          container: 'paypal-container',
          singleUse: false,
          billingAgreementDescription: 'Uptime.com subscription',
          locale: 'en_US',
          enableShippingAddress: false,
        },
      };
    }
    btOptions = Object.assign(btOptions, pmSpecificOptions);
    braintree.setup(this.props.braintreeToken, 'custom', btOptions); // eslint-disable-line no-undef
  }

  handlePaymentMethodPageChange(page) {
    this.setState({paymentMethodPage: page});
    const formData = Object.assign({}, this.getFormData());
    formData.payment_method = page;
    this.setState({formData}, this.setupBrainTree);
  }

  handleSubscribeSubmit() {
    // the entire function is a dirty hack. BrainTree form submit handler
    // doesn't work directly and requires a button to be clicked
    if (this.props.recaptchaRequired && !this.props.recaptchaSolved) {
      MessageBox.alertBox('Please complete the reCaptcha verification step and try your purchase again.');
      return false;
    }

    if (this.props.paymentMethod && !this.state.isChanging) {
      this.props.onSuccess(null);
      return false;
    }

    const method = this.state.paymentMethodPage;
    if (method === MethodPage.CreditCard) {
      this.refs.paymentFormSubmitButton.click();
    } else if (method === MethodPage.PayPal) {
      document.getElementById('braintree-paypal-button').click();
    }
    this.btCallInProgress = true;
    return true;
  }

  handlePaymentTokenReceived(obj) {
    this.btCallInProgress = false;
    const data = Object.assign({}, this.getFormData());
    data.payment_method_nonce = obj.nonce;
    if (this.props.onSuccess) {
      this.props.onSuccess(data);
    }
  }

  handleChangePaymentMethod(e) {
    if (this.props.readOnly) {
      return;
    }
    e.preventDefault();
    this.setState({isChanging: true}, this.setupFields);
    if (this.props.onExpand) {
      this.props.onExpand();
    }
  }

  renderPaymentMethod() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    return (
      <form id="change-plan-form" method="post" autoCorrect="off" autoCapitalize="off" ref="paymentForm">
        {!this.props.readOnly && this.renderAddCreditCard(formLink)}
        {!this.props.readOnly && this.renderAddPayPal()}
        {this.renderBillingInformation(formLink)}
        <div className="row">
          <div className="col-12 form-group">
            <button name="save" ref="paymentFormSubmitButton" className="d-none">
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderAddCreditCard(formLink) {
    const page = this.state.paymentMethodPage;
    const className = ReactUtils.cssClass('CCContainer', {'d-none': page !== MethodPage.CreditCard});
    return (
      <div className={className}>
        <div className="row form-row">
          <div className="col-12 form-group">
            <TextInput
              className="bt-input"
              fieldName="cardholder_name"
              labelText="Card Name"
              placeholder="First and Last Name on Card"
              isRequired={true}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12 form-group">
            <label className="col-form-label requiredField">
              Card Number<span className="ml-1 text-danger">*</span>
            </label>
            <div className="bt-input" id="card_number"></div>
            <div className="error text-danger d-none">Invalid card number.</div>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12 col-md-6 form-group">
            <label className="col-form-label requiredField">
              Expiration Date<span className="ml-1 text-danger">*</span>
            </label>
            <div className="bt-input" id="expiry"></div>
            <div className="error text-danger d-none">
              Invalid value for expiration date. Please enter in MM/YY or MMYY format.
            </div>
          </div>
          <div className="col-12 col-md-6 form-group">
            <label className="col-form-label requiredField">
              Security Code<span className="ml-1 text-danger">*</span>
            </label>
            <div className="bt-input" id="cvv"></div>
            <div className="error text-danger d-none">Invalid CVV number</div>
            <small className="text-muted">Last 3-4 numbers on back of card</small>
          </div>
        </div>
        {this.props.recaptchaRequired && (
          <div className="row form-row">
            <div className="col-12 form-group text-center">
              <FormRecaptchaField
                sitekey={this.props.recaptchaSiteKey}
                name="recaptcha"
                onChange={this.props.recaptchaCallback}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderAddPayPal() {
    const page = this.state.paymentMethodPage;
    const className = ReactUtils.cssClass('PayPalContainer mb-4', {'d-none': page !== MethodPage.PayPal});
    return <div id="paypal-container" className={className}></div>;
  }

  loadStatesFor = (country) => {
    if (this.props.excludeStateCountries.indexOf(country) != -1) {
      this.setState({renderTextFieldForState: false, hideStateField: true});
    } else if (this.props.showStateDropdownForCountries.indexOf(country) != -1) {
      const finalURL = `/billing/states/${country}/ajax/list`;
      new Ajax().get({
        url: finalURL,
        encoder: 'json',
        decoder: 'json',
        success: function (data) {
          this.setState({
            stateOptions: data.states,
            renderTextFieldForState: false,
            hideStateField: false,
          });
        }.bind(this),
      });
    } else {
      this.setState({renderTextFieldForState: true, hideStateField: false});
    }
  };

  renderProvince(formLink) {
    if (this.state.hideStateField) return;

    if (!this.state.renderTextFieldForState) {
      return (
        <Select2
          fieldName="state"
          choices={this.state.stateOptions}
          labelText="Region/State"
          isRequired={true}
          formLink={formLink}
        />
      );
    } else {
      return (
        <TextInput
          fieldName="state"
          labelText="Region/State"
          placeholder="Optional"
          isRequired={false}
          formLink={formLink}
        />
      );
    }
  }

  renderBillingInformation(formLink) {
    return (
      <React.Fragment>
        <h3>Billing Information</h3>
        <div className="row form-row">
          <div className="col-12 col-md-6 form-group">
            <TextInput
              fieldName="billing_name"
              labelText="Billing Name"
              placeholder="Billing Name"
              isRequired={true}
              formLink={formLink}
            />
          </div>
          <div className="col-12 col-md-6 form-group">
            <IntlTelInput
              className="bt-input"
              fieldName="phone"
              labelText="Phone"
              isRequired={false}
              formLink={formLink}
            />
          </div>
        </div>

        <h4>Company address</h4>
        <div className="row form-row">
          <div className="col-12">
            <TextInput
              fieldName="company_name"
              labelText="Company Name"
              placeholder="Uptime.com LLC"
              isRequired={false}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <TextInput
              fieldName="address_1"
              labelText="Address line 1"
              placeholder="228 Hamilton Ave"
              isRequired={true}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <TextInput
              fieldName="address_2"
              labelText="Address line 2"
              placeholder="Optional (eg, Suite, Apt, etc)"
              isRequired={false}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-6">
            <Select2
              fieldName="country"
              choices={this.props.allCountries}
              labelText="Country"
              isRequired={true}
              formLink={formLink}
              onChange={(e) => {
                this.loadStatesFor(e.target.value);
              }}
            />
          </div>
          <div className="col-6">{this.renderProvince(formLink)}</div>
        </div>
        <div className="row form-row">
          <div className="col-6">
            <TextInput
              fieldName="city"
              labelText="City"
              placeholder="Palo Alto"
              isRequired={true}
              formLink={formLink}
            />
          </div>
          <div className="col-6">
            <TextInput
              fieldName="zipcode"
              labelText="Zipcode"
              placeholder="94301"
              isRequired={!this.state.renderTextFieldForState}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <TextArea
              className="bt-input"
              fieldName="additional_info"
              labelText="Additional Info"
              placeholder="Provide your VAT, TaxID number, etc here if required for processing"
              isRequired={false}
              formLink={formLink}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderPaymentMethodSelector() {
    const page = this.state.paymentMethodPage;
    const ccCssClass = ReactUtils.cssClass('col-6', {selected: page === MethodPage.CreditCard});
    const payPalCssClass = ReactUtils.cssClass('col-6', {selected: page === MethodPage.PayPal});
    return (
      <div className="row PaymentMethodSelector mb-4">
        <div className={ccCssClass} onClick={this.handlePaymentMethodPageChange.bind(null, MethodPage.CreditCard)}>
          <i className="fal fa-credit-card"></i> Credit Card
        </div>
        <div className={payPalCssClass} onClick={this.handlePaymentMethodPageChange.bind(null, MethodPage.PayPal)}>
          <i className="fab fa-paypal"></i> PayPal
        </div>
      </div>
    );
  }

  renderChangePaymentMethod() {
    return (
      <React.Fragment>
        <h4>Select Payment Method</h4>
        {this.renderPaymentMethodSelector()}
        {this.renderPaymentMethod()}
      </React.Fragment>
    );
  }

  renderCreditCard() {
    return (
      <div className="PaymentMethod mb-3">
        <strong>Card on File</strong>
        <img className="cc-img" src={this.props.paymentMethod.img} />
        XXXX&ndash;{this.props.paymentMethod.suffix}&nbsp;&ndash;&nbsp;Expires:{' '}
        {this.props.paymentMethod.expiration_date}
      </div>
    );
  }

  renderPayPal() {
    return (
      <div className="PaymentMethod mb-3">
        <strong>PayPal Account</strong>
        <img className="cc-img" src={this.props.paymentMethod.img} />
        {this.props.paymentMethod.email}
      </div>
    );
  }

  renderExistingPaymentMethod() {
    return (
      <React.Fragment>
        {this.props.paymentMethod.pm_type === MethodPage.PayPal ? this.renderPayPal() : this.renderCreditCard()}
        {!this.props.readOnly && (
          <a className="d-block" tabIndex="1" onClick={this.handleChangePaymentMethod}>
            Change payment method
          </a>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="PaymentMethodForm mt-0 mb-2">
        {!this.state.isChanging ? this.renderExistingPaymentMethod() : this.renderChangePaymentMethod()}
      </div>
    );
  }
}

PaymentMethodForm.defaultProps = {
  paymentMethod: null,
  braintreeToken: null,
  initialData: {},
  forceOpen: false,
  onSuccess: null,
  onError: null,
  onExpand: null,
  readOnly: false,
};
