'use strict';

import moment from 'moment-timezone';
import React from 'react';
import Ajax from '../../jskit/general/Ajax';
import Utils from '../../jskit/general/Utils';
import {PrivateLocation} from './PrivateLocationsTypes';
import PrivateLocationsList from './PrivateLocationsList';

interface State {
  hasLoaded: boolean;
  lastRefresh: moment.Moment;
  privateLocations: PrivateLocation[];
}

interface Props {
  urls: Record<string, string>;
  isEditable: boolean;
}

export default class PrivateLocationsController extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    Utils.autoBindClass(this, {});

    this.state = {
      hasLoaded: false,
      lastRefresh: null,
      privateLocations: [],
    };
  }

  componentDidMount(): void {
    this.reloadPrivateLocationData();
    setInterval(this.handlePeriodicRefresh, 60000);
  }

  /**
   * Refreshes the list of private locations either every 10 mins, or anytime
   * a certain server's last status is between 11-20 mins old and a new one
   * should have been sent. (Longer than that and it's probably off, so no
   * point in constantly refreshing)
   *
   */
  handlePeriodicRefresh(): void {
    if (this.state.lastRefresh === null) {
      return;
    }

    const elapsed = moment.utc().diff(this.state.lastRefresh, 'seconds');
    const minStatusSentAt = this.state.privateLocations
      .filter((s) => s.status.sent_at)
      .reduce((a, b) => (a.status.sent_at.length <= b.status.sent_at.length ? a : b)).status.sent_at;
    const minStatusSentAtElapsed = moment.utc().diff(moment.utc(minStatusSentAt), 'seconds');

    if (elapsed >= 600 || (minStatusSentAtElapsed >= 660 && minStatusSentAtElapsed < 1200)) {
      this.reloadPrivateLocationData();
    }
  }

  reloadPrivateLocationData(): void {
    new Ajax().get({
      url: this.props.urls.list,
      decoder: 'json',
      success: (result) => {
        this.setState({
          hasLoaded: true,
          lastRefresh: moment.utc(),
          privateLocations: result.private_locations,
        });
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="mb-4 d-flex p-3" style={{background: '#FDFDFE', border: '1px solid #FDFDFE'}}>
          <i className="fa-solid fa-location-dot mr-2 mt-2" style={{fontSize: '18px', color: '#636464'}}></i>
          <p>
            Private location servers are locations that are deployed by customers inside their private, hybrid or cloud
            environments to run tests behind the firewall or to test and monitor endpoints that are not accessible from
            outside-in (publicly exposed). Use this page to monitor your private location deployments throughout your
            environments.
          </p>
          <br />
          <br />
        </div>
        <PrivateLocationsList
          hasLoaded={this.state.hasLoaded}
          isEditable={this.props.isEditable}
          privateLocations={this.state.privateLocations}
          contactURL={this.props.urls.contact}
          saveURL={this.props.urls.saveURL}
        />
        <em>
          <strong>Important:</strong>
          Private location servers may show warnings or errors during the first hour of operation following a restart.
          The version number column will not be available on PLM versions below 3.0.{' '}
          <a
            // eslint-disable-next-line max-len
            href="https://support.uptime.com/hc/en-us/articles/360012622239-Getting-Started-with-Private-Location-Monitoring"
            target="_blank"
          >
            Get Started with Private Location Monitoring
          </a>
        </em>
      </React.Fragment>
    );
  }
}
