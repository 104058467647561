'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';
import Select2 from '../../jskit/react/forms/Select2';

const ADD_GROUP = '[!#ADD-GROUP#!]';

export default class URLPatternsGroupForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {},
    };
  }

  getFormData() {
    return this.state.formData;
  }

  getGroupIdAndName() {
    const formData = this.getFormData();

    return {
      pattern_id: formData.id,
      group_id: formData.group_id && formData.group_id !== ADD_GROUP ? formData.group_id : null,
      name: formData.group_id === ADD_GROUP ? formData.name : null,
    };
  }

  showModal(initialFormData) {
    if (initialFormData) {
      this.setState({formData: initialFormData});
    }

    this.refs.modal.showModal(this.refs.groupSelect.focus);
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this.getFormData();

    const groupChoices = [
      ['', '--- None ---'],
      [ADD_GROUP, '--- Create New Group ---'],
    ].concat(this.props.groupChoices);

    return (
      <Modal
        ref="modal"
        title={formData.id ? 'Edit Group' : 'Add Group'}
        size="lg"
        saveButton={this.props.onSave}
        cancelButton={true}
      >
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <FormErrors errors={this.props.errors.__all__} />
          <Select2
            ref="groupSelect"
            fieldName="group_id"
            labelText="Select Group"
            choices={groupChoices}
            isRequired={true}
            formLink={formLink}
          />
          {formData.group_id === ADD_GROUP ? (
            <TextInput fieldName="name" labelText="New Group Name" isRequired={true} formLink={formLink} />
          ) : null}
        </form>
      </Modal>
    );
  }
}
