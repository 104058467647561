'use strict';

import React from 'react';
import Ajax from '../../jskit/general/Ajax';
import AutoRefresh from '../../jskit/react/AutoRefresh';
import Utils from '../../jskit/general/Utils';
import RumChecksList from './RumChecksList.jsx';
import {renderNeedHelp} from './RumUtils.jsx';

export default class RumChecksListController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      hasLoaded: false,
      items: [],
    };

    this.refreshRef = React.createRef();
  }

  componentDidMount() {
    this.loadAllData();
  }

  loadAllData() {
    new Ajax().post({
      url: this.props.listURL,
      encoder: 'json',
      success: function (data) {
        this.setState({
          items: data.items,
          hasLoaded: true,
        });
      }.bind(this),
    });
  }

  render() {
    const extraListProps = {
      rum2ReportURL: this.props.rum2ReportURL,
    };

    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">Real User Monitoring Checks</h1>
          <div style={{display: 'flex', alignItems: 'right'}}>
            <AutoRefresh ref={this.refreshRef} refreshFunction={this.loadAllData} extraClass="mr-2 mb-1" />
            <button
              type="button"
              title="Toggle Full Screen"
              onClick={Utils.toggleFullScreenMode}
              className="btn btn-white mr-2 mb-1 d-none d-sm-inline-block"
            >
              <i className="fas fa-fw fa-expand-arrows-alt" />
            </button>
            {this.props.hasWriteAccess && (
              <a href={this.props.addServiceURL} className="btn btn-primary mb-1">
                Add New
              </a>
            )}
          </div>
        </div>
        {renderNeedHelp(undefined, {dismissible: true})}
        <div style={{paddingBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <i className="has-schedule" style={{fontSize: '12px', padding: '3px 10px 0px 10px'}} />
          <p className="font-14 text-light-4 mb-0">Showing last 30 minutes (median)</p>
        </div>
        <RumChecksList hasLoaded={this.state.hasLoaded} items={this.state.items} extraListProps={extraListProps} />
      </React.Fragment>
    );
  }
}
