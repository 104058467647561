'use strict';

import jsSHA from 'jssha/sha1';

function dec2hex(s) {
  return (s < 15.5 ? '0' : '') + Math.round(s).toString(16);
}

function hex2dec(s) {
  return parseInt(s, 16);
}

function leftpad(s, l, p) {
  if (l + 1 >= s.length) {
    s = Array(l + 1 - s.length).join(p) + s;
  }
  return s;
}

function base32tohex(base32) {
  const base32chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
  let bits = '';
  let hex = '';
  for (let i = 0; i < base32.length; i++) {
    const val = base32chars.indexOf(base32.charAt(i).toUpperCase());
    bits += leftpad(val.toString(2), 5, '0');
  }
  for (let i = 0; i + 4 <= bits.length; i += 4) {
    const chunk = bits.substr(i, 4);
    hex = hex + parseInt(chunk, 2).toString(16);
  }
  return hex;
}

function totp(secret, expiry = 30, digits = 6) {
  //remove any characters except for allowed in base32
  secret = secret.replace(/[^A-Z2-7]/gi, '');

  const epoch = Math.round(new Date().getTime() / 1000.0);
  const time = leftpad(dec2hex(Math.floor(epoch / expiry)), 16, '0');
  const valid_seconds = expiry - (epoch % expiry);

  const shaObj = new jsSHA('SHA-1', 'HEX');
  shaObj.setHMACKey(base32tohex(secret), 'HEX');
  shaObj.update(time);
  const hmac = shaObj.getHMAC('HEX');

  const offset = hex2dec(hmac.substring(hmac.length - 1));
  let otp = (hex2dec(hmac.substr(offset * 2, 8)) & hex2dec('7fffffff')) + '';
  if (otp.length < digits) {
    otp = leftpad(otp, digits, '0');
  }
  otp = otp.substr(otp.length - digits, digits);
  return {code: otp, expires_in: valid_seconds};
}

export default totp;
