'use strict';

import React from 'react';
import Ajax from '../../jskit/general/Ajax';
import Utils from '../../jskit/general/Utils';

export default class SSOTab extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.state = {
      entityID: props.saml_settings.idp_entity_id,
      ssoTargetURL: props.saml_settings.idp_sso_target_url,
      idpCert: props.saml_settings.idp_certificate,
      useLegacySHA1: props.saml_settings.use_sha1_for_signature,
      dontSignSAML: props.saml_settings.unsigned_authn_requests,
      showMessage: false,
      messageType: '',
      messageContents: '',
      validationErrors: {},
    };
  }

  toggleCheckbox(checkBoxName) {
    if (checkBoxName === 'useLegacySHA1') {
      const currentState = this.state.useLegacySHA1;
      this.setState({useLegacySHA1: !currentState});
    } else {
      const currentState = this.state.dontSignSAML;
      this.setState({dontSignSAML: !currentState});
    }
  }

  renderAttributeRow(row) {
    const attributeName = row[1].toLowerCase();
    const options = row[2];
    return (
      <li key={attributeName}>
        The user's {attributeName} one of: <strong>{options.join('/')}</strong>
      </li>
    );
  }

  idpChangeHandler(row) {
    this.setState({
      entityID: row.idp_entity_id,
      ssoTargetURL: row.idp_sso_target_url,
      idpCert: row.idp_certificate,
    });
  }

  inputChangeHandler(inputName, updatedValue) {
    if (inputName === 'entityID') {
      this.setState({entityID: updatedValue});
    } else if (inputName === 'ssoTargetURL') {
      this.setState({ssoTargetURL: updatedValue});
    } else {
      this.setState({idpCert: updatedValue});
    }
  }

  renderTestIDPRow(row) {
    return (
      <div key={row.name}>
        <h5 className="mt-4 mb-1">{row.name}</h5>
        {row.info ? <div dangerouslySetInnerHTML={{__html: row.info}}></div> : ''}
        <button
          data-idp="#"
          className="btn btn-outline-light-2 btn-block"
          onClick={() => {
            this.idpChangeHandler(row);
          }}
        >
          Populate Form
        </button>
        &nbsp;
      </div>
    );
  }

  clearMessages() {
    this.setState({
      showMessage: false,
      messageType: '',
      messageContents: '',
    });
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  updateSettings(payload) {
    this.clearMessages();
    this.clearValidationErrors();
    new Ajax().post({
      url: this.props.saml_settings.updateSAMLSettings,
      data: payload,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.error) {
          this.setState({validationErrors: data.fields});
        }
        if (data.success) {
          var currentMessageType = '';
          var currentMessage = '';

          if ('clear' in payload) {
            currentMessageType = 'alert-danger';
            currentMessage = 'Your SAML single sign-on settings have been removed.';
            this.setState({
              entityID: '',
              ssoTargetURL: '',
              idpCert: '',
              useLegacySHA1: false,
              dontSignSAML: false,
            });
          } else {
            currentMessageType = 'alert-success';
            currentMessage = 'Your SAML single sign-on settings have been saved.';
          }

          this.setState(
            {
              showMessage: true,
              messageType: currentMessageType,
              messageContents: currentMessage,
            },
            () => {
              window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            }
          );
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  disableSSO() {
    if (window.confirm('Are you sure you want to remove your SAML SSO integration?')) {
      this.updateSettings({clear: ''});
    }
  }

  renderError(error) {
    return (
      <span className="invalid-feedback d-block">
        <strong>{error}</strong>
      </span>
    );
  }

  getTriggerURL() {
    let serverPrefix = '';
    if (this.props.cname_url) {
      serverPrefix = this.props.cname_url;
    }
    return `${serverPrefix}/accounts/sso/saml/login/${this.props.sp_uuid}`;
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          {this.state.showMessage ? (
            <div className="col-lg-12">
              <div id="alert-messages">
                <div
                  className={`alert  ${this.state.messageType} alert-dismissible fade show text-center mb-4`}
                  role="alert"
                >
                  {this.state.messageContents}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => {
                      this.clearMessages();
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="row white-block white-block-border py-2">
          <div className="col-lg-8">
            <p>
              <strong>Need Help?</strong> See our{' '}
              <a
                data-zd-article-id
                target="_blank"
                /* eslint-disable-next-line max-len */
                href="https://support.uptime.com/hc/en-us/articles/360001434205-Troubleshooting-Single-Sign-On-SSO-"
              >
                SAML SSO support article
              </a>
              .
            </p>

            <hr className="my-4" />

            <h4>Step 1: Add The Uptime.com Application In Your Identity Provider</h4>
            {/* eslint-disable-next-line max-len */}
            <p>
              Please use these values to register the app in your identity provider. Alternatively, you may download our
              metadata:{' '}
              <a download target="_blank" href={this.props.metadata_url}>
                Uptime.com Service Provider Metadata XML File
              </a>
            </p>

            <dt className="mb-2">
              EntityID / Audience URI{' '}
              <i
                data-toggle="tooltip"
                /* eslint-disable-next-line max-len */
                title="This is the value you'll need to enter in the 'Entity ID' field in your Identity Provider's configuration. This may also be referred to as 'Audience Restriction' or 'Audience URI'."
                className="fa fa-info-circle info-pop text-muted font-14"
              ></i>
            </dt>
            {/* eslint-disable-next-line max-len */}
            <dd className="text-force-wrap mb-3">
              <span className="card card-light-2 card-body-slim">
                <code>https://uptime.com/saml/{this.props.sp_uuid}</code>
              </span>
            </dd>

            {/* eslint-disable-next-line max-len */}
            <dt className="mb-2">
              ACS URL / Consumer URL / Recipient{' '}
              <i
                data-toggle="tooltip"
                title="This is the URL is where your Identity Provider will send SAML assertions, and will be required in your Identity Provider's admin when setting up an  BRAND_INFO.name  app."
                className="fa fa-info-circle info-pop text-muted font-14"
              ></i>
            </dt>
            {/* eslint-disable-next-line max-len */}
            <dd className="text-force-wrap mb-3">
              <span className="card card-light-2 card-body-slim">
                <code>{this.props.sp_acs_url}</code>
              </span>
            </dd>

            {/* eslint-disable-next-line max-len */}
            <dt className="mb-2">
              WAYFless URL <span className="text-muted">(optional)</span>{' '}
              <i
                data-toggle="tooltip"
                title="A URL that can be used to directly login to this  BRAND_INFO.name  account via your identity provider."
                className="fa fa-info-circle info-pop text-muted font-14"
              ></i>
            </dt>

            {/* eslint-disable-next-line max-len */}
            <dd className="text-force-wrap mb-3">
              <span className="card card-light-2 card-body-slim">
                <code>{this.props.sp_wayfless_url}</code>
              </span>
            </dd>

            {/* eslint-disable-next-line max-len */}
            <dt>
              Required SAML Attributes{' '}
              <i
                data-toggle="tooltip"
                title="Attributes that must be sent by your identity provider to identify the user being authenticated."
                className="fa fa-info-circle info-pop text-muted font-14"
              ></i>
            </dt>
            <dd className="mb-3">
              <p className="mb-2">
                We require the following attributes to be set to successfully authenticate the user:
              </p>
              <ul className="list-spacer-2">
                {/* eslint-disable-next-line max-len */}
                <li>
                  A unique user identifier or email address: <strong>Name ID &nbsp;/&nbsp; Subject NameID</strong>
                </li>
                {this.props.sp_attributes.map((item) => {
                  return this.renderAttributeRow(item);
                })}
              </ul>
            </dd>

            <strong>Additional help for: &nbsp;</strong>
            <div className="dropdown dropup d-inline-block">
              <a
                className="dropdown-toggle"
                href="#"
                role="button"
                id="more-help-active-directory"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                ActiveDirectory
              </a>
              <div
                className="dropdown-menu p-3 last-child-no-margin light-shadow"
                style={{minWidth: '30rem', backgroundColor: '#f8faff'}}
              >
                <h4>ActiveDirectory Setup Tips</h4>
                <ol className="list-spacer-2">
                  <li>
                    Download the BRAND_INFO.name Service Provider SAML Metadata from the link near the top of the page,
                    and import it into ActiveDirectory.
                  </li>
                  <li>Edit the Claim Rules for BRAND_INFO.name and add a new rule.</li>
                  <li>
                    Map the following LDAP attributes to outgoing claim types:
                    <ol>
                      <li>
                        E-Mail-Addresses &nbsp;<i className="fas fa-angle-double-right"></i>&nbsp; Name ID
                      </li>
                      <li>
                        E-Mail-Addresses &nbsp;<i className="fas fa-angle-double-right"></i>
                        &nbsp; E-Mail Address
                      </li>
                      <li>
                        Given-Name &nbsp;<i className="fas fa-angle-double-right"></i>
                        &nbsp; Given Name
                      </li>
                      <li>
                        Surname &nbsp;<i className="fas fa-angle-double-right"></i>
                        &nbsp; Surname
                      </li>
                    </ol>
                  </li>
                  <li>
                    Fill in the BRAND_INFO.name SSO Setup form below with the appropriate ActiveDirectory details.
                  </li>
                </ol>
              </div>
            </div>

            <hr className="my-4" />

            <h4>Step 2: Paste In The SSO Login Details &amp; Certificate Of Your Identity Provider</h4>

            <label htmlFor="id_idp_entity_id">Identity Provider's EntityID / Issuer</label>
            <div>
              <input
                type="text"
                name="idp_entity_id"
                value={this.state.entityID}
                maxLength={255}
                className={
                  'form-control-lg textinput form-control' +
                  (this.state.validationErrors.idp_entity_id ? ' is-invalid' : '')
                }
                id="id_idp_entity_id"
                onChange={(e) => {
                  this.inputChangeHandler('entityID', e.target.value);
                }}
              />
              {this.state.validationErrors.idp_entity_id
                ? this.renderError(this.state.validationErrors.idp_entity_id[0])
                : ''}
              <small id="hint_id_idp_entity_id" className="form-text text-muted">
                The unique identifier of your identity provider.
              </small>
            </div>

            <div id="div_id_idp_sso_target_url" className="form-group">
              <label htmlFor="id_idp_sso_target_url" className="">
                SSO Target URL
              </label>
              <div className="">
                <input
                  type="url"
                  name="idp_sso_target_url"
                  value={this.state.ssoTargetURL}
                  maxLength={255}
                  className={
                    'form-control-lg urlinput form-control' +
                    (this.state.validationErrors.idp_sso_target_url ? ' is-invalid' : '')
                  }
                  id="id_idp_sso_target_url"
                  onChange={(e) => {
                    this.inputChangeHandler('ssoTargetURL', e.target.value);
                  }}
                />
                {this.state.validationErrors.idp_sso_target_url
                  ? this.renderError(this.state.validationErrors.idp_sso_target_url[0])
                  : ''}
                <small id="hint_id_idp_sso_target_url" className="form-text text-muted">
                  The URL used to trigger a login with your identity provider (HTTP-Redirect method).
                </small>
              </div>
            </div>

            <label htmlFor="id_idp_certificate" className="">
              Identity Provider's Certificate
            </label>
            <div>
              <textarea
                name="idp_certificate"
                cols="40"
                rows="10"
                className={
                  'font-monospace textarea form-control' +
                  (this.state.validationErrors.idp_certificate ? ' is-invalid' : '')
                }
                id="id_idp_certificate"
                value={this.state.idpCert}
                onChange={(e) => {
                  this.inputChangeHandler('idpCert', e.target.value);
                }}
              ></textarea>
              {this.state.validationErrors.idp_certificate
                ? this.renderError(this.state.validationErrors.idp_certificate[0])
                : ''}
              <small id="hint_id_idp_certificate" className="form-text text-muted mb-3">
                A valid X.509 certificate in PEM format used for signing by the identity provider.
              </small>

              <div className="form-group">
                <div id="div_id_use_sha1_for_signature" className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="use_sha1_for_signature"
                    className="form-control-lg checkboxinput custom-control-input"
                    id="id_use_sha1_for_signature"
                    defaultChecked={this.state.useLegacySHA1}
                    onClick={() => {
                      this.toggleCheckbox('useLegacySHA1');
                    }}
                  />
                  <label htmlFor="id_use_sha1_for_signature" className="custom-control-label">
                    Use legacy SHA-1 for signing instead of the recommended SHA-256.
                  </label>
                  <small id="hint_id_use_sha1_for_signature" className="form-text text-muted">
                    {/* eslint-disable-next-line max-len */}
                    Please check this only if your identity provider does not support login requests signed with
                    SHA-256.
                  </small>
                </div>
              </div>

              <div className="form-group">
                <div id="div_id_unsigned_authn_requests" className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="unsigned_authn_requests"
                    className="form-control-lg checkboxinput custom-control-input"
                    id="id_unsigned_authn_requests"
                    defaultChecked={this.state.dontSignSAML}
                    onClick={() => {
                      this.toggleCheckbox('dontSignSAML');
                    }}
                  />
                  <label htmlFor="id_unsigned_authn_requests" className="custom-control-label">
                    Don't digitally sign SAML AuthN login requests (required for Azure)
                  </label>
                  <small id="hint_id_unsigned_authn_requests" className="form-text text-muted">
                    {/* eslint-disable-next-line max-len */}
                    If you are unable to perform a SAML login via the Uptime.com login form, try enabling this setting.
                  </small>
                </div>
              </div>

              <input
                type="submit"
                name="submit"
                value="Save Settings"
                className="btn btn-primary btn-lg"
                id="submit-id-submit"
                onClick={() =>
                  this.updateSettings({
                    idp_entity_id: this.state.entityID,
                    idp_sso_target_url: this.state.ssoTargetURL,
                    idp_certificate: this.state.idpCert,
                    use_sha1_for_signature: this.state.useLegacySHA1,
                    unsigned_authn_requests: this.state.dontSignSAML,
                  })
                }
              ></input>

              <input
                type="submit"
                name="clear"
                value="Disable SAML SSO"
                className="btn btn-primary btn-lg btn-danger float-right"
                id="submit-id-clear"
                onClick={() => {
                  this.disableSSO();
                }}
              ></input>
            </div>
          </div>
          <div className="col-lg-3 offset-lg-1">
            {this.props.test_idps ? (
              <React.Fragment>
                <h4 className="spacer-small">SAML Testing Sites</h4>
                <p>
                  <em>NOTE: This section is visible to superusers only!!</em>
                </p>
                <p>
                  <a href={this.getTriggerURL()} className="btn btn-primary">
                    Trigger SAML Login with Saved Settings
                  </a>
                </p>

                {this.props.test_idps.map((item) => {
                  return this.renderTestIDPRow(item);
                })}
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SSOTab.defaultProps = {
  updateURL: null,
  reorderURL: null,
  servicesCheckListURL: null,
  statuspage: null,
  onNewData: null,
};
