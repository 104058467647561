import {HistoryTabs} from '@/status_pages/display/components/tabs/History/History';
import {SectionNavContent} from '@/jskit/react/SectionNav';
import PastIncidents from '@/status_pages/display/PastIncidents';
import ComponentHistory from '@/status_pages/display/ComponentHistory';
import {ComponentStatusValue, Incident, StatusPage} from '@/status_pages/types';
import {EmptySectionRocket} from '@/status_pages/display/EmptySectionRocket';
import {EmptySectionBan} from '@/status_pages/display/EmptySectionBan';

export interface HistoryOverviewSectionProps {
  currentSectionId: HistoryTabs;
  statuspage: StatusPage;
  incidents: Incident[];
  componentStatusRank: Record<ComponentStatusValue, number>;
  componentStatusChoices: Record<ComponentStatusValue, string>;
  historyStartDate: object;
  historyEndDate: object;
}

export const HistoryOverviewSection = ({
  currentSectionId,
  statuspage,
  incidents,
  componentStatusRank,
  componentStatusChoices,
  historyStartDate,
  historyEndDate,
}: HistoryOverviewSectionProps) => {
  const showPastIncidentsSection =
    statuspage.show_past_incidents && (incidents.length > 0 || !statuspage.hide_empty_tabs_history);
  const showComponentsSection =
    statuspage.show_component_history && (statuspage.components.length > 0 || !statuspage.hide_empty_tabs_history);
  return (
    <SectionNavContent sectionId={HistoryTabs.HISTORY_OVERVIEW} currentSectionId={currentSectionId}>
      {showPastIncidentsSection && (
        <>
          <h4>Past Events</h4>
          {incidents?.length > 0 ? (
            <PastIncidents incidents={incidents} componentStatusRank={componentStatusRank} />
          ) : (
            <EmptySectionRocket>
              There are no <strong>Past Events</strong>
            </EmptySectionRocket>
          )}
        </>
      )}
      {showPastIncidentsSection && showComponentsSection && <div className="white-block-inner-divider mx-n4 my-4" />}
      {showComponentsSection && (
        <>
          <h4>Component History</h4>
          {statuspage.components.length > 0 ? (
            <ComponentHistory
              statuspage={statuspage}
              historyStartDate={historyStartDate}
              componentStatusRank={componentStatusRank}
              componentStatusChoices={componentStatusChoices}
              historyEndDate={historyEndDate}
              historyTypeDisplay={statuspage.uptime_calculation_type}
            />
          ) : (
            <EmptySectionBan>
              There is no <strong>Component History</strong>
            </EmptySectionBan>
          )}
        </>
      )}
    </SectionNavContent>
  );
};
