import PastIncidents from '@/status_pages/display/PastIncidents';
import {HistoryTabs} from '@/status_pages/display/components/tabs/History/History';
import {SectionNavContent} from '@/jskit/react/SectionNav';
import {ComponentStatusValue, Incident} from '@/status_pages/types';
import {EmptySectionRocket} from '@/status_pages/display/EmptySectionRocket';

export interface PastMaintenanceSectionProps {
  currentSectionId: HistoryTabs;
  incidents: Incident[];
  componentStatusRank: Record<ComponentStatusValue, number>;
}

export const PastMaintenanceSection = ({
  incidents,
  componentStatusRank,
  currentSectionId,
}: PastMaintenanceSectionProps) => (
  <SectionNavContent sectionId={HistoryTabs.PAST_MAINTENANCE} currentSectionId={currentSectionId}>
    {incidents?.length > 0 ? (
      <PastIncidents incidents={incidents} componentStatusRank={componentStatusRank} />
    ) : (
      <EmptySectionRocket>
        There is no <strong>Past Maintenance</strong>
      </EmptySectionRocket>
    )}
  </SectionNavContent>
);
