import React from 'react';
import Utils from '../../jskit/general/Utils';

import StepsListItem from './StepsListItem';

export default class StepsList extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  render() {
    return (
      <ul ref="stepsList" className="transaction-steps">
        {this.renderSteps()}
      </ul>
    );
  }

  renderSteps() {
    return this.props.transactionScript.map(
      function (step, i) {
        return (
          <StepsListItem
            key={i}
            index={i}
            shortSelectors={this.props.shortSelectors}
            step={step}
            allowMove={this.props.allowReorder}
            selected={this.props.selectedIndex === i}
            helpText={this.props.transactionScript[i].help_text}
            verboseName={this.props.transactionScript[i].verbose_name}
            htmlElementList={this.props.htmlElementList}
            handleAddStart={this.props.handleAddStart}
            onSelectElement={this.props.onSelectElement}
            onSelectElementCompleted={this.props.onSelectElementCompleted}
            onSelectStep={this.props.onSelectStep}
            onDeleteStep={this.props.onDeleteStep}
            onReorderStep={this.props.onReorderStep}
            onReplaceStep={this.props.onReplaceStep}
            onUpdateSelectedStepField={this.props.onUpdateSelectedStepField}
            onOpenURL={this.props.onOpenURL}
          />
        );
      }.bind(this)
    );
  }
}

StepsList.defaultProps = {
  transactionScript: [],
  selectedIndex: -1,
};
