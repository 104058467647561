import React, {useEffect, useRef, useState} from 'react';
import {Control, useController} from 'react-hook-form';
import _ from 'underscore';
import Ajax from '../../../jskit/general/Ajax';
import {ServiceSelectorFilters} from './ServiceSelectorFilters';
import {ServiceSelectorListPanels} from './ServiceSelectorListPanels';

// servicesCheckListURL should be hard coded
export const ServiceSelector = ({
  servicesCheckListURL,
  maxServices = 50,
  name,
  control,
}: {
  servicesCheckListURL: string;
  maxServices?: number;
  name: string;
  control: Control<any>;
}) => {
  const [search, setSearch] = useState('');
  const [tag, setTag] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [unselectedChoices, setUnselectedChoices] = useState([]);

  const isMounted = useRef(false);

  const {field} = useController({name, control});

  const servicesCheckListAsync = async (
    withChoices: boolean,
    searchQuery: string,
    serviceTypeId: string,
    tagId: number,
    maxServices: number
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      new Ajax().get({
        url: servicesCheckListURL,
        data: {
          choices: withChoices ? 'true' : '',
          search: searchQuery,
          monitoring_service_type: serviceTypeId,
          tag: tagId,
          limit: maxServices,
        },
        encoder: 'url',
        decoder: 'json',
        success: function (data) {
          resolve(data);
        },
        error: function (data) {
          reject(data);
        },
      });
    });
  };

  const loadAvailableChecks = async (
    withChoices: boolean,
    searchQuery: string,
    serviceTypeId: string,
    tagId: number,
    maxServices: number
  ): Promise<void> => {
    try {
      const data = await servicesCheckListAsync(withChoices, searchQuery, serviceTypeId, tagId, maxServices);
      const serviceGrouped = [];
      Object.keys(data.choices.monitoring_service_type).forEach((type) => {
        serviceGrouped.push({label: type, options: data.choices.monitoring_service_type[type]});
      });
      setServiceOptions(data.choices.services);
      setTagOptions([...tagOptions, ...data.choices.tags]);
      setServiceTypeOptions([...serviceTypeOptions, ...serviceGrouped]);
    } catch (e) {
      alert(e);
    }
  };

  const handleSelectService = (service) => {
    if (selectedService.length >= maxServices) {
      return;
    }
    setSelectedService([...selectedService, service]);
    setUnselectedChoices(unselectedChoices.filter((item) => item.id !== service.id));
    field.onChange([...selectedService, service]);
  };

  const handleUnselectService = (service) => {
    setSelectedService(selectedService.filter((item) => item.id !== service.id));
    setUnselectedChoices([...unselectedChoices, service]);
    field.onChange(selectedService.filter((item) => item.id !== service.id));
  };

  const handleSelectAll = (e) => {
    e.preventDefault();
    const remaining = maxServices - selectedService.length;
    const newSelected = unselectedChoices.slice(0, remaining);
    setSelectedService([...selectedService, ...newSelected]);
    setUnselectedChoices(unselectedChoices.slice(remaining));
    field.onChange([...selectedService, ...newSelected]);
  };

  const handleRemoveAll = (e) => {
    e.preventDefault();
    setUnselectedChoices([...unselectedChoices, ...selectedService]);
    setSelectedService([]);
    field.onChange([]);
  };

  const debouncedLoadAvailableChecks = useRef(_.debounce(loadAvailableChecks, 500));
  const throttleLoadAvailableChecks = useRef(_.throttle(loadAvailableChecks, 500));

  useEffect(() => {
    throttleLoadAvailableChecks.current(
      true,
      search,
      serviceType ? serviceType[0] : '',
      tag ? tag[0] : '',
      maxServices
    );
  }, [tag, serviceType]);

  useEffect(() => {
    // This is to prevent the initial load from triggering the debounce the throttle useEffect above is for the initial load
    if (isMounted.current) {
      debouncedLoadAvailableChecks.current(
        true,
        search,
        serviceType ? serviceType[0] : '',
        tag ? tag[0] : '',
        maxServices
      );
    } else {
      isMounted.current = true;
    }
  }, [search]);

  useEffect(() => {
    setUnselectedChoices(serviceOptions.filter((item) => !selectedService.some((service) => service.id === item.id)));
  }, [serviceOptions, selectedService]);

  return (
    <div>
      <ServiceSelectorFilters
        search={search}
        setSearch={setSearch}
        setType={setServiceType}
        type={serviceType}
        typeOptions={serviceTypeOptions}
        setTag={setTag}
        tag={tag}
        tagOptions={tagOptions}
      />
      <ServiceSelectorListPanels
        unselectedServices={unselectedChoices}
        selectedServices={selectedService}
        serviceOptions={serviceOptions}
        maxServices={maxServices}
        onSelectService={handleSelectService}
        onSelectAll={handleSelectAll}
        onUnselectService={handleUnselectService}
        onRemoveAll={handleRemoveAll}
      />
    </div>
  );
};
