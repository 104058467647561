export interface LegacyGlobalMetricsProps {
  uptimePct: string;
  outageCnt: number | string;
  downtime: string;
}

export const LegacyGlobalMetrics = ({uptimePct, outageCnt, downtime}: LegacyGlobalMetricsProps) => (
  <div className="row history-global-metrics">
    <div className="col-sm-4 mb-2">
      <div>
        <h6>Global Uptime</h6>
        <span>{uptimePct}</span>
      </div>
    </div>
    <div className="col-sm-4 mb-2">
      <div>
        <h6>Outages</h6>
        <span>{outageCnt}</span>
      </div>
    </div>
    <div className="col-sm-4 mb-2">
      <div>
        <h6>Total Downtime</h6>
        <span>{downtime}</span>
      </div>
    </div>
  </div>
);
