import React from 'react';
import _ from 'underscore';

class ContactGroup extends React.PureComponent {
  render() {
    var lists = this.props.email_list.concat(this.props.sms_list).concat(this.props.phonecall_list);

    var buttons = null;
    if (this.props.hasWriteAccess) {
      buttons = (
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-xs dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></button>
          <div className="dropdown-menu dropdown-menu-right">
            <a href="#" className="dropdown-item" onClick={this.props.onTest}>
              Test
            </a>
            <a href="#" className="dropdown-item" onClick={this.props.onEdit}>
              Edit
            </a>
            <a href="#" className="dropdown-item" onClick={this.props.onDelete}>
              Delete
            </a>
          </div>
        </div>
      );
    }

    return (
      <tr>
        <td>{this.props.name}</td>
        {this.props.schedule_state === 'SCHEDULED' ? (
          <td className="with-schedule" title="This contact has specified on-call hours.">
            <i className="has-schedule" />
          </td>
        ) : (
          <td></td>
        )}
        <td>
          <span className="text-muted">{this.renderItemList(lists)}</span>
        </td>
        <td>
          <span className="text-muted">
            {this.renderItemList(this.props.integrations.map((id) => this.props.integrationMap[id]))}
          </span>
        </td>
        <td className="text-center">{this.props.num_services}</td>
        <td className="text-center">{this.props.num_escalations}</td>
        <td className="actions-right">{buttons}</td>
      </tr>
    );
  }

  renderItemList(list) {
    var listItems = list.map(function (v, i) {
      return (
        <li className="contact-list-item" key={i}>
          {v}
        </li>
      );
    });

    return <ul className="list-unstyled mb-0">{listItems}</ul>;
  }
}

export default class ContactGroupList extends React.PureComponent {
  render() {
    const integrationMap = _.object(this.props.choices.integration_profiles);

    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return <div>No items found.</div>;
    }

    const nodes = this.props.items.map(
      function (item, index) {
        return (
          <ContactGroup
            {...item}
            key={item.id}
            integrationMap={integrationMap}
            hasWriteAccess={this.props.hasWriteAccess}
            onTest={this.props.onTest.bind(null, index)}
            onEdit={this.props.onEdit.bind(null, index)}
            onDelete={this.props.onDelete.bind(null, index)}
          />
        );
      }.bind(this)
    );

    return (
      <div className="white-block white-block-border">
        <table id="contacts-table" className="table table-responsive-sm data-table mb-0 full-table" role="grid">
          <thead>
            <tr>
              <th>Name</th>
              <th id="contacts-on-call" className="with-schedule"></th>
              <th id="contacts-email-phone">Email / Phone</th>
              <th>Integrations</th>
              <th className="text-center">Checks</th>
              <th className="text-center">Escalations</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }
}
