import Modal from '../../jskit/react/Modal';
import {useRef, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import Formatter from '../../jskit/general/Formatter';
import Ajax from '../../jskit/general/Ajax';
import {toast} from 'react-toastify';

interface Props {
  plan: string;
  resetLocationsURL: string;
  unsupportedChecksURL: string;
}

export const ResetLocationsToPlan = ({plan, resetLocationsURL, unsupportedChecksURL}: Props) => {
  const modalRef = useRef<Modal>();
  const [checks, setChecks] = useState<{checks: any[]; total: number}>({checks: [], total: 0});
  const [loading, setLoading] = useState<boolean>(false);

  const showModal = () => {
    setLoading(true);
    new Ajax().get({
      url: `${unsupportedChecksURL}?plan=${plan}`,
      decoder: 'json',
      success: ({data: {checks, total}}) => {
        setChecks({checks, total});
        modalRef.current.showModal();
      },
      complete: () => {
        setLoading(false);
      },
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    new Ajax().post({
      url: resetLocationsURL,
      data: {
        plan,
      },
      complete: () => {
        setLoading(false);
      },
      success: () => {
        toast.success('Locations has been reset. The page is going to reload', {
          autoClose: 1000,
        });
        setTimeout(() => window.location.reload(), 1000);
      },
      encoder: 'json',
      decoder: 'json',
    });
  };
  const planDisplay = Formatter.capitalizeFirstLetter(plan);
  return (
    <>
      <Modal ref={modalRef} title="Confirm" size="md" saveButton={handleSubmit} cancelButton={true}>
        <div className="row">
          <div className="col text-left">
            <span>Are you sure you want to reset locations on existing checks to the default plan’s locations?</span>
            <span>&nbsp;No other changes to checks will be made.</span>
          </div>
        </div>
        {checks?.total > 0 && checks?.checks?.length > 0 && (
          <div className="row mt-2">
            <div className="col text-left">
              <span className="font-weight-bold mt-2">Here are top 3 checks that will be affected:</span>
              <ul className="mt-2">
                {checks?.checks?.slice(0, 3).map((c) => (
                  <li key={`check-${c.id}`}>
                    <a target="_blank" href={c.url}>
                      {c.name}
                    </a>
                  </li>
                ))}
              </ul>
              {checks?.total > 3 && <div>and {checks?.total - 3} other(s)</div>}
            </div>
          </div>
        )}
      </Modal>
      <ReactTooltip id={`reset-locations-to-${plan}`} />
      <button
        className="btn btn-sm btn-outline-danger my-2"
        onClick={showModal}
        disabled={loading}
        data-for={`reset-locations-to-${plan}`}
        data-tip={`Reset all check locations to use ${planDisplay} plan locations`}
      >
        Reset Locations to Plan
      </button>
    </>
  );
};
