import {useState, ReactNode} from 'react';

type FormBlockProps = {
  title: string;
  subtitle: string;
  children: ReactNode;
  className?: string;
};

const FormBlock = ({title, subtitle, className, children}: FormBlockProps) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className={`border-radius-sm border ${className}`}>
      <div className="bg-gray-200 p-3">
        <h4 className="font-18">{title}</h4>
        <span className="font-14 font-weight-normal">{subtitle}</span>
      </div>
      <table className="table table-fullwidth bg-white mb-0">
        <thead className="text-center">
          <tr className="user-select-none cursor-default" onClick={() => setCollapsed(!collapsed)}>
            <th className="text-left">
              <i className={`mr-2 fa fa-chevron-${collapsed ? 'down' : 'up'}`} />
              <span>Features</span>
            </th>
            <th>{/*tooltip placeholder*/}</th>
            <th>Used / Included</th>
            <th>Add Additional</th>
            <th>{/*tooltip placeholder*/}</th>
            <th>Monthly Cost</th>
          </tr>
        </thead>
        <tbody className={`collapse ${!collapsed && 'show'}`}>{children}</tbody>
      </table>
    </div>
  );
};

export default FormBlock;
