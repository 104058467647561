import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import URLHistory from '../../jskit/general/URLHistory';
import ContactGroupList from './ContactGroupsList.jsx';
import ContactGroupForm from './ContactGroupsForm.jsx';
import MessageBox from '../../js/global/messagebox';

export default class ContactGroupsController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      choices: {},
      items: [],
      validationErrors: {},
    };
  }

  componentDidMount() {
    // You can pass pop=1 for a new item or pop=PKID for editing on the fly
    this.loadAllData(URLHistory.queryStringToObject().pop, true);
  }

  loadAllData(load_pop, withChoices) {
    new Ajax().post({
      url: this.props.listURL + (withChoices ? '?choices=1' : ''),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.setState({items: data.items});

        if (data.choices) {
          this.setState({choices: data.choices});
        }

        if (load_pop !== undefined) {
          var id = null;
          if (load_pop > 1) {
            // Find index of item by PK
            _.each(data.items, function (v, k) {
              if (v.id === load_pop) {
                id = k;
              }
            });
          }

          _.delay(
            function () {
              this.showEditForm(id);
            }.bind(this)
          );
        }
      }.bind(this),
    });
  }

  showModalWithMessage(title, msg) {
    MessageBox.alertBox(msg, title);
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handleTestItemClick(itemIndex, e) {
    e.preventDefault();
    var item = this.state.items[itemIndex];
    var msg = 'Do you want to send test notifications to contact "' + item.name + '"?';

    MessageBox.confirmBox(
      msg,
      'Test Contact',
      function () {
        var ajax = new Ajax();
        ajax.showWaitCursor(true);

        ajax.post({
          url: this.props.testURL,
          data: {id: item.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (data.success) {
              this.showModalWithMessage('Test Complete', 'Test notifications have been sent to "' + item.name + '".');
            }
          }.bind(this),
          complete: function () {
            ajax.showWaitCursor(false);
          },
        });
      }.bind(this)
    );
  }

  handleAddItemClick(e) {
    e.preventDefault();
    this.showEditForm(null);
  }

  handleEditItemClick(itemIndex, e) {
    e.preventDefault();
    this.showEditForm(itemIndex);
  }

  showEditForm(itemIndex) {
    var state;
    this.clearValidationErrors();

    if (null === itemIndex) {
      state = {schedule_state: 'ACTIVE'};
    } else {
      state = _.clone(this.state.items[itemIndex]);
    }

    state.schedule_timezone = state.schedule_timezone || this.state.choices.default_timezone;
    this.refs.form.showModal(state);
  }

  handleDeleteItemClick(itemIndex, e) {
    e.preventDefault();
    var item = this.state.items[itemIndex];
    var msg = 'Are you sure you want to delete contact "' + item.name + '"?';

    MessageBox.confirmBox(
      msg,
      'Delete Contact',
      function () {
        new Ajax().post({
          url: this.props.deleteURL,
          data: {id: item.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (!data.success) {
              this.showModalWithMessage('Unable to Delete Contact', data.fields.is_deleted[0]);
            } else {
              this.refs.form.hideModal();
              this.loadAllData();
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handleFormSaveClick(e) {
    e.preventDefault();
    this.clearValidationErrors();

    new Ajax().post({
      url: this.props.saveURL,
      data: this.refs.form.getFormData(),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.loadAllData();
          this.refs.form.hideModal();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="heading-container">
          <h1 className="heading">Contacts</h1>
          {this.props.hasWriteAccess ? (
            <button data-wizard="contacts-add-new" onClick={this.handleAddItemClick} className="btn btn-primary">
              New Contact
            </button>
          ) : null}
        </div>

        <ContactGroupList
          hasWriteAccess={this.props.hasWriteAccess}
          hasLoaded={!_.isEmpty(this.state.choices)}
          items={this.state.items}
          choices={this.state.choices}
          onTest={this.handleTestItemClick}
          onEdit={this.handleEditItemClick}
          onDelete={this.handleDeleteItemClick}
        />
        <ContactGroupForm
          ref="form"
          contactsGetURL={this.props.contactsGetURL}
          integrationAddURL={this.props.integrationAddURL}
          showIntegrations={this.props.showIntegrations}
          choices={this.state.choices}
          errors={this.state.validationErrors}
          onSave={this.handleFormSaveClick}
        />
      </React.Fragment>
    );
  }
}
