import {PropsWithChildren} from 'react';
import css from './Toolbar.module.css';
import ReactUtils from '@/jskit/react/ReactUtils';

export interface ToolbarProps extends PropsWithChildren {
  className: string;
  containerClassName?: string;
}

export const Toolbar = ({className, containerClassName, children}: ToolbarProps) => {
  return (
    <div
      className={ReactUtils.cssClass(
        'd-flex position-relative justify-content-center justify-content-md-end',
        css.Toolbar,
        containerClassName
      )}
    >
      <div className={className}>{children}</div>
    </div>
  );
};
