'use strict';

import $ from 'jquery';
import moment from 'moment';

/*
 * Updates a counter built from HTML elements to its current value,
 * based on the given start (as at 1-1-2015) & rate (per hour) values.
 *
 * Specifier: <div
 *                data-counter
 *                data-start="123456789"
 *                data-rate="7.345"
 *                data-class="banner-counter-digit"
 *                />
 *                ...
 *
 */
export function initializeCounters() {
  $('[data-counter]').each(function () {
    var container = $(this);
    var cssClass = container.data('class') || 'banner-counter-digit';
    var start = parseFloat(container.data('start')) || 0;
    var rate = parseFloat(container.data('rate')) || 1;
    var hoursSince2015 = (moment.utc() - moment.utc('20150101')) / 1000 / 60 / 60;
    var value = Math.round(start + rate * hoursSince2015);

    for (var i = 1; value > 0; i++) {
      var elem = $('<span/>')
        .addClass(cssClass)
        .text(parseInt(value % 10));
      if (i % 3 === 0) {
        elem.addClass('ml-3');
      }
      container.prepend(elem);
      value = Math.floor(value / 10);
    }
  });
}
