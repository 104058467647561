type BannerProps = {
  className?: string;
  accountUsageURL: string;
};

const Banner = ({className, accountUsageURL}: BannerProps) => (
  <div className={`bg-white p-3 d-flex border-radius-sm border ${className}`}>
    <div>
      <i className="fa text-gray-2 fa-info-circle mr-2"></i>
    </div>
    <div>
      <span className="text-14 font-weight-normal">
        Limits shown in this page refer to your main account only, to see full limits please view{' '}
        <a href={accountUsageURL} className="text-dark text-underline cursor-pointer">
          Account Usage
        </a>
        . To add checks to sub accounts please use the main account to add checks and contact support to allocate these
        checks to sub accounts.
      </span>
    </div>
  </div>
);

export default Banner;
