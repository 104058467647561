'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export default class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className={ReactUtils.cssClass('custom-control custom-checkbox', this.props.divCSSClass)}>
        <input
          type="checkbox"
          id={this.htmlId}
          name={form.fieldName}
          className={ReactUtils.cssClass('custom-control-input', {
            ['is-invalid']: form.errors && !this.props.suppressErrorClass,
          })}
          disabled={this.props.disabled}
          checked={form.value}
          onChange={form.onChange}
        />

        <Label baseCSSClass="custom-control-label" fieldId={this.htmlId} {...this.props} />
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

CheckBox.defaultProps = {
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  isRequired: false, // Field is required?
  disabled: false, // Control is disabled?

  divCSSClass: null, // Extra CSS classes to add to the div.form-check

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName
  suppressErrorClass: false, // suppress is-invalid even if has errors

  onChange: null, // Change handler
};
