import Popup from 'reactjs-popup';
import React from 'react';
import SubscriptionCreateForm from '@/status_pages/display/SubscriptionCreateForm';
import {StatusPage} from '@/status_pages/types';

export interface SubscribeButtonProps {
  statuspage: StatusPage;
  isOpen: boolean;
  errors?: Record<string, string[]>;
  onCreateOrUpdateSubscription: () => void;
  onClearValidationErrors: () => void;
  slackOAuthInitUrl: string;
  rssUrl: string;
  termsConditionsUrl: string;
  privacyPolicyUrl: string;
  popupTrigger: React.ReactElement;
}

export const SubscribeButton = ({
  statuspage,
  isOpen,
  errors,
  onCreateOrUpdateSubscription,
  onClearValidationErrors,
  rssUrl,
  privacyPolicyUrl,
  termsConditionsUrl,
  slackOAuthInitUrl,
  popupTrigger,
}: SubscribeButtonProps) => {
  const hasAnySubscriptionMethod = [
    statuspage.allow_subscriptions_slack,
    statuspage.allow_subscriptions_sms,
    statuspage.allow_subscriptions_rss,
    statuspage.allow_subscriptions_webhook,
    statuspage.allow_subscriptions_email,
  ].reduce((acc, cur) => acc || cur, false);
  if (!hasAnySubscriptionMethod) {
    return false;
  }
  return (
    <Popup
      trigger={popupTrigger}
      open={isOpen}
      position="bottom center"
      contentStyle={{width: 368}}
      arrow={false}
      offsetX={10}
      offsetY={10}
    >
      <SubscriptionCreateForm
        errors={errors}
        onSubmit={onCreateOrUpdateSubscription}
        clearValidationErrors={onClearValidationErrors}
        allowSubscriptionSlack={statuspage.allow_subscriptions_slack}
        allowSubscriptionSMS={statuspage.allow_subscriptions_sms}
        allowSubscriptionRSS={statuspage.allow_subscriptions_rss}
        allowSubscriptionWebhook={statuspage.allow_subscriptions_webhook}
        allowSubscriptionEmail={statuspage.allow_subscriptions_email}
        slackOAuthInitURL={slackOAuthInitUrl}
        rssLink={rssUrl}
        termsConditionsURL={termsConditionsUrl}
        privacyPolicyURL={privacyPolicyUrl}
      />
    </Popup>
  );
};
