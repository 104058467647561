import React, {useEffect, useRef} from 'react';
import Modal from '@/jskit/react/Modal';

interface ConfirmationModalProps {
  children: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  showModal: boolean;
}

export const ConfirmationModal = ({children, onConfirm, onCancel, showModal}: ConfirmationModalProps) => {
  const modalRef = useRef<Modal>();
  useEffect(() => {
    if (showModal) {
      modalRef.current.showModal();
    } else {
      modalRef.current.hideModal();
    }
  }, [showModal]);

  return (
    <>
      <Modal
        ref={modalRef}
        title="Confirm"
        size="md"
        saveButton={() => {
          onConfirm();
          modalRef.current.hideModal();
        }}
        onHideComplete={() => {
          onCancel();
        }}
      >
        {children}
      </Modal>
    </>
  );
};
