import React, {useEffect, useState} from 'react';
import {ComposableMap, ZoomableGroup, Geographies, Geography, Marker} from 'react-simple-maps';
import worldMap from './world-map.json';
import ReactTooltip from 'react-tooltip';

const WorldMap = ({data, markers, valueTooltip, markerTooltip}) => {
  const [mapData, setMapData] = useState([]);
  const [tooltip, setTooltip] = useState('');
  const [position, setPosition] = useState({coordinates: [0, 0], zoom: 1});

  useEffect(() => {
    setMapData(data || []);
    // Reduce whitespace around the map, and clip off as much of Antarctica as we can.
    const mapContainer = document.querySelector('.load-time-world-map');
    if (!mapContainer) {
      return;
    }
    const mapSvg = mapContainer.querySelector('.rsm-svg');
    if (mapSvg) {
      // Numbers figured out by manually moving viewBox around in browser.
      mapSvg.setAttribute('viewBox', '320 100 240 370');
    }
  }, [data]);

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => Object.assign({}, pos, {zoom: pos.zoom * 2}));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => Object.assign({}, pos, {zoom: pos.zoom / 2}));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }

  return (
    <div id="world-map-container">
      <ComposableMap
        data-tip=""
        projectionConfig={{
          rotate: [-5, 0, 0],
          scale: 155,
        }}
        style={{
          width: '100%',
          height: '500px',
          outline: 'none',
        }}
      >
        <ZoomableGroup zoom={position.zoom} center={position.coordinates} onMoveEnd={handleMoveEnd}>
          <Geographies geography={worldMap}>
            {({geographies}) =>
              geographies.map((geo) => {
                const datum = mapData.find((s) => s.ISO2 === geo.properties.ISO_A2);
                let fill;
                if (geo.properties.SUBREGION === 'Antarctica') {
                  fill = '#ffffff';
                } else if (datum) {
                  fill = datum.color;
                } else {
                  fill = '#dedede';
                }
                geo.datum = datum;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={fill}
                    style={{
                      default: {outline: 'none'},
                      hover: {outline: 'none'},
                      pressed: {outline: 'none'},
                    }}
                    onMouseEnter={() => {
                      setTooltip(valueTooltip ? valueTooltip(geo, datum) : '');
                    }}
                    onMouseLeave={() => {
                      setTooltip('');
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
        {markers.map(({name, coordinates, color, value}) => (
          <Marker
            key={name}
            coordinates={coordinates}
            onMouseEnter={() => {
              setTooltip(markerTooltip ? markerTooltip(name, value) : '');
            }}
            onMouseLeave={() => {
              setTooltip('');
            }}
          >
            <g
              fill="none"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(-12, -24)"
            >
              <path
                // https://medium.com/welldone-software/map-pins-using-svg-path-9fdfebb74501
                d="M 0,0 L -5.899,-9.231 A 7 7 1 1 1 5.899,-9.231 L 0,0 z"
                transform="translate(12, 30)"
                fill="#ffffff"
                strokeWidth="0.5"
              />
              <circle cx="12" cy="16.5" r="5.5" fill={color} stroke="none" />
            </g>
          </Marker>
        ))}
      </ComposableMap>
      <div id="world-map-controls">
        <button onClick={handleZoomIn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
        <button onClick={handleZoomOut}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
      </div>
      <ReactTooltip html={true}>{tooltip}</ReactTooltip>
    </div>
  );
};

export default WorldMap;
