import React from 'react';

export class AlertNotification extends React.Component {
  render() {
    if (this.props.message === undefined || this.props.message === null) return '';
    return (
      <div className="d-flex alertNotification">
        <img src="/static/img/billing/subscription/alert.svg" alt="alert icon" />
        <p>{this.props.message}</p>
      </div>
    );
  }
}
