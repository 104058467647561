import React from 'react';
import Utils from '../../jskit/general/Utils';
import Formatter from '../../jskit/general/Formatter';
import {unpackFormLinkOrProps, CustomEvent} from '../../jskit/react/forms/FormHelpers';
import './StepInput.css';

import $ from 'jquery';

export default class StepInput extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  componentWillUnmount() {
    this.resetTooltips();
  }

  onChange(form, val) {
    const event = new CustomEvent({type: 'change', target: this, value: val});
    form.onChange(event);
  }

  resetTooltips() {
    $(this.refs.minusIcon).tooltip('dispose');
    $(this.refs.plusIcon).tooltip('dispose');
  }

  onIncreaseVal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.resetTooltips();
    const form = unpackFormLinkOrProps(this.props);
    const value = form.value ? form.value : 0;
    const step = this.props.step ? this.props.step : 1;
    if (this.props.maxValue !== undefined && this.props.maxValue !== null) {
      if (value + step > this.props.maxValue) {
        $(this.refs.plusIcon).tooltip({title: this.props.maxValueMsg}).mouseover();
        if (typeof this.props.onReachedMax === 'function') {
          this.props.onReachedMax();
        }
        return;
      }
    }
    const newVal = value + step;
    this.onChange(form, newVal);
  }

  onDecreaseVal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.resetTooltips();
    const form = unpackFormLinkOrProps(this.props);
    const value = form.value ? form.value : 0;
    const minValue = this.props.minValue !== null && this.props.minValue !== undefined ? this.props.minValue : 0;
    const step = this.props.step ? this.props.step : 1;
    if (value - step < minValue) {
      $(this.refs.minusIcon).tooltip({title: this.props.minValueMsg}).mouseover();
      return;
    }
    const newVal = value - step;
    this.onChange(form, newVal);
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);
    const value = form.value ? form.value : 0;
    return (
      <div id="step-input">
        <button className="py-1 px-3 btn btn-lg bg-white border-radius-md" ref="minusIcon" onClick={this.onDecreaseVal}>
          -
        </button>
        <span className="value mx-4">{Formatter.humanFriendlyInt(value)}</span>
        <button className="py-1 px-3 btn btn-lg bg-white border-radius-md" ref="plusIcon" onClick={this.onIncreaseVal}>
          +
        </button>
      </div>
    );
  }
}

StepInput.defaultProps = {
  formLink: null,
  step: 1,
  maxValue: null,
  minValue: 0,
  minValueMsg: 'This value can not be decreased',
  maxValueMsg: 'This value can not be increased',
};
