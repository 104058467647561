'use strict';

import React from 'react';
import ReactUtils from '../jskit/react/ReactUtils';
import Ajax from '../jskit/general/Ajax';
import Utils from '../jskit/general/Utils';
import Select2 from '../jskit/react/forms/Select2';
import {prepareFormLink} from '../jskit/react/forms/FormHelpers';
import Validators from '../jskit/general/Validators';
import {TaskStatus} from '../devices/common/TaskStatus';
import MessageBox from '../js/global/messagebox';

import PagespeedReport from '../pagespeed/PagespeedReport.jsx';

interface State {
  formVisible: boolean;
  progressFormVisible: boolean;
  advancedFormVisible: boolean;
  reportVisible: boolean;
  isRunningTest: boolean;
  taskStatus: string;
  taskExecutionPercent: number;
  loadTimer: number;
  waitTimer: number;
  websitePreview: string;
  formData: PagespeedFormFields;
  reportQuery: any;
}

interface Props {
  choices: Record<any, any>;
  form_action: string;
  reportProps: any;
}

interface PagespeedFormFields {
  url: string;
  location: string;
  device: string;
  throttling: string;
}

const MAX_EXECUTION_TIME = 2 * 60; // 2 minutes

export default class PagespeedTestController extends React.Component<Props, State> {
  locations_map = {};
  devices_map = {};
  throttling_map = {};

  constructor(props: Props) {
    super(props);
    Utils.autoBindClass(this, {});

    for (const v of props.choices.monitoring_groups) {
      this.locations_map[v[0]] = v[1];
    }
    for (const v of props.choices.emulated_device) {
      this.devices_map[v[0]] = v[1];
    }
    for (const v of props.choices.connection_throttling) {
      this.throttling_map[v[0]] = v[1];
    }

    this.state = {
      formVisible: true,
      advancedFormVisible: false,
      progressFormVisible: false,
      reportVisible: false,
      isRunningTest: false,
      taskStatus: TaskStatus.NEW,
      taskExecutionPercent: 0,
      loadTimer: 0,
      waitTimer: 0,
      websitePreview: '',
      formData: {
        url: '',
        location: props.choices.monitoring_groups[0][0],
        device: 'DEFAULT',
        throttling: 'UNTHROTTLED',
      },
      reportQuery: null,
    };
  }

  handleAdvancedClick(e: React.MouseEvent<HTMLAnchorElement>) {
    this.setState({
      advancedFormVisible: !this.state.advancedFormVisible,
    });
    e.preventDefault();
  }

  isFormValid(): boolean {
    return Validators.isValidURL(this.state.formData.url);
  }

  resetRunTask() {
    this.setState({
      isRunningTest: false,
      taskStatus: '',
      taskExecutionPercent: 0,
    });
  }

  handleFormSubmit(e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!this.isFormValid()) {
      return;
    }
    this.resetRunTask();

    new Ajax().post({
      url: this.props.form_action,
      data: this.state.formData,
      encoder: 'json',
      decoder: 'json',
      success: function (result) {
        if (result.error) {
          let message = result.error;
          if (result.fields) {
            message += ' ';
            Object.keys(result.fields).forEach(function (key) {
              message += key + ': ' + result.fields[key];
            });
          }
          MessageBox.alertBox(message, 'Error');
          return;
        }
        this.setState({
          formVisible: false,
          progressFormVisible: true,
          isRunningTest: true,
          loadTimer: 0,
          waitTimer: 0,
        });
        window.setTimeout(() => {
          this.pollProgress(result.data.test_id);
        }, 1000);
      }.bind(this),
    });
  }

  pollProgress(test_id: number) {
    if (this.state.loadTimer > MAX_EXECUTION_TIME) {
      this.resetRunTask();
      MessageBox.alertBox('Task execution timeout', 'Error');
      return;
    }
    let preview = '';
    if (!this.state.websitePreview) {
      preview = '&preview=t';
    }
    new Ajax().get({
      url: this.props.form_action + `?test_id=${test_id}${preview}`,
      decoder: 'json',
      success: (result) => {
        const data = result.data;
        if (result.error || data.status === TaskStatus.FAILED) {
          this.setState({
            taskStatus: data.status,
            taskExecutionPercent: 100,
          });
          MessageBox.alertBox(data.error, 'Error');
        } else if (data.status === TaskStatus.NEW) {
          this.setState({
            waitTimer: this.state.waitTimer + 2,
          });
          window.setTimeout(() => {
            this.pollProgress(test_id);
          }, 2000);
          // TODO: do we need to handle somehow long queue waits?
        } else if (data.status === TaskStatus.RUNNING) {
          const newState = {
            loadTimer: this.state.loadTimer + 2,
            taskStatus: data.status,
            taskExecutionPercent: Math.round((this.state.loadTimer / MAX_EXECUTION_TIME) * 100),
          };
          if (data.website_preview) {
            newState['websitePreview'] = data.website_preview;
          }
          this.setState(newState);
          window.setTimeout(() => {
            this.pollProgress(test_id);
          }, 2000);
        } else if (data.status === TaskStatus.COMPLETED) {
          this.setState({
            taskStatus: data.status,
            taskExecutionPercent: 100,
            reportQuery: {
              freetools_test_id: test_id,
            },
            reportVisible: true,
          });
          //window.document.location = data.result_url;
        }
      },
    });
  }

  formatMonitoringGroup(val: {id: string; text: string}) {
    if (this.props.choices.monitoring_group_to_country[val.id]) {
      const el = document.createElement('span');
      el.innerHTML =
        '<span class="flag-icon flag-icon-' +
        this.props.choices.monitoring_group_to_country[val.id] +
        '"></span> ' +
        val.text;
      return el;
    }
    return val.text;
  }

  updateFormFieldURL(event) {
    this.setState({formData: {...this.state.formData, url: event.target.value}});
  }

  renderForm() {
    const formLink = prepareFormLink(this, 'formData', {
      url: Validators.isValidURL(this.state.formData.url) ? null : 'Ivalid URL',
    });

    return (
      <div className="h-100 mt-md-5 mt-3">
        <div className="pagespeed-header p-lg-4">
          <h1>
            <span className="secondary">How fast does your website load?</span>
            <br />
            Find out with <span className="uptime-underline">Uptime</span>
          </h1>
        </div>
        <div className="pagespeed-form white-block p-4">
          <form onSubmit={this.handleFormSubmit}>
            {/* eslint-disable-next-line max-len */}
            <div className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-start mb-4 pagespeed-form-control">
              <input
                type="text"
                className={ReactUtils.cssClass('flex-grow-1 form-control form-control-lg', {
                  error: this.state.formData.url && !Validators.isValidURL(this.state.formData.url),
                })}
                placeholder="Enter a URL like https://example.com..."
                value={this.state.formData.url}
                onChange={this.updateFormFieldURL}
              />
              <button className="btn btn-lg btn-primary" disabled={!this.isFormValid()} onClick={this.handleFormSubmit}>
                Test Your Site
              </button>
            </div>
          </form>
          <div className="mb-1">
            Analysis Option: Testing in <strong>{this.locations_map[this.state.formData.location]}</strong> using{' '}
            <strong>{this.devices_map[this.state.formData.device]}</strong> with{' '}
            <strong>{this.throttling_map[this.state.formData.throttling]} Connection</strong>
          </div>
          <div className="pagepseed-test-advanced-toggle my-2">
            <a className="text-brand-green" href="#" onClick={this.handleAdvancedClick}>
              Advanced options <i className="fa fa-chevron-down"></i>
            </a>
          </div>
          <div
            className={ReactUtils.cssClass({
              'd-none': !this.state.advancedFormVisible,
            })}
          >
            <div className="row">
              <div className="col-md-4">
                <Select2
                  fieldName="location"
                  className="form-control"
                  labelText="Location"
                  choices={this.props.choices.monitoring_groups}
                  templateResult={this.formatMonitoringGroup}
                  templateSelection={this.formatMonitoringGroup}
                  formLink={formLink}
                />
              </div>
              <div className="col-md-4">
                <Select2
                  className="form-control"
                  labelText="Device"
                  choices={this.props.choices.emulated_device}
                  fieldName="device"
                  formLink={formLink}
                />
              </div>
              <div className="col-md-4">
                <Select2
                  className="form-control"
                  labelText="Throttling"
                  choices={this.props.choices.connection_throttling}
                  fieldName="throttling"
                  formLink={formLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderProgressForm() {
    let stage = `Waiting in queue...${this.state.waitTimer}s`;
    if (this.state.taskStatus === TaskStatus.RUNNING) {
      stage = 'Running test...';
    } else if (this.state.taskStatus === TaskStatus.COMPLETED) {
      stage = 'Test completed';
    } else if (this.state.taskStatus === TaskStatus.FAILED) {
      stage = 'Test failed';
    }
    return (
      <div className="mt-4 mt-lg-5">
        <div className="row">
          <div className="col-md-6">
            <h1>{stage}</h1>
            <div className="progress my-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow={this.state.taskExecutionPercent}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{width: this.state.taskExecutionPercent + '%'}}
              />
            </div>
            <h3>{this.state.formData.url}</h3>
            <div className="row">
              <div className="col-md-6">
                <span className="text-muted">Test Server Location:</span>
                <br />
                {this.locations_map[this.state.formData.location]}
              </div>
              <div className="col-md-6">
                <span className="text-muted">Using:</span>
                <br />
                {this.devices_map[this.state.formData.device]}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            {this.state.websitePreview == '' ? (
              <img className="img-fluid mt-4 mt-lg-0" src="/static/img/freetools/FreeTools_Pagespeed_Test.webp" />
            ) : (
              <img className="img-fluid website-preview" src={this.state.websitePreview} />
            )}
          </div>
        </div>
        <div className="row my-4">
          <div className="col">
            <div className="interesting-facts white-block p-3">
              <h5>Did you know?</h5>
              <ul>
                <li>
                  Uptime.com analyzes 1000’s of URL’s everyday! We have the most complete network of Page Performance
                  check servers and infrastructure.
                </li>
                <li>
                  Once the test is completed, use our share report feature to share the page performance of your site
                  with others in your team or download the PDF.
                </li>
                <li>
                  Sign-up for our free 14-day trial and create Page Speed checks that run on a daily basis from our wide
                  network of page speed servers.
                </li>
                <li>
                  Our advanced alert engine can alert you when your page is performing poorly before customers report
                  webpage performance degradation.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderReport() {
    return (
      <PagespeedReport
        {...this.props.reportProps}
        query={this.state.reportQuery}
        onDataLoaded={() => this.setState({progressFormVisible: false})}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.formVisible && this.renderForm()}
        {this.state.progressFormVisible && this.renderProgressForm()}
        {this.state.reportVisible && this.renderReport()}
      </React.Fragment>
    );
  }
}
