import React from 'react';
import Modal from '../../jskit/react/Modal';
import Formatter from '../../jskit/general/Formatter';

export default class IntegrationErrorLogModal extends React.Component {
  showModal() {
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  render() {
    var rows = null,
      name = null;

    if (this.props.data !== undefined) {
      name = this.props.data.name;
      if (this.props.data.errors.length > 0) {
        rows = this.props.data.errors.map((error) => {
          return (
            <tr>
              <td className="text-nowrap">{Formatter.longDateTime(error.created_at)}</td>
              <td>{error.event}</td>
              <td>{error.short_error}</td>
            </tr>
          );
        });
      } else {
        rows = (
          <tr>
            <td colSpan="3">No errors logged</td>
          </tr>
        );
      }
    }

    return (
      <Modal ref="modal" title={'Recent Errors: ' + name} size="lg" closeButton={true}>
        <div className="row">
          <div className="col-lg">
            <div className="table-responsive">
              <table className="table table-sm">
                <thead className="thead-light">
                  <tr>
                    <th>Date</th>
                    <th>Event</th>
                    <th>Error</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
