'use strict';

import React from 'react';
import _ from 'underscore';
import MessageBox from '../../js/global/messagebox';
import Utils from '../../jskit/general/Utils';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import SubscribeComponent from './SubscriptionComponent.jsx';

export default class SubscriptionManageForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.allComponents = _.reduce(
      this.props.statuspage.components,
      (a, cmp) => {
        if (!cmp.is_group) {
          a.push(cmp.id);
        } else {
          cmp.subcomponents.forEach((subcmp) => a.push(subcmp.id));
        }
        return a;
      },
      []
    );
    const formData = _.reduce(
      this.allComponents,
      (a, cmpId) => {
        a[cmpId] = this.props.subscriptionComponents.includes(cmpId) || this.props.initialSubscribe;
        return a;
      },
      {}
    );

    this.state = {
      formData,
    };
  }

  componentDidMount() {
    if (this.props.forceUnsubscribe) {
      this.handleSaveClick();
    }
  }

  handleSaveClick() {
    const selectedComponentIds = _.map(
      _.filter(_.pairs(this.state.formData), (k) => k[1]),
      (k) => k[0] * 1
    );
    if (!selectedComponentIds.length) {
      if (this.props.initialSubscribe) {
        MessageBox.alertBox('Please select at least one component to subscribe to.', 'Subscribe');
      } else {
        this.props.onUnsubscribe();
      }
    } else {
      this.handleSave(selectedComponentIds);
    }
  }

  handleCancelClick() {
    return this.props.onCancel && this.props.onCancel();
  }

  handleSave(selectedComponentIds) {
    return this.props.onSave && this.props.onSave(selectedComponentIds);
  }

  allComponentsSelected() {
    return _.reduce(this.allComponents, (acc, cmpId) => acc && this.state.formData[cmpId], true);
  }

  setAllComponents(e) {
    e.preventDefault();
    const newSelectionState = !this.allComponentsSelected();
    const newFormData = _.reduce(
      this.allComponents,
      (a, cmp) => {
        a[cmp] = newSelectionState;
        return a;
      },
      {}
    );
    this.setState({formData: newFormData});
  }

  render() {
    const sp = this.props.statuspage;
    const formLink = prepareFormLink(this, 'formData');
    const {initialSubscribe} = this.props;
    const title = initialSubscribe
      ? 'Select the components you want to subscribe to'
      : 'Update your Subscription below';

    let subscriptionTargetInfo = (
      <React.Fragment>
        <div className="subscriberName">
          Subscriber: <strong>{this.props.subscriptionTarget}</strong>
        </div>
        <hr />
      </React.Fragment>
    );

    if (this.props.subscriptionType === 'SLACK') {
      subscriptionTargetInfo = (
        <React.Fragment>
          <div className="subscriberName">
            <i className="fa-brands fa-slack font-20" /> Slack Workspace&nbsp;
            <strong>{this.props.subscriptionTargetExtras.workspaceName}</strong>
            <br />
            <i className="fa-brands fa-slack font-20" /> Slack Channel&nbsp;
            <strong>{this.props.subscriptionTargetExtras.channelName}</strong>
            <br />
          </div>
          <hr />
        </React.Fragment>
      );
    }

    return (
      <div id="statuspagesSubscriptionManage" className="subscriptionContainer">
        <div className="white-block white-block-border p-4">
          <h2 className="mb-3">{sp.name}</h2>
          <hr />
          {subscriptionTargetInfo}
          <h2 className="mb-3">{title}:</h2>
          <div className="subscriptionComponentList">
            {this.renderHeader()}
            <FormErrors errors={this.props.errors ? this.props.errors.__all__ : null} />
            {this.renderComponents(formLink)}
          </div>
        </div>
      </div>
    );
  }

  renderHeader() {
    if (this.allComponents.length) {
      return (
        <React.Fragment>
          <div className="row mb-3 ml-0 mr-0">
            <div className="col pl-2 selectAllContainer">
              <a href="#" className="selectAll d-block align-bottom" onClick={this.setAllComponents}>
                {this.allComponentsSelected() ? 'Deselect All' : 'Select All'}
              </a>
            </div>
            <div className="col text-right">
              <button className="btn btn-primary" onClick={this.handleSaveClick}>
                Save
              </button>
            </div>
          </div>
          <hr />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="row mb-4">
            <div className="col">You are about to completely unsubscribe from the status page updates.</div>
          </div>
          <div className="row">
            <div className="col">
              <button className="btn btn-cancel mr-4" onClick={this.handleCancelClick}>
                Cancel
              </button>
              <button className="btn btn-primary" onClick={this.handleSaveClick}>
                Unsubscribe
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderComponents(formLink) {
    const sp = this.props.statuspage;
    const components = sp.components.map((cmp) => (
      <SubscribeComponent key={cmp.id} component={cmp} formLink={formLink} />
    ));
    return <div className="component-list">{components}</div>;
  }
}

SubscriptionManageForm.defaultProps = {
  statuspage: null,
  subscriptionComponents: [],
  onSave: null,
  onCancel: null,
  onUnsubscribe: null,
  initialSubscribe: false,
};
