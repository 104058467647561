import {StatusPage, UptimeCalculationType} from '@/status_pages/types';
import {addSeconds, endOfDay} from 'date-fns';
import {TZDateMini} from '@date-fns/tz';
import {renderToStaticMarkup} from 'react-dom/server';
import {BarData, BarType, defaultBarOptions, UptimeBar, UptimeBarPill} from '@uptime-com/uptimebar';
import '@uptime-com/uptimebar/styles';
import moment from 'moment';
import React from 'react';
import {InspireDateRangeSnakePopup} from '@/status_pages/display/components/theme-specific/inspire/snake/InspireDateRangeSnakePopup';
import './InspireDateRangeSnake.module.css';
import {
  generateEventsFromCheckDowntimes,
  generateEventsFromIncidents,
} from '@/status_pages/display/components/theme-specific/inspire/snake/utils';

export interface InspireDateRangeSnakeProps {
  historyStartDate: object;
  historyEndDate: object;
  statuspage: StatusPage;
}

const toolTipWrapper = (data: BarData): string =>
  renderToStaticMarkup(
    <div style={{margin: '-7px'}}>
      <InspireDateRangeSnakePopup data={data} />
    </div>
  );

export const InspireDateRangeSnake = ({statuspage, historyStartDate, historyEndDate}: InspireDateRangeSnakeProps) => {
  // check if the interval is either less than 24 hrs, or 5-30 days;
  // otherwise, hide the snake as this is not presently supported
  const L_BREAKPOINT = 20;
  const CHART_X_XL = 1170;
  const CHART_Y_XL = 120;
  const CHART_X_L = 600;
  const CHART_Y_L = 80;
  const start = new TZDateMini(moment(historyStartDate).format(), statuspage.used_timezone);
  const end = addSeconds(endOfDay(new TZDateMini(moment(historyEndDate).format(), statuspage.used_timezone)), 1);
  const differenceHours = Math.round(moment.duration(moment(end).diff(moment(start))).asHours());
  const barType = differenceHours <= 24 * 2 ? BarType.Hour : BarType.Day;
  // only display snake for periods < 2 days (in hourly mode) or between 5..31 days
  const shouldDisplaySnake =
    (differenceHours >= 5 && differenceHours <= 24 * 2) || (differenceHours >= 24 * 5 && differenceHours <= 31 * 24);
  if (!shouldDisplaySnake) {
    return false;
  }
  const events =
    statuspage.uptime_calculation_type == UptimeCalculationType.BY_CHECKS
      ? generateEventsFromCheckDowntimes(statuspage, start, end)
      : generateEventsFromIncidents(statuspage, start, end);
  const breakL = differenceHours / 24 > L_BREAKPOINT || (barType == BarType.Hour && differenceHours > 24);
  const width = breakL ? CHART_X_XL : CHART_X_L;
  const height = breakL ? CHART_Y_XL : CHART_Y_L;
  return (
    <UptimeBar
      width={width}
      height={height}
      start={start}
      end={end}
      events={events}
      barType={barType}
      config={{
        ...defaultBarOptions,
        tooltip: toolTipWrapper,
        defaultClassName: UptimeBarPill.SUCCESS,
      }}
      timezone={statuspage.used_timezone}
    />
  );
};
