import ReactTooltip from 'react-tooltip';
import {useState} from 'react';

interface Props {
  tooltip: string;
  className?: string;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  iconClass: string;
}

export const TooltipIcon = ({tooltip, iconClass, className, placement}: Props) => {
  const [randomID] = useState(String(Math.random()));
  return (
    <>
      <ReactTooltip className={className} id={randomID} place={placement} />
      <i data-tip={tooltip} data-for={randomID} className={iconClass} />
    </>
  );
};
