'use strict';

import _ from 'underscore';
import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import Utils from '../../jskit/general/Utils';

import $ from 'jquery';

/**
 * Uses pills to navigate between form screens.
 */
export class SectionNav extends React.PureComponent {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  componentDidUpdate(prevProps) {
    // Check if we need to change our section to point to the first validation error
    // after a form submit
    if (_.isEmpty(prevProps.errors) && !_.isEmpty(this.props.errors)) {
      var section = $(this.refs.sections)
        .find('[data-section]:has(.is-invalid), [data-section]:has(.alert), [data-section]:has(.invalid-feedback)')
        .data('section');
      if (section) {
        this.handleSectionClick(section);
      }
    }
  }

  handleSectionClick(sectionId, e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onSectionNav(sectionId);
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className={this.props.gridClass}>
            <nav className={this.props.navClass} role="tablist">
              {this.renderSectionLinks()}
            </nav>
          </div>
        </div>
        <div ref="sections" className={ReactUtils.cssClass('tab-content', this.props.contentClass)}>
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }

  renderSectionLinks() {
    return this.props.sectionIds.map((p) => {
      const [sectionId, sectionLabel] = p;
      const active = sectionId === this.props.currentSectionId;
      const cssClass = ReactUtils.cssClass('nav-item nav-link', {active: active});
      return (
        <a
          href="#"
          key={sectionId}
          className={cssClass}
          id={'section-nav-' + sectionId}
          role="tab"
          aria-selected={active}
          aria-controls={'section-content-' + sectionId}
          onClick={this.handleSectionClick.bind(null, sectionId)}
        >
          {sectionLabel}
        </a>
      );
    });
  }
}

SectionNav.defaultProps = {
  sectionIds: [], // A list of 2-tuples [sectionId, sectionLabel]
  currentSectionId: '', // The ID of the currently visible section
  errors: '', // Form errors used to jump to the right section
  gridClass: 'col-lg-12', // Which grid size classes to apply to the container
  navClass: 'nav nav-tabs nav-tabs-plain mb-4', // Which nav classes to apply to the nav component
  contentClass: '', // extra class to apply to tab-content
  onSectionNav: null, // Callback when section is changed
};

/**
 * Defines a section supported by SectionNav. Must be a child of SectionNav.
 */
export class SectionNavContent extends React.PureComponent {
  render() {
    const active = this.props.sectionId === this.props.currentSectionId;
    const cls = ReactUtils.cssClass('tab-pane fade', {['active']: active});

    // Add the 'show' class on the next tick so the CSS transition runs
    if (active) {
      setTimeout(() => $(this.refs.section).addClass('show'), 0);
    }

    return (
      <div
        ref="section"
        data-section={this.props.sectionId}
        className={cls}
        id={'section-content-' + this.props.sectionId}
        role="tabpanel"
        aria-hidden={!active}
        aria-labelledby={'section-nav-' + this.props.sectionId}
      >
        {this.props.children}
      </div>
    );
  }
}

SectionNavContent.defaultProps = {
  sectionId: null, // The ID of this section
  currentSectionId: null, // The ID of the currently selected section
};
