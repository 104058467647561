'use strict';

import $ from 'jquery';
import MapProjections from '../../jskit/general/MapProjections';

var MAP_TYPES = {
  solid: {
    cls: MapProjections.RobinsonProjection,
    settings: {
      scaleX: 1.2,
      translateX: -0.055,
      scaleY: 2.4,
      translateY: -0.085,
    },
  },

  dotted: {
    cls: MapProjections.RobinsonProjection,
    settings: {
      scaleX: 1.23,
      translateX: -0.05,
      scaleY: 2.35,
      translateY: -0.09,
    },
  },
};

/*
 * Shows pins on the appropriate map components.
 *
 * See the map projection classes for which settings need to be provided.
 *
 * Specifier: <div class="map-with-pins" data-map-with-pins="solid|dotted">
 *              <img src=""/>
 *              <span data-country="GB" data-offset-x="-2" data-offset-y="5"></span>
 *              <a href="#" data-country="GB"></a>
 *            </div>
 */
export function initializeMapsWithPins(selector) {
  selector = selector || '[data-map-with-pins]';

  $(selector).each(function () {
    var map = $(this);
    var mapSettings = MAP_TYPES[map.data('map-with-pins')];
    var projection = new mapSettings.cls(mapSettings.settings);

    map.children('span, a').each(function () {
      var span = $(this);
      var countryCode = span.data('country');

      var offsetX = parseFloat(span.data('offset-x')) || 0.0;
      var offsetY = parseFloat(span.data('offset-y')) || 0.0;
      if (!countryCode) {
        return;
      }

      var countryLatLon = MapProjections.getCountryLatLon(countryCode);

      if (!countryLatLon) {
        console.log('Invalid country for map: ' + countryCode);
        return;
      }

      var point = projection.projectToPercent(countryLatLon.lat, countryLatLon.lon);
      span.css({left: point.x + offsetX + '%', top: point.y + offsetY + '%'});
    });
  });
}
