'use strict';

import $ from 'jquery';
import MessageBox from './messagebox';
import Ajax from '../../jskit/general/Ajax';

export function initUptimeNavigation() {
  var sidebar = $('.ut-sidebar');
  var submenuToggles = sidebar.find('li.has-submenu > a, li.no-submenu > a');
  var sidebarContent = sidebar.find('.sidebar-content');
  var mobileToggle = sidebar.find('.nav-toggle');

  submenuToggles.on('click', function (e) {
    if ($(this).attr('href') === '#') {
      e.preventDefault();
    }
    if ($(this).attr('id') === 'whatsnewDisplay') {
      return;
    }
    var isOpen = $(this).parent('li').hasClass('open');

    submenuToggles.parent('li.open').removeClass('open').find('ul').css('display', 'block').slideUp(100);

    if (!isOpen) {
      $(this).parent('li').addClass('open').find('ul').css('display', 'none').slideDown(100);
    }
  });

  mobileToggle.on('click', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('open')) {
      $(this).addClass('open');
      sidebarContent.slideDown(500);
    } else {
      sidebarContent.slideUp(500);
      $(this).removeClass('open');
    }
  });

  // Subaccounts select box
  var initialSubaccount = sidebar.find('.subaccounts').data('subaccount');
  var subaccountSelector = sidebar.find('#sidebar-subaccount-selector');

  subaccountSelector.on('change', function () {
    $(this.form).submit();
  });

  subaccountSelector.find('option[value="' + initialSubaccount + '"]').prop('selected', true);

  initWhatsNew();
}

function initWhatsNew() {
  const el = document.getElementById('whatsnewDisplay');
  if (el) {
    const changelog_url = el.getAttribute('data-changelog-url');
    $(el).click((e) => {
      e.preventDefault();
      new Ajax().get({
        url: el.getAttribute('data-url'),
        decoder: 'json',
        success: (data) => {
          MessageBox.messageBox({
            htmlMessage: '<div class="blog-embed whatsnew-container">' + data.content.rendered + '</div>',
            title: "What's New",
            largeModal: true,
            extraButtons:
              '<a target="_blank" href="' +
              changelog_url +
              // eslint-disable-next-line max-len
              '" type="button" class="btn btn-primary">View Release Notes</a> <button data-type="alert" data-event data-dismiss="modal" type="button" class="btn btn-outline-primary">Dismiss</button>',
          });
        },
      });
    });
  }
}
