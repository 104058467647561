import React, {useState} from 'react';
import {HelpIcon} from '../../../components/HelpIcon';
import {DeepMap, FieldError, UseFormRegister} from 'react-hook-form';

export function FormStatusPagePublicComponents({
  register,
  errors,
}: {
  register: UseFormRegister<any>;
  errors: DeepMap<any, FieldError>;
}) {
  const [components, setComponents] = useState([{name: '', description: ''}]);

  const addComponent = (index: number): void => {
    if (index + 1 >= components.length) {
      setComponents([...components, {name: '', description: ''}]);
    }
  };
  return (
    <div className={'form-group'}>
      <label style={{width: '100%'}}>
        <div className="row mb-2">
          <div className={'col-sm-6 d-flex font-weight-bold'}>
            Components Name
            <HelpIcon helpText="Name of components/system, e.g. Website, Database, API." />
          </div>
          <div className={'col-sm-6 d-flex'}>
            Component Description
            <HelpIcon helpText="A description of this component visible to Page visitors." />
          </div>
        </div>
      </label>
      {components.map((component, index) => (
        <div key={index} className="row mb-3">
          <div className={'col-sm-6'}>
            <div className={'d-flex'}>
              <div style={{width: '30px', alignSelf: 'center'}}>{index + 1}</div>
              <input
                type="text"
                name={`initial_components[${index}].name`}
                placeholder="e.g. Website, Database, API"
                {...register(`initial_components[${index}].name`, {
                  onChange: () => addComponent(index),
                })}
                className={`form-control ${errors.initial_components?.[index]?.name ? 'is-invalid' : ''}`}
              />
            </div>
            {errors.initial_components?.[index]?.name && (
              <span className="invalid-feedback d-block font-weight-bold">
                {errors.initial_components?.[index]?.name?.message}
              </span>
            )}
          </div>
          <div className={'col-sm-6'}>
            <input
              type="text"
              name={`initial_components[${index}].description`}
              {...register(`initial_components[${index}].description`, {
                onChange: () => addComponent(index),
              })}
              className={`form-control ${errors.initial_components?.[index]?.description ? 'is-invalid' : ''}`}
            />
            {errors.initial_components?.[index]?.description && (
              <span className="invalid-feedback d-block font-weight-bold">
                {errors.initial_components?.[index]?.description?.message}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
