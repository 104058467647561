import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Formatter from '../../jskit/general/Formatter';
import {PLAN_FIELD_PLURAL} from './PLAN_DEFS';

export default class SubscriptionQuote extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.state = {};
  }

  handlePrint() {
    window.print();
  }

  render() {
    const subscriptionDate = Formatter.localizeDate(this.props.companyData.converted_at).format('MM/DD/YYYY');
    return (
      <div className="SubscriptionQuote">
        <div className="row">
          <div className="col-3">
            <img
              style={{marginLeft: '-10px'}}
              src="/static/img/global/logo-uptime-pdf.png"
              width="185"
              alt="Uptime.com"
            />
          </div>
          <div className="col-9 text-right">
            <small className="text-muted">
              <i className="fal fa-phone"></i>&nbsp;{this.props.uptimeBrandInfo.phone}&nbsp;&nbsp;&nbsp;&nbsp;
              <i className="fal fa-envelope"></i>&nbsp;support@uptime.com
            </small>
          </div>
        </div>
        <hr />
        <h1 className="mb-4">Purchase Quote</h1>
        <div className="row mb-5">
          <div className="col-6">
            <h4>Customer Name</h4>
            <small>
              {this.props.companyData.company_name}
              <br />
              Subscription Date: {subscriptionDate}
            </small>
          </div>
          <div className="col-6 pl-3" style={{borderLeft: '1px solid #C4C4C4'}}>
            <h4>{this.props.uptimeBrandInfo.name} LLC</h4>
            <small
              dangerouslySetInnerHTML={{
                __html: Formatter.linebreaksbr(this.props.uptimeBrandInfo.address),
              }}
            ></small>
          </div>
        </div>
        <div className="row mt-4 mb-2">
          <div className={this.props.isCustomer ? 'col-8' : 'col-6'}>
            <strong>Purchase Description</strong>
          </div>
          {this.props.isCustomer ? (
            false
          ) : (
            <div className="col-2">
              <strong>Included</strong>
            </div>
          )}
          <div className="col-2">
            <strong>Added</strong>
          </div>
          <div className="col-2">
            <strong>Cost</strong>
          </div>
        </div>
        {this.renderBasePlan()}
        {this.renderLines()}
        {this.renderTotals()}
        <div className="text-center mt-3 d-p-none">
          <span style={{color: '#0074ce'}}>
            <i className="fas fa-print"></i>
          </span>
          &nbsp;&nbsp;
          <a tabIndex="2" onClick={this.handlePrint}>
            Print Quote
          </a>
        </div>
      </div>
    );
  }

  renderBasePlan() {
    if (this.props.isCustomer && !this.props.isUpgrade) {
      return false;
    }
    const basePlan = (
      <div className="row mt-4">
        <div className="col-10">{this.props.basePlanName}</div>
        <div className="col-2">{Formatter.shortCurrency(this.props.basePlanPrice, 2)}</div>
      </div>
    );
    return <React.Fragment>{basePlan}</React.Fragment>;
  }

  renderTotals() {
    const {calculatedValues} = this.props;
    let monthlySubtotal = calculatedValues.monthly_subtotal;
    let monthlyPrice = calculatedValues.price_monthly;
    if (this.props.isCustomer && !this.props.isUpgrade) {
      monthlySubtotal = calculatedValues.added_cost_full * 1.0;
      monthlyPrice = calculatedValues.added_cost * 1.0;
    }
    return (
      <div className="totals pb-4">
        <div className="row mt-4">
          <div className="col-9 text-right">Subtotal</div>
          <div className="col-3 text-left">{Formatter.shortCurrency(monthlySubtotal, 2)}</div>
        </div>
        {this.renderDiscounts()}
        <div className="row mt-4">
          <div className="col-9 text-right">
            <strong>Grand Total Per Month</strong>
          </div>
          <div className="col-3 text-left" style={{fontSize: '24px', fontWeight: 600}}>
            {Formatter.shortCurrency(monthlyPrice, 2)}
          </div>
        </div>
        {this.props.isAnnual && (
          <div className="row mt-1">
            <div className="col-9 text-right">
              <strong>Billed Annually</strong>
            </div>
            <div className="col-3 text-left" style={{fontSize: '24px', fontWeight: 600}}>
              {Formatter.shortCurrency(monthlyPrice * 12, 2)}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderDiscounts() {
    const {discounts} = this.props.calculatedValues;
    if (!discounts || !discounts.length) {
      return false;
    }
    const discountsLines = discounts.map((el, idx) => {
      if (!(el.value * 1.0)) {
        return false;
      }

      // Special suffix for fixed amount discounts on an annual subscription
      let suffix = '';
      if (this.props.isAnnual && el.percent === 0 && el.title.toLowerCase().startsWith('promo')) {
        suffix = '(annual)';
      }

      return (
        <div className="row mt-2" key={'discount-' + idx}>
          <div className="col-9 text-right">{el.title}</div>
          <div className="col-3 text-left">
            <strong style={{color: '#11B559'}}>{Formatter.shortCurrency(el.value * -1, 2)}</strong>
            {suffix ? <span className="font-14"> {suffix}</span> : null}
          </div>
        </div>
      );
    });
    return <div>{discountsLines}</div>;
  }

  renderLines() {
    return _.map(PLAN_FIELD_PLURAL, (title, field) => {
      const itemName = title;
      let included = this.props.includedValues[field];
      let added = this.props.addedValues[field] || 0;
      if ((this.props.isCustomer && !added) || (!this.props.isCustomer && !included && !added)) {
        return false;
      }
      const barePrice = this.props.unitsPrices[field] * (added * 1.0);
      if (added && _.isBoolean(added)) {
        added = <i className="fas fa-check"></i>;
      } else if (added && _.isNumber(added)) {
        added = '+' + added;
      }
      if (included && _.isBoolean(included)) {
        included = <i className="fas fa-check"></i>;
      } else if (added && _.isNumber(added)) {
        included = Formatter.humanFriendlyInt(included);
      }
      if (!itemName) {
        return false;
      }
      const colCls = ReactUtils.cssClass('', {
        'col-6 pl-5': !this.props.isCustomer,
        'col-8': this.props.isCustomer,
      });
      return (
        <div key={field}>
          <hr />
          <div className="row">
            <div className={colCls}>{itemName}</div>
            {this.props.isCustomer ? false : <div className="col-2">{included}</div>}
            <div className="col-2">{added ? added : ''}</div>
            <div className="col-2">{barePrice ? Formatter.shortCurrency(barePrice, 2) : ''}</div>
          </div>
        </div>
      );
    });
  }
}
