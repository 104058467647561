'use strict';

import {useEffect, useRef, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'underscore';
import {ServiceSelectorInput} from '../../devices/common/DevicesCommon';
import {default as QuickActions} from '../../devices/services/QuickActions';
import Ajax from '../../jskit/general/Ajax';
import {HelpIcon} from '../../jskit/react/forms/FormHelpers';
import TextInput from '../../jskit/react/forms/TextInput';

const LIMIT = 10;
const SEARCH_DELAY = 200;
const GROUP_LIMIT = 1000;

const SLAReportReportingGroups = ({...props}) => {
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');
  const [searchDelayID, setSearchDelayID] = useState(null);
  const [savedGroups, setSavedGroups] = useState([]);
  const [displayGroups, setDisplayGroups] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [offset, setOffset] = useState(0);

  const isMounted = useRef(false);

  const fetchGroups = (report_id, offset, search) => {
    new Ajax().post({
      url: props.reportingGroupsListURL,
      data: {sla_report_id: report_id, limit: LIMIT, offset: offset, search: search},
      encoder: 'json',
      decoder: 'json',
      success: function (result) {
        const data = result.data;
        setSavedGroups((savedGroups) => [...savedGroups, ...data.items]);
        if (savedGroups.length === data.total) {
          setHasMore(false);
        }
        setLoaded(true);
      },
    });
  };

  const debouncedSearch = useRef(_.debounce(fetchGroups, 500));
  const throttleScroll = useRef(_.throttle(fetchGroups, 100));

  useEffect(() => {
    // Initial data load
    setSavedGroups([]);
    setDisplayGroups([]);
    setHasMore(true);
    setOffset(0);
    setSearch('');
    if (props.formData.id) {
      fetchGroups(props.formData.id, offset, '');
    }
  }, [props.formData.id]);

  useEffect(() => {
    if (isMounted.current) {
      setSavedGroups([]);
      setDisplayGroups([]);
      setHasMore(true);
      setOffset(0);
      setLoaded(false);
      debouncedSearch.current(props.formData.id, offset, search);
    } else {
      isMounted.current = true;
    }
  }, [search]);

  useEffect(() => {
    if (offset > 0) {
      throttleScroll.current(props.formData.id, offset, search);
    }
  }, [offset]);

  useEffect(() => {
    setDisplayGroups([
      ...props.formData.new_groups.filter((g) => g.name.toLowerCase().includes(search.toLowerCase())),
      ...savedGroups,
    ]);

    props.formData.delete_groups.forEach((group, index) => {
      setDisplayGroups((displayGroups) => displayGroups.filter((g) => g.name !== group.name));
    });
  }, [savedGroups]);

  const getNext = () => {
    setOffset((offset) => offset + LIMIT);
  };

  const isGroupNameUnique = async (name) => {
    return new Promise((resolve, reject) => {
      if (props.formData.id) {
        new Ajax().post({
          url: props.reportingGroupsIsValidURL,
          data: {sla_report_id: props.formData.id, name: name},
          encoder: 'json',
          decoder: 'json',
          success: function (data, textStatus, jqXHR, options) {
            const new_is_unique = props.formData.new_groups.filter((group) => group.name == name) == 0;
            resolve(data.success.is_unique && new_is_unique);
          },
        });
      } else {
        resolve(displayGroups.filter((group) => group.name == name) == 0);
      }
    });
  };

  const isGroupLimitOk = async () => {
    return new Promise((resolve, reject) => {
      if (props.formData.id) {
        new Ajax().post({
          url: props.countReportingGroupsURL,
          data: {sla_report_id: props.formData.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data, textStatus, jqXHR, options) {
            resolve(data.success.count - props.formData.delete_groups.length + props.formData.new_groups < GROUP_LIMIT);
          },
        });
      } else {
        resolve(displayGroups.length < GROUP_LIMIT);
      }
    });
  };

  const isGroupValid = async () => {
    let valid = true;
    let error = '';
    let is_name_unique = true;

    if (!props.formData.group || (props.formData.group && props.formData.group.name !== props.formData.group_name)) {
      is_name_unique = await isGroupNameUnique(props.formData.group_name);
    }

    if (!(props.formData.group_name && is_name_unique)) {
      valid = false;
      error = props.formData.group_name ? 'Group Name must be unique.' : 'Group Name is required.';
      props.onError('group_name', error);
    }

    if (props.formData.group_services_selected.length === 0) {
      valid = false;
      error = 'Group must contain at least one check.';
      props.onError('group_services_selected', error);
    }

    if (!(await isGroupLimitOk())) {
      valid = false;
      error = 'Maximum limit reached: Only ' + GROUP_LIMIT + ' groups can be added per report.';
      props.onError('group_name', error);
      props.onError('group_services_selected', error);
    }

    return valid;
  };

  const handleAddGroupClick = async (e) => {
    e.preventDefault();
    props.onError('group_name', '');
    props.onError('group_services_selected', '');

    if (await isGroupValid()) {
      let group = {
        name: props.formData.group_name,
        group_services: props.formData.group_services_selected,
      };

      props.formData.group_name = '';
      props.formData.group_services_selected = [];
      props.formData.group = null;

      props.handleGroupAdd(group);
      setDisplayGroups([group, ...displayGroups]);
    }
  };

  const handleRemoveGroup = (group, e) => {
    e.preventDefault();
    props.handleGroupRemove(group);
    setDisplayGroups(displayGroups.filter((g) => g.name !== group.name));
  };

  const handleEditGroup = (group, e) => {
    e.preventDefault();

    props.formData.group_name = group.name;
    props.formData.group_services_selected = group.group_services;
    props.formData.group = group;
    handleRemoveGroup(group, e);
  };

  return (
    <div className="col-12 mb-4">
      <div className="card">
        <div className="card-body px-3 py-2 border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="card-title d-inline-block m-0">Reporting Groups</h4>

            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" disabled checked />
              <label className="custom-control-label">
                Report on the Groups average Uptime %
                <HelpIcon titleText="Groups Uptime % will be an average of checks Uptime %." />
              </label>
            </div>
          </div>
        </div>
        <div className="card-body bg-light-2">
          <div>
            <h5 className="card-sub-title d-inline-block m-0">Add a New Group</h5>
          </div>
          <div className="mt-4">
            <div className="row">
              <div className="col-6">
                <TextInput fieldName="group_name" labelText="Name" isRequired={true} formLink={props.formLink} />
              </div>

              <div className="col-6">
                <ServiceSelectorInput
                  numChecks={props.formData.group_services_selected.length}
                  fieldName="group_services_selected"
                  placeholder="All checks..."
                  isRequired={false}
                  formErrors={props.errors}
                  labelText="Manually select checks"
                  titleText="Individually select checks."
                  addonLabel="Add"
                  onAddonClick={handleAddGroupClick}
                  addonClass="btn-secondary ml-3"
                  onShowDialog={props.handleChecksSelectionShowDialog}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <h5 className="col-lg-6 col-sm-12 mb-2">List of Groups</h5>
            <div className="col-lg-6 col-sm-12 mb-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-search" />
                  </span>
                </div>
                <input
                  type="text"
                  className={'form-control'}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>

          <div className="col-12 pre-scrollable" id="GroupsTable">
            <InfiniteScroll
              dataLength={displayGroups.length}
              next={getNext}
              hasMore={hasMore}
              children={displayGroups}
              scrollableTarget="GroupsTable"
            >
              <table className="table table-responsive-md mb-0" role="grid">
                <tbody>
                  {displayGroups.length == 0 ? (
                    <>
                      <tr>
                        {loaded && (
                          <td className="col-1 text-center">
                            {search ? (
                              <span>
                                <i className="fas fa-empty-set mt-2 mb-2 ts-large" />
                                <br />
                                No groups found matching your search criteria.
                              </span>
                            ) : (
                              <span>
                                <i className="fas fa-empty-set mt-2 mb-2 ts-large" />
                                <br />
                                You haven't added any groups yet. <br /> Add a name and select checks above to create a
                                new group.
                              </span>
                            )}
                          </td>
                        )}
                      </tr>
                    </>
                  ) : (
                    <>
                      {displayGroups.map((group, index) => {
                        return (
                          <tr key={'reporting-group-row-' + index}>
                            <td className="col-1 text-left text-dark font-weight-bold">{group.name}</td>
                            <td className="col-1 text-center text-dark font-weight-bold">
                              {group.group_services.length} {group.group_services.length > 1 ? 'Checks' : 'Check'} added
                            </td>
                            <td className="col-1 actions-right">
                              <QuickActions
                                actions={[
                                  {
                                    onClick: handleEditGroup.bind(null, group),
                                    className: 'fa-pen-to-square',
                                    text: 'Edit Group',
                                  },
                                  {
                                    onClick: handleRemoveGroup.bind(null, group),
                                    className: 'fa-trash',
                                    text: 'Delete Group',
                                  },
                                ]}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SLAReportReportingGroups;
