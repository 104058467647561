import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Validators from '../../jskit/general/Validators';
import ReactUtils from '../../jskit/react/ReactUtils';
import Modal from '../../jskit/react/Modal';
import TextInput from '../../jskit/react/forms/TextInput';
import CheckBox from '../../jskit/react/forms/CheckBox';
import Select2 from '../../jskit/react/forms/Select2';
import ListInput from '../../jskit/react/forms/ListInput';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import {prepareFormLink, FormErrors, Label} from '../../jskit/react/forms/FormHelpers';
import Schedule from '../../devices/common/Schedule';
import FormWarning from '../../jskit/react/forms/FormWarning';
import Messagebox from '../../js/global/messagebox';

const Pages = {
  PAGE_INFO: 'page_info',
  PAGE_ONCALL: 'page_oncall',
};

const AVAILABLE_STATES = {
  ACTIVE: <span>Always on call</span>,
  SCHEDULED: (
    <span>
      Use this schedule: <i className="far fa-clock text-muted ml-1"></i>
    </span>
  ),
};

export default class ContactGroupForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {},
    };

    this.ALL_PAGES = [
      [Pages.PAGE_INFO, 'Contact Info'],
      [Pages.PAGE_ONCALL, 'On-Call Hours'],
    ];
  }

  getFormData() {
    return this.state.formData;
  }

  showModal(initialFormData) {
    if (initialFormData) {
      this.setState({formData: initialFormData});
      this.setState({currentPage: Pages.PAGE_INFO});
      this.refs.emailList.reset();
      this.refs.smsList.reset();
      this.refs.phonecallList.reset();
      this.refs.oncallschedule.deserialize(initialFormData);
    }

    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  redirectNoProfiles() {
    Messagebox.confirmBox(
      'You are about to be redirected to a new page to add an integration, is this OK?',
      'Redirection',
      function () {
        document.location = this.props.integrationAddURL + '?pop=1';
      }.bind(this)
    );
  }

  handlePageChange(page) {
    this.setState({currentPage: page});
  }

  handleScheduleChange() {
    // force re-rendering if schedule form changed
    this.setState({});
  }

  handleSave(e) {
    e.persist();
    e.preventDefault();

    const newState = Object.assign({}, this.getFormData(), this.refs.oncallschedule.serialize());

    this.setState(
      {formData: newState},
      function () {
        this.props.onSave(e);
      }.bind(this)
    );
  }

  shouldWarnAboutMissingSchedule() {
    const scheduleComponent = this.refs.oncallschedule;
    return scheduleComponent && scheduleComponent.shouldWarn();
  }

  generateWarningList() {
    const warnings = [];
    if (this.shouldWarnAboutMissingSchedule()) {
      warnings.push(
        'Consider adding at least one shift to the schedule; otherwise ' +
          'contact person will not receive notifications. '
      );
    }

    return warnings;
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const formData = this.getFormData();

    // Don't render until we have choices otherwise tel input doesn't initialize properly
    if (_.isEmpty(this.props.choices)) {
      return null;
    }

    return (
      <Modal
        ref="modal"
        wizard="contacts-form"
        title={formData.id ? 'Edit Contact' : 'Add Contact'}
        size="lg"
        saveButton={this.handleSave}
        cancelButton={true}
      >
        <form ref="form" className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          <input type="hidden" name="id" value={formData.id || ''} />
          <SectionNav
            sectionIds={this.ALL_PAGES}
            currentSectionId={this.state.currentPage}
            errors={this.props.errors}
            gridClass="col-lg-6"
            navClass="nav nav-pills nav-pills-buttons nav-justified mb-4"
            onSectionNav={this.handlePageChange}
          >
            <SectionNavContent sectionId={Pages.PAGE_INFO} currentSectionId={this.state.currentPage}>
              <FormErrors errors={this.props.errors.__all__} />
              <div className="row">
                <div className="col-lg">
                  <TextInput fieldName="name" labelText="Name" isRequired={true} formLink={formLink} />
                </div>
              </div>
              <div className="row">
                <div className="col-lg">
                  <ListInput
                    ref="emailList"
                    fieldName="email_list"
                    labelText="Emails"
                    isRequired={false}
                    inputType="email"
                    placeholder="Enter email address"
                    validationFn={Validators.isValidEmail}
                    validationError="Please enter a valid email."
                    formLink={formLink}
                    alphabetical={true}
                  />
                </div>
                <div className="col-lg">{this.renderIntegrations(formLink, formData)}</div>
              </div>
              <div className="row">
                <div className="col-lg">
                  <ListInput
                    ref="smsList"
                    fieldName="sms_list"
                    labelText="SMS"
                    isRequired={false}
                    inputType="tel"
                    countryCodes={this.props.choices.country_codes}
                    placeholder="Enter phone number"
                    validationFn={Validators.isValidInternationalPhone}
                    validationError="Please enter a valid international phone number."
                    formLink={formLink}
                  />
                </div>
                <div className="col-lg">
                  <ListInput
                    ref="phonecallList"
                    fieldName="phonecall_list"
                    labelText="Phone Call"
                    isRequired={false}
                    inputType="tel"
                    countryCodes={this.props.choices.country_codes}
                    placeholder="Enter phone number"
                    validationFn={Validators.isValidInternationalPhone}
                    validationError="Please enter a valid international phone number."
                    formLink={formLink}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div>{this.renderPushNotifications(formLink, formData)}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg">
                  <CheckBox
                    fieldName="up_alerts_email_only"
                    labelText="Send UP alerts by email and integrations only"
                    isRequired={false}
                    formLink={formLink}
                  />
                </div>
              </div>
            </SectionNavContent>
            <SectionNavContent sectionId={Pages.PAGE_ONCALL} currentSectionId={this.state.currentPage}>
              <div className="mb-4">
                <i className="fas fa-info-circle"></i>&nbsp; On-call hours indicate when this contact will be notified
                if a check fails.
              </div>
              <Schedule
                ref="oncallschedule"
                availableStates={AVAILABLE_STATES}
                choices={this.props.choices}
                errors={this.props.errors}
                onChange={this.handleScheduleChange}
              />
            </SectionNavContent>
          </SectionNav>
          {this.renderFooterWarning()}
        </form>
      </Modal>
    );
  }

  renderIntegrations(formLink) {
    if (!this.props.showIntegrations) {
      return null;
    } else if (this.props.choices.integration_profiles.length > 0) {
      return (
        <Select2
          fieldName="integrations"
          multiple={true}
          choices={this.props.choices.integration_profiles}
          labelText="Integrations"
          isRequired={false}
          formLink={formLink}
          wizard="contacts-form-add-integration"
        />
      );
    } else {
      return (
        <div className="form-group">
          <Label labelText="Integrations" />
          <div>
            <button
              data-wizard="contacts-form-add-integration"
              className="btn btn-sm btn-secondary"
              type="button"
              onClick={this.redirectNoProfiles}
            >
              Add New Profile
            </button>
          </div>
        </div>
      );
    }
  }

  renderPushNotifications(formLink) {
    if (this.props.choices.push_notification_profiles.length === 0) {
      return null;
    }

    return (
      <Select2
        fieldName="push_notification_profiles"
        multiple={true}
        choices={this.props.choices.push_notification_profiles}
        labelText="Push Notifications"
        isRequired={false}
        formLink={formLink}
      />
    );
  }

  renderFooterWarning() {
    const warnings = this.generateWarningList();
    return (
      <div className="mt-3">
        <FormWarning warnings={warnings} warningTitle="Suboptimal settings detected:" />
      </div>
    );
  }
}
