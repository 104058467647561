import {SectionNavContent} from '@/jskit/react/SectionNav';
import {CurrentStatusTabs} from '@/status_pages/display/components/tabs/CurrentStatus/CurrentStatus';
import IncidentsList from '@/status_pages/display/IncidentsList';
import ComponentStatus from '@/status_pages/display/ComponentStatus';
import SystemMetrics from '@/status_pages/display/SystemMetrics';
import {ComponentStatusValue, StatusPage} from '@/status_pages/types';
import {EmptySectionRocket} from '@/status_pages/display/EmptySectionRocket';
import {EmptySectionBan} from '@/status_pages/display/EmptySectionBan';

export interface StatusOverviewSectionProps {
  currentSectionId: CurrentStatusTabs;
  statuspage: StatusPage;
  componentStatusRank: Record<ComponentStatusValue, number>;
  componentStatusChoices: Record<ComponentStatusValue, string>;
  metricDatapointsURL: string;
}

export const StatusOverviewSection = ({
  currentSectionId,
  statuspage,
  componentStatusRank,
  componentStatusChoices,
  metricDatapointsURL,
}: StatusOverviewSectionProps) => (
  <SectionNavContent sectionId={CurrentStatusTabs.STATUS_OVERVIEW} currentSectionId={currentSectionId}>
    {statuspage.show_active_incidents &&
      (statuspage.active_incidents.length > 0 || !statuspage.hide_empty_tabs_status) && (
        <>
          <h4 className="mb-3">All Events</h4>
          {statuspage.active_incidents.length > 0 ? (
            <IncidentsList componentStatusRank={componentStatusRank} incidents={statuspage.active_incidents} />
          ) : (
            <EmptySectionRocket>
              There are no <strong>Active Events</strong>
            </EmptySectionRocket>
          )}
        </>
      )}
    {(statuspage.components?.length > 0 || !statuspage.hide_empty_tabs_status) && (
      <>
        <div className="white-block-inner-divider mx-n4 my-4" />
        <h4 className="mb-3">Components: Current Status</h4>
        {statuspage.components?.length > 0 ? (
          <ComponentStatus
            statuspage={statuspage}
            componentStatusRank={componentStatusRank}
            componentStatusChoices={componentStatusChoices}
          />
        ) : (
          <EmptySectionBan>
            There are no <strong>Components</strong>
          </EmptySectionBan>
        )}
      </>
    )}
    {statuspage.metrics?.length > 0 && (
      <>
        <div className="white-block-inner-divider mx-n4 my-4" />
        <h4>System Metrics</h4>
        <SystemMetrics metrics={statuspage.metrics} datapointsURL={metricDatapointsURL} />
      </>
    )}
  </SectionNavContent>
);
