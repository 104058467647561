'use strict';

import React from 'react';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Modal from '../../jskit/react/Modal';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import TextInput from '../../jskit/react/forms/TextInput';
import TextArea from '../../jskit/react/forms/TextArea';
import ImageInput from '../../jskit/react/forms/ImageInput';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';

const Pages = {
  PAGE_STATUSPAGE: 'page_statuspage',
  PAGE_EMAIL: 'page_email',
};

export default class CustomizeForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.ALL_PAGES = [
      [Pages.PAGE_STATUSPAGE, 'Status Page'],
      [Pages.PAGE_EMAIL, 'Emails'],
    ];

    this.state = {
      currentPage: null,
      formData: {},
      validationErrors: {},
    };
  }

  getFormData() {
    return this.state.formData;
  }

  showModal() {
    this.setState({
      currentPage: Pages.PAGE_STATUSPAGE,
      formData: Object.assign({}, this.props.statuspage),
    });
    this.clearValidationErrors();
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  handlePageChange(page) {
    this.setState({currentPage: page});
  }

  handleImageRemove(imgFieldName) {
    const formData = Object.assign({}, this.getFormData());
    formData[imgFieldName] = null;
    this.setState({formData});
  }

  handleSubmit(e) {
    e.preventDefault();

    this.clearValidationErrors();
    new Ajax().post({
      url: this.props.updateURL,
      data: this.getFormData(),
      encoder: 'formdata',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.props.onNewData(data.data);
          this.hideModal();
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.state.validationErrors);
    const formData = this.getFormData();

    return (
      <Modal ref="modal" title="Customize Look &amp; Feel" size="lg" saveButton={this.handleSubmit} cancelButton={true}>
        <form ref="form" className="form-spaced" onSubmit={this.handleSubmit}>
          <SectionNav
            sectionIds={this.ALL_PAGES}
            currentSectionId={this.state.currentPage}
            errors={this.state.validationErrors}
            gridClass="col-lg-6"
            navClass="nav nav-pills nav-pills-buttons nav-justified mb-4"
            onSectionNav={this.handlePageChange}
          >
            <SectionNavContent sectionId={Pages.PAGE_STATUSPAGE} currentSectionId={this.state.currentPage}>
              {this.renderStatuspageForm(formLink, formData)}
            </SectionNavContent>
            <SectionNavContent sectionId={Pages.PAGE_EMAIL} currentSectionId={this.state.currentPage}>
              {this.renderEmailForm(formLink, formData)}
            </SectionNavContent>
          </SectionNav>
        </form>
      </Modal>
    );
  }

  renderCustomHeaderFooterCss(formLink) {
    return (
      <React.Fragment>
        <TextArea
          fieldName="custom_css"
          labelText="Custom CSS"
          /* eslint-disable-next-line max-len */
          titleText="OPTIONAL: On public pages, inline CSS used to style customized HTML in the Header and Footer sections and/or to style the page itself."
          isRequired={false}
          formLink={formLink}
        />
        <TextArea
          fieldName="custom_header_html"
          labelText="Custom Header HTML"
          /* eslint-disable-next-line max-len */
          titleText="OPTIONAL: On public pages, inline HTML to render in the header section in place of the standard logo & title."
          isRequired={false}
          formLink={formLink}
        />
        <TextArea
          fieldName="custom_footer_html"
          labelText="Custom Footer HTML"
          /* eslint-disable-next-line max-len */
          titleText="OPTIONAL: On public pages, inline HTML to render in the footer section in place of the standard logo & title."
          isRequired={false}
          formLink={formLink}
        />
      </React.Fragment>
    );
  }

  renderStatuspageForm(formLink, formData) {
    const {advancedFeaturesEnabled} = this.props;
    return (
      <React.Fragment>
        <FormErrors errors={this.state.validationErrors.__all__} />
        <div className="row">
          <div className="col-lg-6">
            <ImageInput
              fieldName="logo"
              labelText="Custom Logo"
              isRequired={false}
              currentImageURL={this.props.statuspage.logo_url}
              onRemove={this.handleImageRemove}
              formLink={formLink}
            />
          </div>
          <div className="col-lg-6">
            <ImageInput
              fieldName="favicon"
              labelText="Custom Favicon"
              isRequired={false}
              currentImageURL={this.props.statuspage.favicon_url}
              onRemove={this.handleImageRemove}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <TextInput
              fieldName="google_analytics_code"
              labelText="Google Analytics Property ID"
              titleText="Your google analytics property ID."
              placeholder="Property ID such as UA-12345678-1"
              isRequired={false}
              formLink={formLink}
            />
          </div>
          <div className="col-lg-6">
            <TextInput
              fieldName="contact_email"
              labelText="Contact email address"
              /* eslint-disable-next-line max-len */
              titleText="Allow page visitors to report issues to this email address (e.g. support@your-domain.com)."
              isRequired={false}
              formLink={formLink}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TextInput
              fieldName="company_website_url"
              labelText="Company Website URL"
              titleText="Clicking the logo of your status page will forward to this URL."
              placeholder="https://example.com"
              isRequired={false}
              formLink={formLink}
            />
          </div>
        </div>
        {advancedFeaturesEnabled ? this.renderCustomHeaderFooterCss(formLink, formData) : false}
      </React.Fragment>
    );
  }

  renderEmailForm(formLink) {
    const emailFromHelp = (
      <React.Fragment>
        This will require DNS changes for your domain (e.g. SPF and/or DKIM records).&nbsp;
        {/* eslint-disable-next-line max-len */}
        <ZendeskSupportLink href="https://support.uptime.com/hc/en-us/articles/360016295019-Create-an-SPF-Record-to-Send-Email-via-Uptime-com">
          View&nbsp;Documentation&nbsp;&rarr;
        </ZendeskSupportLink>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <ImageInput
          fieldName="email_logo"
          labelText="Custom Email Logo"
          titleText="Upload an image to use as the logo in emails sent to subscribers of your Page."
          isRequired={false}
          currentImageURL={this.props.statuspage.email_logo_url}
          onRemove={this.handleImageRemove}
          formLink={formLink}
        />
        <TextInput
          fieldName="email_from"
          labelText="Send from email"
          isRequired={false}
          helpText={emailFromHelp}
          formLink={formLink}
        />
        <TextInput fieldName="email_reply_to" labelText="Reply To email" isRequired={false} formLink={formLink} />
      </React.Fragment>
    );
  }
}

CustomizeForm.defaultProps = {
  updateURL: null,
  choices: {},
  statuspage: null,
  onNewData: null,
  advancedFeaturesEnabled: false,
};
