'use strict';

import $ from 'jquery';
import './import-modules';
import initSelect from 'select2';
import 'bootstrap-slider';
import 'bootstrap-daterangepicker';
import 'tempusdominus-bootstrap-4';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
import 'intl-tel-input';
import 'floatthead';
import 'tablesorter';
import 'jquery-bar-rating';
import 'popper.js';
import 'bootstrap';
import initHover from 'bootstrap-dropdown-hover/dist/jquery.bootstrap-dropdown-hover';

import {initializeAlertMessages as initializeAlerts} from '../js/global/alerts';
import {initUptimeNavigation as initializeSidebar} from '../js/global/sidebar';
import {initializeDateRangePicker} from '../js/global/daterangepicker';
import {initializeMapsWithPins} from '../js/global/maps';
import {initializeCounters} from '../js/global/counters';
import {initialize as initializeZendesk} from '../js/global/zendesk';
import initMisc from '../js/global/misc';

export const initializeAdmin = () => {
  function copyToClipboard(text, prompt) {
    prompt = prompt || 'Copy to clipboard: Ctrl+C, Enter';
    window.prompt(prompt, text);
    return false;
  }

  $(document).ready(function () {
    $('[data-clipboard]').on('click', function (e) {
      e.preventDefault();
      copyToClipboard($(this).data('clipboard'));
    });
    $('.tablesorter').tablesorter({
      widgets: ['stickyHeaders'],
    });
  });
};

export const initializeJQueryPlugins = () => {
  initHover($);
  initSelect($);
  $(document).ready(function () {
    // init dropdown only if navbar is found
    // a bug, even if the author claims it's not
    // https://github.com/istvan-ujjmeszaros/bootstrap-dropdown-hover/issues/23
    if ($('.navbar [data-toggle="dropdown"]').html()) {
      $('.navbar [data-toggle="dropdown"]').bootstrapDropdownHover({});
    }
    initializeAlerts();
    initializeSidebar();
    initializeDateRangePicker();
    initializeMapsWithPins();
    initializeCounters();
    initializeZendesk();
  });
  initMisc();
};
