import moment from 'moment-timezone';
import {LegacyLogo} from '@/status_pages/display/components/theme-specific/legacy/LegacyLogo';
import {ReactNode} from 'react';
import {StatusPage} from '@/status_pages/types';

export interface LegacyHeaderProps {
  statuspage: StatusPage;
  toolbar: ReactNode | null;
}

export const LegacyHeader = ({statuspage, toolbar}: LegacyHeaderProps) => {
  const formattedTimezoneOffset = moment().tz(statuspage.used_timezone).format('Z');
  const hasCustomHeader = statuspage.custom_header_html && statuspage.custom_header_html.length;
  if (hasCustomHeader) {
    return null;
  } else {
    return (
      <div className="white-block white-block-border white-block-divider d-flex justify-content-between row mx-0">
        <div>
          <div className="header p-4">
            <LegacyLogo
              name={statuspage.name}
              logoUrl={statuspage.logo_url}
              companyWebsiteUrl={statuspage.company_website_url}
            />
            <h1>{statuspage.name}</h1>
            {statuspage.description_html ? (
              <div
                className="description markdown"
                dangerouslySetInnerHTML={{__html: statuspage.description_html}}
              ></div>
            ) : null}
            <div className="timezone">
              Timezone: (GMT {formattedTimezoneOffset}) {statuspage.used_timezone}
            </div>
          </div>
        </div>
        <div className="p-4 d-flex">{toolbar}</div>
      </div>
    );
  }
};
