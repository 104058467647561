import {StatusPageGlobalStatus} from '@/status_pages/types';
import ReactUtils from '@/jskit/react/ReactUtils';
import formatter from '@/jskit/general/Formatter';
import css from './GlobalStatus.module.css';

export interface GlobalStatusProps {
  status: StatusPageGlobalStatus;
  durationSecs: number | null;
}

const PulseIcon = () => (
  <svg
    className={ReactUtils.cssClass('mr-1', css.PulseIcon)}
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.1" cx="11.5" cy="11" r="9" fill="#00AA66" />
    <circle opacity="0.25" cx="11.5" cy="11" r="8" fill="#00AA66" />
    <circle opacity="0.4" cx="11.5" cy="11" r="7" fill="#00AA66" />
    <circle cx="11.5" cy="11" r="6" fill="#00AA66" />
  </svg>
);

export const GlobalStatus = ({status, durationSecs}: GlobalStatusProps) => {
  let message, verbosePeriod, containerClass;
  const shortDuration = formatter.shortDuration(durationSecs);
  if (status === StatusPageGlobalStatus.OPERATIONAL) {
    message = (
      <>
        <i className="fa-solid fa-arrow-up mr-2"></i>All Systems Operational
      </>
    );
    verbosePeriod = (
      <>
        <PulseIcon /> For: <strong>{shortDuration}</strong>
      </>
    );
    containerClass = css.isOperational;
  } else if (status === StatusPageGlobalStatus.ISSUES) {
    message = (
      <>
        <i className="fa-solid fa-arrow-down mr-2"></i>Some systems are experiencing issues
      </>
    );
    verbosePeriod = (
      <>
        For: <strong>{shortDuration}</strong>
      </>
    );
    containerClass = css.isIssue;
  } else if (status === StatusPageGlobalStatus.MAINTENANCE) {
    message = (
      <>
        <i className="fa-solid fa-wrench mr-2"></i>Maintenance
      </>
    );
    verbosePeriod = (
      <>
        Ends in: <strong>{shortDuration}</strong>
      </>
    );
    containerClass = css.isMaintenance;
  }
  return (
    <div
      className={ReactUtils.cssClass(
        'pt-3 pt-lg-4 pb-3 ml-lg-auto mr-lg-auto',
        css.GlobalStatusContainer,
        containerClass
      )}
    >
      <div className="row pl-2 pr-2 mt-lg-1">
        <div className="col-12 col-lg-8 text-center text-lg-left">{message}</div>
        {durationSecs > 0 && (
          <div className="col-12 col-lg-4 text-lg-right text-center mt-2 mt-lg-0">
            <span className={css.GlobalStatusDuration}>{verbosePeriod}</span>
          </div>
        )}
      </div>
    </div>
  );
};
