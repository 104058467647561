'use strict';

import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import {FormErrors} from '../../jskit/react/forms/FormHelpers';
import Select2 from '../../jskit/react/forms/Select2';
import DurationInput from '../../jskit/react/forms/DurationInput';

export default class ServiceEscalations extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      escalations: [],
    };
  }

  serialize() {
    const escalations = this.state.escalations.filter((e) => e.contact_groups && e.contact_groups.length);

    return JSON.parse(JSON.stringify(escalations));
  }

  deserialize(escalations) {
    if (escalations && escalations.length) {
      escalations = JSON.parse(JSON.stringify(escalations));
      escalations = escalations.map((e) => _.pick(e, 'wait_time', 'contact_groups', 'num_repeats'));
      this.setState({escalations: escalations});
    } else {
      this.setState({
        escalations: [{wait_time: 0, contact_groups: [], num_repeats: 0}],
      });
    }
  }

  handleChange(index, field, e) {
    this.state.escalations[index][field] = e.target.value;
    this.setState({escalations: this.state.escalations});
  }

  handleDelete(index, e) {
    e.preventDefault();
    this.state.escalations.splice(index, 1);
    this.setState({escalations: this.state.escalations});
  }

  handleAddAnother(e) {
    e.preventDefault();
    if (this.state.escalations.length < 20) {
      this.state.escalations.push({wait_time: 0, contact_groups: [], num_repeats: 0});
      this.setState({escalations: this.state.escalations});
    }
  }

  render() {
    return (
      <div className="mb-4">
        <FormErrors errors={this.props.errors && this.props.errors.escalations} />
        <div className="mb-4">
          <i className="fa fa-info-circle"></i>&nbsp; Escalations let you notify additional contacts if a check stays
          down for a longer period of time.
        </div>
        <div className="table-responsive">
          <table className="escalations-table">
            <thead>
              <tr>
                <td>After:</td>
                <td></td>
                <td>Notify:</td>
                <td>Repeat Times:</td>
                <td></td>
              </tr>
            </thead>
            <tbody>{this.state.escalations.map(this.renderEscalation)}</tbody>
          </table>
        </div>
        <div>
          <button type="button" onClick={this.handleAddAnother} className="btn btn-outline-secondary">
            <i className="fa fa-plus"></i>&nbsp; Add another
          </button>
        </div>
      </div>
    );
  }

  renderEscalation(escalation, index) {
    return (
      <tr key={index}>
        <td>
          <DurationInput
            fieldName="after"
            value={escalation.wait_time}
            maxLength={'3'}
            upperLimit={999}
            onChange={this.handleChange.bind(null, index, 'wait_time')}
          />
        </td>
        <td className="text-center">
          <i className="fas fa-angle-double-right mt-2" />
        </td>
        <td>
          <Select2
            fieldName="contact_groups"
            choices={this.props.choices.contact_groups}
            isRequired={true}
            multiple={true}
            closeOnSelect={false}
            value={escalation.contact_groups}
            onChange={this.handleChange.bind(null, index, 'contact_groups')}
          />
        </td>
        <td>
          <input
            type="number"
            min={0}
            max={this.props.choices.escalation_max_repeats}
            maxLength={2}
            size={2}
            value={escalation.num_repeats}
            onChange={this.handleChange.bind(null, index, 'num_repeats')}
            className={'form-control text-right'}
          />
        </td>
        <td className="text-center">
          <a href="#" className="text-danger d-inline-block mt-2" onClick={this.handleDelete.bind(null, index)}>
            <i className="fas fa-times"></i>
          </a>
        </td>
      </tr>
    );
  }
}
