/**
 * Returns an object with keys:
 *   - componentIndex: Index in the main component array
 *   - subcomponentIndex: Index in the subcomponent array, if relevant
 *   - component: Component object itself
 */
export function findComponentIndex(components, componentId, componentIsGroup) {
  for (let i = 0; i < components.length; i++) {
    if (components[i].id === componentId && components[i].is_group === componentIsGroup) {
      return {
        component: components[i],
        componentIndex: i,
        subcomponentIndex: null,
      };
    }

    if (!components[i].subcomponents || componentIsGroup) {
      continue;
    }
    for (let j = 0; j < components[i].subcomponents.length; j++) {
      if (components[i].subcomponents[j].id === componentId) {
        return {
          component: components[i].subcomponents[j],
          componentIndex: i,
          subcomponentIndex: j,
        };
      }
    }
  }

  return null;
}

export function findMetricIndex(metrics, metricId) {
  for (let i = 0; i < metrics.length; i++) {
    if (metrics[i].id === metricId) {
      return i;
    }
  }
  return null;
}

export function swapMetricsWithIds(metrics, srcId, dstId) {
  const sourceMetricPos = findMetricIndex(metrics, srcId);
  const metric = metrics[sourceMetricPos];
  metrics.splice(sourceMetricPos, 1);
  const targetMetricPos = findMetricIndex(metrics, dstId);
  metrics.splice(targetMetricPos, 0, metric);
}

/**
 *
 * @param components - original list of components to alter
 * @param el - element to move
 * @param targetGroupId - if provided, el will be added to the top of the component group with this id
 * @param insertBefore - if provided, el will be inserted onto the same level before this element
 */
export function moveComponent(components, el, targetGroupId, insertBefore) {
  // remove component from it's original place
  const srcPos = findComponentIndex(components, el.id, el.is_group);
  if (srcPos.subcomponentIndex !== null) {
    components[srcPos.componentIndex].subcomponents.splice(srcPos.subcomponentIndex, 1);
  } else {
    components.splice(srcPos.componentIndex, 1);
  }
  // insert component to its new place and update its group id
  if (insertBefore) {
    el.group = insertBefore.group;
    const dstPos = findComponentIndex(components, insertBefore.id, insertBefore.is_group);
    if (dstPos.subcomponentIndex !== null) {
      components[dstPos.componentIndex].subcomponents.splice(dstPos.subcomponentIndex, 0, el);
    } else {
      components.splice(dstPos.componentIndex, 0, el);
    }
  } else {
    // insert into the head of subcomponents of the group with given id
    const dstGroupPos = findComponentIndex(components, targetGroupId, true);
    el.group = targetGroupId;
    components[dstGroupPos.componentIndex].subcomponents.splice(0, 0, el);
  }
}

export function getComponentStatus(component, ranks) {
  if (!component.is_group) {
    return component.status;
  }
  if (!component.subcomponents.length) {
    return 'operational';
  }
  const keyFn = (x) => ranks[x.status] + '|' + x.starts_at;
  return component.subcomponents.slice().sort((a, b) => (keyFn(a) < keyFn(b) ? -1 : 1))[0].status;
}

export function getComponentStatusDisplay(component, choices, ranks) {
  const status = getComponentStatus(component, ranks);
  const displayName = choices.find((c) => c[0] === status);
  return displayName[1];
}

export const markdownGuideURL = 'https://www.markdownguide.org/cheat-sheet/';
