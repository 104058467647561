import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import Modal from '../../jskit/react/Modal';
import ReactUtils from '../../jskit/react/ReactUtils';
import {FieldErrors} from '../../jskit/react/forms/FormHelpers';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';

import $ from 'jquery';

const DEFAULT_TAG_COLOR = '#999999';

const STANDARD_COLORS = [
  '#999999',
  '#bcc1c5',
  '#4d4d4d',
  '#61bd4f',
  '#f2d600',
  '#ffab4a',
  '#eb5a46',
  '#c377e0',
  '#0079bf',
  '#00c2e0',
  '#51e898',
  '#ff80ce',
];

export default class TagsController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      tags: [],
      maxTags: 0,
      validationErrors: {},
    };
  }

  showModal(tags, maxTags) {
    this.setState({tags: tags.slice(0, maxTags), maxTags: maxTags});
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  handleTagChange(index, attrs) {
    var tags = _.clone(this.state.tags);
    if (index >= tags.length) {
      tags.push({id: null, tag: '', color_hex: DEFAULT_TAG_COLOR});
    }

    tags[index] = _.extend({}, tags[index], attrs);
    this.setState({tags: tags});
  }

  handleTagNameChange(index, e) {
    e.preventDefault();
    this.handleTagChange(index, {tag: e.target.value});
  }

  handleTagColorSelection(index, e) {
    e.preventDefault();
    if (index >= this.state.tags.length) {
      this.refs.blankTag.focus();
    }

    this.handleTagChange(index, {color_hex: $(e.target).data('color-hex')});
  }

  handleTagRemove(index, e) {
    e.preventDefault();
    this.handleTagChange(index, {deleted: true});
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({validationErrors: {}});

    new Ajax().post({
      url: this.props.saveURL,
      data: {tags: this.state.tags},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          if (this.props.onSaved) {
            this.props.onSaved();
          }
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  render() {
    return (
      <Modal ref="modal" title="Manage Tags" size="sm" saveButton={this.handleSave} cancelButton={true}>
        <form onSubmit={ReactUtils.doNotSubmit}>{this.renderTagList()}</form>
        {this.state.tags && this.state.tags.length >= this.state.maxTags ? (
          <div className="text-muted">
            <em>Limited to {this.state.maxTags}. Update additional tags via the API.</em>
          </div>
        ) : null}
        <small className="form-text text-muted">
          <ZendeskSupportLink href="https://support.uptime.com/hc/en-us/articles/360001254399-Tags-and-Filtering-Basics">
            View&nbsp;Documentation&nbsp;→
          </ZendeskSupportLink>
        </small>
      </Modal>
    );
  }

  renderTagList() {
    var tagsWithBlankTag = this.state.tags.concat({tag: '', color_hex: DEFAULT_TAG_COLOR, blank: true});

    return _.map(tagsWithBlankTag, (tag, index) => {
      const colorSelectionLinks = STANDARD_COLORS.map((color, cIdx) => {
        return (
          <a
            href="#"
            key={cIdx}
            onClick={this.handleTagColorSelection.bind(null, index)}
            data-color-hex={color}
            className="dropdown-item"
            style={{backgroundColor: color}}
          />
        );
      });

      const error = this.state.validationErrors[index];

      return (
        <div key={index} className={ReactUtils.cssClass('form-group row', {['d-none']: tag.deleted})}>
          <div className="col-2">
            <div className="dropdown tag-color-picker">
              <a href="#" data-toggle="dropdown" style={{backgroundColor: tag.color_hex}}></a>
              <div className="dropdown-menu">{colorSelectionLinks}</div>
            </div>
          </div>
          <div className="col-8">
            <input
              ref={tag.blank ? 'blankTag' : null}
              type="text"
              name="tag"
              className={ReactUtils.cssClass('form-control', {['is-invalid']: error})}
              placeholder={tag.blank ? 'Add new tag...' : null}
              value={tag.tag}
              onChange={this.handleTagNameChange.bind(null, index)}
            />
            <FieldErrors errors={error} />
          </div>
          <div className="col-2">
            {!tag.blank ? (
              <a href="#" className="tag-remove-link" onClick={this.handleTagRemove.bind(null, index)}>
                <i className="fas fa-times"></i>
              </a>
            ) : null}
          </div>
        </div>
      );
    });
  }
}
